'use strict';

import { DateTime } from 'luxon';
import { IProbeQuestionEditorModel } from 'isc-ui';
import { IUploadConversationAttachment, IUploadConversationStimulus } from 'isc-ui/dist/components/upload-conversation/isc-upload-conversation.model';
import { IProbeQuestionModel } from '../../activity/activityQualResearchConfig/activityQualSteps/activityProbeQualBuild/IProbeQuestionModel';
import { ITargetingQuota } from './targeting.contracts';

export interface IActivityFilterData {
  Keyword: string,
  Highlight?: boolean,
  StartDate: DateTime,
  EndDate: DateTime,
  Statuses?: string[],
  SelectedThemes?: string[],
  SelectedMembers?: string[],
  SelectedSegments?: string[],
  SelectedSegmentsOption?: number,
  SelectedRatings?: number[],
  TagsSelected?: string[],
  HashtagsSelected?: string[],
  SelectedStimuliOptions?: number[],
  SelectedAnnotationsOptions?: number[],
  SelectedModerationStatusOptions?: number[],
  SelectedProbeQuestions?: string[],
  JumpToFirstNew?: boolean,
  ActivityType?: number,
  IsGroupedByMember?: boolean,
  SortOption?: number,
}

export interface IActivityFilterAdditionalData {
  MinStartDate: DateTime,
  MaxEndDate: DateTime,
  IsModeratorCurationEnabled?: boolean;
}

export interface IThemeItem {
  Guid: string,
  DisplayLabel: string,
  Name: string,
  Selected: boolean,
}
export interface IMemberItem {
  Guid: string,
  Id: number,
  Username: string,
  DisplayLabel: string,
  Selected: boolean,
}

export interface ITagItem {
  Guid: string,
  Text: string,
  DisplayLabel: string,
  Selected: boolean,
}

export interface IProbeQuestionItem {
  Guid: string,
  Message: string,
  DisplayLabel: string,
  Selected: boolean,
}

export interface IRating {
  Text: string,
  DisplayLabel: string,
  Selected: boolean,
  Value: number
}

// Used for filters that have a predefined list of options
export interface IStaticFilterOption {
  Id: number,
  Text: string,
  DisplayLabel: string
}

export interface IActivityReportData {
  StartDate: string | DateTime;
  EndDate: string | DateTime;
  StartDateOffset: number;
  EndDateOffset: number;
  Status: number;
  Type: number;
  Format: number;
  OverallProgress: IOverallProgress;
  OverallProgressGraphData: Array<{
    values: number[];
    text: string;
    backgroundColor: string;
  }>;
  DropOutRate: IDropOutRate;
  ReportDataCommunicationList: IReportDataCommunication[];
  RandomlySelected?: number;
  Targeted: number;
  TargetedFrom: number;
  TargetingHistory: IReportDataTargetingHistory[];
  DateLastSynced?: string | DateTime;
  CompletionTarget?: number;
}

export interface IReportDataTargetingHistory {
  CommunicationDateCreated: string;
  TargetingFilterOption: number | string;
  Items: IReportDataItem[];
  Targeted: number;
  TargetedFrom: number;
  RandomlySelected?: number;
  TargetingType: number;
  TargetingQuota?: ITargetingQuota;
}

interface IReportDataItem {
  Segmentation: string;
  Segments: string[] | string;
  TargetingFilterOption: number | string;
}

interface IReportDataCommunication {
  CommunicationTitle: string;
  TargetingFilterOption: number | string;
  Items: IReportDataItem[];
  RandomlySelected?: number;
  Targeted: number;
  TargetedFrom: number;
  CommunicationChannelTypes: number[];
  ChannelTypeLabel?: string;
}

interface IDropOutRate {
  Incomplete: number;
  CompletePlusIncomplete: number;
  Percentage: number;
}

interface IOverallProgress {
  Incomplete: number;
  Invited: number | string;
  Qualified: number;
  Screened: number;
  QuotaFull: number;
  Percentage: number;
  Completed: number;
  NotStarted: number;
}

export interface IHashtagItem {
  Guid: string,
  Text: string,
  DisplayLabel: string,
  Selected: boolean,
}

export interface IDecipherConfiguration {
  xmlFound: boolean;
  surveyNodeFound: boolean;
  loadDataIsConfigured: boolean;
  alwaysSaveDataConfigured: boolean;
  delphiConfigured: boolean;
  uniqueIdentifierInExtraVariables: boolean;
  customJSConfigured: boolean;
}

export interface IPrepareSurveyFinished {
  IsFinished: boolean;
  ActivityGuid: string;
}

export interface ITargetingNumbersUpdated {
  TargetingStatus: number;
  ActivityGuid: string;
}

export interface IGenericDraft {
  message: string,
  stimuli: IUploadConversationStimulus[],
  attachments: IUploadConversationAttachment[],
  questions: IProbeQuestionEditorModel[],
  additionalquestions: IProbeQuestionModel[],
  state: number,
}

export interface StimulusTranscriptionStatusChanged {
  StimulusGuid: string;
  Status: number;
  Url: string;
  ThumbnailUrl: string;
  DiscussionGuid: string;
}
