'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const squareLogoutAppRun = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'squarelogout',
      config: {
        url: '/client/:clientGuid/program/:programGuid/square/:squareGuid/squarelogout?token?redirectType?activityGuid?step?replyGuid?isEdit',
        component: 'squareLogoutComponent',
        requireADLogin: false,
        allowAnonymous: true,
      },
    },
  ]);
};
squareLogoutAppRun.$inject = ['routerHelper'];

angular
  .module('insitesApp.squareLogout')
  .run(squareLogoutAppRun);
