'use strict';

import { ServerConstants } from './../../core/serverconstants';
import { AuthService } from '../../core/dataservices/auth.service';

export class ResearcherLoginController {
  static $inject = ['authService', '$stateParams', 'serverConstants'];

  constructor(
    private authService: AuthService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
  ) { }

  async $onInit() {
    const switchInterface = this.$stateParams.switchinterface;
    const activateMessage = this.$stateParams.activatemsg && this.$stateParams.activatemsg === 'true';
    const redirectType: number = this.$stateParams.redirectType ?
      Number(this.$stateParams.redirectType) :
      this.serverConstants.researcherLoginRedirectTypeConstants.activities;
    const additionalParams = {
      activityGuid: this.$stateParams.activityGuid,
      step: this.$stateParams.step,
      replyGuid: this.$stateParams.replyGuid,
      isEdit: this.$stateParams.isEdit,
      activateMessage,
    };

    await this.authService.redirectToRoute(redirectType, switchInterface, additionalParams);
  }
}
