import { IscColorPickerController } from './isc-color-picker.controller';
var template = "    <div class=\"selectColorDivContainer\">\n      <div class=\"title\" ng-class=\"{'input-error': !vm.isValid()}\">{{vm.label}}</div>\n      <div\n          md-color-picker\n          color-validator\n          required\n          md-color-hsl=\"false\"\n          md-color-rgb=\"false\"\n          md-color-history=\"false\"\n          md-color-generic-palette=\"false\"\n          md-color-material-palette=\"false\"\n          md-color-alpha-channel=\"false\"\n          md-color-clear-button=\"false\"\n          class=\"selectColorDiv\"\n          ng-class=\"{'borderError': !vm.isValid()}\"\n          ng-model=\"vm.color\"\n          Name=\"color\">\n        <div class=\"selectColor\" ng-style=\"{'background-color': vm.color}\"></div>\n      </div>\n    </div>\n";
import './isc-color-picker.scss';
export var IscColorPickerComponent = {
    controller: IscColorPickerController,
    controllerAs: 'vm',
    template: template,
    bindings: {
        color: '=',
        label: '<?',
    },
};
