'use strict';

import { DateTime } from 'luxon';
import { IUserNotification } from './../services/notifications.contracts';
import { ForumService } from './forum.service';
import { HttpService } from './http.service';
import { NotificationsService } from '../services/notifications';
import { Utils } from '../../core/utils';

import { ServerConstants } from '../serverconstants';
import { CurrentUserService } from './currentUser.service';
import { IQService } from 'angular';

export class UserNotificationService {
  static $inject = ['_', 'httpservice', 'notifications', 'serverConstants', 'forumservice', '$state', 'currentUserService', '$q'];

  constructor(
    private _: _.LoDashStatic,
    private httpservice: HttpService,
    private notifications: NotificationsService,
    private serverConstants: ServerConstants,
    private forumservice: ForumService,
    private $state,
    private currentUserService: CurrentUserService,
    private $q: IQService,
  ) {
    this._cancelScheduledExpirededNotificationRemoval = _.noop;

    this.notifications.userNotification
      .subscribe(() => {
        this.loadNotifications();
      });

    this.currentUserService.impersonatedUserChange.subscribe(() => {
      this.loadNotifications();
    });

    this.notifications.connected.subscribe((connected) => {
      if (!connected) {
        this.disposeNotifications();
      } else {
        this.loadNotifications();
      }
    });
  }

  get userNotifications() {
    return this._userNotifications;
  }

  private _userNotifications: IUserNotification[] = [];
  private _cancelScheduledExpirededNotificationRemoval: () => void;

  markNotificationRead(guid, relatedNotificationGuids: string[]) {
    return this.httpservice.post({
      url: '/NotificationService/MarkNotificationRead',
      data: {
        notificationGuid: guid,
        relatedNotificationGuids: relatedNotificationGuids || [],
      },
    });
  }

  clearAllNotifications() {
    this._userNotifications = [];
    return this.httpservice.post({
      url: '/NotificationService/ClearAllNotifications',
    });
  }

  targetHasActiveNotification(targetGuid, notificationType) {
    const activeNotification = this._.find(this._userNotifications, (notification) =>
      (notification.TargetGuid === targetGuid && notification.NotificationType === notificationType));
    return activeNotification !== undefined;
  }

  async resolveNotificationTargetGuid(notification: IUserNotification) {
    let targetGuid = notification.TargetGuid;
    if (notification.NotificationType === this.serverConstants.notificationTypeConstants.discussionMention) {
      targetGuid = await this.forumservice.getQualSampleGuidFromReply(notification.TargetGuid);
    }
    return targetGuid;
  }

  private getUserNotifications(): ng.IPromise<IUserNotification[]> {
    if (!this.$state.params.squareGuid) {
      return this.$q.resolve([]);
    }

    return this.httpservice.get({
      url: '/NotificationService/GetUserNotificationsForAdminIf',
    }).then((response) => response.data);
  }

  private scheduleExpiredNotificationRemoval() {
    // Remove expired notifications
    this._cancelScheduledExpirededNotificationRemoval();
    if (this._userNotifications.length === 0) {
      this._cancelScheduledExpirededNotificationRemoval = this._.noop;
    } else {
      // Find the closest expiration date, and remove notifications when we're there
      let minExpiryDate = this._userNotifications[0].ExpiryDate;
      this._userNotifications.forEach((n) => {
        if (n.ExpiryDate < minExpiryDate) {
          minExpiryDate = n.ExpiryDate;
        }
      });
      this._cancelScheduledExpirededNotificationRemoval = Utils.runAtDate(minExpiryDate.toJSDate(), () => {
        this.removeExpiredNotifications();
        this.scheduleExpiredNotificationRemoval();
      });
    }
  }

  private removeExpiredNotifications() {
    const currentDate = DateTime.now();
    this._userNotifications = this._.filter(this._userNotifications, (notification) => notification.ExpiryDate >= currentDate);
  }

  private incomingNotificationsHandler(newNotifications: IUserNotification[]) {
    this._userNotifications = newNotifications;

    this.scheduleExpiredNotificationRemoval();
  }

  private disposeNotifications() {
    this._userNotifications = [];
  }

  private loadNotifications() {
    this.getUserNotifications().then((notificationData) => this.incomingNotificationsHandler(notificationData));
  }
}
