'use strict';

angular
  .module('insitesApp.home')
  .component('noticeCustom', {
    templateUrl: require('./noticeCustom.html'),
    controller: () => { },
    controllerAs: 'vm',
    bindings: {
      model: '=',
      form: '<',
      offsetDate: '<',
      minDate: '<',
      changeCallback: '&',
      maxlength: '<',
    },
  });
