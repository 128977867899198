'use strict';

import { ActivityService } from '../../../../../../core/dataservices/activity.service';
import { ClipboardService } from '../../../../../../core/services/clipboard.service';

export class PublishUrlController {
  static $inject = ['activityservice', 'ClipboardService', '$stateParams'];

  url = '';
  taskId = 0;
  showProgress = false;
  taskStatusMessage = '';
  updateSucceeded = true;
  squareActivity;
  constructor(
    private activityservice: ActivityService,
    private clipboardService: ClipboardService,
    private $stateParams: ng.ui.IStateParamsService,
  ) {
  }

  async $onInit() {
    this.activityservice.getActivityDetails(this.$stateParams.activityGuid).then((response) => {
      this.url = response.data.ActivityUri;
    });
  }

  copyToClipboard() {
    this.clipboardService.copyToClipboard(this.url);
  }
}
