'use strict';

import * as _ from 'lodash';
import { DateTime } from 'luxon';

export type CamelCase<T> = T extends readonly unknown[] ?
  { [K in keyof T]: CamelCase<T[K]> } :
  T extends DateTime ? T : // We don't want to convert Luxon DateTime objects
    T extends object ? { [K in keyof T as Uncapitalize<Extract<K, string>>]: CamelCase<T[K]> } :
      T;

export type PascalCase<T> = T extends readonly unknown[] ?
  { [K in keyof T]: PascalCase<T[K]> } :
  T extends DateTime ? T : // We don't want to convert Luxon DateTime objects
    T extends object ? { [K in keyof T as Capitalize<Extract<K, string>>]: PascalCase<T[K]> } :
      T;

type ConvertLuxonToString<O> =
  O extends readonly unknown[] ? { [K in keyof O]: ConvertLuxonToString<O[K]> } :
    O extends DateTime ? string :
      O extends object ? {[K in keyof O as Extract<K, string>]: ConvertLuxonToString<O[K]> } : O

type ConvertStringToLuxon<O> =
  O extends readonly unknown[] ? { [K in keyof O]: ConvertStringToLuxon<O[K]> } :
    O extends string ? string | DateTime :
      O extends object ? {[K in keyof O as Extract<K, string>]: ConvertStringToLuxon<O[K]> } : O

export class Utils {
  static getEnumValueName(enumObject: { [param: string]: number }, value: number, firstLetterUpperCase = true) {
    for (const item in enumObject) {
      if (enumObject[item] === value) {
        let label = firstLetterUpperCase ? item[0].toUpperCase() : item[0];
        label += item.slice(1);
        return label;
      }
    }
    return null;
  }

  static getEnumAsArray(enumObject) {
    let arr = [];
    for (const item in enumObject) {
      arr = _.concat(arr, {
        Value: enumObject[item],
        Label: this.toInSitesSafeSentenceCase(item[0].toUpperCase() + item.slice(1)),
      });
    }
    return arr;
  }

  static getRoleEnumAsArray(roleConstants) {
    return _.map(this.getEnumAsArray(roleConstants), (role) => {
      if (role.Value === roleConstants.human8) {
        role.Label += ' Admin';
      }
      return role;
    });
  }

  static buildUrl(baseUrl: string, paramObj: any): string {
    const paramsPrefix = baseUrl.indexOf('?') >= 0 ? '&' : '?';
    const params = [];
    for (const param in paramObj) {
      if (param && paramObj[param]) {
        const isArray = Array.isArray(paramObj[param]);
        const isEmpty = paramObj[param].length === 0;
        if (isArray) {
          for (const item of paramObj[param]) {
            params.push(`${encodeURIComponent(param)}=${encodeURIComponent(item)}`);
          }

          continue;
        }
        if (!isEmpty) {
          params.push(`${encodeURIComponent(param)}=${encodeURIComponent(paramObj[param])}`);
        }
      }
    }
    return baseUrl + paramsPrefix + params.join('&');
  }

  static getRoleLabel(roleConstants, roleType: any): string {
    let roleName = Utils.getEnumValueName(roleConstants, roleType);
    roleName = this.toInSitesSafeSentenceCase(roleName);

    if (roleType === roleConstants.human8) {
      roleName += ' Admin';
    }

    return roleName;
  }

  static getSentenceCasedLabel(constantsEnum, enumValue: number): string {
    return this.toInSitesSafeSentenceCase(this.getEnumValueName(constantsEnum, enumValue));
  }

  static toInSitesSafeSentenceCase(text: string): string {
    text = text?.replace('inSites', 'insites').replace('InSites', 'Insites')
      .replace(/([a-z](?=[A-Z]))/g, '$1 ')
      .replace('Insites', 'InSites');
    return text;
  }

  static getStatusLabel(registrationStatusConstants, statusType: any, suspended: boolean, locked = false): string {
    if (locked) {
      return 'Locked';
    }
    // if Suspended and Anonymized we may "loose" the anonymized ones
    if (suspended && statusType !== registrationStatusConstants.anonymized) {
      return 'Suspended';
    }
    if (statusType === registrationStatusConstants.singleOptInNoEmail || statusType === registrationStatusConstants.singleOptInReminder) {
      statusType = registrationStatusConstants.singleOptIn;
    }
    if (statusType === registrationStatusConstants.doubleOptinReminder) {
      statusType = registrationStatusConstants.doubleOptIn;
    }
    if (statusType === registrationStatusConstants.active) {
      return 'Activated';
    }
    return Utils.getEnumValueName(registrationStatusConstants, statusType);
  }

  static getChannelTypeLabel(channelTypeConstants, communicationChannelConstants, channelType: number): string {
    switch (channelType) {
      case channelTypeConstants.communicationCard:
        return communicationChannelConstants.labelCommunicationCard;
      case channelTypeConstants.emailNewsletter:
        return communicationChannelConstants.labelEmailNewsletter;
      case channelTypeConstants.communicationText:
        return communicationChannelConstants.labelCommunicationText;
      case channelTypeConstants.researchActivityChallenge:
        return communicationChannelConstants.labelResearchActivityChallenge;
      case channelTypeConstants.researchActivityEmail:
        return communicationChannelConstants.labelResearchActivityEmail;
      case channelTypeConstants.researchActivityReminder:
        return communicationChannelConstants.labelResearchActivityReminder;
      case channelTypeConstants.researchActivityIncomplete:
        return communicationChannelConstants.labelResearchActivityIncomplete;
      case channelTypeConstants.researchActivityChallengeHome:
        return communicationChannelConstants.labelResearchActivityChallengeHome;
      case channelTypeConstants.researchActivityMobileApp:
        return communicationChannelConstants.labelResearchActivityMobileApp;
      case channelTypeConstants.communicationNews:
        return communicationChannelConstants.labelCommunicationNews;
      case channelTypeConstants.notificationUpdate:
        return communicationChannelConstants.labelNotificationUpdate;
      case channelTypeConstants.communicationNewsHome:
        return communicationChannelConstants.labelCommunicationNewsHome;
      case channelTypeConstants.customAppNotification:
        return communicationChannelConstants.labelCustomAppNotification;
      default:
        return Utils.getEnumValueName(channelTypeConstants, channelType);
    }
  }

  static getGeneralCommunicationTypeLabel(generalCommunicationTypeConstants, communicationChannelConstants, generalType: number): string {
    switch (generalType) {
      case generalCommunicationTypeConstants.email:
        return communicationChannelConstants.labelCommunicationText;
      case generalCommunicationTypeConstants.homePage:
        return communicationChannelConstants.labelHomePage;
      case generalCommunicationTypeConstants.customAppNotification:
        return communicationChannelConstants.labelCustomAppNotification;
      default:
        return Utils.getEnumValueName(generalCommunicationTypeConstants, generalType, true);
    }
  }

  static getSampleStatusLabel(
    sampleStatusConstants, sampleStatusLabelConstants, samplestatus, outcomeCodeConstants,
    outcomeCodeLabelConstants, outcomeCode, removedFromTargeting: boolean): string {
    let statusLabel;
    switch (samplestatus) {
      case sampleStatusConstants.toDo:
        statusLabel = sampleStatusLabelConstants.toDoLabel;
        break;
      case sampleStatusConstants.inProgress:
        statusLabel = sampleStatusLabelConstants.inProgressLabel;
        break;
      case sampleStatusConstants.completed:
        switch (outcomeCode) {
          case outcomeCodeConstants.none:
            statusLabel = sampleStatusLabelConstants.completedLabel;
            break;
          case outcomeCodeConstants.qualified:
            statusLabel = outcomeCodeLabelConstants.qualifiedLabel;
            break;
          case outcomeCodeConstants.screened:
            statusLabel = outcomeCodeLabelConstants.screenedLabel;
            break;
          case outcomeCodeConstants.quotaFull:
            statusLabel = outcomeCodeLabelConstants.quotaFullLabel;
            break;
        }
    }

    return statusLabel + (removedFromTargeting ? ' (Removed from target)' : '');
  }

  static runAtDate(date, fn): () => void {
    let timeout;
    const runLater = () => {
      const now = (new Date()).getTime();
      const then = date.getTime();
      const diff = Math.max((then - now), 0);
      if (diff > 0x7FFFFFFF) {
        // SetTimeout limit is MAX_INT32=(2^31-1)
        timeout = setTimeout(runLater, 0x7FFFFFFF);
      } else {
        timeout = setTimeout(fn, diff);
      }
    };
    // Start
    runLater();

    return () => {
      clearTimeout(timeout);
    };
  }

  static anchorScrollWithWait(id, waitTime = 5000) {
    if (Utils.findElementAndScroll(id)) {
      return;
    }
    // Search for the element every 200ms
    const interval = setInterval(() => {
      if (Utils.findElementAndScroll(id)) {
        clearInterval(interval);
      }
    }, 200);
    // The search expires after a while
    setTimeout(() => {
      clearInterval(interval);
    }, waitTime);
  }

  private static findElementAndScroll(id) {
    const element = document.getElementById(id);
    if (element) {
      setTimeout(() => {
        try {
          element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        } catch (e) {
          element.scrollIntoView();
        }
      }, 2000);
      return true;
    }
    return false;

  }

  static reverseEnum(labels, value) {
    for (const k in labels) {
      if (labels[k] === value) {
        return k.charAt(0).toUpperCase() + k.slice(1);
      }
    }
    return '';
  }

  static stringToPascalCase(value: string): string {
    return value.replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());
  }

  static convertPascalCasedObjectToCamelCasedObject = <T>(obj: T): CamelCase<T> => {
    if (DateTime.isDateTime(obj)) {
      return obj as CamelCase<T>;
    }
    const t = Object.prototype.toString.apply(obj);
    if (t === '[object Object]') {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [_.camelCase(key).replace(/ /g, '')]: this.convertPascalCasedObjectToCamelCasedObject(obj[key as keyof typeof obj]),
        }),
        {},
      ) as CamelCase<T>;
    } else if (t === '[object Array]') {
      return (obj as unknown as unknown[]).map((v) => this.convertPascalCasedObjectToCamelCasedObject(v)) as unknown as CamelCase<T>;
    }
    return obj as CamelCase<T>;
  };

  static convertCamelCasedObjectToPascalCasedObject = <T>(obj: T): PascalCase<T> => {
    if (DateTime.isDateTime(obj)) {
      return obj as PascalCase<T>;
    }
    const t = Object.prototype.toString.apply(obj);
    if (t === '[object Object]') {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [_.startCase(_.camelCase(key)).replace(/ /g, '')]: this.convertCamelCasedObjectToPascalCasedObject(obj[key as keyof typeof obj]),
        }),
        {},
      ) as PascalCase<T>;
    } else if (t === '[object Array]') {
      return (obj as unknown as unknown[]).map((v) => this.convertCamelCasedObjectToPascalCasedObject(v)) as unknown as PascalCase<T>;
    }
    return obj as PascalCase<T>;
  };

  static getExtensionFromFilename(filename: string) {
    if (!filename) {
      return ''; // what to return in case of wrong input? I chose empty string
    }

    const i = filename.lastIndexOf('.');
    return i > 0   // for 0 case it rather means hidden file on linux like .gitignore
      ? filename.substr(i + 1)
      : '';       // if there is no . I think we return nothing rather than everything
  }

  static getNameOfStatus(status: number): string {
    switch (status) {
      case 0: return 'Legacy';
      case 1: return 'Not started';
      case 2: return 'Active';
      case 3: return 'Expires soon';
      case 4: return 'Expires soon';
      case 5: return 'Expired';
      case 6: return 'Paused';
      default: return 'New';
    }
  }

  static convertDateTimesForObject<T>(data: T): ConvertStringToLuxon<T> {
    let t = Object.prototype.toString.apply(data);
    if (!data || t !== '[object Object]') {
      return data as ConvertStringToLuxon<T>;
    }
    // Regex for iso dates e.g. 2021-08-23T08:30:50.453Z. Milliseconds are optional
    const isoDateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d{1,3})?Z$/;

    return Object.keys(data).reduce((result, key) => ({
      ...result,
      [key]: (() => {
        t = Object.prototype.toString.apply(data[key as keyof typeof data]);
        if (t === '[object String]' && (data[key as keyof typeof data] as unknown as string).match(isoDateRegex)) {
          const date = DateTime.fromISO(data[key as keyof typeof data] as unknown as string);
          if (date.isValid) {
            return date;
          }
        } else if (data[key as keyof typeof data] && t === '[object Object]') {
          return this.convertDateTimesForObject(data[key as keyof typeof data]);
        }

        return data[key as keyof typeof data];
      })(),
    }), {}) as ConvertStringToLuxon<T>;
  }

  static convertDateTimesToIsoStringForObject<T>(data: T): ConvertLuxonToString<T> {
    let t = Object.prototype.toString.apply(data);
    if (!data || t !== '[object Object]') {
      return data as ConvertLuxonToString<T>;
    }

    return Object.keys(data).reduce((result, key) => ({
      ...result,
      [key]: (() => {
        t = Object.prototype.toString.apply(data[key as keyof typeof data]);
        if (DateTime.isDateTime(data[key as keyof typeof data])) {
          return (data[key as keyof typeof data] as unknown as DateTime).toUTC().toISO();
        } else if (data[key as keyof typeof data] && t === '[object Object]') {
          return this.convertDateTimesToIsoStringForObject(data[key as keyof typeof data]);
        }

        return data[key as keyof typeof data];
      })(),
    }), {}) as ConvertLuxonToString<T>;
  }
}
