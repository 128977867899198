'use strict';

import { ActivityCompleteRedirectController } from './activityCompleteRedirect.controller';
const template = require('./activityCompleteRedirect.html');

angular
  .module('insitesApp.activityconfig')
  .component('activityCompleteRedirect', {
    templateUrl: template,
    controllerAs: 'vm',
    controller: ActivityCompleteRedirectController,
    bindings: {
      model: '=',
      isReadOnly: '<',
    },
  });
