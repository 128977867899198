'use strict';

import { SearchService } from '../../core/dataservices/search.service';
import { SelectedProgramFactory } from '../../core/selectedprogram.factory';
import * as _ from 'lodash';

export class ProgramSelectionController {
  static $inject = ['searchservice', '$stateParams', '$state', '$scope', 'selectedProgramFactory', 'pinnedItemsService', '$transitions'];
  constructor(
    private searchService: SearchService,
    private $stateParams,
    private $state,
    private $scope,
    private selectedProgramFactory: SelectedProgramFactory,
    private pinnedItemsService,
    private $transitions,
  ) {
  }
  selectedProgram = this.selectedProgramFactory;
  selectedProgramGuid: string;
  selectedClientGuid: string;
  dropdownList = [];
  icon = 'fa fa-cubes';

  async $onInit() {
    await this.pinnedItemsService.init();
    const responseList = await this.searchService.getSearchSelectionForDropdownProgram();

    if (responseList) {
      this.dropdownList = _.map(responseList, (el: any) => {
        el.isSelected = el.Guid === this.$stateParams.programGuid;
        el.IsPinned = () => this.pinnedItemsService.isPinned(el.Guid);
        return el;
      });
    }

    this.updateSelectedProgramFactory();


    // Used for updating the dropdown when updating an program name
    this.$scope.$watch(
      () => this.selectedProgramFactory.Name,
      (newValue) => {
        if (newValue) {
          const selectedFactory = this.selectedProgramFactory;
          _.each(this.dropdownList, (element) => {
            if (element.Guid === selectedFactory.Guid) {
              element.Name = newValue;
            }
          });
        }

      });

    // Used for updating the dropdown when updating an program name
    this.$scope.$watch(

      () => this.selectedProgramFactory.Guid,

      (newValue) => {
        if (newValue) {
          const selectedFactory = this.selectedProgramFactory;

          const newItem = _.find(this.dropdownList, {
            Guid: selectedFactory.Guid,
          });
          if (newItem === undefined) {
            this.dropdownList.push({
              Name: selectedFactory.Name,
              Guid: selectedFactory.Guid,
            });
            this.updateSelectedProgramFactory();
          }
        }
      });

    this.$transitions.onSuccess({}, (transition) => {
      this.selectedProgramGuid = transition.params('to').programGuid;
      this.selectedClientGuid = transition.params('to').clientGuid;
      this.updateSelectedProgramFactory();
    });
  }

  updateSelectedProgramFactory() {
    _.each(this.dropdownList, (program) => {
      if (program.Guid === this.$stateParams.programGuid) {
        this.selectedProgramFactory.setProgramInfo(
          {
            Name: program.Name,
            Guid: program.Guid,
          });
        this.selectInList(program.Guid);
      }
    });
  }

  selectProgram(program) {
    if (program === 'current') {
      if (this.$stateParams.programGuid) {
        this.$state.go('root.programsquares',
          { clientGuid: this.$stateParams.clientGuid, programGuid: this.$stateParams.programGuid },
          { reload: 'root.programsquares' });
      } else if (this.$stateParams.networkGuid) {
        this.$state.go('root.networkinfo',
          { clientGuid: this.$stateParams.clientGuid, networkGuid: this.$stateParams.networkGuid },
          { reload: 'root.networkinfo' });
      }
    } else {
      this.selectInList(program.Guid);
      this.$state.go('root.programsquares',
        { clientGuid: this.$stateParams.clientGuid, programGuid: program.Guid },
        { reload: 'root.programsquares' });
      this.selectedProgramFactory.setProgramInfo(
        {
          Name: program.Name,
          Guid: program.Guid,
        });

    }
  }

  selectInList(selectedGuid) {
    _.each(this.dropdownList, (element: any) => {
      if (element.isSelected) {
        element.isSelected = false;
      }
      if (element.Guid === selectedGuid) {
        element.isSelected = true;
      }
    });
  }
}
