'use strict';

import { ServerConstants } from '../../../core/serverconstants';
import { SquareService } from '../../../core/dataservices/square.service';
import * as _ from 'lodash';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { AuthService } from 'src/app/core/dataservices/auth.service';

const tabTemplates = {
  footercustom: require('../commonTabTemplates/tabtemplate.html'),
};

export class SquareConfigFooterController implements ng.IOnInit {
  static $inject = ['featureservice', 'squareservice', 'serverConstants', 'authService', '$scope'];
  constructor(
    private featureService: FeatureService,
    private squareservice: SquareService,
    private serverConstants: ServerConstants,
    private authService: AuthService,
    private $scope: ng.IScope) {
    this.pageTypeConstants = serverConstants.pageTypeConstants;
    this.area = serverConstants.areaConstants.footer;
  }

  footerRestrictionsEnabled: boolean;
  footerPageRestricted: boolean;
  isInsitesUser: boolean;
  isFullDev: boolean;
  pageTypeConstants;
  area;
  pageTypeDetails = [];

  async $onInit() {
    this.pageTypeDetails = [
      {
        Label: 'Custom',
        syncService: this.squareservice.syncPage('FooterCustom'),
        required: false,
        onlyOneAllowed: false,
        pageType: this.pageTypeConstants.footerCustom,
      },
      {
        Label: 'Saving Up Reward Policy',
        syncService: this.squareservice.syncPage('FooterCustom'),
        required: false,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.savingUpRewardPolicy,
      },
      {
        Label: 'Reward Policy',
        syncService: this.squareservice.syncPage('FooterCustom'),
        required: false,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.rewardPolicy,
      },
      {
        Label: 'Cookie Settings',
        syncService: this.squareservice.syncPage('FooterCustom'),
        required: true,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.myCookieSettings,
      },
      {
        Label: 'Cookie Policy',
        syncService: this.squareservice.syncPage('FooterCustom'),
        required: false,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.cookiePolicy,
      },
    ];
    await this.init();
  }

  async init(){
    this.footerRestrictionsEnabled = await this.featureService.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.footerRestrictions);
    this.isFullDev = this.authService.isFullDev();
    // When the feature toggle is on, the restrictions are for everyone except full dev
    if(this.footerRestrictionsEnabled){
      this.footerPageRestricted = !this.isFullDev;
    }
  }

  getTemplateForPage = (page) => {
    const name = _.findKey(this.pageTypeConstants, (c) => (c === this.getPageTypeForTemplate(page.PageType)));
    return tabTemplates[name.toLowerCase()];
  };

  /*
   * RewardPolicy & SavingUpRewardPolicy types were added to be able to know which pages are used for both without having to use the name
   * Both are still footer pages and should be treated as such to make sure everything still works as before
   */
  getPageTypeForTemplate(pageType) {
    if (pageType === this.pageTypeConstants.footerCustom ||
      pageType === this.pageTypeConstants.rewardPolicy ||
      pageType === this.pageTypeConstants.savingUpRewardPolicy ||
      pageType ===  this.pageTypeConstants.myCookieSettings ||
      pageType === this.pageTypeConstants.cookiePolicy) {
      return this.pageTypeConstants.footerCustom;
    }
    return pageType;
  }

  addPage() {
    this.$scope.$broadcast('addPage');
  }
}
