'use strict';

import { MemberDetailsIncentivesController } from './memberDetailsIncentives.controller';
const template = require('./memberDetailsIncentives.html');

angular.module('insitesApp.squaremembers.memberOverview').component('memberDetailsIncentives', {
  controller: MemberDetailsIncentivesController,
  controllerAs: 'vm',
  templateUrl: template,
});
