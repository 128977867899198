'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.forum.room.conversation',
      config: {
        url: '/conversation/:conversationGuid?page?sort?replyGuid?limit?query',
        component: 'forumConversationComponent',
        title: 'Conversation',
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

