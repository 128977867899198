import * as angular from 'angular';
var IscWizardSubstepController = (function () {
    function IscWizardSubstepController() {
        this.state = undefined;
    }
    IscWizardSubstepController.prototype.$onInit = function () {
        this.stepComponent.addSubstep(this);
    };
    IscWizardSubstepController.prototype.$onDestroy = function () {
        this.stepComponent.removeSubstep(this);
    };
    IscWizardSubstepController.prototype.loadData = function () {
        return this.forwardToLinkedComponent('$loadData');
    };
    IscWizardSubstepController.prototype.isCompleted = function () {
        return this.forwardToLinkedComponent('$wizardStepIsCompleted');
    };
    IscWizardSubstepController.prototype.isValid = function () {
        return this.forwardToLinkedComponent('$wizardIsValid', true);
    };
    IscWizardSubstepController.prototype.linkComponent = function (name, component) {
        this.component = component;
        return this.wizardComponent.linkStep(this, name, component);
    };
    IscWizardSubstepController.prototype.resolveComponent = function (name) {
        return this.wizardComponent.resolveStep(name);
    };
    IscWizardSubstepController.prototype.nextDescription = function () {
        return this.forwardToLinkedComponent('$wizardNextDescription');
    };
    IscWizardSubstepController.prototype.nextLabel = function () {
        return this.forwardToLinkedComponent('$wizardNextLabel');
    };
    IscWizardSubstepController.prototype.previousLabel = function () {
        return this.forwardToLinkedComponent('$wizardPreviousLabel');
    };
    IscWizardSubstepController.prototype.onBeforeNextStep = function () {
        return this.forwardToLinkedComponent('$wizardBeforeNext');
    };
    IscWizardSubstepController.prototype.onBeforePreviousStep = function () {
        return this.forwardToLinkedComponent('$wizardBeforePrevious');
    };
    IscWizardSubstepController.prototype.onStepLoaded = function () {
        return this.forwardToLinkedComponent('$wizardStepLoaded');
    };
    IscWizardSubstepController.prototype.forwardToLinkedComponent = function (methodName, defaultValue) {
        if (typeof this.component === 'undefined') {
            return defaultValue;
        }
        var method = this.component[methodName];
        if (!angular.isFunction(method)) {
            return defaultValue;
        }
        return method.apply(this.component);
    };
    IscWizardSubstepController.prototype.getState = function () {
        if ((this.position - 1) < this.stepComponent.currentStepIndex) {
            this.state = this.wizardComponent.constants.state.complete;
        }
        else if ((this.position - 1) === this.stepComponent.currentStepIndex) {
            this.state = this.isCompleted() ? this.wizardComponent.constants.state.current_complete : this.wizardComponent.constants.state.current_incomplete;
        }
        else if ((this.position - 1) === (this.wizardComponent.nextHoverableSubstepIndex)) {
            this.state = this.wizardComponent.constants.state.next;
        }
        else {
            this.state = this.isCompleted() ? this.wizardComponent.constants.state.complete : this.wizardComponent.constants.state.incomplete;
        }
        return this.state;
    };
    return IscWizardSubstepController;
}());
export { IscWizardSubstepController };
