import { IscTextareaController } from './isc-textarea.controller';
import { UiModule } from '../../../index';
var iscTextAreaTemplate = "<md-input-container  md-no-float>\n  <label ng-if=\"vm.label\" class=\"md-no-float isc-textarea-label\">{{vm.label}}</label>\n    <textarea\n      ng-model=\"vm.model\"\n      ng-attr-max-rows=\"{{vm.maxRows}}\"\n      ng-attr-rows=\"{{vm.rows}}\"\n      ng-attr-name=\"{{vm.name}}\"\n      md-maxlength=\"vm.maxlength\"\n      ng-attr-md-select-on-focus=\"{{vm.selectOnFocus}}\"\n      ng-focus=\"vm.onFocus()\"\n      ng-disabled=\"vm.isDisabled\"\n      ng-blur=\"vm.onBlur()\">\n    </textarea>\n  <div ng-show=\"vm.ngModelController.$touched\" md-auto-hide=\"false\" ng-messages=\"vm.ngModelController.$error\" ng-transclude=\"errors\">\n  </div>\n</md-input-container>";
UiModule.component('iscTextarea', {
    template: iscTextAreaTemplate,
    controllerAs: 'vm',
    controller: IscTextareaController,
    bindings: {
        label: '@',
        name: '@',
        autofocus: '@',
        selectOnFocus: '@',
        rows: '@',
        maxRows: '@',
        maxlength: '<',
        model: '=',
        onBlur: '&',
        isDisabled: '<',
    },
    transclude: {
        errors: '?iscTextareaErrors',
    },
});
