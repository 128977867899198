'use strict';

import { SquareConfigHelpCenterController } from './helpCenter.controller';

const template = require('./helpCenter.html');

export const SquareConfigHelpCenterComponent: ng.IComponentOptions = {
  controller: SquareConfigHelpCenterController,
  templateUrl: template,
  controllerAs: 'vm',
};
