'use strict';

import { LogController } from './log.controller';

const template = require('./log.html');

export const LogComponent: ng.IComponentOptions = {
  controller: LogController,
  templateUrl: template,
  controllerAs: 'vm',
};
