'use strict';

const publishRewardsTemplate = require('./publishRewards.html');

angular
  .module('insitesApp.activityconfig')
  .component('publishRewards', {
    templateUrl: publishRewardsTemplate,
    controllerAs: 'vm',
    bindings: {
      rewards: '<',
      rewardsAt: '<',
      offset: '<',
      squareGuid: '<',
    },
  });
