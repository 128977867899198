'use strict';

import * as angular from 'angular';

import routeConfig from './activitiesQuantTab.route';
import { activitiesQuantTab } from './activitiesQuantTab.component';

angular
  .module('insitesApp.squareActivities')
  .component('activitiesQuantTab', activitiesQuantTab)
  .run(routeConfig);


