'use strict';

import _ = require('lodash');
import { JobService, IJobDetails } from './job.service';

export class JobStatusChecker {
  private pollPromise;
  jobDetails: IJobDetails;
  result;
  loading: boolean;

  constructor(
    private jobId: string,
    private jobService: JobService,
    private $timeout: ng.ITimeoutService,
  ) {
  }

  public async startPollingJobStatus(callback: () => void = null) {
    this.loading = true;
    this.pollJobStatus(callback);
    this.loading = false;
  }

  public async stopPollingJobStatus() {
    if (this.pollPromise) {
      this.$timeout.cancel(this.pollPromise);
      this.pollPromise = null;
    }
  }

  private get isJobInProgress() {
    return this.loading || this.jobDetails && !_.includes(['Failed', 'Succeeded', 'Deleted'], this.jobDetails.State);
  }

  private async pollJobStatus(callback: () => void = null) {
    this.jobDetails = await this.jobService.getJobDetails(this.jobId);
    if (this.jobDetails && this.jobDetails.Result) {
      this.result = JSON.parse(this.jobDetails.Result);
    }
    if (!this.isJobInProgress) {
      this.pollPromise = null;
      if (callback) {
        callback();
      }
      return;
    }

    this.pollPromise = this.$timeout(() => {
      this.pollJobStatus(callback);
    }, 1000);
  }
}
