'use strict';

import { BackgroundVariableEditor } from './backgroundVariableEditor.controller';

const template = require('./backgroundVariableEditor.html');

export const BackgroundVariableEditorComponent: ng.IComponentOptions = {
  controller: BackgroundVariableEditor,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    variables: '=',
    partner: '<',
    onEditVariable: '&',
    onCloseEditedVariable: '&',
    onEditValue: '&',
    onCloseEditedValue: '&',
    isDisabled: '=',
  },
};
