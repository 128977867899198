'use strict';

import { ForumService } from '../../../core/dataservices/forum.service';
import { BaseSharer } from './basesharer';
import * as _ from 'lodash';

export class Copy extends BaseSharer {
  constructor(
    private forumservice: ForumService,
    private config: {
      isOnHighlightedElement: boolean,
      highlightedElementsCommonIdentifier?: string,
      highlightingElement?: any,
    }) {
    super();
  }
  protected renderMenuButton(text, rawText, refUrl) {
    return `<a title="Copy" data-quote="${rawText}" href="${refUrl}" target="_blank" rel="noopener nofollow noreferrer">
              <i class="fal fa-quote-right"></i></a>`;
  }

  protected async menuButtonClick(event, item) {
    event.preventDefault();
    const li = item.firstChild;
    let selectedSnippet = li.getAttribute('data-quote');
    if (this.config.highlightedElementsCommonIdentifier) {
      selectedSnippet = '';
      const highlightedSpans = document.querySelectorAll(`.snippet-highlightable span.snippet-highlighted.${this.config.highlightedElementsCommonIdentifier}`);
      _.each(highlightedSpans, (span: any) => selectedSnippet += span.innerText);
    }
    const placeholder = document.createElement('input');
    document.body.appendChild(placeholder);
    const copySuffix = await this.prepareCopyingSnippetSuffix();
    placeholder.value = `\"${selectedSnippet.replace(/^\s+|\s+$/g, '')}\" ${copySuffix}`; // eslint-disable-line no-useless-escape
    placeholder.select();
    let succeed;
    try {
      succeed = document.execCommand('copy');
    } catch (e) {
      succeed = false;
    } finally {
      document.body.removeChild(placeholder);
    }
    return succeed;
  }

  private name = 'copy';

  private async prepareCopyingSnippetSuffix(): Promise<string> {
    const highlightingElement = this.config.highlightingElement;
    const squareParticipantId = highlightingElement.getAttribute('data-squareparticipantid');
    const squareParticipantGuid = highlightingElement.getAttribute('data-squareparticipantguid');
    const memberDetails = await this.forumservice.getMemberDetails(squareParticipantGuid);
    const keySegmentations = memberDetails.SegmentationsWithSegments;
    let copySuffix: string[] = [];
    copySuffix = copySuffix.concat([`ID: ${squareParticipantId}`], keySegmentations.map((item) => item.Segments));
    return copySuffix.join(', ');
  }

  public toSharer() {
    return {
      name: this.name,
      render: (text, rawText, refUrl) => this.renderMenuButton(text, rawText, refUrl),
      action: async (event, item) => await this.menuButtonClick(event, item),
    };
  }
}
