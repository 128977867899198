'use strict';

export class Criteria {
  Label: string;
  CriteriaType: number;
  Value: number = null;
  DefaultValue: number;
  Checked = false;

  constructor(label: string, criteriaType: number, defaultValue: number) {
    this.Label = label;
    this.CriteriaType = criteriaType;
    this.DefaultValue = defaultValue;
  }
}
