'use strict';

export class ColorValidator implements ng.IDirective {
  validationRegex = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');

  restrict = 'A';
  require = 'ngModel';
  link(scope, element, attrs, ngModel) {
    ngModel.$validators.colorValidator = () => {
      const currentValue = ngModel.$viewValue;
      let result = true;
      if (attrs.required && !currentValue) {
        result = false;
      } else if (currentValue) {
        const matches = currentValue.match(this.validationRegex);
        if (!matches || matches.length === 0) {
          result = false;
        }
      }
      return result;
    };
  }
}
