'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.health',
      config: {
        url: '/health',
        component: 'squareHealthComponent',
        title: 'Health',
        settings: {
          nav: 7,
          icon: 'fa fa-heart-pulse',
          label: 'Health',
          section: 'SQUARE MANAGEMENT',
          showForums: true,
        },
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

