'use strict';

import { ServerConstants } from '../../../core/serverconstants';
import { SquareChangeType } from './squareChangeType';
import { ISavingUpModel } from './savingUpModel';
import { DateFormatService } from '../../../core/services/dateformat.service';
require('./squareInfoChangeConfirm.scss');

export class SquareInfoChangeConfirmController {
  static $inject = ['squareChangeTypes', 'squareName', 'closureMessage', 'savingUpModel',
    '$mdDialog', 'serverConstants', '$stateParams', '$scope', '_', 'dateFormatService'];
  constructor(
    public squareChangeTypes: SquareChangeType[],
    public squareName,
    public closureMessage,
    public savingUpModel: ISavingUpModel,
    private $mdDialog: ng.material.IDialogService,
    private serverConstants: ServerConstants,
    private $stateParams: ng.ui.IStateParamsService,
    private $scope: ng.IScope,
    private _: _.LoDashStatic,
    private dateFormatService: DateFormatService,
  ) {
    this.activate();
  }
  raffleType = this.serverConstants.raffleTypeConstants.savingUp;
  squareGuid: string;
  form: ng.IFormController;

  private activate() {
    if (this.savingUpModel.isSavingUpRewardsEnabled) {
      this.$scope.$watch('vm.savingUpModel', () => {
        this.form.$setValidity('notAllCurrenciesHaveValue',
          this._.filter(this.savingUpModel.rewards, (reward) => reward.IsNew).every((r) => r.Value != null), this.form);
      }, true);
    }

    this.squareGuid = this.$stateParams.squareGuid;
  }

  cancel() {
    return this.$mdDialog.hide(false);
  }

  confirm() {
    return this.$mdDialog.hide(true);
  }

  get isChangeUrl(): boolean {
    return this.squareChangeTypes.indexOf(SquareChangeType.ChangeUrl) >= 0 || this.isChangeUrlToPrevious;
  }

  get isChangeUrlToPrevious(): boolean {
    return this.squareChangeTypes.indexOf(SquareChangeType.ChangeUrlToPrevious) >= 0;
  }

  get isOpenSquare(): boolean {
    return this.squareChangeTypes.indexOf(SquareChangeType.OpenSquare) >= 0;
  }

  get isCloseSquare(): boolean {
    return this.squareChangeTypes.indexOf(SquareChangeType.CloseSquare) >= 0;
  }

  get isNewCurrency(): boolean {
    return this.squareChangeTypes.indexOf(SquareChangeType.ChangeCountryRequiresCreditValue) >= 0;
  }
}
