'use strict';

import { Pagination } from '../../core/models/pagination';

export class OverviewPaginationController {
  static $inject = [];
  constructor() {
  }
  onPaginate;
  defaultLimitOptions: number[] = Pagination.defaultLimitOptions;
  onPaging(page) {
    if (this.onPaginate) {
      this.onPaginate({ page });
    }
  }
}
