'use strict';

import { HttpService } from './http.service';
import { ServerConstants } from '../serverconstants';
import { IHttpResponse } from 'angular';
import _ = require('lodash');
import { SelectedClientFactory } from '../selectedclient.factory';
import * as UpChunk from '@mux/upchunk';
import { BeaconService } from './beacon.service';
import { Utils } from '../utils';
import { MediaService } from '../services/media.service';

export class MuxService {
  static $inject = [
    'logger',
    'httpservice',
    'serverConstants',
    'selectedClientFactory',
    'beaconService',
    '$window',
    '$location',
    'mediaService',
  ];

  /*
      How this works
        1. user chooses a video file when posting
        2. frontend will call backend to create a new link for the upload (it could have happened in the frontend too, but)
            this is mainly because
              - we have sensitive credentials for Mux (API key and token) and there`s no need to throw them to the client side
              - we would require entries in databases too, so that the DB can associate Mux ID to the video upload
        3. backend will return an {id, url} object
        4. frontend will execute raw PUT command on the url with the byte array of the file
              - background (no await) execution is recommended
  */

  private _stimuliUploads: IStimulusProgress[] = [];
  private _videoStorage: number = undefined;

  constructor(
    private logger: Logger,
    private httpService: HttpService,
    private serverConstants: ServerConstants,
    private selectedClientFactory: SelectedClientFactory,
    private beaconService: BeaconService,
    private $window: ng.IWindowService,
    private $location: ng.ILocationService,
    private mediaService: MediaService,
  ) {
    this.$window.addEventListener('beforeunload', (ev) => {
      // If at least one upload is in progress, prevent browser from closing the tab
      if (this._stimuliUploads && _.find(this._stimuliUploads, (s) => s.progress !== 100)) {
        // Cancel the event
        ev.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        ev.returnValue = '';
      }
    });
    const self = this;
    // the code below is based on
    // https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
    // it could be refactored and moved into the beacon service
    this.$window.document.addEventListener('visibilitychange', () => {
      if ($window.document.visibilityState === 'hidden') {
        if (self._stimuliUploads && self._stimuliUploads.length > 0) {
          const unfinishedUploads = _.filter(self._stimuliUploads, (s) => s.progress !== 100);
          if (unfinishedUploads && unfinishedUploads.length > 0) {
            // this will give false positives, so let's call it a warning
            self.logWarningOnBackend(_.map(unfinishedUploads, (e) => e.stimulusValue));
          }
        }
      }
    });
  }

  getSource(): string {
    return `mux.service @ ${this.$location.absUrl()}`;
  }

  // Is Mux service enabled for this client or not (if not, Azure Media Services is in place)
  get MuxServiceEnabled() {
    const videoStorage = this._videoStorage !== undefined
      ? this._videoStorage
      : this.selectedClientFactory?.VideoStorage;
    return videoStorage && videoStorage === this.serverConstants.clientVideoStorageConstants.mux;
  }

  set MuxServiceEnabled(value: boolean) {
    this._videoStorage = value
      ? this.serverConstants.clientVideoStorageConstants.mux
      : this.serverConstants.clientVideoStorageConstants.azureMediaServices;
  }

  // Upload a specific video to the Mux service using the URL from the getNewUploadLinkForVideo
  // Callers should not wait for this to happen, so that the upload is going to happen in background
  public uploadVideo(_muxData: IVideoLink, _file: File) {

    /*
      This is really stupid: if _file is not a File, but a Blob, UpChunk does not upload
    */
    if (!_file.lastModified) {
      _file = new File([_file], _file.name);
    }

    this._stimuliUploads.push({
      stimulusValue: _muxData.Id,
      progress: 0,
    });
    const upload = UpChunk.createUpload({
      endpoint: _muxData.Url,
      file: _file,
      chunkSize: 5120,
    });

    // log size and extension for everything
    // this will be a second point of comparison for how many succeeded(if the record timestamps will also be updated)
    this.logStatisticsOnBackend({
      uploadId: _muxData.Id,
      sizeInMB: this.convertBToMB(_file.size), // File.size is in Bytes
      extension: Utils.getExtensionFromFilename(_file.name),
      mimeType: _file.type,
      isMobile: this.mediaService.isMobile(), // of course the services are not the same on both UIs
      isMobileApp: false, // we do not have an app for the admin
    });

    let resolve: () => void;
    let reject: () => void;
    const promise = new Promise<void>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    upload.on('error', () => {
      // Remove stimulus from the list with stimuli upload in progress
      this.removeStimulusFromUploadingList(_muxData.Id);
      this.logger.error('Upload failed.');
      this.logErrorOnBackend(_muxData.Id);
      reject();
    });
    upload.on('success', () => {
      resolve();
    });
    upload.on('progress', (progress) => {
      // Uploaded {progress.detail} percent
      const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === _muxData.Id)[0];
      if (stimulusUpload && progress.detail > stimulusUpload.progress) {
        // Oddly, the progress can jump backwards too, so we make sure we only show the progress increase to the user
        stimulusUpload.progress = progress.detail;
      }
    });

    return promise;
  }

  convertBToMB(size: number) {
    return size / 1024 / 1024;
  }

  async logErrorOnBackend(uploadId: string) {
    const uploadData = {
      source: this.getSource(),
      message: `Upload failed on client for uploadId: ${uploadId}`,
    };
    return this.httpService.post({
      url: '/ConversationService/LogErrorFromClient',
      data: uploadData,
    });
  }

  async logWarningOnBackend(uploadIds: string[]) {
    const uploadIdsString = uploadIds.join('; ');
    const uploadData = {
      source: this.getSource(),
      message: `Upload warning (tab may have closed) on client for uploadIds: ${uploadIdsString}`,
    };
    // since the tab may have been closed, we use the beacon
    return this.beaconService.sendBeacon(
      '/ConversationService/LogAnonymousErrorFromClient',
      uploadData);
  }

  async logStatisticsOnBackend(statistics: IVideoStatistics) {
    return this.httpService.post({
      url: '/PlatformHealthService/LogVideoStatisticsFromClient',
      data: statistics,
    });
  }

  // Get a new upload link for a new video stimuli so that we can directly PUT the contents there
  // If successful, it is returning an ID which can be cancelled and a URL which we can use to directly
  // PUT raw data, no questions asked
  public async getNewUploadLinkForVideo(): Promise<IHttpResponse<IVideoLink>> {
    // Mux does not care about our file`s mime type
    return this.httpService.post<IVideoLink>({
      url: '/ConversationService/GetNewUploadLinkForVideo?mimeType=',
    });
  }

  public async cancelUploadForVideo(_muxData: IVideoLink): Promise<IHttpResponse<any>> {
    return this.httpService.post({
      url: '/ConversationService/CancelUploadLinkForVideo',
      data: _muxData,
    });
  }

  public async processAndUploadStimuliBeforePost(stimuli: any[]): Promise<any[]> {
    let result = stimuli;
    // If the service is enabled and we have stimuli entries
    if (this.MuxServiceEnabled
      && stimuli && stimuli.length > 0) {

      result = [];
      for (const stimulus of stimuli) {
        // If we are processing a video which was just uploaded (file exists)
        if (stimulus.TypeName
          && stimulus.TypeName === 'video'
          && stimulus.File) {
          // Lets get ourselves a new upload link
          const videoData = await this.getNewUploadLinkForVideo();
          if (videoData.data && videoData.data.Url) {
            // We have permission to PUT this file to a new URL, yehaaaa
            this.uploadVideo(videoData.data, stimulus.File);
            // Now, we tell the backend not to upload file and that we have a mux id
            stimulus.File = undefined;
            stimulus.Id = videoData.data.Id;
          }
        }
        result.push(stimulus);
      }
    }
    return result;
  }

  public getStimulusUploadProgress(stimulusValue: string) {
    const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
    return stimulusUpload ? stimulusUpload.progress : null;
  }

  public removeStimulusFromUploadingList(stimulusValue: string): void {
    // Remove stimulus from the list with stimuli upload in progress
    const stimulusUpload = this._stimuliUploads.filter((s) => s.stimulusValue === stimulusValue)[0];
    if (stimulusUpload) {
      this._stimuliUploads = this._stimuliUploads.filter((s) => s.stimulusValue !== stimulusValue);
    }
  }
}

export interface IVideoLink {
  Id: string;
  Url: string;
  StimuliGuid?: string;
  IsDiscussionNew?: boolean;
}

export interface IStimulusProgress {
  stimulusValue: string;
  progress: number;
}

interface IVideoStatistics {
  uploadId: string;
  sizeInMB: number;
  extension: string;
  mimeType: string;
  isMobile: boolean;
  isMobileApp: boolean;
}


