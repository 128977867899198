'use strict';

import { SquareConfigDesignColorsController } from './colors.controller';
import { ColorValidator } from './colorValidator.directive';
const template = require('./colors.html');

angular
  .module('insitesApp.squareconfig.design.colors')
  .component('squareConfigColors', {
    templateUrl: template,
    controller: SquareConfigDesignColorsController,
    controllerAs: 'vm',
    bindings: {
      squareDesignData: '<',
    },
  })
  .directive('colorValidator', () => new ColorValidator());
