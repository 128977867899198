'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.info',
      config: {
        url: '',
        component: 'squareInfo',
        title: 'Square info',
        settings: {
          nav: 1,
          section: 'SQUARE SETTINGS',
          icon: 'fa fa-gauge',
          label: 'Square info',
          showForums: true,
        },
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

