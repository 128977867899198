'use strict';

import { CurrentPageInformation } from '../../core/services/currentPageInformation';
import { ActivitySequenceService } from './activitySequenceConfirm/activitysequence.service';
import * as _ from 'lodash';

/*
   After changing the logic in this class,
   run 'checkTestCases' from 'SquareActivitiesDragServiceTests' located in './squareActivitiesDrag.tests.ts'
*/
export class SquareActivitiesDragService {
  static $inject = ['activitySequenceService', '$q'];
  constructor(
    private activitySequenceService: ActivitySequenceService,
    private $q: ng.IQService,
  ) {

  }

  private minMoveTresholdPixels = 60;
  private filteredActivities;
  private isUpOrDownMove: boolean = false;
  private stopScrolling: boolean = true;
  private maincontent = document.getElementById('maincontent');

  getDragOptions(loadActivitiesCallback: () => void, getPageInfo: () => CurrentPageInformation) {
    return {
      accept: () => true,
      beforeDrag: (sourceNodeScope) => !sourceNodeScope.$modelValue.IsParent,
      removed: () => {
      },
      dropped: async () => {
        this.stopScrolling = true;
      },
      dragStart: (event) => {
        // Reset moving flag to default
        this.isUpOrDownMove = false;
        const pageInfo: CurrentPageInformation = getPageInfo();
        const activities = _.clone(event.dest.nodesScope.$modelValue);
        this.filterActivities(activities, pageInfo);
        this.filteredActivities = activities;
        this.resolveDropPositionOnThePlaceholder(event);
      },
      dragMove: (event) => {
        this.isUpOrDownMove = true;
        const params = this.resolveDropParameters(event, true);
        // If dragging in a place where it should be dropable
        if (!(!params && event.dest.index === 0) && (!params && event.source.index !== event.dest.index)) {
          event.elements.placeholder[0].children[0].classList.add('move-disabled');
          event.elements.placeholder[0].classList.add('move-disabled');
        } else {
          this.resolveDropPositionOnThePlaceholder(event, params ? params.childOrder : 0);
          event.elements.placeholder[0].children[0].classList.remove('move-disabled');
          event.elements.placeholder[0].classList.remove('move-disabled');
        }

        // If it's a removing from a sequence then add the removing class to the placeholder
        if ((!params && event.dest.index === 0) || (params && !params.parentGuid)) {
          event.elements.placeholder[0].children[0].classList.add('removing');
          event.elements.placeholder[0].classList.add('removing');
        } else {
          this.resolveDropPositionOnThePlaceholder(event, params ? params.childOrder : 0);
          event.elements.placeholder[0].children[0].classList.remove('removing');
          event.elements.placeholder[0].classList.remove('removing');
        }

        this.stopScrolling = true;

        if (event.pos.nowY < 200) {
          this.stopScrolling = false;
          this.scrolling(-1);
        }

        if (event.pos.nowY > (this.maincontent.offsetHeight - 50)) {
          this.stopScrolling = false;
          this.scrolling(1);
        }

      },
      dragStop: () => {
        this.stopScrolling = true;
      },
      beforeDrop: async (event) => {
        try {
          const params = this.resolveDropParameters(event);
          if (!params && event.dest.index !== 0) {
            return this.$q.reject();
          }

          const result
            = await this.onActivitySequenced(
              params.parentGuid,
              params.childGuid,
              params.childOrder,
              params.isSequencedActivity,
              loadActivitiesCallback);

          if (result) {
            return this.$q.resolve();
          }
          // If the drag was canceled we have to return a rejected promise
          // This way the draging element will be moved back automatically to the initial position in the tree
          return this.$q.reject();

        } catch {
          return this.$q.reject();
        }
      },
      toggle: () => {
      },
    };
  }

  private scrolling(step) {
    this.maincontent.scrollTo({
      left: 0,
      top: this.maincontent.scrollTop + step,
    });
    if (!this.stopScrolling) {
      const that = this;
      setTimeout(() => that.scrolling(step), 20);
    }
  }

  private resolveDropPositionOnThePlaceholder = (event, childOrder: number = 0) => {
    // Ensure the inner div is added as a child element
    // That one is styled to highlight the drop position and order
    if (event.elements.placeholder[0].children[0].children.length === 0) {
      event.elements.placeholder[0].children[0].appendChild(document.createElement('div'));
    }

    const defaultPlaceholderMarginLeft = 30;
    const sourceActivity = this.filteredActivities[event.source.index];
    let currentChildOrder = childOrder;
    if (event.source.index === event.dest.index && sourceActivity.ChildOrder) {
      currentChildOrder = sourceActivity.ChildOrder;
    }

    event.elements.placeholder[0].children[0].children[0].classList.value = '';
    if (currentChildOrder === 0) {
      this.isUpOrDownMove = false;
      event.elements.placeholder[0].children[0].children[0].classList.value = 'position-zero';
    }

    const marginLeft = defaultPlaceholderMarginLeft + (defaultPlaceholderMarginLeft * currentChildOrder);
    event.elements.placeholder[0].children[0].children[0].setAttribute('style', `margin-left:${marginLeft.toString()}px;`);
  };

  private filterActivities(activities: any[], pageInfo: CurrentPageInformation) {
    if (activities.length > 0) {
      const previousPagesActivitesCount = (pageInfo.pagination.page - 1) * pageInfo.pagination.limit;
      if (previousPagesActivitesCount > 0 && activities.length > previousPagesActivitesCount) {
        activities.splice(0, previousPagesActivitesCount);
      }
    }
  }

  private async onActivitySequenced(parentGuid, childGuid, childOrder, isSequencedActivity: boolean, loadActivitiesCallback: () => void): Promise<boolean> {
    try {
      const result = await this.onDropAction(parentGuid, childGuid, childOrder, isSequencedActivity);
      if (result) {
        loadActivitiesCallback();
      }

      return result;
    } catch {
      loadActivitiesCallback();
    }
  }

  private async onDropAction(parentGuid, childGuid, childOrder, isSequencedActivity: boolean): Promise<boolean> {
    if (parentGuid) {
      return await this.activitySequenceService.addActivityToSequence(parentGuid, childGuid, childOrder, isSequencedActivity);
    }
    return await this.activitySequenceService.removeActivityFromSequence(childGuid);

  }

  // eslint-disable-next-line complexity
  private resolveDropParameters(event, enforceRightMoveWhenSamePosition: boolean = false): { parentGuid?, childGuid?, childOrder?, isSequencedActivity?: boolean } {
    const sourceIndex = event.source.index;
    const destIndex = event.dest.index;
    const isRightMove = event.pos.startX + this.minMoveTresholdPixels <= event.pos.nowX;
    const isSamePositionMove = sourceIndex === destIndex && sourceIndex > 0;
    const sourceActivity = this.filteredActivities[sourceIndex];
    let destActivity = this.filteredActivities[destIndex];
    let childOrderAddValue = 0;

    const isSequenced = (activity): boolean => activity.IsParent || !!activity.ParentGuid;

    if (this.isUpOrDownMove && isSamePositionMove && (isRightMove || !enforceRightMoveWhenSamePosition)) {
      destActivity = this.filteredActivities[destIndex - 1];

      // Moved right under an unsequenced activity so new sequence will be created
      if (!sourceActivity.ParentGuid && !isSequenced(destActivity) && destActivity.CouldBeParent) {
        return { parentGuid: destActivity.Guid, childGuid: sourceActivity.Guid, childOrder: 1 };
      }

      // Moved right under sequenced activity so it will be added as the last element in the sequence
      if (!sourceActivity.ParentGuid && destActivity.ParentGuid && destActivity.CouldBeParent) {
        return { parentGuid: destActivity.ParentGuid, childGuid: sourceActivity.Guid, childOrder: destActivity.ChildOrder + 1, isSequencedActivity: true };
      }
    }

    // Remove from a sequence
    if (destIndex === 0) {
      return { childGuid: sourceActivity.Guid };
    }

    if (sourceIndex !== destIndex) {
      if (sourceIndex > destIndex) {
        destActivity = this.filteredActivities[destIndex - 1];
        childOrderAddValue = 1;
      }

      if (destActivity) {
        // Dragged under another unsequenced activity so new sequence will be created
        if (!sourceActivity.ParentGuid && !isSequenced(destActivity) && destActivity.CouldBeParent) {
          return { parentGuid: destActivity.Guid, childGuid: sourceActivity.Guid, childOrder: 1 };
        }

        // Dragged in a sequence under a child
        if (!sourceActivity.ParentGuid && destActivity.ParentGuid && destActivity.CouldBeParent) {
          return { parentGuid: destActivity.ParentGuid, childGuid: sourceActivity.Guid, childOrder: destActivity.ChildOrder + 1, isSequencedActivity: true };
        }

        // Dragged in a sequence under a parent
        if (!sourceActivity.ParentGuid && destActivity.IsParent && destActivity.CouldBeParent) {
          return { parentGuid: destActivity.Guid, childGuid: sourceActivity.Guid, childOrder: 1, isSequencedActivity: true };
        }

        // Change order in a sequence: dragged under the parent
        if (sourceActivity.ParentGuid && destActivity.IsParent && destActivity.CouldBeParent &&
          sourceActivity.ParentGuid === destActivity.Guid) {
          return { parentGuid: destActivity.Guid, childGuid: sourceActivity.Guid, childOrder: 1, isSequencedActivity: true };
        }

        // Change order in a sequence: dragged under an other child
        if (sourceActivity.ParentGuid && destActivity.ParentGuid && destActivity.CouldBeParent &&
          sourceActivity.ParentGuid === destActivity.ParentGuid) {
          return {
            parentGuid: destActivity.ParentGuid,
            childGuid: sourceActivity.Guid,
            childOrder: destActivity.ChildOrder + childOrderAddValue,
            isSequencedActivity: true,
          };
        }

        // Remove from a sequence
        if (sourceActivity.ParentGuid) {
          return { childGuid: sourceActivity.Guid };
        }
      }
    }

    return null;
  }
}
