import { UiModule } from '../../../index';
import { IscSwitchController } from './isc-switch.controller';
var iscSwitchTemplate = "<md-switch\n  ng-attr-name=\"{{vm.name}}\"\n  ng-class=\"{'md-primary':!vm.disablePrimary, 'no-margin':vm.noMargin }\"\n  ng-model=\"vm.model\"\n  aria-label=\"{{vm.ariaLabel}}\"\n  ng-attr-md-autofocus=\"{{vm.autofocus}}\"\n  ng-disabled=\"vm.isDisabled\"\n  ng-change=\"vm.onValueChanged()\"\n  ng-focus=\"vm.onFocus()\"\n  ng-blur=\"vm.onBlurObject()\"\n  >\n  <span ng-show=\"vm.label\" ng-class=\"{ 'inactive': vm.isDisabled }\">{{vm.label}}</span>\n  <div class=\"tooltip\" ng-if=\"::vm.tooltip\">\n    <i class=\"fa fa-circle-info\" aria-hidden=\"true\"></i>\n    <md-tooltip  md-direction=\"right\">\n      {{vm.tooltip}}\n    </md-tooltip>\n  </div>\n</md-switch>";
UiModule
    .component('iscSwitch', {
    template: iscSwitchTemplate,
    controllerAs: 'vm',
    controller: IscSwitchController,
    bindings: {
        name: '@',
        autofocus: '@',
        isDisabled: '<',
        isChanged: '&?',
        model: '=',
        onBlur: '&?',
        ariaLabel: '@',
        label: '@',
        noMargin: '<',
        disablePrimary: '<',
        onChange: '&?',
        tooltip: '@',
    },
});
