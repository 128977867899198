'use strict';

import { IscSegmentationsFilterController } from './isc-segmentations-filter.controller';

const template = require('./isc-segmentations-filter.html');
require('./_isc-segmentations-filter.scss');

export const iscSegmentationsFilterComponent: ng.IComponentOptions = {
  controller: IscSegmentationsFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    segmentations: '=',
    onChange: '&',
    model: '=',
    label: '@',
    isDisabled: '<',
  },
};
