'use strict';

import './squareMembers.module';
import routeConfig from './squareMembers.route';
import './segmentation';
import './memberOverview';

angular
  .module('insitesApp.squaremembers')
  .run(routeConfig);
