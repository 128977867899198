'use strict';

import { SelectedSquareFactory } from '../../../../../core/selectedsquare.factory';
import { ActivityService } from '../../../../../core/dataservices/activity.service';
import { CommunicationService } from '../../../../../core/dataservices/communication.service';
import { CommunicationWizardController } from '../../communicationWizard.controller';
import { SequencedActivityInfoMessageHelper } from '../../sequencedActivity/sequencedActivityInfoMessageHelper';
import * as _ from 'lodash';
import { ServerConstants } from '../../../../../core/serverconstants';
import { PageService } from '../../../../../core/dataservices/page.service';

export class CommunicationChannelController {
  static $inject = ['serverConstants', 'selectedSquareFactory', 'logger', 'pageService',
    '$stateParams', '$state', 'activityservice', 'communicationservice', '$scope'];
  constructor(
    private serverConstants: ServerConstants,
    private selectedSquareFactory: SelectedSquareFactory,
    private logger: Logger,
    private pageService: PageService,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private activityservice: ActivityService,
    private communicationservice: CommunicationService,
    private $scope: ng.IScope,
  ) { }

  // Form
  form;
  saveCallback = () => this.$wizardBeforeNext();
  customValidation = () => this.validateNavigation();
  resetFormCallback = async () => await this.resetForm();
  saveNotApplicable = true;

  // Common params to all steps
  removeLink;
  wizardStep;
  wizard;
  communicationWizard: CommunicationWizardController;
  channelTypeConstants = this.serverConstants.channelTypeConstants;
  communication;
  researchActivity;
  // Activity specific params
  selectedActivityGuid = '';
  selectedActivityTitle = '';
  activitiesDropdownOptions;
  showResearchActivityEmail = false;
  showResearchActivityDropdown = false;
  communicationChannelsToDeleteGuids = [];

  // Channel decision step specific params : channels, emailchannels, etc
  isEdit = false;
  isActivitiesDropdownDisabled = false;
  channels = [
    {
      guid: '',
      caption: 'Card',
      message: 'Engagement Card',
      icon: 'images/home_card_update.svg',
      description: 'Help members on the platform to keep the right focus',
      checks: ['Home page stream'],
      channelType: this.channelTypeConstants.communicationCard,
      block: false,
      test: false,
      selected: false,
      order: 0,
      isEngagement: true,
    }, {
      guid: '',
      caption: 'Custom app notification',
      message: 'Custom app notification',
      icon: 'images/platform_notification.svg',
      description: 'Manually notify members to announce an event. Members with the mobile app will receive a push notification',
      checks: ['Web platform (one-off when triggered)', 'App platform (one-off when triggered)'],
      channelType: this.channelTypeConstants.customAppNotification,
      block: false,
      test: false,
      selected: false,
      order: 1,
      isEngagement: true,
    }, {
      guid: '',
      caption: 'News',
      message: 'News',
      icon: 'images/news_card_update.svg',
      description: 'Share announcements that are too long to put on a card and remain relevant throughout the project',
      checks: ['News page stream', 'Home page stream (optional)'],
      channelType: this.channelTypeConstants.communicationNews,
      block: false,
      test: false,
      selected: false,
      order: 2,
      isEngagement: true,
    }, {
      guid: '',
      caption: 'Newsletter email',
      message: 'Newsletter email',
      icon: 'images/email_news.svg',
      description: 'Extend reach or generate traffic to the platform',
      checks: ['The member\'s mailbox (one-off when triggered)'],
      channelType: this.channelTypeConstants.emailNewsletter,
      block: false,
      test: false,
      selected: false,
      order: 3,
      isEngagement: true,
    },
    {
      guid: '',
      caption: 'Research activity email',
      message: 'Research activity email',
      icon: 'images/channel_mail.png',
      description: 'Maximize reach and traffic to your research activity',
      checks: ['The member\'s mailbox  (one-off when triggered)'],
      channelType: this.channelTypeConstants.researchActivityEmail,
      messageType: 'Research Activity',
      block: false,
      selected: false,
      isEngagement: false,
    },
  ];

  emailChannels = [{
    guid: '',
    caption: 'Research activity reminder email',
    message: 'Research activity reminder email',
    icon: 'images/reminder_email.svg',
    description: 'Remind members who didn’t act upon your initial invite after a period of time to optimize conversion of your email invite',
    checks: ['The member\'s mailbox'],
    channelType: this.channelTypeConstants.researchActivityReminder,
    messageType: 'Research Activity',
    block: false,
    selected: false,
    component: undefined,
    isEngagement: false,
  }, {
    guid: '',
    caption: 'Research activity incomplete email',
    message: 'Research activity incomplete email',
    icon: 'images/incomplete_email.svg',
    description: 'Re-contact members who didn’t complete the activity after a period of time to optimize conversion of your email invite',
    checks: ['The member\'s mailbox'],
    channelType: this.channelTypeConstants.researchActivityIncomplete,
    messageType: 'Research Activity',
    block: false,
    selected: false,
    component: undefined,
    isEngagement: false,
  }];

  allChannels = this.channels.concat(this.emailChannels);
  selectedMainChannel = '';
  // Targeting params
  targeting = {
    TargetingOptions: {
      Targeting: {
        Targeted: 0,
        TargetedFrom: 0,
      },
    },
  };
  researchActivityQuery;
  currentChannels;
  isReadOnly;
  infoTextProvider: SequencedActivityInfoMessageHelper;
  originalSubChannels;

  async $onInit() {
    this.removeLink = this.wizardStep.linkComponent('channels', this);
    this.infoTextProvider = new SequencedActivityInfoMessageHelper(this.serverConstants, this);
    if (this.$stateParams.researchActivityGuid !== undefined) {
      this.selectedActivityGuid = this.$stateParams.researchActivityGuid;
    } else if (this.communication && this.communication.Communication.ActivityGuid !== undefined) {
      this.selectedActivityGuid = this.communication.Communication.ActivityGuid;
    }

    this.$scope.$watchGroup(['vm.communication', 'vm.selectedActivityGuid'], async () => {
      if (this.communication !== undefined) {
        if (this.$stateParams.researchActivityGuid !== undefined) {
          this.selectedActivityGuid = this.$stateParams.researchActivityGuid;
        } else if (this.communication.Communication.ActivityGuid && !this.selectedActivityGuid) {
          this.selectedActivityGuid = this.communication.Communication.ActivityGuid;
        }
        if (this.communication.Channels.length > 0) {
          this.isEdit = true;
          const empty = !this.currentChannels;
          this.currentChannels = _.filter<any>(this.allChannels, (channel) => _.find<any>(this.communication.Channels, (c) => {
            const bool = c.Channel.ChannelType === channel.channelType;
            if (bool) {
              channel.guid = c.Channel.Guid;
            }
            return bool;
          }));
          this.communication.isEngagement = this.currentChannels[0].isEngagement;
          this.communicationWizard.messageTitle = this.currentChannels[0].message;
          if (this.currentChannels[0].channelType === this.serverConstants.channelTypeConstants.researchActivityEmail && this.selectedActivityGuid !== undefined) {
            const response = await this.activityservice.getActivityDetails(this.selectedActivityGuid);
            this.researchActivity = response.data;
            await this.loadDataResearchEmailChannels(this.currentChannels);
            if (empty) {
              this.currentChannels.forEach((c) => c.selected = true);
              this.originalSubChannels = { ...this.currentChannels };
            }
          } else {
            this.currentChannels[0].selected = true;
          }
          this.blockChannels(true);
        } else if (this.selectedActivityGuid) {
          // Goddamn cursed watch
          // Every "this.researchactivity = response.data" will fockin` trigger a damn watch, so
          // This else is forever in loop
          if (!this.researchActivity || !this.researchActivity.Guid || this.selectedActivityGuid !== this.researchActivity.Guid) {
            const response = await this.activityservice.getActivityDetails(this.selectedActivityGuid);
            this.researchActivity = response.data;
            // Pre-populate activity dropdown values
            this.selectedActivityTitle = this.researchActivity.Name;
            this.showResearchActivityDropdown = true;
            // Select channel and block the edit
            const emailChannel = _.find<any>(this.allChannels, (channel) => channel.channelType === this.channelTypeConstants.researchActivityEmail);
            // Mark as selected received channel
            emailChannel.selected = true;
            // Show options for email channel
            this.showResearchActivityEmail = true;

            // The incomplete email channel should not be enabled for Discussion 2.0 or Scout activities because it won't trigger any email
            const incompleteEmailChannel = this.emailChannels.filter((ec) => ec.channelType === this.channelTypeConstants.researchActivityIncomplete)[0];
            if (this.activityservice.isQualResearchActivity(this.researchActivity.Type)) {
              incompleteEmailChannel.block = true;
              this.deselectChannel(incompleteEmailChannel);
            } else {
              incompleteEmailChannel.block = false;
            }
          }
        }
      }
    });
    await this.getActivitiesDropdown();
  }

  async loadDataResearchEmailChannels(currentChannels) {
    this.isActivitiesDropdownDisabled = true;
    this.showResearchActivityDropdown = true;
    this.showResearchActivityEmail = true;
    this.selectedActivityGuid = this.researchActivity.Guid;
    this.selectedActivityTitle = this.researchActivity.Name;
    _.forEach(currentChannels, (channelQuery) => {
      this.loadDataForChannel(channelQuery);
    });
  }

  loadDataForChannel(channel) {
    // Find currently editing channel from this array
    // Mark as selected received channel
    if (channel.channelType === 6) {
      channel.selected = true;
    }
    // Checks if the current iteration is one of the reminder or incomplete types
  }

  public canUpdate(): boolean {
    return !this.isEdit || this.communication.Channels[0].Channel.ChannelType === this.channelTypeConstants.researchActivityEmail;
  }

  async updateCommunicationChannels() {
    const currentChannels = _.filter<any>(this.allChannels, (channel) => channel.selected);
    const channels = [];
    _.forEach(currentChannels, (channel) => {
      channels.push({
        Title: this.communication.Communication.Title,
        ChannelType: channel.channelType,
        CommunicationGuid: this.communication.Communication.Guid,
        Guid: channel.guid,
      });
    });
    if (currentChannels[0].channelType === this.channelTypeConstants.researchActivityEmail) {
      await this.communicationservice.createUpdateActivityEmailChannels(this.$stateParams.communicationGuid, this.selectedActivityGuid, channels).then((res) => {
        let index = 0;
        const guids = res.data.SyncedCommunicationChannelGuids;
        _.forEach(guids, (guid) => {
          if (this.communication.Channels.length > 0 && this.communication.Channels[index] !== undefined) {
            this.communication.Channels[index].Channel.Guid = guid;
            this.communication.Channels[index].Channel.ChannelType = channels[index].ChannelType;
          } else {
            this.communication.Channels.push({ Channel: { Guid: channels[index].Guid, ChannelType: channels[index].ChannelType } });
          }
          index++;
        });
        // Only possible change to a published channel is delete
        if (!this.isReadOnly) {
          const hasdeletedChannels = _.countBy(
            this.communication.Channels, (c: any) => c.Channel.IsPublished
            && _.some(this.communicationChannelsToDeleteGuids, (ctd) => ctd === c.Channel.Guid),
          );
          if (hasdeletedChannels.true > 0) {
            this.showToastSuccessful(hasdeletedChannels.true > 1, 'successfully deleted.');
          }
        }
      }, () => {
        this.showToastError(currentChannels.length > 1, 'not successfully updated.');
      });
    } else {
      await this.communicationservice.updateCommunicationChannel(channels[0]).then((res) => {
        if (this.communication.Channels.length === 0) {
          const guid = res.data.Guid;
          this.communication.Channels.push({ Channel: { Guid: guid, ChannelType: channels[0].ChannelType } });
        }
        if (this.communication.Channels[0] && this.communication.Channels[0].Channel.IsPublished && !this.isReadOnly) {
          this.showToastSuccessful(false, 'successfully updated.');
        }
      }, () => {
        this.showToastError(false, 'not successfully updated.');
      });
    }

    // We need to update the current communication with the new channels.
    this.communicationservice.selectCommunication(this.$stateParams.communicationGuid).then((response) => {
      this.communication = response;
      this.communicationWizard.Communication = this.communication;
    });
  }

  showToastSuccessful(multiple: boolean, message) {
    const result = (multiple ? 'Channels ' : 'Channel ') + message;
    this.logger.success(result);
  }

  showToastError(multiple: boolean, message) {
    const result = (multiple ? 'Channels ' : 'Channel ') + message;
    this.logger.error(result);
  }

  async getActivitiesDropdown() {
    const includingChildActivityGuid = this.selectedActivityGuid;
    const activityList = await this.activityservice.getActivitiesDropdown(includingChildActivityGuid);
    this.activitiesDropdownOptions = activityList;
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardStepLoaded() {
    let currentChannel:any = {};
    if (this.communication !== undefined && this.communication.Channels.length > 0) {
      currentChannel = _.find<any>(this.allChannels, (channel) => channel.channelType === this.communication.Channels[0].Channel.ChannelType);
    }
    if (this.selectedSquareFactory.SsoEnabled) {
      currentChannel = _.find(this.channels, {
        channelType: this.channelTypeConstants.communicationCard,
      });
    }
    this.selectChannel(currentChannel || {});
    currentChannel.block = true;
  }

  $wizardStepIsCompleted() {
    return this.$stateParams.channelType !== undefined || _.some(this.channels, (channel) => channel.selected);
  }

  $wizardIsValid() {
    if (this.channels[4].selected && !this.selectedActivityGuid) {
      return false;
    }
    const result = _.some(this.channels, (channel) => channel.selected);
    if (result) {
      // If all good, we need to reset the form because
      // We changed it by setting the "selected" on a channel
      this.form.$setPristine();
    }
    return result;
  }

  async $wizardBeforeNext() {
    this.blockChannels(false);
    if (this.canUpdate()) {
      await this.updateCommunicationChannels();
      this.$state.includes('.', {
        channelType: this.communication.Channels[0].Channel.ChannelType,
      }, { location: true, notify: false });
    }
  }

  blockChannels(onlyIfClosed: boolean) {
    const toBlock = onlyIfClosed ? this.$stateParams.status === '3' : true;
    _.forEach(this.channels, (channel) => {
      channel.block = toBlock;
    });
    if (this.isReadOnly) {
      _.forEach(this.emailChannels, (emailChannel) => {
        emailChannel.block = toBlock;
      });
    }
  }

  async toggleSelected(channel) {
    if (channel.selected) {
      this.deselectChannel(channel);
    } else {
      this.selectChannel(channel);
    }
    if (this.form) {
      this.form.$dirty = true;
    }
  }

  selectChannel(channel) {
    // Makes sure it's not possible to multiple select
    this.mainSelectBehaviour(channel);
    // Behaviour for each selected channel
    if (channel.selected) {
      this.selectChannelSwitch(channel);
    }

  }

  mainSelectBehaviour(channel) {
    // Checks if this is the main channel
    const isMainChannel = _.some(this.channels, (ch) => ch.channelType === channel.channelType);
    // Checks if there are any other item selected and gets that item to be deselected
    const anyOtherSelected = _.find<any>(this.channels, (c) => c.selected === true);
    if (isMainChannel) {
      this.selectedMainChannel = channel.message;
      this.communicationWizard.messageTitle = this.selectedMainChannel;
      if (anyOtherSelected) {
        this.deselectChannel(anyOtherSelected);
      }
    }
    // Get component for selected channel
    const componentName = `channel-${channel.channelType}`;
    this.wizardStep.resolveComponent(componentName).then((component) => {
      channel.component = component;
      this.wizardStep.substeps.sort((a, b) => b.order < a.order);
    });
    channel.selected = true;
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  async selectChannelSwitch(channel) {
    switch (channel.channelType) {
      // Main channels
      case this.channelTypeConstants.researchActivityEmail:
        // Show options for this channel
        this.showResearchActivityEmail = true;
        this.showResearchActivityDropdown = true;
        break;
      case this.channelTypeConstants.communicationCard:
        this.selectedActivityGuid = null;
        break;
      case this.channelTypeConstants.customAppNotification:
        this.selectedActivityGuid = null;
        // Todo: do we need anything else for platform notification?!
        break;
      case this.channelTypeConstants.communicationNews:
      {
        this.selectedActivityGuid = null;
        const anyNewsPage = await this.pageService.anyNewsPageForSquare();
        if (!anyNewsPage) {
          channel.selected = false;
          this.logger.error('Please have at least a news page before creating cards !');
        }
        break;
      }
      case this.channelTypeConstants.emailNewsletter:
        this.selectedActivityGuid = null;
        break;
      // Optional/additional channels
      case this.channelTypeConstants.researchActivityReminder:
        // Remove this channel from the to be deleted list on select
        if (this.isEdit) {
          if (channel.guid !== '') {
            _.remove(this.communicationChannelsToDeleteGuids, (guid) => guid === channel.guid);
          }
        }
        break;
      case this.channelTypeConstants.researchActivityIncomplete:
        // Remove this channel from the to be deleted list on select
        if (this.isEdit) {
          if (channel.guid !== '') {
            _.remove(this.communicationChannelsToDeleteGuids, (guid) => guid === channel.guid);
          }
        }
        break;
    }
  }

  deselectChannel(channel) {
    this.deselectChannelSwitch(channel);
    channel.selected = false;
    channel.component = undefined;
  }

  deselectChannelSwitch(channel) {
    switch (channel.channelType) {
      // Main channels
      case this.channelTypeConstants.researchActivityEmail:
        // Hide options for this channel
        this.showResearchActivityEmail = false;
        this.showResearchActivityDropdown = false;
        // De-select child options (in case they were selected)
        _.forEach(this.emailChannels, (ech) => {
          ech.selected = false;
          ech.component = undefined;
        });
        break;
      case this.channelTypeConstants.communicationCard:
        // Hide options for this channel
        this.showResearchActivityDropdown = false;
        break;
      case this.channelTypeConstants.communicationNews:
        break;
      case this.channelTypeConstants.customAppNotification:
        break;
      case this.channelTypeConstants.emailNewsletter:
        break;
      // Optional/additional channels
      case this.channelTypeConstants.researchActivityReminder:
        // Add this channel to be deleted on de-select
        if (this.isEdit) {
          if (channel.guid !== '') {
            this.communicationChannelsToDeleteGuids.push(channel.guid);
          }
        }
        break;
      case this.channelTypeConstants.researchActivityIncomplete:
        // Add this channel to be deleted on de-select
        if (this.isEdit) {
          if (channel.guid !== '') {
            this.communicationChannelsToDeleteGuids.push(channel.guid);
          }
        }
        break;
    }
  }

  validateNavigation() {
    return  {
      canNavigate: this && this.form ? this.form.$valid : true,
      isDirty: this && this.form ? this.form.$dirty : false,
    };
  }

  async resetForm() {
    this.form.$setPristine();
    // Mark all selections as they were originally
    if (this.originalSubChannels) {
      this.currentChannels.forEach((cc) =>
        cc.selected = this.originalSubChannels.find((oc) => oc.guid === cc.guid).selected);
    }
  }

}
