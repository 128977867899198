'use strict';

const template = require('./star-button.html');

export const StarButtonComponent: ng.IComponentOptions = {
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    isStarred: '<',
  },
};
