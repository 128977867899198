'use strict';

import { ServerConstants } from './../../../../core/serverconstants';
import { SquareService } from '../../../../core/dataservices/square.service';
import { IImageSettingsModel, IImageSettings } from './imageSettingsModel';
import { ISquareDesignDetailItem } from '../designModel';
export class SquareConfigDesignImagesController {
  static $inject = ['logger', 'squareservice', '$mdDialog', '_', '$scope', 'serverConstants'];

  constructor(
    private logger: Logger,
    private squareservice: SquareService,
    private $mdDialog: ng.material.IDialogService,
    private _: _.LoDashStatic,
    private $scope: ng.IScope,
    private serverConstants: ServerConstants,
  ) { }
  imagesError;
  images;
  form: ng.IFormController;
  squareDesignData: ISquareDesignDetailItem;
  emptyModel: IImageSettingsModel = {
    Label: '',
    Tooltip: '',
    StoredImage: '',
    AlternateImage: '',
    MaxSize: '',
    Text: '',
    IsStored: false,
  };
  imageSettingList: IImageSettings = {
    logoTopBar: this.emptyModel,
    headerLogo: this.emptyModel,
    headerDesktop: this.emptyModel,
    headerMobile: this.emptyModel,
    headerEmail: this.emptyModel,
    background: this.emptyModel,
    activationEmailImage: this.emptyModel,
    activationReminderEmailImage: this.emptyModel,
    almostInactiveEmailImage: this.emptyModel,
    changeEmailImage: this.emptyModel,
    digestEmailImage: this.emptyModel,
    downloadVoucherEmailImage: this.emptyModel,
    failedStimuliEmailImage: this.emptyModel,
    forgotPasswordMultipleSquaresEmailImage: this.emptyModel,
    forgotPasswordEmailImage: this.emptyModel,
    incentiveEmailImage: this.emptyModel,
    newsletterEmailImage: this.emptyModel,
    probingQuestionsEmailImage: this.emptyModel,
    reactivationEmailImage: this.emptyModel,
    replyAcceptedEmailImage: this.emptyModel,
    replyWithAnswerRequiredEmailImage: this.emptyModel,
    researchActivityIncompleteEmailImage: this.emptyModel,
    researchActivityInviteEmailImage: this.emptyModel,
    researchActivityReminderEmailImage: this.emptyModel,
    savingUpRewardsCorrectionEmailImage: this.emptyModel,
    squareClosedPointsExpirationEmailImage: this.emptyModel,
    welcomeEmailImage: this.emptyModel,
    communicationCodeEmailImage: this.emptyModel,
  };
  // : { [name: string]: IImageSettingsModel; } = {};
  imagesToDelete = [];

  isSaving = false;
  async $onInit() {
    this.logger.info('Square config - Design Images Editor activated');
    this.imagesError = {};
    this.imageSettingList.logoTopBar = {
      Tooltip: `This logo is used on the <strong><u>top left of the web</u></strong> version
        and is displayed in the <strong><u>mobile app header, against a white background</u></strong>`,
      Label: 'Logo top bar',
      StoredImage: this.serverConstants.imagesConstants.logoTopBar,
      AlternateImage: 'images/top-logo.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>160 x 35px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasLogoTopBar,
    };

    this.imageSettingList.headerLogo = {
      Label: 'Header logo',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.headerLogo,
      AlternateImage: 'images/header-logo.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Please ensure the same size as the header image',
      IsStored: this.squareDesignData.SquareDesign.HasHeaderLogo,
    };

    this.imageSettingList.headerDesktop = {
      Label: 'Desktop header',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.headerDesktop,
      AlternateImage: 'images/header-image-desktop.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>1920 x 200px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasHeaderDesktop,
    };

    this.imageSettingList.headerMobile = {
      Label: 'Mobile web version header',
      Tooltip: 'This header is used in the <strong><u>web version when opened on mobile</u></strong>. It is not used in the mobile app',
      StoredImage: this.serverConstants.imagesConstants.headerMobile,
      AlternateImage: 'images/header-image-mobile.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>960 x 150px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasHeaderMobile,
    };

    this.imageSettingList.headerEmail = {
      Label: 'Email header',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.headerEmail,
      AlternateImage: 'images/email-header.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 100px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasHeaderEmail,
    };

    this.imageSettingList.background = {
      Label: 'Background image',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.background,
      AlternateImage: 'images/background-image.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>1920 x 1080px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasBackground,
    };

    this.imageSettingList.activationEmailImage = {
      Label: 'Activation',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.activationEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasActivationEmailImage,
    };

    this.imageSettingList.activationReminderEmailImage = {
      Label: 'Activation Reminder',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.activationReminderEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasActivationReminderEmailImage,
    };

    this.imageSettingList.almostInactiveEmailImage = {
      Label: 'Almost Inactive',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.almostInactiveEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasAlmostInactiveEmailImage,
    };

    this.imageSettingList.changeEmailImage = {
      Label: 'Change Email Placeholder',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.changeEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasChangeEmailImage,
    };

    this.imageSettingList.digestEmailImage = {
      Label: 'Digest',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.digestEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasDigestEmailImage,
    };

    this.imageSettingList.downloadVoucherEmailImage = {
      Label: 'Download Voucher',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.downloadVoucherEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasDownloadVoucherEmailImage,
    };

    this.imageSettingList.failedStimuliEmailImage = {
      Label: 'Failed Stimuli',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.failedStimuliEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasFailedStimuliEmailImage,
    };

    this.imageSettingList.forgotPasswordMultipleSquaresEmailImage = {
      Label: 'Forgot Password (Multiple)',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.forgotPasswordMultipleSquaresEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasForgotPasswordMultipleSquaresEmailImage,
    };

    this.imageSettingList.forgotPasswordEmailImage = {
      Label: 'Forgot Password',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.forgotPasswordEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasForgotPasswordEmailImage,
    };

    this.imageSettingList.incentiveEmailImage = {
      Label: 'Incentive',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.incentiveEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasIncentiveEmailImage,
    };

    this.imageSettingList.newsletterEmailImage = {
      Label: 'Newsletter',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.newsletterEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasNewsletterEmailImage,
    };

    this.imageSettingList.probingQuestionsEmailImage = {
      Label: 'Probing Questions',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.probingQuestionsEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasProbingQuestionsEmailImage,
    };

    this.imageSettingList.reactivationEmailImage = {
      Label: 'Reactivation',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.reactivationEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasReactivationEmailImage,
    };

    this.imageSettingList.replyAcceptedEmailImage = {
      Label: 'Reply Accepted',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.replyAcceptedEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasReplyAcceptedEmailImage,
    };

    this.imageSettingList.replyWithAnswerRequiredEmailImage = {
      Label: 'Reply with Answer Required',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.replyWithAnswerRequiredEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasReplyWithAnswerRequiredEmailImage,
    };

    this.imageSettingList.researchActivityIncompleteEmailImage = {
      Label: 'Research Activity Incomplete',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.researchActivityIncompleteEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasResearchActivityIncompleteEmailImage,
    };

    this.imageSettingList.researchActivityInviteEmailImage = {
      Label: 'Research Activity Invite',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.researchActivityInviteEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasResearchActivityInviteEmailImage,
    };

    this.imageSettingList.researchActivityReminderEmailImage = {
      Label: 'Research Activity Reminder',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.researchActivityReminderEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasResearchActivityReminderEmailImage,
    };

    this.imageSettingList.savingUpRewardsCorrectionEmailImage = {
      Label: 'Saving Up Rewards Correction',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.savingUpRewardsCorrectionEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasSavingUpRewardsCorrectionEmailImage,
    };

    this.imageSettingList.squareClosedPointsExpirationEmailImage = {
      Label: 'Square Closed Points Expiration',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.squareClosedPointsExpirationEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasSquareClosedPointsExpirationEmailImage,
    };

    this.imageSettingList.welcomeEmailImage = {
      Label: 'Welcome',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.welcomeEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasWelcomeEmailImage,
    };
    this.imageSettingList.communicationCodeEmailImage = {
      Label: 'Communication Code',
      Tooltip: '',
      StoredImage: this.serverConstants.imagesConstants.communicationCodeEmailImage,
      AlternateImage: 'images/email-body.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>600 x 300px<b>',
      IsStored: this.squareDesignData.SquareDesign.HasCommunicationCodeEmailImage,
    };
  }

  async saveImages() {
    if (this.imagesToDelete.length > 0) {
      await this.squareservice.deleteDesignImages(this.imagesToDelete)
        .then(() => {
          this.imagesError = {};
          this.imagesToDelete = [];
          this.form.$setPristine();
        });
    }
    if (Object.getOwnPropertyNames(this.images).length > 0) {
      await this.squareservice.saveSquareImages(this.images)
        .then(() => {
          this.logger.success('Images saved successfully');
        }, (error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.$scope.$broadcast('isc-images-saved');
          this.imagesError = {};
          this.images = {};
          this.form.$setPristine();
          this.isSaving = false;
        });
    } else {
      this.isSaving = false;
      this.$scope.$broadcast('isc-images-saved');
      this.logger.success('Images saved successfully');
    }

  }
  handleError(error: any) {
    this.logger.error(error.data.message);
    const listOfFailedUploads = error.data.reason.split(',').map((str) => str.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase()));
    listOfFailedUploads.forEach((img) => this.imageSettingList[img].IsStored = false);
  }

  savePage() {
    this.isSaving = true;
    const self = this;
    self._.forEach(self.images, (image, key) => {
      delete self.images[key];
      const newKey = self._.kebabCase(key);
      if (image === null) {
        this.imagesToDelete.push(newKey);
      } else {
        self.images[newKey] = image;
        self.imageSettingList[key].IsStored = true;
      }
    });
    if (self._.values(self.images).some((x: any) => {
      if (x.size > 1048576) {
        self.imagesError.message = 'Some images are too large!';
        self._.forEach(self.images, (image, key) => {
          if (image === x) {
            delete self.images[key];
          }
        });
      }
      return x != null;
    })) {
      if (this._.values(this.imagesError).some((x) => x != null)) {
        this.cancelPage();
        this.$mdDialog.show(this.$mdDialog.iscConfirm({
          title: 'Save page',
          text: 'Some images are invalid and will not be saved. Do you want to continue?',
          ok: 'Confirm',
          cancel: 'Cancel',
        })).then(() => {
          this.saveImages();
        }, () => {
          this.isSaving = false;
        });
      } else {
        this.saveImages();
      }
    } else {
      if (this.imagesToDelete.length > 0) {
        this.saveImages();
      } else {
        this.$mdDialog.show(
          this.$mdDialog.iscAlert()
            .title('Save page')
            .text('All images are invalid, nothing to save!')
            .ok('Ok'),
        );
        this.isSaving = false;
      }

    }
  }

  cancelPage() {
    this.images = {};
    this.imagesError = {};
    this.form.$setPristine();
    this.$scope.$broadcast('images-cancelled');
  }
}


