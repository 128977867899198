'use strict';

const template = require('./periodicRewards.html');

import { PeriodicRewardsController } from './periodicRewards.controller';

export const PeriodicRewardsComponent: ng.IComponentOptions = {
  controller: PeriodicRewardsController,
  templateUrl: template,
  controllerAs: 'vm',
};
