'use strict';

import { ActivitySequenceActionType } from './activitySequenceActionType';
import { ActivitySequenceConfirmController } from './activitySequenceConfirm.controller';
import { ActivitySequenceDataService } from '../../../core/dataservices/activitySequence.service';

const confirmTemplate = require('./activitySequenceConfirm.html');

export class ActivitySequenceService {
  static $inject = ['_', 'activitySequenceDataService', '$mdDialog', 'spinnerservice'];
  constructor(
    private _: _.LoDashStatic,
    private activitySequenceDataService: ActivitySequenceDataService,
    private $mdDialog: ng.material.IDialogService,
    private spinnerservice) { }

  public async addActivityToSequence(parentActivityGuid, childActivityGuid, index, isSequencedActivity): Promise<boolean> {
    const confirmationResult = await this.confirmCreateSequence(parentActivityGuid, childActivityGuid, isSequencedActivity);
    if (confirmationResult) {
      try {
        this.spinnerservice.show('loading');
        await this.activitySequenceDataService.addActivityToSequence(parentActivityGuid, childActivityGuid, index);
      } finally {
        this.spinnerservice.hide('loading');
      }

      return confirmationResult;
    }
  }

  public async removeActivityFromSequence(childActivityGuid): Promise<boolean> {
    const confirmationResult = await this.confirmRemoveSequence(childActivityGuid);
    if (confirmationResult) {
      try {
        this.spinnerservice.show('loading');
        await this.activitySequenceDataService.removeActivityFromSequence(childActivityGuid);
      } finally {
        this.spinnerservice.hide('loading');
      }
    }

    return confirmationResult;
  }

  private async confirmCreateSequence(parentActivityGuid, childActivityGuid, isSequencedActivity) {
    let validationResult;
    try {
      this.spinnerservice.show('loading');
      validationResult = await this.activitySequenceDataService.validateSequencedActivityCreate(parentActivityGuid, childActivityGuid);
    } finally {
      this.spinnerservice.hide('loading');
    }
    let actionType = ActivitySequenceActionType.Create;
    if (isSequencedActivity) {
      actionType = ActivitySequenceActionType.Reorder;
    }
    return await this.confirmDialog(actionType, validationResult);
  }

  private async confirmRemoveSequence(childActivityGuid) {
    let validationResult;
    try {
      this.spinnerservice.show('loading');
      validationResult = await this.activitySequenceDataService.validateSequencedActivityRemove(childActivityGuid);
    } finally {
      this.spinnerservice.hide('loading');
    }

    return await this.confirmDialog(ActivitySequenceActionType.Remove, validationResult);
  }

  private async confirmDialog(actionType: number, validationResult: any) {
    return await this.$mdDialog.show({
      controller: ActivitySequenceConfirmController,
      controllerAs: 'vm',
      templateUrl: confirmTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        actionType,
        validationResult,
      },
    });
  }
}
