'use strict';

import { ServerConstants } from '../../core/serverconstants';
import { ActivityFilterService } from '../../core/services/activityFilter.service';
import { ActivityService } from '../../core/dataservices/activity.service';
import { RouterHelper } from '../../blocks/router/router-helper';
import { IscUIUtils } from 'isc-ui';
import { DateTime } from 'luxon';

const appRunActivityData = (
  routerHelper: RouterHelper,
  activityFilterService: ActivityFilterService,
  $stateParams: ng.ui.IStateParamsService,
  activityservice: ActivityService) => {

  const showDiscussionMenu: {
    [activityGuid: string]: boolean
  } = {};
  routerHelper.configureStates([
    {
      state: 'root.square.activitydata',
      config: {
        url: '/activity/:activityGuid/data',
        component: 'activityDataComponent',
        title: 'Activity discussion',
        settings: {
          nav: 3,
          section: 'ACTIVITY MANAGEMENT',
          icon: 'fa fa-comments',
          label: 'Moderation & Analysis',

          // Show in sidenav only for Qual activities
          show: async () => {
            // If true then it will not change so do not need the extra getActivityDetails api call
            if (!showDiscussionMenu[$stateParams.activityGuid]) {
              const response = await activityservice.getActivityDetails($stateParams.activityGuid);
              showDiscussionMenu[$stateParams.activityGuid] = activityservice.isQualResearchActivity(response.data.Type)
                && response.data.IsPublished;
            }

            return showDiscussionMenu[$stateParams.activityGuid];
          },
        },
        resolve: {
          discussionType: ['serverConstants', '$stateParams', '$state',
            async (serverConstants: ServerConstants, $stateParam: ng.ui.IStateParamsService, $state: ng.ui.IStateService) => {
              const response = await activityservice.getActivityDetails($stateParam.activityGuid, $stateParam.clientGuid);
              let redirectRouteName = '';
              if (IscUIUtils.isActivityDiscussionNewType(response.data.Type, serverConstants)) {
                redirectRouteName = 'root.square.activitydata.discussion';
              } else {
                redirectRouteName = 'root.square.activitydata.conversations';
              }

              activityservice.setModerationAndAnalysisDiscussionType(redirectRouteName);
              return $state.go(redirectRouteName, {
                ...$stateParam,
                replyGuid: $stateParams.replyGuid ?? $stateParam.replyGuid,
                isEdit: $stateParams.isEdit ?? $stateParam.isEdit,
                activityGuid: response.data.Guid,
                activityType: response.data.Type,
                startDate: DateTime.fromISO($stateParam.startDate ?? response.data.StartDate).toFormat('yyyy-LL-dd'),
                endDate: DateTime.fromISO($stateParam.endDate ?? response.data.EndDate).toFormat('yyyy-LL-dd'),
              }, {
                location: 'replace',
              });
            }],
        },
      },
    },
    {
      state: 'root.square.activitydata.conversations',
      config: {
        url: '/conversations'
          + `?page?sort?expandedPosts?${activityFilterService.getQualFilterUrlPartsForDiscussions()}?referer?replyGuid?refererlabel?isEdit?isReloadRequired`,
        component: 'activityDataConversationsComponent',
        title: 'Moderation and analyses',
      },
    },
    {
      state: 'root.square.activitydata.discussion',
      config: {
        url: '/discussion'
          + `?page?sort?expandedPosts?${activityFilterService.getQualFilterUrlPartsForDiscussions()}?referer?replyGuid?refererlabel?isEdit`,
        component: 'forumNewDiscussionConversationComponent',
        title: 'Conversation',
        isVue: true,
      },
    },
  ]);
};

appRunActivityData.$inject = ['routerHelper', 'activityFilterService', '$stateParams', 'activityservice', '$q'];

angular.module('insitesApp.activityData')
  .run(appRunActivityData);
