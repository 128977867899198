'use strict';

import { RouterHelper } from '../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper, __env: Environment) => {
  routerHelper.configureStates([
    {
      state: 'root.home',
      config: {
        redirectTo: __env.showStartPage ? 'root.landing' : 'root.clientselection',
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', '__env'];

export default routeConfig;

