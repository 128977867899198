'use strict';

const configureAngularMaterialThemes = ($mdThemingProvider: ng.material.IThemingProvider) => {
  const customPrimary = {
    50: '#5c9ccf',
    100: '#4890c9',
    200: '#3883c0',
    300: '#3376ac',
    400: '#2d6899',
    500: '#004750',
    600: '#214d71',
    700: '#1b405e',
    800: '#16334a',
    900: '#102536',
    A100: '#70a8d5',
    A200: '#84b4db',
    A400: '#97c0e1',
    A700: '#0a1822',
  };

  $mdThemingProvider
    .definePalette('customPrimary', customPrimary);

  const customAccent = {
    50: '#245944',
    100: '#2b6b52',
    200: '#337d60',
    300: '#3a8f6e',
    400: '#42a17c',
    500: '#49b48a',
    600: '#6cc3a2',
    700: '#7ecbad',
    800: '#90d2b9',
    900: '#a3d9c4',
    A100: '#6cc3a2',
    A200: '#347F62',
    A400: '#49b48a',
    A700: '#b5e1d0',
  };

  $mdThemingProvider
    .definePalette('customAccent', customAccent);

  const customWarn = {
    50: '#fce2e5',
    100: '#facbd1',
    200: '#f7b4bc',
    300: '#f59da7',
    400: '#f28693',
    500: '#C2273D',
    600: '#CF2B49',
    700: '#eb4155',
    800: '#e92a40',
    900: '#e1172f',
    A100: '#fefafa',
    A200: '#ffffff',
    A400: '#ffffff',
    A700: '#ca152a',
  };

  $mdThemingProvider
    .definePalette('customWarn', customWarn);

  $mdThemingProvider
    .theme('default')
    .primaryPalette('customPrimary')
    .accentPalette('customAccent')
    .warnPalette('customWarn')
    .backgroundPalette('grey');
};

configureAngularMaterialThemes.$inject = ['$mdThemingProvider'];

export default configureAngularMaterialThemes;
