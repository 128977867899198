'use strict';

import './exportlog.module';
import { ExportLogComponent } from './exportlog.component';
import routeConfig from './exportlog.route';

angular
  .module('insitesApp.squareconfig.footer')
  .component('exportLogList', ExportLogComponent)
  .run(routeConfig);
