'use strict';

import { ActivityQualOpeningPostController } from './activityQualOpeningPost.controller';

require('./activityQualOpeningPost.scss');

const template = require('./activityQualOpeningPost.html');

export const ActivityQualOpeningPostComponent: ng.IComponentOptions = {
  controller: ActivityQualOpeningPostController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=',
  },
};
