'use strict';

import { JobService } from './../../../../core/jobs/job.service';
import { ParticipantService } from './../../../../core/dataservices/participant.service';
import { Utils } from './../../../../core/utils';
import * as _ from 'lodash';
import { CurrentUserService } from '../../../../core/dataservices/currentUser.service';
import { SquareService } from './../../../../core/dataservices/square.service';
import { ServerConstants } from './../../../../core/serverconstants';
import { DateTime } from 'luxon';
import { FeatureService } from 'src/app/core/dataservices/feature.service';

require('./_importMembers.scss');

export class ImportMembersDialogController {
  static $inject = [
    '$mdDialog',
    'participantservice',
    'jobservice',
    'loadMembers',
    'serverConstants',
    'downloadservice',
    'currentUserService',
    'squareservice',
    '$stateParams',
    'featureservice',
  ];

  isSaving = false;
  importMembersForm;
  invalidType = false;
  errors = {};
  errorkeys = [];
  warnings = {};
  warningkeys = [];
  totalImportableMembers: number = 0;
  fileValidationSucceed: boolean = false;
  file;
  blob;
  roles = [];
  role;
  country: string;
  squareCountries: any[];
  isDownloadTemplateDisabled = false;
  overrulelabels = ['ActivationSuccess'];
  placeholders;
  isActivationEmailEdited;
  isTemplateShown;
  minConsentValidityDate: DateTime;
  consentValidityDate: DateTime;
  consentNeverExpires = false;
  isConsentFeatureEnabled = false;
  isDiySquare = false;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private participantservice: ParticipantService,
    private jobservice: JobService,
    private loadMembers,
    private serverConstants: ServerConstants,
    private downloadservice,
    private currentUserService: CurrentUserService,
    private squareservice: SquareService,
    private $stateParams: ng.ui.IStateParamsService,
    private featureService: FeatureService,
  ) {
    this.squareservice.getSquareCountries(this.$stateParams.squareGuid).then((countries) => {
      this.squareCountries = countries;
    });

    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        this.isConsentFeatureEnabled = isEnabled;
      });

    this.minConsentValidityDate = DateTime.utc().plus(({ days: 1 }));
    this.minConsentValidityDate = DateTime.local(this.minConsentValidityDate.year, this.minConsentValidityDate.month, this.minConsentValidityDate.day);

    this.squareservice.getSquareDetails(this.$stateParams.squareGuid)
      .then((data) => {
        this.isDiySquare = data.data.Detail.JobIds.some((job) => job.IsSelfServe);

        const roleConstants = this.serverConstants.roleConstants;
        const currentRole = this.currentUserService.userProfile.Role;
        this.roles = _.filter(Utils.getEnumAsArray(roleConstants), (role) => (
          role.Value === roleConstants.participant
          || ((role.Value === roleConstants.professionalAdmin || (role.Value === roleConstants.clientAdmin && this.isDiySquare)
            || (role.Value === roleConstants.clientEditor && this.isDiySquare))
          && currentRole === roleConstants.human8)
          || role.Value === roleConstants.observer),
        );

        const sortingArr = [roleConstants.professionalAdmin, roleConstants.clientAdmin, roleConstants.clientEditor,
          roleConstants.observer, roleConstants.participant];
        this.roles.sort((a, b) => sortingArr.indexOf(a.Value) - sortingArr.indexOf(b.Value));
      });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  async validateMass(file, invalidFile) {
    this.resetUpload();
    this.file = file;
    if (invalidFile) {
      this.invalidType = true;
    }
    this.blob = '';
    this.fileValidationSucceed = false;
    if (this.file) {
      await this.participantservice.validateExcelFile(this.file)
        .then(
          (validationInfo) => {
            this.errors = validationInfo.data.Errors;
            this.errorkeys = Object.keys(this.errors);
            this.warnings = validationInfo.data.Warnings;
            this.warningkeys = Object.keys(this.warnings);
            this.totalImportableMembers = validationInfo.data.Total;
            this.blob = validationInfo.data.Name;
            if (Object.keys(this.errors).length === 0) {
              this.fileValidationSucceed = true;
            }
          });
    }
  }

  resetUpload() {
    this.fileValidationSucceed = false;
    this.totalImportableMembers = 0;
    this.invalidType = false;
    this.errors = {};
  }

  async importMembers(sendActivationEmail: boolean) {
    this.isSaving = true;
    this.$mdDialog.hide();

    const jobId = this.participantservice.processUploadedExcelFile(this.blob,
      sendActivationEmail,
      this.placeholders,
      this.role,
      this.country,
      this.isActivationEmailEdited,
      this.isTemplateShown,
      this.consentValidityDate,
    );
    await this.jobservice.showJobProgressDialog(jobId, 'Importing members');
    this.loadMembers();
  }

  async downloadMembersImportTemplate() {
    this.isDownloadTemplateDisabled = true;
    const response = await this.participantservice.downloadMembersImportTemplate();
    this.downloadservice.downloadResponse(response);
    this.isDownloadTemplateDisabled = false;
  }

  isSaveDisabled() {
    const isSaveDisabled = !this.fileValidationSucceed || !this.importMembersForm.$valid;
    if (this.isConsentFeatureEnabled) {
      return isSaveDisabled || (this.consentValidityDate == null && !this.consentNeverExpires);
    }
    return isSaveDisabled;
  }
}
