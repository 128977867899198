import { UiModule } from '../../../index';
import { IscProgressLinearController } from './isc-progress-linear.controller';
var template = "<div layout=\"row\" class=\"progress-bar-container\">\n  <div class=\"progress-bar-line\">\n    <div class=\"progress-bar-line line green\" role=\"progressbar\" ng-style=\"{'width': vm.percentage.Completed + '%'}\" aria-valuenow=\"{{vm.percentage.Completed}}\" aria-valuemin=\"0\" aria-valuemax=\"100\"></div>\n    <div class=\"progress-bar-line line orange\" role=\"progressbar\" ng-style=\"{'width': vm.percentage.InProgress + '%'}\" aria-valuenow=\"{{vm.percentage.InProgress}}\" aria-valuemin=\"0\" aria-valuemax=\"100\"></div>\n    <div class=\"progress-bar-line line darkGrey\" role=\"progressbar\" ng-style=\"{'width': vm.percentage.Target + '%'}\" aria-valuenow=\"{{vm.percentage.Target}}\" aria-valuemin=\"0\" aria-valuemax=\"100\"></div>\n  </div>\n  <span flex=\"none\" class=\"text\" ng-transclude=\"iscProgressLinearContent\"></span>\n</div>";
UiModule
    .component('iscProgressLinear', {
    template: template,
    controller: IscProgressLinearController,
    controllerAs: 'vm',
    bindings: {
        percentage: '<',
    },
    transclude: {
        iscProgressLinearContent: '?iscProgressLinearContent',
    },
});
