import { UiModule } from '../../../index';
import { IscTagController } from './isc-tag.controller';
var iscTagTemplate = "<md-input-container class=\"md-block\" ng-class=\"!vm.ngModelController.$valid ? 'md-input-invalid' : ''\">\n  <label class=\"md-no-float tag-label\" ng-show=\"vm.label\" ng-class=\"{'requiredLabel': vm.isRequired || false}\">{{vm.label}}</label>\n  <md-chips\n    name=\"{{vm.name}}\"\n    md-require-match=\"true\"\n    ng-model=\"vm.model\"\n    md-removable=\"vm.removable\"\n    readonly=\"vm.readonly\"\n    md-on-add=\"vm.onAdd({chip: $chip})\"\n    class=\"autocompleteRegion chips\">\n      <button ng-click=\"vm.removeChip($event, $chip)\" md-chip-remove class=\"md-chip-remove\" type=\"button\" aria-label=\"Remove {{$chip}}\">\n        <md-icon class=\"fa fa-xmark\" aria-label=\"\"></md-icon>\n      </button>\n      <md-autocomplete\n        ng-if=\"!vm.skipAutocomplete\"\n        ng-keydown=\"vm.onTextTyped()\"\n        md-search-text=\"vm.searchTerm\"\n        md-items=\"item in vm.items({query: vm.searchTerm})\"\n        md-item-text=\"vm.chipProperty ? item[vm.chipProperty] : item\"\n        placeholder=\"{{vm.placeholder}}\"\n        md-no-cache=\"true\">\n        <md-item-template>\n          <span md-highlight-text=\"vm.searchTerm\" md-highlight-flags=\"i\">{{vm.getChip(item)}}</span>\n        </md-item-template>\n        <md-not-found>\n          No matches found.\n        </md-not-found>\n      </md-autocomplete>\n      <md-chip-template>\n        <i ng-if=\"vm.showStar({chip: $chip})\" class=\"typcn typcn-star-full-outline\"></i><span class=\"chip\">{{vm.getChip($chip)}}</span>\n      </md-chip-template>\n    </md-chips>\n    <div ng-show=\"vm.jobIdsCustomTouched\" ng-messages=\"vm.ngModelController.$error\" ng-transclude=\"errors\"></div>\n</md-input-container>\n";
UiModule
    .component('iscTag', {
    template: iscTagTemplate,
    controller: IscTagController,
    controllerAs: 'vm',
    bindings: {
        label: '@',
        placeholder: '@',
        name: '@',
        items: '&',
        model: '=',
        isRequired: '<',
        removable: '<',
        readonly: '<',
        skipAutocomplete: '<',
        chipProperty: '@',
        onRemove: '&',
        onAdd: '&',
        showStar: '&',
        warningMessage: '<?',
    },
    transclude: {
        errors: '?iscTagErrors',
    },
});
