'use strict';

import { SquareClosureController } from './squareclosure.controller';
const template = require('./squareclosure.html');

export const SquareClosureComponent: ng.IComponentOptions = {
  controller: SquareClosureController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    closuremessage: '=',
    status: '=',
    statusstring: '=',
    hasSquareOnlyExpiredJobIds: '=',
  },
  require: {
    form: '^^form',
  },
};
