'use strict';

import { config } from './config';
import configure from './configure';
import configureAngularMaterialThemes from './config.md-themes';
import configureServiceDecorators from './config.service-decorators';
import { overridePromises } from './override-promises';

angular
  .module('insitesApp.core')
  .value('config', config)
  .config(configure)
  .config(configureAngularMaterialThemes)
  .config(configureServiceDecorators)
  .run(overridePromises);
