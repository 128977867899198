'use strict';

import { ToggleableService } from './isc-expander.service';

export class ToggleableController {
  static $inject = ['toggleableservice'];
  constructor(
    private toggleableservice: ToggleableService) { }

  isCollapsed: boolean;
  registerId;

  $onInit() {
    this.isCollapsed = this.isCollapsed || false;
    this.toggleableservice.register(this, this.registerId);
  }

  toggle = function() {
    this.isCollapsed = !this.isCollapsed;
  };
}
