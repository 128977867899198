'use strict';

import { ActivityThemeController } from './activityTheme.controller';

const template = require('./activityTheme.html');

export const ActivityThemeComponent: ng.IComponentOptions = {
  controller: ActivityThemeController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=?',
    squareActivity: '<',
    isReadOnly: '<',
  },
};
