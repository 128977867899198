'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const getWelcomeMessageStates = () => [{
  state: 'root.clientconfigwelcomemessage',
  config: {
    url: 'client/:clientGuid/config/welcomemessage',
    component: 'welcomeMessageConfiguration',
    title: 'Welcome message',
    settings: {
      nav: 3,
      childnav: 1,
      label: 'Welcome message',
      section: 'Set-up',
    },
    disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
  },
}];

const appRunWelcomeMessageConfiguration = (routerHelper: RouterHelper) => {
  routerHelper.configureStates(getWelcomeMessageStates());
};
appRunWelcomeMessageConfiguration.$inject = ['routerHelper'];

export default appRunWelcomeMessageConfiguration;
