'use strict';

import { AuthService } from 'src/app/core/dataservices/auth.service';

export class LandingSidenavController {
  static $inject = ['__env', 'authService'];

  constructor(
    private __env: Environment,
    private authService: AuthService,
  ) { }

  decipherUrl = this.__env.decipherUrl;

  get shouldShowThirdParties(): boolean {
    return this.authService.hasUserDoItYourselfAccess();
  }

  openInNewTab(url: string) {
    window.open(url, '_blank', 'noopener noreferrer');
  }

  openSurveyTool() {
    this.openInNewTab(`${this.decipherUrl}/login`);
  }

  openAcademy() {
    this.openInNewTab('https://academy.insites-consulting.com');
  }
}
