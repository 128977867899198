'use strict';

import { SpinnerService } from '../../../core/services/spinner.service';
import { SquareService } from '../../../core/dataservices/square.service';
import { partition, cloneDeep } from 'lodash';

export class ActivationQuestionsController {
  static $inject = ['logger', 'spinnerservice', 'squareservice', '$stateParams'];

  constructor(
    private logger: Logger,
    private spinnerservice: SpinnerService,
    private squareservice: SquareService,
    private $stateParams: ng.ui.IStateParamsService,

  ) { }

  // used for mandatory bindings
  checked = true;

  // mandatory questions
  mandatoryQuestions;

  // optional questions
  optionalQuestions;
  // original optional to restore when user cancels form
  originalOptionalQuestions;

  form;
  isSaving = false;

  $onInit() {
    this.spinnerservice.show('loading');
    this.squareservice.getSquareActivationQuestions(this.$stateParams.squareGuid)
      .then((response) => {
        this.processQuestions(response?.data);
      })
      .finally(() => this.spinnerservice.hide('loading'));
  }

  processQuestions(questions) {
    if (questions && questions.Questions) {
      const partitioned = partition(
        questions.Questions, (q) => q.Mandatory === true);
      this.mandatoryQuestions = partitioned[0];
      this.optionalQuestions = partitioned[1];
      this.originalOptionalQuestions = cloneDeep(this.optionalQuestions);
    }
  }

  savePage() {
    this.isSaving = true;
    const request = {
      SquareGuid: this.$stateParams.squareGuid,
      Questions: this.optionalQuestions,
    };
    this.squareservice.setSquareActivationQuestions(request)
      .then(() => {
        this.form.$setPristine();
        this.logger.success('Activation questions saved successfully');
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  cancelPage() {
    this.form.$setPristine();
    this.optionalQuestions = cloneDeep(this.originalOptionalQuestions);
  }

}
