'use strict';

import './squareForumRoom.module';
import { ForumRoomComponent } from './squareForumRoom.component';
import routeConfig from './squareforumRoom.route';

angular
  .module('insitesApp.squareForumRoom')
  .component('squareForumRoom', ForumRoomComponent)
  .run(routeConfig);

require('./conversation');
require('./new-discussion-conversation');
