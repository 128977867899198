'use strict';

export class ToastController {
  static $inject = ['$mdToast'];

  constructor(private $mdToast: ng.material.IToastService) { }

  closeToast(): void {
    this.$mdToast.hide();
  }
}
