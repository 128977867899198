'use strict';

import { IProbeQuestionModel } from '../../IProbeQuestionModel';
import { ServerConstants } from '../../../../../../core/serverconstants';
import { IscUIUtils } from 'isc-ui';

export class ProbeQuestionController {
  static $inject = ['serverConstants', '$document', '$element', '$scope', '$timeout'];

  question: IProbeQuestionModel;
  onlyOptional: boolean = false;
  onDelete: () => void;
  onAdd: () => void;
  isEditable: boolean;
  isDisabled: boolean;
  questionTypeConstants = this.serverConstants.conversationQuestionTypeConstants;
  maxQuestionsMessage: string;
  autosave: () => void;
  isScoutDiscussionType;
  activityType: number;
  isFirstQuestion: boolean;
  isAdditionalProbeQuestion: boolean;
  // The question types that should be allowed for the current question.
  allowedTypes: number[];
  isDiscussionNew?: boolean;

  textQuestionType: number;
  photoQuestionType: number;
  videoQuestionType: number;
  photoVideoQuestionType: number;
  attachmentQuestionType: number;
  freestyleQuestionType: number;

  constructor(
    private serverConstants: ServerConstants,
    private $document: ng.IDocumentService,
    private $element: ng.IRootElementService,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
  ) { }

  $onInit() {
    this.$document.on('click', this.outsideClick);
    this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);

    if (!this.isDiscussionNew) {
      this.textQuestionType = this.serverConstants.conversationQuestionTypeConstants.text;
      this.attachmentQuestionType = this.serverConstants.conversationQuestionTypeConstants.attachment;
      this.photoQuestionType = this.serverConstants.conversationQuestionTypeConstants.image;
      this.videoQuestionType = this.serverConstants.conversationQuestionTypeConstants.video;
      this.photoVideoQuestionType = this.serverConstants.conversationQuestionTypeConstants.imageAndVideo;
      this.freestyleQuestionType = this.serverConstants.conversationQuestionTypeConstants.freestyle;
    } else {
      // We use a different enum for question types on the discussions module.
      // Since that one cannot be exported right now (it is not located in the Common project), this is a temporary solution.
      // Will further investigate it
      this.textQuestionType = 1;
      this.attachmentQuestionType = 2;
      this.photoQuestionType = 4;
      this.videoQuestionType = 8;
      this.photoVideoQuestionType = 12;
      this.freestyleQuestionType = 15;
    }
  }

  editQuestion(): void {
    if (this.question && !this.isDisabled && !this.question.isEdit) {
      this.question.isEdit = true;
    }
  }

  addQuestion(): void {
    if (!this.question && !this.isDisabled && this.isEditable) {
      this.onAdd();
    }
  }

  $onDestroy() {
    this.$document.off('click', this.outsideClick);
  }

  // Sometimes the on-change event is fired before the model is updated, so we need to skip a digest cycle before firing the on-change event
  onAutosave() {
    this.$timeout(() => {
      this.autosave();
    });
  }

  outsideClick = (event: JQueryEventObject) => {
    // Only do something if the click didn't happen in the element itself
    if (!this.$element[0].contains(event.target)) {
      const newProbeQuestionElement: HTMLElement = this.$document[0].querySelector('.new-probe-question');
      const ngWigEditorElement: HTMLElement = this.$element[0].querySelector('#ng-wig-editable');
      const ngWigElement: HTMLElement = this.$element[0].querySelector('.ng-wig');
      const emojiButton: HTMLElement = ngWigElement && ngWigElement.querySelector('.emoji-picker-button');
      const emojiPickerId: string = emojiButton && emojiButton.getAttribute('aria-owns');
      const emojiPicker: HTMLElement = emojiPickerId && this.$document[0].querySelector(`#${emojiPickerId}`);

      // Don't collapse the question block if picking an emoji
      if (emojiPicker && emojiPicker.contains(event.target)) {
        return;
      }

      // Collapse the question block if the question is filled in
      if (this.question && this.question.question) {
        this.$scope.$apply(() => {
          this.question.isEdit = false;
        });
      }

      // If not clicked on the new Probequestion element and question is not filled in add the 'empty-question-error' class.
      // Adding this class makes the placeholder red.
      // Else remove the class.
      if (ngWigEditorElement && newProbeQuestionElement && !newProbeQuestionElement.contains(event.target)) {
        if (this.question && !this.question.question) {
          ngWigEditorElement.classList.add('empty-question-error');
        } else {
          ngWigEditorElement.classList.remove('empty-question-error');
        }
      }
    }
  };

  acceptQuestion(): void {
    const ngWigEditorElement: HTMLElement = this.$element[0].querySelector('#ng-wig-editable');

    // Collapse the question block if the question is filled in
    if (this.question && this.question.question) {
      this.question.isEdit = false;
    }

    // If question is not filled in add the 'empty-question-error' class.
    // Adding this class makes the placeholder red.
    // Else remove the class.
    if (ngWigEditorElement) {
      if (this.question && !this.question.question) {
        ngWigEditorElement.classList.add('empty-question-error');
      } else {
        ngWigEditorElement.classList.remove('empty-question-error');
      }
    }
  }

  isQuestionTypeAllowed(type: number) {
    let result = true;
    if (this.allowedTypes && this.allowedTypes.length > 0) {
      result = this.allowedTypes.indexOf(type) !== -1;
    }
    return result;
  }

  isQuestionTypeAllowedScoutActivity(): boolean {
    if (this.isScoutDiscussionType && this.isFirstQuestion) {
      return true;
    }
    return false;
  }

  isAnswerRequiredDisabled(): boolean {
    if (this.isScoutDiscussionType && this.isFirstQuestion && !this.isAdditionalProbeQuestion) {
      return true;
    }
    return false;
  }
}
