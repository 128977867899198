'use strict';

export class AccessibleNgf implements ng.IDirective {
  restrict = 'A';

  // Make file upload is accessible via keyboard
  link(_scope, element, attrs) {
    element.attr('role', 'button');
    element.attr('aria-label', 'Select file');

    _scope.$watch(() => attrs.disabled, (disabled) => {
      if(disabled) {
        element.attr('tabindex', '-1');
      } else {
        element.attr('tabindex', '0');
      }
    });

    element.bind('keydown keypress', (event) => {
      if(event.which === 13) {
        element.triggerHandler('click');
        event.preventDefault();
      }
    });
  }
}
