'use strict';

import { DateTime } from 'luxon';

export interface IChipsItem {
  StartDate: string;
  EndDate: string;
  Filters: string[];
}

export interface IMemberOverviewFilter {
  Keyword: string,
  Roles: number[],
  Statuses: number[],
  Suspended: boolean,
  Locked: boolean,
  SegmentGuids: string[],
  SegmentOptions: number,
  StartDate: DateTime,
  EndDate: DateTime,
  List: string[];
  ExcludeParticipantsInvitedSinceWeeks?: number;
}

export interface IMembershipStatusItem {
  DisplayLabel: string,
  Label: string,
  Value: number,
}

export interface IManuallyHandledRewardFilter {
  keyword: string,
  statuses: number[],
  clients: string[],
  squares: string[],
  jobIds: string[],
  squareParticipants: number[],
  countries: string[],
  rewardPartners: number[],
  startDate: DateTime,
  endDate: DateTime,
  list: string[],
  cleared: boolean,
}

export interface IManuallyHandledRewardFilterItem {
  label: string,
  value: number,
}

export interface IManuallyHandledRewardDictionaryFilterItem {
  label: string,
  value: string,
}
