'use strict';

import { HttpService } from './http.service';
import * as _ from 'lodash';
import { IPromise } from 'angular';
import { IQuestionTree, ISegment, ISegmentAnswer, ISaveSegmentationResponse, ISurveyAnswerDetails } from './../../core/dataservices/dataservice.contracts';
import { SelectedSquareFactory } from '../selectedsquare.factory';

export class SegmentationService {
  static $inject = ['httpservice',  '$stateParams', 'selectedSquareFactory'];

  constructor(
    private httpservice: HttpService,
    private $stateParams: ng.ui.IStateParamsService,
    selectedSquareFactory: SelectedSquareFactory,
  ) {
    selectedSquareFactory.squareInfoObservable.subscribe(() => {
      this.invalidateCache();
    });
  }

  private invalidateCache() {
    this._getSelectedSegmentsDetails.cache = new _.memoize.Cache();
    this._getKeySegmentations.cache = new _.memoize.Cache();
    this._getSegmentationForFiltering.cache = new _.memoize.Cache();
  }

  exportMembers() {
    return this.httpservice.post({
      url: '/ExportService/ExportMembers',
    });
  }

  getSelectedSegmentsDetails(segmentationGuid, questionGuid) {
    return this._getSelectedSegmentsDetails(segmentationGuid, questionGuid);
  }

  private _getSelectedSegmentsDetailsKeyResolver = (segmentationGuid, questionGuid) => JSON.stringify([segmentationGuid, questionGuid]);
  private _getSelectedSegmentsDetails = _.memoize((segmentationGuid, questionGuid) => this.httpservice.get({
    url: '/SegmentationService/GetSelectedSegmentsDetails',
    params: {
      SegmentationGuid: segmentationGuid,
      QuestionGuid: questionGuid,
    },
  }).then((response) =>
    // Return membersCountDictionary;
    response.data.Segments,
  ), this._getSelectedSegmentsDetailsKeyResolver);

  isSegmentationNameUnique(segmentation) {
    return this.httpservice.get({
      url: '/SegmentationService/IsSegmentationNameUnique',
      params: {
        name: segmentation.name,
        squareGuid: this.$stateParams.squareGuid || '',
        segmentationGuid: segmentation.segmentationGuid,
      },
    }).then((response) => response.data);
  }

  getSurveys() {
    return this.httpservice.get({
      url: '/SegmentationService/GetSurveys',
    }).then((response) => response.data.Surveys);
  }

  getQuestionsForActivity(surveyGuid, onlyWithoutSegmentation: boolean): IPromise<IQuestionTree[]> {
    return this.httpservice.get({
      url: '/SegmentationService/GetQuestionsForActivity',
      params: { surveyGuid, onlyWithoutSegmentation },
    }).then((response) => response.data.Questions);
  }

  getQuestionsForSegmentation(segmentationGuid): IPromise<IQuestionTree[]> {
    return this.httpservice.get({
      url: '/SegmentationService/GetQuestionsForSegmentation',
      params: { segmentationGuid },
    }).then((response) => response.data.Questions);
  }

  getSegmentsForSegmentation(segmentationGuid): IPromise<ISegment[]> {
    return this.httpservice.get({
      url: '/SegmentationService/GetSegmentsForSegmentation',
      params: { segmentationGuid },
    }).then((response) => _.map(response.data, (item) => (
      {
        guid: item.Guid,
        name: item.Name,
        precode: item.Precode,
        memberCount: item.MemberCount,
        surveyAnswerGuids: item.SurveyAnswerGuids,
      } as ISegment)));
  }

  getAnswersForSegment(segmentGuid): IPromise<ISurveyAnswerDetails[]> {
    return this.httpservice.get({
      url: '/SegmentationService/GetAnswersForSegment',
      params: { segmentGuid },
    }).then((response) => _.map(response.data.Answers, (item) => (
      {
        answerGuid: item.AnswerGuid,
        answerLabel: item.AnswerLabel,
        questionLabel: item.QuestionLabel,
      } as ISurveyAnswerDetails)));
  }

  getAnswers(questionGuid) {
    return this.httpservice.get({
      url: '/SegmentationService/GetAnswers',
      params: { questionGuid },
    }).then((response) => response.data.Answers);
  }

  saveSegmentation(data): IPromise<ISaveSegmentationResponse> {
    this.invalidateCache();
    return this.httpservice.post({
      url: '/SegmentationService/SaveSegmentation',
      data,
    }).then((response) => ({
      segmentationGuid: response.data.SegmentationGuid,
      segmentGuidsForName: response.data.SegmentGuidsForName,
    }) as ISaveSegmentationResponse);
  }

  deleteAnswerForSegment(segmentGuid: string, answerGuid: string) {
    return this.httpservice.post({
      url: '/SegmentationService/DeleteAnswerForSegment',
      data: { segmentGuid, answerGuid },
    }).then((response) => response.data);
  }

  toggleFavoriteSegment(guid) {
    return this.httpservice.post({
      url: '/SegmentationService/ToggleFavoriteSegment',
      data: { segmentationGuid: guid },
    }).then((response) => response.data);
  }

  togglePublicSegmentation(guid) {
    return this.httpservice.post({
      url: '/SegmentationService/TogglePublicSegmentation',
      data: { segmentationGuid: guid },
    }).then((response) => response.data);
  }

  toggleIsArchived(guid) {
    return this.httpservice.post({
      url: '/SegmentationService/ToggleIsArchived',
      data: { segmentationGuid: guid },
    }).then((response) => response.data);
  }

  getKeySegmentations(includeSegmentationGuid) {
    return this._getKeySegmentations(includeSegmentationGuid);
  }

  private _getKeySegmentations = _.memoize((includeSegmentationGuid) => this.httpservice.get({
    url: '/SegmentationService/GetKeySegmentations',
    params: {
      includeSegmentationGuid,
    },
  }).then((response) => response.data));

  getSquareSegmentations(
    includeSegments: boolean,
    includeDisabled: boolean = false,
    includeArchived: boolean = false,
    excludeBehavioralSegmentationForActivityGuid: string = null) {
    return this.httpservice.get({
      url: '/SegmentationService/GetSegmentations',
      params: {
        includeSegments,
        includeDisabled,
        includeArchived,
        excludeBehavioralSegmentationForActivityGuid,
      },
    }).then((response) => response.data.SegmentationListItem);
  }

  getSegmentationForFiltering(includeSegments: boolean) {
    return this._getSegmentationForFiltering(includeSegments);
  }

  private _getSegmentationForFiltering = _.memoize((includeSegments: boolean) => this.httpservice.get({
    url: '/SegmentationService/GetSegmentationForFiltering',
    params: {
      includeSegments,
    },
  }).then((response) => response.data.SegmentationListItem));

  invalidateSegmentationForFiltering(includeSegments: boolean) {
    this._getSegmentationForFiltering.cache.delete(includeSegments);
  }

  deleteSegmentation(guid: string) {
    this.invalidateCache();
    return this.httpservice.post({
      url: '/SegmentationService/DeleteSegmentation',
      data: { segmentationGuid: guid },
    });
  }

  toggleActivitySegmentation(activityGuid: string, isDisable: boolean) {
    return this.httpservice.post({
      url: '/SegmentationService/ToggleActivitySegmentation',
      data: { activityGuid, isDisable },
    });
  }

  linkSegmentAnswers(surveyQuestionGuid: string, segmentAnswers: ISegmentAnswer[]) {
    return this.httpservice.post({
      url: '/SegmentationService/LinkSegmentAnswers',
      data: { links: segmentAnswers, surveyQuestionGuid },
    }).then((response) => _.map(response.data, (item) => (
      {
        segmentGuid: item.SegmentGuid,
        answerGuid: item.AnswerGuid,
      } as ISegmentAnswer)));
  }
}
