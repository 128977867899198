'use strict';

import { IscHealthFilterController } from './isc-health-filter.controller';

const template = require('./isc-health-filter.html');
require('./_isc-health-filter.scss');

export const iscHealthFilterComponent: ng.IComponentOptions = {
  controller: IscHealthFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    filterData: '=',
    filterDataChips: '=',
    applyFilter: '&',
    resetFilter: '&',
  },
};
