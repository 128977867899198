'use strict';

import { ForumService } from './../dataservices/forum.service';
import { Utils } from '../utils';
import { IActivityFilterData } from '../contracts/activity.contract';
import { ServerConstants } from '../../core/serverconstants';
import { HelperService } from '../../core/services/helper.service';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import { IProbeQuestionAnswerModel, IUploadConversationStimulus, IUploadConversationAttachment } from 'isc-ui';
import { MuxService } from '../dataservices/mux.service';
import { FileStorageService } from '../dataservices/fileStorage.service';
import { SelectedClientFactory } from '../selectedclient.factory';
export class ConversationService {

  static $inject = [
    'forumservice',
    '$stateParams',
    '$state',
    'spinnerservice',
    '$mdDialog',
    'serverConstants',
    'helperservice',
    'selectedSquareFactory',
    'muxService',
    'fileStorageService',
    '__env',
    'selectedClientFactory',
  ];

  constructor(
    private forumservice: ForumService,
    private $stateParams,
    private $state: ng.ui.IStateService,
    private spinnerservice,
    private $mdDialog: ng.material.IDialogService,
    private serverConstants: ServerConstants,
    private helperservice: HelperService,
    private selectedSquareFactory: SelectedSquareFactory,
    private muxService: MuxService,
    private fileStorageService: FileStorageService,
    private __env: Environment,
    private selectedClientFactory: SelectedClientFactory,
  ) { }
  readonly ITEMS_PER_PAGE = 25;

  public async scrollToAnchorGuid(anchorGuid) {
    const reply = await this.forumservice.getQualConversationElementOrDefault(anchorGuid, this.ITEMS_PER_PAGE);
    this.$state.go('root.square.activitydata.conversations',
      {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
        activityGuid: reply.ActivityGuid,
        page: reply.PageIndex,
        expandedPosts: reply.ExpandedPosts,
        replyGuid: reply.Guid,
      },
      { reload: true },
    );
  }

  public async jumpToReplyQual(replyGuid, activityGuid = null, isDiscussionNew = false) {
    if (!isDiscussionNew) {
      const reply = await this.forumservice.getQualConversationElementOrDefault(replyGuid, this.ITEMS_PER_PAGE);

      this.$state.go('root.square.activitydata.conversations',
        {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid: reply.ActivityGuid,
          page: reply.PageIndex,
          expandedPosts: reply.ExpandedPosts,
          replyGuid: reply.Guid,
        });
    } else {
      if (activityGuid) {
        this.$state.go('root.square.activitydata.discussion', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid,
          replyGuid,
        });
      }
    }
  }

  public async replyToProbingQuestions(
    probingAnswers: IProbeQuestionAnswerModel[],
    originalSquareParticipantGuid?: string,
    activityType?: number,
    conversationFocusType?: number,
    contributionType?: number,
    channelType?: number): Promise<string> {
    const newStimulusFiles: File[] = [].concat(...probingAnswers.map((answer: IProbeQuestionAnswerModel) => answer.newStimulusFiles));
    const newAttachmentFiles: File[] = [].concat(...probingAnswers.map((answer: IProbeQuestionAnswerModel) => answer.newAttachmentFiles));
    // The way we have the backend implemented, unfortunatlly we can't skip this update for the file mappings.
    probingAnswers.forEach((answer: IProbeQuestionAnswerModel) => {
      answer.stimuli = answer.stimuli.map((stimulus: IUploadConversationStimulus) => {
        if (stimulus.file) {
          const stimulusIndex = newStimulusFiles.indexOf(stimulus.file);
          if (stimulusIndex !== -1) {
            stimulus.id = stimulusIndex.toString();
          }
        }
        return stimulus;
      });
      answer.attachments = answer.attachments.map((attachment: IUploadConversationStimulus) => {
        if (attachment.file) {
          const attachmentIndex = newAttachmentFiles.indexOf(attachment.file);
          if (attachmentIndex !== -1) {
            attachment.id = attachmentIndex.toString();
          }
        }
        return attachment;
      });
    });

    probingAnswers.forEach((answer) => {
      answer.stimuli?.forEach((stimulus) => {
        stimulus.videoPlayer = null;
      });
    });

    return await this.forumservice.replyToProbingQuestions(
      probingAnswers,
      newStimulusFiles,
      newAttachmentFiles,
      originalSquareParticipantGuid,
      activityType,
      conversationFocusType,
      contributionType,
      channelType)
      .then((response: string) => response);
  }

  public async jumpToFirstNewReplyQual(
    activityGuid: string,
    pageNumber: number,
    limit,
    filter: IActivityFilterData,
    sortOption,
    expandedPosts,
    expandLatestUpdatePost): Promise<boolean> {
    const reply = await this.forumservice.getFirstUnReadConversation(activityGuid, pageNumber, limit, filter, sortOption, expandedPosts, expandLatestUpdatePost);

    if (!reply) {
      return false;
    }

    this.$state.go('root.square.activitydata.conversations', {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      squareGuid: this.$stateParams.squareGuid,
      activityGuid: reply.ActivityGuid,
      page: reply.PageIndex,
      expandedPosts: reply.ExpandedPosts,
      replyGuid: reply.Guid,
    }, { location: 'replace', inherit: false });
    // Always use inherit: false - to override current state params with provided state params

    return true;
  }

  public async jumpToReplyForum(replyGuid) {
    const currentPage = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
    const reply = await this.forumservice.getConversationLocation(replyGuid, this.ITEMS_PER_PAGE);

    if (this.$state.current.name === 'root.square.forum.room.conversation' &&
      this.$stateParams.forumGuid === reply.PageGuid &&
      this.$stateParams.roomGuid === reply.RoomGuid &&
      this.$stateParams.conversationGuid === reply.ConversationGuid &&
      currentPage === reply.PageIndex) {
      Utils.anchorScrollWithWait(replyGuid);
    } else {
      this.$state.go('root.square.forum.room.conversation',
        {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          forumGuid: reply.PageGuid,
          roomGuid: reply.RoomGuid,
          conversationGuid: reply.ConversationGuid,
          page: reply.PageIndex,
          replyGuid,
        });
    }
  }

  public async jumpToConsumerReplyForum(replyGuid, redirectType) {
    await this.helperservice.goToSquareParticipantForumView(this.selectedSquareFactory.SquareUrl, replyGuid, redirectType);
  }

  public censorConversation(conversation) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: (conversation.Censored ? 'Unflag as inappropriate' : 'Flag as inappropriate'),
      // eslint-disable-next-line max-len
      text: `Do you want to ${conversation.Censored ? 'unflag' : 'flag'} this reply as inappropiate and ${conversation.Censored ? 'show' : 'hide'} it for participants`,
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      this.spinnerservice.show('loading');
      this.forumservice.censorConversation(conversation.Guid)
        .then(() => {
          conversation.Censored = !conversation.Censored;
        }).finally(() => {
          this.spinnerservice.hide('loading');
        });
    });
  }

  public async saveLoungeConversation(
    guid: string,
    parentMessageGuid: string,
    message: string,
    type: number,
    stimuli: IUploadConversationStimulus[],
    attachments: IUploadConversationAttachment[],
    newStimulusFiles: File[],
    newAttachmentFiles: File[],
  ) {
    const request = await this.prepareConversation(guid, parentMessageGuid, message, type, stimuli, attachments);
    return await this.forumservice.replyToForumConversation(request, newStimulusFiles, newAttachmentFiles);
  }

  private async prepareConversation(
    guid: string,
    parentMessageGuid: string,
    message: string,
    type: number,
    stimuli: IUploadConversationStimulus[],
    attachments: IUploadConversationAttachment[],
  ) {

    const request = {
      Guid: guid,
      ParentMessageGuid: parentMessageGuid,
      Message: message,
      Room: this.$stateParams.roomGuid,
      Type: type,
      Stimuli: stimuli,
      Attachments: attachments,
    };

    const preppedStimuli = await this.prepareAndUploadStimuli(stimuli);
    request.Stimuli = preppedStimuli.stimuli;

    const preppedAttachments = await this.prepareAndUploadAttachments(attachments);
    request.Attachments = preppedAttachments.attachments;

    return request;
  }

  // Upload stimuli for non-localhost
  private async prepareAndUploadStimuli(stimuli: IUploadConversationStimulus[])
    : Promise<IStimuliAndFilesResult> {
    const result: IStimuliAndFilesResult = {
      files: [],
      stimuli: [],
    };

    const stimTypeConstants = this.serverConstants.stimulusTypeConstants;

    /*
      Ok, we enter here with the list of stimuli (both already saved and new ones)
        and the list of files for new stimuli (yes, from the first list)

      Now, if we have mux, this is what we`re gonna` do:
        - ignore the input files list, we`re gonna` compose it
        - for each stimulus in list
            - stimulus does not have a new file
                - it will be pushed to the result`s stimuli list (no changes)
            - stimulus has a new video file
                - we try to create mux link and task for background upload
                    - if we fail, we push the stimulus to the result`s stimuli list,
                        as well as the file, so that backend can do whatever the hell
                        it wants with the file, we couldn`t upload it to mux
                    - if we succeed, we get the mux upload ID from the new upload
                        and we push the stimulus with the mux ID to the result`s stimuli list,
                        and we set that stimulus file to undefined so that backend doesn`t
                        upload it, and we don`t push the file to the result`s files list

    Stim types:
    stimulusTypeConstants = {
    photo: 0,
    video: 1,
    youtube: 2,
    vimeo: 3,
    attachment: 4,
  };
    */

    for (const stimulus of stimuli) {
      if (stimulus.file !== undefined && stimulus.type === stimTypeConstants.video) {
        // There is a file and its a video
        if (this.muxService.MuxServiceEnabled) {
          const muxData = await this.muxService.getNewUploadLinkForVideo();
          if (muxData && muxData.data) {
            // We got a new upload ID from backend ;)
            this.muxService.uploadVideo(muxData.data, stimulus.file); // (uploadProgress) => this.updateStimuliUploadTaskProgress(undefined, uploadProgress)
            // We do not put files to result, instead, we put stimuli with ID
            // stimulus.file = undefined; we do not hide the file until save
            stimulus.fileContentToBeRemoved = true;
            stimulus.id = muxData.data.Id;
          } else {
            result.files.push(stimulus.file);
          }
        } else {
          // Azure media services
          const videoData = await this.fileStorageService.GetNewUploadLinkForVideo(stimulus.file.type);
          if (videoData && videoData.data) {
            // Upload into azure media services using the Url from amsData.data
            this.fileStorageService.uploadVideo(videoData.data, stimulus.file);
            stimulus.fileContentToBeRemoved = true;
            stimulus.id = videoData.data.StimuliGuid;
            stimulus.url = videoData.data.Url.split('?')[0];
            stimulus.thumbnailUrl = this.selectedClientFactory.VideoStorage === this.serverConstants.clientVideoStorageConstants.azureMediaServices
              ? this.GetThumbnailUrl(stimulus.url)
              : null;
          } else {
            result.files.push(stimulus.file);
          }
        }
        result.stimuli.push(stimulus);
      } else if (this.__env.environment !== 'LOCALHOST' && stimulus.file !== undefined && stimulus.type === stimTypeConstants.photo) {
        // There is a file and is a image
        const photoUploadData = await this.fileStorageService.GetNewUploadLinkForPhoto(stimulus.file.type);
        if (photoUploadData && photoUploadData.data) {
          this.fileStorageService.uploadImage(photoUploadData.data, stimulus.file);
          // stimulus.file = undefined; we do not hide the file until save
          stimulus.fileContentToBeRemoved = true;
          stimulus.url = photoUploadData.data.PhotoSasUrl.split('?')[0];
          stimulus.thumbnailUrl = this.GetThumbnailUrl(photoUploadData.data.PhotoSasUrl.split('?')[0]);
          stimulus.id = photoUploadData.data.StimuliGuid;
        } else {
          result.files.push(stimulus.file);
        }
        result.stimuli.push(stimulus);
      } else {
        result.stimuli.push(stimulus);
        if (stimulus.file !== undefined) {
          result.files.push(stimulus.file);
        }
      }
    }
    return result;
  }

  // Upload attachments for non-localhost
  private async prepareAndUploadAttachments(
    attachments: IUploadConversationAttachment[]): Promise<IAttachmentAndFilesResult> {
    const result: IAttachmentAndFilesResult = {
      files: [],
      attachments: [],
    };
    for (const attachment of attachments) {
      if (this.__env.environment !== 'LOCALHOST' && attachment.file !== undefined) {
        const attachmentUploadData = await this.fileStorageService.GetNewUploadLinkForAttachment(attachment.file.type);
        if (attachmentUploadData && attachmentUploadData.data) {
          this.fileStorageService.uploadAttachment(attachmentUploadData.data, attachment.file);
          attachment.mimeType = attachment.file.type;
          attachment.file = undefined;
          attachment.id = attachmentUploadData.data.AttachmentGuid;
          attachment.url = attachmentUploadData.data.AttachmentSasUrl.split('?')[0];
        } else {
          result.files.push(attachment.file);
        }
        result.attachments.push(attachment);
      } else {
        result.attachments.push(attachment);
        if (attachment.file !== undefined) {
          result.files.push(attachment.file);
        }
      }
    }

    return result;
  }

  private GetThumbnailUrl(url: string) {
    const extension = url.substring(url.lastIndexOf('.'));
    // the _medium string added to the thumbnailUrl is hardcoded and should be consistent with how ThumbnailService creates thumbnails
    // thumbnail service use png encoding, that's why is hardcoded here
    return url.replace(extension, '_medium.png');
  }
}

interface IStimuliAndFilesResult {
  stimuli: IUploadConversationStimulus[];
  files: File[];
}

interface IAttachmentAndFilesResult {
  attachments: IUploadConversationAttachment[];
  files: File[];
}
