'use strict';

import { CommunicationPublishController } from './communicationPublish.controller';

const template = require('./communicationPublish.html');

export const communicationPublish: ng.IComponentOptions = {
  templateUrl: template,
  controller: CommunicationPublishController,
  controllerAs: 'vm',
  require: {
    wizardStep: '^^iscWizardStep',
    wizard: '^^iscWizard',
  },
  bindings: {
    communication: '<',
    isReadOnly: '<',
  },
};
