'use strict';

const template = require('./activityPublish.html');

import { ActivityPublishController } from './activityPublish.controller';

angular
  .module('insitesApp.activityconfig')
  .component('activityPublish', {
    templateUrl: template,
    controller: ActivityPublishController,
    controllerAs: 'vm',
    bindings: {
      squareActivity: '<',
      isPublished: '<',
      isReadOnly: '<',
      surveyPreparationStatus: '<',
    },
    require: {
      wizardStep: '^^iscWizardStep',
    },
  });
