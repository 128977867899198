'use strict';

export class ToggleableService {
  static $inject = ['_'];

  constructor(private _) { }
  private elements = [];

  register(el, registerId) {
    this.elements.push({ element: el, id: registerId });
  }

  toggle(registerId) {
    const toggleItem = this._.find(this.elements, (item) => item.id === registerId);
    if (toggleItem && toggleItem.element) {
      toggleItem.element.toggle();
    }
  }
}
