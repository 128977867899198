'use strict';


import { ReplaySubject } from 'rxjs/ReplaySubject';

export class SelectedProgramFactory implements IProgramInfo {
  private name = null;
  private guid = null;
  private _programInfo = new ReplaySubject<IProgramInfo>(1);

  constructor() {
  }

  get Name() {
    return this.name;
  }

  get Guid() {
    return this.guid;
  }

  get programInfoObservable() {
    return this._programInfo.distinctUntilChanged((a, b) => a.Guid === b.Guid);
  }

  setProgramInfo(programInfo: IProgramInfo) {
    this.name = programInfo.Name;
    this.guid = programInfo.Guid;
    this.infoChanged();
  }

  resetProgramInfo() {
    this.name = null;
    this.guid = null;
    this.infoChanged();
  }

  private infoChanged() {
    this._programInfo.next({
      Guid: this.guid,
      Name: this.name,
    });
  }
}

export interface IProgramInfo {
  Name: string,
  Guid: string,
}

angular
  .module('insitesApp.core')
  .service('selectedProgramFactory', SelectedProgramFactory);
