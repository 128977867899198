'use strict';

import * as angular from 'angular';

import './squareActivities.module';
import './activitiesAllTab';
import './activitiesQualTab';
import './activitiesQuantTab';
import './activitySequenceConfirm';
import routeConfig from './squareActivities.route';
import { squareActivitiesComponent } from './squareActivities.component';
import { AddActivityDialogController } from './addActivityDialog.controller';
import { SquareActivitiesDragService } from './squareActivitiesDrag.service';
angular
  .module('insitesApp.squareActivities')
  .controller('AddActivityDialogController', AddActivityDialogController)
  .component('squareActivitiesComponent', squareActivitiesComponent)
  .service('squareActivitiesDragService', SquareActivitiesDragService)
  .run(routeConfig);
