'use strict';

import { getAccessibleName } from 'accname';
import * as _ from 'lodash';
export class NavigationHelperService {
  static $inject = ['$timeout', '$mdDialog', '$q'];
  constructor(private $timeout, private $mdDialog, private $q) { }

  camelToFlat = (c) => {
    if (!/^([a-z]+[A-Z]*)+$/.test(c)) {
      return c;
    }
    c = c.replace(/[A-Z]/g, ' $&');
    return c[0].toUpperCase() + c.slice(1).toLowerCase();
  }

  getErrors = (errors) => {
    const errorLabels = [];
    for (const error in errors) {
      if (errors.hasOwnProperty(error)) {
        errors[error].forEach((field) => {
          if (field) {
            const label = getAccessibleName(field.$$element[0]);
            if (label) {
              errorLabels.push(this.camelToFlat(label));
            } else if (field.$name) {
              errorLabels.push(this.camelToFlat(field.$name));
            }
          }
        });
      }
    }
    return  _.uniq(errorLabels);
  }

  onNavigate(
    canNavigate,
    isDirty,
    errors,
    saveNotApplicable,
    customMessage,
    navigationErrorMessage,
    transitionCallback,
    saveCallback,
    customValidation,
    isWizardStep,
    resetFormCallback,
    hideSaveDraftBtn) {
    if (customValidation !== undefined) {
      const validationResult = customValidation();
      isDirty = validationResult.isDirty;
      canNavigate = validationResult.canNavigate;
      hideSaveDraftBtn = validationResult.hideSaveDraftBtn || hideSaveDraftBtn;
      navigationErrorMessage = validationResult.navigationErrorMessage || navigationErrorMessage;
    }

    if (!canNavigate) {
      const errorFields = `<ul>${this.getErrors(errors).map((err) => `<li>${err}</li>`).join('')}</ul>`;
      this.$timeout(() => {
        this.$mdDialog.show(
          this.$mdDialog.iscAlert()
            .title('Action Required')
            .text(navigationErrorMessage.replace('$errors', errorFields))
            .ok('Got it!'),
        );
      });
      return this.$q.reject();
    } else if (isDirty) {
      if (!customMessage) {
        customMessage = 'Do you want to leave and lose your changes?';
      }
      if (saveNotApplicable) {
        this.$mdDialog.show(this.$mdDialog
          .iscConfirm()
          .text(customMessage)
          .ok('Leave')
          .cancel('Stay')
          .clickOutsideToClose(true),
        ).then(() => {
          if (isWizardStep) {
            resetFormCallback();
          }
          transitionCallback();
        }, () => {
            // do nothing. This needs to be here to not throw an error
        });
      } else if (!hideSaveDraftBtn) {
        this.$mdDialog.show(this.$mdDialog
          .iscConfirm()
          .text(customMessage)
          .stay('Stay')
          .cancel('Leave')
          .ok('Save and leave'),
        ).then(() => {
          saveCallback().then(() => {
            transitionCallback();
          });
        }, (stay) => {
          if (!stay) {
            if (isWizardStep) {
              resetFormCallback();
            }
            transitionCallback();
          }
        });
      } else {
        this.$mdDialog.show(this.$mdDialog
          .iscConfirm()
          .text('Do you want to leave and lose your changes?')
          .stay('Stay')
          .cancel('Leave'),
        ).then(() => {
          saveCallback().then(() => {
            transitionCallback();
          });
        }, (stay) => {
          if (!stay) {
            if (isWizardStep) {
              resetFormCallback();
            }
            transitionCallback();
          }
        });
      }
      return this.$q.reject();
    } else if (isWizardStep) {
      transitionCallback();
    }
  }
}
