'use strict';

const fileInputRequiredValidator = () => ({
  restrict: 'A',
  require: 'ngModel',
  link: (scope, element, attrs, ngModel) => {
    ngModel.$validators.required = () => {
      // To do - check
      const currentValue = ngModel.$viewValue;
      let result = true;
      if (!currentValue
          || !currentValue.length
          || (currentValue.length > 0 && currentValue[currentValue.length - 1].action === 'delete')) {
        result = false;
      }
      return result;
    };
  },
});

angular
  .module('insitesApp.squareActivities')
  .directive('fileInputRequiredValidator', fileInputRequiredValidator);
