'use strict';

import './cardChannel';
import './newsCardChannel';
import './newsletterChannel';
import './incompleteEmailChannel';
import './newsCardChannel';
import './reminderEmailChannel';
import './emailChannel';
import './platformNotificationChannel';
import './common';
import './fileInput';

import * as angular from 'angular';
import { communicationChannel } from './communicationChannel.component';
angular
  .module('insitesApp.squarecommunicationWizard')
  .component('communicationChannel', communicationChannel);
