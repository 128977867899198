'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.communicationparent',
      config: {
        url: '',
        abstract: true,
        template: '<ui-view />',
        title: 'Communication',
        default: '.overview',
        settings: {
          nav: 5,
          icon: 'fa fa-bullhorn',
          label: 'Communication',
          showForums: true,
          section: 'SQUARE MANAGEMENT',
        },
        disallowRoles: ['Observer'],
      },
    },
    {
      state: 'root.square.communicationparent.overview',
      config: {
        url: '/communication/overview?page?limit?order?statuses?generalType?keyword?triggerDate?',
        component: 'messageOverview',
        title: 'Communication Overview',
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

