'use strict';

import { ServerConstants } from '../../../../core/serverconstants';

export class AddPageDialogController {
  static $inject = ['pageTypes', 'pages', '$mdDialog', 'serverConstants'];
  constructor(
    private pageTypes,
    private pages,
    private $mdDialog: ng.material.IDialogService,
    private serverConstants: ServerConstants) {
    this.validationConstants = serverConstants.validationConstants;
    this.init();
  }

  validationConstants;
  pageNameMaxLength = this.serverConstants.validationConstants.pagenameMaxLength;
  isNameFieldDisabled;

  Page = {
    Name: '',
    PageType: null,
  };

  private init = () => {
    if (this.pageTypes !== undefined && this.pageTypes.length === 1) {
      const page = this.pageTypes[0];
      this.Page.PageType = page.pageType;
    }
  };

  cancel = () => this.$mdDialog.cancel();

  addPage = () => this.$mdDialog.hide(this.Page);

  selectedItemChanged = (pageType) => {
    if (pageType !== this.serverConstants.pageTypeConstants.platformTour) {
      this.Page.Name = '';
      this.isNameFieldDisabled = false;
    } else {  // If a platform tour page is added
      this.isNameFieldDisabled = true;
      this.Page.Name = 'Platform tour';
    }
  };
}

angular
  .module('insitesApp.squareconfig.tabComponents.pageEditor')
  .controller('AddPageDialogController', AddPageDialogController);
