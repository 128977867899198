'use strict';

const template = require('./edit-button.html');
import { EditButtonController } from './edit-button.controller';

export const EditButtonComponent: ng.IComponentOptions = {
  controller: EditButtonController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
  },
};
