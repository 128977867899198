'use strict';

import { HttpService } from './http.service';

export class LogService {
  static $inject = ['httpservice'];

  constructor(private httpservice: HttpService) { }

  getLogList(startDate, endDate, severity, limit, clientCode, message, url) {
    return this.httpservice.get({
      url: '/LogService/List',
      params: {
        startDate,
        endDate,
        severity,
        pageSize: limit,
        clientCode,
        message,
        url,
      },
    });
  }
}
