'use strict';

import { TableArrowsController } from './isc-table-arrows.controller';

const tableArrowsTemplate = require('./isc-table-arrows.html');
require('./isc-table-arrows.scss');

export const TableArrowsComponent: ng.IComponentOptions = {
  templateUrl: tableArrowsTemplate,
  controller: TableArrowsController,
  controllerAs: 'vm',
};
