'use strict';

import { DonutChartController } from './donut-chart.controller';

const template = require('./donut-chart.html');

export const DonutChartComponent: ng.IComponentOptions = {
  controller: DonutChartController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    donutData: '<',
    donutCenter: '<',
    donutSubCenter: '<',
    donutPreventStatusHide: '<',
  },
};
