'use strict';

import _ = require('lodash');

const filterSegmentation = ($filter: ng.IFilterService) => (segmentations, query) =>
  _.filter(segmentations, (segmentation) => {
    const filtered = $filter('filter')(segmentation.SegmentItems, query);
    return _.some(filtered) || (query && segmentation.Question.toLowerCase().indexOf(query.SegmentAnswer.toLowerCase()) > -1);
  });

filterSegmentation.$inject = ['$filter'];

export const segmentationsFilter = filterSegmentation;

const filterSegment = ($filter: ng.IFilterService) => (segments, query, segmentationName) => {
  let result = [];
  const filteredSegments = $filter('filter')(segments, query);
  // If searchterm is found in the segmentation name or any segment name
  // => return all segments: requirement comes with task 30186
  if (filteredSegments.length > 0 || (query && segmentationName.toLowerCase().indexOf(query.SegmentAnswer.toLowerCase()) > -1)) {
    result = segments;
  }

  return result;
};

filterSegment.$inject = ['$filter'];

export const segmentsFilter = filterSegment;
