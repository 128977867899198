'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';
import { DesignController } from './design.controller';

const template = require('./design.html');

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'root.square.design',
    config: {
      url: '/config/design',
      templateUrl: template,
      controller: DesignController,
      controllerAs: 'vm',
      title: 'Design',
      settings: {
        nav: 2,
        childnav: 6,
        label: 'Design',
        showForums: true,
      },
      disallowRoles: ['Observer'],
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

