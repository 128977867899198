'use strict';

export class FocusWithinMenu implements ng.IDirective {
  link(scope, element) {
    element.on('keydown', (event) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        const focusableItems = element[0].querySelectorAll('button');
        focusableItems.forEach((item) => {
          item.setAttribute('tabindex', '-1');
        });
        const currentIndex = Array.prototype.indexOf.call(focusableItems, document.activeElement);

        let newIndex;
        if (event.key === 'ArrowDown') {
          // Move focus to the next item
          newIndex = (currentIndex === focusableItems.length - 1) ? 0 : currentIndex + 1;
        } else if (event.key === 'ArrowUp') {
          // Move focus to the previous item
          newIndex = (currentIndex === 0) ? focusableItems.length - 1 : currentIndex - 1;
        }

        focusableItems[newIndex].focus();
      }
    });
  }
}
