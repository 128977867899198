'use strict';

import { ActivityInfoController } from './activityInfo.controller';

const template = require('./activityInfo.html');
require('./activityInfo.scss');

export const ActivityInfoComponent: ng.IComponentOptions = {
  controller: ActivityInfoController,
  templateUrl: template,
  controllerAs: 'vm',
};
