'use strict';

import { VueToggle } from './../../core/vueToggle';
import * as angular from 'angular';
import routeConfig from './activation.route';
import { ActivationPage, createApp } from '@insites/vue-eco-temp-library';

angular
  .module('insitesApp.activation', ['insitesApp.home'])
  .component('activationPage', {
    controller: ['VueToggle', function(vueToggle: VueToggle) {
      this.$postLink = () => {
        vueToggle.mount(createApp(ActivationPage),'activation-page');
      };
      this.$onDestroy = () => {
        vueToggle.unMount();
      };
    }],
  })
  .run(routeConfig);
