'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';
import { ActivityFilterService } from '../../../core/services/activityFilter.service';

const routeConfig = (routerHelper: RouterHelper, activityFilterService: ActivityFilterService) => {
  routerHelper.configureStates([
    {
      state: 'root.square.activities.qual',
      config: {
        title: 'Qualitative Research',
        url: `qual/?${activityFilterService.getQualFilterUrlParts()}?page?limit?order`,
        component: 'activitiesQualTab',
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', 'activityFilterService'];

export default routeConfig;

