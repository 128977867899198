'use strict';

import { Logger } from '../logger/logger';
import { SelectedClientFactory } from '../../core/selectedclient.factory';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import { AuthService } from '../../core/dataservices/auth.service';
import { RouterHelperProvider } from './router-helper.provider';
import { RejectType, Transition, UIRouter, UrlRouterProvider } from '@uirouter/angularjs';
import { IState } from 'angular-ui-router';

export class RouterHelper {
  constructor(
    private config: RouterHelperProvider['config'],
    private $urlRouterProvider: UrlRouterProvider,
    private $stateProvider: ng.ui.IStateProvider,
    private $location: ng.ILocationService,
    private $rootScope,
    private $transitions: Transition,
    private $state: ng.ui.IStateService,
    private logger: Logger,
    private $injector,
    private $window: ng.IWindowService,
    private selectedClientFactory: SelectedClientFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private authService: AuthService,
    private $uiRouter: UIRouter) {
    this.init();
  }

  handlingStateChangeError;
  stateCounts;

  init = () => {
    this.handlingStateChangeError = false;
    this.stateCounts = {
      errors: 0,
      changes: 0,
    };

    this.handleRoutingErrors();
    const $mdToast = this.$injector.get('$mdToast');
    this.$transitions.onSuccess({}, (transition) => {
      this.marksAsVuePage(transition);
      this.updateDocTitle(transition);
      $mdToast.hide();
    });
  };

  configureStates = (states: Array<{ state: string, config: StateConfig }>) => {
    states.forEach((state) => {
      state.config.resolve =
        angular.extend(state.config.resolve || {}, this.config.resolveAlways);
      if (state.config.requireADLogin === undefined) {
        state.config.requireADLogin = true;
      }
      this.$stateProvider.state(state.state, state.config);
    });

    // Set otherwise state: go to 404 page in case no state matches the url
    this.$urlRouterProvider.otherwise(() => {
      // location: false to not update the url
      this.$state.go('root.404', {}, { location: false });
    });
  };

  handleRoutingErrors = () => {
    // Route cancellation:
    // On routing error, go to the dashboard.
    // Provide an exit clause if it tries to do it twice.
    this.$transitions.onError({}, (transition) => {
      const error = transition.error();

      if (this.handlingStateChangeError || error.type === RejectType.SUPERSEDED) {
        return;
      }

      this.stateCounts.errors++;
      this.handlingStateChangeError = true;
      const transitionTo = transition.to();
      const destination = (transitionTo &&
        (transitionTo.data.title || transitionTo.name || transitionTo.data.loadedTemplateUrl)) ||
        'unknown target';
      const msg = `Error routing to ${destination}. ${error.message || ''}`;
      this.logger.warning(msg, [transitionTo]);
      if (error.type === RejectType.ERROR) {
        this.$location.path('/');
      }

      // Since we already handle errors via $transitions.onError, we can disable the defaultErrorHandler
      this.$uiRouter.stateService.defaultErrorHandler(() => null);
    });
  };

  getStates = () => this.$state.get();

  updateDocTitle = (transition) => {
    this.stateCounts.changes++;
    this.handlingStateChangeError = false;
    const title = `${this.config.docTitle} ${transition.$to().self.title || ''}`;
    this.$rootScope.title = title; // Data bind to <title>
  };

  marksAsVuePage = (transition: Transition) => {
    if ((transition.to() as StateConfig).isVue) {
      document.body.classList.add('vue-page');
    } else {
      document.body.classList.remove('vue-page');
    }
  };

  static factory(config, $urlRouterProvider, $stateProvider) {
    const helper =
      ($location, $rootScope, $transitions, $state, logger, $injector, $window, selectedClientFactory, selectedSquareFactory, authService, $uiRouter) =>
        new RouterHelper(config, $urlRouterProvider, $stateProvider, $location, $rootScope, $transitions, $state,
          logger, $injector, $window, selectedClientFactory, selectedSquareFactory, authService, $uiRouter);

    helper.$inject = ['$location', '$rootScope', '$transitions', '$state',
      'logger', '$injector', '$window', 'selectedClientFactory', 'selectedSquareFactory', 'authService', '$uiRouter'];
    return helper;
  }

  static insitesUserInvalidSquareGuid;

}

export interface StateConfig extends IState {
  requireLogin?: boolean;
  requireADLogin?: boolean;
  allowAnonymous?: boolean;
  activateRoute?: string;
  prevState?: string | { state: string; params: Record<string, string> };
  mobileTitle?: string;
  default?: string;
  title?: string;
  settings?: Record<string, any>;
  disallowRoles?: string[];
  isFeatureEnabled?: () => Promise<boolean>;
  isVue?: boolean;
}
