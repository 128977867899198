'use strict';

import { CardController } from './cardChannel.controller';

const template = require('./cardChannel.html');

angular
  .module('insitesApp.squarecommunicationWizard', [])
  .component('cardChannel', {
    templateUrl: template,
    controller: CardController,
    controllerAs: 'vm',
    require: {
      wizardStep: '^^iscWizardStep',
    },
    bindings: {
      description: '@',
      channelType: '<',
      timeZone: '<',
      communication: '<',
      isReadOnly: '<',
    },
  });


