'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const getDesignConfigStates = () => [{
  state: 'root.clientconfigdesign',
  config: {
    url: 'client/:clientGuid/config/design',
    component: 'designConfiguration',
    title: 'Design',
    settings: {
      nav: 3,
      childnav: 3,
      label: 'Design',
      section: 'Set-up',
    },
    disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
  },
}];

const appRunDesignConfiguration = (routerHelper: RouterHelper) => {
  routerHelper.configureStates(getDesignConfigStates());
};
appRunDesignConfiguration.$inject = ['routerHelper'];

export default appRunDesignConfiguration;
