'use strict';

import { PlatformNotificationController } from './platformNotificationChannel.controller';

const template = require('./platformNotificationChannel.html');

angular
  .module('insitesApp.squarecommunicationWizard')
  .component('platformNotificationChannel', {
    templateUrl: template,
    controller: PlatformNotificationController,
    controllerAs: 'vm',
    require: {
      wizardStep: '^^iscWizardStep',
    },
    bindings: {
      description: '@',
      channelType: '<',
      timeZone: '<',
      communication: '<',
      isReadOnly: '<',
    },
  });
