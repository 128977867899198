'use strict';

import { ActivityRewardsController } from './activityRewards.controller';

const template = require('./activityRewards.html');

export const ActivityRewardsComponent: ng.IComponentOptions = {
  controller: ActivityRewardsController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    allowRewardsEdit: '<',
    allowExecutionDateEdit: '<',
  },
  require: {
    form: '^^form',
  },
};
