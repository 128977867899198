'use strict';

import { IServiceProvider } from 'angular';

const configuration = ($provide) => {
  $provide.decorator('$exceptionHandler', extendExceptionHandler);
};
configuration.$inject = ['$provide'];

const extendExceptionHandler = ($delegate, exceptionHandler, logger) => (exception, cause) => {
  exception = typeof exception === 'string' ? new Error(exception) : exception;
  const appErrorPrefix = ((exceptionHandler || {}).config || {}).appErrorPrefix || '';
  const errorData = { exception, cause };
  exception.message = appErrorPrefix + exception.message;
  $delegate(exception, cause);
  /**
     * Could add the error to a service's collection,
     * add errors to $rootScope, log errors to remote web server,
     * or log locally. Or throw hard. It is entirely up to you.
     * throw exception;
     *
     * @example
     *     throw { message: 'error message we added' };
     */
  logger.error(exception.message, errorData);
};

extendExceptionHandler.$inject = ['$delegate', 'exceptionHandler', 'logger'];

export class ExceptionHandlerProvider implements IServiceProvider {
  config = {
    appErrorPrefix: undefined,
  };

  configure = (appErrorPrefix) => {
    this.config.appErrorPrefix = appErrorPrefix;
  };

  $get = () => this.config;
}

export const config = configuration;
