'use strict';

const htmlTextFilter = () => {
  const element = document.createElement('div');
  return (html) => {
    element.innerHTML = html;
    return element.textContent;
  };
};

angular
  .module('insitesApp.layout')
  .filter('htmlText', htmlTextFilter);
