import { UiModule } from '../../../index';
import { IscProgressCircularController } from './isc-progress-circular.controller';
var template = "<md-progress-circular class=\"background\" md-mode=\"determinate\" value=\"100\" md-diameter=\"170\"></md-progress-circular>\n<md-progress-circular class=\"{{vm.color}}\" md-mode=\"determinate\" value=\"{{vm.percentage}}\" md-diameter=\"170\"></md-progress-circular>\n<div class=\"text\" ng-transclude=\"iscProgressCircularContent\"></div>";
UiModule
    .component('iscProgressCircular', {
    template: template,
    controller: IscProgressCircularController,
    controllerAs: 'vm',
    bindings: {
        percentage: '<',
        color: '@',
    },
    transclude: {
        iscProgressCircularContent: 'iscProgressCircularContent',
    },
});
