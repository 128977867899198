'use strict';

import { ActivityEmailIncompleteController } from './activityEmailIncompleteChannel.controller';

const template = require('./activityEmailIncompleteChannel.html');

angular
  .module('insitesApp.squarecommunicationWizard')
  .component('activityEmailIncompleteChannel', {
    templateUrl: template,
    controller: ActivityEmailIncompleteController,
    controllerAs: 'vm',
    bindings: {
      description: '@',
      communication: '=',
      isReadOnly: '<',
    },
    require: {
      wizardStep: '^^iscWizardSubstep',
    },
  });
