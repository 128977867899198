'use strict';

import { AuthService } from './../../core/dataservices/auth.service';

export class ClientSelectionController {
  static $inject = ['authService'];

  title = 'Home';
  clients = [];
  firstname = '';

  constructor(
    private authService: AuthService,
  ) { }

  async $onInit() {
    this.firstname = this.authService.firstName;
  }
}
