'use strict';

import { SegmentationService } from '../dataservices/segmentation.service';

export class SegmentationValidator implements ng.IDirective {
  constructor(
    private $q: ng.IQService,
    private segmentationservice: SegmentationService) { }

  restrict = 'A';
  require = 'ngModel';

  link(scope, element, attrs, ngModel) {
    ngModel.$asyncValidators.uniqueSegmentationname = (modelValue, viewValue) => {
      const segmentation = {
        name: viewValue,
        segmentationguid: Object.prototype.hasOwnProperty.call(attrs,'segmentationguid') ? attrs.segmentationguid : null,
      };

      return this.segmentationservice.isSegmentationNameUnique(segmentation).then(
        (response) => {
          if (response.data === 'False') {
            return this.$q.reject();
          }

          return response.data;
        });
    };
  }
}
