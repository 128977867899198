import * as angular from 'angular';
var IscWizardStepController = (function () {
    function IscWizardStepController(_) {
        this._ = _;
        this.substeps = [];
        this.currentStepIndex = -1;
        this.isLast = false;
        this.position = -1;
        this.component = undefined;
        this.state = null;
    }
    IscWizardStepController.prototype.$onInit = function () {
        this.wizardComponent.addStep(this);
    };
    IscWizardStepController.prototype.$onDestroy = function () {
        this.wizardComponent.removeStep(this);
    };
    IscWizardStepController.prototype.show = function () {
        if (this.getState() === this.wizardComponent.constants.state.current_complete
            || this.getState() === this.wizardComponent.constants.state.current_incomplete) {
            return true;
        }
        return false;
    };
    IscWizardStepController.prototype.addSubstep = function (substep) {
        var index = 0;
        index = this.substeps.push(substep);
        substep.position = index;
        this.wizardComponent.setNextStepNextSubstepIndices();
    };
    IscWizardStepController.prototype.removeSubstep = function (substep) {
        this._.pull(this.substeps, substep);
        this._.forEach(this.substeps, function (sub, i) { return sub.position = i + 1; });
    };
    IscWizardStepController.prototype.formattedCaption = function () {
        return this.caption + (this.currentStepIndex === -1 ? '' : ' > ' + this.substeps[this.currentStepIndex].caption);
    };
    IscWizardStepController.prototype.formattedDescription = function () {
        return this.currentStepIndex === -1 ? this.description : this.substeps[this.currentStepIndex].description;
    };
    IscWizardStepController.prototype.nextSubstep = function () {
        if (this.currentStepIndex >= this.substeps.length - 1) {
            this.currentStepIndex = -1;
            return false;
        }
        this.currentStepIndex++;
        this.wizardComponent.currentSubstep = this.substeps[this.currentStepIndex];
        return true;
    };
    IscWizardStepController.prototype.previousSubstep = function () {
        var result = false;
        var previousStep = this.wizardComponent.steps[this.wizardComponent.currentStepIndex - 1];
        var previousStepHasSubsteps = previousStep.substeps.length > 0;
        var stepIndexesWithSubsteps = [];
        this._.forEach(this.wizardComponent.steps, function (step) {
            if (step.substeps.length > 0) {
                stepIndexesWithSubsteps.push(step.position - 1);
            }
        });
        var currentStep = this.wizardComponent.steps[this.wizardComponent.currentStepIndex];
        var currentStepHasSubsteps = this._.includes(stepIndexesWithSubsteps, (this.wizardComponent.currentStepIndex));
        if (previousStepHasSubsteps) {
            if (currentStepHasSubsteps) {
                if (currentStep.currentStepIndex > -1) {
                    currentStep.currentStepIndex--;
                    result = true;
                }
                else {
                    result = this.goToLastSubstepOfPreviousStepWithSubsteps(previousStep);
                }
            }
            else {
                result = this.goToLastSubstepOfPreviousStepWithSubsteps(previousStep);
            }
        }
        else {
            if (currentStepHasSubsteps) {
                if (currentStep.currentStepIndex > -1) {
                    currentStep.currentStepIndex--;
                    result = true;
                }
            }
        }
        return result;
    };
    IscWizardStepController.prototype.goToLastSubstepOfPreviousStepWithSubsteps = function (previousStep) {
        var lastSubstepIndexFromPreviousStepWithSubsteps = this._.findLastIndex(previousStep.substeps, function (substep) { return substep; });
        previousStep.currentStepIndex = lastSubstepIndexFromPreviousStepWithSubsteps;
        this.wizardComponent.currentStepIndex--;
        return true;
    };
    IscWizardStepController.prototype.goToSubstep = function (substep) {
        if (this.currentStepIndex >= 0) {
            return false;
        }
        this.currentStepIndex = this._.findIndex(this.substeps, substep);
        return true;
    };
    IscWizardStepController.prototype.onBeforeNextStep = function () {
        if (this.currentStepIndex === -1) {
            return this.forwardToLinkedComponent('$wizardBeforeNext');
        }
        return this.substeps[this.currentStepIndex].onBeforeNextStep();
    };
    IscWizardStepController.prototype.onBeforePreviousStep = function () {
        if (this.currentStepIndex === -1) {
            return this.forwardToLinkedComponent('$wizardBeforePrevious');
        }
        return this.substeps[this.currentStepIndex].onBeforePreviousStep();
    };
    IscWizardStepController.prototype.onStepLoaded = function () {
        if (this.currentStepIndex === -1) {
            return this.forwardToLinkedComponent('$wizardStepLoaded');
        }
        return this.substeps[this.currentStepIndex].onStepLoaded();
    };
    IscWizardStepController.prototype.loadData = function () {
        if (this.currentStepIndex === -1) {
            return this.forwardToLinkedComponent('$loadData', false);
        }
        return this.substeps[this.currentStepIndex].loadData();
    };
    IscWizardStepController.prototype.isCompleted = function () {
        return this.forwardToLinkedComponent('$wizardStepIsCompleted', false);
    };
    IscWizardStepController.prototype.isValid = function () {
        return this.forwardToLinkedComponent('$wizardIsValid', true);
    };
    IscWizardStepController.prototype.nextDescription = function () {
        var label = '';
        if (this.currentStepIndex !== -1) {
            label = this.substeps[this.currentStepIndex].nextDescription();
        }
        if (!label) {
            label = this.forwardToLinkedComponent('$wizardNextDescription');
        }
        return label;
    };
    IscWizardStepController.prototype.nextLabel = function () {
        if (this.currentStepIndex === -1) {
            return this.forwardToLinkedComponent('$wizardNextLabel');
        }
        return this.substeps[this.currentStepIndex].nextLabel();
    };
    IscWizardStepController.prototype.previousLabel = function () {
        if (this.currentStepIndex === -1) {
            return this.forwardToLinkedComponent('$wizardPreviousLabel');
        }
        return this.substeps[this.currentStepIndex].previousLabel();
    };
    IscWizardStepController.prototype.goto = function (substepComponentName) {
        var _this = this;
        return this.wizardComponent.resolveStep(substepComponentName)
            .then(function (component) {
            var found;
            for (var i = 0; i < _this.substeps.length; i++) {
                var substep = _this.substeps[i];
                if (!found) {
                    if (substep.component === component) {
                        substep.state = substep.isCompleted() ? _this.wizardComponent.constants.state.current_complete : _this.wizardComponent.constants.state.current_incomplete;
                        _this.currentStepIndex = i;
                        found = substep;
                    }
                    else {
                        substep.state = _this.wizardComponent.constants.state.complete;
                    }
                }
                else {
                    substep.state = substep.isCompleted() ? _this.wizardComponent.constants.state.complete : _this.wizardComponent.constants.state.incomplete;
                }
            }
            if (found) {
                found.onStepLoaded();
            }
        });
    };
    IscWizardStepController.prototype.forwardToLinkedComponent = function (methodName, defaultValue) {
        if (typeof this.component === 'undefined') {
            return defaultValue;
        }
        var method = this.component[methodName];
        if (!angular.isFunction(method)) {
            return defaultValue;
        }
        return method.apply(this.component);
    };
    IscWizardStepController.prototype.linkComponent = function (name, component) {
        this.component = component;
        return this.wizardComponent.linkStep(this, name, component);
    };
    IscWizardStepController.prototype.resolveComponent = function (name) {
        return this.wizardComponent.resolveStep(name);
    };
    IscWizardStepController.prototype.getState = function () {
        if ((this.position - 1) < this.wizardComponent.currentStepIndex) {
            this.state = this.wizardComponent.constants.state.complete;
        }
        else if (((this.position - 1) === this.wizardComponent.currentStepIndex)) {
            this.state = this.isCompleted() ? this.wizardComponent.constants.state.current_complete : this.wizardComponent.constants.state.current_incomplete;
        }
        else if ((this.position - 1) === (this.wizardComponent.nextHoverableStepIndex)) {
            this.state = this.wizardComponent.constants.state.next;
        }
        else {
            this.state = this.isCompleted() ? this.wizardComponent.constants.state.complete : this.wizardComponent.constants.state.incomplete;
        }
        return this.state;
    };
    IscWizardStepController.$inject = ['_'];
    return IscWizardStepController;
}());
export { IscWizardStepController };
