'use strict';

import { ActivityDiscussionNewBuildController } from './activityDiscussionNewBuild.controller';
require('./activityDiscussionNewBuild.scss');

const template = require('./activityDiscussionNewBuild.html');

export const ActivityDiscussionNewBuildComponent: ng.IComponentOptions = {
  controller: ActivityDiscussionNewBuildController,
  templateUrl: template,
  controllerAs: 'vm',
  require: {
    wizardStep: '^^iscWizardStep',
  },
  bindings: {
    squareActivity: '=',
    isReadOnly: '<',
    isPublished: '<',
  },
};
