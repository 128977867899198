'use strict';

import ng = require('angular');
import { DataImportModalController } from './modal/data-import-modal.controller';

const dialogTemplate = require('./modal/data-import-modal.html');

export class DataImportController implements ng.IOnInit {

  static $inject = [
    '$mdDialog',
  ];

  constructor(
    private $mdDialog: ng.material.IDialogService,
  ) {
  }

  isUploadDisabled = false;
  activityGuid;

  $onInit(): void {
  }

  async onClick() {
    // Open modal to setup and import discussion
    this.isUploadDisabled = true;
    try {
      await this.$mdDialog.show({
        controllerAs: 'dvm',
        controller: DataImportModalController,
        templateUrl: dialogTemplate,
        clickOutsideToClose: true,
        locals: {
          activityGuid: this.activityGuid,
        },
      });
    } finally {
      this.isUploadDisabled = false;
    }
  }

}
