'use strict';

/* eslint-disable no-useless-escape */
import { NotificationService } from './../../core/dataservices/notification.service';
import { SelectedSquareFactory } from './../../core/selectedsquare.factory';
import { SpinnerService } from '../../core/services/spinner.service';
import * as _ from 'lodash';
import { SquareService } from 'src/app/core/dataservices/square.service';

export class MailtemplateOverrulerController {
  static $inject = ['notificationservice', '$sce', 'labelFactory', 'selectedSquareFactory', 'spinnerservice',
    'squareservice', '$stateParams', '$timeout', '$scope', '__env'];

  body;
  styles;
  colors;
  overruleLabels;
  placeholders;
  isEdited;
  templateName;
  defaultLabels = [];
  subjectLabelName;
  showTemplate = false;
  identifier = Math.round(Math.random() * 1000000);
  checkbox;
  showCheckbox;
  defaultSubject;

  constructor(
    private notificationservice: NotificationService,
    private $sce,
    private labelFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private spinnerservice: SpinnerService,
    private squareservice: SquareService,
    private $stateParams,
    private $timeout,
    private $scope: ng.IScope,
    private __env: Environment,
  ) { }

  async $onInit() {
    await this.getColors();
    this.isEdited = false;
    this.showCheckbox = this.checkbox !== undefined;
  }

  async displayTemplate() {
    // TODO: replace by basecolor form backend
    const template = await this.notificationservice.getEmailTemplate(this.templateName);
    this.addStyles(template);
    await this.getBody(template);
    this.getDefaultLabels();
    await this.overRule();
    for (const key in this.placeholders) {
      this.$scope.$watch(`vm.placeholders['${key}']`, (newVal, oldVal) => {
        if (!_.isEqual(newVal, oldVal)) {
          this.isEdited = true;
        }
      });
    }
    this.disableLinks();
  }

  async getBody(template) {
    const regexBody = /(?:body(?:(?!>).*)*>)((?!<\/body)[\s\S]*)(?:<\/body)/g;
    this.getAppIcons();
    template = this.getAppFooter(template);
    const squareDetials = await this.squareservice.getSquareDetails(this.$stateParams.squareGuid);
    const shouldShowAppStoreLinks = squareDetials.data.Detail.ShouldShowAppStoreLinksInEmailFooter;
    if (!shouldShowAppStoreLinks) {
      template = template.replace(/{{#if FooterAppStores}}([\s\S]*?){{\/if}}/, '');
    }
    this.body = new RegExp(regexBody).exec(template)[1];
  }

  getDefaultLabels() {
    const regexPlaceholders = /(?:<p(?:(?!>)[\s\S])*>(?:(?!{)[\s\S])*{)((?:(?!})[\s\S])*)(?:}(?:(?!<\/p>)[\s\S])*<\/p>)/g;
    const re = new RegExp(regexPlaceholders);
    let match;
    while ((match = re.exec(this.body)) !== null) {
      this.defaultLabels.push(match[1]);
    }
  }

  getAppIcons() {
    const emailLanguage = this.selectedSquareFactory.Language;
    const unsupportedLanguages = ['uk', 'ha', 'en'];
    const blobUrl = this.__env.blobUrl;
    const defaultImagesFolder = this.__env.blobDefaultImagesFolder;
    const isLanguageSupported = !unsupportedLanguages.includes(emailLanguage);
    const appStoreImg = new URL(`${blobUrl}${defaultImagesFolder}/AppleStore/app-store-badge${isLanguageSupported ? `_${emailLanguage}` : ''}.png`);
    const playStoreImg = new URL(`${blobUrl}${defaultImagesFolder}/PlayStore/google-play-badge${isLanguageSupported ? `_${emailLanguage}` : ''}.png`);
    return { appStoreImg, playStoreImg };
  }

  getAppFooter(template) {
    const { appStoreImg, playStoreImg } = this.getAppIcons();
    template = template.replace(/{{AppStoreImg}}/g, appStoreImg);
    template = template.replace(/{{PlayStoreImg}}/g, playStoreImg);
    return template;
  }

  addStyles(template) {
    const regexStyles = /(?:style(?:(?!>).*)*>)((?!<\/style)[\s\S]*)(?:<\/style)/g;
    // eslint-disable-next-line no-useless-escape
    const regexStylesSelectors = /([,|\}][\s$]*)([\.#]?-?[_a-zA-Z]+[_a-zA-Z0-9-]*)/g;
    // eslint-disable-next-line no-useless-escape, no-irregular-whitespace
    const regexStylesSelectorsAfterMedia = /(@media[^{]+{[\s$]*)([\.#]?-?[_a-zA-Z]+[_a-zA-Z‌​0-9-]*)/g;
    const regexStylesBaseColor = /\*\|BaseColor\|\*/g;
    const regexStylestrailinWhitespace = /^\s+|\s+$/g;
    const basecolor = this.colors.SquareDesign.BaseColor1;


    this.styles = `mailtemplate-overruler ${new RegExp(regexStyles).exec(template)[1]
      .replace(regexStylestrailinWhitespace, '')
      .replace(regexStylesBaseColor, basecolor)
      .replace(regexStylesSelectors, '$1mailtemplate-overruler $2')
      .replace(regexStylesSelectorsAfterMedia, '$1mailtemplate-overruler $2')}`;

    const sheet = document.createElement('style');
    sheet.innerHTML = this.styles;
    document.head.appendChild(sheet);
  }

  getColors() {
    this.spinnerservice.show('loading');
    this.squareservice.selectSquareDesign(this.$stateParams.squareGuid)
      .then((response) => {
        this.colors = _.cloneDeep(response.data);
      }).finally(() => {
        this.spinnerservice.hide('loading');
        this.displayTemplate();
      });
  }

  async overRule() {
    this.placeholders = this.placeholders || {};
    const squareLanguage = this.selectedSquareFactory.Language;
    const labels = await this.labelFactory.getEmailLabelsLanguage(squareLanguage);
    this.defaultLabels.forEach((element) => {
      const label = labels.getLabelValue(element);
      if (_.includes(this.overruleLabels, element)) {
        this.placeholders[element] = this.placeholders[element] || label;
        const richtTextPlaceholder = `<isc-rich-input-placeholder_${this.identifier} placeholder="${element}"></isc-rich-input-placeholder>`;
        this.body = this.body.replace(`{${element}}`, richtTextPlaceholder);
      } else {
        this.body = this.body.replace(`{${element}}`, label);
      }
    });

    // Add subject with name and value in placeholders
    const defaultLabels = await this.labelFactory.getListEmailDefaultLabel(squareLanguage);
    this.defaultSubject = defaultLabels.getLabelValue(this.subjectLabelName);
    this.placeholders[this.subjectLabelName] = this.placeholders[this.subjectLabelName] || this.defaultSubject;

    this.$timeout(() => {
      const elements = document.getElementsByTagName(`isc-rich-input-placeholder_${this.identifier}`);
      while (elements.length > 0) {
        const el = elements[0];
        const placeHolderName = el.getAttribute('placeholder');
        const input = document.getElementsByName(`${placeHolderName}_${this.identifier}`)[0];
        const parent = el.parentNode;
        parent.replaceChild(input, el);
      }
    }, 0, false);
  }

  disableLinks() {
    const regexDisableButton = /href=["|'][^"']*["|']/g;
    this.body = this.body.replace(regexDisableButton, '');
  }

  getTemplate() {
    return this.$sce.trustAsHtml(this.body);
  }

  changeToDefaultIfEmpty() {
    if (!this.placeholders[this.subjectLabelName]) {
      this.placeholders[this.subjectLabelName] = this.defaultSubject;
    }
  }

  toggleCustom() {
    this.isEdited = this.showTemplate ? this.isEdited : false;
  }
}
