'use strict';

import { DateFormatService } from '../../../../../../core/services/dateformat.service';
import { ServerConstants } from '../../../../../../core/serverconstants';
import { DateTime } from 'luxon';

export class ActivityStartEndDate {
  static $inject = ['dateFormatService', 'serverConstants', '$element'];
  constructor(
    private dateFormatService: DateFormatService,
    private serverConstants: ServerConstants,
    private $element: ng.IRootElementService,
  ) {
    this.validationConstants = serverConstants.validationConstants;
  }

  validationConstants;
  form: ng.IFormController;
  model;
  minStartDate: DateTime;
  minEndDate: DateTime;
  endDate;
  allowEndDateEdit = true;
  activityType;
  squareActivity;
  timespan = {
    isValid: true,
    text: '',
  };
  initialEnddate;
  initialStatus;
  showCompletionTarget = true;
  isActivityTargetCompletionEnabled;

  async $onInit() {
    this.calculateTimespan();
    this.minStartDate = this.dateFormatService.getMinDate(this.minStartDate,
      this.model.Status === this.serverConstants.squareActivityStatusConstants.draft ||
        this.model.Status === this.serverConstants.squareActivityStatusConstants.scheduled ? DateTime.now() : this.model.StartDateTime);
    this.minEndDate = this.model.Status === this.serverConstants.squareActivityStatusConstants.closed ? DateTime.now() : this.minEndDate || this.minStartDate;

    // Remove the time from the minStartDate and minEndDate because of parsing issues into the mdp-date-picker and mdp-time-picker
    this.minStartDate = DateTime.local(this.minStartDate.year, this.minStartDate.month, this.minStartDate.day);
    this.minEndDate = DateTime.local(this.minEndDate.year, this.minEndDate.month, this.minEndDate.day);

    this.initialEnddate = angular.copy(this.endDate);
    this.initialStatus = angular.copy(this.model.Status);

    if (
      this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.profile
        || this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.screener) {
      this.showCompletionTarget = false;
    }
    this.model.StartTime = this.model.StartDateTime;
    this.model.EndTime = this.model.EndDateTime;

    this.$element.find('mdp-date-picker').find('input')[0].setAttribute('aria-labelledby', 'start-date');
    this.$element.find('mdp-date-picker').find('input')[1].setAttribute('aria-labelledby', 'end-date');
    this.$element.find('mdp-time-picker').find('input')[0].setAttribute('aria-labelledby', 'start-time');
    this.$element.find('mdp-time-picker').find('input')[1].setAttribute('aria-labelledby', 'end-time');


  }

  onStartDateChanged = () => {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
    }
    this.form.StartDate.$setValidity('serverErrors', true);
    if (this.model.StartDateTime) {
      this.minEndDate = this.dateFormatService.startOfDay(this.model.StartDateTime);
    }
    this.calculateTimespan();
  };

  onEndDateChanged = () => {
    if (this.model.EndTime) {
      this.model.EndDateTime = this.model.EndDateTime.set({ hour: this.model.EndTime.hour, minute: this.model.EndTime.minute });
    }
    this.form.EndDate.$setValidity('serverErrors', true);
    if (this.form.RaffleExecutionDate) {
      this.form.RaffleExecutionDate.$setValidity('serverErrors', true);
    }
    this.calculateTimespan();

    if (this.model.EndDateTime && this.model.EndDateTime > DateTime.now()
      && this.initialStatus === this.serverConstants.squareActivityStatusConstants.closed) {
      this.model.Status = this.serverConstants.squareActivityStatusConstants.active;
    } else {
      this.model.Status = this.initialStatus;
    }
  };

  private calculateTimespan() {
    if (this.model.EndDateTime && this.model.StartDateTime) {
      const end = this.model.EndDateTime.startOf('minute');
      const start = this.model.StartDateTime.startOf('minute');
      const diffMinutes = end.diff(start).as('minutes');
      if (diffMinutes < 0) {
        this.timespan = {
          isValid: false,
          text: 'End date ends before start date.',
        };
      } else if (diffMinutes === 0) {
        this.timespan = {
          isValid: false,
          text: 'The start and end time are the same.',
        };
      } else {
        const diffHours = end.diff(start).as('hours');
        const diffDays = end.diff(start).as('days');
        this.timespan = {
          isValid: true,
          text: `${diffDays > 0 ? ` ${diffDays} days and ` : ''} ${diffHours} hours`,
        };
      }
    }
  }

  isLowerThanCompletionTargetLimit() {
    return (this.model != null && this.model.CompletionTarget != null
      && ((this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey
        || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.confirmitSurvey
        || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.decipherSurvey
        || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey)
        && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
        && this.model.CompletionTarget < this.serverConstants.completionTargetConstants.researchActivityMin));
  }

  isHigherThanCompletionTargetLimit() {
    return (this.model != null && this.model.CompletionTarget != null
      && ((this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey
        || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.confirmitSurvey
        || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.decipherSurvey
        || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey)
        && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
        && this.model.CompletionTarget > this.serverConstants.completionTargetConstants.researchActivityMax));
  }
}
