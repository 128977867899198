'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';
import { AuthService } from '../../core/dataservices/auth.service';

const appRunModeratorLandingPage = (routerHelper: RouterHelper, authService: AuthService, $transitions) => {
  routerHelper.configureStates([{
    state: 'root.moderatorlandingpage',
    config: {
      url: 'client/:clientGuid/moderatorhome',
      disallowRoles: ['Human8', 'Observer'],
    },
  }]);

  $transitions.onBefore({
    to: 'root.moderatorlandingpage',
  }, async (transition) => {
    const homeRoute = await authService.getHomeRouteInfoForLoggedInUser();
    if (!homeRoute.routeData.activate) {
      delete homeRoute.routeData.activate;
    }
    return transition.router.stateService.target(homeRoute.routeName, homeRoute.routeData);
  });
};

appRunModeratorLandingPage.$inject = ['routerHelper', 'authService', '$transitions'];

export default appRunModeratorLandingPage;
