'use strict';

import { SquareConfigDesignImagesController } from './images.controller';
const template = require('./images.html');

export const SquareConfigDesignImagesComponent: ng.IComponentOptions = {
  controller: SquareConfigDesignImagesController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    squareDesignData: '<',
  },
};
