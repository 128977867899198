'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates(
    [
      {
        state: 'root.square.forum',
        config: {
          abstract: true,
          template: '<ui-view flex layout="column" />',
          url: '/forum/:forumGuid/',
        },
      },
      {
        state: 'root.square.forum.room',
        config: {
          abstract: true,
          title: 'Lounge',
          template: '<ui-view flex layout="column" />',
          url: 'room/:roomGuid',
          default: 'root.square.forum.room.squareforumroom',
        },
      },
      {
        state: 'root.square.forum.room.squareforumroom',
        config: {
          url: '?page?query?limit',
          title: 'Lounge',
          component: 'squareForumRoom',
        },
      },
    ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

