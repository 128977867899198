'use strict';

import { DateTime } from 'luxon';
import { ActivityBuildModelBase } from './../common/activityBuildBase.model';

export class ActivityQualBuilderModel extends ActivityBuildModelBase {
  cardTeaser = '';
  callToActionText = '';
  sticky = false;
  prePopulateResponse = false;
  prePopulateDescription = '';
  startDateTime: DateTime = DateTime.now();
  communicationGuid = '';
  openPostUsername = '';
  communicationStimuli = [];
  communicationFiles = [];
  showOnHomepage = true;
  showOnPublicPage = false;
  constructor() {
    super();
  }
}
