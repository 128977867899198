'use strict';

import './design.module';
import routeConfig from './design.route';
import './design.constants';

import './colors';
import './custom';
import './images';
import './customCSS';

angular
  .module('insitesApp.squareconfig.design')
  .run(routeConfig);
