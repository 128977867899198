'use strict';

import * as angular from 'angular';

require('./customCSS.module');

import { squareCustomCss } from './customCSS.component';

angular
  .module('insitesApp.squareconfig.design.customCSS')
  .component('squareCustomCss', squareCustomCss);
