'use strict';

import * as angular from 'angular';
import './segmentation.module';
import routeConfig from './segmentation.route';
import { segmentationComponent } from './segmentation.component';
import { SegmentationController } from './segmentation.controller';

angular
  .module('insitesApp.squaremembers.segmentation')
  .controller('segmentationController', SegmentationController)
  .component('segmentationComponent', segmentationComponent)
  .run(routeConfig);

