'use strict';

import { PlatformNoticeOption } from '../core/models/platformNoticeOption';
import { CacheService } from '../core/services/cache.service';
import { AuthService } from '../core/dataservices/auth.service';
import { PlatformNoticeService } from '../core/dataservices/platformnotice.service';
import { ServerConstants } from '../core/serverconstants';
import { SelectedClientFactory } from '../core/selectedclient.factory';
import { SelectedProgramFactory } from '../core/selectedprogram.factory';
import { SelectedNetworkFactory } from '../core/selectednetwork.factory';
import { SelectedSquareFactory } from '../core/selectedsquare.factory';
import { SquareService } from '../core/dataservices/square.service';
import { StateConfig } from '../blocks/router/router-helper';

export class LayoutController {
  static $inject = ['$scope', 'selectedClientFactory', 'selectedProgramFactory',
    'selectedNetworkFactory', 'selectedSquareFactory', 'squareservice', '__env',
    '$mdSidenav', '$transitions', '$stateParams', 'authService',
    'platformNoticeService', 'mediaService', 'serverConstants', '$state'];

  constructor(
    private $scope,
    private selectedClientFactory: SelectedClientFactory,
    private selectedProgramFactory: SelectedProgramFactory,
    private selectedNetworkFactory: SelectedNetworkFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private squareservice: SquareService,
    private __env,
    private $mdSidenav,
    private $transitions,
    private $stateParams,
    private authService: AuthService,
    private platformNoticeService: PlatformNoticeService,
    private mediaService,
    private serverConstants: ServerConstants,
    private $state: ng.ui.IStateService,
  ) {
    this.successTransitionHandler();
    this.watchForSelected();
  }

  selected: any = {};
  showSpinner = true;
  squareUrlBase = this.__env.squareUrlBase;
  selectedSquareUrl = '';
  squares: any[] = null;
  platformNotice = null;
  showPlatformNotice = false;
  platformNoticeOption = null;
  platformNoticeTypes = this.platformNoticeService.platformNoticeTypeConstants;

  clientGuid;
  programGuid;
  networkGuid;
  squareGuid;
  orphanGuid;
  activityGuid;
  selectedActivityGuid;

  async $onInit() {
    if (!(this.$state.current as StateConfig).allowAnonymous) {
      this.platformNoticeOption =
      CacheService.getCache(`${PlatformNoticeOption.KEY}_${this.authService.uniqueMemberName}`);
      this.platformNoticeService.displayNotice().then((response) => {
        this.platformNotice = response;
        this.showPlatformNotice = this.platformNotice && this.platformNotice.customMessage;
        if (this.showPlatformNotice && this.platformNoticeOption) {
          this.showPlatformNotice = !this.platformNoticeOption.value ||
            this.platformNoticeOption.value !== this.platformNotice.customMessage;
        }
      });
    }

    this.clientGuid = this.$stateParams.clientGuid;
    this.programGuid = this.$stateParams.programGuid;
    this.networkGuid = this.$stateParams.networkGuid;
    this.squareGuid = this.$stateParams.squareGuid;
    this.orphanGuid = this.$stateParams.orphanGuid;
    this.activityGuid = this.$stateParams.activityGuid;
    if (this.authService.isObserverUserLogin()) {
      this.squareservice.getSquareListForObserverOrModerator().then((squareList) => {
        this.squares = squareList;
      });
    } else if (this.programGuid) {
      this.squareservice.getSquareSelectionForDropdown(this.programGuid).then((squareList) => {
        this.squares = squareList;
      });
    }
    // make sure to throw scroll event on the entire window when scrolling the maincontent so Quasar dropdowns know they need to recalculate
    const maincontent = document.getElementById('maincontent');
    maincontent.addEventListener('scroll', () => window.dispatchEvent(new Event('scroll')));
  }

  toggleSidenav() {
    this.$mdSidenav('left').toggle();
  }

  close() {
    this.showPlatformNotice = false;
    if (!this.platformNoticeOption) {
      this.platformNoticeOption = new PlatformNoticeOption();
      this.platformNoticeOption.key = `${PlatformNoticeOption.KEY}_${this.authService.uniqueMemberName}`;
    }
    this.platformNoticeOption.value = this.platformNotice.customMessage;
    CacheService.setCache(this.platformNoticeOption);
  }

  private successTransitionHandler = () => {
    this.$transitions.onSuccess({}, (transition) => {
      this.clientGuid = transition.params('to').clientGuid;
      this.programGuid = transition.params('to').programGuid;
      this.networkGuid = transition.params('to').networkGuid;
      this.squareGuid = transition.params('to').squareGuid;
      this.orphanGuid = transition.params('to').orphanGuid;
      this.activityGuid = transition.params('to').activityGuid;

      if (this.clientGuid !== undefined && !(this.programGuid !== undefined || this.networkGuid !== undefined
        || this.squareGuid !== undefined || this.selectedActivityGuid !== undefined)) {
        this.selected = this.selectedClientFactory;
        this.selected.switchPlaceholder = 'Client';
      } else if (this.programGuid !== undefined && !(this.networkGuid !== undefined || this.squareGuid !== undefined || this.selectedActivityGuid !== undefined)) {
        this.selected = this.selectedProgramFactory;
        this.selected.switchPlaceholder = 'Program';
      } else if (this.networkGuid !== undefined && !(this.programGuid !== undefined || this.squareGuid !== undefined || this.selectedActivityGuid !== undefined)) {
        this.selected = this.selectedNetworkFactory;
        this.selected.switchPlaceholder = 'Network';
      } else {
        this.selected = this.selectedSquareFactory;
        this.selected.switchPlaceholder = 'Square';
      }
    });
  };

  openMenu($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  }

  private watchForSelected = () => {
    this.$scope.$watchGroup(
      [() => this.selected.Guid, () =>
        this.selected.SquareUrl, // Needed when the Square has just been created and the user saves the URL
      ],
      (newValues, oldValues) => {
        if (this.clientGuid) {
          if (newValues[0] !== oldValues[0] || newValues[1] !== oldValues[1]) {
            switch (this.selected.switchPlaceholder) {
              case 'Client':
              case 'Network':
                this.squareservice.getSquareSelectionForDropdown().then((squares) => {
                  this.squares = squares;
                  this.checkSquareListLength(this.squares);
                });
                break;
              case 'Program':
                this.squareservice.getSquareSelectionForDropdown(newValues[0]).then((squares) => {
                  this.squares = squares;
                  this.checkSquareListLength(this.squares);
                });
                break;
              case 'Square':
                if (this.selected.SquareUrl) {
                  this.buildSquareUrl(this.selected.SquareUrl);
                }
                break;
              default:
                break;
            }
          }
        }
      },
    );
  };

  checkSquareListLength(squares) {
    if (squares && squares.length === 1) {
      this.buildSquareUrl(squares[0].SquareUrl);
    } else {
      this.selectedSquareUrl = '';
    }
  }

  buildSquareUrl(url) {
    this.selectedSquareUrl = `http://${url}.${this.squareUrlBase}`;
  }

  showUseGoogleChrome = () => !this.mediaService.isBrowserSupported();
  showUseDesktop = () => !this.mediaService.isDesktop();

  getChromeDownloadLink = () => {
    if (this.authService.isHuman8UserLogin()) {
      return this.serverConstants.squareConstants.corporateChromeDownloadLink;
    }
    return this.serverConstants.squareConstants.generalChromeDownloadLink;

  };

  get showBackgroundImage() {
    return this.$state.current.name === 'root.landing';
  }

  showNav() {
    return !(this.$state.current as any).allowAnonymous;
  }
}

angular
  .module('insitesApp.layout')
  .controller('LayoutController', LayoutController);
