'use strict';

import { RouterHelper, StateConfig } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper, __env: Environment) => {
  const states: Array<{ state: string, config: StateConfig }> = [
    {
      state: 'root.landing',
      config: {
        url: __env.showStartPage ? '' : 'landing',
        title: __env.showStartPage ? 'Home' : 'Landing Page',
        views: {
          'sidenav': {
            component: 'landingSidenavComponent',
          },
          'sidenav-footer': {
            component: 'homeSidenavFooterComponent',
          },
          '': {
            component: 'landingPage',
          },
        },
        isVue: true,
      },
    },
  ];

  // To make sure that '/landing' still works after the switch we add a redirect route
  if (__env.showStartPage) {
    states.push({
      state: 'root.landingredirect',
      config: {
        url: 'landing',
        redirectTo: 'root.landing',
      },
    });
  }

  routerHelper.configureStates(states);
};

routeConfig.$inject = ['routerHelper', '__env'];

export default routeConfig;
