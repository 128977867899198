'use strict';

const confirmTemplate = require('./squareOpenRegistration.html');
import { SquareOpenRegistrationController } from './squareOpenRegistration.controller';

export class SquareOpenRegistrationService {
  static $inject = ['$mdDialog'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
  ) {}

  public async showInfoDialog(): Promise<boolean> {
    return await this.infoDialog();
  }

  private async infoDialog() {
    return await this.$mdDialog.show({
      controller: SquareOpenRegistrationController,
      controllerAs: 'vm',
      templateUrl: confirmTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
    });
  }
}
