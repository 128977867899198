'use strict';

import { IncentiveService } from '../../../../core/dataservices/incentive.service';
import { FeatureService } from '../../../../core/dataservices/feature.service';
require('./_newManualReward.scss');

export class NewManualRewardController {
  static $inject = ['$mdDialog', 'incentiveservice', 'downloadservice', 'jobservice', 'name', '$rootScope', 'serverConstants', 'featureservice'];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private incentiveservice: IncentiveService,
    private downloadservice,
    private jobservice,
    private name,
    private $rootScope: ng.IRootScopeService,
    private serverConstants,
    private featureService: FeatureService,
  ) {
  }

  file;
  blob;
  errors = {};
  errorkeys = [];
  warning = undefined;
  warnings = [];
  isDownloadTemplateDisabled: boolean = false;
  fileValidationSucceed: boolean = false;
  isSaving = false;
  invalidType: boolean = false;
  totalRewardWinners: number = 0;
  isApprovalAutomatic: boolean = false;
  approvalType;
  isSavingUpEnabled: boolean = false;
  saveButtonLabel = 'Import manual reward';
  jobProgressDialogTitel = 'Importing manual reward';

  ApprovalTypes = [
    {
      Label: 'Automatic',
      ApprovalFormat: this.serverConstants.squareActivityApprovalListConstants.automatic,
    },
    {
      Label: 'Manual',
      ApprovalFormat: this.serverConstants.squareActivityApprovalListConstants.manual,
    },
  ];

  $onInit() {
    this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards)
      .then((isEnabled) => {
        this.isSavingUpEnabled = isEnabled;
        if(isEnabled){
          this.saveButtonLabel = 'Import manual point correction';
          this.jobProgressDialogTitel = 'Importing manual point correction';
        }
      });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  async downloadManualRewardTemplate() {
    this.isDownloadTemplateDisabled = true;
    const response = await this.incentiveservice.downloadManualRewardTemplate();
    this.downloadservice.downloadResponse(response);
    this.isDownloadTemplateDisabled = false;
  }

  async importManualReward() {
    this.isSaving = true;
    this.$mdDialog.hide();
    const jobId = this.incentiveservice.processUploadedExcelFile(this.blob, this.name, this.approvalType);
    await this.jobservice.showJobProgressDialog(jobId, this.jobProgressDialogTitel);
    this.$rootScope.$broadcast('rewardsUploaded');
    this.isSaving = false;
  }

  async validateFile(file, invalidFile) {
    this.resetUpload();
    this.file = file;
    if (invalidFile) {
      this.invalidType = true;
    }
    this.fileValidationSucceed = false;

    if (this.file) {
      const validationInfo = await this.incentiveservice.validateExcelFile(this.file);

      this.errors = validationInfo.data.Errors;
      this.errorkeys = Object.keys(this.errors);

      this.warning = validationInfo.data.Warning;
      this.warnings = validationInfo.data.Warnings;

      this.totalRewardWinners = validationInfo.data.Total;
      this.blob = validationInfo.data.Blob;
      if (Object.keys(this.errors).length === 0) {
        this.fileValidationSucceed = true;
      }
    }
  }

  resetUpload() {
    this.fileValidationSucceed = false;
    this.isApprovalAutomatic = false;
    this.totalRewardWinners = 0;
    this.invalidType = false;
    this.errors = {};
    this.blob = '';
  }

}
