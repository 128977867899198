'use strict';

import { ForumService } from '../../../core/dataservices/forum.service';
import { GoogleTranslateHelperService } from '../../../layout/google-translate-helper/google-translate-helper.service';
import { ConversationEventsService } from '../../../core/services/conversationEvents.service';
import { Utils } from '../../../core/utils';
import { Pagination } from '../../../core/models/pagination';
import { SelectedSquareForumsService } from '../../../core/services/selectedSquareForumsService';
import { SpinnerService } from '../../../core/services/spinner.service';
import { ConversationsExportParams } from '../../../core/contracts/download.contract';
import { JobService } from '../../../core/jobs/job.service';

export class ForumConversationController {
  static $inject = [
    '$stateParams',
    'forumservice',
    '$state',
    'selectedSquareForumsService',
    '$q',
    'spinnerservice',
    'serverConstants',
    'googleTranslateHelper',
    '$scope',
    'conversationEventsService',
    '_',
    'logger',
    '$timeout',
    '$window',
    'jobservice',
  ];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private forumservice: ForumService,
    private $state: ng.ui.IStateService,
    private selectedSquareForumsService: SelectedSquareForumsService,
    private $q: ng.IQService,
    private spinnerservice: SpinnerService,
    private serverConstants,
    private googleTranslateHelper: GoogleTranslateHelperService,
    private $scope: ng.IScope,
    private conversationEventsService: ConversationEventsService,
    private _: _.LoDashStatic,
    private logger,
    private $timeout: ng.ITimeoutService,
    private $window,
    private jobService: JobService,
  ) {
    ForumConversationController._this = this;
  }

  downloadParams: ConversationsExportParams = new ConversationsExportParams();
  conversation;
  conversationElements = [];
  conversationGuid;
  sortBy;
  roomGuid;
  Name = '';
  loading = true;
  _searchTermReplies = '';
  highlight = false;
  registrationStatusConstants = this.serverConstants.registrationStatusConstants;
  roleConstants = this.serverConstants.roleConstants;
  sortOptions = this.serverConstants.conversationSortOptionConstants;
  isDownloadDisabled = false;
  unsubscribeConversationChange = this._.noop;
  static _this;

  pagination: IPagination = new Pagination(1);

  async $onInit() {
    try {
      await this.init();
    } catch (e) {
      this.logger.error('Failed to init conversation component.', e);
    }
    this.unsubscribeConversationChange = await this.conversationEventsService.forumConversationChange.subscribe(
      this.conversationGuid,
      async () => {
        const silentLoad = true;
        await this.loadAll(silentLoad);
      });
  }

  $onDestroy() {
    this.unsubscribeConversationChange();
  }

  private async init() {
    this.googleTranslateHelper.autoEnableForScope(this.$scope);
    const defaultSortOption = this.serverConstants.conversationSortOptionConstants.chronological;
    this.sortOptions = this.serverConstants.conversationSortOptionConstants;
    this.sortBy = this.$stateParams.sort === undefined
      ? defaultSortOption : parseInt(this.$stateParams.sort, 10);
    this.roomGuid = this.$stateParams.roomGuid;
    this.conversationGuid = this.$stateParams.conversationGuid;
    this.pagination.page = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
    this.searchTermReplies = this.$stateParams.query;
    this.pagination.limit = this.$stateParams.limit ? parseInt(this.$stateParams.limit, 10) : 50;
    this.downloadParams.conversationGuid = this.conversationGuid;
    this.downloadParams.sortOption = this.sortBy;
    this.highlight = this.searchTermReplies ? true : false;
    await this.loadAll();
    this.scrollReply(this.$stateParams.replyGuid);
  }

  scrollReply(replyGuid) {
    if (replyGuid) {
      Utils.anchorScrollWithWait(replyGuid);
    }
  }

  async loadAll(silentLoad: boolean = false) {
    try {
      if (!silentLoad) {
        this.spinnerservice.show('loading');
      }

      await this.$q.all([
        this.loadConversationElements(),
        this.loadFirstPost(),
        this.loadRoomName(),
      ]);
    } finally {
      if (!silentLoad) {
        this.spinnerservice.hide('loading');
      }

      this.loading = false;
      this.$timeout().then(() => {
        angular.element(this.$window).triggerHandler('checkInView');
      });
    }
  }

  loadFirstPost() {
    // The first post only appears on the first page
    if (this.pagination.page === 1) {
      return this.forumservice.getConversation(this.conversationGuid).then((response) => {
        this.conversation = response.data;
      });
    }
    return this.$q.resolve();
  }

  loadRoomName() {
    return this.selectedSquareForumsService.update(this.$stateParams.squareGuid)
      .then(() => {
        this.Name = this.selectedSquareForumsService.getRoom(this.roomGuid).Name;
      });
  }

  loadConversationElements() {
    return this.getConversationsForCurrentPage().then((paginatedResult) => {
      this.pagination.total = paginatedResult.TotalItems;
      this.conversationElements = paginatedResult.Items.filter((item) => !item.IsInDraft);
    });
  }

  getConversationsForCurrentPage() {
    return this.forumservice.getConversationElements(this.conversationGuid, this.pagination.page, this.pagination.limit, this.searchTermReplies, this.sortBy)
      .then((response) => response.data);
  }

  navigate(pageNumber, limit) {
    ForumConversationController._this.$state.go('.', {
      page: pageNumber,
      limit,
      query: this.searchTermReplies,
      sort: this.sortBy,
    });
  }

  search(searchTerm) {
    this.$state.go('.', {
      page: 1,
      query: searchTerm,
      sort: this.sortBy,
    });
  }

  onSortChange(value) {
    this.sortBy = value;
    this.downloadParams.sortOption = value;
    this.$state.go('.', {
      page: 1,
      query: this.searchTermReplies,
      sort: this.sortBy,
    });
    this.highlight = this.searchTermReplies ? true : false;
  }

  filterChanged() {
    this.highlight = false;
  }

  get searchTermReplies(): string {
    return this._searchTermReplies;
  }

  set searchTermReplies(value: string) {
    this._searchTermReplies = value;
    this.downloadParams.keyword = value;
  }
}
