'use strict';

import { SpinnerService } from '../../core/services/spinner.service';
import { ServerConstants } from '../../core/serverconstants';
import { SquareService } from '../../core/dataservices/square.service';

export class SsoIdeaStreamController {
  static $inject = ['serverConstants', '$scope', 'squareservice', 'spinnerservice', '$timeout'];
  constructor(
    private serverConstants: ServerConstants,
    private $scope,
    private squareservice: SquareService,
    private spinnerservice: SpinnerService,
    private $timeout) {
    this.validationConstants = serverConstants.validationConstants;
    $scope.$watch(
      () => this.ctaUrl,
      () => {
        if (this.timer) {
          $timeout.cancel(this.timer);
        }

        this.timer = $timeout(() => {
          this.onCtaUrlChange();
        }, 1000);
      });
  }

  timer;
  isIdeaStreamUrl = false;
  isSsoEnabled;
  validationConstants;
  errorMessage = '';
  origCtaUrl;
  projectidUrl;
  ctaUrl;

  $onInit() {
    this.origCtaUrl = this.ctaUrl;
  }

  onSwitchChange() {
    if (!this.isSsoEnabled) {
      this.projectidUrl = this.ctaUrl;
      return;
    }
    this.onCtaUrlChange(true);
  }

  get isSsoEnabledModel() {
    return this.isSsoEnabled;
  }

  set isSsoEnabledModel(value: boolean) {
    this.isSsoEnabled = value;
    if (!this.isSsoEnabled) {
      this.projectidUrl = this.ctaUrl;
      return;
    }
    this.onCtaUrlChange(true);
  }

  onCtaUrlChange(force: boolean = false) {
    this.isIdeaStreamUrl = this.isValidIdeaStreamUrl(this.ctaUrl);
    this.errorMessage = '';
    if (!force && this.ctaUrl === this.projectidUrl && this.isIdeaStreamUrl) {
      this.isSsoEnabled = false;
      this.isIdeaStreamUrl = true;
      return;
    }

    if (this.ctaUrl && this.isValidIdeaStreamUrl(this.ctaUrl)) {
      this.spinnerservice.show('loading');
      this.squareservice.getSquareUrlWithIdeaStreamProjectId(this.ctaUrl)
        .then((result) => {
          if (!result.data) {
            this.errorMessage = 'Sorry, couldn\'t get the ideastream project Url';
            return;
          }

          this.projectidUrl = result.data;
          this.isSsoEnabled = true;
          this.errorMessage = '';
        })
        .finally(() => {
          this.spinnerservice.hide('loading');
        })
        .catch(() => {
          this.errorMessage = 'Sorry, couldn\'t get the ideastream project Url';
        });

      return;
    }

    this.projectidUrl = this.ctaUrl;
  }

  isValidIdeaStreamUrl(url) {
    const urlCheck = new RegExp(this.serverConstants.validationConstants.ideaStreamUrlValidation, 'gi');
    return urlCheck.test(url);
  }
}
