'use strict';

import { IOnChanges } from 'angular';

export class InfoChartController implements IOnChanges{
  static $inject = [];
  constructor() {}

  graphData;
  chartModel = {
    type: 'AreaChart',
    displayed: false,
    data: {
      cols: [
        {
          id: 'xvalue',
          label: '',
          type: 'string',
          p: {},
        },
        {
          id: 'yvalue',
          label: '',
          type: 'number',
          p: {},
        },
      ],
      rows: [],
    },
    options: {
      legend: 'none',
      isStacked: 'true',
      fill: 20,
      displayExactValues: true,
      colors: ['#f29303'],
      hAxis: {
        title: '',
        textPosition: 'none',
      },
      vAxis: {
        viewWindowMode: 'explicit',
        viewWindow: { min: 0 },
      },
    },
    formatters: {},
  };

  $onChanges(changes) {
    this.graphData = changes.graphdata.currentValue;
    this.chartModel.data.cols[0].label = '';
    this.chartModel.options.hAxis.title = '';
    this.chartModel.data.rows = this.graphData.data.map((pair) => ({
      c: [
        {
          v: pair[0],
          f: pair[0],
        },
        {
          v: pair[1],
          f: `${this.graphData.snippet}: ${pair[1]}`,
        },
      ],
    }));
  }
}
