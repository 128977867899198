'use strict';

import { HttpService } from './http.service';

export class NetworkService {
  static $inject = ['httpservice'];

  constructor(private httpservice: HttpService) { }

  getNetworkDetails(networkGuid) {
    return this.httpservice.get({
      url: '/NetworkService/SelectNetwork',
      params: {
        Guid: networkGuid,
      },
    });
  }

  updateNetwork(network) {
    return this.httpservice.post({
      url: '/NetworkService/UpdateNetwork',
      data: {
        Name: network.Name,
        Description: network.Description,
        id: network.Id,
        guid: network.Guid,
        JobIdList: network.JobIds,
      },
    });
  }

  getClientNetworks() {
    return this.httpservice.get({
      url: '/NetworkService/ListNetwork',
    });
  }

  createNetwork(networkName) {
    return this.httpservice.post({
      url: '/NetworkService/CreateNetwork',
      data: { name: networkName },
    });
  }
}
