'use strict';

const template = require('./home-button.html');
require('./_home-button.scss');

export const HomeButtonComponent: ng.IComponentOptions = {
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    showOnHomepage: '<',
    isUpdatingHome: '<',
  },
};
