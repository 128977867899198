'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const appRunClientPrograms = (routerHelper: RouterHelper) => {
  routerHelper.configureStates(getClientProgramsStates());
};
appRunClientPrograms.$inject = ['routerHelper'];

const getClientProgramsStates = () => [
  {
    state: 'root.clientprograms',
    config: {
      url: 'client/:clientGuid/programs',
      component: 'clientPrograms',
      title: 'Client Programs',
      settings: {
        nav: 2,
        section: 'CLIENT MANAGEMENT',
        icon: 'fa fa-cubes',
        label: 'Programs',
      },
      disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
    },
  },
];

angular
  .module('insitesApp.clientprograms')
  .run(appRunClientPrograms);
