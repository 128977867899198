'use strict';

import { HttpService } from './http.service';

export class PinService {
  static $inject = ['httpservice'];

  constructor(private httpservice: HttpService) {
  }

  listPinnedItems() {
    return this.httpservice.get({
      url: '/PinService/ListPinnedItems',
    }).then((response) => response.data);
  }

  getPinnedItemsOnEnv(envUrl: string, authToken: string,
    catchException: boolean = false) {
    const apiUrl = '/PinService/ListPinnedItems';

    let promise = this.httpservice.get({
      url: apiUrl,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    }, envUrl).then((response) => response.data);

    // if caller wants to catch exceptions and not fail,
    // we add the catch promise too
    if (catchException === true) {
      promise = promise.catch(() => null);
    }
    return promise;
  }
}
