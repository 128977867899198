'use strict';

import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { FaqCategory, FaqQuestion } from '../../../../core/contracts/page.contract';
import { PageService } from '../../../../core/dataservices/page.service';
import { ServerConstants } from '../../../../core/serverconstants';
import { FaqPage } from './faq.types';
import { Utils } from './../../../../core/utils';
import { IPromise } from 'angular';
import ng = require('angular');

const editCategoryDialogTemplate = require('./editCategoryDialog.html');
const editQuestionDialogTemplate = require('./editQuestionDialog.html');

export class FaqController implements ng.IOnInit {
  static $inject = ['featureservice', '$mdDialog', '_', 'pageService', '$scope', 'serverConstants'];
  constructor(
    private featureService: FeatureService,
    private $mdDialog: ng.material.IDialogService,
    private _: _.LoDashStatic,
    private pageService: PageService,
    private $scope: ng.IScope,
    private serverConstants: ServerConstants) {
  }

  operationTypeConstants;
  validationConstants;
  faqCategoryTypeConstants;
  faqForm = null;
  isSavingUpRewardsEnabled: boolean;
  isAutomaticHandlingEnabled: boolean;
  isFeatureSavingUpRewardsEnabled: boolean;
  savingUpState: number;
  page: FaqPage;

  async $onInit() {
    this.operationTypeConstants = this.serverConstants.operationTypeConstants;
    this.validationConstants = this.serverConstants.validationConstants;
    this.faqCategoryTypeConstants = this.serverConstants.faqCategoryTypeConstants;
    const featureSettings = await this.featureService.getFeatureSettings(this.serverConstants.featureConstants.savingUpRewards);
    this.isFeatureSavingUpRewardsEnabled = featureSettings.isMasterSwitchEnabled;
    this.isSavingUpRewardsEnabled = featureSettings.isEnabled;
    const automaticHandlingSetting = featureSettings.settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsAutomaticHandlingEnabled];
    this.isAutomaticHandlingEnabled = automaticHandlingSetting !== undefined ? automaticHandlingSetting.toLowerCase() === 'true' : false;

    this.savingUpState = await this.getRewardsState();

    this.$scope.$watch('fvm.page', () => {
      this.fetchFaqData();
    });

    this.$scope.$on('pageSaved', () => {
      this.fetchFaqData();
    });
  }

  fetchFaqData() {
    if (this.page.Guid != null) {
      this.fetchFaqCategories();
      this.fetchFaqQuestions();
    } else {
      this.page.Categories = [];
      this.page.Questions = [];
      this.faqForm.$setDirty();
    }
  }

  private fetchFaqQuestions(): IPromise<any> {
    return this.pageService.getFAQQuestions(this.page.Guid).then((response) => {
      // Filter out questions that are in categories that are not filtered out
      const questions = response.List.filter((question) => this.page.Categories.some((cat) => cat.Guid === question.FaqCategoryGuid));
      this.page.Questions = questions;
    });
  }

  private fetchFaqCategories(): IPromise<any> {
    return this.pageService.getFAQCategories(this.page.Guid).then((response) => {
      const categories = response.List;
      this.page.Categories = categories.filter((category) => category.Type === this.savingUpState || category.Type === this.faqCategoryTypeConstants.none);
    });
  }

  addCategory() {
    const category = {
      Name: '',
      IsVisible: true,
    };
    const title = 'Adding a new category';
    const warning =
      'You first need to save the FAQ overview before new categories are visible when creating or editing questions.';

    this.showEditCategoryDialog(category, title, warning).then((c) => {
      c.OperationType = this.operationTypeConstants.create;
      this.page.Categories.push(c);
      this.faqForm.$setDirty();
    });
  }

  editCategory(category: FaqCategory) {
    const title = 'Edit category';
    const warning = '';
    this.showEditCategoryDialog(category, title, warning).then((newCategory: FaqCategory) => {
      const shouldUpdateVisibility = category.IsVisible !== newCategory.IsVisible;
      Object.assign(category, newCategory);
      category.OperationType = this.operationTypeConstants.update;
      // Get all answers linked to category and also mark them as hidden or not
      this.page.Questions
        .filter((q) => q.FaqCategoryGuid === category.Guid)
        .forEach((q) => {
          if (shouldUpdateVisibility) {
            q.IsVisible = category.IsVisible;
          }
          if (q.OperationType !== this.operationTypeConstants.create) {
            q.OperationType = this.operationTypeConstants.update;
          }
        });

      this.faqForm.$setDirty();
    });
  }

  showEditCategoryDialog(category, title, warning) {
    return this.$mdDialog.show({
      controller: 'EditCategoryDialogController',
      controllerAs: 'vm',
      templateUrl: editCategoryDialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        category: angular.copy(category),
        title,
        warning,
        validationConstants: this.validationConstants,
      },
    });
  }

  deleteCategory(category) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: 'Delete category',
      text: `Do you want to delete the category ${category.Name}? All the questions from this category will be deleted.`,
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      if (category.OperationType === this.operationTypeConstants.create) {
        // The category is new, we should delete it
        this.page.Categories = this._.without(this.page.Categories, category);
      } else {
        category.OperationType = this.operationTypeConstants.delete;
      }

      // Remove related questions
      const questions = this._.filter(this.page.Questions, {
        FaqCategoryGuid: category.Guid,
      });

      this.removeQuestions(questions);
      this.faqForm.$setDirty();
    });
  }

  addQuestion() {
    this.fetchFaqCategories().then(() => {
      const question = {
        IsVisible: true,
      };
      this.showEditQuestionDialog(question).then((q: FaqQuestion) => {
        q.OperationType = this.operationTypeConstants.create;
        this.page.Questions.push(q);
        this.faqForm.$setDirty();
      });
    });
  }

  editQuestion(question) {
    this.showEditQuestionDialog(question).then((q: FaqQuestion) => {
      if (q.OperationType !== this.operationTypeConstants.create) {
        q.OperationType = this.operationTypeConstants.update;
        this.faqForm.$setDirty();
      }
      Object.assign(question, q);
    });
  }

  deleteQuestion(question) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: 'Delete question',
      text: `Do you want to delete the question ${question.Title}?`,
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      this.removeQuestions([question]);
      this.faqForm.$setDirty();
    });
  }

  removeQuestions(questions) {
    this._.each(questions, (question) => {
      if (question.OperationType === this.operationTypeConstants.create) {
        this.page.Questions = this._.without(this.page.Questions, question);
      } else {
        question.OperationType = this.operationTypeConstants.delete;
      }
    });
  }

  getNameForCategory(categoryGuid) {
    const category: any = this._.find(this.page.Categories, {
      Guid: categoryGuid,
    });
    return category && category.Name;
  }

  isCategoryLinkedToIncentives(categoryGuid) {
    const category: any = this._.find(this.page.Categories, {
      Guid: categoryGuid,
    });
    return category && category.IsLinkedToRewards;
  }

  showEditQuestionDialog(question) {
    return this.$mdDialog.show({
      controller: 'EditQuestionDialogController',
      controllerAs: 'vm',
      templateUrl: editQuestionDialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        question: angular.copy(question),
        categories: this._.filter(this.page.Categories, (c) =>
          c.OperationType === this.operationTypeConstants.unchanged
          || c.OperationType === this.operationTypeConstants.update
          || c.OperationType === undefined),
        validationConstants: this.validationConstants,
      },
    });
  }

  getRewardsState() {
    if (!this.isFeatureSavingUpRewardsEnabled || !this.isSavingUpRewardsEnabled) {
      // Show manual rewards scenario when feature toggle or saving up toggle is off
      return this.faqCategoryTypeConstants.manualRewards;
    }
    if (this.isAutomaticHandlingEnabled) {
      // Show automatic saving up rewards scenario when automated rewarding via Human8 partners is on
      return this.faqCategoryTypeConstants.savingUpRewards;
    }
    // Show automatic saving up rewards scenario when automated rewarding via Human8 partners is off
    return this.faqCategoryTypeConstants.manualSavingUpRewards;
  }

  isNotDeleted = (o) => o.OperationType !== this.operationTypeConstants.delete;

  getCategoryRewardType(category: number) {
    return Utils.getSentenceCasedLabel(this.faqCategoryTypeConstants, category);
  }
}
