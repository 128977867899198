import { UiModule } from '../../../index';
import { IscSaveButtonController } from './isc-save-button.controller';
var template = "<isc-button theme=\"{{vm.theme}}\" ng-class=\"{ 'is-saving': vm.saving }\" type=\"{{vm.type || 'submit'}}\"\nng-disabled=\"vm.disabled || vm.saving\" label=\"{{vm.label}}\" ng-attr-aria-hidden=\"{{vm.disabled || vm.saving}}\">\n  <isc-button-icon>\n    <md-progress-circular md-diameter=\"24px\"></md-progress-circular>\n  </isc-button-icon>\n</isc-button>\n";
UiModule
    .component('iscSaveButton', {
    template: template,
    controllerAs: 'vm',
    controller: IscSaveButtonController,
    transclude: {
        icon: '?iscButtonIcon',
    },
    bindings: {
        saving: '<',
        type: '@',
        label: '@',
        theme: '@',
    },
});
