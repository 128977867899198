'use strict';

import { IscConsentController } from './isc-consent.controller';

const template = require('./isc-consent.html');

export const IscConsentComponent: ng.IComponentOptions = {
  controller: IscConsentController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    consentValidityDate: '=',
    consentNeverExpires: '=',
  },
};
