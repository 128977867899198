'use strict';

import routeConfig from './memberDetails.route';
import './memberDetails.component';
import './memberDetailsProfile';
import './memberDetailsSegmentations';
import './memberDetailsActivities';
import './memberDetailsIncentives';
import './memberDetailsSavingUp';

angular
  .module('insitesApp.squaremembers.memberOverview')
  .run(routeConfig);
