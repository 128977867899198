'use strict';

export class IscCommunicationStatusFilterController {
  availableStatuses = [
    {
      Label: 'Draft',
      Value: 3,
    },
    {
      Label: 'Scheduled',
      Value: 1,
    },
    {
      Label: 'Active',
      Value: 0,
    },
    {
      Label: 'Closed',
      Value: 2,
    },
  ];
}
