'use strict';

import { RouterHelper } from '../../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.members.details',
      config: {
        url: '/:memberGuid',
        title: 'Member Details',
        component: 'memberDetails',
        activateRoute: 'root.square.members',
        default: '.profile',
        abstract: true,
        disallowRoles: ['Observer'],
      },
    },
    {
      state: 'root.square.members.details.profile',
      config: {
        url: '/profile',
        title: 'Member Details',
        component: 'memberDetailsProfile',
        activateRoute: 'root.square.members',
        disallowRoles: ['Observer'],
      },
    },
    {
      state: 'root.square.members.details.segmentations',
      config: {
        url: '/segmentations',
        title: 'Member Details',
        component: 'memberDetailsSegmentations',
        activateRoute: 'root.square.members',
        disallowRoles: ['Observer'],
      },
    },
    {
      state: 'root.square.members.details.activities',
      config: {
        url: '/activities',
        title: 'Member Details',
        component: 'memberDetailsActivities',
        activateRoute: 'root.square.members',
        disallowRoles: ['Observer'],
      },
    },
    {
      state: 'root.square.members.details.incentivesoverview',
      config: {
        url: '/incentives',
        title: 'Member Details',
        component: 'memberDetailsIncentives',
        activateRoute: 'root.square.members',
        disallowRoles: ['Observer'],
      },
    },
    {
      state: 'root.square.members.details.savingupoverview',
      config: {
        url: '/incentives',
        title: 'Member Details',
        component: 'memberDetailsSavingUp',
        activateRoute: 'root.square.members',
        disallowRoles: ['Observer'],
      },
    },
  ]);
};
routeConfig.$inject = ['routerHelper'];

export default routeConfig;
