'use strict';

import { MailtemplateOverrulerController } from './mailtemplateOverruler.controller';
const template = require('./mailtemplateOverruler.html');
require('./_mailtemplateOverruler.scss');

angular.module('insitesApp.layout')
  .component('mailtemplateOverruler', {
    templateUrl: template,
    controller: MailtemplateOverrulerController,
    controllerAs: 'vm',
    bindings: {
      templateName: '@',
      overruleLabels: '<',
      subjectLabelName: '@',
      placeholders: '=',
      isEdited: '=?',
      checkbox: '@',
      customText: '<',
      showTemplate: '=?',
    },
  });
