'use strict';

export interface ICreateOrUpdateDiscussionActivityRequest {
  guid?: string;
  activityGuid?: string;
  discussionGuid?: string;
  communicationGuid?: string;
  appearance?: number;
  contributionType?: number;
  visibilityType: number;
  moderatorCuration: boolean;
  isOneByOne: boolean;
}

export interface ICreateOrUpdateDiscussionActivityResponse {
  activityGuid: string;
  discussionGuid: string;
  communicationGuid: string;
  title: string;
  contributionType: number;
  visibilityType: number;
}
