'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = async (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.rewards',
      config: {
        abstract: true,
        settings: {
          nav: 8,
          icon: 'fa fa-gift',
          label: 'Rewards',
          section: 'SQUARE MANAGEMENT',
          stateParams: ['clientGuid', 'programGuid', 'squareGuid'],
          showForums: true,
        },
        disallowRoles: ['Observer', 'ProfessionalAdmin', 'ClientAdmin', 'ClientEditor'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', 'serverConstants', 'featureservice'];

export default routeConfig;
