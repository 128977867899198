'use strict';

import { DateTime } from 'luxon';
import { HelperService } from '../core/services/helper.service';
import { SpinnerService } from '../core/services/spinner.service';
import { ExportLogService } from '../core/dataservices/exportlog.service';
import * as _ from 'lodash';

const exportLogDetails = require('./exportLogDetailsDialog.html');

export class ExportLogController implements ng.IOnInit {
  $inject = ['squareservice', 'serverConstants', '$scope'];
  constructor(
    private $q: ng.IQService,
    private spinnerservice: SpinnerService,
    private exportlogservice: ExportLogService,
    private helperservice: HelperService,
    private $mdDialog: ng.material.IDialogService,
    private $timeout: ng.ITimeoutService,
  ) {

    this.endDate = DateTime.now().toJSDate();
    this.startDate = DateTime.now().minus({ days: 1 }).toJSDate();
    this.maxEndDate = DateTime.now().toJSDate();
    this.isFetching = false;
    this.limitOptions = [100, 250, 500, 1000, 2000];
    this.limit = '';
    this.filter = '';
    this.entries = [];
  }

  endDate;
  startDate;
  maxEndDate;
  isFetching;

  limitOptions;
  limit;
  filter;
  entries;
  search = (filter) => this.helperservice.search(filter, this.filter);

  $onInit(): void {
    this.defaultLimitValue();
    this.activate();
  }

  filterChanged = () => {
    this.$timeout(() => {
      this.activate();
    }, 100);
  };

  activate = () => {
    this.spinnerservice.show('loading');
    const promises = [this.getLogList()];
    return this.$q.all(promises)
      .finally(() => {
        this.spinnerservice.hide('loading');
      });
  };

  limitMessage = (message) => {
    if (message && message.length > 200) {
      return `${message.substring(0, 200)}...`;
    }
    return message;
  };

  openEntry = (entry) => {
    this.$mdDialog.show({
      controllerAs: 'vm',
      controller: _.noop,
      bindToController: true,
      templateUrl: exportLogDetails,
      clickOutsideToClose: true,
      fullscreen: false,
      locals: {
        entry,
        getSeverityLabel: '',
        thingsToShow: Object.keys(entry),
        close: () => {
          this.$mdDialog.hide();
        },
      },
    });
  };

  getLogList = () => this.exportlogservice.getLogList(this.startDate, this.endDate, this.limit)
    .then((response) => {
      const data = response.data.List;
      this.entries = data;
      return this.$q.resolve(response);
    });

  defaultLimitValue = () => {
    this.limit = 100;
  };
}
