'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from './../serverconstants';
import { SquareService } from './square.service';
import { ParticipantFactory } from './../participant.factory';
import { ParticipantService } from './participant.service';
import { ICurrentUser, IImpersonateUser, IImpersonate, IClaims } from './dataservice.contracts';
import { Subject } from 'rxjs/Subject';
import { SplashAgreement } from 'src/app/layout/splash-page/splashPage.types';
import { HttpService } from './http.service';
import { setCurrentUser } from '@insites/vue-eco-temp-library';

export class CurrentUserService {
  static $inject = ['squareservice', 'serverConstants', 'participantFactory', 'participantservice', '_', '$q', 'httpservice', '__env', '$rootScope'];

  constructor(
    private squareservice: SquareService,
    private serverConstants: ServerConstants,
    private participantFactory: ParticipantFactory,
    private participantService: ParticipantService,
    private _: _.LoDashStatic,
    private $q: ng.IQService,
    private httpservice: HttpService,
    private __env: Environment,
    $rootScope: ng.IRootScopeService,
  ) {
    $rootScope.$watch(
      () => this.userProfile,
      () => {
        setCurrentUser({
          avatar: this.userProfile.Avatar,
          guid: this.userProfile.Guid,
          role: this.userProfile.Role,
          username: this.userProfile.Username,
          isFullDev: this.userProfile.IsFullDev,
        });
      },
      true);
  }

  impersonatedUserChange: Subject<IImpersonate> = new Subject<IImpersonate>();
  private guid: string;
  private username: string;
  private defaultUserInfo = {
    Guid: '',
    Avatar: ParticipantFactory.defaultAvatar,
    Firstname: '',
    Lastname: '',
    Username: '',
    Role: 0,
    ParticipantId: 0,
    IsFullDev: false,
  };

  private _user: ICurrentUser = angular.copy(this.defaultUserInfo);
  private _originalUser: ICurrentUser;
  private _impersonateUserList: IImpersonateUser[];

  get userProfile() {
    return this._user;
  }

  get userName() {
    return this.username || this._user.Username;
  }

  get originalUser() {
    return this._originalUser;
  }

  get impersonateUserList() {
    return this._impersonateUserList;
  }

  get role() {
    return this._user.Role;
  }

  public async getAgreement(): Promise<SplashAgreement> {
    return this.httpservice.get({
      url: '/api/SplashMessage/GetCurrentAgreement',
    }, this.__env.memberApiUrl).then((response) => response.data);
  }

  setUserProfileFromClaims(claims: IClaims): void {
    this._user = {
      Guid: this.guid || claims.memberGuid,
      Avatar: ParticipantFactory.defaultAvatar,
      Role: claims.role,
      Firstname: claims.firstname,
      Lastname: claims.lastname,
      Username: claims.username,
      IsFullDev: claims.isFullDev,
    };
  }

  async setUserProfileFromSquareParticipantGuid(squareParticipantGuid: string) {
    await this.participantService.getSquareParticipantDetails(squareParticipantGuid).then((response: any) => {
      this.guid = squareParticipantGuid;
      this._user.Guid = squareParticipantGuid;
      this._user.Role = response.data.Detail.Role;
      this._user.Firstname = response.data.Detail.FirstName;
      this._user.Lastname = response.data.Detail.LastName;
      this._user.Username = response.data.Detail.Username;
      this._user.Avatar = this.getAvatar(squareParticipantGuid, response.data.Detail.HasProfileImage, response.data.Detail.DateModified);
      this._user.IsFullDev = response.data.Detail.IsFullDev;
    });
  }

  async getImpersonateUserList() {
    if (this._user.Role !== this.serverConstants.roleConstants.human8
      && this._user.Role !== this.serverConstants.roleConstants.professionalAdmin
      && this._user.Role !== this.serverConstants.roleConstants.clientAdmin
      && this._user.Role !== this.serverConstants.roleConstants.clientEditor) {
      return this.$q.resolve([]);
    }
    await this.squareservice.GetImpersonateUserList().then((response) => {
      // Apply additional filtering based on editor role
      let filteredUsers = response;
      if(this.role === this.serverConstants.roleConstants.clientEditor) {
        filteredUsers = filteredUsers.filter((user) => user.Role === this.serverConstants.roleConstants.clientEditor);
      }

      this._impersonateUserList = this._.map(filteredUsers, (user) => ({
        Guid: user.Guid,
        Username: user.Username,
        Avatar: this.getAvatar(user.Guid, user.HasProfileImage, user.DateModified),
        Role: user.Role,
      }));
    });
  }

  getAvatar(guid: string, hasProfileImage: boolean, lastUpdateDate: string) {
    return hasProfileImage ?
      this.participantFactory.getParticipantImageUrl(guid, lastUpdateDate || DateTime.now().toISO()) :
      ParticipantFactory.defaultAvatar;
  }

  onImpersonateAction(impersonate: IImpersonate) {
    this.username = null;
    if (impersonate) {
      if (!this._originalUser) {
        this._originalUser = this._user;
      }
      this.guid = impersonate.SquareParticipantGuid;
      this.username = this._impersonateUserList?.length
        ? this._impersonateUserList.find((list) => list.Guid === this.guid)?.Username
        : null;
    }
    this.impersonatedUserChange.next(impersonate);
  }
}
