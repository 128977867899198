'use strict';

const publishPushedOnTopTemplate = require('./publishPushedOnTop.html');

angular
  .module('insitesApp.activityconfig')
  .component('publishPushedOnTop', {
    templateUrl: publishPushedOnTopTemplate,
    controller: PublishPushedOnTopController,
    controllerAs: 'vm',
    bindings: {
      pushedOnTop: '<',
    },
  });

PublishPushedOnTopController.$inject = ['serverConstants'];
function PublishPushedOnTopController(serverConstants) {
  this.label = serverConstants.communicationChannelConstants.pushChannelOnTopOfPage;
}
