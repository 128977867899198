'use strict';

import * as angular from 'angular';

import { TargetCompletionComponent } from './target-completion.component';

angular
  .module('insitesApp.layout')
  .component('targetCompletion', TargetCompletionComponent);

