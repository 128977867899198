'use strict';

import { ManualRewardsController } from './manualRewards.controller';

const manualRewardsTemplate = require('./manualRewards.html');

export const ManualRewardsComponent: ng.IComponentOptions = {
  controller: ManualRewardsController,
  templateUrl: manualRewardsTemplate,
  controllerAs: 'vm',
};
