var IscInputButtonController = (function () {
    function IscInputButtonController($element, _) {
        this.$element = $element;
        this._ = _;
        this.editMode = false;
    }
    IscInputButtonController.prototype.$postLink = function () {
        var input = this.createForm.createInput;
        this._.each(this.validations || [], function (validation) {
            if (validation.isAsync) {
                input.$asyncValidators[validation.name] = validation.validate;
            }
            else {
                input.$validators[validation.name] = validation.validate;
            }
        });
    };
    IscInputButtonController.prototype.handleModeChange = function () {
        var _this = this;
        if (this.editMode) {
            this.submit();
        }
        else {
            this.editMode = !this.editMode;
            if (this.editMode) {
                setTimeout(function () {
                    var input = _this.$element.find('input')[0];
                    if (input) {
                        input.focus();
                    }
                }, 50);
            }
        }
    };
    IscInputButtonController.prototype.create = function () {
        this.onCreate();
        this.editMode = !this.editMode;
    };
    IscInputButtonController.prototype.submit = function () {
        if (this.createForm.$valid && !this.createForm.$pending && this.model) {
            this.create();
        }
    };
    IscInputButtonController.$inject = ['$element', '_'];
    return IscInputButtonController;
}());
export { IscInputButtonController };
