'use strict';

import { AuthorViewModel } from '@insites/vue-eco-temp-library/dist/types/api/dist/models/query';

export interface ICreateOrUpdateDiscussionRequest {
  guid?: string;
  title: string;
}

export interface ICreateOrUpdateDiscussionResponse {
  id: number;
  guid: string;
}

export interface ICreateOrUpdatePostRequest {
  guid?: string;
  discussionGuid?: string;
  parentGuid?: string;
  likes?: number;
  moderationStatus?: number;
  label?: number;
  isPublished?: boolean; // the opposite of IsDraft
  isAnonymized?: boolean;
}

export class ICreateOrUpdatePostResponse {
  guid: string;
}

export interface ICreateOrUpdateMessageRequest {
  guid?: string;
  postGuid?: string;
  text: string;
  caption?: string;
}

export interface ICreateOrUpdateMessageResponse {
  guid: string;
}

export interface ICreateOrUpdateQuestionRequest {
  guid?: string;
  postGuid?: string;
  type?: number;
  sortOrder?: number;
  text: string;
  isOptional?: boolean;
}

export interface IRemoveQuestionRequest {
  questionGuid?: string;
}

export interface ICreateOrUpdateQuestionResponse {
  guid: string;
}

export interface ICreateOrUpdateAnswerRequest {
  guid?: string;
  postGuid?: string;
  questionGuid?: string;
  text: string;
}

export interface ICreateOrUpdateAnswerResponse {
  guid: string;
}

export interface ICreateStimulusRequest {
  messageGuid?: string;
  questionGuid?: string;
  answerGuid?: string;
  type: number;
  sortOrder: number;
  caption?: string;
  mimeType?: string;
  socialStimulusId?: string;
  thumbnailUrl?: string;
  name?: string;
}

export interface ICreateStimulusResponse {
  guid: string;
  uploadUrl: string;
  clientCode: string;
}

export interface IUpdateStimulusRequest {
  guid?: string;
  url?: string;
  thumbnailUrl?: string;
  clientCode?: string;
  assetId?: string;
  status?: number;
}

export interface IUpdateStimulusResponse {
  success: boolean;
}

export interface IRemoveStimulusRequest {
  stimulusGuid: string;
}

export interface IRemovalResponse {
  success: boolean;
}

export interface IDiscussionActivityRequest {
  activityGuid?: string;
  communicationGuid?: string;
}

export interface IStimulusViewModel {
  guid?: string;
  type: number;
  sortOrder?: number;
  caption?: string;
  thumbnailUrl?: URL;
  uploadId?: string;
  url?: URL;
  name?: string;
}

export interface IMessageViewModel {
  text: string;
  guid: string;
  postGuid?: string;
  stimuli: IStimulusViewModel[];
}

export interface IPostViewModel {
  id: number;
  guid: string;
  parentId?: number;
  sortOrder?: number;
  isOpeningPost: boolean;
  isPublished: boolean;
  authorGuid: string;
  author?: AuthorViewModel;
  onBehalfOfGuid?: string;
  onBehalfOf?: AuthorViewModel;
  datePublished: Date;
  message?: IMessageViewModel;
  questions: IQuestionViewModel[];
}

export interface IQuestionViewModel {
  guid: string,
  postGuid: string,
  text: string,
  type: number,
  isOptional: boolean,
}
export interface IDiscussionActivityResponse {
  discussionGuid?: string;
  activityGuid?: string;
  communicationGuid?: string;
  title: string;
  post: IPostViewModel;
  contributionType: number;
  visibilityType: number;
  appearance: number;
  isOneByOne: boolean;
  isAnswered: boolean;
  caption: string;
}

export interface ICommunicationChannelWithStimulus {
  Channel?: ICommunicationChannelDetailItem;
  Stimulus?: ICommunicationChannelStimuliDetailItem;
}

export interface ICommunicationChannelDetailItem {
  Guid: string; // CommunicationChannelGuid
  CommunicationGuid: string;
  ChannelType: number;
  Message: string; // CardTeaser
  Title: string;
  CallToActionText: string;
  CardTeaser: string;
  ShowOnHomepage: boolean;
  Sticky: boolean;

}

export interface ICommunicationChannelStimuliDetailItem {
  Guid: string;
  Type: number;
  FileType: string;
  Url: string;
  ThumbnailUrl: string;
  CommunicationGuid: string;
  CommunicationChannelGuid: string;
}
