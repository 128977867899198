'use strict';

export class EqualsTo implements ng.IDirective {
  restrict = 'A';
  require = 'ngModel';
  scope = {
    otherModelValue: '=equalsTo',
  };

  link($scope, element, attributes, ngModel) {
    ngModel.$validators.equalsTo = (modelValue) => modelValue === $scope.otherModelValue;

    $scope.$watch('otherModelValue',
      () => {
        ngModel.$validate();
      });
  }
}
