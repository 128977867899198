'use strict';

import { RouterHelper } from '../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'log',
    config: {
      url: '/log',
      component: 'logList',
      title: 'Log',
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

