import { __awaiter, __generator } from "tslib";
var IscTagController = (function () {
    function IscTagController($element, $scope, $mdDialog, $q) {
        this.$element = $element;
        this.$scope = $scope;
        this.$mdDialog = $mdDialog;
        this.$q = $q;
        this.searchTerm = '';
        this.jobIdsCustomTouched = false;
    }
    IscTagController.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watchCollection('vm.model', function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.ensureModelController();
                        if (!this.isRequired) return [3, 2];
                        this.ngModelController.$setValidity('required', true);
                        if (!(!this.model || this.model.length === 0)) return [3, 2];
                        return [4, this.delay(200)];
                    case 1:
                        _a.sent();
                        this.ngModelController.$setValidity('required', false);
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        }); });
    };
    IscTagController.prototype.onTextTyped = function () {
        this.jobIdsCustomTouched = true;
        this.ngModelController.$setValidity('serverErrors', true);
    };
    IscTagController.prototype.delay = function (ms) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2, this.$q(function (resolve) { return setTimeout(resolve, ms); })];
            });
        });
    };
    IscTagController.prototype.removeChip = function (event, chip) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        event.stopImmediatePropagation();
                        if (!(this.warningMessage != null)) return [3, 2];
                        return [4, this.$mdDialog.show(this.$mdDialog.iscConfirm()
                                .title('Warning')
                                .text(this.warningMessage)
                                .ok('Yes')
                                .cancel('No'))];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        this.model.splice(this.model.indexOf(chip), 1);
                        this.onRemove({ chip: chip });
                        this.ngModelController.$setDirty();
                        return [2];
                }
            });
        });
    };
    IscTagController.prototype.ensureModelController = function () {
        var element = this.$element.find('md-chips');
        this.ngModelController = element.controller('ngModel');
    };
    IscTagController.prototype.getChip = function (chip) {
        if (!this.chipProperty || chip[this.chipProperty] === undefined) {
            return chip;
        }
        return chip[this.chipProperty];
    };
    IscTagController.$inject = ['$element', '$scope', '$mdDialog', '$q'];
    return IscTagController;
}());
export { IscTagController };
