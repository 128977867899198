'use strict';

import { DateTime } from 'luxon';
import { FeatureService } from './../../../../../core/dataservices/feature.service';
import { LabelFactory } from '../../../../../core/label.factory';
import { SelectedSquareFactory } from '../../../../../core/selectedsquare.factory';
import { ServerConstants } from '../../../../../core/serverconstants';
import { IncentiveService } from '../../../../../core/dataservices/incentive.service';
import {
  IManualRewardRedemption, ISavingUpRedemption, ISavingUpReward, ISavingUpCard,
  ISavingUpRedemptionCard, ISavingUpRewardCard, ISavingUpParent, ISavingUpPartnerLabel,
  IManualRewardRedemptionCard, ISavingUpInvalidation, ISavingUpInvalidationCard, IInfoCard, IRedemption,
} from '../../../../../core/dataservices/incentive.contracts';
import * as _ from 'lodash';
import { UserNotificationService } from '../../../../../core/dataservices/usernotification.service';
import { IUserNotification } from '../../../../../core/services/notifications.contracts';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { IncentiveCardType } from 'isc-ui';
import { Utils } from '../../../../../core/utils';

export class MemberDetailsSavingUpController {
  static $inject = ['incentiveservice', 'serverConstants', '$state', '$stateParams'
    , 'userNotificationService', 'selectedSquareFactory', 'labelFactory', 'featureservice', 'spinnerservice', '__env'];

  constructor(
    private incentiveService: IncentiveService,
    private serverConstants: ServerConstants,
    private $state: ng.ui.IStateService,
    private $stateParams: { memberGuid: any; },
    private userNotificationService: UserNotificationService,
    private selectedSquareFactory: SelectedSquareFactory,
    private labelFactory: LabelFactory,
    private featureService: FeatureService,
    private spinnerservice: SpinnerService,
    private __env: Environment,
  ) { }

  pointsSavedUp: number;
  minimumPointsToRedeem: number;
  pointValue: number;
  currency: string;
  unitLabel: string;
  isAutomaticHandlingEnabled: boolean;
  savingUpRedemptions: ISavingUpRedemption[];
  rewards: ISavingUpReward[];
  invalidations: ISavingUpInvalidation[];
  manualInterventionRedemptions: IManualRewardRedemption[];
  manualRewardsRedemptions: IManualRewardRedemption[];
  cards: ISavingUpCard[];
  rewardLabels: ISavingUpPartnerLabel[] = [];
  channelTypes: any;
  savingUpRewardsEarnedNotifications: IUserNotification[] = [];
  SavingUpCardTypes = IncentiveCardType;
  isAlmostInactive: boolean;
  winnerStatus = this.serverConstants.winnerStatusConstants;
  canSeeRewardLinks = false;

  async $onInit() {
    try {
      this.spinnerservice.show('loading');

      // Saving Up here is enabled only if
      //        Is enabled in master and at square level
      const isSavingUpEnabled = await this.featureService.isFeatureEnabledForSquare(
        this.serverConstants.featureConstants.savingUpRewards);
      if (!isSavingUpEnabled) {
        this.$state.go('root.square.members.details.incentivesoverview');
      }
      this.reset();
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async reset() {
    const data = await this.incentiveService.getSavingUpRedeemOverview(this.$stateParams.memberGuid);
    this.pointsSavedUp = data.PointsSavedUp;
    this.minimumPointsToRedeem = data.MinimumPointsToRedeem;
    this.pointValue = data.PointValue;
    this.currency = data.Currency;
    this.unitLabel = data.UnitLabel;
    this.isAutomaticHandlingEnabled = data.IsAutomaticHandlingEnabled;

    this.channelTypes = this.serverConstants.channelTypeConstants;
    this.savingUpRewardsEarnedNotifications = this.userNotificationService.userNotifications
      .filter((n) => n.NotificationType === this.serverConstants.notificationTypeConstants.savingUpRewardsEarned ||
        n.NotificationType === this.serverConstants.notificationTypeConstants.moreSavingUpRewardsEarned);
    await this.fetchAllRewardLabels();
    this.canSeeRewardLinks = await this.incentiveService.canSeeRewardLinks(this.$stateParams.memberGuid);
    await this.getRewardData(this.$stateParams.memberGuid);
    this.arrangeRewards();
  }

  async fetchAllRewardLabels() {
    const squareLanguage = await this.selectedSquareFactory.languagePromise;
    const labels = await this.labelFactory.getLabelsLanguage(squareLanguage);

    for (const prop in this.serverConstants.rewardPartnerTypeConstants) {
      if (Object.prototype.hasOwnProperty.call(this.serverConstants.rewardPartnerTypeConstants, prop)) {
        const value = this.serverConstants.rewardPartnerTypeConstants[prop];
        const capitalized = prop.charAt(0).toUpperCase() + prop.slice(1);
        const label = labels.getLabelValue(`LabelRewardPartner${capitalized}`);
        if (this.serverConstants.rewardPartnerTypeConstants[prop] !== this.serverConstants.rewardPartnerTypeConstants.none) {
          this.rewardLabels[value] = label || prop;
        }
      }
    }
  }

  async arrangeRewards() {
    // Make sorted list of all items
    const tempCards: ISavingUpCard[] = _.concat(
      _.map(this.savingUpRedemptions, (r: ISavingUpRedemption) => {
        const card = {
          DateTime: r.DateCreated,
          Guid: r.Guid,
          Type: this.SavingUpCardTypes.redemptionHistory,
          ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
          SavingUpUnits: r.SavingUpUnits,
          SavingUpUnitValue: r.SavingUpUnitValue,
          SavingUpTotalValue: r.SavingUpTotalValue,
          CurrencyName: r.CurrencyName,
          RewardPartner: this.rewardLabels[r.RewardPartner] as string,
          Children: [],
          Leftover: r.Leftover,
          RewardWinnerStatusLabel: this.getRewardWinnerStatusLabel(r.Status, r.DateNotificationSent),
          ClipboardItem: r.ClipboardItem,
        } as ISavingUpRedemptionCard;
        return card;
      }) as ISavingUpCard[],
      _.map(this.rewards, (r: ISavingUpReward) => {
        // If ReceivedPoints is filled in, it automatically means that the reward has been earned.
        // When screened out or quotafull, it can be that no points were configured, meaning ReceivedPoints will not be filled in.
        // The card should still be marked as a rewardEarned card anyway.
        const type = r.ReceivedPoints
          || r.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.quotaFull
          || r.OutcomeCode === this.serverConstants.surveyOutcomeCodeConstants.screened
          ? this.SavingUpCardTypes.rewardEarned
          : this.SavingUpCardTypes.rewardNotEarned;

        const card = {
          DateTime: r.RewardDateCreated || (r.IsRewardActivityCompleted ? r.DateRewardActivityCompleted : r.ActivityEndDate),
          Guid: r.Guid,
          Type: type,
          ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
          IsSampleCompleted: r.IsSampleCompleted,
          IsActivityFinished: r.IsActivityFinished,
          Removed: r.Removed,
          SavingUpUnits: r.ReceivedPoints || r.CompletionPoints,
          IsRewardActivityCompleted: r.IsRewardActivityCompleted,
          ActivityName: r.ActivityName,
          SampleStatus: r.SampleStatus,
          OutcomeCode: r.OutcomeCode,
        } as ISavingUpRewardCard;
        return card;
      }) as ISavingUpCard[],
      _.map(this.manualInterventionRedemptions, (r: IManualRewardRedemption) => {
        const card = {
          DateTime: r.DateCreated,
          Guid: r.Guid,
          Type: r.ActivityGuid
            ? this.SavingUpCardTypes.manualRedemptionHistory
            : r.ManualIntervention
              ? this.SavingUpCardTypes.manualIntervention
              : this.SavingUpCardTypes.manualRedemptionHistory,
          ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
          RewardValue: r.RewardValue,
          RewardPartner: this.rewardLabels[r.RewardPartner] as string,
          CurrencyName: r.CurrencyName,
          ManualIntervention: r.ManualIntervention,
          CustomMessage: r.CustomMessage,
          Leftover: null,
        } as IManualRewardRedemptionCard;
        return card;
      }) as ISavingUpCard[],
      this.isAutomaticHandlingEnabled && this.pointsSavedUp >= this.minimumPointsToRedeem &&
        this.pointsSavedUp * this.pointValue >= 1 ? // If redeemable, add redeem card
        ({
          DateTime: DateTime.now().toISO(),
          Guid: 'redeemCard',
          Type: this.SavingUpCardTypes.redemptionAction,
          ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
          SavingUpUnits: 0,
          SavingUpUnitValue: 0,
          RewardPartner: '',
          Children: [],
        } as ISavingUpRedemptionCard) as ISavingUpCard : [],
      _.map(this.invalidations, (i: ISavingUpInvalidation) => ({
        DateTime: i.DateCreated,
        Guid: i.Guid,
        Credits: i.Credits,
        Type: this.SavingUpCardTypes.invalidation,
        ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
        Children: [],
      } as ISavingUpInvalidationCard)) as ISavingUpCard[],
    ).sort((a, b) => DateTime.fromISO(b.DateTime).toMillis() - DateTime.fromISO(a.DateTime).toMillis());
    // Make parents and children and assign each child to a parent
    let parent: ISavingUpParent;
    this.cards = [];
    _.forEach(tempCards, (card) => {
      if (this.IsReward(card)) { // If reward, add to parent
        if (!parent) { // If no parent => Create parent for not redeemed active rewards.
          parent = {
            DateTime: DateTime.now().toISO(),
            Guid: 'emptyContainer',
            Type: this.SavingUpCardTypes.emptyContainer,
            ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
            Children: [],
            Leftover: null,
          };
          this.cards.push(parent);
        }
        parent.Children.push(card);
        return;
      }
      // If invalidation, turn current parent into invalidation
      if (card.Type === this.SavingUpCardTypes.info) {
        this.cards.push(card);
        return;
      }
      // If redemption, make parent
      parent = (card as ISavingUpRedemptionCard);
      this.cards.push(card);
    });
    // if almost inactive, add info card
    if (this.isAlmostInactive && this.pointsSavedUp > 0) {
      this.cards.push(({
        DateTime: DateTime.now().plus({ seconds: 1 }).toISO(), // Keep it at the top of the list
        Guid: 'AlmostInactive',
        Type: this.SavingUpCardTypes.info,
        ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
        Leftover: null,
        Title: 'Almost ',
        Body: 'Inactive',
      } as IInfoCard) as ISavingUpCard);
    }
    // Add manual reward cards
    this.cards = _.concat(this.cards, _.map(this.manualRewardsRedemptions, (r: IManualRewardRedemption) => {
      const card = {
        DateTime: r.DateCreated,
        Guid: r.Guid,
        Type: r.ActivityGuid
          ? this.SavingUpCardTypes.manualRedemptionHistory
          : r.ManualIntervention
            ? this.SavingUpCardTypes.manualIntervention
            : this.SavingUpCardTypes.manualRedemptionHistory,
        ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
        RewardValue: r.RewardValue,
        RewardPartner: this.rewardLabels[r.RewardPartner] as string,
        CurrencyName: r.CurrencyName,
        ManualIntervention: r.ManualIntervention,
        Leftover: null,
        RewardWinnerStatusLabel: this.getRewardWinnerStatusLabel(r.Status, r.DateNotificationSent),
        ClipboardItem: r.ClipboardItem,
      } as IManualRewardRedemptionCard;
      return card;
    }) as ISavingUpCard[])
      .sort((a, b) => DateTime.fromISO(b.DateTime).toMillis() - DateTime.fromISO(a.DateTime).toMillis());

    // For each SavingUpRedemptionCard, add its leftover as the first child of the last card
    // The manual redemption cards have to be filtered out since we don't want to add it as a child to that type of card
    const cardsWithoutManualRedemption = this.cards.filter((c) => c.Type !== this.SavingUpCardTypes.manualRedemptionHistory);
    if (cardsWithoutManualRedemption.length > 0) {
      // Loop over the cards
      for (let i = 0; i < cardsWithoutManualRedemption.length; i++) {
        const currentCard = cardsWithoutManualRedemption[i];
        // Check if there's a leftover
        if (currentCard.Leftover && currentCard.Leftover !== 0 && currentCard.Type === this.SavingUpCardTypes.redemptionHistory) {
          // Check if we need to add a new container, this will be the case if we're handling the first card in the list
          if (i === 0) {
            // Create empty container
            const emptyContainer: ISavingUpParent = {
              DateTime: DateTime.now().toISO(),
              Guid: 'emptyContainer',
              Type: this.SavingUpCardTypes.emptyContainer,
              ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
              Children: [],
              Leftover: null,
            };
            // Add it to both the actual cards and the cardsWithoutManualRedemption arrays
            this.cards.unshift(emptyContainer);
            cardsWithoutManualRedemption.unshift(emptyContainer);
            // After adding the container, the current index needs to be updated
            i++;
          }

          // Now that we're sure there's another container, we can add a new card to the children of that container
          const nextCard = cardsWithoutManualRedemption[i - 1] as ISavingUpParent;
          nextCard.Children.push({
            DateTime: DateTime.utc().toISO(),
            Guid: `leftover_${currentCard.Guid}`,
            ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
            Type: this.SavingUpCardTypes.leftover,
            Leftover: currentCard.Leftover,
          } as ISavingUpCard);
        }
      }
    }
  }

  IsReward(card: ISavingUpCard) {
    return [this.SavingUpCardTypes.rewardEarned, this.SavingUpCardTypes.rewardNotEarned, this.SavingUpCardTypes.manualIntervention].indexOf(card.Type) > -1;
  }

  async getRewardData(squareParticipantGuid?: string) {
    await this.incentiveService.getSavingUpRewardRedemptionList(squareParticipantGuid).then((response) => {
      this.savingUpRedemptions = _.map(response, (r: ISavingUpRedemption) => r);
      this.addClipboardItemsToRedemptions(this.savingUpRedemptions);
    });
    await this.incentiveService.getSavingUpRewardItemList(squareParticipantGuid).then((response) => {
      this.rewards = _.map(response, (r: ISavingUpReward) => r);
    });
    await this.incentiveService.getSavingUpInvalidationList(squareParticipantGuid).then((response) => {
      this.invalidations = response;
    });
    this.isAlmostInactive = await this.incentiveService.isParticipantAlmostInactive();

    const manualRewardRedemptions: IManualRewardRedemption[] = await this.incentiveService
      .getManualRewardsEarnedList(squareParticipantGuid);
    this.addClipboardItemsToRedemptions(manualRewardRedemptions);
    this.manualInterventionRedemptions = _.filter(manualRewardRedemptions, (reward: IManualRewardRedemption) => reward.ManualIntervention !== null);
    this.manualRewardsRedemptions = _.filter(manualRewardRedemptions, (reward: IManualRewardRedemption) => reward.ManualIntervention === null);
  }

  addClipboardItemsToRedemptions(redemptions: IRedemption[]) {
    if (!redemptions || !this.canSeeRewardLinks) {
      return;
    }

    _.forEach(redemptions, (reward: IRedemption) => {
      if ((reward.Status === this.winnerStatus.notified && !this.isRewardExpired(reward.Status, reward.DateNotificationSent)) || reward.RedeemedRewardResponse) {
        const interfaceUrl = new URL(this.__env.interfaceUrl);

        reward.ClipboardItem = reward.Status === this.winnerStatus.notified ?
          `${interfaceUrl.protocol}//${this.selectedSquareFactory.SquareUrl}.${this.__env.squareUrlBase}/getreward/${reward.Guid}` :
          this.beautifyRedeemedRewardResponse(reward.RedeemedRewardResponse, reward.Status);
      }
    });
  }

  getRewardWinnerStatusLabel(status: number, dateNotificationSent: string) {
    return this.isRewardExpired(status, dateNotificationSent) ?
      '(LabelRewardWinnerStatusLinkExpired)' :
      `(LabelRewardWinnerStatus${Utils.getEnumValueName(this.serverConstants.winnerStatusConstants, status)})`;
  }

  beautifyRedeemedRewardResponse(redeemedRewardResponse: string, rewardStatus: number) {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (urlRegex.test(redeemedRewardResponse)) {
      return redeemedRewardResponse;
    }

    return rewardStatus === this.winnerStatus.offeredToRedeem ? `Reward ID: ${redeemedRewardResponse}` : redeemedRewardResponse;
  }

  private isRewardExpired(status: number, dateNotificationSent: string) {
    if (status !== this.winnerStatus.notified || !dateNotificationSent) {
      return false;
    }

    const daysDifference = DateTime.now().diff(DateTime.fromISO(dateNotificationSent), 'days').days;

    return daysDifference > this.serverConstants.incentivesConstants.rewardLinkValidityDays;
  }
}
