import { UiModule } from '../../index';
var iscBannerTemplate = "<md-card>\n  <md-card-content ng-transclude=\"iscBannerContent\"></md-card-content>\n</md-card>";
UiModule
    .component('iscBanner', {
    template: iscBannerTemplate,
    controllerAs: 'vm',
    transclude: {
        iscBannerContent: 'iscBannerContent',
    },
});
