'use strict';

import ng = require('angular');
import { ActivityService } from '../core/dataservices/activity.service';

export class ActivityWizardType implements ng.IOnInit {
  static $inject = ['$stateParams', 'activityservice', 'serverConstants', 'logger'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    private serverConstants,
    private logger: Logger,
  ) { }

  loaded = false;
  activityGuid: string;
  activityType: number;
  activityTypes: number[];
  startingStep: string;

  $onInit(): void {
    this.activityTypes = this.serverConstants.squareActivityTypeConstants;
    this.activityGuid = this.$stateParams.activityGuid;
    if (this.$stateParams.toastMessage) {
      this.logger.success(this.$stateParams.toastMessage);
    }
    if (this.$stateParams.activityType) {
      this.activityType = this.$stateParams.activityType as number;
      this.loaded = true;
    } else {
      this.getActivityType();
    }

    this.startingStep = this.$stateParams.step;
  }

  private getActivityType = () => {
    this.activityservice.getActivityDetails(this.activityGuid).then((response) => {
      if (response) {
        this.activityType = response.data.Type;
        this.loaded = true;
      }
    });
  };

  private get isQualResearchActivity() {
    return this.activityservice.isQualResearchActivity(this.activityType);
  }

  private get isQuantActivity() {
    return this.activityservice.isQuantActivity(this.activityType);
  }

}
