'use strict';

import { EventPubSub } from 'isc-ui/dist/event/eventpubsub';
import { AuthService } from '../dataservices/auth.service';
import { ForumService } from '../dataservices/forum.service';
import _ = require('lodash');

export abstract class NotificationsServiceBase {

  private static connectionId: string;
  readonly connected: EventPubSub<boolean>;
  private pendingCalls: Array<() => void> = [];
  private groups: string[] = [];
  public isConnected = false;

  private readonly hubRegistrations: {
    [key: string]: {
      events: Array<EventPubSub<any>>,
      proxy?: any,
    },
  } = {};

  constructor(
    private signalr,
    protected logger: Logger,
    protected $rootScope: ng.IRootScopeService,
    private $q: ng.IQService,
    private __env,
    protected authService: AuthService,
    private forumservice: ForumService,
  ) {
    this.notificationsScope = $rootScope.$new(true);
    this.connected = new EventPubSub<boolean>(this.notificationsScope, 'SignalrConnected', $q, _);
  }

  public async addUserToGroup(groupName: string) {
    if (groupName === undefined || groupName === null) {
      return;
    }
    const forwardCall = () => {
      this.forwardCallToHub('Notifications', 'addUserToGroup', groupName);
      if (!_.includes(this.groups, groupName)) {
        this.groups.push(groupName);
      }
    };

    if (!NotificationsServiceBase.connectionId) {
      this.pendingCalls.push(forwardCall);
    } else {
      forwardCall();
    }

    await this.forumservice.addUserToGroup(groupName);
  }

  public async removeUserFromGroup(groupName: string) {
    const forwardCall = () => {
      this.forwardCallToHub('Notifications', 'removeUserFromGroup', groupName);
      _.remove(this.groups, (item) => item === groupName);
    };

    if (!NotificationsServiceBase.connectionId) {
      this.pendingCalls.push(forwardCall);
    } else {
      forwardCall();
    }

    await this.forumservice.removeUserFromGroup(groupName);
  }

  static get ConnectionId() {
    return NotificationsServiceBase.connectionId;
  }

  private readonly notificationsScope: ng.IScope;

  protected disconnect = _.noop;

  protected abstract getSignalRToken(): ng.IPromise<string>;
  protected abstract addOnTokenChangeListener(fn: () => void): () => void;

  protected onLogout() {
    this.disconnect();
  }

  protected createEvent<T>(hubName: string, signalrEvent: string) {
    if (!this.hubRegistrations[hubName]) {
      this.hubRegistrations[hubName] = { events: [] };
    }
    const pubSub = new EventPubSub<T>(this.notificationsScope, signalrEvent, this.$q, _);
    this.hubRegistrations[hubName].events.push(pubSub);
    return pubSub;
  }

  protected rejectNotConnected() {
    return this.$q.reject('Signalr not connected');
  }

  protected forwardCallToHub<T>(hubName: string, method: string, ...args) {
    if (!NotificationsServiceBase.connectionId) {
      return this.$q.reject('signalr connection not started');
    }

    const hubRegistration = this.hubRegistrations[hubName];
    if (!hubRegistration || !hubRegistration.proxy) {
      return this.$q.reject(`no hub registered with name ${hubName}`);
    }

    args = _.concat([method], args);
    const deffered = this.$q.defer<T>();

    const signalrCall = () => {
      hubRegistration.proxy.invoke(...args).done(() => {
        deffered.resolve();
      }).fail((error) => {
        deffered.reject(error);
      });
    };

    // If the connection is down, keep the call in a queue
    // Unfortunately we loose the exact timestamp
    if (hubRegistration.proxy.connection.state !== 1) {
      this.pendingCalls.push(signalrCall);
    } else {
      signalrCall();
    }

    return deffered.promise;
  }

  protected async connect() {
    this.onLogout();
    const connection = this.signalr(this.__env.signalrUrl, { useDefaultPath: false, logging: this.__env.enableDebug });
    const onDisconnect: Array<() => void> = [];
    _.forEach(this.hubRegistrations, (registrations, hubName) => {
      registrations.proxy = connection.createHubProxy(hubName);

      for (const pubsub of registrations.events) {
        const off = pubsub.useEmitter(registrations.proxy);
        onDisconnect.push(off);
      }
    });

    let updateTokenInQueryString = async () => {
      connection.qs.token = await this.getSignalRToken();
      if (connection.qs && connection.qs.token === '') {
        throw new Error('Empty token');
      }
    };

    const unsubscribeFromTokenChange = this.addOnTokenChangeListener(async () => {
      await updateTokenInQueryString();
    });
    onDisconnect.push(unsubscribeFromTokenChange);

    let connect = async () => {
      connection.qs = { version: this.__env.buildVersion };
      if (this.authService.isImpersonating) {
        connection.qs.ImpersonateGuid = this.authService.impersonate.SquareParticipantGuid;
      }
      await updateTokenInQueryString();
      try {
        await connection.start({
          withCredentials: false,
          transport: ['webSockets', 'serverSentEvents', 'longPolling'],
        });
        NotificationsServiceBase.connectionId = connection.id;
        this.connected.next(true);

        _.forEach(this.hubRegistrations, (reg) => {
          _.forEach(reg.events, (event) => event.resetSubscriptions());
        });

        // Restore groups
        for (const groupName of this.groups) {
          this.addUserToGroup(groupName);
        }

        this.isConnected = true;

        for (const pending of this.pendingCalls) {
          pending();
        }
        this.pendingCalls = [];
      } catch (e) {
        // There are too many retries to bother users with a message, maybe in future UX could be improved
        this.logger.log(e.message);
      }
    };
    await connect();

    // Retry connection
    let connectAttempts = 1;
    const maxConnectAttempts = 5;
    let timeoutInSeconds = 5;
    connection.disconnected(() => {
      this.isConnected = false;
      if (connect && connectAttempts < maxConnectAttempts) {
        connectAttempts++;
        // Calculate timeout between two attempts
        // First  will be between 1        and 12 seconds
        // Second                 (First)      24
        // Third                  (Second)     36
        // Fourth                 (Third)      48
        // Fifth                  (Fourth)     60
        timeoutInSeconds = _.random(timeoutInSeconds, 60) * connectAttempts / maxConnectAttempts;
        setTimeout(() => {
          if (connect) {
            connect();
          }
        }, timeoutInSeconds * 1000);
      }
    });

    this.disconnect = () => {
      for (const off of onDisconnect) {
        off();
      }
      this.pendingCalls = [];
      connection.stop();
      connect = null;
      updateTokenInQueryString = null;
      delete connection.qs;
      this.disconnect = _.noop;
      NotificationsServiceBase.connectionId = null;
      this.connected.next(false);
    };
  }
}
