'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.forum.room.newDiscussionConversation',
      config: {
        url: '/conversation-new?page?sort?replyGuid?limit?query',
        component: 'forumNewDiscussionConversationComponent',
        title: 'Conversation',
        isVue: true,
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', 'serverConstants', 'featureservice'];

export default routeConfig;

