'use strict';

import { ActivityService } from '../../../../../../core/dataservices/activity.service';

export class ActivityCompleteRedirectController {
  static $inject = ['activityservice'];
  profileActivities = [
    {
      Name: 'No redirect',
      Guid: null,
    },
    {
      Name: 'Second',
      Guid: 'Uff2',
    },
  ];

  constructor(private activityservice: ActivityService) {}

  $onInit() {
    this.activityservice.getProfileActivities().then((activities) => {
      this.profileActivities = [
        {
          Name: 'None',
          Guid: null,
        },
        ...activities,
      ];
    });
  }
}
