'use strict';

import './squaredownload.module';
import routeConfig from './squaredownload.route';
import { SquareDownloadComponent } from './squaredownload.component';

angular
  .module('insitesApp.squaredownload')
  .component('squareDownload', SquareDownloadComponent)
  .run(routeConfig);
