'use strict';


import { ClientService } from '../../../core/dataservices/client.service';
import { IClientConfigurationVideoStorage } from '../../../core/dataservices/dataservice.contracts';
import * as _ from 'lodash';
import { ServerConstants } from 'src/app/core/serverconstants';

export class VideoStorageConfigurationController {

  static $inject = [
    '$stateParams',
    'clientservice',
    'logger',
    'serverConstants',
  ];

  VideoStorage: number;
  originalVideoStorage: number;
  VideoQuality: number;
  originalVideoQuality: number;
  VideoPostJob: boolean = false;
  originalVideoPostJob: boolean = false;
  VideoStorageOptions;
  VideoQualityOptions;
  isSaving: boolean = false;
  HideVideoQuality: boolean = true;

  videoStorageConfigForm: ng.IFormController;

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private clientservice: ClientService,
    private logger: Logger,
    private serverConstants: ServerConstants,
  ) {
    this.VideoStorageOptions = [{
      Label: 'Azure Media Services',
      Type: serverConstants.clientVideoStorageConstants.azureMediaServices,
    }, {
      Label: 'Mux Services',
      Type: serverConstants.clientVideoStorageConstants.mux,
    }, {
      Label: 'Human8 Media Service',
      Type: serverConstants.clientVideoStorageConstants.human8MediaService,
    }];
    this.VideoQualityOptions = [{
      Label: 'Low quality',
      Type: serverConstants.clientVideoQualityConstants.low,
    }, {
      Label: 'Medium quality',
      Type: serverConstants.clientVideoQualityConstants.medium,
    }, {
      Label: 'High quality',
      Type: serverConstants.clientVideoQualityConstants.high,
    }];
    this.isSaving = false;
  }

  $onInit() {
    this.clientservice
      .getClientConfigurationVideoStorage(this.$stateParams.clientGuid)
      .then((videoStorage) => {
        if (videoStorage) {
          this.VideoStorage = videoStorage.VideoStorage;
          this.originalVideoStorage = this.VideoStorage;
          this.VideoQuality = videoStorage.VideoQuality;
          this.originalVideoQuality = this.VideoQuality;
          this.VideoPostJob = videoStorage.VideoPostJob;
          this.originalVideoPostJob = this.VideoPostJob;
          this.videoStorageChanged(this.VideoStorage);
        }
      });
  }

  isFormDirty(): boolean {
    return (this.videoStorageConfigForm.VideoStorage && this.videoStorageConfigForm.VideoStorage.$dirty)
      || (this.HideVideoQuality || (this.videoStorageConfigForm.VideoQuality && this.videoStorageConfigForm.VideoQuality.$dirty)
        || (this.videoStorageConfigForm.VideoPostJob && this.videoStorageConfigForm.VideoPostJob.$dirty));
  }

  isFormValid(): boolean {
    return (this.videoStorageConfigForm.VideoStorage && this.videoStorageConfigForm.VideoStorage.$valid)
      || (this.HideVideoQuality || (this.videoStorageConfigForm.VideoQuality && this.videoStorageConfigForm.VideoQuality.$valid)
        || (this.videoStorageConfigForm.VideoPostJob && this.videoStorageConfigForm.VideoPostJob.$valid));
  }

  couldSave(): boolean {
    return this.isFormDirty()
      && this.isFormValid();
  }

  hasErrorsFor(validationErrors, propertyName: string) {
    return _.some(validationErrors, ['PropertyName', propertyName]);
  }

  errorMessage(validationErrors, propertyName: string) {
    const propertyErrors = _.filter(validationErrors, ['PropertyName', propertyName]);
    const messages = _.map(propertyErrors, 'ErrorMessage');

    return _.join(messages, ' ');
  }

  setServerErrors(validationErrors, control, propertyName: string) {
    if (!this.hasErrorsFor(validationErrors, propertyName)) {
      return;
    }

    control.$setDirty();
    control.$setValidity('serverErrors', false);
    control.errorMessage = this.errorMessage(validationErrors, propertyName);
  }

  save() {
    this.isSaving = true;
    const request: IClientConfigurationVideoStorage = {
      ClientGuid: this.$stateParams.clientGuid,
      VideoStorage: this.VideoStorage,
      VideoQuality: this.VideoQuality,
      VideoPostJob: this.VideoPostJob,
    };
    return this.clientservice
      .saveClientConfigurationVideoStorage(request)
      .then(() => {
        this.logger.success('Video storage configuration saved successfully');
        this.videoStorageConfigForm.$setPristine();
        this.originalVideoStorage = this.VideoStorage;
        this.originalVideoQuality = this.VideoQuality;
      }, (error) => {
        if (error.status === 400) {
          const validationErrors = error.data.ValidationErrors;
          this.setServerErrors(validationErrors, this.videoStorageConfigForm.VideoStorage, 'VideoStorage');
          this.setServerErrors(validationErrors, this.videoStorageConfigForm.VideoQuality, 'VideoQuality');
        }
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  cancel() {
    this.VideoStorage = this.originalVideoStorage;
    this.VideoQuality = this.originalVideoQuality;
    this.VideoPostJob = this.originalVideoPostJob;
    this.videoStorageChanged(this.VideoStorage);
    this.videoStorageConfigForm.$setPristine();
  }

  private videoStorageChanged(value) {
    this.HideVideoQuality = (value !== this.serverConstants.clientVideoStorageConstants.mux);
  }

}
