'use strict';

import { ToggleActivitySegmentationButtonController } from './toggle-activity-segmentation-button.controller';
const template = require('./toggle-activity-segmentation-button.html');

export const ToggleActivitySegmentationButtonComponent: ng.IComponentOptions = {
  controller: ToggleActivitySegmentationButtonController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    activityGuid: '<',
    segmentationGuid: '<',
    activityName: '<',
  },
};
