'use strict';

import { SquareService } from 'src/app/core/dataservices/square.service';
import { ServerConstants } from '../../../core/serverconstants';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { SquareSocialLoginConfigResponse } from 'src/app/core/dataservices/square.service.contracts';

export class SocialLoginController {
  static $inject = ['$scope', '_', 'logger', 'serverConstants', 'authService', 'squareservice'];

  constructor(
    private $scope: ng.IScope,
    private _: _.LoDashStatic,
    private logger: Logger,
    private serverConstants: ServerConstants,
    private authService: AuthService,
    private squareService: SquareService,
  ) {

  }

  form: ng.IFormController;
  isSaving = false;
  model: SquareSocialLoginConfigResponse;

  async $onInit() {
    const configResponse = await this.squareService.getSquareSocialLoginConfig();
    this.model = configResponse && configResponse.data
      ? configResponse.data
      : { Types: [] };
  }

  async savePage() {
    this.isSaving = true;

    try {
      const success = await this.squareService.setSquareSocialLoginConfig(this.model);
      if (success) {
        this.logger.success('Social login configuration saved successfully');
      } else {
        this.logger.error('Error saving social login configuration');
      }
    } catch {
      this.logger.error('Error saving social login configuration');
    }

    this.isSaving = false;
    this.form.$setPristine();
  }

}
