'use strict';

import './config.module';
import { ConfigComponent } from './config.component';
import routeConfig from './config.route';

angular
  .module('insitesApp.squareconfig.footer')
  .component('configList', ConfigComponent)
  .run(routeConfig);
