'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const getVideoStorageStates = () => [{
  state: 'root.clientconfigvideostorage',
  config: {
    url: 'client/:clientGuid/config/videostorage',
    component: 'videoStorageConfiguration',
    title: 'Video storage',
    disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
  },
}];

const appRunVideoStorageConfiguration = (routerHelper: RouterHelper) => {
  routerHelper.configureStates(getVideoStorageStates());
};
appRunVideoStorageConfiguration.$inject = ['routerHelper'];

export default appRunVideoStorageConfiguration;

