'use strict';

const publishDateTemplate = require('./publishDate.html');

angular
  .module('insitesApp.activityconfig')
  .component('publishDate', {
    templateUrl: publishDateTemplate,
    controllerAs: 'vm',
    bindings: {
      startDate: '<',
      endDate: '<',
      offSet: '<',
      isCompletionTarget: '=',
      completionTarget: '=',
    },
  });
