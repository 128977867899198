'use strict';

export class SegmentModel {
  private _initialSelected;
  private _initialIndeterminate;
  get selected() {
    return this._selected;
  }
  set selected(value) {
    this._indeterminate = false;
    this._selected = value;
  }
  get indeterminate() {
    return this._indeterminate;
  }
  get hasChanges() {
    return this._selected !== this._initialSelected || this._indeterminate !== this._initialIndeterminate;
  }
  constructor(public readonly guid: string, public readonly label: string, private _selected: boolean, private _indeterminate: boolean) {
    this._initialSelected = _selected;
    this._initialIndeterminate = _indeterminate;
  }
}
