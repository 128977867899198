'use strict';

import { TargetCompletionController } from './target-completion.controller';

const template = require('./target-completion.html');
require('./target-completion.scss');
export const TargetCompletionComponent: ng.IComponentOptions = {
  controller: TargetCompletionController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    activityGuid: '<',
    activityInfo: '<',
    isReadonly: '=',
    segmentTargets: '=?',
    isUpdateSamplesDisabled: '=?',
    isUpdatingSamples: '=?',
    updateSamplesFn: '&?',
    isValid: '=?',
    hasChanges: '=?',
    lastModified: '=?',
    uniqueNrOfCompletes: '=?',
  },
};
