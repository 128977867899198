'use strict';

import * as angular from 'angular';

import './isc-on-vertical-changed';
import './must-not-contain-survey-link-validator';

import { ErrSrc } from './err-src.directive';
import { DropdownGeneral } from './dropdown-general.directive';
import { EqualsTo } from './equals-to.directive';
import { Input } from './input.directive';
import { IframeDirective } from './i-frame.directive';
import { ScrollToFixed } from './scroll-to-fixed.directive';
import { OnScrollShadow } from './on-scroll-shadow.directive';
import { SegmentationValidator } from './segmentation-validator.directive';
import { FaqCategoryNameValidator } from './faq-category-name-validator.directive';
import { FaqQuestionTitleValidator } from './faq-question-title-validator.directive';
import { UsernameValidator } from './username-validator.directive';
import { PasswordRuleValidator } from './password-rule-validator.directive';
import { AutoGrowTextArea } from './auto-grow-text-area.directive';
import { PageService } from '../dataservices/page.service';
import { ParticipantService } from '../dataservices/participant.service';
import { SegmentationService } from '../dataservices/segmentation.service';
import { KeyboardTableSort } from './keyboardTableSort.directive';
import { DisableButtonTabindex } from './disable-button-tabindex.directive';
import { AccessibleNgf } from './accessible-ngf.directive';
import { FocusWithinMenu } from './focus-within-menu.directive';
import { ShowDetails } from './show-details.directive';

angular
  .module('insitesApp.core')
  .directive('errSrc', () => new ErrSrc())
  .directive('dropdownGeneral', () => new DropdownGeneral())
  .directive('equalsTo', () => new EqualsTo())
  .directive('input', () => new Input())
  .directive('scrollToFixed', () => new ScrollToFixed())
  .directive('onScrollShadow', ['$document', '$filter',
    ($document, $filter) => new OnScrollShadow($document, $filter)])
  .directive('segmentationValidator', ['$q', 'segmentationservice',
    ($q, segmentationservice: SegmentationService) => new SegmentationValidator($q, segmentationservice)])
  .directive('faqCategoryNameValidator', ['$q', 'pageService', 'logger',
    ($q, pageService: PageService, logger) => new FaqCategoryNameValidator($q, pageService, logger)])
  .directive('faqQuestionTitleValidator', ['$q', 'pageService', 'logger',
    ($q, pageService: PageService, logger) => new FaqQuestionTitleValidator($q, pageService, logger)])
  .directive('usernameValidator', ['$q', 'participantservice', '_', 'logger', 'serverConstants',
    ($q, participantservice: ParticipantService, _, logger, serverConstants) => new UsernameValidator($q, participantservice, _, logger, serverConstants)])
  .directive('passwordRuleValidator', () => new PasswordRuleValidator())
  .directive('autoGrowTextArea', () => new AutoGrowTextArea())
  .directive('iframeDirective', ['$sce', ($sce) => new IframeDirective($sce)])
  .directive('th', () => new KeyboardTableSort())
  .directive('ngfSelect', () => new AccessibleNgf())
  .directive('navButton', () => new KeyboardTableSort())
  .directive('iscButton', () => new KeyboardTableSort())
  .directive('mdpTimePicker', () => new DisableButtonTabindex())
  .directive('mdMenuContent', () => new FocusWithinMenu())
  .directive('iscUserDetailsButton', () => new ShowDetails());
