'use strict';

angular
  .module('insitesApp.core', [
    'ngAnimate', 'ngSanitize',
    'blocks.exception', 'blocks.logger', 'blocks.router', 'blocks.state',
    'ui.router', 'ui.router.state.events', 'ngMaterial', 'ngMessages',
    'ngWig', 'naif.base64', 'mdColorPicker', 'ngFileUpload', 'youtube-embed',
    'vimeoEmbed', 'ngImgCrop',
    'isc-ui', 'ui.router.default', 'as.sortable', 'ui.ace', 'ui.tree', 'angularXRegExp',
    'ngTouch',
  ]);
