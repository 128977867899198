'use strict';

import { MemberDetailsSegmentationsController } from './memberDetailsSegmentations.controller';
const template = require('./memberDetailsSegmentations.html');

angular.module('insitesApp.squaremembers.memberOverview').component('memberDetailsSegmentations', {
  controller: MemberDetailsSegmentationsController,
  controllerAs: 'vm',
  templateUrl: template,
});
