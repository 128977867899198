'use strict';

import { HttpService } from './http.service';
import { IManuallyHandledRewardFilter } from '../contracts/filter.contract';
import * as _ from 'lodash';

export class IncentiveService {
  static $inject = ['httpservice', '$stateParams'];

  constructor(
    private httpService: HttpService,
    private $stateParams: { memberGuid: any; },
  ) { }

  // //////////////

  invalidateCache() {
    this._getSavingUpRedeemOverview.cache = new _.memoize.Cache();
    this._getSavingUpRewardRedemptionList.cache = new _.memoize.Cache();
    this._getSavingUpRewardItemList.cache = new _.memoize.Cache();
    this._getManualRewardsEarnedList.cache = new _.memoize.Cache();
    this._canSeeRewardLinks.cache = new _.memoize.Cache();
  }

  getSquareInstantRewards() {
    return this.httpService.get({
      url: '/IncentiveService/GetSquareInstantRewardsList',
    }).then((response) =>
      response.data,
    );
  }

  getManuallyHandledRewards(pageNumber: number, limit: number, order: string, filter: IManuallyHandledRewardFilter) {
    return this.httpService.postToQuery({
      url: '/IncentiveService/GetManuallyHandledRewards',
      data: {
        pageNumber,
        limit,
        order,
        ...filter,
      },
    });
  }

  getOptionsManuallyHandledRewardFilters() {
    return this.httpService.get({
      url: '/IncentiveService/GetOptionsManuallyHandledRewardFilters',
    }).then((response) =>
      response.data,
    );
  }

  getSquarePeriodicRewards() {
    return this.httpService.get({
      url: '/IncentiveService/GetSquarePeriodicRewardsList',
    }).then((response) =>
      response.data,
    );
  }

  getWinnersInstant(activityGuid: any) {
    return this.httpService.get({
      url: `/IncentiveService/GetWinnersInstant?Guid=${activityGuid}`,
    }).then((response) =>
      response.data,
    );
  }

  getRewardsInstant(activityGuid: any) {
    return this.httpService.get({
      url: `/IncentiveService/GetRewardsInstant?Guid=${activityGuid}`,
    }).then((response) =>
      response.data,
    );
  }
  getManualRewards(raffleGuid: any) {
    return this.httpService.get({
      url: '/IncentiveService/GetManualRewards',
      params: {
        raffleGuid,
      },
    }).then((response) =>
      response.data,
    );
  }
  isRewardPeriodValid(period: { startDate: any; endDate: any; raffleGuid: any; }) {
    return this.httpService.get({
      url: '/IncentiveService/IsRewardPeriodValid',
      params: {
        StartDate: period.startDate,
        EndDate: period.endDate,
        RaffleGuid: period.raffleGuid,
      },
    }).then((response) =>
      response.data,
    );
  }

  getRewardsPeriodic(raffleGuid: any) {
    return this.httpService.get({
      url: '/IncentiveService/GetRewardsPeriodic',
      params: {
        raffleGuid,
      },
    }).then((response) =>
      response.data,
    );
  }

  getRewardsSavingUp() {
    return this.httpService.get({
      url: '/IncentiveService/GetRewardsSavingUp',
    }).then((response) =>
      response.data,
    );
  }

  getManualRaffles() {
    return this.httpService.get({
      url: '/IncentiveService/GetManualRaffles',
    }).then((response) =>
      response.data,
    );
  }

  getWinnersPeriodic(raffleGuid: any) {
    return this.httpService.get({
      url: '/IncentiveService/GetWinnersPeriodic',
      params: {
        raffleGuid,
      },
    }).then((response) =>
      response.data,
    );
  }

  getProfileIncentiveDetails() {
    return this.httpService.get({
      url: '/IncentiveService/ProfileIncentive',
      params: {
        squareParticipantGuid: this.$stateParams.memberGuid,
      },
    }).then((response) => response.data);
  }

  downloadManualRewardTemplate() {
    return this.httpService.post({
      url: '/ExportService/DownloadManualRewardTemplate',
      responseType: 'arraybuffer',
    }).then((response) => response);
  }

  handleManualRewardWinner(request: string[]) {
    return this.httpService.post({
      url: '/IncentiveService/HandleManualRewardWinner',
      data: {
        Guids: request,
      },
    }).then((response) => response);
  }

  validateExcelFile(file: any) {
    return this.httpService.upload({
      url: '/IncentiveService/ValidateExcelFile',
      data: {
        file,
      },
    });
  }

  processUploadedExcelFile(blob: string, name: string, approvalType: number) {
    return this.httpService.post<string>({
      url: '/IncentiveService/ProcessUploadedExcelFile',
      data: {
        Blob: blob,
        Name: name,
        ApprovalType: approvalType,
      },
    }).then((response) => response.data);
  }

  getSavingUpRedeemOverview(squareParticipantGuid?: string) {
    return this._getSavingUpRedeemOverview(squareParticipantGuid);
  }

  private _getSavingUpRedeemOverview = _.memoize((squareParticipantGuid) => this.httpService.get({
    url: '/IncentiveService/GetSavingUpRedeemOverview',
    params: {
      squareParticipantGuid: squareParticipantGuid || this.$stateParams.memberGuid,
    },
  }).then((response) => response.data));

  getSavingUpRewardRedemptionList(squareParticipantGuid?: string) {
    return this._getSavingUpRewardRedemptionList(squareParticipantGuid);
  }

  private _getSavingUpRewardRedemptionList = _.memoize((squareParticipantGuid) => this.httpService.get({
    url: '/IncentiveService/GetSavingUpRewardRedemptionList',
    params: {
      squareParticipantGuid: squareParticipantGuid || this.$stateParams.memberGuid,
    },
  }).then((response) => response.data));

  getSavingUpRewardItemList(squareParticipantGuid?: string) {
    return this._getSavingUpRewardItemList(squareParticipantGuid);
  }

  private _getSavingUpRewardItemList = _.memoize((squareParticipantGuid) => this.httpService.get({
    url: '/IncentiveService/GetSavingUpRewardItemList',
    params: {
      squareParticipantGuid: squareParticipantGuid || this.$stateParams.memberGuid,
    },
  }).then((response) => response.data));

  getSavingUpInvalidationList(squareParticipantGuid?: string) {
    return this.httpService.get({
      url: '/IncentiveService/GetSavingUpInvalidationList',
      params: {
        squareParticipantGuid: squareParticipantGuid || this.$stateParams.memberGuid,
      },
    }).then((response) => response.data);
  }

  isParticipantAlmostInactive() {
    return this.httpService.get({
      url: '/ParticipantService/SquareParticipantDetails',
      params: {
        squareParticipantGuid: this.$stateParams.memberGuid,
      },
    }).then((response) => response.data.Detail.IsAlmostInactiveInActivities);
  }

  getManualRewardsEarnedList(squareParticipantGuid?: string) {
    return this._getManualRewardsEarnedList(squareParticipantGuid);
  }

  private _getManualRewardsEarnedList = _.memoize((squareParticipantGuid) => this.httpService.get({
    url: '/IncentiveService/GetManualRewardsEarnedList',
    params: {
      squareParticipantGuid: squareParticipantGuid || this.$stateParams.memberGuid,
    },
  }).then((response) => response.data));

  canSeeRewardLinks(squareParticipantGuid?: string) {
    return this._canSeeRewardLinks(squareParticipantGuid);
  }

  private _canSeeRewardLinks = _.memoize((squareParticipantGuid) => this.httpService.get({
    url: '/IncentiveService/CanSeeRewardLinks',
    params: {
      fromSquareParticipantGuid: squareParticipantGuid,
    },
  }).then((response) => response.data));
}
