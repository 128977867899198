'use strict';

import { DataImportController } from './data-import.controller';
const template = require('./data-import.html');

export const DataImportComponent: ng.IComponentOptions = {
  controller: DataImportController,
  controllerAs: 'cvm',
  templateUrl: template,
  transclude: {
    uploadButton: 'uploadButton',
  },
  bindings: {
    pullRight: '<?',
    activityGuid: '<',
  },
};
