'use strict';

import { ForumRoomController } from './squareForumRoom.controller';

const forumRoomTemplate = require('./squareforumroom.html');

export const ForumRoomComponent: ng.IComponentOptions = {
  controller: ForumRoomController,
  templateUrl: forumRoomTemplate,
  controllerAs: 'vm',
};
