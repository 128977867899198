'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';
import { FeatureService } from '../../../core/dataservices/feature.service';
import { SelectedSquareFactory } from '../../../core/selectedsquare.factory';
import { ServerConstants } from '../../../core/serverconstants';

const routeConfig = async (
  routerHelper: RouterHelper,
  featureService: FeatureService,
  serverConstants: ServerConstants,
  $rootScope: ng.IRootScopeService,
  selectedSquareFactory: SelectedSquareFactory,
) => {
  // Create state and register it.
  const state = {
    state: 'root.square.manualrewards',
    config: {
      url: '/manualrewards',
      title: 'Incentives',
      component: 'manualRewards',
      settings: {
        nav: 8,
        childnav: 4,
        label: 'Manual Rewards',
      },
      disallowRoles: ['Observer'], // Manual Rewards should be visible without saving up
    },
  };
  routerHelper.configureStates([state]);

  // Adapt the label of the state depending on whether saving up is enabled or not.
  const setSettingsLabel = (isEnabled: boolean) => {
    state.config.settings.label = isEnabled ? 'Manual Point Corrections' : 'Manual Rewards';
  };

  // Make sure to adapt the label when saving up gets enabled or disabled
  $rootScope.$on('savingUpEnabledChanged', (_event, isEnabled: boolean) => {
    setSettingsLabel(isEnabled);
  });

  selectedSquareFactory.squareInfoObservable.subscribe(async (squareInfo) => {
    if (squareInfo.ClientGuid) {
      const isEnabled = await featureService.isFeatureEnabledForSquare(serverConstants.featureConstants.savingUpRewards);
      setSettingsLabel(isEnabled);
    }
  });
};

routeConfig.$inject = ['routerHelper', 'featureservice', 'serverConstants', '$rootScope', 'selectedSquareFactory'];

export default routeConfig;
