'use strict';

import { ClipboardService } from '../../core/services/clipboard.service';

export class CopyLinkButtonController {
  static $inject = ['ClipboardService'];

  textToCopy: string;
  tooltipText: string = 'Copy Activity URL';

  constructor(
    protected clipboardService: ClipboardService,
  ) { }

  async copyLinkToClipboard() {
    this.tooltipText = 'Activity URL copied';
    this.clipboardService.copyToClipboard(this.textToCopy);
    setTimeout(() => {
      this.tooltipText = 'Copy Activity URL';
    }, 100);
  }
}
