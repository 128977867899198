'use strict';

import { FiveStarRatingController } from './fiveStarRating.controller';
const template = require('./fiveStarRating.html');

require('./fiveStarRating.scss');

angular.module('insitesApp.activityData').component('iscFiveStarRating', {
  controller: FiveStarRatingController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    rating: '<',
    id: '<',
    setRating: '&',
    disabled: '<?',
  },
});
