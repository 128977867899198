'use strict';

const publishBackgroundVarsTemplate = require('./publishBackgroundVars.html');

angular
  .module('insitesApp.activityconfig')
  .component('publishBackgroundVars', {
    templateUrl: publishBackgroundVarsTemplate,
    controllerAs: 'vm',
    bindings: {
      segmentations: '<',
    },
  });
