'use strict';

import * as angular from 'angular';
import './steps';
import routeConfig from './communicationWizard.route';
import { communicationWizard } from './communicationWizard.component';

angular
  .module('insitesApp.squarecommunicationWizard')
  .component('communicationWizard', communicationWizard)
  .run(routeConfig);
