'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { ServerConstants } from 'src/app/core/serverconstants';
import { SelectedClientFactory } from 'src/app/core/selectedclient.factory';
import { SelectedSquareFactory } from 'src/app/core/selectedsquare.factory';
import { SelectedActivityFactory } from 'src/app/core/selectedactivity.factory';
import { TransitionService } from '@uirouter/angularjs';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { ClientService } from 'src/app/core/dataservices/client.service';

const routeConfig = (
  routerHelper: RouterHelper,
  serverConstants: ServerConstants,
  authService: AuthService,
  $transitions: TransitionService,
  featureService: FeatureService,
  clientService: ClientService,
  selectedClientFactory: SelectedClientFactory,
  selectedSquareFactory: SelectedSquareFactory,
  selectedActivityFactory: SelectedActivityFactory,
  __env: Environment,
) => {
  routerHelper.configureStates([
    {
      state: 'root.square.gallery',
      config: {
        url: '/activity/:activityGuid/Gallery',
        title: 'Gallery-Media',
        settings: {
          nav: 3,
          section: 'ACTIVITY MANAGEMENT',
          svgIcon: 'images/MediaGallery.svg#gallery_button|0 0 48 47',
          label: 'Gallery',
          show: async () =>
            await hasAccessToMediaGallery(featureService, serverConstants,
              clientService, selectedClientFactory, selectedSquareFactory, selectedActivityFactory),
        },
      },
    },
  ]);

  $transitions.onBefore({ to: 'root.square.gallery' }, (transition) => {
    const { activityGuid, squareGuid, clientGuid, programGuid } = transition.params();
    const url = new URL(__env.mediaGalleryUrl);
    url.searchParams.set('clientGuid', clientGuid);
    url.searchParams.set('programGuid', programGuid);
    url.searchParams.set('platformGuid', squareGuid);
    url.searchParams.set('activityGuid', activityGuid);
    url.searchParams.set('token', authService.getToken());
    window.open(url);
    return false;
  });
};

const hasAccessToMediaGallery = async (
  featureservice: FeatureService,
  serverConstants: ServerConstants,
  clientService: ClientService,
  selectedClientFactory: SelectedClientFactory,
  selectedSquareFactory: SelectedSquareFactory,
  selectedActivityFactory: SelectedActivityFactory,
) => {
  let waitIteration = 0;
  const waitForInitialization = async () => {
    if (selectedActivityFactory.Name) {
      return true;
    }
    waitIteration++;
    if (waitIteration === 10) {
      return false;
    }
    // The activity is not initialized, wait for a moment and check again
    await new Promise((resolve) => setTimeout(resolve, 500));
    return waitForInitialization();
  };

  // Wait for selectedSquareFactory to have an activity set
  await waitForInitialization();

  if (waitIteration === 10) {
    return false;
  }

  if (!selectedActivityFactory.HasMedia) {
    return false;
  }

  const masterFeature = await featureservice.getMasterFeature(serverConstants.featureConstants.mediaGallery);

  if (!masterFeature || !masterFeature.isEnabled) {
    return false;
  }

  // at least for moderators,
  // the selected client factory contains null for all members
  let clientCode = selectedClientFactory.Code;
  if (!clientCode) {
    // if this happens, we retrieve current client code
    const clientInfo = await clientService.getClientDetails(
      selectedSquareFactory.clientGuid, false);
    clientCode = clientInfo?.Detail?.Code;
  }
  if (!clientCode) {
    return false;
  }

  const getFeatureSquare = () => masterFeature.specificSquares
    .find((x) => x.squareId === selectedSquareFactory.Id
      && x.clientCode === clientCode);

  if (masterFeature.isExcludedForSquaresFromAllSquares) {
    return !getFeatureSquare();
  }

  if (masterFeature.isEnabledForSpecificSquares) {
    return !!getFeatureSquare();
  }

  return true;
};

routeConfig.$inject = [
  'routerHelper',
  'serverConstants',
  'authService',
  '$transitions',
  'featureservice',
  'clientservice',
  'selectedClientFactory',
  'selectedSquareFactory',
  'selectedActivityFactory',
  '__env'];

angular
  .module('insitesApp.iscMediaGallery', [])
  .run(routeConfig);
