'use strict';

import './squareHealth.module';
import routeConfig from './squareHealth.route';
import { squareHealthComponent } from './squareHealth.component';
import { SquareHealthController } from './squareHealth.controller';
angular
  .module('insitesApp.squarehealth')
  .controller('SquareHealthController', SquareHealthController)
  .component('squareHealthComponent', squareHealthComponent)
  .run(routeConfig);
