'use strict';

export class Exception {
  static $inject = ['$q', 'logger'];
  constructor(
    private $q: ng.IQService,
    private logger: Logger) { }

  catcher = (message) => (e) => {
    let thrownDescription;
    let newMessage;
    if (e.data && e.data.description) {
      thrownDescription = `\n${e.data.description}`;
      newMessage = message + thrownDescription;
      e.data.description = newMessage;
    }
    this.logger.error(newMessage);
    return this.$q.reject(e);
  };
}
