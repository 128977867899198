'use strict';

import { DateTime } from 'luxon';
import { IncentiveService } from './../../../../core/dataservices/incentive.service';
import { DateFormatService } from './../../../../core/services/dateformat.service';
import { Utils } from '../../../../core/utils';
import { ClipboardService } from '../../../../core/services/clipboard.service';
import { ServerConstants } from '../../../../core/serverconstants';
import { SpinnerService } from '../../../../core/services/spinner.service';

export class NewPeriodicRewardsController {
  static $inject = ['$mdDialog', 'periodicRewardName', 'rewardservice', '$q', 'serverConstants',
    'edit', '_', 'raffleGuid', 'spinnerservice', 'dateFormatService', 'rewardsService', 'incentiveservice', '$stateParams', 'ClipboardService'];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private periodicRewardName,
    private rewardservice,
    private $q: ng.IQService,
    private serverConstants: ServerConstants,
    private editMode,
    private _: _.LoDashStatic,
    private raffleGuid,
    private spinnerservice: SpinnerService,
    private dateFormatService: DateFormatService,
    private rewardsService,
    private incentiveservice: IncentiveService,
    private $stateParams: ng.ui.IStateParamsService,
    private clipboardService: ClipboardService,
  ) {
    this.activate();
  }

  private modifiedWinners = [];
  private currentDate = DateTime.now();
  dataLoaded = false;
  hasEnded = false;
  isActive = false;
  anyWinnersNotApproved = false;
  isClosed = false;
  isSaving = false;
  isValid = false;
  validationConstants = this.serverConstants.validationConstants;
  validDate = true;
  formValidDate = false;
  periodicModel = {
    RaffleGuid: '',
    Name: this.periodicRewardName || '',
    StartDate: DateTime.now(),
    EndDate: null as DateTime,
    Offset: DateTime.local().offset / 60,
    ExecutionDate: null as DateTime,
    Rewards: [],
  };
  rewardPartners = [];
  minStartDate = this.dateFormatService.startOfDay(this.periodicModel.StartDate);
  minEndDate = this.periodicModel.StartDate;
  minRewardDate = this.periodicModel.ExecutionDate;
  squareGuid: string;
  periodicRewardForm: ng.IFormController;
  anyWinners: boolean;
  periodicRewardWinners: any[];
  winnerStatus = this.serverConstants.winnerStatusConstants;

  private activate() {
    this.squareGuid = this.$stateParams.squareGuid;
    if (this.editMode) {
      this.loadRaffleData();
    } else {
      this.dataLoaded = true;
    }
  }

  private isValidDate() {
    if (this.periodicModel.StartDate && this.periodicModel.EndDate && this.periodicModel.ExecutionDate) {
      const start = this.periodicModel.StartDate;
      const end = this.periodicModel.EndDate;
      const execution = this.periodicModel.ExecutionDate;
      if (end.diff(start).milliseconds < 0 || execution.diff(end).milliseconds < 0) {
        return false;
      }
      return true;
    }
  }

  checkForm() {
    if (this.dataLoaded) {
      if (this.periodicRewardForm && this.periodicRewardForm.$pristine) {
        return false;
      }
      if (Object.prototype.hasOwnProperty.call(this.periodicModel, 'Name') && !this._.isUndefined(this.periodicModel.Name)) {
        if (!this.periodicModel.Name.trim()) {
          return false;
        }
      }
      if (!this.periodicModel.ExecutionDate
        || !this.periodicModel.StartDate
        || !this.periodicModel.EndDate
        || !this.periodicModel.Name) {
        return false;
      }
      if (!this.isValidDate()) {
        return false;
      }
      if (this.periodicModel.Rewards.length < 1) {
        return false;
      }
      return this._.every(this.periodicModel.Rewards, (s) => (s.Amount && s.Value && s.CurrencyGuid));
    }
  }

  private loadRaffleData() {
    this.spinnerservice.show('loading');
    this.initRewardPartners();
    this.incentiveservice
      .getRewardsPeriodic(this.raffleGuid)
      .then((response) => {
        this.periodicModel = {
          Name: response.Name,
          RaffleGuid: this.raffleGuid,
          StartDate: this.dateFormatService.getDateTime(response.StartDate, response.Offset),
          EndDate: this.dateFormatService.getDateTime(response.EndDate, response.Offset),
          Offset: response.Offset,
          ExecutionDate: this.dateFormatService.getDateTime(response.RaffleExecutionDate, response.Offset),
          Rewards: [],
        };
        this.periodicModel.Rewards = this._.map(response.RewardsPeriodicDetailsList,
          (reward: any) => ({
            Amount: reward.Amount,
            CurrencyGuid: reward.CurrencyGuid,
            IsAutomaticApproved: reward.IsAutomaticApproved,
            Value: reward.Value,
            KeySegment: reward.KeySegment || 'All segments',
            KeySegmentation: reward.KeySegmentation || 'All segmentations',
            Guid: reward.RewardGuid,
            SegmentGuid: reward.KeySegmentGuid,
            SegmentationGuid: reward.KeySegmentGuid || 0,
            RaffleGuid: this.raffleGuid,
          }));
        this.setMinStartEndDates();
      })
      .then(() => this.getWinners())
      .finally(() => {
        this.spinnerservice.hide('loading');
      });
  }

  private setMinStartEndDates() {
    this.isActive = this.currentDate > this.periodicModel.StartDate && this.currentDate < this.periodicModel.EndDate;
    this.isClosed = this.currentDate > this.periodicModel.StartDate && this.currentDate > this.periodicModel.EndDate;
    const isScheduled = !(this.isActive || this.isClosed);
    if (this.isActive) {
      this.minEndDate = this.currentDate;
    } else if (isScheduled) {
      this.minStartDate = this.currentDate;
    }
    this.computeMinEndDate();
    this.computeRewardExecutionMinDate();
  }

  private initRewardPartners() {
    this.rewardPartners = this.rewardsService.getRewardPartners();
  }

  computeRewardExecutionMinDate() {
    this.minRewardDate = this.dateFormatService.startOfDay(this.currentDate.plus({ days: 1 }));
    if (this.periodicModel.EndDate instanceof DateTime) {
      if (this.minRewardDate < this.periodicModel.EndDate) {
        this.minRewardDate = this.dateFormatService.startOfDay(this.periodicModel.EndDate.plus({ days: 1 }));
      }
      if (this.periodicModel.EndDate >= this.periodicModel.ExecutionDate) {
        this.periodicModel.ExecutionDate = null;
      }
    }
  }

  computeMinEndDate() {
    this.minEndDate = this.currentDate;
    if (this.periodicModel.StartDate instanceof DateTime) {
      this.minEndDate = this.currentDate < this.periodicModel.StartDate ? this.periodicModel.StartDate : this.currentDate;
      if (this.periodicModel.StartDate > this.periodicModel.EndDate) {
        this.periodicModel.EndDate = null;
        this.periodicModel.ExecutionDate = null;
      }
    }
  }

  savePeriodicReward() {
    this.isSaving = true;
    // Applying endofDay is necesary because 16 Jan 00:00:00 - utc 2 hours will become 15 Jan 22:00:00 which is not ok
    // By endofDay the date becomes 16 Jan 23:59:59 - utc 2 hours will become 16 Jan 21:59:59 which is ok
    this.periodicModel.EndDate = this.dateFormatService.endOfDay(this.periodicModel.EndDate);
    this.periodicModel.ExecutionDate = this.dateFormatService.endOfDay(this.periodicModel.ExecutionDate);
    const data = {
      Name: this.periodicModel.Name,
      RaffleGuid: this.raffleGuid,
      StartDate: this.dateFormatService.getDateTimeForSaving(this.periodicModel.StartDate, this.periodicModel.Offset),
      EndDate: this.dateFormatService.getDateTimeForSaving(this.periodicModel.EndDate, this.periodicModel.Offset),
      Offset: this.periodicModel.Offset,
      ExecutionDate: this.dateFormatService.getDateTimeForSaving(this.periodicModel.ExecutionDate, this.periodicModel.Offset),
      Rewards: this.periodicModel.Rewards,
    };
    const period = {
      startDate: data.StartDate,
      endDate: data.EndDate,
      raffleGuid: this.raffleGuid,
    };

    this.incentiveservice.isRewardPeriodValid(period).then((result) => {
      if (result) {
        this.validDate = true;
        this.spinnerservice.show('loading');
        let savePromise;
        if (this.editMode) {
          savePromise = this.updatePeriodicRaffle(data);
        } else {
          savePromise = this.createPeriodicRaffle(data);
        }
        savePromise.then(() => {
          this.incentiveservice.invalidateCache();
          this.isSaving = false;
          this.$mdDialog.hide();
        }).finally(() => {
          this.spinnerservice.hide('loading');
        });
      } else {
        this.isSaving = false;
        this.validDate = false;
      }
    });
  }

  approve(winner) {
    this.markWinnerAsModified(winner);
    winner.RewardStatus = 'Pending Approval';
    winner.IsApprovalPending = true;
  }

  onRewardPartnerChanged(winner, value) {
    winner.RewardPartner = value;
    this.markWinnerAsModified(winner);
  }

  private markWinnerAsModified(winner) {
    if (!this._.includes(this.modifiedWinners, winner)) {
      this.modifiedWinners.push(winner);
    }
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  private createPeriodicRaffle(data) {
    return this.rewardservice.createPeriodicRaffle(data);
  }

  private updatePeriodicRaffle(data) {
    let updatePromises = [];
    const updateWinnersPromise = this.updateWinners();
    const updateRafflePromise = this.rewardservice.updatePeriodicRaffle(data);
    updatePromises = [updateRafflePromise, updateWinnersPromise];
    return this.$q.all(updatePromises);
  }

  private updateWinners() {
    const updateWinnerPromises = this._.map(this.modifiedWinners, (winner) => this.rewardservice.updateWinner({
      WinnerGuid: winner.WinnerGuid,
      IsApproved: winner.IsApprovalPending,
      RewardPartner: winner.RewardPartner,
    }).then(() => {
      winner.IsApproved = winner.IsApprovalPending;
      winner.IsCompleted = winner.IsApproved && winner.RewardPartner;
    }));
    return this.$q.all(updateWinnerPromises).then(() => {
      this.incentiveservice.invalidateCache();
      this.modifiedWinners = [];
    });
  }

  private getWinners() {
    return this.incentiveservice.getWinnersPeriodic(this.raffleGuid).then((response) => {
      this.anyWinners = response.WinnersDetailsList.length >= 1;
      this.periodicRewardWinners = this._.map(response.WinnersDetailsList, (winner: any) => ({
        Id: winner.SquareParticipantId,
        Username: winner.Username,
        Email: winner.Email,
        RewardValue: winner.RewardValue,
        CurrencyName: winner.CurrencyName,
        RewardPartner: winner.RewardPartner,
        Status: winner.Status,
        WinnerGuid: winner.WinnerGuid,
        RedeemedRewardResponse: winner.RedeemedRewardResponse,
      }));

      this._.forEach(this.periodicRewardWinners,
        (winner) => {
          winner.IsApproved = (winner.Status !== 'Not approved');
          if (!winner.IsApproved) {
            this.anyWinnersNotApproved = true;
          }
          winner.RewardStatus = winner.IsApproved ? 'Approved' : 'Approve';
          if (winner.Segment === null || winner.Segment === undefined) {
            winner.Segment = 'All';
          }
          winner.IsCompleted = winner.IsApproved && winner.RewardPartner;
          winner.IsRewardPartnerDisabled = winner.RewardPartner === this.serverConstants.rewardPartnerTypeConstants.other;
        });

      this.dataLoaded = true;
      this.hasEnded = DateTime.now() > this.periodicModel.ExecutionDate;
    });
  }

  copyToClipboard(url) {
    this.clipboardService.copyToClipboard(url);
  }

  showCopyToClipboard(reward) {
    if (reward.RewardPartner === this.serverConstants.rewardPartnerTypeConstants.cadora
      && reward.Status === Utils.getEnumValueName(this.winnerStatus, this.winnerStatus.offeredToRedeem)) {
      return true;
    }
    return false;

  }
}
