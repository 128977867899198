'use strict';

import { ForumConversationController } from './conversation.controller';

const template = require('./conversation.html');

export const ForumConversationComponent: ng.IComponentOptions = {
  controller: ForumConversationController,
  templateUrl: template,
  controllerAs: 'vm',
};
