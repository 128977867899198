'use strict';

import { DateTime } from 'luxon';
import { ActivitySpecifyControllerBase } from '../../../../activity/common/ActivitySpecifyControllerBase';

export abstract class ActivityQualSpecifyControllerBase extends ActivitySpecifyControllerBase {
  abstract model: {
    Context: number;
    Visibility: number;
    StartTime: DateTime;
    EndTime: DateTime;
    DateOffset: number;
    IsCompletionTarget: boolean;
    CompletionTarget: number;
    CompletionPoints: Record<string, number>;
  }

  Context = [{
    Label: 'Group',
    Value: this.serverConstants.activityContextTypeConstants.public,
  },
  {
    Label: 'Private',
    Value: this.serverConstants.activityContextTypeConstants.private,
  },
  ];

  Visibility = [{
    Label: 'Influenced',
    Value: this.serverConstants.activityVisibilityTypeConstants.influenced,
  },
  {
    Label: 'Uninfluenced',
    Value: this.serverConstants.activityVisibilityTypeConstants.uninfluenced,
  },
  ];
}
