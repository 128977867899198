'use strict';

import { IServiceProvider, ILocationProvider } from 'angular';
import { RouterHelper } from './router-helper';
import { UrlRouterProvider } from '@uirouter/angularjs';

export class RouterHelperProvider implements IServiceProvider {
  static $inject = ['$urlRouterProvider', '$stateProvider', '$locationProvider'];
  constructor(
    private $urlRouterProvider: UrlRouterProvider,
    private $stateProvider: ng.ui.IStateProvider,
    $locationProvider: ILocationProvider,
  ) {

    if (!(window.history && window.history.pushState)) {
      window.location.hash = '/';
    }

    $locationProvider.html5Mode({ enabled: true, requireBase: true, rewriteLinks: true }).hashPrefix('!');
  }

  config = {
    docTitle: undefined,
    resolveAlways: {},
  };

  configure = (cfg) => angular.extend(this.config, cfg);

  $get = RouterHelper.factory(this.config, this.$urlRouterProvider, this.$stateProvider);
}
