'use strict';

import './../activityProbeQualBuild/probe-question/isc-probe-question';
import './../activityProbeQualBuild/probe-question/isc-probe-question-overview';
import './../../activityQualOpeningPost';

import { ActivityScoutBuildComponent } from './activityScoutBuild.component';

angular
  .module('insitesApp.activityQualResearchConfig')
  .component('activityScoutBuild', ActivityScoutBuildComponent);
