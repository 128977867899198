'use strict';

import { HttpService } from './http.service';

export class GeofencingService {
  static $inject = ['httpservice'];

  constructor(
    private httpService: HttpService,
  ) { }

  async downloadGeofencingLocationsImportTemplate() {
    return this.httpService.post({
      url: '/ExportService/DownloadGeofencingLocationsImportTemplate',
      responseType: 'arraybuffer',
    });
  }

  async importGeofencingLocations(blobName: string, role: number) {
    return this.httpService.post<string>({
      url: '/GeofencingService/ImportGeofencingLocations',
      data: {
        blobName,
        role,
      },
    }).then((response) => response.data);
  }

  async exportGeofencingLocations() {
    return this.httpService.post({
      url: '/ExportService/ExportGeofencingLocations',
    });
  }

  async validateExcelFile(file) {
    return this.httpService.upload({
      url: '/GeofencingService/ValidateExcelFile',
      data: {
        file,
      },
    });
  }

  async setDefaultGeofencingNotificationText(notificationText, language) {
    return this.httpService.post({
      url: '/GeofencingService/SetDefaultGeofencingNotificationText',
      data: {
        notificationText,
        language,
      },
    });
  }

  async getDefaultGeofencingNotificationText(language) {
    return this.httpService.get({
      url: '/GeofencingService/GetDefaultGeofencingNotificationText',
      params: {
        language,
      },
    }).then((response) => response.data);
  }

  async getGeofencingStatistics() {
    return this.httpService.get({
      url: '/GeofencingService/GetGeofencingStats',
    }).then((response) => response.data);
  }
}
