'use strict';

import { ForumService } from '../dataservices/forum.service';
import { ServerConstants } from '../serverconstants';
import { SelectedSquareFactory } from '../selectedsquare.factory';
import * as _ from 'lodash';

export class SelectedSquareForumsService {
  public static $inject = ['forumservice', '$rootScope', 'serverConstants',
    'selectedSquareFactory', 'labelFactory'];

  operationTypeConstants = this.serverConstants.operationTypeConstants;
  lastUpdate = null;
  lastSquareGuid: string = null;
  forums = [];

  constructor(
    private forumservice: ForumService,
    private $rootScope: ng.IRootScopeService,
    private serverConstants: ServerConstants,
    private selectedSquareFactory: SelectedSquareFactory,
    private labelFactory,
  ) { }

  getRoom(roomGuid: string) {
    let requestedRoom;
    _.each(this.forums, (forum) => {
      _.each(forum.Rooms, (room: any) => {
        if (room.RoomGuid === roomGuid) {
          requestedRoom = room;
        }
      });
    });
    return requestedRoom;
  }

  getForum(forumGuid: string) {
    let requestedForum;
    _.each(this.forums, (forum) => {
      if (forum.PageGuid === forumGuid) {
        requestedForum = forum;
      }
    });
    return requestedForum;
  }

  update(squareGuid: string, force: boolean = false) {
    let result;
    if (squareGuid === this.lastSquareGuid && !force) {
      result = this.lastUpdate;
    } else {
      this.lastSquareGuid = squareGuid;
      result = this.forumservice.listForumRooms(squareGuid).then((response) => {
        this.forums = response.data.Forums;
        // Emit an event so the sidenav knows that the forums have changed
        this.$rootScope.$emit('sidenavChanged');
      });
      this.lastUpdate = result;
    }
    return result;
  }

  createDefaultRoom() {
    return this.selectedSquareFactory.languagePromise.then((squareLanguage) => this.labelFactory.getLabelsLanguage(squareLanguage)).then((labels) => {
      const defaultRoomName = labels.getLabelValue('LabelForumRoomName');
      return this.createRoom(defaultRoomName, true);
    });
  }

  createRoom(name: string, isVisible: boolean) {
    return {
      Name: name,
      IsVisible: isVisible,
      OperationType: this.operationTypeConstants.create,
      PageGuid: '',
    };
  }
}
