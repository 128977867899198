'use strict';

import { Logger } from '../../blocks/logger/logger';
import { ActivityService } from '../../core/dataservices/activity.service';
import { SquareService } from '../../core/dataservices/square.service';
import { ServerConstants } from '../../core/serverconstants';

export class ArchiveButtonController {
  static $inject = ['logger', '_', 'activityservice', 'squareservice', 'serverConstants'];
  constructor(
    private logger: Logger,
    private _: _.LoDashStatic,
    private activityService: ActivityService,
    private squareService: SquareService,
    private serverConstants: ServerConstants,
  ) {
  }

  item;
  list;
  archiveType;
  isSaving = false;
  menuItem;

  isArchived(): boolean {
    /* The IsArchived property is not used for Squares anymore, so we check the status
    The IsArchived property is still used for activities */
    const entity = this.list.filter((e) => e.Guid === this.item.Guid)[0];
    if (this.archiveType === 'activity') {
      return entity.IsArchived;
    } else if (this.archiveType === 'square') {
      return entity.Status === this.serverConstants.squareStatusConstants.archived;
    }
  }

  async toggleArchive() {
    this.isSaving = true;
    if (this.archiveType === 'activity') {
      await this.toggleActivityArchive();
    } else if (this.archiveType === 'square') {
      await this.toggleSquareArchive();
    }
  }

  async toggleActivityArchive() {
    await this.activityService.toggleActivityArchive(this.item.Guid)
      .then((result) => {
        let act;
        const actList = this.list;
        result.forEach((activityGuid) => {
          act = actList.filter((al) => al.Guid === activityGuid)[0];
          act.IsArchived = !act.IsArchived;
          if (act.IsArchived) {
            act.StatusString = 'archived';
          } else {
            act.StatusString = 'closed';
          }
        });
        if (act.IsArchived) {
          this.logger.success('Activity has been archived');
        } else {
          this.logger.success('Activity has been unarchived');
        }
      }, (error) => {
        this.errorHandling(error);
      }).finally(() => {
        this.isSaving = false;
      });
  }

  async toggleSquareArchive() {
    await this.squareService.toggleSquareArchive(this.item.Guid)
      .then((newStatus: number) => {
        const sq = this.list.filter((s) => s.Guid === this.item.Guid)[0]; // Find the square in the existing list (which was not refreshed)
        sq.Status = newStatus; // Update the status of the square in the list
        if (sq.Status === this.serverConstants.squareStatusConstants.archived) {
          sq.StatusString = 'archived';
          this.logger.success('Square has been archived');
        } else {
          if (sq.Status === this.serverConstants.squareStatusConstants.closed) {
            sq.StatusString = 'closed';
          } else {
            sq.StatusString = 'active';
          }
          this.logger.success('Square has been unarchived');
        }
      }, (error) => {
        this.errorHandling(error);
      }).finally(() => {
        this.isSaving = false;
      });
  }

  errorHandling(error) {
    if (error.status === 400) {
      const data = error.data;
      let message = '';
      this._.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
        message += `${validationError.ErrorMessage} `;
      });
      if (message.length > 0) {
        this.logger.error(message);
      }
    }
  }
}
