'use strict';

import { FeatureService } from './../../../../../core/dataservices/feature.service';
import { IncentiveService } from '../../../../../core/dataservices/incentive.service';
import { Utils } from '../../../../../core/utils';
import { ServerConstants } from '../../../../../core/serverconstants';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import * as _ from 'lodash';

export class MemberDetailsIncentivesController {
  static $inject = ['incentiveservice', '$filter', 'serverConstants', 'spinnerservice', 'featureservice', '$state'];

  constructor(
    private incentiveservice: IncentiveService,
    private $filter: ng.IFilterService,
    private serverConstants: ServerConstants,
    private spinnerservice: SpinnerService,
    private featureService: FeatureService,
    private $state: ng.ui.IStateService,
  ) {
  }
  model;
  numberOfRewards;
  channelTypes;

  async $onInit() {
    try {
      this.spinnerservice.show('loading');

      // Saving Up here is enabled only if
      //        Is enabled in master and at square level
      const isSavingUpEnabled = await this.featureService.isFeatureEnabledForSquare(
        this.serverConstants.featureConstants.savingUpRewards);
      if (isSavingUpEnabled) {
        this.$state.go('root.square.members.details.savingupoverview');
      }
      this.channelTypes = this.serverConstants.channelTypeConstants;
      await this.loadIncentives();
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async loadIncentives() {
    const details = await this.incentiveservice.getProfileIncentiveDetails();
    this.model = details;

    _.forEach(this.model.Items, (item: any) => {
      if (item.RewardPartnerType && item.RewardWinnerStatus) {
        const rewardPartnerType: string = Utils.getEnumValueName(this.serverConstants.rewardPartnerTypeConstants, item.RewardPartnerType);
        const rewardWinnerStatus: string = Utils.getEnumValueName(this.serverConstants.winnerStatusConstants, item.RewardWinnerStatus);
        if (item.RewardPartnerType !== this.serverConstants.rewardPartnerTypeConstants.other) {
          item.IncentiveLabel = `(LabelRewardPartner${rewardPartnerType})`;
        }
        item.RewardWinnerStatusLabel = `(LabelRewardWinnerStatus${rewardWinnerStatus})`;
      }
    });

    if (this.model.CurrentRaffleStartDate) {
      this.model.Items.push({
        DateTime: this.model.CurrentRaffleStartDate,
        ChannelType: this.serverConstants.channelTypeConstants.incentiveCard,
      });
    }

    this.model.Items = this.$filter('orderBy')(this.model.Items, 'DateTime', true);
    this.numberOfRewards = this.getNumberOfRewards();
    this.findOutdatedCards(this.model.Items, this.model.CurrentRaffleStartDate);
  }

  getNumberOfRewards() {
    let rewards = 0;

    angular.forEach(this.model.Items, (value) => {
      if (value.Currency) {
        rewards++;
      }
    });

    return rewards;
  }

  findOutdatedCards(items, refDate) {
    angular.forEach(items, (value) => {
      if (!refDate || value.DateTime < refDate) {
        value.isOutdated = true;
      }
    });
  }
}
