'use strict';

import * as _ from 'lodash';

const anyWindow: any = window || {};

angular
  .module('insitesApp.core')
  .constant('_', _)
  .constant('amp', anyWindow.amp)
  .constant('passwordRules', [
    '8 characters minimum',
    'One lowercase character',
    'One uppercase character',
    'One number',
    'One special character',
  ])
  .constant('emojione', anyWindow.emojione)
  .constant('constants', {
    suspendedStatus: 8,
    lockedStatus: 9,
  } as IConstants);
export interface IConstants {
  readonly suspendedStatus: number;
  readonly lockedStatus: number;
}
