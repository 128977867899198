'use strict';

import * as highlightShare from 'highlight-share/dist/highlight-share.js';
// When debugging something then use: import * as highlightShare from 'highlight-share/src/core.js';
import { Copy } from './copy';
import { Highlight } from './highlight';
import { ForumService } from '../../../core/dataservices/forum.service';
import { GoogleTranslateHelperService } from 'src/app/layout/google-translate-helper/google-translate-helper.service';
require('highlight-share/style/scss/highlight-share.scss');
require('./snippet-highlight.scss');

export class SnippetHighlighterService {
  static $inject = ['_', 'forumservice', 'googleTranslateHelper'];
  constructor(
    private _: _.LoDashStatic,
    private forumservice: ForumService,
    private googleTranslateHelper: GoogleTranslateHelperService) { }
  private _selectionShare;
  private _destroy = this._.noop;
  private config: {
    isOnHighlightedElement: boolean,
    highlightedElementsCommonIdentifier?: string,
    highlightingElement?: any
  } = { isOnHighlightedElement: false };
  init() {
    if (!this._selectionShare) {
      // When debugging something then use: this._selectionShare = highlightShare.default({
      this._selectionShare = highlightShare({
        selector: '.snippet-highlightable',
        sharers: [
          new Highlight(this.forumservice, this.googleTranslateHelper, this.config).toSharer(),
          new Copy(this.forumservice, this.config).toSharer()],
        onOpen: (popover, rawText, text, element) => {
          this.handleHighlightedElementSelectedCase(element, popover);
          this.config.highlightingElement = this.getRootNodeOfElement(element, 'snippet-highlightable');
        },
        onClose: () => {
          this.config.highlightedElementsCommonIdentifier = null;
          this.config.isOnHighlightedElement = false;
          this.config.highlightingElement = undefined;
        },
        isOnSelectedElement: (event) => this.isOnSelectedElement(event),
      });
    }

    setTimeout(() => {
      this._selectionShare.init();
      this._destroy = () => this._selectionShare.destroy();
    }, 100);
  }

  destroy() {
    this._destroy();
    this._destroy = this._.noop;
    this._selectionShare = undefined;
  }

  private handleHighlightedElementSelectedCase(element, popover = null) {
    // If highlighted element selected then we have to let highlighter know that it have to unhighlight on click
    // And style the highlight button accordingly
    const rootHighlightedNode = this.getRootNodeOfElement(element, 'snippet-highlighted');
    if (rootHighlightedNode && !document.getSelection().toString()) {
      this.config.highlightedElementsCommonIdentifier = this._.findLast(rootHighlightedNode.classList, (item) => !!item.toString().match(/^s\d+$/));
      this.config.isOnHighlightedElement = true;
      if (popover) {
        const highlightMenuItem = angular.element(popover.querySelectorAll('li[data-share-via="highlight"] a')[0]);
        highlightMenuItem.addClass('unhighlight');
        highlightMenuItem.removeClass('highlight');
      }
    } else {
      this.config.highlightedElementsCommonIdentifier = null;
      this.config.isOnHighlightedElement = false;
    }
  }

  private isOnSelectedElement(event): boolean {
    if (document.getSelection().toString()) {
      return false;
    }

    return !!this.getRootNodeOfElement(event.target, 'snippet-highlighted');
  }

  private getRootNodeOfElement(element, rootNodeCssClassName: string, currentIteration = 0) {
    const maxIterations = 8;
    if (angular.element(element).hasClass(rootNodeCssClassName)) {
      return element;
    }

    if (currentIteration < maxIterations && element.parentNode) {
      return this.getRootNodeOfElement(element.parentNode, rootNodeCssClassName, currentIteration++);
    }
    return null;
  }
}
