'use strict';

import { ForumService } from '../dataservices/forum.service';
import { CommunicationService } from '../dataservices/communication.service';
import { ConstantsFactory } from '../constants.factory';
import { ConfigurationService } from '../dataservices/configuration.service';
import _ = require('lodash');

const extendIscLabelService = ($delegate, constantsfactory: ConstantsFactory) => {
  const labelsPromise = constantsfactory
    .Labels()
    .then((labels) => {
      const returnLabels = {};
      // Don`t use foreach, IE doesn`t like it apparently?!?!?!?!
      _(labels.getLabels())
        .each((label) => {
          returnLabels[label.Name] = label.Value;
        });
      return returnLabels;
    });
  $delegate.getLabels = () => labelsPromise;
  return $delegate;
};
extendIscLabelService.$inject = ['$delegate', 'constantsfactory'];
const extendIscStimuliService = ($delegate, forumService: ForumService, communicationservice: CommunicationService) => {
  $delegate.getStimuliStreamingUrl = forumService.createConversationStimuliStreamingUrl.bind(forumService);
  $delegate.downloadStimuli = forumService.downloadStimuli.bind(forumService);
  $delegate.uploadEditorImage = communicationservice.uploadEditorImage.bind(communicationservice);
  $delegate.getVimeoThumbnailImagePath = forumService.getVimeoThumbnailImagePath.bind(forumService);
  return $delegate;
};
extendIscStimuliService.$inject = ['$delegate', 'forumservice', 'communicationservice'];

const extendIscSpinnerService = ($delegate, spinnerservice) => {
  $delegate.show = spinnerservice.show.bind(spinnerservice);
  $delegate.hide = spinnerservice.hide.bind(spinnerservice);
  return $delegate;
};
extendIscSpinnerService.$inject = ['$delegate', 'spinnerservice'];

const implementIscStimuliTypeService = ($delegate, serverConstants) => {
  $delegate.getTypes = () => serverConstants.conversationStimuliTypeConstants;
  return $delegate;
};

implementIscStimuliTypeService.$inject = ['$delegate', 'serverConstants'];
const extendIscEnvInfoService = ($delegate, __env, selectedSquareFactory) => {
  $delegate.getSquareBaseUrlWithSubdomain = () => selectedSquareFactory.SquareCompleteUrl;

  return $delegate;
};
extendIscEnvInfoService.$inject = ['$delegate', '__env', 'selectedSquareFactory'];

const extendIscConfigurationService = ($delegate, configurationService: ConfigurationService) => {
  $delegate.getFileConfiguration = configurationService.getFileConfiguration.bind(configurationService);
  $delegate.getFileTypes = configurationService.getFileTypes.bind(configurationService);
  $delegate.getAcceptedMimeTypesAndExtensions = configurationService.getAcceptedMimeTypesAndExtensions.bind(configurationService);
  return $delegate;
};
extendIscConfigurationService.$inject = ['$delegate', 'configurationService'];


const configureServiceDecorators = ($provide: ng.auto.IProvideService) => {
  $provide.decorator('iscLabelService', extendIscLabelService);
  $provide.decorator('iscStimuliTypeService', implementIscStimuliTypeService);
  $provide.decorator('iscStimuliService', extendIscStimuliService);
  $provide.decorator('iscSpinnerService', extendIscSpinnerService);
  $provide.decorator('iscEnvInfoService', extendIscEnvInfoService);
  $provide.decorator('iscConfigurationService', extendIscConfigurationService);
};

configureServiceDecorators.$inject = ['$provide'];

export default configureServiceDecorators;
