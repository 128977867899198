'use strict';

import { DeveloperMenuController } from './developer-menu.controller';

const template = require('./developer-menu.html');

export const DeveloperMenuComponent: ng.IComponentOptions = {
  controller: DeveloperMenuController,
  templateUrl: template,
  controllerAs: 'vm',
};
