'use strict';

const template = require('./delete-button.html');
import { DeleteButtonController } from './delete-button.controller';

export const DeleteButtonComponent: ng.IComponentOptions = {
  controller: DeleteButtonController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    isDisabled: '<',
    menuItem: '<',
  },
};
