'use strict';

import { RewardHandlingController } from './rewardHandling.controller';
const template = require('./rewardHandling.html');

require('./_rewardHandling.scss');

export const RewardHandlingComponent: ng.IComponentOptions = {
  templateUrl: template,
  controller: RewardHandlingController,
  controllerAs: 'vm',
};
