'use strict';

import { EditQuestionDialogController } from './editQuestionDialog.controller';
import { EditCategoryDialogController } from './editCategoryDialog.controller';
import { FaqComponent } from './faq.component';

angular
  .module('insitesApp.squareconfig.helpCenter')
  .controller('EditQuestionDialogController', EditQuestionDialogController)
  .controller('EditCategoryDialogController', EditCategoryDialogController)
  .component('helpCenterFaq', FaqComponent);
