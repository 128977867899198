'use strict';

import { SegmentSelectorController } from './segmentSelector.controller';

const template = require('./segmentSelector.html');
require('./segmentSelector.scss');

export const SegmentSelectorComponent: ng.IComponentOptions = {
  controller: SegmentSelectorController,
  templateUrl: template,
  controllerAs: 'vm',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    squareGuid: '=',
    segmentationGuid: '=',
    formData: '=',
  },
};
