'use strict';

import { NavigationHelperService } from 'isc-ui/dist/services/navigation/navigationHelperService';

export class NavigationValidator implements ng.IDirective {
  static $inject = ['$mdDialog', '$state', '$timeout', '$transitions', '$q', 'navigationHelperService'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $state: ng.ui.IStateService,
    private $timeout: ng.ITimeoutService,
    private $transitions,
    private $q: ng.IQService,
    private navigationHelperService: NavigationHelperService) {
  }

  restrict = 'A';
  require = '^form';
  scope = {
    navigationValidator: '=',
    saveCallback: '&',
    navigationErrorMessage: '@?',
    customValidation: '&?',
    saveNotApplicable: '<?',
    customMessage: '@?',
  };

  link($scope, element, attributes: ng.IAttributes, form: ng.IFormController) {
    const transitionsOnStartUnbind = this.$transitions.onStart({ }, (transition) => {
      const canNavigate = !(form.$invalid && form.$dirty);
      const isDirty = form.$dirty;
      const customValidation = $scope.customValidation !== undefined ?
        () => $scope.customValidation({ toParams: transition.params('to'), fromParams: transition.params('from') }) :
        undefined;

      const transitionCallback = () => {
        window.onbeforeunload = null;
        transitionsOnStartUnbind();
        this.$state.go(transition.to().name, transition.params('to'));
      };

      return this.navigationHelperService.onNavigate(canNavigate, isDirty, form.$error, $scope.saveNotApplicable, $scope.customMessage,
        $scope.navigationErrorMessage, transitionCallback, $scope.saveCallback, customValidation, false, null, false);
    });

    $scope.$on('$destroy', () => {
      window.onbeforeunload = null;
      transitionsOnStartUnbind();
    });
  }

  static factory(): ng.IDirectiveFactory {
    const directive =
      ($mdDialog, $state, $timeout, $transitions, $q, navigationHelperService) =>
        new NavigationValidator($mdDialog, $state, $timeout, $transitions, $q, navigationHelperService);
    directive.$inject = ['$mdDialog', '$state', '$timeout', '$transitions', '$q', 'navigationHelperService'];
    return directive;
  }
}
