'use strict';


import { PageService } from '../dataservices/page.service';

export class FaqQuestionTitleValidator implements ng.IDirective {
  constructor(
    private $q: ng.IQService,
    private pageService: PageService,
    private logger: Logger) { }

  restrict = 'A';

  link(scope, element, attrs) {
    const inputElem = element.find('input');
    if (!inputElem) {
      this.logger.error('No child input element was found');
      return;
    }
    const ngModel = inputElem.controller('ngModel');
    if (!ngModel) {
      this.logger.error('Input element doesn\'t have a ngModel specified');
      return;
    }

    ngModel.$asyncValidators.uniqueFaqQuestionTitle = (modelValue, viewValue) => {
      let faqQuestionGuid;
      if (Object.prototype.hasOwnProperty.call(attrs,'faqquestionguid')) {
        faqQuestionGuid = attrs.faqquestionguid;
      }
      const faqQuestion = {
        Title: encodeURIComponent(viewValue),
        Guid: faqQuestionGuid,
      };

      return this.pageService.isFaqQuestionTitleUnique(faqQuestion).then((response) => {
        if (response.data === false) {
          return this.$q.reject();
        }
        return response.data;
      });
    };
  }
}
