'use strict';

const template = require('./flag-button.html');

export const FlagButtonComponent: ng.IComponentOptions = {
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    item: '=',
  },
};
