'use strict';

const template = require('./dataTable.html');

export const DataTableComponent: ng.IComponentOptions = {
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    head: '=',
    data: '=',
    tooltip: '<?',
  },
};
