'use strict';

import { ITargeting } from '../../../../core/contracts/targeting.contracts';

export class ActivityQualPublishModel {
  Context = '';
  Visibility = '';
  StartDateTime;
  EndDateTime;
  DateOffset: number | string = '';
  CallToActionText = '';
  Sticky = false;
  ShowOnHomepage = false;
  ShowOnPublicPage = false;
  Target: { Targeting: ITargeting };
  Files: Array<{
    file: File;
    type: string;
    videoId: number;
    name: string;
  }> = [];
  Title = '';
  Description = '';
  ConversationGuid = '';
  IsCompletionTarget = false;
  CompletionTarget = null;
  OpenPostUsername = '';
  CompletionPoints: Record<string, number>;
  constructor() {
  }
}
