'use strict';

import { HomeSidenavController } from './homeSidenav.controller';
const template = require('./homeSidenav.html');

export const HomeSidenavComponent: ng.IComponentOptions = {
  controller: HomeSidenavController,
  templateUrl: template,
  controllerAs: 'vm',
};
