'use strict';

import { ServerConstants } from './../../core/serverconstants';
import { SearchService } from '../../core/dataservices/search.service';
import { SelectedActivityFactory } from '../../core/selectedactivity.factory';
import * as _ from 'lodash';

export class ActivitySelectionController {
  static $inject = [
    'searchservice',
    '$stateParams',
    '$state',
    '$scope',
    'selectedActivityFactory',
    'pinnedItemsService',
    '$transitions',
    'serverConstants',
  ];
  constructor(
    private searchService: SearchService,
    private $stateParams,
    private $state,
    private $scope,
    private selectedActivityFactory: SelectedActivityFactory,
    private pinnedItemsService,
    private $transitions,
    private serverconstants: ServerConstants,
  ) {}
  selectedActivity = this.selectedActivityFactory;
  selectedActivityGuid: string;
  dropdownList = [];
  icon = 'fa fa-flag';

  async $onInit() {
    await this.pinnedItemsService.init();
    this.searchService.invalidate();
    const responseList = await this.searchService.getSearchSelectionForDropdownActivity(
      {
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      },
    );

    if (responseList) {
      this.dropdownList = _.map(responseList, (el: any) => {
        el.isSelected = el.Guid === this.$stateParams.activityGuid;
        el.IsPinned = () => this.pinnedItemsService.isPinned(el.Guid);
        return el;
      });
    }
    this.updateSelectedActivityFactory();

    // Used for updating the squares dropdown when updating a square name
    this.$scope.$watch(
      () => this.selectedActivityFactory.Name,
      (newValue) => {
        if (newValue) {
          const selectedFactory = this.selectedActivityFactory;
          _.each(this.dropdownList, (element: any) => {
            if (element.Guid === selectedFactory.Guid) {
              element.Name = newValue;
            }
          });
        }
      },
    );

    // Used for updating the squares dropdown when creating a new square
    this.$scope.$watch(
      () => this.selectedActivityFactory.Guid,
      (newValue) => {
        if (newValue) {
          const selectedFactory = this.selectedActivityFactory;
          const newItem = _.find(this.dropdownList, {
            Guid: selectedFactory.Guid,
          });
          if (newItem === undefined) {
            this.dropdownList.push({
              Name: selectedFactory.Name,
              Guid: selectedFactory.Guid,
              Type: this.serverconstants.searchTypeConstants.activity,
              HasMedia: selectedFactory.HasMedia,
            });
            this.updateSelectedActivityFactory();
          }
        }
      },
    );

    this.$transitions.onSuccess({}, (transition) => {
      this.selectedActivityGuid = transition.params('to').activityGuid;
      this.updateSelectedActivityFactory();
    });
  }

  updateSelectedActivityFactory() {
    _.each(this.dropdownList, (activity) => {
      if (activity.Guid === this.$stateParams.activityGuid) {
        this.selectedActivityFactory.setActivityInfo({
          Guid: activity.Guid,
          Name: activity.Name,
          Format: activity.Format,
          HasMedia: activity.HasMedia,
        });
        this.selectInList(activity.Guid);
      }
    });
  }

  selectActivity(activity) {
    if (activity === 'current') {
      this.$state.go('root.square.activity', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
        activityGuid: this.$stateParams.activityGuid,
      });
    } else {
      this.selectInList(activity.Guid);
      if (activity.Type === 3) {
        this.$state.go('root.square.activity', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid:
            activity.ProgramGuid || this.$stateParams.programGuid,
          squareGuid: activity.SquareGuid || this.$stateParams.squareGuid,
          activityGuid: activity.Guid,
          startDate: activity.StartDate ?? this.$stateParams.StartDate,
          endDate: activity.EndDate ?? this.$stateParams.EndDate,
        });
        this.selectedActivityFactory.setActivityInfo({
          Guid: activity.Guid,
          Name: activity.Name,
          Format: activity.Format,
          HasMedia: activity.HasMedia,
        });
      }
    }
  }

  selectInList(selectedGuid) {
    _.each(this.dropdownList, (element) => {
      if (element.isSelected) {
        element.isSelected = false;
      }
      if (element.Guid === selectedGuid) {
        element.isSelected = true;
      }
    });
  }
}
