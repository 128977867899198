'use strict';

import { ActivityEmailReminderController } from './activityEmailReminderChannel.controller';

const template = require('./activityEmailReminderChannel.html');

angular
  .module('insitesApp.squarecommunicationWizard')
  .component('activityEmailReminderChannel', {
    templateUrl: template,
    controller: ActivityEmailReminderController,
    controllerAs: 'vm',
    bindings: {
      description: '@',
      communication: '=',
      isReadOnly: '<',
    },
    require: {
      wizardStep: '^^iscWizardSubstep',
    },
  });
