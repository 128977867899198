'use strict';

require('./_consent-expiration-banner.scss');

export class ConsentExpirationBannerController {
  extendingSourceText = 'TO PLATFORM SUPPORT TO SOLVE THE PROBLEM.';
  activityName: string;
  activityGuid: string;

  static $inject = ['$state', '$stateParams'];
  constructor(
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
  ) {
  }

  async $onInit() {
    if (this.activityGuid) {
      this.extendingSourceText = 'TO GO TO THE ACTIVITY CONFIGURATION';
    }
  }

  extendingSourceLinkClicked() {
    this.$state.go('root.square.activityWizardType', {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      squareGuid: this.$stateParams.squareGuid,
      activityGuid: this.activityGuid,
      step: 'Recruitment partners', // Recruitment source step
    });
  }
}
