'use strict';

import { LoginErrorController } from './loginerror.controller';

const loginerrorTemplate = require('./loginerror.html');
require('./loginerror.scss');

export const LoginErrorComponent: ng.IComponentOptions = {
  controller: LoginErrorController,
  templateUrl: loginerrorTemplate,
  controllerAs: 'vm',
};
