'use strict';

export class IframeDirective implements ng.IDirective {
  constructor(private $sce) { }
  restrict = 'E';
  template = '<iframe frameborder="0" allowfullscreen></iframe>';
  link(scope, element, attrs) {
    const URL = attrs.url;
    const trustedUrl = this.$sce.trustAsResourceUrl(URL);
    const frame = element[0].firstChild;
    frame.contentWindow.location.replace(trustedUrl);
  }
}
