'use strict';

import { FileInputController } from './fileInput.controller';

const template = require('./fileInput.html');

export const fileInput: ng.IComponentOptions = {
  templateUrl: template,
  controller: FileInputController,
  controllerAs: 'vm',
  bindings: {
    files: '=',
    isReadOnly: '<',
    change: '&?',
  },
  require: {
    ngModel: 'ngModel',
  },
};

