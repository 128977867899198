'use strict';

import * as _ from 'lodash';
import { DateFormatService } from '../../../core/services/dateformat.service';
import { SelectedSquareFactory } from '../../../core/selectedsquare.factory';
import { IMemberOverviewFilter } from '../../../core/contracts/filter.contract';
import { AddMemberDialogController } from './addMemberDialog/addMemberDialog.controller';
import { ImportMembersDialogController } from './importMembersDialog/importMembersDialog.controller';
import { MailtemplateOverrulerDialogController } from './mailtemplateOverrulerDialog/mailtemplateOverrulerDialog.controller';
import { Utils } from '../../../core/utils';
import { ParticipantService } from '../../../core/dataservices/participant.service';
import { MemberService } from '../../../core/services/member.service';
import { SegmentationService } from '../../../core/dataservices/segmentation.service';
import { ClipboardService } from '../../../core/services/clipboard.service';
import { Pagination } from '../../../core/models/pagination';
import { ProjectService } from '../../../core/dataservices/project.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { ParticipantFactory } from '../../../core/participant.factory';
import { ServerConstants } from '../../../core/serverconstants';
import { JobService } from '../../../core/jobs/job.service';
import { CacheService } from '../../../core/services/cache.service';
import { IConstants } from '../../../core/constants';
import { MembersExportParams } from '../../../core/contracts/download.contract';
import { DateTime } from 'luxon';
import { IConsentBanner } from 'src/app/core/contracts/member.contract';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { SquareService } from 'src/app/core/dataservices/square.service';


const addMemberDialogTemplateUrl = require('./addMemberDialog/addMemberDialog.html');
const importMembersDialogTemplateUrl = require('./importMembersDialog/importMembersDialog.html');
const mailtemplateOverrulerDialogTemplateUrl = require('./mailtemplateOverrulerDialog/mailtemplateOverrulerDialog.html');
export class MemberOverviewController implements ng.IOnDestroy {

  static $inject = [
    '$stateParams',
    'participantservice',
    'participantFactory',
    'projectservice',
    'spinnerservice',
    '$state',
    'serverConstants',
    'segmentationservice',
    'memberService',
    '$mdDialog',
    'selectedSquareFactory',
    'dateFormatService',
    'ClipboardService',
    '$scope',
    '$document',
    'jobservice',
    '$transitions',
    'constants',
    'featureservice',
    'squareservice',
  ];

  members = [];
  cultures;
  pagination: IPagination = new Pagination(25);
  memberFilter: IMemberOverviewFilter;
  roleLabels: Map<number, string> = new Map();
  exportParams: MembersExportParams = new MembersExportParams();
  availableRoles = [];
  availableSegmentations = [];
  availableSegments = [];
  isSendEmailDisabled: boolean = false;
  order = null;
  minStartDate: DateTime = DateTime.now();
  maxEndDate: DateTime = DateTime.now();
  excludeParticipantsInvitedSinceWeeks = null;
  showFixed: boolean = false;
  isDisabled = false;
  allSelected: boolean = false;
  allSquareParticipantGuids: string[] = [];
  filteredSquareParticipantGuids: string[] = [];
  selectedSquareParticipantGuids: string[] = [];
  consentBanners: IConsentBanner[] = [];
  isConsentFeatureEnabled = false;
  private removeScrollHandler = _.noop;

  registrationStatus = {
    SingleOptIn: 1,
    DoubleOptIn: 3,
    Activated: 5,
    Unsubscribed: 6,
    Anonymized: 7,
  };
  filterDataCounters = [];
  loading: boolean = false;
  cachedGuids: string[] = [];

  isDiySquare = false;

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private participantservice: ParticipantService,
    private participantFactory: ParticipantFactory,
    private projectservice: ProjectService,
    private spinnerservice: SpinnerService,
    private $state: ng.ui.IStateService,
    private serverConstants: ServerConstants,
    private segmentationservice: SegmentationService,
    private memberService: MemberService,
    private $mdDialog: ng.material.IDialogService,
    private selectedSquareFactory: SelectedSquareFactory,
    private dateFormatService: DateFormatService,
    private clipboardService: ClipboardService,
    private $scope: ng.IScope,
    private $document: ng.IDocumentService,
    private jobService: JobService,
    private $transitions,
    private constants: IConstants,
    private featureService: FeatureService,
    private squareservice: SquareService,
  ) {
  }

  defaultAvatar = ParticipantFactory.defaultAvatar;

  onKeyDown($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.keyCode === 67) {
      let text = '';
      if (window.getSelection()) {
        text = window.getSelection().toString();
      }
      this.clipboardService.copyToClipboard(text);
      return text;
    }
  }

  private getDataFromLocalStorage(key, bool = false) {
    const result = CacheService.getCacheValue(key);
    if (result != null) {
      return result;
    }

    if (bool) {
      return false;
    }
    return [];
  }

  private setDataInLocalStorage(key, value) {
    if (!value) {
      CacheService.removeCacheValue(key);
    } else {
      CacheService.setCache({ key, value });
    }
  }

  async selectAll() {
    if (this.allSelected) {
      this.selectedSquareParticipantGuids = angular.copy(this.filteredSquareParticipantGuids);
    } else {
      this.selectedSquareParticipantGuids = [];
    }
    for (const member of this.members) {
      member.selected = this.allSelected;
    }
    this.setDataInLocalStorage('selectedSquareParticipantGuids', this.selectedSquareParticipantGuids);
  }
  selectMember(member) {
    if (member.selected) {
      this.selectedSquareParticipantGuids = _.concat(this.selectedSquareParticipantGuids, member.Detail.Guid);
    } else {
      _.pull(this.selectedSquareParticipantGuids, member.Detail.Guid);
      this.allSelected = false;
    }
    this.setDataInLocalStorage('selectedSquareParticipantGuids', this.selectedSquareParticipantGuids);
  }

  get selectedCount() {
    return _.size(this.selectedSquareParticipantGuids);
  }
  get totalCount() {
    return _.size(this.allSquareParticipantGuids);
  }

  async $onInit() {
    this.loading = true;

    await this.squareservice.getSquareDetails(this.$stateParams.squareGuid)
      .then((data) => this.isDiySquare = data.data.Detail.JobIds.some((job) => job.IsSelfServe));

    this.$transitions.onFinish({ from: 'root.square.members.**' }, (transition) => {
      const toComponent = transition.to().component;
      if (toComponent && !(toComponent === 'memberDetailsProfile' || toComponent === 'memberOverview')) {
        this.setDataInLocalStorage('selectedSquareParticipantGuids', []);
        this.setDataInLocalStorage('isMemberFilterCleared', false);
      }
    });

    const mainContent = document.getElementById('maincontent');
    const handler = () => this.memberActionScroll();
    mainContent.addEventListener('scroll', handler);
    this.removeScrollHandler = () => mainContent.removeEventListener('scroll', handler);
    this.pagination.hide = true;
    this.getAvailableRoles();
    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        this.isConsentFeatureEnabled = isEnabled;
      });
    await this.getAvailableSegmentations();
    await this.initData();

    this.loading = false;

  }

  async initData() {
    /* MinStartDate means users min logged in date so in order to avoid filtering out users with
    last login date before square startdate
    let the minStartDate be the square creation date */
    this.minStartDate = await this.selectedSquareFactory.squareCreateDatePromise;
    this.minStartDate = this.dateFormatService.startOfDay(this.minStartDate);
    this.maxEndDate = this.dateFormatService.endOfDay();

    this.parseParameters();
    this.initSegments();
    await this.loadMembers();
  }

  initSegments() {
    if (!this.memberFilter.SegmentGuids || !this.availableSegmentations) {
      return;
    }
    this.availableSegments = this.memberFilter.SegmentGuids;
  }

  getAvailableRoles() {
    const roleConstants = this.serverConstants.roleConstants;

    const tempRoles = [];
    const roles = _.filter(Utils.getEnumAsArray(roleConstants), (role) => role.Value === roleConstants.human8 || role.Value === roleConstants.participant ||
      role.Value === roleConstants.professionalAdmin || (role.Value === roleConstants.clientAdmin && this.isDiySquare) ||
      (role.Value === roleConstants.clientEditor && this.isDiySquare) || role.Value === roleConstants.observer);
    _.each(roles, (role) => {
      const label = Utils.getRoleLabel(roleConstants, role.Value);
      const item = {
        Label: label, // This value should be unchanged and is used to customize DisplayLabel if needed (e.g. adding count)
        Id: role.Value,
        DisplayLabel: label,
      };
      tempRoles.push(item);
      this.roleLabels.set(role.Value, role.DisplayLabel);
    });
    // Roles should be in a specific order based on how much they can do (Human8 users can do more than moderators, etc)
    const sortingArr = [roleConstants.human8, roleConstants.professionalAdmin, roleConstants.clientAdmin, roleConstants.clientEditor,
      roleConstants.observer, roleConstants.participant];
    tempRoles.sort((a, b) => sortingArr.indexOf(a.Id) - sortingArr.indexOf(b.Id));

    this.availableRoles = tempRoles;
  }

  async getAvailableSegmentations() {
    this.spinnerservice.show('loading');
    try {
      this.availableSegmentations = await this.segmentationservice.getSegmentationForFiltering(true);
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  parseParameters() {
    const stateParamsPage = this.$stateParams.page;
    const stateParamsLimit = this.$stateParams.limit;
    const stateParamsOrder = this.$stateParams.order;
    const stateParamsMemberFilter = this.memberService.getFilterFromQueryString();
    const localStoragePage = this.getDataFromLocalStorage('pageInfo').page;
    const localStorageLimit = this.getDataFromLocalStorage('pageInfo').limit;
    const localStorageOrder = this.getDataFromLocalStorage('pageInfo').order;
    const localStorageMemberFilter = this.getDataFromLocalStorage('memberFilter') as IMemberOverviewFilter;
    if (this.isFilterUsed(localStorageMemberFilter)) {
      // Luxon changed the dates to an ISO string while using JSON.stringify before saving it to the localstorage. Let's make Luxon objects from them again
      localStorageMemberFilter.StartDate = localStorageMemberFilter.StartDate ? DateTime.fromISO(localStorageMemberFilter.StartDate as any as string) : null;
      localStorageMemberFilter.EndDate = localStorageMemberFilter.EndDate ? DateTime.fromISO(localStorageMemberFilter.EndDate as any as string) : null;
    }

    this.pagination.page = stateParamsPage ? parseInt(stateParamsPage, 10) : localStoragePage ? localStoragePage : 1;
    this.pagination.limit = stateParamsLimit ? parseInt(stateParamsLimit, 10) : localStorageLimit ? localStorageLimit : 25;
    this.selectedSquareParticipantGuids = this.getDataFromLocalStorage('selectedSquareParticipantGuids');
    this.allSelected = this.getDataFromLocalStorage('allSelected');
    this.order = stateParamsOrder || localStorageOrder || null;
    this.memberFilter = stateParamsMemberFilter ? stateParamsMemberFilter : !this.isFilterUsed(localStorageMemberFilter)
      ? this.memberService.getEmptyMemberOverviewFilter() : localStorageMemberFilter;
    this.memberFilter.List = this.getDataFromLocalStorage('memberFilter.List') || [];
    const isMemberFilterCleared = this.getDataFromLocalStorage('isMemberFilterCleared', true);
    if (_.isEmpty(localStorageMemberFilter) && !isMemberFilterCleared && this.memberFilter.Roles.length <= 0) {
      this.setInitialRoles();
    }
    this.setDataInLocalStorage('memberFilter.List', undefined);
    this.setDataInLocalStorage('memberFilter', undefined);
    this.setDataInLocalStorage('pageInfo', undefined);
  }

  setInitialRoles() {
    const roleConstants = this.serverConstants.roleConstants;
    const rolesSelected = _.filter(this.availableRoles, (role) => role.Id === roleConstants.participant ||
      role.Id === roleConstants.professionalAdmin || role.Id === roleConstants.clientAdmin || role.Id === roleConstants.clientEditor ||
      role.Id === roleConstants.observer);
    _.each(rolesSelected, (role) => {
      const item = {
        Label: role.Label,
        Id: role.Id,
        DisplayLabel: role.DisplayLabel,
      };
      this.memberFilter.Roles.push(item.Id);
    });
  }

  async loadMembers() {
    try {
      this.spinnerservice.show('loading');

      if (!this.exportParams) {
        this.exportParams = new MembersExportParams();
      }
      this.exportParams.keyword = this.memberFilter.Keyword;
      this.exportParams.selectedRoles = this.memberFilter.Roles;
      this.exportParams.selectedStatuses = this.memberFilter.Statuses;
      this.exportParams.selectedSegments = this.memberFilter.SegmentGuids;
      this.exportParams.selectedSegmentsOption = this.memberFilter.SegmentOptions;
      this.exportParams.startDate = this.memberFilter.StartDate;
      this.exportParams.endDate = this.memberFilter.EndDate;
      this.exportParams.excludeParticipantsInvitedSinceWeeks = this.memberFilter.ExcludeParticipantsInvitedSinceWeeks;

      // Use local cache, if any
      if (!this.cachedGuids || !this.cachedGuids.length) {
        const participants = await this.participantservice.getSquareParticipantGuidList(null);
        if (participants && participants.data) {
          this.allSquareParticipantGuids = participants.data;
          this.cachedGuids = this.allSquareParticipantGuids;
        } else {
          this.allSquareParticipantGuids = [];
        }
      } else {
        this.allSquareParticipantGuids = this.cachedGuids;
      }
      this.filteredSquareParticipantGuids = this.allSquareParticipantGuids;

      this.consentBanners = await this.participantservice.getConsentExpirationBanners();

      if (this.isFilterUsed()) {
        const filteredParticipants = await this.participantservice.getSquareParticipantGuidList(this.memberFilter);
        if (filteredParticipants && filteredParticipants.data) {
          this.filteredSquareParticipantGuids = filteredParticipants.data;
        } else {
          this.filteredSquareParticipantGuids = [];
        }
      }

      // Reset the all selected flag if needed
      if (this.allSelected && this.selectedSquareParticipantGuids.length < this.filteredSquareParticipantGuids.length) {
        this.allSelected = false;
      }

      this.memberFilter.Suspended = this.memberFilter.Statuses
        && this.memberFilter.Statuses.length !== 0
        && _.find(this.memberFilter.Statuses, (status) => status === this.constants.suspendedStatus) === this.constants.suspendedStatus;
      this.memberFilter.Locked = this.memberFilter.Statuses
        && this.memberFilter.Statuses.length !== 0
        && _.find(this.memberFilter.Statuses, (status) => status === this.constants.lockedStatus) === this.constants.lockedStatus;

      const participants = await this.participantservice.getSquareParticipantList(this.pagination.page, this.pagination.limit, this.order, this.memberFilter);
      if (participants && participants.data.SearchResult.Items && participants.data.SearchResult.Items.length) {
        this.members = participants.data.SearchResult.Items;
        this.pagination.total = participants.data.SearchResult.TotalItems;
        this.filterDataCounters = participants.data.FilterDataCounters;
        _.each(this.members, (member) => {
          member.Detail.Username = member.Detail.Username || member.Detail.Email;
          member.Detail.FullName = member.Detail.FirstName + (member.Detail.LastName ? ` ${member.Detail.LastName}` : '');
          member.Detail.RoleName = Utils.getRoleLabel(this.serverConstants.roleConstants, member.Detail.Role);
          member.Detail.StatusName = Utils.getStatusLabel(this.serverConstants.registrationStatusConstants, member.Detail.Status,
            member.Detail.Suspended, member.Detail.Locked);
          member.Detail.OrderableDate = DateTime.fromISO(member.Detail.LastLoginDate || 0).toFormat('yyyy-MM-dd h:mm:ss a');
          this.memberChanged(member);
        });
        this.pagination.hide = false;
      } else {
        this.members = [];
        this.selectedSquareParticipantGuids = [];
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  memberChanged(member) {
    member.selected = _.includes(this.selectedSquareParticipantGuids, member.Detail.Guid);
    member.enableSendActivationEmail = member.Detail.Status === this.serverConstants.registrationStatusConstants.singleOptInNoEmail
      && member.Detail.Suspended === false;
    member.enableResendActivationEmail = member.Detail.Status !== this.serverConstants.registrationStatusConstants.active &&
      member.Detail.Status !== this.serverConstants.registrationStatusConstants.unsubscribed &&
      member.Detail.Status !== this.serverConstants.registrationStatusConstants.anonymized &&
      member.Detail.Status !== this.serverConstants.registrationStatusConstants.singleOptInNoEmail &&
      member.Detail.Suspended === false;
    member.activationEmailSentLast24hours = DateTime.fromISO(member.Detail.DateLastActivationEmail || 0).plus({ days: 1 }) > DateTime.now();
    member.showLastActivationEmail = (member.enableSendActivationEmail || member.enableResendActivationEmail) && member.Detail.DateLastActivationEmail;
  }

  getAvatar(member) {
    if (member) {
      return member.Detail.HasProfileImage ?
        this.participantFactory.getParticipantImageUrl(member.Detail.Guid, member.Detail.DateModified) :
        ParticipantFactory.defaultAvatar;
    }
    return undefined;
  }

  onPaginate = (pageNumber) => {
    this.goToPage(pageNumber);
  };

  private goToPage = (pageNumber) => {
    this.setDataInLocalStorage('selectedSquareParticipantGuids', this.selectedSquareParticipantGuids);
    this.setDataInLocalStorage('allSelected', this.allSelected);
    this.pagination.page = pageNumber;
    this.pagination.hide = true;
    this.setDataInLocalStorage('memberFilter.List', this.memberFilter.List.length > 0 ? this.memberFilter.List : undefined);
    const filterParams = this.memberService.getStateParamsFromFilter(this.memberFilter);
    this.$state.go('.', {
      page: this.pagination.page,
      limit: this.pagination.limit,
      order: this.order,
      ...filterParams,
      triggerDate: DateTime.utc().valueOf(), // Unique parameter to always trigger refresh
    });
  };

  onOrder = () => {
    this.goToPage(this.pagination.page);
  };

  goToMemberDetails(memberGuid): void {
    this.setDataInLocalStorage('selectedSquareParticipantGuids', this.selectedSquareParticipantGuids);
    this.setDataInLocalStorage('memberFilter', this.memberFilter);
    this.setDataInLocalStorage('pageInfo', {
      page: this.pagination.page,
      limit: this.pagination.limit,
      order: this.order,
    });
    this.$state.go('root.square.members.details', {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      squareGuid: this.$stateParams.squareGuid,
      memberGuid,
    });
  }

  async filterMembers() {
    if (this.loading) {
      return;
    }
    await this.loadMembers();
  }

  resetFilter() {
    this.memberFilter.Keyword = null;
    this.memberFilter.List = [];
    this.memberFilter.Roles = [];
    this.memberFilter.Statuses = [];
    this.memberFilter.StartDate = null;
    this.memberFilter.EndDate = null;
    this.memberFilter.ExcludeParticipantsInvitedSinceWeeks = null;
    this.resetSegmentation();
    this.setDataInLocalStorage('isMemberFilterCleared', true);
  }

  resetSegmentation() {
    this.memberFilter.SegmentGuids = [];
  }

  isFilterUsed(filter: IMemberOverviewFilter = null) {
    filter = filter || this.memberFilter;
    return filter.Keyword ||
      (filter.List !== undefined && filter.List.length > 0) ||
      (filter.Roles !== undefined && filter.Roles.length > 0) ||
      (filter.Statuses !== undefined && filter.Statuses.length > 0) ||
      filter.StartDate ||
      filter.EndDate ||
      (filter.SegmentGuids !== undefined && filter.SegmentGuids.length > 0) ||
      filter.ExcludeParticipantsInvitedSinceWeeks;
  }

  createMember() {
    if (!this.cultures) {
      this.projectservice.getCultureList()
        .then((cultures) => {
          this.cultures = cultures;
          this.openCreateMemberModal();
        });
    } else {
      this.openCreateMemberModal();
    }
  }

  importMembers() {
    if (!this.cultures) {
      this.projectservice.getCultureList()
        .then((cultures) => {
          this.cultures = cultures;
          this.openImportMembersModal();
        });
    } else {
      this.openImportMembersModal();
    }
  }

  openCreateMemberModal() {
    this.$mdDialog.show({
      controller: AddMemberDialogController,
      controllerAs: 'vm',
      templateUrl: addMemberDialogTemplateUrl,
      clickOutsideToClose: false,
      escapeToClose: false,
      locals: {
        cultures: this.cultures,
      },
    },
    ).then(() => {
      this.loadMembers();
    });
  }

  openImportMembersModal() {
    this.$mdDialog.show({
      controller: ImportMembersDialogController,
      controllerAs: 'vm',
      templateUrl: importMembersDialogTemplateUrl,
      clickOutsideToClose: false,
      locals: {
        loadMembers: () => this.loadMembers(),
        cultures: this.cultures,
      },
    });
  }

  openMailtemplateOverrulerModal(member, event) {
    event.stopPropagation();
    this.isSendEmailDisabled = true;
    this.$mdDialog.show({
      controller: MailtemplateOverrulerDialogController,
      controllerAs: 'vm',
      templateUrl: mailtemplateOverrulerDialogTemplateUrl,
      clickOutsideToClose: false,
      locals: {
        member,
      },
    }).then(() => {
      if (member.Detail.Status === this.serverConstants.registrationStatusConstants.singleOptInNoEmail) {
        member.Detail.Status = this.serverConstants.registrationStatusConstants.singleOptIn;
        member.Detail.StatusName = Utils.getStatusLabel(this.serverConstants.registrationStatusConstants, member.Detail.Status, member.Detail.Suspended);
      }
      member.Detail.DateLastActivationEmail = DateTime.now();
      this.memberChanged(member);
    }).finally(() => {
      this.isSendEmailDisabled = false;
    });
  }

  async exportMembers() {
    this.isDisabled = true;
    const jobResult = await this.segmentationservice.exportMembers();
    if (jobResult && jobResult.data && jobResult.data !== '') {
      await this.jobService.showJobProgressDialog(
        jobResult.data, 'Export conversation');
    }
    this.isDisabled = false;
  }

  memberActionScroll() {
    const stickyMenu = document.querySelector('.isc-member-filter-bottom');
    const memberActionsDefault = document.querySelector('.member-action-bar-table');
    if (stickyMenu && memberActionsDefault) {
      const memberActionsBounding = memberActionsDefault.getBoundingClientRect();
      const stickyMenuBoundings = stickyMenu.getBoundingClientRect();
      if (memberActionsBounding.top <= stickyMenuBoundings.bottom) {
        this.$scope.$apply(() => {
          this.showFixed = true;
        });
      } else {
        this.$scope.$apply(() => {
          this.showFixed = false;
        });
      }
    }
  }

  $onDestroy(): void {
    this.$document[0].onkeypress = _.noop;
    this.removeScrollHandler();
  }
}
