'use strict';

import { ProgramService } from '../../../core/dataservices/program.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { IProgramCreditsPerActivityType } from '../../../core/dataservices/program.service.contracts';

export class UpdateProgramCreditsController implements ng.IOnInit {
  static $inject = ['programservice', 'spinnerservice', 'logger', '$q', '$stateParams', '$mdDialog'];
  updateProgramCreditsForm;

  constructor(
    private programservice: ProgramService,
    private spinnerservice: SpinnerService,
    private logger: Logger,
    private $q: ng.IQService,
    private $stateParams: ng.ui.IStateParamsService,
    private $mdDialog: ng.material.IDialogService,
  ) {}

  isSaving: boolean;
  ProgramCreditsPerActivityType: IProgramCreditsPerActivityType;

  $onInit(): void {
    this.logger.info('Program details activated');
    this.spinnerservice.show('loading');
    this.getProgramCreditsPerActivityType().then(() => {
      this.logger.info('Program details loaded');
    }).finally(() => {
      this.spinnerservice.hide('loading');
    });
  }

  getProgramCreditsPerActivityType = () => this.programservice.getProgramCreditsPerActivityType(this.$stateParams.programGuid).then((response) => {
    this.ProgramCreditsPerActivityType = angular.copy(response);
  });

  save() {
    this.isSaving = true;
    this.programservice.createUpdateProgramCreditsPerActivityType(this.ProgramCreditsPerActivityType, this.$stateParams.programGuid).then((result) => {
      if(result) {
        const data = result;
        this.ProgramCreditsPerActivityType = angular.copy(data);
      }
      this.$mdDialog.hide();
    }).finally(() => {
      this.isSaving = false;
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
