'use strict';

import _ = require('lodash');

export class SpinnerService {
  static $inject = ['$q'];

  constructor(
    private $q,
  ) {}

  spinners: { [key: string]: any } = {};

  _register(data) {
    if (!Object.prototype.hasOwnProperty.call(data,'name')) {
      throw new Error('Spinner must specify a name when registering with the spinner service.');
    }
    data.countShown = 0;
    this.spinners[data.name] = data;
  }

  _unregister(name: string) {
    if (Object.prototype.hasOwnProperty.call(this.spinners,name)) {
      delete this.spinners[name];
    }
  }

  _unregisterGroup(group: string) {
    _.each(this.spinners, (name) => {
      if (this.spinners[name].group === group) {
        delete this.spinners[name];
      }
    });
  }

  _unregisterAll() {
    _.each(this.spinners, (name) => {
      delete this.spinners[name];
    });
  }

  show(name: string) {
    const spinner = this.spinners[name];
    if (!spinner) {
      throw new Error(`No spinner named ${name} is registered.`);
    }
    ++spinner.countShown;
    spinner.show();
  }

  showFor(name: string, promises) {
    if (!Array.isArray(promises)) {
      promises = [promises];
    }

    this.show(name);
    this.$q.all(promises).finally(() => {
      this.hide(name);
    });
  }

  hide(name: string) {
    const spinner = this.spinners[name];
    if (!spinner) {
      throw new Error(`No spinner named ${name} is registered.`);
    }
    if (--spinner.countShown === 0) {
      spinner.hide();
    }
  }

  showGroup(group: string) {
    let groupExists = false;
    _.each(this.spinners, (name) => {
      const spinner = this.spinners[name];
      if (spinner.group === group) {
        spinner.show();
        groupExists = true;
      }
    });
    if (!groupExists) {
      throw new Error(`No spinners found with group ${group}.`);
    }
  }

  hideGroup(group: string) {
    let groupExists = false;
    _.each(this.spinners, (name) => {
      const spinner = this.spinners[name];
      if (spinner.group === group) {
        spinner.hide();
        groupExists = true;
      }
    });
    if (!groupExists) {
      throw new Error(`No spinners found with group ${group}.`);
    }
  }

  showAll() {
    _.each(this.spinners, (name) => {
      this.spinners[name].show();
    });
  }

  hideAll() {
    _.each(this.spinners, (spinner) => {
      spinner.hide();
    });
  }
}
