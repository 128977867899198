'use strict';

import { DateTime } from 'luxon';
import { SelectedActivityFactory } from '../selectedactivity.factory';
import * as _ from 'lodash';
import { SelectedSquareFactory } from '../selectedsquare.factory';
import { ActivityService } from '../dataservices/activity.service';
import { PinnedItemsService } from './pinned-items.service';
import { ServerConstants } from '../serverconstants';

export class ActivityHelperService {
  static $inject = ['serverConstants', 'helperservice', 'selectedActivityFactory',
    '$stateParams', '$state', 'activityFilterService', 'selectedSquareFactory', 'activityservice', 'pinnedItemsService','__env'];

  entityType = 'activity';

  constructor(
    private serverConstants: ServerConstants,
    private helperservice,
    private selectedActivityFactory: SelectedActivityFactory,
    private $stateParams,
    private $state,
    private activityFilterService,
    private selectedSquareFactory: SelectedSquareFactory,
    private activityservice: ActivityService,
    private pinnedItemsService: PinnedItemsService,
    private __env: Environment,
  ) {
  }

  getSquareActivityFormatAsString(format: number) {
    return _.findKey(this.serverConstants.squareActivityFormatConstants, (c) => c === format);
  }

  getSquareActivityTypeAsString(type: number) {
    return _.findKey(this.serverConstants.squareActivityTypeConstants, (c) => c === type);
  }

  getSquareActivityStatusAsString(status: number) {
    return _.findKey(this.serverConstants.squareActivityStatusConstants, (c) => c === status);
  }

  convertEnumsToStringForActivity(activity) {
    activity.StatusString = this.getSquareActivityStatusAsString(activity.Status);
  }

  convertEnumsToStringForActivities(activities) {
    _.forEach(activities, (activity: any) => {
      this.convertEnumsToStringForActivity(activity);
      this.convertEnumsToStringForActivities(activity.ChildActivities);
    });
  }

  getFlattenedActivities(activities) {
    const flattenedActivities = [];
    this.flattenActivities(activities, flattenedActivities, null);
    return flattenedActivities;
  }

  flattenActivities(activities, flattenedActivities, parentGuid) {
    activities.forEach((activity) => {
      activity.ParentGuid = parentGuid;
      flattenedActivities.push(activity);
      this.flattenActivities(activity.ChildActivities, flattenedActivities, activity.Guid);
    });

    return flattenedActivities;
  }

  isActivityDisplayed(activity, allowedStatuses, activityList) {
    const shouldDisplayActivity = (activityToBeChecked) => _.some(allowedStatuses, (item) => (item as string).toLowerCase() === activityToBeChecked.StatusString);

    let shouldDisplay = shouldDisplayActivity(activity);
    const isChildActivity = activity.ParentGuid;
    const isParentActivity = activity.ChildActivities && activity.ChildActivities.length > 0;
    if (isChildActivity) {
      const parentActivity = _.find(activityList, (item: any) => item.Guid === activity.ParentGuid);
      const isParentDisplayed = shouldDisplayActivity(parentActivity);
      shouldDisplay = isParentDisplayed || shouldDisplay;
    } else if (isParentActivity) {
      const hasDisplayedChildren = _.some(allowedStatuses, (item: any) => {
        const allowedStatus = (item as string).toLowerCase();
        return _.some(activity.ChildActivities, (child: any) => child.StatusString === allowedStatus);
      });

      shouldDisplay = hasDisplayedChildren || shouldDisplay;
    }

    return shouldDisplay;
  }

  async getActivityMaxStartDate(activityType) {
    const maxStartDate = await this.activityservice.getActivityMaxStartDate(activityType);
    return maxStartDate && DateTime.fromISO(maxStartDate);
  }

  isPinned(activity) {
    return this.pinnedItemsService.isPinned(activity.Guid);
  }

  togglePin(activity) {
    this.pinnedItemsService.toggleActivityPin(this.__env.commandApiUrl, activity.Guid);
  }

  isPrivateDiscussion(activityType: number, activityVisibility: number) {
    return activityType === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch ||
      activityType === this.serverConstants.squareActivityTypeConstants.privateScoutResearch ||
      (activityType === this.serverConstants.squareActivityTypeConstants.discussion20Research &&
        activityVisibility === this.serverConstants.activityVisibilityTypeConstants.private);
  }
}
