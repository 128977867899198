'use strict';

import './clientinfo.module';
import { ClientInfoComponent } from './clientinfo.component';
import routeConfig from './clientinfo.route';

angular
  .module('insitesApp.clientinfo')
  .component('clientInfo', ClientInfoComponent)
  .run(routeConfig);
