'use strict';

import { ActivitySegmentationController } from './activitySegmentation.controller';
const template = require('./activitySegmentation.html');

export const ActivitySegmentationComponent: ng.IComponentOptions = {
  controller: ActivitySegmentationController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    squareActivity: '<',
    isPublished: '<',
    isReadOnly: '<',
    surveyPreparationStatus: '<',
  },
  require: {
    wizardStep: '^^iscWizardStep',
  },
};
