'use strict';

const trustAsHTMLFilter = ($sce) => (value) => {
  const txt = document.createElement('textarea');
  txt.innerHTML = value;
  return $sce.trustAsHtml(txt.value);
};
trustAsHTMLFilter.$inject = ['$sce'];

angular
  .module('insitesApp.layout')
  .filter('trustAsHTML', trustAsHTMLFilter);
