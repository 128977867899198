'use strict';

import { MappingService } from './../../../../core/services/mapping.service';
import { SelectedActivityFactory } from './../../../../core/selectedactivity.factory';
import { ServerConstants } from './../../../../core/serverconstants';
import { ActivityDataService, IForumConversation } from './../../../../core/services/activityData.service';
import { ActivityService } from './../../../../core/dataservices/activity.service';
import { ActivityProbeQualBuildModel } from './activityProbeQualBuildModel';
import { ActivityBuildModelBase } from '../common/activityBuildBase.model';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
import { ActivityBuildBaseController } from '../common/activityBuildBase.controller';
export class ActivityProbeQualBuildController extends ActivityBuildBaseController {
  static $inject = [
    'serverConstants',
    'activityservice',
    'activitydataservice',
    '$stateParams',
    'logger',
    'selectedActivityFactory',
    '$scope',
    '$mdDialog',
    'poboService',
    'mappingService',
    '$q'];

  removeLink: () => void;

  model = new ActivityProbeQualBuildModel();

  isBuildStepCompleted = false;
  isNavigationPossible = false;
  saveCallback = () => super.beforeSave(false);
  resetFormCallback = () => super.resetForm();

  constructor(
    protected serverConstants: ServerConstants,
    protected activityservice: ActivityService,
    protected activitydataservice: ActivityDataService,
    protected $stateParams: ng.ui.IStateParamsService,
    protected logger: Logger,
    protected selectedActivityFactory: SelectedActivityFactory,
    protected $scope: ng.IScope,
    protected $mdDialog: ng.material.IDialogService,
    protected poboService: PoboService,
    protected mappingService: MappingService,
    private $q: ng.IQService,
  ) {
    super(serverConstants, activityservice, activitydataservice, $stateParams, logger, selectedActivityFactory, $scope,
      $mdDialog, poboService, mappingService);
    this.validationConstants = serverConstants.validationConstants;
    this.questionType = this.serverConstants.conversationQuestionTypeConstants.text;
  }

  customValidation = () => this.validateNavigation('', '', this);

  $onInit() {
    super.$onInit();
  }

  protected mapModel(data: SquareActivityModel) {
    this.model = this.mappingService.mapSquareActivityModelForProbeQual(data);
  }

  $loadData(data) {
    super.$loadData(data);
  }

  protected additionalConditionForCompletedSteps(model: ActivityBuildModelBase = null):boolean {
    return model.probeQuestions.some((question) => question.answerRequired);
  }

  $wizardIsValid() {
    let formIsValid = super.$wizardIsValid();
    if (this.isBuildStepCompleted) {
      const isAtLeastOneQuestionRequired = this.model.probeQuestions.some((question) => question.answerRequired);
      formIsValid = formIsValid && isAtLeastOneQuestionRequired;
      this.form.ProbeQuestions.$setValidity('probeQuestionsMandatory', isAtLeastOneQuestionRequired, null);
    }

    return formIsValid;
  }

  validateNavigation(toParams, fromParams, root) {
    if (!root) {
      root = this;
    }
    if(root.wizardStep.state === 'next' || root.wizardStep.state === 'incomplete'){
      this.isNavigationPossible = true;
    } else {
      this.isNavigationPossible = root.form.$valid;
    }
    return {
      canNavigate: this.isNavigationPossible,
      isDirty: root.form.$dirty,
    };
  }

  async $wizardBeforeNext(reload: boolean) {

    // We do not know when the user finishes adding Probing Questions and we do not want to display the validation message till finished so
    // The only trigger for validation is "save and continue" and the user can still continue configuring but cannot finish till fixing the "errors"
    // In this case setting at least one mandatory probing question
    this.isBuildStepCompleted = true;
    if (!this.$wizardIsValid()) {
      this.form.$setDirty();
      return this.$q.reject();
    }
    await this.beforeSave(reload);
  }

  async updateActivityBuild(reload: boolean, root: this) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }

    const conversation: IForumConversation = this.mappingService.mapActivityBuildModelToForumConversation(root.model);
    conversation.ActivityGuid = root.$stateParams.activityGuid;
    const dataActivityQual = {
      ActivityGuid: root.$stateParams.activityGuid,
      ConversationGuid: '',
      Title: root.model.title,
      Description: root.model.description,
      IsOneByOne: root.model.isOneByOne,
      ConversationFocusType: root.model.conversationFocusType,
    };

    let conversationGuid = '';
    try {
      ({ ConversationGuid: conversationGuid } = await root.activitydataservice
        .saveConversation(
          conversation,
          root.model.newAttachmentFiles,
          root.probeQuestions));
    } catch (error) {
      if (error.data && error.data.ValidationErrors && error.data.ValidationErrors[0] && error.data.ValidationErrors[0].PropertyName
        && error.data.ValidationErrors[0].PropertyName === 'ProbeQuestions') {
        // Reload the data for this step and show that probe questions can't be changed anymore.
        root.$loadData(root.squareActivity);
        root.probeQuestionsAlreadyAnswered = true;
      }
      // Rethrow error so that the wizard doesn't go to the next step.
      throw error;
    }

    dataActivityQual.ConversationGuid = conversationGuid;
    root.model.conversationGuid = dataActivityQual.ConversationGuid;
    await root.activityservice.updateActivityQualBuild(dataActivityQual);

    const response = await root.activityservice.selectActivityQualResearch(root.$stateParams.activityGuid);
    if (response.data) {
      root.logger.success('Activity build successfully updated');
    }
    root.squareActivity = response.data;
    root.squareActivity.isChildActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.child;
    root.squareActivity.isParentActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.parent;

    root.$loadData(response.data);
    root.form.$setPristine();
  }
}
