'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';
import { FeatureService } from '../../../core/dataservices/feature.service';
import { ServerConstants } from '../../../core/serverconstants';

const routeConfig = (
  routerHelper: RouterHelper,
  featureservice: FeatureService,
  serverConstants: ServerConstants) => {
  routerHelper.configureStates([{
    state: 'root.square.incentives',
    config: {
      url: '/config/incentives',
      component: 'incentives',
      title: 'Incentives',
      settings: {
        nav: 2,
        childnav: 3,
        label: 'Reward pages',
        showForums: true,
        show: async () => {
          const featureSettings = await featureservice.getFeatureSettings(serverConstants.featureConstants.savingUpRewards);
          return featureSettings.isEnabled && featureSettings.isMasterSwitchEnabled;
        },
      },
      disallowRoles: ['Observer'],
    },
  }]);
};

routeConfig.$inject = ['routerHelper', 'featureservice', 'serverConstants', '$q'];

export default routeConfig;

