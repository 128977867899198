var IscTagInputController = (function () {
    function IscTagInputController($element, $scope) {
        this.$element = $element;
        this.$scope = $scope;
        this.searchTerm = '';
    }
    IscTagInputController.prototype.$onInit = function () {
        var _this = this;
        this.$scope.$watchCollection('vm.model', function () {
            _this.ensureModelController();
            if (_this.isRequired) {
                _this.ngModelController.$setValidity('required', true);
                if (!_this.model || _this.model.length === 0) {
                    _this.ngModelController.$setValidity('required', false);
                }
            }
        });
    };
    IscTagInputController.prototype.ensureModelController = function () {
        var element = this.$element.find('md-chips');
        this.ngModelController = element.controller('ngModel');
    };
    IscTagInputController.prototype.getChip = function (chip) {
        if (!this.chipProperty || chip[this.chipProperty] === undefined) {
            return chip;
        }
        return chip[this.chipProperty];
    };
    IscTagInputController.$inject = ['$element', '$scope'];
    return IscTagInputController;
}());
export { IscTagInputController };
