'use strict';

const publishCreditsTemplate = require('./publishCredits.html');

angular
  .module('insitesApp.activityconfig')
  .component('publishCredits', {
    templateUrl: publishCreditsTemplate,
    controllerAs: 'vm',
    bindings: {
      credits: '<',
    },
  });
