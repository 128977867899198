'use strict';

export class IscSegmentsFilterController implements ng.IOnInit {
  static $inject = ['$timeout', '_', 'serverConstants'];

  constructor(private $timeout, private _: _.LoDashStatic, private serverConstants) {}

  selectedSegments;
  segmentOptions = this.serverConstants.selectedSegmentsOptionConstants;
  selectedSegmentsOption;
  options;

  $onInit(): void {
    this.selectedSegmentsOption = this.selectedSegmentsOption || this.segmentOptions.any;
  }

  onClick() {
    // By default on dropdown opening, the scroll bar is not on top so the search box is not visible
    this.$timeout(() => {
      const container = angular.element(document.querySelector('.md-select-menu-container.md-active.segments-filter-container md-content'));
      if (container[0]) {
        container[0].scrollTop = 0;
      }
    }, 100);
  }

  showSelectedText() {
    let segmentOptionsText = 'Select segments';
    let answer = '';
    if (this.selectedSegments) {
      if (this.selectedSegmentsOption === this.segmentOptions.all && this.selectedSegments.length > 0) {
        segmentOptionsText = '(Matches all) ';
      }
      if (this.selectedSegmentsOption === this.segmentOptions.any && this.selectedSegments.length > 0) {
        segmentOptionsText = '(Matches any)';
      }
      this._.each(this.options, (item: any) => {
        if (this.selectedSegments) {
          return this._.each(item.SegmentItems, (s: any) => {
            if (s.Guid === this.selectedSegments[0]) {
              answer = s.SegmentAnswer;
            }
          });
        }
      });
    }
    answer = this._.trim(answer);
    if (answer.length > 9) {
      answer = `${answer.substr(0, 9)}...`;
    }
    if (this.selectedSegments) {
      if (this.selectedSegments.length === 1) {
        segmentOptionsText = `${segmentOptionsText} ${answer}`;
      }
      if (this.selectedSegments.length > 1) {
        segmentOptionsText = `${segmentOptionsText} ${answer} (+${this.selectedSegments.length - 1})`;
      }
    }
    return segmentOptionsText;
  }
}
