'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.membersparent',
      config: {
        abstract: true,
        settings: {
          nav: 6,
          icon: 'fa fa-users',
          label: 'Members',
          section: 'SQUARE MANAGEMENT',
          stateParams: ['clientGuid', 'programGuid', 'squareGuid'],
          showForums: true,
        },
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

