'use strict';

import { RouterHelper } from 'src/app/blocks/router/router-helper';

const routeConfig = (
  routerHelper: RouterHelper,
) => {
  routerHelper.configureStates([{
    state: 'root.splash-agreement',
    config: {
      url: 'splash-agreement?returnUri',
      component: 'splashPage',
      params: {
        returnUri: null,
      },
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;
