'use strict';

import {
  IUploadConversationStimulus,
  IUploadConversationAttachment,
  IUploadedItem,
} from 'isc-ui';
import { IForumConversation } from './../../core/services/activityData.service'; // to be changed

import { ForumService } from '../dataservices/forum.service';
import { ServerConstants } from '../serverconstants';
import * as _ from 'lodash';
import { DiscussionService } from '../dataservices/discussion.service';
import { SquareActivityModel } from '../../activity/activityQualResearchConfig/squareActivityModel';

import {
  ICreateOrUpdateDiscussionRequest,
  ICreateOrUpdateMessageRequest,
  ICreateOrUpdatePostRequest,
  ICreateOrUpdateQuestionRequest,
  ICreateStimulusRequest,
  IQuestionViewModel,
  IRemoveStimulusRequest,
  IRemoveQuestionRequest,
  ICreateStimulusResponse,
} from '../contracts/discussion.contract';
import {
  ICreateOrUpdateDiscussionActivityRequest,
} from '../contracts/discussionActivity.contract';
import { FileStorageService } from '../dataservices/fileStorage.service';
import { IVideoLink, MuxService } from '../dataservices/mux.service';
import { SelectedClientFactory } from '../selectedclient.factory';
import { IProbeQuestionModel } from 'src/app/activity/activityQualResearchConfig/activityQualSteps/activityProbeQualBuild/IProbeQuestionModel';

export class DiscussionDataService {
  static $inject = [
    'forumservice',
    'serverConstants',
    'discussionService',
    'fileStorageService',
    'muxService',
    'logger',
    '$q',
    'selectedClientFactory',
  ];
  constructor(
    private forumService: ForumService,
    private serverConstants: ServerConstants,
    private discussionService: DiscussionService,
    private fileStorageService: FileStorageService,
    private muxService: MuxService,
    private logger: Logger,
    private $q: ng.IQService,
    private selectedClientFactory: SelectedClientFactory,
  ) { }

  private prepareCreateOrUpdateDiscussionRequest(
    conversation: IForumConversation,
    squareActivityModel: SquareActivityModel,
  ): ICreateOrUpdateDiscussionRequest {
    const createOrUpdateDiscussionRequest: ICreateOrUpdateDiscussionRequest = {
      guid: squareActivityModel.discussionModel.discussionGuid, // use the Conversation GUID Field since we dont have a discusssion Guid - just create for the moment
      title: conversation.Title,
    };
    return createOrUpdateDiscussionRequest;
  }

  private prepareCreateOrUpdatePostRequest(
    conversation: IForumConversation,
    discussionGuid: string,
    squareActivityModel: SquareActivityModel,
  ): ICreateOrUpdatePostRequest {
    const postGuid: string = squareActivityModel.discussionModel?.post?.guid; // new Discussion
    const createOrUpdateDiscussionPostRequest: ICreateOrUpdatePostRequest = {
      guid: postGuid,
      // if we have PostGuid we don't send discussion Guid anymore because of validation
      // (we assume we must have only one of those PostGuid, DiscussionGuid or ParentGuid)
      discussionGuid: postGuid ? null : discussionGuid,
      parentGuid: conversation.ParentMessage, // for the moment is Parent Conversation Guid
      isPublished: postGuid ? squareActivityModel.ActivityQualDetail.IsPublished : null,
    };
    return createOrUpdateDiscussionPostRequest;
  }

  private prepareCreateOrUpdateQuestionRequest(
    probingQuestion: IProbeQuestionModel,
    postGuid: string,
  ): ICreateOrUpdateQuestionRequest {
    const createOrUpdateQuestionRequest: ICreateOrUpdateQuestionRequest = {
      guid: probingQuestion.guid,
      postGuid: probingQuestion.guid ? null : postGuid, // if we have Guid is an update
      type: probingQuestion.questionType,
      text: probingQuestion.question,
      sortOrder: probingQuestion.sortOrder,
      isOptional: !probingQuestion.answerRequired,
    };
    return createOrUpdateQuestionRequest;
  }

  private prepareCreateOrUpdateMessageRequest(
    conversation: IForumConversation,
    squareActivityModel: SquareActivityModel,
    discussionPostGuid: string,
  ): ICreateOrUpdateMessageRequest {
    const messageGuid: string = squareActivityModel?.discussionModel?.post?.message?.guid;
    if (conversation.ConversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual) {
      conversation.Message = '';
    }

    const createOrUpdateDiscussionPostRequest: ICreateOrUpdateMessageRequest = {
      guid: messageGuid,
      postGuid: messageGuid ? null : discussionPostGuid,
      text: conversation.Message,
      caption: conversation.Caption,
    };
    return createOrUpdateDiscussionPostRequest;
  }

  private prepareCreateOrUpdateDiscussionActivityRequest(
    discussionGuid: string,
    squareActivityModel: SquareActivityModel,
    conversation: IForumConversation,
  ): ICreateOrUpdateDiscussionActivityRequest {
    // Forum = 1, //text focused
    // Chat,
    // Titles,
    // Centered // visual focused
    let appearance: number = null;
    switch (conversation.ConversationFocusType) {
      case this.serverConstants.conversationFocusTypeConstants.visual:
        appearance = this.serverConstants.appearanceConstants.centered;
        break;
      case this.serverConstants.conversationFocusTypeConstants.text:
        appearance = this.serverConstants.appearanceConstants.forum;
        break;
      default:
        appearance = null;
        break;
    }

    const createOrUpdateDiscussionActivityRequest: ICreateOrUpdateDiscussionActivityRequest =
    {
      guid: null, // if we have activityGuid and/Or discussionGuid is enough to update discussionActivity
      activityGuid: squareActivityModel.ActivityQualDetail.ActivityGuid, // we update it by ActivityGuid
      discussionGuid,
      communicationGuid:
        squareActivityModel.ActivityQualDetail.CommunicationGuid,
      appearance,
      contributionType:
        squareActivityModel.ActivityQualDetail.ContributionType, // enum ActivityContributionType
      visibilityType: squareActivityModel.ActivityQualDetail.Visibility || 0, // enum ActivityVisibilityType
      moderatorCuration:
        squareActivityModel.ActivityQualDetail.ModeratorCuration || false,
      isOneByOne:
        squareActivityModel.ActivityQualDetail.IsOneByOne || false,
    };
    return createOrUpdateDiscussionActivityRequest;
  }

  private prepareCreateStimulusRequest(
    stimulus: IUploadedItem,
    stimulusIndex: number,
    messageGuid?: string,
    questionGuid?: string,
    answerGuid?: string,
    caption?: string,
  ): ICreateStimulusRequest {
    return {
      messageGuid,
      questionGuid,
      answerGuid,
      type: stimulus.type,
      caption,
      mimeType: stimulus.file?.type,
      sortOrder: stimulusIndex,
      socialStimulusId: stimulus.id,
      thumbnailUrl: stimulus.thumbnailUrl,
      name: stimulus.file?.name,
    };
  }

  private async createStimulusAsync(
    stimulus: IUploadedItem,
    stimulusIndex: number,
    messageGuid?: string,
    questionGuid?: string,
    answerGuid?: string,
    caption?: string,
  ): Promise<ICreateStimulusResponse> {
    // for now, only the first stimulus can have a caption
    const stimulusCaption = stimulusIndex === 0 ? caption : '';
    const request = this.prepareCreateStimulusRequest(stimulus, stimulusIndex, messageGuid, questionGuid, answerGuid, stimulusCaption);
    return await this.discussionService.createStimulus(request);
  }

  public async createOrUpdateDiscussionActivity(discussionGuid: string, activityGuid: string, visibilityType: number,
    contributionType: number, title: string, isOneByOne: boolean): Promise<string> {
    const createOrUpdateDiscussionRequest: ICreateOrUpdateDiscussionRequest = {
      title,
      guid: discussionGuid,
    };
    const discussionResponse = await this.discussionService.createOrUpdateDiscussion(createOrUpdateDiscussionRequest);

    // add DiscussionActivity
    const createOrUpdateDiscussionActivityRequest: ICreateOrUpdateDiscussionActivityRequest = {
      discussionGuid: discussionResponse.guid,
      activityGuid,
      visibilityType,
      contributionType,
      moderatorCuration: false,
      isOneByOne,
    };
    await this.discussionService.createOrUpdateDiscussionActivity(createOrUpdateDiscussionActivityRequest);

    return discussionResponse.guid;
  }

  public async saveDiscussionActivity(
    conversation: IForumConversation,
    probeQuestions: IProbeQuestionModel[],
    squareActivityModel: SquareActivityModel,
  ): Promise<string> {
    const dataConversation = this.prepareConversation(
      conversation,
      probeQuestions,
    );

    const createOrUpdateDiscussionRequest =
      this.prepareCreateOrUpdateDiscussionRequest(
        conversation,
        squareActivityModel,
      );

    const discussionResponse =
      await this.discussionService.createOrUpdateDiscussion(
        createOrUpdateDiscussionRequest,
      );
    if (squareActivityModel.discussionModel) {
      squareActivityModel.discussionModel.discussionGuid =
        discussionResponse.guid;
    }

    // add DiscussionActivity
    const createOrUpdateDiscussionActivityRequest =
      this.prepareCreateOrUpdateDiscussionActivityRequest(
        discussionResponse.guid,
        squareActivityModel,
        conversation,
      );
    const discussionActivityResponse =
      await this.discussionService.createOrUpdateDiscussionActivity(
        createOrUpdateDiscussionActivityRequest,
      );

    // Add DiscussionPost
    const createOrUpdatePostRequest = this.prepareCreateOrUpdatePostRequest(
      conversation,
      discussionResponse.guid,
      squareActivityModel,
    );
    const postResponse = await this.discussionService.createOrUpdatePost(
      createOrUpdatePostRequest,
    );
    if (squareActivityModel.discussionModel?.post) {
      squareActivityModel.discussionModel.post.guid = postResponse.guid;
    }

    // Add DiscussionPostMessage
    const createOrUpdateMessageRequest =
      this.prepareCreateOrUpdateMessageRequest(
        conversation,
        squareActivityModel,
        postResponse.guid,
      );
    const messageResponse = await this.discussionService.createOrUpdateMessage(
      createOrUpdateMessageRequest,
    );
    if (squareActivityModel.discussionModel?.post?.message) {
      squareActivityModel.discussionModel.post.message.guid = postResponse.guid;
    }

    // remove Questions
    const removedQuestionsGuids = this.getRemovedQuestions(
      squareActivityModel.discussionModel.post?.questions,
      probeQuestions,
    );
    await this.removeQuestions(removedQuestionsGuids.map((q) => q.guid));

    // Add DiscussionPost Questions
    if (probeQuestions && probeQuestions.length > 0) {
      for (const probeQuestion of probeQuestions) {
        const createOrUpdateQuestionRequest =
          this.prepareCreateOrUpdateQuestionRequest(
            probeQuestion,
            postResponse.guid,
          );
        await this.discussionService.createOrUpdateQuestion(
          createOrUpdateQuestionRequest,
        );
      }
    }

    // update the stimuli for the Post
    let newAttachments = conversation.Attachments.filter((a) => a.status === 2);

    // When focusType is visual make sure existing attachments are removed
    // and no new attachments are created.
    if (conversation.ConversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual) {
      const existingAttachmentGuids = conversation.Attachments.filter((a) => !newAttachments.includes(a)).map((a) => a.guid);
      dataConversation.RemovedAttachment.push(...existingAttachmentGuids);
      conversation.Attachments = [];
      newAttachments = [];
    }

    await this.removeStimuli([...dataConversation.RemovedStimuli, ...dataConversation.RemovedAttachment]);

    const newPhotoVideoStimuli = conversation.Stimuli.filter((s) => s.status === 2);
    const newStimuli = [...newPhotoVideoStimuli, ...newAttachments];
    if (newStimuli?.length) {
      for (let i = 0; i < newStimuli.length; i++) {
        const createStimulusResponse = await this.createStimulusAsync(newStimuli[i], i, messageResponse.guid, null, null, conversation.Caption);

        if (createStimulusResponse && createStimulusResponse.guid && createStimulusResponse.uploadUrl) {
          const isPhoto = newStimuli[i].type === this.serverConstants.stimulusTypeConstants.photo;
          const isAttachment = newStimuli[i].type === this.serverConstants.stimulusTypeConstants.attachment;

          if (isPhoto || isAttachment) {
            this.handleUploadBlobStimulus(createStimulusResponse, newStimuli[i], isPhoto);
          } else if (newStimuli[i].type === this.serverConstants.stimulusTypeConstants.video) {
            this.handleUploadVideoStimulus(createStimulusResponse, newStimuli[i]);
          }
        }
      }
    }

    // When trying to serialize stimuli, circular reference happens because of the videoPlayer property
    dataConversation.Stimuli.forEach((stimulus) => {
      if (stimulus.videoPlayer) {
        stimulus.videoPlayer = undefined;
      }
      if (stimulus.fileContentToBeRemoved) {
        stimulus.file = undefined;
      }
    });

    // Publish the Post, finalize the flow
    await this.discussionService.createOrUpdatePost({
      guid: postResponse.guid,
      isPublished: true,
    });

    return discussionActivityResponse.discussionGuid;
  }

  private removeStimuli(removeStimuliGuids: string[]) {
    const promises = removeStimuliGuids.map((stimulusGuid) => {
      const removeStimulusRequest: IRemoveStimulusRequest = {
        stimulusGuid,
      } as IRemoveStimulusRequest;
      return this.discussionService.removeStimulus(removeStimulusRequest);
    });
    return this.$q.all(promises);
  }

  private removeQuestions(removeQuestionGuids: string[]) {
    const promises = removeQuestionGuids.map((questionGuid) => {
      const removeQuestionRequest: IRemoveQuestionRequest = {
        questionGuid,
      } as IRemoveQuestionRequest;
      return this.discussionService.removeQuestion(removeQuestionRequest);
    });
    return this.$q.all(promises);
  }

  private handleUploadBlobStimulus(
    createStimulusResponse: ICreateStimulusResponse,
    stimulus: IUploadConversationStimulus | IUploadConversationAttachment,
    isPhoto: boolean) {
    this.logger.info('Uploading stimulus', createStimulusResponse.guid);
    this.fileStorageService.uploadBlobStimulus(
      createStimulusResponse,
      stimulus.file,
      true,
    );
    // stimulus.file = undefined; //we do not hide it till save
    if (isPhoto) {
      (stimulus as IUploadConversationStimulus).fileContentToBeRemoved = true;
    }
    stimulus.url = createStimulusResponse.uploadUrl.split('?')[0];
    stimulus.thumbnailUrl = stimulus.type === this.serverConstants.stimulusTypeConstants.photo
      ? this.forumService.getThumbnailUrl(stimulus.url)
      : '';
    stimulus.id = createStimulusResponse.guid;
  }

  private handleUploadVideoStimulus(
    createStimulusResponse: ICreateStimulusResponse,
    stimulus: IUploadConversationStimulus,
  ) {
    const uploadVideoRequest: IVideoLink = {
      Id: createStimulusResponse.guid,
      Url: createStimulusResponse.uploadUrl,
      IsDiscussionNew: true,
    };
    if (this.muxService.MuxServiceEnabled) {
      this.muxService.uploadVideo(uploadVideoRequest, stimulus.file)
        .then(async () => {
          await this.discussionService.updateStimulus({
            guid: stimulus.id,
            status: this.serverConstants.stimuliUploadStateConstants.encoding,
          });
        });
      // We do not put files to result, instead, we put stimuli with ID
      stimulus.fileContentToBeRemoved = true;
      stimulus.id = createStimulusResponse.guid;
    } else {
      this.fileStorageService.uploadVideo(
        uploadVideoRequest,
        stimulus.file,
      ).then(async () => {
        await this.discussionService.updateStimulus({
          guid: stimulus.id,
          status: this.serverConstants.stimuliUploadStateConstants.encoding,
        });
      });
      stimulus.fileContentToBeRemoved = true;
      stimulus.id = createStimulusResponse.guid;
      stimulus.url = createStimulusResponse.uploadUrl.split('?')[0];
      stimulus.thumbnailUrl = this.forumService.getThumbnailUrl(stimulus.url);
    }
  }

  private prepareConversation(
    conversation: IForumConversation,
    probeQuestions: IProbeQuestionModel[],
  ) {
    let newConversationStimuli: IUploadConversationStimulus[] = [];
    let newConversationAttachments: IUploadConversationAttachment[] = [];
    let removedConversationStimuliGuids: string[] = [];
    let removedConversationAttachmentGuids: string[] = [];
    const removedConverstationProbeQuestionGuids: string[] = [];

    removedConversationStimuliGuids = this.getRemovedFilesGuid(
      conversation.Stimuli,
    );
    removedConversationAttachmentGuids = this.getRemovedFilesGuid(
      conversation.Attachments,
    );
    newConversationStimuli = this.getNewFiles(conversation.Stimuli);
    newConversationAttachments = this.getNewFiles(conversation.Attachments);

    // TODO: maybe refactor this too. For now we focus only on stimuli and attachments.
    if (
      probeQuestions &&
      probeQuestions.length > 0 &&
      conversation.ProbeQuestions
    ) {
      probeQuestions.forEach((probeQuestion) => {
        const index = conversation.ProbeQuestions.findIndex(
          (convProbeQuestion) => convProbeQuestion.guid === probeQuestion.guid,
        );

        if (probeQuestion && index < 0) {
          removedConverstationProbeQuestionGuids.push(probeQuestion.guid);
        }
      });
    }
    const dataconversation = {
      // Add query
      ActivityGuid: conversation.ActivityGuid,
      ConversationGuid: conversation.Guid,
      Title: conversation.Title,
      Message: conversation.Message,
      Caption: conversation.Caption,
      ThankYouMessage: conversation.ThankYouMessage,
      RemovedStimuli: removedConversationStimuliGuids,
      RemovedAttachment: removedConversationAttachmentGuids,
      RemovedProbeQuestions: removedConverstationProbeQuestionGuids,
      ConversationFocusType: conversation.ConversationFocusType,
      ContributionType: conversation.ContributionType,
      Stimuli: newConversationStimuli,
      Attachments: newConversationAttachments,
      ProbeQuestions: conversation.ProbeQuestions
        ? conversation.ProbeQuestions
        : probeQuestions,
      ParentMessage: conversation.ParentMessage,
    };

    return dataconversation;
  }

  private getRemovedFilesGuid(files: IUploadedItem[]) {
    const removedStatus =
      this.serverConstants.conversationStimuliStatusConstants.removed;
    return _.filter(
      files,
      (stimulus: IUploadedItem) =>
        stimulus.guid && stimulus.status === removedStatus,
    ).map((stimulus: IUploadedItem) => stimulus.guid);
  }

  private getRemovedQuestions(
    questions: IQuestionViewModel[],
    probeQuestions: IProbeQuestionModel[],
  ) {
    return _.filter(
      questions,
      (question) => !_.find(probeQuestions, (q) => q.guid === question.guid),
    );
  }

  private getNewFiles(files: IUploadedItem[]): IUploadedItem[] {
    const newFiles: IUploadedItem[] = [];
    _.forEach(files, (stimulus: IUploadedItem) => {
      if (
        stimulus.status ===
        this.serverConstants.conversationStimuliStatusConstants.new &&
        !stimulus.guid
      ) {
        newFiles.push(stimulus);
      }
    });
    return newFiles;
  }
}

export interface IStimulus {
  ThumbnailUrl: string;
  Id: string;
  Guid: string;
  Type: number;
  file?: File;
  type?: string;
  videoId?: string;
  Thumbnail?: string;
  Value?: string;
}

export interface IStimuliFile {
  Type: number;
  ThumbnailUrl: string;
  Value?: string;
}
