'use strict';

import { NewsCardController } from './newsCardChannel.controller';

const template = require('./newsCardChannel.html');

angular
  .module('insitesApp.squarecommunicationWizard')
  .component('newsCardChannel', {
    templateUrl: template,
    controller: NewsCardController,
    controllerAs: 'vm',
    require: {
      wizardStep: '^^iscWizardStep',
    },
    bindings: {
      description: '@',
      channelType: '<',
      timeZone: '<',
      communication: '<',
      isReadOnly: '<',
    },
  });
