'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';
const appRunObserverLandingPage = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'root.observerlandingpage',
    config: {
      url: 'client/:clientGuid/observerhome?activate?',
      title: 'Home',
      views: {
        '': {
          component: 'observerLandingPageComponent',
        },
        'sidenav': {
          component: 'homeSidenavComponent',
        },
      },
      disallowRoles: ['Human8', 'ProfessionalAdmin', 'ClientAdmin', 'ClientEditor'],
    },
  }]);
};

appRunObserverLandingPage.$inject = ['routerHelper', 'authService'];

export default appRunObserverLandingPage;
