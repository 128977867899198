'use strict';



const ToSentenceCase = (capitalizeFilter) => (input) => {
  if (!input) {
    return input;
  }
  const inputString = input.toString();
  const parts = inputString.split(/(?=[A-Z])/);
  let output = capitalizeFilter(parts[0]);
  for (let i = 1; i < parts.length; i++) {
    output += ` ${parts[i].toLowerCase()}`;
  }
  return output;
};

ToSentenceCase.$inject = ['capitalizeFilter'];

angular.module('insitesApp.layout')
  .filter('toSentenceCase', ToSentenceCase);
