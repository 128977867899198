'use strict';

import { SelectedSquareFactory } from './../../core/selectedsquare.factory';
import { SegmentationService } from './../../core/dataservices/segmentation.service';
import { DownloadService } from './../../core/services/download.service';
import { SquareService } from './../../core/dataservices/square.service';
import { DateFormatService } from './../../core/services/dateformat.service';
import { IKeySegmentation, IKeySegment } from './../../core/dataservices/dataservice.contracts.d';
import { PlatformHealthService } from '../../core/dataservices/platformhealth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { IHealthFilterData } from '../../core/contracts/health.contract';
import { HealthFilterDataItem } from '../../isc-components/isc-health-filter/healthFilterDataItem';
import { HealthExportParams } from '../../core/contracts/download.contract';
export class SquareHealthController {
  static $inject = ['$stateParams', 'spinnerservice', 'squareservice', '_', '$q', 'platformhealthservice',
    'downloadservice', 'toggleableservice', 'serverConstants', 'segmentationservice', 'dateFormatService', 'selectedSquareFactory'];

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private spinnerservice: SpinnerService,
    private squareservice: SquareService,
    private _: _.LoDashStatic,
    private $q: ng.IQService,
    private platformhealthservice: PlatformHealthService,
    private downloadservice: DownloadService,
    private toggleableservice,
    private serverConstants,
    private segmentationservice: SegmentationService,
    private dateFormatService: DateFormatService,
    private selectedSquareFactory: SelectedSquareFactory,
  ) {
  }

  filterData: IHealthFilterData = new HealthFilterDataItem();
  filterDataChips;

  memberStatusModel = {};
  segmentsFilterModel = {
    SegmentationGuid: [],
    RequestType: 2,
    Filter: undefined,
  };
  graphFilterUniqueLoginsModel = {};
  graphFilterModel = {};
  segmentChips = [];
  initial = {};
  selectedSegmentation: IKeySegmentation = {
    Id: 0,
    Guid: '',
    SquareGuid: '',
    Question: '',
    QuestionGuid: '',
    IsFavorite: false,
    IsSourceQuestionDeleted: false,
    KeySegments: [],
  };
  hasSegmentChips = false;
  members = 0;
  selectedSegment = [];
  keySegmentList: IKeySegment[] = [];
  keySegmentTableModel = [];
  keySegmentations: IKeySegmentation[] = [];
  exportParams: HealthExportParams = new HealthExportParams();
  graphLoginTable = [];
  loginLoaded = false;
  uniqueLoginLoaded = false;
  memberStatusTable;
  graphdata = {
    data: [],
    title: '',
    snippet: '',
    total: 0,
  };
  graphdataUnique = {
    data: [],
    title: '',
    snippet: '',
    total: 0,
  };
  activeMembersTooltip = 'A participant member is defined as ‘Active’ in case at least 1 research activity was '
    + 'completed out of the last 5 research activities he / she was invited for.';

  getPlaceholder(selectBox) {
    switch (selectBox) {
      case 'membership': return 'Select membership status';
      case 'segmentation': return 'Select key segmentation';
      case 'segment': return 'Select key segments';
      default:
        return '';
    }
  }

  getSegmentsToModel() {
    const arr = [];
    this._.each(this.selectedSegment, (item) => {
      const index = this._.findIndex(this.keySegmentList, (o) => o.Answer === item);
      arr.push(this.keySegmentList[index].Guid);
    });
    return arr;
  }

  async reloadHealthData() {
    this.toggleableservice.toggle('1');
    this.initRequestObjects();
    const getHealthDataPromises = [];
    this.keySegmentTableModel = [];
    this.graphLoginTable = [];
    this.loginLoaded = false;
    this.uniqueLoginLoaded = false;

    if (!this.exportParams) {
      this.exportParams = new HealthExportParams();
    }
    this.exportParams.selectedRoles = this.filterData.SelectedRoles;
    this.exportParams.selectedStatuses = this.filterData.SelectedMembershipStatus;
    this.exportParams.selectedSegments = this.filterData.SelectedSegments;
    this.exportParams.selectedSegmentsOption = this.filterData.SelectedSegmentsOption;
    this.exportParams.startDate = this.filterData.StartDate;
    this.exportParams.endDate = this.filterData.EndDate;

    const memberStatusPromise = await this.platformhealthservice.getHealthData(angular.copy(this.memberStatusModel)).then((response) => {
      response = response.HealthDataResponses[0];
      this.memberStatusTable = response;
      if (this._.isArrayLike(response.Metrics)) {
        response.Metrics.forEach((e) => {
          e.Count = e.Count !== 0 ? e.Count : null;
          e.Active = e.Active !== 0 ? e.Active : null;
        });
        this.members = this._.sumBy<any>(response.Metrics, (metric) => metric.Count);
      }
    });

    this.segmentsFilterModel.SegmentationGuid = this._.map(this.keySegmentations, 'Guid');
    const segmentationPromise = await this.platformhealthservice.getHealthData(angular.copy(this.segmentsFilterModel)).then((response) => {
      this._.each(response.HealthDataResponses, (item: any) => {
        this.keySegmentTableModel.push(item);
      });
    });

    const loginsPromise = await this.platformhealthservice.getHealthData(angular.copy(this.graphFilterModel)).then((response) => {
      response = response.HealthDataResponses[0];
      this.graphdata.title = response.Title;
      this.graphdata.snippet = response.Title;
      this.graphdata.total = response.Value;
      this._.each<any[]>(response.Metrics, (item: any) => {
        this.graphdata.data.push([item.Metric, item.Count]);
      });
    }).then(() => {
      this.loginLoaded = true;
    });

    const uniqueLoginsPromise = await this.platformhealthservice.getHealthData(angular.copy(this.graphFilterUniqueLoginsModel)).then((response) => {
      response = response.HealthDataResponses[0];
      this.graphdataUnique.title = response.Title;
      this.graphdataUnique.snippet = response.Title;
      this.graphdataUnique.total = response.Value;
      this._.each<any[]>(response.Metrics, (item: any) => {
        this.graphdataUnique.data.push([item.Metric, item.Count]);
      });
    }).then(() => {
      this.uniqueLoginLoaded = true;
    });

    getHealthDataPromises.push(memberStatusPromise);
    getHealthDataPromises.push(segmentationPromise);
    getHealthDataPromises.push(loginsPromise);
    getHealthDataPromises.push(uniqueLoginsPromise);
    this.spinnerservice.show('loading');
    this.$q.all(getHealthDataPromises)
      .finally(() => {
        this.spinnerservice.hide('loading');
      });
  }

  initRequestObjects() {
    this.graphdata = {
      data: [],
      title: '',
      snippet: '',
      total: 0,
    };
    this.graphdataUnique = {
      data: [],
      title: '',
      snippet: '',
      total: 0,
    };
    const filter = {
      Roles: this.filterData.SelectedRoles,
      RegistrationStatuses: this.filterData.SelectedMembershipStatus,
      SegmentGuids: this.filterData.SelectedSegments,
      SegmentOptions: this.filterData.SelectedSegmentsOption,
      StartDate: this.filterData.StartDate,
      EndDate: this.filterData.EndDate,
    };

    this.memberStatusModel = {
      RequestType: 1,
      Filter: filter,
    };
    this.segmentsFilterModel.RequestType = 2;
    this.segmentsFilterModel.Filter = filter;
    this.graphFilterUniqueLoginsModel = {
      RequestType: 4,
      Filter: filter,
    };
    this.graphFilterModel = {
      RequestType: 3,
      Filter: filter,
    };
  }
}
