'use strict';

import { MappingService } from './mapping.service';
import { SegmentationService } from './../dataservices/segmentation.service';
import { DownloadService } from './download.service';
import { ActivityDataService } from './activityData.service';
import { DiscussionDataService } from './discussionData.service';
import { MemberService } from './member.service';
import { ClipboardService } from './clipboard.service';
import { ActivityFilterService } from './activityFilter.service';
import { ActivityHelperService } from './activityHelper.service';
import { SegmentationHelperService } from './segmentationHelper.service';
import { HelperService } from './helper.service';
import { DateFormatService } from './dateformat.service';
import { NavigationHelperService } from '../../../../node_modules/isc-ui/src/services/navigation/navigationHelperService';
import { ConversationEventsService } from './conversationEvents.service';
import { NotificationsService } from './notifications';
import { ConversationService } from './conversation.service';
import { MediaService } from './media.service';

import { SelectedSquareForumsService } from './selectedSquareForumsService';
import { SpinnerService } from './spinner.service';
import { PinnedItemsService } from './pinned-items.service';
import { ExportConfigService } from './exportconfig.service';
import './paramresolver';
import { PoboService } from './pobo.service';

angular
  .module('insitesApp.core')
  .service('activitydataservice', ActivityDataService)
  .service('discussionDataService', DiscussionDataService)
  .service('memberService', MemberService)
  .service('ClipboardService', ClipboardService)
  .service('activityFilterService', ActivityFilterService)
  .service('activityHelperService', ActivityHelperService)
  .service('segmentationHelperService', SegmentationHelperService)
  .service('helperservice', HelperService)
  .service('downloadservice', DownloadService)
  .service('dateFormatService', DateFormatService)
  .service('navigationHelperService', NavigationHelperService)
  .service('conversationEventsService', ConversationEventsService)
  .service('notifications', NotificationsService)
  .service('selectedSquareForumsService', SelectedSquareForumsService)
  .service('conversationService', ConversationService)
  .service('segmentationService', SegmentationService)
  .service('pinnedItemsService', PinnedItemsService)
  .service('mediaService', MediaService)
  .service('exportconfigservice', ExportConfigService)
  .service('spinnerservice', SpinnerService)
  .service('poboService', PoboService)
  .service('mappingService', MappingService);
