'use strict';

import { ForumRoomModel } from './forumRoomModel';
import * as _ from 'lodash';

export class EditRoomDialogController {
  static $inject = ['$mdDialog', 'room', 'selectedPage', 'validationConstants'];

  roomInEditMode: ForumRoomModel;
  editRoom;
  visibility = [{
    value: true,
    label: 'Active',
  }, {
    value: false,
    label: 'Closed',
  }];
  pageRooms;
  constructor(
    private $mdDialog: ng.material.IDialogService,
    public room,
    public selectedPage,
    private validationConstants,
  ) {
    this.roomInEditMode = new ForumRoomModel(room.Name, room.IsVisible);
    this.pageRooms = _.without(this.selectedPage.Rooms, this.room);
  }


  submit() {
    this.room.Name = this.roomInEditMode.Name;
    this.room.IsVisible = this.roomInEditMode.IsVisible;
    this.$mdDialog.hide(this.room);
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
