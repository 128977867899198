'use strict';

import { JobProgressController } from './job-progress.controller';
import { HttpService } from './../dataservices/http.service';
import { JobStatusChecker } from './jobStatusChecker';
const jobProgressTemplate = require('./job-progress.html');

export class JobService {
  static $inject = ['httpservice', '$mdDialog', '$timeout'];

  constructor(
    private httpservice: HttpService,
    private $mdDialog: ng.material.IDialogService,
    private $timeout: ng.ITimeoutService,
  ) {
  }

  public getJobDetails(jobId: string) {
    return this.httpservice.get<IJobDetails>({
      url: '/JobService/GetJobDetails',
      params: {
        jobId,
      },
    }).then((response) => response.data);
  }

  public sendDigest(jobId: string) {
    return this.httpservice.post({
      url: '/JobService/SendJobDigest',
      params: {
        jobId,
      },
    });
  }

  async showJobProgressDialog(
    jobId: string | Promise<string> | ng.IPromise<string>,
    title: string,
    canSendDigest: boolean = true,
    canEmitOnClose: boolean = false) {
    await this.$mdDialog.show({
      controller: JobProgressController,
      controllerAs: 'vm',
      templateUrl: jobProgressTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        // Avoid having $mdDialog to resolve our promise
        jobId: {
          jobId,
        },
        title,
        canSendDigest,
        canEmitOnClose,
      },
      multiple: true,
    });
  }

  getJobStatusCheckerFor(jobId: string): JobStatusChecker {
    return new JobStatusChecker(jobId, this, this.$timeout);
  }

  private async pollResetStatus(jobId: string, pollPromise = null) {
    await this.getJobDetails(jobId);
    pollPromise = this.$timeout(() => {
      this.pollResetStatus(jobId, pollPromise);
    }, 1000);
  }
}

export interface IJobDetails {
  State: 'Enqueued' | 'Scheduled' | 'Failed' | 'Processing' | 'Succeeded' | 'Deleted';
  Result?: string;
  Reason?: string;
  ExceptionMessage?: string;
}
