'use strict';

import { ActivityQualResearchConfigController } from './activityQualResearchConfig.controller';

const template = require('./activityQualResearchConfig.html');

export const ActivityQualResearchConfigComponent: ng.IComponentOptions = {
  controller: ActivityQualResearchConfigController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    startingStep: '<',
    activityType: '<?',
  },
};
