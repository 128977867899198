'use strict';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/combineLatest';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import * as _ from 'lodash';

export class CtaValidation {

  static $inject = ['$timeout'];

  constructor(
    private $timeout: ng.ITimeoutService,
  ) {
  }

  setupValidation(
    scope: ng.IScope,
    getCtaText: () => ng.INgModelController,
    getCtaLink: () => ng.INgModelController,
  ) {
    const ctaTextObervable = this.observeScope<ng.INgModelController>(scope, getCtaText);
    const ctaLinkObervable = this.observeScope<ng.INgModelController>(scope, getCtaLink);
    const subscription = ctaTextObervable
      .combineLatest(ctaLinkObervable)
      .first()
      .mergeMap(([ctaText, ctaLink]) => {
        const ctaTextValues = this.observeModelValues(scope, ctaText);
        const ctaLinkValues = this.observeModelValues(scope, ctaLink);
        return ctaTextValues.combineLatest(ctaLinkValues).map(([textValue, linkValue]) => ({
          ctaText,
          ctaLink,
          enableValidation: !!(textValue || linkValue && linkValue.trim() !== 'https://'),
        }));
      })
      .distinctUntilChanged((a, b) => a === b, (x) => x.enableValidation)
      .subscribe((e) => {
        this.storeValidators(e.ctaText);
        this.storeValidators(e.ctaLink);
        if (e.enableValidation) {
          this.addValidators(e.ctaText);
          this.addValidators(e.ctaLink);
        } else {
          this.removeValidators(e.ctaText);
          this.removeValidators(e.ctaLink);
        }
      });

    return () => subscription.unsubscribe();
  }

  private storeValidators(model: ng.INgModelController) {
    const anyModel = model as any;
    if (!anyModel.__validatorsBackup) {
      anyModel.__validatorsBackup = angular.copy(model.$validators);
    }
  }

  private removeValidators(model: ng.INgModelController) {
    for (const key in model.$validators) {
      model.$setValidity(key, true);
      delete model.$validators[key];
    }
    model.$setTouched();
    model.$validate();
  }

  private addValidators(model: ng.INgModelController) {
    const anyModel = model as any;
    angular.copy(anyModel.__validatorsBackup, model.$validators);
    model.$validate();
  }

  private observeScope<T>(scope: ng.IScope, expression): Observable<T> {
    return Observable.create((observer) => scope.$watch(expression, (value) => {
      observer.next(value);
    }));
  }

  private observeModelValues(scope: ng.IScope, model: ng.INgModelController): Observable<string> {
    return Observable.create((observer) => {
      const listener = () => {
        observer.next(model.$viewValue);
      };
      this.$timeout(() => listener());
      model.$viewChangeListeners.push(listener);
      return () => {
        _.pull(model.$viewChangeListeners, listener);
      };
    });
  }
}
