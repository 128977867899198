'use strict';

import { ServerConstants } from '../../../../../../core/serverconstants';

export class CheckmarketIdController {
  static $inject = ['$scope', 'serverConstants'];
  constructor(
    private $scope: ng.IScope,
    private serverConstants: ServerConstants,
  ) { }

  urlMaxLength: number;
  model;
  checkmarketIdLink;
  form;
  validId;
  checkmarketRegex = /.*[&?]e=\d+(?:&.*|$)/;
  isDisabled;

  $onInit() {
    this.urlMaxLength = this.serverConstants.validationConstants.urlTechnicalMaxLength;
    this.checkmarketIdLink = this.model.InlineSurveyUri;
    this.$scope.$watch('vm.model.InlineSurveyUri', () => {
      this.model.SurveyId = this.checkId(this.model.InlineSurveyUri);
    });
  }

  checkId(id) {
    if (id !== null) {
      const x = id.match(/e=\d+/);
      if (x !== null) {
        const part = x[0].split('e=');
        return part[1];
      }
    }
  }
}
