'use strict';

import { ProgramInfoController } from './programinfo.controller';
const template = require('./programinfo.html');
require('./programinfo.scss');

export const ProgramInfoComponent: ng.IComponentOptions = {
  templateUrl: template,
  controller: ProgramInfoController,
  controllerAs: 'vm',
};
