'use strict';

import { SquareService } from '../../../../core/dataservices/square.service';
import { DefaultColors } from '../design.constants';
import { ISquareDesignDetailItem } from '../designModel';
export class SquareConfigDesignColorsController {
  static $inject = ['defaultColors', 'logger', 'squareservice', '$stateParams', '_'];
  constructor(
    private defaultColors: DefaultColors,
    private logger: Logger,
    private squareservice: SquareService,
    private $stateParams: ng.ui.IStateParamsService,
    private _: _.LoDashStatic,
  ) { }
  form;
  isSaving = false;
  squareDesignData: ISquareDesignDetailItem;
  originalColors;
  pages = [];
  baseColor1 = null;
  navigationBarLeft = null;
  navigationBarRight = null;
  navigationBarText = null;

  backgroundColor = null;
  options = {
    history: false,
    genericPalette: false,
    materialPalette: false,
    hsl: false,
    alphaChannel: false,
    cancel: false,
  };


  $onInit() {
    this.logger.info('Square config - Design Colors Editor activated');
    this.originalColors = this._.cloneDeep(this.squareDesignData);
    this.checkForColors(this.squareDesignData);
  }

  saveButtonClick() {
    if (!this.form.$valid) {
      const errorsByType = this.form.$error;
      this.logger.warning('Not saving because of validation errors.', errorsByType);
    }
  }

  savePage() {
    this.isSaving = true;
    const request = {
      BaseColor1: this.baseColor1 ||  this.baseColor1.trim(),
      NavigationBarLeft: this.navigationBarLeft || this.navigationBarLeft.trim(),
      NavigationBarRight: this.navigationBarRight ||  this.navigationBarRight.trim(),
      NavigationBarText: this.navigationBarText || this.navigationBarText.trim(),
      BackgroundColor: this.backgroundColor || this.backgroundColor.trim(),
      PageColor: this.pages,
    };

    this.squareservice.saveSquareColors(request)
      .then(() => {
        this.originalColors.PageDesign = this._.cloneDeep(request.PageColor);
        delete request.PageColor;
        this.originalColors.SquareDesign = this._.cloneDeep(request);
        this.form.$setPristine();
        this.logger.success('Colors saved successfully');
      }, (error) => {
        if (error.status === 400) {
          const data = error.data;

          // Group by property name in case there is more than 1 error for that property
          // Ideally we should already group them in the backend
          const grouped = this._.groupBy(data.ValidationErrors, 'PropertyName');
          this._.forEach(grouped, (item, key) => {
            if (this.form[key]) {
              this.form[key].$setValidity('serverErrors', false);
            } else {
              this.form.pageColors[key].$setValidity('serverErrors', false);
            }
          });
        }
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  cancelPage() {
    this.checkForColors(this._.cloneDeep(this.originalColors));
    this.clearServerErrors();
    this.form.$setPristine();
  }

  clearServerErrors() {
    // Start by setting server errors to false on the main form
    const workList = [this.form];
    for (const form of workList) {
      if (this.hasServerErrors(form)) {
        // Reset the errors for the current form or control
        form.$setValidity('serverErrors', true);
        // Add each child form or control with errors to the work list
        this._.each(form, (child) => {
          if (this.hasServerErrors(child)) {
            workList.push(child);
          }
        });
      }
    }
  }

  hasServerErrors(formControl) {
    return formControl && formControl.$error && formControl.$error.serverErrors;
  }

  checkForColors(originalColors) {
    const squareBaseColor1 = originalColors.SquareDesign.BaseColor1;
    this.baseColor1 = squareBaseColor1 || this.defaultColors.defaultMainColor;

    const squareNavigationBarLeft = originalColors.SquareDesign.NavigationBarLeft;
    this.navigationBarLeft = squareNavigationBarLeft;
    const squareNavigationBarRight = originalColors.SquareDesign.NavigationBarRight;
    this.navigationBarRight = squareNavigationBarRight;
    const squareNavigationBarText = originalColors.SquareDesign.NavigationBarText;
    this.navigationBarText = squareNavigationBarText;

    const squareBackgroundColor = originalColors.SquareDesign.BackgroundColor;
    this.backgroundColor = squareBackgroundColor || this.defaultColors.defaultBackgroundColor;

    this.pages = originalColors.PageDesign;
    let countDefaultColors = 0;
    this._.each(this.pages,
      (page) => {
        if (page.Color == null) {
          ++countDefaultColors;
          page.Color = this.defaultColors.defaultExtraColors[`color${countDefaultColors}`];
        }
      });
  }
}
