'use strict';

import './router.module';
import { RouterHelperProvider } from './router-helper.provider';
import './../logger';

/* Help configure the state-base ui.router */
angular
  .module('blocks.router')
  .provider('routerHelper', RouterHelperProvider);
