'use strict';

import { ActivityQualBuilderController } from './activityQualBuilder.controller';

const template = require('./activityQualBuilder.html');

export const ActivityQualBuilderComponent: ng.IComponentOptions = {
  controller: ActivityQualBuilderController,
  templateUrl: template,
  controllerAs: 'vm',
  require: {
    wizardStep: '^^iscWizardStep',
  },
  bindings: {
    squareActivity: '=',
    isReadOnly: '<',
    isPublished: '<',
  },
};
