'use strict';

import './activityConfig';
import './activityHealth';
import './activityInfo';
import './activityQualResearchConfig';
import './activityWizardType.module';
import './activityData';
import './activityGallery';
import './activityTextAnalytics';

import routeConfig from './activityWizardType.route';
import { ActivityWizardTypeComponent } from './activityWizardType.component';

angular
  .module('insitesApp.activityWizardType')
  .component('activityWizardType', ActivityWizardTypeComponent)
  .run(routeConfig);

