'use strict';


import { ActivityQualSpecifyController } from './activityQualSpecify.controller';

const template = require('./activityQualSpecify.html');

export const ActivityQualSpecifyComponent: ng.IComponentOptions = {
  controller: ActivityQualSpecifyController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    squareActivity: '=',
    isReadOnly: '<',
    isPublished: '<',
    isSavingUpReward: '<',
  },
  require: {
    wizardStep: '^^iscWizardStep',
  },
};
