'use strict';


import { RouterHelper } from '../../../blocks/router/router-helper';
import { ActivityFilterService } from '../../../core/services/activityFilter.service';

const routeConfig = (routerHelper: RouterHelper, activityFilterService: ActivityFilterService) => {
  routerHelper.configureStates([
    {
      state: 'root.square.activities.quant',
      config: {
        url: `quant/?${activityFilterService.getFilterUrlParts()}?page?limit?order`,
        component: 'activitiesQuantTab',
        title: 'Quantitative research',
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', 'activityFilterService'];

export default routeConfig;

