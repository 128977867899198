'use strict';

declare const InstallTrigger: any;

export class MediaService {
  static $inject = [];
  constructor(
  ) { }

  readonly mobileBreakpoint = 600;
  readonly tabletBreakpoint = 960;

  isMobile = () => window.innerWidth < this.mobileBreakpoint;

  isTablet = () => !this.isMobile()
    && window.innerWidth < this.tabletBreakpoint;

  isDesktop = () => !this.isMobile()
    && !this.isTablet();

  isChrome = () => {
    const w: any = window;
    // https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
    const isChromium = w.chrome;
    const winNav = w.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof w.opr !== 'undefined';
    if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false
    ) {
      return true;
    }
    return false;
  };

  // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  // ATTENTION! This check may not work in the future versions of Firefox:
  // "This feature is non - standard and is not on a standards track.Do not use it on production sites facing the Web: it will not work for every user.
  // There may also be large incompatibilities between implementations and the behavior may change in the future."
  // (https://developer.mozilla.org/en-US/docs/Web/API/InstallTrigger)
  isFirefox = () => typeof InstallTrigger !== 'undefined';

  isBrowserSupported = () => this.isChrome() || this.isFirefox();
}

