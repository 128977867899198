'use strict';

import * as angular from 'angular';

import routeConfig from './activitiesQualTab.route';
import { activitiesQualTab } from './activitiesQualTab.component';

angular
  .module('insitesApp.squareActivities')
  .component('activitiesQualTab', activitiesQualTab)
  .run(routeConfig);
