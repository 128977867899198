'use strict';

import { MappingService } from './../../../../core/services/mapping.service';
import { IConversationEditorConfig } from 'isc-ui';
import { ServerConstants } from './../../../../core/serverconstants';
import { ActivityDataService, IForumConversation } from './../../../../core/services/activityData.service';
import { ActivityService } from './../../../../core/dataservices/activity.service';
import { SelectedSquareFactory } from './../../../../core/selectedsquare.factory';
import { ImageCropService } from '../../../../layout/image-crop/image-crop.service';
import { IStimuliIgnoreFilter } from '../../../../core/filters/stimuli-ignore.filter';
import { SquareActivityModel } from '../../squareActivityModel';
import { ActivityBuildModelBase } from '../common/activityBuildBase.model';
import { ActivityQualBuilderModel } from './activityQualBuilderModel';
import { SelectedActivityFactory } from '../../../../core/selectedactivity.factory';
import { PoboService } from '../../../../core/services/pobo.service';
import { ConfigurationService } from '../../../../core/dataservices/configuration.service';
import { IFileConfigurationResponse } from '../../../../core/contracts/configuration.contracts';
import { ActivityBuildBaseController } from './../common/activityBuildBase.controller';
export class ActivityQualBuilderController extends ActivityBuildBaseController {
  static $inject = [
    'serverConstants',
    'activityservice',
    'activitydataservice',
    '$stateParams',
    'logger',
    'selectedSquareFactory',
    '$scope',
    '$mdDialog',
    'poboService',
    'mappingService',
    '_',
    'labelFactory',
    'imageCropService',
    'selectedActivityFactory',
    'stimuliIgnoreFilter',
    'iscConfigurationService'];

  removeLink;
  communicationChannelConstants;
  displayTabOptions = false;
  invalidFiles = [];
  fileValidationErrors = [];
  form: ng.IFormController;
  stimuliOptions;
  invalidCommunicationFiles = [];
  initialFiles = [];
  isValid = false;

  saveCallback = () => this.updateActivityBuild(false, this);
  resetFormCallback = () => super.resetForm();
  isPublished;
  originalShowOnHomepage;
  originalSticky;
  conversationEditorConfig: IConversationEditorConfig;
  model = new ActivityQualBuilderModel();
  isReadOnly: boolean;
  imageStimuliMaxSizeBytes: number;
  videoStimuliMaxSizeBytes: number;
  fileTypeConstants;
  fileConfiguration: IFileConfigurationResponse[] = [];
  acceptedMimeTypes: string[];
  acceptedMimeTypesString: string;
  acceptedPatterns: string;
  acceptedExtensions: string;

  constructor(
    protected serverConstants: ServerConstants,
    protected activityservice: ActivityService,
    protected activitydataservice: ActivityDataService,
    protected $stateParams: ng.ui.IStateParamsService,
    protected logger: Logger,
    protected selectedSquareFactory: SelectedSquareFactory,
    protected $scope: ng.IScope,
    protected $mdDialog: ng.material.IDialogService,
    protected poboService: PoboService,
    protected mappingService: MappingService,
    protected _: _.LoDashStatic,
    protected labelFactory,
    protected imageCropService: ImageCropService,
    protected selectedActivityFactory: SelectedActivityFactory,
    protected stimuliIgnoreFilter: IStimuliIgnoreFilter,
    protected iscConfigurationService: ConfigurationService,
  ) {
    super(serverConstants, activityservice, activitydataservice, $stateParams, logger, selectedActivityFactory, $scope,
      $mdDialog, poboService, mappingService);
    this.validationConstants = serverConstants.validationConstants;

    this.communicationChannelConstants = serverConstants.communicationChannelConstants;
    this.conversationEditorConfig = {
      showTitle: true,
      showTextEditor: true,
      showCaptionEditor: true,
      messageLabel: '(LabelForumConversationEditorMessage)',
      titleMaxLength: this.validationConstants.conversationTitleMaxLength,
      messageMaxLength: this.validationConstants.qualActivityDescriptionTextareaMaxLength,
      uploadConfig: {
        maxStimuli: serverConstants.validationConstants.conversationStimuliMaxNo,
        maxAttachments: serverConstants.validationConstants.conversationAttachmentMaxNo,
        showImageUpload: true,
        showVideoLink: true,
        showVideoUpload: true,
        showAttachment: true,
      },
    };
  }

  $onInit() {
    this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
    this.iscConfigurationService.getFileConfiguration()
      .then((response) => {
        this.fileConfiguration = response;
        this.imageStimuliMaxSizeBytes = this._.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.image).MaxFileSizeMb * Math.pow(1024, 2);
        this.videoStimuliMaxSizeBytes = this._.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.video).MaxFileSizeMb * Math.pow(1024, 2);

        const { mimeTypes, patterns, extensions } = this.iscConfigurationService
          .getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image, this.fileTypeConstants.video]);
        this.acceptedMimeTypes = mimeTypes;
        this.acceptedMimeTypesString = mimeTypes.toString();
        this.acceptedPatterns = `'${patterns.toString()}'`;
        this.acceptedExtensions = extensions.toString();
      });

    this.$loadData(this.squareActivity);
    this.removeLink = this.wizardStep.linkComponent('builderQual', this);
    this.wizardStep.resolveComponent(['targeting']).then((resolved) => {
      resolved[0].$loadData();
    });
  }

  async $wizardStepLoaded() {
    // Load default fields
    if (!this.model.title) {
      this.model.title = this.selectedActivityFactory.Name;
    }
    if (!this.model.callToActionText) {
      const squareLanguage = await this.selectedSquareFactory.languagePromise;
      const labels = await this.labelFactory.getLabelsLanguage(squareLanguage);
      this.model.callToActionText = labels.getLabelValue('LabelCallToActionTextDefaultResearchActivity');
    }
  }

  protected mapModel(data: SquareActivityModel) {
    this.model = this.mappingService.mapSquareActivityModelForQualActivity(data);
  }

  $loadData(data: SquareActivityModel) {
    this.model = new ActivityQualBuilderModel();
    if (data && data.ActivityQualDetail) {
      this.mapModel(data);
      // If this step isn't complete yet the values shouldn't be taken over from the object in the db.
      // The default values of these booleans should be used in place.
      if (this.$wizardStepIsCompleted()) {
        const qualData = data.ActivityQualDetail;
        this.model.showOnHomepage = qualData.ShowOnHomepage;
        this.originalShowOnHomepage = qualData.ShowOnHomepage;
        this.model.sticky = qualData.Sticky;
        this.originalSticky = qualData.Sticky;
      }
      this.saveNotApplicable = this.squareActivity.ActivityQualDetail.IsPublished;
    }
  }

  removeCommunicationFile(file) {
    this.invalidCommunicationFiles = [];
    const index = this._.indexOf(this.model.communicationStimuli, file);
    if (this.model.communicationStimuli[index].source === this.serverConstants.communicationConstants.stimuliSourceUpload) {
      this.model.communicationStimuli.splice(index, 1);
    } else {
      this.model.communicationStimuli[index].action = this.serverConstants.communicationConstants.stimuliActionDelete;
    }
    const fileIndex = this.model.communicationFiles.map((f) => f.name).indexOf(file.imgUrl.name);
    if (fileIndex >= 0) {
      this.model.communicationFiles.splice(fileIndex, 1);
    }
  }

  isImageFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const imageMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('image') === 0);
      result = imageMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  isVideoFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const videoMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('video') === 0);
      result = videoMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  async validateCommunicationFiles($files, $newFiles, $invalidFiles) {
    if (!$newFiles) {
      return;
    }

    for (let file of $newFiles) {
      this._.pull($files, file);
      this.invalidCommunicationFiles = [];
      const isImageFile = this.isImageFile(file);

      if ((isImageFile && file.size > this.imageStimuliMaxSizeBytes) ||
        (this.isVideoFile(file) && file.size > this.videoStimuliMaxSizeBytes)) {
        file.$errorMessages = file.$errorMessages || {};
        file.$errorMessages.maxSize = true;
        this.invalidCommunicationFiles.push(file);
        $invalidFiles.splice(0);
      } else if (file.$errorMessages) {
        this.invalidCommunicationFiles.push(file);
        $invalidFiles.splice(0);
      } else if (this.isCommunicationStimuliLimitReached()) {
        this.invalidCommunicationFiles.push(file);
        file.$errorMessages = { maxFiles: true };
      } else {
        if (isImageFile) {
          try {
            file = await this.imageCropService.cropStimuli(file);
          } catch (err) {
            // User canceled
            continue;
          }
        }
        this.model.communicationStimuli.push({
          source: this.serverConstants.communicationConstants.stimuliSourceUpload,
          imgUrl: file,
          action: this.serverConstants.communicationConstants.stimuliActionUpload,
          type: file.type,
          hashKey: file.$$hashkey,
        });
        this.model.communicationFiles.push(file);
      }
    }
  }

  isCommunicationStimuliLimitReached() {
    return this.stimuliIgnoreFilter(this.model.communicationStimuli).length > 0;
  }

  getVideoStimulusDisplaySrc(file): string {
    return (typeof file.imgUrl === 'string') ? file.imgUrl : 'images/video-placeholder.png';
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardStepIsCompleted() {
    let result = this.squareActivity
      && this.squareActivity.ActivityQualDetail !== null
      && this.squareActivity.ActivityQualDetail.CardTeaser !== null
      && this.squareActivity.ActivityQualDetail.Title !== null;
    if (this.model.conversationFocusType === this.serverConstants.conversationFocusTypeConstants.text) {
      result = result && this.squareActivity.ActivityQualDetail.Description !== null;
    } else {
      result = result && this.model.stimuli.length > 0;
    }
    return result;
  }

  // eslint-disable-next-line no-unused-vars
  protected additionalConditionForCompletedSteps(model: ActivityBuildModelBase) {
    return true; // no other additional condition for tis type
  }

  $wizardIsValid() {
    if (!this._.isUndefined(this.form)) {
      this.isValid = true;
      let validVisual = true;
      if (this.model.conversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual) {
        validVisual = this.model.stimuli.length > 0 || this.model.stimuli.length > 0;
      }
      const stimuli = this._.filter(this.model.communicationStimuli, (s) =>
        s.action !== this.serverConstants.communicationConstants.stimuliActionDelete);
      return this.form.$valid && validVisual  && (this.model.communicationFiles.length > 0 || stimuli.length > 0);
    }
  }

  async $wizardBeforeNext(reload: boolean) {
    if (!reload) {
      await this.updateActivity(false, this);
    } else {
      await this.updateActivity(true, this);
    }
  }

  // Returns true when changes for the childActivities must be made
  protected hasChanges(): boolean {
    return this.model.showOnHomepage !== this.originalShowOnHomepage || this.model.sticky !== this.originalSticky;
  }

  async updateActivity(reload: boolean, root: this) {
    if (root.squareActivity.ActivityQualDetail?.IsPublished === true) {
      const username = await this.poboService.checkPoboConfirmation(
        root.squareActivity.ActivityQualDetail.OpenPostUsername);
      root.squareActivity.ActivityQualDetail.OpenPostUsername = username;
    }
    await this.updateActivityBuild(reload, root);
  }

  async updateActivityBuild(reload: boolean, root: this) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }
    const datacommunication = {
      ActivityGuid: root.$stateParams.activityGuid,
      Title: root.model.title,
      Message: root.model.cardTeaser,
      Sticky: root.model.sticky,
      StartDateTime: root.squareActivity.Detail.StartDate,
      CallToActionText: root.model.callToActionText,
      StimuliCollection: root.model.communicationStimuli,
      ShowOnHomepage: root.model.showOnHomepage,
      ShowOnPublicPage: root.model.showOnPublicPage,
      Stimuli: root._.map(root.model.communicationFiles, (file) => {
        let index = root._.findIndex(root.model.communicationFiles,
          (f) => f === file || (f.name === file.name && f.size === file.size && f.lastModifiedDate === file.lastModifiedDate));

        if (index === -1) {
          index = root.model.communicationFiles.length;
          root.model.communicationFiles.push(file);
        }
        return index;
      }),
      IsQualActivity: true,
    };

    const conversation: IForumConversation = this.mappingService.mapActivityBuildModelToForumConversation(root.model);
    conversation.ActivityGuid = root.$stateParams.activityGuid;

    const dataActivityQual = {
      ActivityGuid: root.$stateParams.activityGuid,
      CommunicationGuid: '',
      ConversationGuid: '',
      Title: root.model.title,
      Description: root.model.description,
      PrePopulateDescription: root.model.prePopulateDescription,
      ConversationFocusType: root.model.conversationFocusType,
    };

    const communicationData = await root.activityservice.createUpdateActivityConfigCommunication(datacommunication, root.model.communicationFiles);
    dataActivityQual.CommunicationGuid = communicationData.CommunicationGuid;
    const wizardTargeting = await root.wizardStep.resolveComponent('targeting');
    wizardTargeting.communicationGuid = dataActivityQual.CommunicationGuid;

    if (root.model.prePopulateResponse === false) {
      dataActivityQual.PrePopulateDescription = null;
    }
    const conversationData = await root.activitydataservice.saveConversation(
      conversation,
      root.model.newAttachmentFiles);
    dataActivityQual.ConversationGuid = conversationData.ConversationGuid;
    root.model.conversationGuid = dataActivityQual.ConversationGuid;
    await root.activityservice.updateActivityQualBuild(dataActivityQual);

    const response = await root.activityservice.selectActivityQualResearch(root.$stateParams.activityGuid);
    if (response.data) {
      root.logger.success('Activity build successfully updated');
    }
    root.squareActivity = response.data;

    // Bug 23255 - MODELS without an interface are assholes
    // These properties were set on init of the config component
    // But because they were not mandatory, here people forgot to
    // Set them, thus, after saving previous step, here the model
    // Is reloaded from backend and these 2 properties were not set
    root.squareActivity.isChildActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.child;
    root.squareActivity.isParentActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.parent;

    root.$loadData(response.data);
    root.form.$setPristine();
  }
}
