'use strict';

import { ServerConstants } from './../../../../core/serverconstants';
import { ActivityService } from './../../../../core/dataservices/activity.service';
import { DateFormatService } from '../../../../core/services/dateformat.service';
import { ActivityQualPublishModel } from './activityQualPublishModel';
import { PoboService } from '../../../../core/services/pobo.service';
import { TargetService } from '../../../../core/dataservices/target.service';
import { FeatureService } from '../../../../core/dataservices/feature.service';
import { SquareActivityModel } from '../../squareActivityModel';
import { ActivityQualSpecifyControllerBase } from '../common/activityQualSpecifyControllerBase';
import { ActivityBuildBaseController } from '../common/activityBuildBase.controller';
import { WizardTargetingController } from '../../../../layout/wizard-targeting/wizard-targeting.controller';
import { ActivityProbeQualPresentController } from '../activityProbeQualPresent/activityProbeQualPresent.controller';

export class ActivityQualPublishController {
  static $inject = ['_', 'serverConstants', '$stateParams', 'activityservice', '$state', 'logger',
    'dateFormatService', '$mdDialog', 'poboService', 'targetservice', 'featureservice'];

  wizardStep;
  removeLink;
  validationConstants;
  activityTypes;
  communicationChannelConstants;
  displayTabOptions = false;
  invalidFiles = [];
  fileValidationErrors = [];
  selected;
  squareActivity: SquareActivityModel;
  isPublished;
  previousStepCompleted;
  communicationGuid;
  isSavingUpRewardsEnabled = false;
  isActivityTargetCompletionEnabled;
  showShowOnPublicPage = false;

  constructor(
    private _: _.LoDashStatic,
    private serverConstants: ServerConstants,
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    private $state: ng.ui.IStateService,
    private logger: Logger,
    private dateFormatService: DateFormatService,
    protected $mdDialog,
    private poboService: PoboService,
    private targetService: TargetService,
    private featureService: FeatureService,
  ) {
    this.communicationChannelConstants = serverConstants.communicationChannelConstants;
    this.validationConstants = serverConstants.validationConstants;
    this.activityTypes = serverConstants.squareActivityTypeConstants;
  }

  model = new ActivityQualPublishModel();

  async $onInit() {
    const featureSettings = await this.featureService.getFeatureSettings(this.serverConstants.featureConstants.savingUpRewards);

    this.isActivityTargetCompletionEnabled = await this.featureService.checkFeatureAccessibilityForSetup(
      this.serverConstants.featureConstants.activityTargetCompletion);
    this.showShowOnPublicPage = await this.featureService.checkFeatureAccessibilityForSetup(
      this.serverConstants.featureConstants.curationFeedbackCircle);

    this.isSavingUpRewardsEnabled = featureSettings.isEnabled && featureSettings.isMasterSwitchEnabled;
    this.removeLink = this.wizardStep.linkComponent('publishQual', this);
    this.wizardStep.resolveComponent(['builderQual']).then((resolved) => {
      this.squareActivity = resolved[0].squareActivity;
    });
  }

  $wizardStepIsCompleted() {
    return this.isPublished;
  }

  $wizardIsValid() {
    let result = false;
    if (this.model.Target.Targeting != null) {
      result = true;
    }
    return result || this.previousStepCompleted;
  }

  async $wizardStepLoaded() {
    const resolveArray = ['specifyQual', 'builderQual', 'targeting'];
    if (this.squareActivity.Detail.ActivityType !== this.serverConstants.squareActivityTypeConstants.qualitativeResearch) {
      resolveArray.push('presentQual');
    }

    this.wizardStep.resolveComponent(resolveArray)
      .then(async (resolved) => {
        const specifyStep = resolved[0] as ActivityQualSpecifyControllerBase;
        const buildStep = resolved[1] as ActivityBuildBaseController;
        const targetingStep = resolved[2] as WizardTargetingController;

        const selectedContext = specifyStep.model.Context;
        const selectedVisibility = specifyStep.model.Visibility;

        if (selectedContext === this.serverConstants.activityContextTypeConstants.private ||
          selectedVisibility === this.serverConstants.activityVisibilityTypeConstants.private) {
          this.model.Visibility = 'N/A';
        } else if (this.squareActivity.ActivityQualDetail.IsOneByOne) {
          this.model.Visibility = 'Uninfluenced';
        } else {
          this.model.Visibility = specifyStep.Visibility[selectedVisibility].Label;
        }

        this.model.StartDateTime = this.dateFormatService.getDateTime(specifyStep.model.StartTime, specifyStep.model.DateOffset);
        this.model.EndDateTime = this.dateFormatService.getDateTime(specifyStep.model.EndTime, specifyStep.model.DateOffset);

        if(this.isActivityTargetCompletionEnabled){
          this.model.IsCompletionTarget = targetingStep.isCompletionTarget;
          this.model.CompletionTarget = targetingStep.completionTarget;
        } else{
          this.model.IsCompletionTarget = specifyStep.model.IsCompletionTarget;
          this.model.CompletionTarget = specifyStep.model.CompletionTarget;
        }

        this.model.DateOffset = specifyStep.model.DateOffset;
        this.model.CompletionPoints = specifyStep.model.CompletionPoints;
        this.model.Target = { Targeting: targetingStep.targeting };
        this.model.Context = specifyStep.Context[selectedContext].Label;

        if (this.squareActivity.Detail.ActivityType !== this.serverConstants.squareActivityTypeConstants.qualitativeResearch) {
          const presentStep = resolved[3] as ActivityProbeQualPresentController;
          this.model.Sticky = presentStep.model.Sticky;
          this.model.ShowOnHomepage = presentStep.model.ShowOnHomepage;
          this.model.ShowOnPublicPage = presentStep.model.ShowOnPublicPage;
          this.communicationGuid = presentStep.squareActivity.ActivityQualDetail.CommunicationGuid;
        } else {
          this.model.Sticky = buildStep.model.sticky;
          this.model.ShowOnHomepage = buildStep.model.showOnHomepage;
          this.communicationGuid = buildStep.squareActivity.ActivityQualDetail.CommunicationGuid;
        }

        this.model.Target.Targeting = (await this.targetService.getTargetingForActivity(this.$stateParams.activityGuid));
      });
  }

  $wizardNextLabel() {
    return 'Publish';
  }

  $wizardNextDescription() {
    let description = '';
    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  $onDestroy() {
    this.removeLink();
  }

  async $wizardBeforeNext() {
    const username = await this.poboService.checkPoboConfirmation(
      this.squareActivity.ActivityQualDetail.OpenPostUsername,
      this.squareActivity.ActivityQualDetail.IsPublished !== true);
    this.squareActivity.ActivityQualDetail.OpenPostUsername = username;
    this.publishActivity();
  }

  async publishActivity() {
    const data = {
      ActivityGuid: this.$stateParams.activityGuid,
    };
    try {
      await this.activityservice.updateActivityQualPublish(data);
      this.isPublished = true;
    } finally {
      this.$state.go('root.square.activities.all', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      });
    }
  }
}
