'use strict';

import { AuthService } from './../../../core/dataservices/auth.service';
import { ServerConstants } from './../../../core/serverconstants';
import { FeatureService } from './../../../core/dataservices/feature.service';
import { SelectedSquareFactory } from './../../../core/selectedsquare.factory';
import { RouterHelper } from '../../../blocks/router/router-helper';

let hasAccessToSavingUpRewardsCache: boolean;

const routeConfig = (
  routerHelper: RouterHelper,
  serverConstants: ServerConstants,
  featureservice: FeatureService,
  authService: AuthService,
  selectedSquareFactory: SelectedSquareFactory,
) => {
  routerHelper.configureStates([
    {
      state: 'root.square.savinguprewards',
      config: {
        url: '/savinguprewards',
        title: 'Incentives',
        component: 'savingUpRewards',
        settings: {
          nav: 8,
          childnav: 1,
          label: 'Saving up Rewards',
          show: async () =>
            await hasAccessToSavingUpRewards(
              featureservice,
              authService,
              serverConstants,
            ),
        },
        disallowRoles: ['Observer','ProfessionalAdmin', 'ClientAdmin', 'ClientEditor'],
        // Used for feature-based-access-control
        isFeatureEnabled: async () =>
          await hasAccessToSavingUpRewards(
            featureservice,
            authService,
            serverConstants,
          ),
      },
    },
  ]);


  // eslint-disable-next-line no-shadow
  const hasAccessToSavingUpRewards = async (featureservice: FeatureService, authService: AuthService, serverConstants: ServerConstants) => {
    if (hasAccessToSavingUpRewardsCache === undefined) {
      let hasAccess = await featureservice.checkFeatureAccessibilityForSetup(
        serverConstants.featureConstants.savingUpRewards);
      if (hasAccess) {
        const featureSettings = await featureservice.getFeatureSettings(serverConstants.featureConstants.savingUpRewards);
        const isDeveloper = await authService.isDeveloper();
        hasAccess = isDeveloper || featureSettings.isEnabled;
      }
      hasAccessToSavingUpRewardsCache = hasAccess;
    }
    return hasAccessToSavingUpRewardsCache;
  };

  selectedSquareFactory.squareInfoObservable.subscribe(async () => {
    hasAccessToSavingUpRewardsCache = undefined;
  });
};

routeConfig.$inject = ['routerHelper', 'serverConstants', 'featureservice', 'authService', 'selectedSquareFactory'];

export default routeConfig;
