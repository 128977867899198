'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'root.programinfo',
    config: {
      url: 'client/:clientGuid/program/:programGuid',
      component: 'programInfo',
      title: 'Program info',
      settings: {
        nav: 1,
        section: 'PROGRAM SETTINGS',
        icon: 'fa fa-gauge',
        label: 'Program info',
      },
      disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

