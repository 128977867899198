'use strict';

import { DateTime } from 'luxon';

export class ActivityObservationSpecifyModel {
  Context: number;
  Visibility: number;
  ContributionType?: number;
  Theme: any;
  IsCompletionTarget: boolean = true;
  CompletionTarget: number;
  CompletionPoints: Record<string, number>;
  StartTime: DateTime;
  EndTime: DateTime;

  get DateOffset() {
    return this._dateOffset;
  }
  set DateOffset(value) {
    this._dateOffset = value;
    this.onOffsetChanged();
  }

  constructor(
    public StartDateTime: DateTime,
    public EndDateTime: DateTime,
    private _dateOffset: number,
    private onOffsetChanged: () => void,
  ) {
    this.StartTime = StartDateTime;
    this.EndTime = EndDateTime;
  }
}
