'use strict';

import { IProbeQuestionModel } from '../activityProbeQualBuild/IProbeQuestionModel';
import { MappingService } from '../../../../core/services/mapping.service';
import { SelectedActivityFactory } from '../../../../core/selectedactivity.factory';
import { ServerConstants } from '../../../../core/serverconstants';
import { ActivityDataService } from '../../../../core/services/activityData.service';
import { ActivityService } from '../../../../core/dataservices/activity.service';
import { ParentActivityChangeConfirm } from '../../../common/ParentActivityChangeConfirm';
import { ActivityBuildModelBase } from './activityBuildBase.model';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
export abstract class ActivityBuildBaseController extends ParentActivityChangeConfirm {
  static $inject = [
    'serverConstants',
    'activityservice',
    'activitydataservice',
    '$stateParams',
    'logger',
    'selectedActivityFactory',
    '$scope',
    '$mdDialog',
    'poboService',
    'mappingService'];

  wizardStep;
  removeLink: () => void;
  validationConstants: ServerConstants['validationConstants'];
  form: ng.IFormController;
  squareActivity: SquareActivityModel;

  saveNotApplicable = false;
  saveCallback = () => this.beforeSave(false);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  isPublished: boolean;
  resetFormCallback = () => this.resetForm();
  model = new ActivityBuildModelBase();
  isReadOnly: boolean;
  probeQuestions: IProbeQuestionModel[] = [];
  probeQuestionsAlreadyAnswered: boolean;
  allowedQuestionTypes: number[] = [];

  questionType: number = this.serverConstants.conversationQuestionTypeConstants.imageAndVideo;

  constructor(
    protected serverConstants: ServerConstants,
    protected activityservice: ActivityService,
    protected activitydataservice: ActivityDataService,
    protected $stateParams: ng.ui.IStateParamsService,
    protected logger: Logger,
    protected selectedActivityFactory: SelectedActivityFactory,
    protected $scope: ng.IScope,
    protected $mdDialog: ng.material.IDialogService,
    protected poboService: PoboService,
    protected mappingService: MappingService,
  ) {
    super($mdDialog);
    this.validationConstants = serverConstants.validationConstants;
  }

  $onInit() {
    this.$loadData(this.squareActivity);
    this.removeLink = this.wizardStep.linkComponent('builderQual', this);
    this.$scope.$watch('vm.model.probeQuestions', () => {
      this.validateProbeQuestions();
    }, true);
  }

  protected async $wizardStepLoaded() {
    // Load default fields
    if (!this.model.title) {
      this.model.title = this.selectedActivityFactory.Name;
    }
    // If there are no ProbeQuestions yet, an empty new ProbeQuestion will be added in edit mode.
    // We do this because this is more user friendly.
    if (this.model.probeQuestions.length === 0) {
      this.addProbeQuestionCallback();
    }
  }

  protected genericLoadData(data) {
    this.probeQuestionsAlreadyAnswered = data.ProbeQuestionsAlreadyAnswered;
    this.saveNotApplicable = this.squareActivity.ActivityQualDetail.IsPublished;
    this.probeQuestions = [...this.model.probeQuestions];
  }

  protected $loadData(data: SquareActivityModel) {
    if (this.model === undefined) {
      this.model = new ActivityBuildModelBase();
    }
    if (data && data.ActivityQualDetail) {
      this.mapModel(data);
      this.genericLoadData(data);
    }
  }

  addProbeQuestionCallback() {
    if (this.model.probeQuestions.length >= this.serverConstants.validationConstants.maxProbeQuestions) {
      return;
    }
    this.model.probeQuestions.push(
      {
        guid: '',
        question: '',
        questionType: this.questionType,
        isEdit: true,
        answerRequired: true,
        sortOrder: this.model.probeQuestions.length,
      });
  }

  resetForm() {
    this.form.$setPristine();
    this.$loadData(this.squareActivity);
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardStepIsCompleted() {
    let result = this.squareActivity
      && this.squareActivity.ActivityQualDetail != null
      && this.squareActivity.ActivityQualDetail.Title != null
      && this.model != null
      && this.model.probeQuestions != null
      && this.model.probeQuestions.length > 0
      && this.additionalConditionForCompletedSteps(this.model);

    if (this.model.conversationFocusType === this.serverConstants.conversationFocusTypeConstants.text) {
      result = result && this.squareActivity.ActivityQualDetail.Description != null;
    } else {
      result = result && this.model.stimuli.length > 0;
    }
    return result;
  }

  $wizardIsValid() {
    const questionsInEditMode = this.model.probeQuestions.some((question) => question.isEdit);
    let validVisual = true;
    if (this.model.conversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual) {
      validVisual = this.model.stimuli.length > 0 || this.model.stimuli.length > 0;
    }

    return this.form.$valid && !questionsInEditMode && validVisual;
  }

  $wizardNextLabel() {
    return this.isReadOnly ? 'Continue' : 'Save and continue';
  }

  $wizardNextDescription() {
    let description = '';
    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  protected hasChanges(): boolean {
    return false;
  }

  private validateProbeQuestions(): void {
    if (this.form.ProbeQuestions && Array.isArray(this.model.probeQuestions)) {
      this.form.ProbeQuestions.$setValidity('probeQuestionsMandatory', this.model.probeQuestions.length > 0);
    }
  }

  async $wizardBeforeNext(reload: boolean) {
    await this.beforeSave(reload);
  }

  async beforeSave(reload: boolean) {
    if (this.squareActivity.ActivityQualDetail?.IsPublished === true) {
      const username = await this.poboService.checkPoboConfirmation(
        this.squareActivity.ActivityQualDetail?.OpenPostUsername);
      this.squareActivity.ActivityQualDetail.OpenPostUsername = username;
    }
    await this.updateActivityBuild(reload, this);
  }

  protected abstract additionalConditionForCompletedSteps(model: ActivityBuildModelBase): boolean;
  protected abstract mapModel(data: SquareActivityModel);
  protected abstract updateActivityBuild(reload: boolean, root: this);

}
