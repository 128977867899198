'use strict';

import { ClientService } from '../../../../core/dataservices/client.service';
import { IImageSettingsModel } from '../../../../square/squareConfig/design/images/imageSettingsModel';
import { SpinnerService } from '../../../../core/services/spinner.service';
export class ClientConfigImagesController {
  static $inject = ['logger', 'clientservice', 'spinnerservice', '$mdDialog', '_', '$scope', 'serverConstants'];

  constructor(
    private logger: Logger,
    private clientservice: ClientService,
    private spinnerservice: SpinnerService,
    private $mdDialog: ng.material.IDialogService,
    private _: _.LoDashStatic,
    private $scope: ng.IScope,
    private serverConstants,
  ) { }
  imageError;
  image;
  form;
  clientLogo: IImageSettingsModel;

  isSaving = false;
  async $onInit() {
    this.logger.info('Client config - Design Images Editor activated');
    this.imageError = {};
    this.clientLogo = {
      Label: 'Client Logo',
      Tooltip: '',
      StoredImage: 'client-logo',
      AlternateImage: 'images/SquareIcon.png',
      MaxSize: 'Max <b>1MB</b>',
      Text: 'Ideal dimension:<br/><b>180 x 80px<b>',
    };
  }

  saveImage() {
    this.clientservice.saveClientLogo({ clientlogo: this.image })
      .then(() => {
        this.$scope.$broadcast('isc-images-saved');
        this.logger.success('Image saved successfully');
      })
      .finally(() => {
        this.imageError = {};
        this.form.$setPristine();
        this.isSaving = false;
      });
  }

  savePage() {
    this.isSaving = true;
    if (this.image && this.image.size > this.serverConstants.validationConstants.clientLogoMaxSize) {
      this.imageError.message = 'The file is too large!';
      this.isSaving = false;
      this.form.$setPristine();
    } else {
      this.saveImage();
    }
  }
}
