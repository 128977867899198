'use strict';

import { Logger } from '../../../blocks/logger/logger';

export class UniquePropertyDirective {
  constructor(
    private _: _.LoDashStatic,
    private logger: Logger) { }

  restrict = 'A';
  link = (scope, element, attrs) => {
    // { list: [], property: 'Name', alreadyInList: true }

    const validate = () => {
      ngModel.$setValidity('uniqueProperty', true);
      if (!ngModel || !ngModel.$modelValue || !settings || !settings.list) {
        return;
      }
      const currentValue = ngModel.$modelValue;
      const elementsWithSameName = this._.filter(settings.list, (e) => e[settings.property] === currentValue);
      const targetLength = settings.alreadyInList ? 1 : 0;
      const isValid = elementsWithSameName.length === targetLength;
      ngModel.$setValidity('uniqueProperty', isValid);
    };

    const inputElem = element.find('input');
    if (!inputElem) {
      this.logger.error('No child input element was found');
      return;
    }
    const ngModel = inputElem.controller('ngModel');
    if (!ngModel) {
      this.logger.error('Input element doesn\'t have a ngModel specified');
      return;
    }
    const settings = scope.$eval(attrs.uniqueProperty);
    validate();
    inputElem.bind('blur', () => {
      validate();
    });
  };

  static factory(): ng.IDirectiveFactory {
    const directive = (_, logger) => new UniquePropertyDirective(_, logger);
    directive.$inject = ['_', 'logger'];
    return directive;
  }
}
