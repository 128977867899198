'use strict';

import _ = require('lodash');

export class ConversationDataModerationService {
  static $inject = ['httpservice', '$rootScope'];

  constructor(
    private httpService,
    private $rootScope: ng.IRootScopeService) { }

  private lastTagSuggestionList;

  addNote(note) {
    return this.httpService.post({
      url: '/ConversationDataModerationService/AddConversationNote',
      data: {
        ConversationGuid: note.ConversationGuid,
        Text: note.Text,
      },
    });
  }
  updateNote(note) {
    return this.httpService.post({
      url: '/ConversationDataModerationService/UpdateConversationNote',
      data: {
        Guid: note.Guid,
        Text: note.Text,
      },
    });
  }
  deleteNote(note) {
    return this.httpService.post({
      url: '/ConversationDataModerationService/DeleteConversationNote',
      data: {
        Guid: note.Guid,
      },
    });
  }
  addTag(tag) {
    return this.httpService.post({
      url: '/ConversationDataModerationService/AddConversationTag',
      data: {
        ConversationGuid: tag.ConversationGuid,
        Text: tag.Text,
      },
    }).then((response) => {
      this.$rootScope.$emit('tagsChanged');
      return response.data;
    });
  }
  deleteTag(tag) {
    return this.httpService.post({
      url: '/ConversationDataModerationService/DeleteConversationTag',
      data: {
        Guid: tag.Guid,
      },
    }).then((response) => {
      this.$rootScope.$emit('tagsChanged');
      return response.data;
    });
  }
  getTagSuggestionList(searchQuery, activityGuid) {
    return this._getTagSuggestionList(searchQuery, activityGuid).then((response) => {
      if (!angular.equals(this.lastTagSuggestionList, response.data)) {
        this.$rootScope.$emit('tagsChanged');
        this.lastTagSuggestionList = response.data;
      }
      return this.lastTagSuggestionList;
    });
  }

  private _getTagSuggestionListKey = (searchQuery, activityGuid) => JSON.stringify([searchQuery, activityGuid]);
  private _getTagSuggestionList = _.memoize((searchQuery, activityGuid) => this.httpService.post({
    url: '/ForumService/GetTagsForModeration',
    data: {
      SearchQuery: searchQuery,
      ActivityGuid: activityGuid,
    },
  }), this._getTagSuggestionListKey);
}
