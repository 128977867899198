'use strict';

import { FeatureService } from './feature.service';
import { ActivityService } from './activity.service';
import { DiscussionService } from './discussion.service';
import { ActivitySequenceDataService } from './activitySequence.service';
import { ForumService } from './forum.service';
import { ParticipantService } from './participant.service';
import { HttpService } from './http.service';
import { RewardService } from './reward.service';
import { ActivityRecruitmentPartnerService } from './activityrecruitmentpartner.service';
import { AuthService } from './auth.service';
import { ClientService } from './client.service';
import { IncentiveService } from './incentive.service';
import { NotificationService } from './notification.service';
import { NetworkService } from './network.service';
import { PlatformHealthService } from './platformhealth.service';
import { ProjectService } from './project.service';
import { SearchService } from './search.service';
import { SegmentationService } from './segmentation.service';
import { SquareService } from './square.service';
import { TargetService } from './target.service';
import { CommunicationService } from './communication.service';
import { ProgramService } from './program.service';
import { LogService } from './log.service';
import { ExportLogService } from './exportlog.service';
import { ConversationDataModerationService } from './conversationDataModeration.service';
import { UserNotificationService } from './usernotification.service';
import { PinService } from './pin.service';
import { CurrentUserService } from './currentUser.service';
import { PlatformNoticeService } from './platformnotice.service';
import { CacheService } from '../services/cache.service';
import { ConfigurationService } from './configuration.service';
import { InsitesUserDetailService } from './insitesUserDetail.service';
import { MuxService } from './mux.service';
import { FileStorageService } from './fileStorage.service';
import { BeaconService } from './beacon.service';
import { PageService } from './page.service';
import { GeofencingService } from './geofencing.service';

angular
  .module('insitesApp.core')
  .service('activityservice', ActivityService)
  .service('discussionService', DiscussionService)
  .service('activitySequenceDataService', ActivitySequenceDataService)
  .service('authService', AuthService)
  .service('forumservice', ForumService)
  .service('httpservice', HttpService)
  .service('rewardservice', RewardService)
  .service('activityrecruitmentpartnerservice', ActivityRecruitmentPartnerService)
  .service('incentiveservice', IncentiveService)
  .service('notificationservice', NotificationService)
  .service('squareservice', SquareService)
  .service('pageService', PageService)
  .service('targetservice', TargetService)
  .service('communicationservice', CommunicationService)
  .service('participantservice', ParticipantService)
  .service('conversationDataModerationService', ConversationDataModerationService)
  .service('segmentationservice', SegmentationService)
  .service('programservice', ProgramService)
  .service('clientservice', ClientService)
  .service('searchservice', SearchService)
  .service('userNotificationService', UserNotificationService)
  .service('platformhealthservice', PlatformHealthService)
  .service('currentUserService', CurrentUserService)
  .service('projectservice', ProjectService)
  .service('pinservice', PinService)
  .service('networkservice', NetworkService)
  .service('logservice', LogService)
  .service('exportlogservice', ExportLogService)
  .service('platformNoticeService', PlatformNoticeService)
  .service('cacheService', CacheService)
  .service('featureservice', FeatureService)
  .service('configurationService', ConfigurationService)
  .service('insitesUserDetailService', InsitesUserDetailService)
  .service('muxService', MuxService)
  .service('beaconService', BeaconService)
  .service('fileStorageService', FileStorageService)
  .service('discussionService', DiscussionService)
  .service('geofencingservice', GeofencingService);

