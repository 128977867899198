'use strict';

import { RouterHelper } from '../blocks/router/router-helper';

const appRunCore = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([]);
};

appRunCore.$inject = ['routerHelper'];

angular
  .module('insitesApp.core')
  .run(appRunCore);
