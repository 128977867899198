'use strict';

import { ServerConstants } from '../serverconstants';

export class SegmentationHelperService {
  static $inject = ['serverConstants'];

  constructor(private serverConstants: ServerConstants) {
  }

  segmentationTypeToReadableString(segmentationType: number): string {
    let result: string;
    switch (segmentationType) {
      case this.serverConstants.segmentationTypeConstants.survey: result = 'Survey data'; break;
      case this.serverConstants.segmentationTypeConstants.manual: result = 'Manual'; break;
      case this.serverConstants.segmentationTypeConstants.platformBehavior: result = 'Platform behavior'; break;
      case this.serverConstants.segmentationTypeConstants.activityBehavior: result = 'Activity behavior'; break;
      case this.serverConstants.segmentationTypeConstants.profile: result = 'Profile'; break;
    }
    return result;
  }
}
