'use strict';

import { IConversationElement, IProbeQuestionConversationElementItem } from './../conversationElementModel';
import { MappingService } from './../../../core/services/mapping.service';
import { IForumConversation } from './../../../core/services/activityData.service';
import {
  IConversationEditorConfig, IUploadConversationStimulus, IUploadConversationAttachment,
  IVisualFocusedConversationConfig, IProbeQuestionAnswerModel, IscUIUtils,
} from 'isc-ui';
import { CurrentUserService } from '../../../core/dataservices/currentUser.service';
import { ForumService } from '../../../core/dataservices/forum.service';
import { ActivityDataService } from '../../../core/services/activityData.service';
import { Utils } from '../../../core/utils';
import { ConversationEventsService } from '../../../core/services/conversationEvents.service';
import { ConversationService } from '../../../core/services/conversation.service';
import { ParticipantFactory } from '../../../core/participant.factory';
import { ServerConstants } from '../../../core/serverconstants';
import { SpinnerService } from '../../../core/services/spinner.service';
import { IPromise, IQService } from 'angular';
import * as _ from 'lodash';
import { ICurrentUser, IImpersonateUser } from '../../../core/dataservices/dataservice.contracts';
import { AuthService } from '../../../core/dataservices/auth.service';
import { HelperService } from '../../../core/services/helper.service';
import { MuxService } from '../../../core/dataservices/mux.service';
import { IProbeQuestionModel } from '../../activityQualResearchConfig/activityQualSteps/activityProbeQualBuild/IProbeQuestionModel';
import { IConversationStimuli } from '../../activityQualResearchConfig/squareActivityModel';
import { FileStorageService } from '../../../core/dataservices/fileStorage.service';
import { NotificationsService } from '../../../core/services/notifications';
import { IAttachmentUploaded, IStimuliUploaded } from '../../../core/services/notifications.contracts';
import { IscDraftService } from 'isc-ui/dist/services/activity/draft.service';
import { IGenericDraft } from '../../../core/contracts/activity.contract';

export class ActivityDataPostController  implements ng.IOnInit {
  static $inject = ['$scope', '$stateParams',
    'activitydataservice', 'forumservice',
    'participantFactory', '$mdDialog', 'spinnerservice',
    'serverConstants', 'logger', 'conversationEventsService', 'conversationService',
    'currentUserService', '$state', 'authService', 'mappingService',
    'helperservice', 'muxService', 'fileStorageService', 'notifications', '$q',
  ];

  constructor(
    private $scope: ng.IScope,
    private $stateParams: ng.ui.IStateParamsService,
    private activitydataservice: ActivityDataService,
    private forumservice: ForumService,
    private participantFactory: ParticipantFactory,
    private $mdDialog: ng.material.IDialogService,
    private spinnerservice: SpinnerService,
    private serverConstants: ServerConstants,
    private logger: Logger,
    private conversationEventsService: ConversationEventsService,
    private conversationService: ConversationService,
    private currentUserService: CurrentUserService,
    private $state: ng.ui.IStateService,
    private authService: AuthService,
    private mappingService: MappingService,
    private helperservice: HelperService,
    private muxService: MuxService,
    private fileStorageService: FileStorageService,
    private notifications: NotificationsService,
    private $q: IQService,
  ) {
  }

  /** Bindings */
  item: IConversationElement;
  showRating: boolean;
  hideReplyAction: boolean;
  hideUpdateAction: any;
  hideRemoveAction: any;
  diableRatingAction: any;
  disableTagAction: any;
  isDividedDiscussionType: boolean;
  contributionType?: number;
  activityEndDatePassed: boolean;
  activityStatus: number;
  isModeratorCurationEnabled: boolean;
  visibilityBufferPeriodPassed: boolean;
  /** /Bindings */

  // These are used for replies
  message: string;
  caption: string;
  title: string;
  tmpTitle: string;
  stimuli: IUploadConversationStimulus[] = [];
  attachments: IUploadConversationAttachment[] = [];
  newStimulusFiles: File[];
  newAttachmentFiles: File[];

  channel: any;
  disableButtons = false;
  setNoteOnTop: (arg0: { guid: string; }) => void;
  setTagOnTop: (arg0: { guid: string; }) => void;
  showMemberDetails = false;
  showMemberDetailsTitle = false;
  buttonClicked: boolean;

  readOnly = false;
  previousSavedReply: string;
  previousSavedCaption: string;
  saveButtonClicked = false;
  isTopic: boolean = false;
  hasAdditionalQuestions: boolean;
  conversationFocusType;
  additionalProbeQuestions: IProbeQuestionModel[] = [];
  shouldHideAnswers: boolean;
  isEditMode = false;
  isOpeningPost: boolean;
  postOfflineText: string;
  activityType;
  isScoutDiscussionType;
  areAllQuestionsAnswered: boolean = false;
  isDiary?: boolean;
  serverErrors: string;

  private editorStates = {
    Closed: 0,
    OpenReply: 1,
    OpenEdit: 3,
    OpenUpdate: 4,
  };
  editorState = this.editorStates.Closed;

  replyConversationEditorConfig: IConversationEditorConfig;
  additionalAnswerConversationEditorConfig: IConversationEditorConfig;
  visualFocusedConversationConfig: IVisualFocusedConversationConfig;

  postStimuli: IUploadConversationStimulus[];

  // These 2 fields ar used to keep track of the files we are currently saving as draft.
  currentlySavingStimuli: File[];
  currentlySavingAttachments: File[];

  unsubscribeShowPhotoThumbnail = _.noop;
  unsubscribeUpdateAttachmentUrl = _.noop;

  autosaved = false;

  $onInit() {
    this.showMemberDetailsTitle = this.item.SquareParticipantRole === this.serverConstants.roleConstants.participant;

    this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
    this.isDiary = this.contributionType === this.serverConstants.activityContributionTypeConstants.diary;
    this.visualFocusedConversationConfig = {
      isColumnLayout: true,
      showTextEditor: false,
      uploadConfig: {
        showImageUpload: false,
        showVideoLink: false,
        showVideoUpload: false,
        showAttachment: false,
      },
    };

    this.replyConversationEditorConfig = {
      showTitle: false,
      showTextEditor: true,
      showCaptionEditor: true,
      textEditorButtons: this.textEditorButtons(),
      titleMaxLength: this.serverConstants.validationConstants.qualActivityTitleMaxLength,
      messageMaxLength: this.serverConstants.validationConstants.textareaResearchMessageMaxLength,
      saveLabel: this.getSaveLabel(),
      uploadConfig: {
        showImageUpload: true,
        showVideoUpload: true,
        showVideoLink: true,
        showAttachment: true,
        maxStimuli: this.serverConstants.validationConstants.conversationStimuliMaxNo,
        maxAttachments: this.serverConstants.validationConstants.conversationAttachmentMaxNo,
      },
    };

    this.additionalAnswerConversationEditorConfig = {
      showTitle: this.showTitleInEditor(),
      saveLabel: this.getSaveLabel(),
      messageLabel: 'Reply',
      showTextEditor: true,
      showCaptionEditor: false,
      textEditorButtons: this.textEditorButtons(),
      uploadConfig: {
        showVideoLink: false,
        showVideoUpload: false,
        showImageUpload: false,
        showAttachment: false,
      },
    };
    this.newStimulusFiles = [];
    this.newAttachmentFiles = [];
    this.isTopic = this.item.Guid === this.item.ParentMessage;
    this.initProbeQuestionProperties();
    this.isOpeningPost = this.item.ParentMessage === this.item.Guid;
    this.hideReplyAction = this.hideReplyAction ||
      (!this.item.IsReadonly && this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost) || this.isOpeningPost;
    this.postOfflineText = this.isCurrentParticipant(this.item.SquareParticipantGuid) ?
      'Your post has been taken offline. Either it didn\'t comply with our Terms & Conditions or it was inappropriate.' :
      'This post has been taken offline';
    this.checkIfEditMode();
    this.postStimuli = this.mappingService.mapConversationStimuliForConversationEditor(this.item.Stimuli);
    this.unsubscribeShowPhotoThumbnail = this.notifications.showPhotoThumbnail.subscribe((stimuli: IStimuliUploaded) => {
      _.forEach(this.stimuli, (stimulus) => {
        if (stimulus.guid === stimuli.Guid) {
          stimulus.thumbnailUrl = stimuli.ThumbnailUrl;
          stimulus.value = stimuli.Url;
        }
      });
    });
    this.unsubscribeUpdateAttachmentUrl = this.notifications.updateAttachmentUrl.subscribe((attachmentUploaded: IAttachmentUploaded) => {
      _.forEach(this.attachments, (attachment) => {
        if (attachment.guid === attachmentUploaded.Guid) {
          attachment.url = attachmentUploaded.Url;
        }
      });
    });

    IscDraftService.subscribeToDraftFileChanges(this.getDraftIdentifier(), this.onDraftFileChanges);
  }

  onDraftFileChanges(param: IDraftFileChangeCallbackParam): boolean {
    if (param && param.uid) {
      if (param.added) {
        // TODO: the draft has a new file, we should upload it to blob storage
      }
      if (param.removed) {
        // TODO: the draft removed a file, we should remove it from the blob storage
      }
    }
    return true;
  }

  private textEditorButtons() {
    if (this.isScoutDiscussionType) {
      return 'none';
    }

    return 'emoji, bold, italic, underline, list1, list2, customlink, outdent, indent, removeFormat';
  }

  $onChanges() {
    if (this.item.ProbeQuestions) {
      this.shouldHideAnswers = this.item.IsReadonly &&
        this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost &&
        !this.item.HasBeenAnswered;
      this.initProbeQuestionProperties();
    }
  }

  getRoleLabel(roleType: any): string {
    return Utils.getRoleLabel(this.serverConstants.roleConstants, roleType);
  }

  getStatusLabel(statusType: any, suspended: boolean): string {
    // Do not get the label for active, to preserve space in the design
    return (suspended || statusType !== this.serverConstants.registrationStatusConstants.active)
      ? Utils.getStatusLabel(this.serverConstants.registrationStatusConstants, statusType, suspended)
      : null;
  }

  isCurrentParticipant(squareParticipantGuid) {
    return this.currentUserService.userProfile.Guid === squareParticipantGuid;
  }

  async onHeartClick(post) {
    this.buttonClicked = true;
    let promise: IPromise<any>;
    if (post.IsLiked) {
      promise = this.forumservice.unlikeQualConversation(post.Guid);
    } else {
      promise = this.forumservice.likeQualConversation(post.Guid);
    }
    await this.onHeartClicked(promise, post);
    this.buttonClicked = false;
  }

  private async onHeartClicked(promise, post) {
    await promise;
    if (post.IsLiked) {
      post.LikesCount--;
    } else {
      post.LikesCount++;
    }
    post.IsLiked = !post.IsLiked;

    return promise;
  }

  iscMentionSelectFn(item) {
    if (item) {
      return `<span data-username="${item.label}">@${item.label}</span>&nbsp;`;
    }
    return '';
  }

  async iscMentionSearchFn(term: string) {
    if (term) {
      return await this.forumservice.getMentionUsersForConversationAdmin(this.$stateParams.activityGuid, term, this.item.Guid);
    }
    return this.$q.resolve(undefined);
  }

  iscHashtagSelectFn(item) {
    if (item) {
      return `<span>${item.text}</span>&nbsp;`;
    }
    return '';
  }

  async iscHashtagSearchFn(term: string) {
    if (term) {
      return await this.forumservice.getHashtagsForConversation(this.$stateParams.activityGuid, term);
    }
    return this.$q.resolve(undefined);
  }

  async reply() {
    try {
      this.spinnerservice.show('loading');
      const replyGuid = await this.activitydataservice.sendQualReply(
        this.item,
        this.message,
        this.stimuli,
        this.attachments,
        this.newAttachmentFiles,
        this.channel,
        this.caption);
      this.cancel();
      await this.$scope.$emit('qualPostChanged', replyGuid, this.item.Guid);
    } catch (error) {
      if (error.status === 400) {
        if (error.data && error.data.Reason) {
          this.logger.error(error.data.Reason);
        }
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  enterReply(item) {
    if (item.Level === this.serverConstants.squareConstants.maxReplyLevel) {
      this.message = this.message || `<span class="mention" data-username="${item.Username}">@${item.Username}</span>&nbsp;`;
    }
    this.saveButtonClicked = false;
    this.setEditorState(this.editorStates.OpenReply);
    this.conversationEventsService.discussionChangeStarted(this.$stateParams.activityGuid);
    this.hasAdditionalQuestions = this.getHasAdditionalQuestions;
    this.item.AnswerRequired = this.isModeratorCurationEnabled && !this.visibilityBufferPeriodPassed ? true : false;
  }

  enterUpdate() {
    this.saveButtonClicked = false;
    this.setEditorState(this.editorStates.OpenUpdate);
    this.conversationEventsService.discussionChangeStarted(this.$stateParams.activityGuid);
    this.hasAdditionalQuestions = this.getHasAdditionalQuestions;
  }

  cancel() {
    this.setEditorState(this.editorStates.Closed);
    this.isEditMode = false;
    this.message = '';
    this.caption = '';
    this.title = '';
    this.item.Title = this.tmpTitle;
    this.tmpTitle = '';
    this.stimuli.splice(0);
    this.attachments.splice(0);
    this.conversationEventsService.discussionChangeEnded(this.$stateParams.activityGuid);
    this.hasAdditionalQuestions = false;
    this.additionalProbeQuestions = [];

    IscDraftService.broadcastDraftRemove(undefined, this.getDraftIdentifier());
  }

  notRemovable() {
    return this.hideRemoveAction || !this.item || !this.item.Level || this.item.Level === 0 || this.item.Censored;
  }

  ownPost() {
    return this.item.SquareParticipantGuid === this.user().Guid;
  }

  toggleCensorConversation() {
    this.conversationService.censorConversation(this.item);
  }

  async setRating(rating: number) {
    await this.forumservice.setRating(this.item.Guid, rating);
  }

  toggleNotes() {
    this.setNoteOnTop({ guid: this.item.Guid });
  }
  toggleTags() {
    this.setTagOnTop({ guid: this.item.Guid });
  }

  isEditable() {
    return this.item && this.item.IsEditable;
  }

  editPostClick() {
    if (this.item.Level === 0 && this.item.ParentMessage === this.item.Guid) {
      this.$state.go('root.square.activityWizardType', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
        activityGuid: this.$stateParams.activityGuid,
        step: 'Build',
      });
    } else {
      this.message = this.item.Message;
      this.caption = this.item.Caption;
      this.title = this.item.Title;
      this.tmpTitle = this.item.Title;
      this.item.Title = '';
      this.stimuli = this.mappingService.mapConversationStimuliForConversationEditor(this.item.Stimuli);
      this.attachments = this.mappingService.mapConversationAttachmentsForConversationEditor(this.item.Attachments);
      this.setEditorState(this.editorStates.OpenEdit);
      this.isEditMode = true;
      if (this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost) {
        this.hasAdditionalQuestions = true;
        this.additionalProbeQuestions = this.item.ProbeQuestions.map((q, index) =>
          ({
            guid: q.Guid,
            question: q.Message,
            questionType: q.QuestionType,
            isEdit: null,
            answerRequired: q.AnswerRequired,
            sortOrder: index,
          }),
        );
      }
      this.conversationEventsService.discussionChangeStarted(this.$stateParams.activityGuid);
    }
  }

  user(): ICurrentUser {
    if (!this.authService.isImpersonating
      || this.impersonateUserList == null
      || !this.impersonateUserList.length) {
      return this.currentUserService.userProfile;
    }
    // The impersonate in auth is a getter which will retrieve and parse JSON from storage with every get -> for each in impersonateUserList
    const impersonationGuid = this.authService.impersonate.SquareParticipantGuid;
    const impersonateUser = _.find(this.impersonateUserList, (u) => u.Guid === impersonationGuid);
    return {
      Guid: this.currentUserService.userProfile.Guid,
      Role: this.currentUserService.userProfile.Role,
      Avatar: this.currentUserService.userProfile.Avatar,
      Username: impersonateUser ? impersonateUser.Username : '',
      Firstname: this.currentUserService.userProfile.Firstname,
      Lastname: this.currentUserService.userProfile.Lastname,
    } as ICurrentUser;
  }

  async saveClicked() {
    try {
      this.saveButtonClicked = true;
      switch (this.editorState) {
        case this.editorStates.OpenEdit:
          await this.update(this.item.Guid, (this.hasAdditionalQuestions && this.additionalProbeQuestions.length > 0) ? this.additionalProbeQuestions : null);
          break;
        case this.editorStates.OpenReply:
          if (this.hasAdditionalQuestions && this.additionalProbeQuestions.length > 0) {
            await this.update(this.item.InDraftReply ? this.item.InDraftReply.Guid : null, this.additionalProbeQuestions, true);
          } else {
            await this.reply();
          }
          break;
        case this.editorStates.OpenUpdate:
          await this.createUpdatePost();
          break;
      }
    } finally {
      this.conversationEventsService.discussionChangeEnded(this.$stateParams.activityGuid);
      this.saveButtonClicked = false;
    }
  }

  async createUpdatePost() {
    try {
      this.spinnerservice.show('loading');
      const response = await this.activitydataservice.sendQualPostUpdate(
        this.item,
        this.message,
        this.stimuli,
        this.attachments,
        this.newAttachmentFiles,
        this.channel,
        this.caption);
      this.cancel();
      this.$scope.$emit('qualPostChanged', response.data);
      if (this.isDividedDiscussionType && this.item.Type === this.serverConstants.conversationElementTypeConstants.topic) {
        // Save button was clicked but applies on the update post and not on this post
        this.saveButtonClicked = false;
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async update(conversationGuid: string, probeQuestions: IProbeQuestionModel[], isNewIndividualUpdatePost = false) {
    if (this.item.Username != null &&
      (!isNewIndividualUpdatePost && this.item.Username !== this.user().Username ||
        isNewIndividualUpdatePost && this.authService.isImpersonating)) {
      this.showRepostDialog(!isNewIndividualUpdatePost).then(async () => {
        await this.updatePost(conversationGuid, probeQuestions);
      });
    } else {
      await this.updatePost(conversationGuid, probeQuestions);
    }
  }

  async showRepostDialog(isRepost: boolean) {
    let dialogTitle = 'Repost as different member';
    let dialogText = `You are about to repost as <b>${this.user().Username}</b>, while the initial post was submitted by <b>${this.item.Username}</b>.
    This change will be visible to all participants. \n\n Are you sure you want to proceed?`;
    let dialogButton = 'Repost';

    if (!isRepost) {
      dialogTitle = 'Post as a different member';
      dialogText = `You are about to post as <b>${this.user().Username}</b>. This will be visible to all participants. Are you sure you want to proceed?`;
      dialogButton = 'Post';
    }

    await this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title(dialogTitle)
        .text(dialogText)
        .ok(dialogButton)
        .cancel('Cancel'));
  }

  async updatePost(conversationGuid: string, probeQuestions: IProbeQuestionModel[]) {
    try {
      this.spinnerservice.show('loading');
      const parentMessage = (this.hasAdditionalQuestions && this.additionalProbeQuestions.length > 0) ?
        (this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost ? this.item.ParentMessage : this.item.Guid) :
        null;
      const conversation: IForumConversation = {
        Guid: conversationGuid,
        Message: this.message,
        Caption: this.caption,
        ActivityGuid: this.$stateParams.activityGuid,
        Stimuli: this.stimuli,
        Attachments: this.attachments,
        Title: this.title,
        ParentMessage: parentMessage,
        ContributionType: this.contributionType,
      };
      this.activitydataservice.saveConversation(
        conversation, this.newAttachmentFiles, probeQuestions)
        .then(() => {
          this.$scope.$emit('qualPostChanged', this.item.Guid);
          this.cancel();
        })
        .catch((error) => {
          this.serverErrors = error.data.ValidationErrors[0].ErrorMessage;
        });
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async toggleMemberDetails(item) {
    if (!this.showMemberDetails) {
      if (this.item.MemberDetails === undefined) {
        this.item.MemberDetails = await this.forumservice.getMemberDetails(item.SquareParticipantGuid);
      }
    }
    this.showMemberDetails = !this.showMemberDetails;
  }

  async startSaveAllProbeAnswers(): Promise<void> {
    this.buttonClicked = true;
    this.saveButtonClicked = !this.item.IsOneByOne;
    try {
      if (this.item.Username != null && this.item.Username !== this.user().Username && this.editorState === this.editorStates.OpenEdit) {
        await this.showRepostDialog(true).then(async () => {
          await this.saveAllProbeAnswers();
        });
      } else {
        await this.saveAllProbeAnswers();
      }
    } catch {
      // Error gets handled with errorTask. We want the rest of the code to be executed
    } finally {
      if (!this.item.IsOneByOne) {
        this.cancel();
      }
      this.buttonClicked = false;
      this.saveButtonClicked = false;
    }
  }

  async saveAllProbeAnswers(): Promise<void> {
    const probeAnswers: IProbeQuestionAnswerModel[] = this.item.ProbeQuestionsForEditor
      .map((question) => question.answer);
    this.spinnerservice.show('loading');
    try {
      const replyGuid = await this.conversationService.replyToProbingQuestions(
        probeAnswers,
        this.editorState === this.editorStates.OpenEdit ? this.item.SquareParticipantGuid : null,
        this.activityType,
        this.conversationFocusType,
        this.contributionType,
        this.channel);
      if (replyGuid !== null) {
        this.$scope.$emit('qualPostChanged', replyGuid);
        await this.conversationService.jumpToReplyQual(replyGuid);
        this.areAllQuestionsAnswered = true;
        this.isEditMode = false;
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  showConversationEditor(): boolean {
    return this.showEditor()
      && !this.hasAdditionalQuestions
      && !(this.editorState === this.editorStates.OpenEdit
        && this.item.Type === this.serverConstants.conversationElementTypeConstants.answer);
  }

  addProbeQuestionCallback() {
    if (!this.additionalProbeQuestions) {
      this.additionalProbeQuestions = [];
    }
    if (this.additionalProbeQuestions.length >= this.serverConstants.validationConstants.maxProbeQuestions) {
      return;
    }
    this.additionalProbeQuestions.push({
      guid: '',
      question: '',
      questionType: this.serverConstants.conversationQuestionTypeConstants.text,
      isEdit: true,
      answerRequired: !this.onlyOptionalAdditionalQuestionsAllowed,
      sortOrder: this.additionalProbeQuestions.length,
    });
  }

  additionalProbeQuestionsValidator() {
    return this.additionalProbeQuestions.length === 0 ||
      (this.additionalProbeQuestions.length > 0
      && !this.additionalProbeQuestions.some((question) => question.isEdit));
  }

  shouldHighLightPost(): boolean {
    return !this.item.IsReadonly && this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost;
  }

  isStimuliDownloadSupported(stimuli: { Type: number }) {
    return stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.image ||
      stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.video;
  }

  showTitleInEditor() {
    return this.editorState === this.editorStates.OpenEdit && this.item.Level === undefined;
  }

  showEditor() {
    return this.editorState !== this.editorStates.Closed
      && (this.activityStatus !== this.serverConstants.squareActivityStatusConstants.closed || !this.activityEndDatePassed);
  }

  get onlyOptionalAdditionalQuestionsAllowed() {
    return this.activityEndDatePassed;
  }

  showUpdateButton() {
    return !this.hideUpdateAction && this.item.IsEditable && this.item.ParentMessage === this.item.Guid;
  }

  allQuestionsAnswered() {
    return this.item.ProbeQuestionsForEditor &&
      Array.isArray(this.item.ProbeQuestionsForEditor) &&
      this.item.ProbeQuestionsForEditor.every((q) => q.hasBeenAnswered && !this.item.IsReadonly);
  }

  showToggleable() {
    const hasProbingQuestions = this.item.ProbeQuestionsForEditor != null;
    const isAnsweredTopic = this.isTopic && this.allQuestionsAnswered();
    const isIndividualUpdatePostInEditMode = this.editorState === this.editorStates.OpenEdit &&
      this.item.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost;

    return hasProbingQuestions
      && !isIndividualUpdatePostInEditMode
      && (!isAnsweredTopic || (this.isDiary && !this.visibilityBufferPeriodPassed))
      && (this.item.Level !== 0 || !this.visibilityBufferPeriodPassed);
  }

  getSaveLabel() {
    switch (this.editorState) {
      case this.editorStates.OpenEdit:
        return '(LabelForumPostSave)';
      case this.editorStates.OpenReply:
        return '(LabelForumReply)';
      case this.editorStates.OpenUpdate:
        return '(LabelForumPostUpdate)';
    }
  }

  get impersonateUserList(): IImpersonateUser[] {
    return this.currentUserService.impersonateUserList;
  }

  get avatar() {
    if (!this.item) {
      return undefined;
    }
    return this.participantFactory.getAvatar(this.item);
  }

  get memberLink(): string {
    if (this.item.SquareParticipantRole === this.serverConstants.roleConstants.participant) {
      return this.item && this.$state.href('root.square.members.details.activities', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
        memberGuid: this.item.SquareParticipantGuid,
      });
    }

    return null;
  }

  isVisualFocusedPost(): boolean {
    return this.conversationFocusType === this.serverConstants.conversationFocusTypeConstants.visual;
  }

  isVisualFocusedOpeningPost(): boolean {
    return this.isVisualFocusedPost() && this.isOpeningPost;
  }

  get showContributionTitle(): boolean {
    return this.isDiary && !this.isOpeningPost && this.item.Level > 0;
  }

  get getHasAdditionalQuestions(): boolean {
    return this.editorState === this.editorStates.OpenReply &&
      this.item.Type === this.serverConstants.conversationElementTypeConstants.answer &&
      !this.activityEndDatePassed;
  }

  private initProbeQuestionProperties() {
    if (this.item.ProbeQuestions) {
      this.item.ProbeQuestionsForEditor = this.item.ProbeQuestionsForEditor || this.mappingService.mapProbeQuestionsForProbeQuestionEditor(this.item.ProbeQuestions);
    }
  }

  private checkIfEditMode() {
    const isEditMode = this.$stateParams.isEdit !== undefined ? JSON.parse(this.$stateParams.isEdit) : false;
    const replyGuid = this.$stateParams.replyGuid;

    if (isEditMode && replyGuid === this.item.Guid) {
      this.editPostClick();
    }
  }

  private async setEditorState(newState: number) {
    this.editorState = newState;
    this.replyConversationEditorConfig.saveLabel = this.getSaveLabel();
    this.additionalAnswerConversationEditorConfig.saveLabel = this.getSaveLabel();
  }

  getUploadProgress(stimulus: IConversationStimuli | IUploadConversationStimulus) {
    let stimulusObject: IUploadConversationStimulus;
    if (stimulus && this.muxService.MuxServiceEnabled) {
      if (this.isInstanceOfIConversationStimuli(stimulus)) {
        stimulusObject = this.mappingService.mapConversationStimuliForConversationEditor([stimulus])[0];
      } else {
        stimulusObject = stimulus;
      }

      if (stimulusObject.value &&
        stimulusObject.type === this.serverConstants.conversationStimuliTypeConstants.video &&
        !stimulusObject.thumbnailUrl) {
        return this.muxService.getStimulusUploadProgress(stimulusObject.value);
      }

      if (stimulusObject.guid &&
        stimulusObject.type === this.serverConstants.conversationStimuliTypeConstants.image &&
        !stimulusObject.thumbnailUrl) {
        return this.fileStorageService.getStimulusUploadProgress(stimulusObject.value);
      }
    }
    return null;
  }

  // Used to determine if the object is IConversationStimuli - has properties with PascalCase (while IUploadConversationStimulus has camelCase properties)
  private isInstanceOfIConversationStimuli(object: IConversationStimuli | IUploadConversationStimulus): object is IConversationStimuli {
    return 'Id' in object && 'Guid' in object && 'ConversationGuid' in object;
  }

  setConversationAccepted(conversation: IForumConversation) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: 'ACCEPT submission?',
      text: 'An accepted submission triggers an email and a notification to the Participant. \n'
      + ' Are you sure you want to ACCEPT this submission?',
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      this.spinnerservice.show('loading');
      this.forumservice.setContributionAccepted(conversation.Guid)
        .then(() => {
          conversation.IsAccepted = true; // it can only be set to accepted
        }).finally(() => {
          this.spinnerservice.hide('loading');
        });
    }, () => {
      conversation.IsAccepted = false;
    });
  }

  $onDestroy() {
    this.unsubscribeShowPhotoThumbnail();
    this.unsubscribeUpdateAttachmentUrl();

    // If we have a draft worth saving, we do it
    const draft = this.getDraftObject();
    if (draft) {
      IscDraftService.storeDraft(this.getDraftIdentifier(), draft);
    }
  }

  get canBeSaved() {
    return this.editorState === this.editorStates.OpenEdit;
  }

  getDraftObject(): IGenericDraft {
    // We create a draft for the draft service, if any
    let result: IGenericDraft;
    if (this.isEditMode
      || this.hasAdditionalQuestions
      || this.message || this.stimuli.length > 0 || this.attachments.length > 0) {
      switch (this.editorState) {
        case this.editorStates.OpenReply:
        case this.editorStates.OpenUpdate:
        // Case this.editorStates.Closed:
          result = {
            message: this.message,
            stimuli: this.stimuli,
            attachments: this.attachments,
            questions: this.editorState === this.editorStates.OpenReply ? undefined : this.item.ProbeQuestionsForEditor,
            additionalquestions: this.editorState === this.editorStates.OpenReply ? this.additionalProbeQuestions : undefined,
            state: this.editorState,
          };
          break;
      }
    }
    return result;
  }

  async setDraftObject(value: IGenericDraft) {
    if (value) {
      // We received a draft for our entity
      this.message = value.message;
      this.stimuli = value.stimuli;
      this.attachments = value.attachments;
      this.item.ProbeQuestionsForEditor = value.questions || this.item.ProbeQuestionsForEditor;
      this.additionalProbeQuestions = value.additionalquestions || this.additionalProbeQuestions;
      this.stimuli.forEach((stimulus) => {
        if (stimulus.file) {
          if (stimulus.type === this.serverConstants.conversationStimuliTypeConstants.image) {
            const preview = URL.createObjectURL(stimulus.file);
            stimulus.thumbnailUrl = preview;
            if (!stimulus.url) {
              stimulus.url = preview;
            }
            stimulus.isPreview = true;
          } else if (stimulus.type === this.serverConstants.conversationStimuliTypeConstants.video) {
            stimulus.isPreview = false;
          }
        }
      });
      switch (value.state) {
        case this.editorStates.OpenReply:
          this.enterReply(this.item);
          break;
        case this.editorStates.OpenUpdate:
          this.enterUpdate();
          break;
        case this.editorStates.Closed:
          break;
      }
    }
  }

  getDraftIdentifier() {
    return `${this.item.Guid}_${this.authService.SquareParticipantGuid}`;
  }

  private autosave() {
    // Main entry point for child components to tell us that they changed something
    // Here we notify the draft mechanism that we need our entity stored
    IscDraftService.broadcastDraftUpdate(undefined, this.getDraftIdentifier(), this.getDraftObject());
    this.autosaved = true;
    setTimeout(() => {
      this.autosaved = false;
    }, 2000);
  }

  private retryStimulus(newStimulus: IUploadConversationStimulus) {
    let foundStimulus: IConversationStimuli = null;
    let foundMappedStimulus: IUploadConversationStimulus = null;
    if (newStimulus && newStimulus.guid) {

      // Look into probe questions first
      if (this.item.ProbeQuestions) {
        _.forEach(this.item.ProbeQuestions, (probeQuestion: IProbeQuestionConversationElementItem) => {
          if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
            _.forEach(probeQuestion.Answer.Stimuli, (stimulus: IConversationStimuli) => {
              if (stimulus.Guid === newStimulus.guid) {
                foundStimulus = stimulus;
                return false; // Break if the video was found
              }
            });
          }
        });
        if (this.item.ProbeQuestionsForEditor) {
          _.forEach(this.item.ProbeQuestionsForEditor, (probeQuestion) => {
            if (probeQuestion.answer && probeQuestion.answer.stimuli && probeQuestion.answer.stimuli.length) {
              _.forEach(probeQuestion.answer.stimuli, (stimulus: IUploadConversationStimulus) => {
                if (stimulus.guid === newStimulus.guid) {
                  foundMappedStimulus = stimulus;
                  return false; // Break if the video was found
                }
              });
            }
          });
        }
      }

      // Look into the reply itself, if no probe questions
      if (!foundStimulus && this.item.Stimuli && this.item.Stimuli.length) {
        _.forEach(this.item.Stimuli, (stimulus) => {
          if (stimulus.Guid === newStimulus.guid) {
            foundStimulus = stimulus;
            return false; // Break if the video was found
          }
        });
      }

      // Also check post stimuli for mapped entries, if any
      if (!foundMappedStimulus && this.postStimuli && this.postStimuli.length) {
        _.forEach(this.postStimuli, (stimulus: IUploadConversationStimulus) => {
          if (stimulus.guid === newStimulus.guid) {
            foundMappedStimulus = stimulus;
            return false; // Break if the video was found
          }
        });
      }

      // If we have at least one matching stimulus, we upload
      if ((foundStimulus && foundStimulus.IsBroken === true && foundStimulus.IsBrokenAndBelongsToCurrentUser === true)
        || (foundMappedStimulus && foundMappedStimulus.IsBroken === true && foundMappedStimulus.IsBrokenAndBelongsToCurrentUser === true)) {
        // We will upload again for this particular stimulus
        const stimuli: IUploadConversationStimulus[] = [];
        stimuli.push(newStimulus);
        this.forumservice.prepareStimuliAndNewStimuli(stimuli)
          .then((result) => {
            if (result && result.stimuli && result.stimuli.length > 0) {
              result.stimuli.forEach((stimulus) => {
                if (stimulus.fileContentToBeRemoved) {
                  stimulus.file = undefined;
                  this.forumservice.updateStimulusAfterNewUpload(stimulus);
                }
              });
              // Set the broken to undefined so that the UI will bind the proper component
              // And set the value to the new upload ID so that upload progress can be shown on the UI component
              if (foundStimulus) {
                foundStimulus.IsBroken = undefined;
                if (foundStimulus.Type === this.serverConstants.conversationStimuliTypeConstants.video) {
                  foundStimulus.Value = result.stimuli[0].id;
                } else if (foundStimulus.Type === this.serverConstants.conversationStimuliTypeConstants.image) {
                  foundStimulus.Url = result.stimuli[0].url;
                  foundStimulus.ThumbnailUrl = result.stimuli[0].thumbnailUrl;
                }
              }
              if (foundMappedStimulus) {
                foundMappedStimulus.IsBroken = undefined;
                if (foundMappedStimulus.type === this.serverConstants.conversationStimuliTypeConstants.video) {
                  foundMappedStimulus.value = result.stimuli[0].id;
                } else if (foundMappedStimulus.type === this.serverConstants.conversationStimuliTypeConstants.image) {
                  foundMappedStimulus.url = result.stimuli[0].url;
                  foundMappedStimulus.thumbnailUrl = result.stimuli[0].thumbnailUrl;
                }
              }
            }
          });
      }
    }
  }

}
