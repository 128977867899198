'use strict';

import { SelectedClientFactory } from '../../core/selectedclient.factory';
import { ClientService } from '../../core/dataservices/client.service';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import { SquareService } from '../../core/dataservices/square.service';
import { FeatureService } from '../../core/dataservices/feature.service';
import { HelperService } from '../../core/services/helper.service';
import { ServerConstants } from '../../core/serverconstants';
import * as _ from 'lodash';
import { AuthService } from '../../core/dataservices/auth.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { IRelatedClientInfo } from 'src/app/core/dataservices/dataservice.contracts';

export class ObserverLandingPageController {
  static $inject = [
    'clientservice',
    '$state',
    '$stateParams',
    'selectedClientFactory',
    'selectedSquareFactory',
    'squareservice',
    'helperservice',
    'serverConstants',
    'authService',
    'spinnerservice',
    'featureservice',
  ];

  WelcomeMessage: string = '';
  WelcomeTitle: string = '';
  BaseColor: string = '#275B85';
  VideoStorage: number;
  squaresByProgram = [];
  squares = [];
  squareType = this.serverConstants.searchTypeConstants.square;

  constructor(
    private clientservice: ClientService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private selectedClientFactory: SelectedClientFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private squareservice: SquareService,
    private helperservice: HelperService,
    private serverConstants: ServerConstants,
    private authService: AuthService,
    private spinnerservice: SpinnerService,
    private featureService: FeatureService,
  ) {}

  async $onInit() {
    await this.setupSelectedClientFactory();
    this.selectedSquareFactory.resetSquareInfo();
    this.featureService.invalidateCache();

    try {
      this.spinnerservice.show('loading');
      this.squares = await this.squareservice.getSquareListForObserverOrModerator();
      this.squaresByProgram = this.groupSquaresByProgram(this.squares);

      const clientConfig =  await this.clientservice.getClientConfiguration(this.$stateParams.clientGuid);
      if (clientConfig) {
        this.WelcomeTitle = clientConfig.Title;
        this.WelcomeMessage = clientConfig.Message;
        this.BaseColor = clientConfig.Color || this.BaseColor;
        this.VideoStorage = clientConfig.VideoStorage;
      }

    }  finally {
      this.spinnerservice.hide('loading');
    }
  }

  private groupSquaresByProgram(squareList) {
    const squaresByProgramGuid = _.groupBy<any>(squareList, (s) => s.ProgramGuid);
    const result = [];
    for (const programGuid of _.keys(squaresByProgramGuid)) {
      const squares = squaresByProgramGuid[programGuid];
      result.push({
        programName: squares[0].ProgramName,
        squares,
      });
    }
    return result;
  }

  setupSelectedClientFactory() {
    return this.clientservice
      .getClientDetails(this.$stateParams.clientGuid)
      .then((response) => {
        const client = response.Detail;
        this.selectedClientFactory.setClientInfo({
          Id: client.Id,
          Name: client.AdminName || client.Name,
          Guid: client.Guid,
          VideoStorage: client.VideoStorage,
          Code: client.Code,
          RelatedClients: client.RelatedClients as IRelatedClientInfo[],
        });
      });
  }

  async goToSquareAdminView(square) {
    this.$state.go('root.square.activities', {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: square.ProgramGuid,
      squareGuid: square.Guid,
    });
  }

  async goToSquareParticipantView(square) {
    this.helperservice.goToSquareParticipantView(square.Url);
  }
}
