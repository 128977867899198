'use strict';

import { InstantRewardsComponent } from './instantRewards.component';
// import routeConfig from './instantRewards.route';

angular
  .module('insitesApp.squarerewards')
  .component('instantRewards', InstantRewardsComponent);
// routing commented out because feature shouldn't be used at the moment
// .run(routeConfig);

require('./details');
