'use strict';

import { AuthService } from '../../core/dataservices/auth.service';
import { ServerConstants } from '../../core/serverconstants';

export class SquareLogoutController {
  static $inject = ['authService', '$stateParams', 'serverConstants', '$state'];

  constructor(
    private authService: AuthService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
    private $state: ng.ui.IStateService,
  ) { }

  async $onInit() {
    const userToken = this.$stateParams.token;
    if (userToken) {
      await this.authService.setJwToken(userToken);
    }
    const redirectType: number = +this.$stateParams.redirectType;
    let redirectUrl: string;
    switch (redirectType) {
      case this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.squareLogin:
        redirectUrl = await this.authService.getSquareLogoutUrl();
        break;
      case this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.activities:
        redirectUrl = this.$state.href('root.square.activities.all',
          {
            clientGuid: this.$stateParams.clientGuid,
            programGuid: this.$stateParams.programGuid,
            squareGuid: this.$stateParams.squareGuid,

          });
        break;
      case this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.squareInfo:
        redirectUrl = this.$state.href('root.squareinfo',
          {
            clientGuid: this.$stateParams.clientGuid,
            programGuid: this.$stateParams.programGuid,
            squareGuid: this.$stateParams.squareGuid,

          }, { absolute: true });
        break;
      case this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.activityWizard:
        redirectUrl = this.$state.href('root.square.activityWizardType', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid: this.$stateParams.activityGuid,
          step: this.$stateParams.step,
        });
        break;
      case this.serverConstants.inSitesSquareLogoutRedirectTypeConstants.activityConversation:
        await this.$state.go('root.square.activitydata', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid: this.$stateParams.activityGuid,
          replyGuid: this.$stateParams.replyGuid,
          isEdit: this.$stateParams.isEdit,
        });
        return;
    }

    window.location.href = redirectUrl;
  }
}
