'use strict';

import { ProgramService } from '../../core/dataservices/program.service';
import { ServerConstants } from '../../core/serverconstants';
import { SelectedProgramFactory } from '../../core/selectedprogram.factory';
import { SpinnerService } from '../../core/services/spinner.service';
import * as _ from 'lodash';
import { DateFormatService } from '../../core/services/dateformat.service';
import { IProgramJobId } from '../../core/dataservices/program.service.contracts';
import { IProgramCredits } from '../../core/dataservices/program.service.contracts';
import { Pagination } from '../../core/models/pagination';
import { ProjectService } from '../../core/dataservices/project.service';
import { DateTime } from 'luxon';
import { Utils } from '../../core/utils';
import { AuthService } from '../../core/dataservices/auth.service';
import { UpdateProgramCreditsController } from './updateProgramCreditsDialog/updateProgramCreditsDialog.controller';
import { IPromise } from 'angular';
import { SquareService } from 'src/app/core/dataservices/square.service';
import ng = require('angular');

const updateProgramCreditsDialogTemplateUrl = require('./updateProgramCreditsDialog/updateProgramCreditsDialog.html');

export class ProgramInfoController implements ng.IOnInit {
  static $inject = ['$stateParams', 'logger', 'programservice', '$q', 'selectedProgramFactory', 'serverConstants', 'spinnerservice', 'dateFormatService'
    , 'projectservice', 'authService', '$mdDialog', 'squareservice'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
    private programservice: ProgramService,
    private $q: ng.IQService,
    private selectedProgramFactory: SelectedProgramFactory,
    private serverConstants: ServerConstants,
    private spinnerservice: SpinnerService,
    private dateFormatService: DateFormatService,
    private projectservice: ProjectService,
    private authService: AuthService,
    private $mdDialog: ng.material.IDialogService,
    private squareService: SquareService,
  ) { }
  programInfo;
  programSquareJobInfo;
  programCreditsInfo;
  isSaving: boolean;
  initial = {};
  validationConstants = this.serverConstants.validationConstants;
  model: any = {
    detail: {
      Name: '',
      CreditsReminderEmail: '',
    },
  };

  programJobIds: IProgramJobId[] = [];
  programCredits = [];
  pagination: IPagination = new Pagination();
  squareJobStatusFilter = [this.serverConstants.jobIdStatusConstants.active, this.serverConstants.jobIdStatusConstants.notStarted,
    this.serverConstants.jobIdStatusConstants.paused, this.serverConstants.jobIdStatusConstants.elapsed75Perc,
    this.serverConstants.jobIdStatusConstants.elapsed90Perc];
  initialProgramJobIds: IProgramJobId[];
  initialProgramCredits: any[];
  programCreditTypes = [this.serverConstants.programCreditsTypeConstants.native, this.serverConstants.programCreditsTypeConstants.decipher];
  programCreditTypesName = ['Native', 'Decipher'];
  jobIds: string[] = [];
  existingJobIds: string[] = [];
  existingCreditJobIds: string[] = [];

  $onInit(): void {
    this.logger.info('Program details activated');
    this.spinnerservice.show('loading');
    this.pagination.limit = 10;
    const promises = [this.getProgramDetails(), this.getProgramSquareJobs(), this.getProgramCredits(), this.getJobIds(), this.getUsedJobIds()];

    this.$q.all(promises).then(() => {
      this.logger.info('Program details loaded');
    }).finally(() => {
      this.spinnerservice.hide('loading');
    });
  }

  updateProgram = () => {
    type UpdateProgramResponse = ReturnType<typeof this.programservice.updateProgram>;
    type UpdateProgramSquareJobsResponse = ReturnType<typeof this.squareService.updateProgramSquareJobs>
    type CreateUpdateProgramCreditsResponse = ReturnType<typeof this.programservice.createUpdateProgramCredits>

    this.isSaving = true;
    const tasks: Array<UpdateProgramResponse | UpdateProgramSquareJobsResponse | CreateUpdateProgramCreditsResponse> =
      [this.programservice.updateProgram(this.model.detail), this.squareService.updateProgramSquareJobs(this.programJobIds)];

    // If no self serve was enabled and we didn't enable it just now, then we don't need to update program credits
    const noSelfServeEnabledBothInitialAndNew =
      !this.programJobIds.some((jobId) => jobId.IsSelfServe) // Check if we didn't enable it
      && _.isEqual(_.sortBy(this.programJobIds), _.sortBy(this.initialProgramJobIds)); // Check if it wasn't already enabled

    if (!noSelfServeEnabledBothInitialAndNew) {
      tasks.push(this.programservice.createUpdateProgramCredits(this.programCredits, this.$stateParams.programGuid));
    }

    this.$q.all(tasks).then(async (results) => {
      const updateProgramSquareJobsResponse = results[1] as Awaited<UpdateProgramSquareJobsResponse>;
      if (updateProgramSquareJobsResponse) {
        const programSquareJobs = updateProgramSquareJobsResponse.map((j) => ({
          SquareName: j.SquareName,
          IsSelfServe: j.IsSelfServe,
          Guid: j.Guid,
          JobId: j.JobId,
          SquareGuid: j.SquareGuid,
          StartDate: DateTime.fromISO(j.StartDate),
          Commitment: j.Commitment,
          Status: j.Status,
        }));

        this.programJobIds = angular.copy(programSquareJobs);
        this.initialProgramJobIds = angular.copy(programSquareJobs);
      }

      this.getProgramCredits();

      if (!results[0]) {
        return;
      }
      this.initial = angular.copy(this.model.detail);
      this.selectedProgramFactory.setProgramInfo(
        {
          Name: this.model.detail.Name,
          Guid: this.selectedProgramFactory.Guid,
        });
      if (this.programInfo) {
        this.programInfo.$setPristine();
      }
      if (this.programSquareJobInfo) {
        this.programSquareJobInfo.$setPristine();
      }
      if (this.programCreditsInfo) {
        this.programCreditsInfo.$setPristine();
      }

      this.logger.success('Program info saved successfully');
    }, (error) => {
      // Validation error
      if (error.status === 400) {
        const data = error.data;

        // Group by property name in case there is more than 1 error for that property
        // Ideally we should already group them in the backend
        const grouped = _.groupBy(data.ValidationErrors, 'PropertyName');
        _.forEach(grouped, (item, key) => {
          let message = '';
          _.forEach(item, (errorMessage) => {
            message += `${errorMessage.ErrorMessage} `;
          });
          this.programInfo[key].$setValidity('serverErrors', false);
          this.programInfo[key].errorMessage = message;
          this.programCreditsInfo[key].$setValidity('serverErrors', false);
          this.programCreditsInfo[key].errorMessage = message;
          this.programSquareJobInfo[key].$setValidity('serverErrors', false);
          this.programSquareJobInfo[key].errorMessage = message;
        });
      } else {
        this.logger.error("Program info couldn't be saved");
      }
    }).finally(() => {
      this.isSaving = false;
    });
  };

  getProgramDetails = () => this.programservice.getProgramDetails(this.$stateParams.programGuid).then((response) => {
    const data = response;
    this.initial = {
      ...data.Detail,
      Type: 'program',
    };
    this.model.detail = angular.copy(this.initial);
  });

  getProgramSquareJobs = () => this.squareService.getSquareJobs(this.$stateParams.programGuid).then((response) => {
    const programSquareJobs = response.map((j) => ({
      SquareName: j.SquareName,
      Commitment: j.Commitment,
      JobId: j.JobId,
      Status: j.Status,
      IsSelfServe: j.IsSelfServe,
      StartDate: this.dateFormatService.convertDate(j.StartDate),
      Guid: j.Guid,
      SquareGuid: j.SquareGuid,
    }));
    this.programJobIds = angular.copy(programSquareJobs);
    this.initialProgramJobIds = angular.copy(programSquareJobs);
  });

  getProgramCredits = () => this.programservice.getProgramCredits(this.$stateParams.programGuid).then((response) => {
    const programCredits = response.map((p) => ({
      CreditJobId: p.CreditJobId,
      Type: this.getNameOfType(p.Type),
      TotalCredits: p.TotalCredits,
      StartDate: this.dateFormatService.convertDate(p.StartDate),
      CreditsLeft: p.CreditsLeft,
      DateExpired: p.DateExpired,
      Guid: p.Guid,
    }));
    this.programCredits = angular.copy(programCredits);
    this.initialProgramCredits = angular.copy(programCredits);
  });

  filterStatusses() {
    return (item) => this.squareJobStatusFilter.some((s) => s === item.Status);
  }

  rollBackForm = (programInfoForm, programCreditsInfoForm, programSquareJobInfoForm) => {
    this.model.detail = angular.copy(this.initial);
    this.programJobIds = angular.copy(this.initialProgramJobIds);
    this.programCredits = angular.copy(this.initialProgramCredits);
    this.squareJobStatusFilter = [this.serverConstants.jobIdStatusConstants.active, this.serverConstants.jobIdStatusConstants.elapsed75Perc
      , this.serverConstants.jobIdStatusConstants.elapsed90Perc];
    programInfoForm.$setPristine();
    programCreditsInfoForm.$setPristine();
    programSquareJobInfoForm.$setPristine();
  };

  hasProgramCreditsOrSelfServeJobs(): boolean {
    if (this.programJobIds && this.programCredits) {
      return this.programJobIds.some((p) => p.IsSelfServe === true) || _.size(this.programCredits) > 0;
    }
    return false;
  }

  hasSelfServeJobs(): boolean {
    if (this.programJobIds) {
      return this.programJobIds.some((p) => p.IsSelfServe === true);
    }
    return false;
  }

  addCredits(): void {
    const programCredit: IProgramCredits = {
      ProgramGuid: this.$stateParams.programGuid,
      CreditJobId: undefined,
      Type: undefined,
      TotalCredits: undefined,
      StartDate: undefined,
      CreditsLeft: undefined,
      DateExpired: undefined,
      Guid: '',
    };
    this.programCredits.push(programCredit);
    this.programCreditsInfo.$setDirty();
  }

  deleteCredit(index: number): void {
    this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Warning')
        .text('Job ID may be in use in Activities where credits are configured. \
        Removal can impact the budget. It will be replaced with the most recent Job ID available. \
        Please revise the link between this Job ID and the corresponding Activities if needed.')
        .ok('Yes')
        .cancel('No'),
    ).then(() => {
      this.programCredits.splice(index, 1);
      this.programCreditsInfo.$setDirty();
    });
  }

  getNameOfType(type: number): string {
    switch (type) {
      case 0: return 'Native';
      case 1: return 'Decipher';
    }
  }

  getJobIds(): IPromise<void> {
    return this.projectservice.getJobIds().then((response) => {
      this.jobIds = response.data.List
        .map((job) => job.JobId);
    });
  }

  // Gets all JobIds used for credits on all programs
  getUsedJobIds(): IPromise<void> {
    return this.programservice.getAllUsedCreditJobIds().then((response) => {
      this.existingCreditJobIds = response.List.map((job) => job.JobId);
    });
  }

  getRemainingJobIds(): void {
    const existingJobIds = this.programCredits.map((b) => b.CreditJobId).slice(0, -1);
    const ids = _.uniq(this.programJobIds.map((p) => p.JobId));
    this.existingJobIds = _.uniq(this.jobIds.filter((e) => !existingJobIds.includes(e) && !ids.includes(e) && !this.existingCreditJobIds.includes(e)));
  }

  getCorrectStatus(status: number): string {
    return Utils.getNameOfStatus(status);
  }

  openCreateMemberModal(): void {
    this.$mdDialog.show({
      controller: UpdateProgramCreditsController,
      controllerAs: 'vm',
      templateUrl: updateProgramCreditsDialogTemplateUrl,
      clickOutsideToClose: false,
      escapeToClose: false,
    },
    );
  }

  hasCorrectAuthorisation(): boolean {
    return this.authService.hasFullAccess() || this.authService.isDeveloper();
  }

  getCorrectDateFormat(date: any): DateTime {
    return this.dateFormatService.convertDate(date);
  }

  hasCreditWithNegativeCreditSaldo() {
    return this.programCredits.some((pc) => pc.CreditsLeft < 0);
  }
}
