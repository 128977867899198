'use strict';

import { DateTime } from 'luxon';
import { IActivityFilterAdditionalData, IActivityFilterData } from '../../core/contracts/activity.contract';
import { ActivityFilterService } from '../../core/services/activityFilter.service';
import { GoogleTranslateHelperService } from '../../layout/google-translate-helper/google-translate-helper.service';
import { ConversationEventsService } from '../../core/services/conversationEvents.service';
import { ServerConstants } from '../../core/serverconstants';
import { QualConversationExportParams } from '../../core/contracts/download.contract';
import { ActivityService } from '../../core/dataservices/activity.service';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { ActivityHelperService } from 'src/app/core/services/activityHelper.service';

export class ActivityDataController {
  static $inject = ['$scope', '$stateParams',
    '$state', 'serverConstants', 'googleTranslateHelper',
    'activityFilterService', '$location',
    'conversationEventsService', 'activityservice',
    'authService', 'featureservice', 'activityHelperService',
  ];

  constructor(
    private $scope: ng.IScope,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private serverConstants: ServerConstants,
    private googleTranslateHelper: GoogleTranslateHelperService,
    private activityFilterService: ActivityFilterService,
    private $location: ng.ILocationService,
    private conversationEventsService: ConversationEventsService,
    private activityservice: ActivityService,
    private authService: AuthService,
    private featureService: FeatureService,
    private activityHelperService: ActivityHelperService,
  ) {
  }
  downloadParams: QualConversationExportParams = new QualConversationExportParams();
  filterDataCounters;
  qualFilter: IActivityFilterData;
  filterAdditionalData: IActivityFilterAdditionalData;
  minStartDate: DateTime;
  maxEndDate: DateTime;
  goBackActLabel = '';

  importFeature: Awaited<ReturnType<typeof this.featureService.getFeatureSettings>> = null;
  activityType: number;
  visibility: number;
  contributionType: number;

  // when coming from "replies" button, the referer from state params is getting lost, perhaps in the sidenav logic
  // so, we are doubling it inside this component
  referer = undefined;

  activityGuid;
  loading = true;
  subscriptions = [];
  _downloadAvailability: number = this.serverConstants.conversationsDownloadAvailabilitiesConstants.none;
  loadFilteredRepliesHasBeenCalled = false;

  async $onInit() {
    const newGoToState = this.activityservice.getModerationAndAnalysisDiscussionType();

    this.activityGuid = this.$stateParams.activityGuid;
    this.downloadParams.activityGuid = this.activityGuid;
    this.subscriptions.push(
      this.conversationEventsService.dataConversationsLoadingChange.subscribe((loading) => {
        this.loading = loading;

        // If the loading stops and the loadFilteredReplies method has been called in the meantime, call it again to make sure the initial load works.
        if (!loading && this.loadFilteredRepliesHasBeenCalled) {
          this.loadFilteredReplies();
        }
      }),
      this.conversationEventsService.filterDataCountersChange.subscribe((result) => {
        this.filterDataCounters = result;
        this.downloadParams.applyQualFilter(JSON.parse(JSON.stringify(this.qualFilter)));
        this.downloadParams.SortOption = this.sort;
        this.downloadParams.stimuliDownloadAvailable = this.filterDataCounters.HasDownloadableVideo ||
          this.filterDataCounters.HasDownloadableImage;
        this.downloadParams.textDownloadAvailable = this.filterDataCounters.ConversationsCount;
      }),
      this.conversationEventsService.qualFilterChange.subscribe((result) => this.qualFilter = result),
      this.conversationEventsService.filterAdditionalDataChange.subscribe((result) => {
        this.filterAdditionalData = result;
        this.downloadParams.isModeratorCurationEnabled = result.IsModeratorCurationEnabled;
      }),
      this.conversationEventsService.discussionActivityTypeChange.subscribe((result) => {
        this.activityType = result.activityType;
        this.contributionType = result.contributionType;
        this.visibility = result.visibility;
      }),
    );

    this.googleTranslateHelper.autoEnableForScope(this.$scope);
    if (this.$stateParams.referer && this.$stateParams.refererlabel) {
      this.goBackActLabel = this.$stateParams.refererlabel;
    } else {
      this.goBackActLabel = 'GO TO SQUARE ACTIVITIES';
    }

    this.importFeature = await this.featureService
      .getFeatureSettings(this.serverConstants.featureConstants.customDiscussionImport);

    this.loadFilteredReplies();

    // double the referer just in case something else messes with the one in state params
    this.referer = this.$stateParams.referer;

    this.$location.replace();
    this.$state.go(newGoToState,  this.$stateParams, { location: 'replace' });
  }

  $onDestroy() {
    this.subscriptions.map((sub) => sub.unsubscribe());
  }

  loadFilteredReplies = () => {
    this.loadFilteredRepliesHasBeenCalled = true;
    if (!this.loading) {
      this.qualFilter.Highlight = true;
      this.conversationEventsService.dataConversationsFilterApply.next(this.qualFilter);
    }
  };

  goBackAct() {
    // state params or our stored referer
    if (this.$stateParams.referer || this.referer) {
      this.$location.url(this.$stateParams.referer || this.referer);
    } else {
      const filterParams = this.activityFilterService.getQualStateParamsFromFilter(this.qualFilter);
      delete filterParams.probequestions;
      this.$state.go('root.square.activities.qual',
        {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          ...filterParams,
        });
    }
  }

  resetFilter() {
    this.qualFilter = {
      Keyword: '',
      Highlight: false,
      SelectedThemes: [],
      SelectedMembers: [],
      StartDate: this.minStartDate,
      EndDate: this.maxEndDate,
      SelectedRatings: [],
      SelectedModerationStatusOptions: [],
      SelectedAnnotationsOptions: [],
      SelectedStimuliOptions: [],
      TagsSelected: [],
      HashtagsSelected: [],
      SelectedSegments: [],
      SelectedProbeQuestions: [],
      SelectedSegmentsOption: this.serverConstants.selectedSegmentsOptionConstants.any,
    };
    this.loadFilteredReplies();
  }

  get sort(): number {
    const defaultSortOption = this.serverConstants.conversationSortOptionConstants.chronological;
    return this.$stateParams.sort === undefined ? defaultSortOption : parseInt(this.$stateParams.sort, 10);
  }

  get downloadAvailability(): number {
    let _downloadAvailability: number = this.serverConstants.conversationsDownloadAvailabilitiesConstants.none;
    if (this.filterDataCounters) {
      if (this.filterDataCounters.ConversationsCount) {
        _downloadAvailability |= this.serverConstants.conversationsDownloadAvailabilitiesConstants.text;
      }

      if (this.filterDataCounters.HasDownloadableVideo ||
        this.filterDataCounters.HasDownloadableImage) {
        _downloadAvailability |= this.serverConstants.conversationsDownloadAvailabilitiesConstants.stimuli;
      }
    }

    return _downloadAvailability;
  }

  // We should show some things like the title and back button only for angular activities
  get isAngularActivity(): boolean {
    return this.activityservice.getModerationAndAnalysisDiscussionType() === 'root.square.activitydata.conversations';
  }

  get canImportData(): boolean {
    // only FullDev has access to the import
    // and only for private discussions with multiple contributions
    if (!this.authService.isFullDev()
      || !this.importFeature
      || !this.importFeature.isMasterSwitchEnabled) {
      return false;
    }

    return this.activityHelperService.isPrivateDiscussion(this.activityType, this.visibility) &&
      this.contributionType === this.serverConstants.activityContributionTypeConstants.diary;
  }
}
