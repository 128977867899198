'use strict';

import { ClientSelectionController } from './client-selection.controller';
const template = require('./client-selection.html');

export const ClientSelectionComponent: ng.IComponentOptions = {
  controller: ClientSelectionController,
  templateUrl: template,
  controllerAs: 'vm',
};
