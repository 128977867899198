'use strict';

import { RouterHelper, StateConfig } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  const states: Array<{ state: string, config: StateConfig }> = [
    {
      state: 'root.activation',
      config: {
        url: 'activation/:objectId',
        title: 'Activation',
        views: {
          '': {
            component: 'activationPage',
          },
        },
        isVue: true,
        allowAnonymous: true,
      },
    },
  ];

  routerHelper.configureStates(states);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;
