'use strict';

import { ConsentExpirationBannerController } from './consent-expiration-banner.controller';
const template = require('./consent-expiration-banner.html');
require('./_consent-expiration-banner.scss');

angular.module('insitesApp.squaremembers.memberOverview')
  .component('consentExpirationBanner', {
    controller: ConsentExpirationBannerController,
    controllerAs: 'vm',
    templateUrl: template,
    bindings: {
      activityName: '<',
      activityGuid: '<',
    },
  });
