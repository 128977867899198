'use strict';

export class ScrollToFixed implements ng.IDirective {
  restrict = 'A';
  link(scope, element, attrs) {
    const stickyElement = angular.element(attrs.scrollComponent ? element[0].childNodes[0] : element[0]);
    stickyElement.addClass('sticky');
    const offesetParent = angular.element(element[0].offsetParent);
    offesetParent.on('scroll', function() {
      if (this.scrollTop >= this.offsetTop && attrs.scrollShadow) {
        stickyElement.addClass('sticky-shadow');
      }
      if (this.scrollTop < this.offsetTop && attrs.scrollShadow) {
        stickyElement.removeClass('sticky-shadow');
      }
    });
  }
}
