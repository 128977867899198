'use strict';

import { IStimuliIgnoreFilter } from '../../../../../../core/filters/stimuli-ignore.filter';
import { ImageCropService } from '../../../../../../layout/image-crop/image-crop.service';
import { ConfigurationService } from '../../../../../../core/dataservices/configuration.service';
import { IFileConfigurationResponse } from '../../../../../../core/contracts/configuration.contracts';
import { ServerConstants } from 'src/app/core/serverconstants';

export class FileInputController {
  static $inject = ['_', 'serverConstants', 'imageCropService', '$scope', 'stimuliIgnoreFilter', 'iscConfigurationService'];
  constructor(
    private _: _.LoDashStatic,
    private serverConstants: ServerConstants,
    private imageCropService: ImageCropService,
    private $scope: ng.IScope,
    private stimuliIgnoreFilter: IStimuliIgnoreFilter,
    private iscConfigurationService: ConfigurationService,
  ) {
    this.validationConstants = this.serverConstants.validationConstants;
  }
  invalidFiles = [];
  validationConstants;
  ngModel;
  files;
  isReadOnly: boolean;
  change;
  imageStimuliMaxSizeBytes: number;
  videoStimuliMaxSizeBytes: number;
  fileTypeConstants;
  fileConfiguration: IFileConfigurationResponse[] = [];
  acceptedMimeTypes: string[];
  acceptedMimeTypesString: string;
  acceptedPatterns: string;
  acceptedExtensions: string;

  $onInit() {
    this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
    this.iscConfigurationService.getFileConfiguration()
      .then((response) => {
        this.fileConfiguration = response;
        this.imageStimuliMaxSizeBytes = this._.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.image).MaxFileSizeMb * Math.pow(1024, 2);
        this.videoStimuliMaxSizeBytes = this._.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.video).MaxFileSizeMb * Math.pow(1024, 2);

        const { mimeTypes, patterns, extensions } = this.iscConfigurationService
          .getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image, this.fileTypeConstants.video]);
        this.acceptedMimeTypes = mimeTypes;
        this.acceptedMimeTypesString = mimeTypes.toString();
        this.acceptedPatterns = `'${patterns.toString()}'`;
        this.acceptedExtensions = extensions.toString();
      });
  }

  removeFile(file) {
    this.invalidFiles = [];
    if (file.source === this.serverConstants.communicationConstants.stimuliSourceUpload) {
      const index = this.ngModel.$modelValue.indexOf(file);
      this.ngModel.$modelValue.splice(index, 1);
    } else {
      file.action = this.serverConstants.communicationConstants.stimuliActionDelete;
    }

    if (file.imgUrl && this.files.length > 0) {
      const fileIndex = this.files.map((f) => f.$ngfBlobUrl).indexOf(file.imgUrl.$ngfBlobUrl);
      if (fileIndex >= 0) {
        this.files.splice(fileIndex, 1);
        if (this.change) {
          setTimeout(() => {
            this.$scope.$apply(() => this.change());
          }, 200);
        }
      }
    }
    this.ngModel.$validate();
  }

  isImageFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const imageMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('image') === 0);
      result = imageMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  isVideoFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const videoMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('video') === 0);
      result = videoMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  getVideoStimulusDisplaySrc(file): string {
    return (typeof file.imgUrl === 'string') ? file.imgUrl : 'images/video-placeholder.png';
  }

  async validate($files, $newFiles, $invalidFiles) {
    if (!$newFiles) {
      return;
    }

    for (let file of $newFiles) {
      this._.pull($files, file);
      this.invalidFiles = [];
      const isImageFile = this.isImageFile(file);

      if ((isImageFile && file.size > this.imageStimuliMaxSizeBytes) ||
        (this.isVideoFile(file) && file.size > this.videoStimuliMaxSizeBytes)) {
        file.$errorMessages = file.$errorMessages || {};
        file.$errorMessages.maxSize = true;
        this.invalidFiles.push(file);
        $invalidFiles.splice(0);
      } else if (file.$errorMessages) {
        this.invalidFiles.push(file);
        $invalidFiles.splice(0);
      } else if (this.isCommunicationStimuliLimitReached()) {
        this.invalidFiles.push(file);
        file.$errorMessages = { maxFiles: true };
      } else {
        if (isImageFile) {
          file = await this.imageCropService.cropStimuli(file);
        }

        this.ngModel.$viewValue.push({
          source: this.serverConstants.communicationConstants.stimuliSourceUpload,
          imgUrl: file,
          action: this.serverConstants.communicationConstants.stimuliActionIgnore,
          type: file.type,
          hashKey: file.$$hashkey,
        });

        this.files.push(file);
        if (this.change) {
          setTimeout(() => {
            this.$scope.$apply(() => this.change());
          }, 200);
        }
      }
    }
    this.ngModel.$validate();
  }

  isCommunicationStimuliLimitReached() {
    const files = this.stimuliIgnoreFilter(this.ngModel.$viewValue);
    return files.length > 0;
  }
}
