'use strict';

import { IActivityFilterData, IActivityFilterAdditionalData } from './../../../core/contracts/activity.contract';
import { HelperService } from './../../../core/services/helper.service';
import { ActivityService } from '../../../core/dataservices/activity.service';
import { ActivityFilterService } from '../../../core/services/activityFilter.service';
import { ActivityHelperService } from '../../../core/services/activityHelper.service';
import { CurrentPageInformation } from '../../../core/services/currentPageInformation';
import { SquareActivitiesDragService } from '../squareActivitiesDrag.service';
import { AuthService } from '../../../core/dataservices/auth.service';
import { ActivitiesOverviewControllerBase } from '../common/activitiesOverviewControllerBase';
import { SpinnerService } from '../../../core/services/spinner.service';
import { ServerConstants } from '../../../core/serverconstants';
import { ConstantsFactory } from '../../../core/constants.factory';
import { DateFormatService } from '../../../core/services/dateformat.service';
import { FeatureService } from '../../../core/dataservices/feature.service';
import { DateTime } from 'luxon';
import { CurrentUserService } from 'src/app/core/dataservices/currentUser.service';

export class ActivitiesAllTabController extends ActivitiesOverviewControllerBase {
  static $inject = ['helperservice', 'spinnerservice', 'serverConstants', '$stateParams', 'activityservice',
    'activityHelperService', 'authService', 'squareActivitiesDragService', 'constantsfactory', '$mdDialog', 'logger',
    'dateFormatService', '$location', '$rootScope', '$state', 'featureservice', 'activityFilterService', 'currentUserService'];

  constructor(
    private helperservice: HelperService,
    spinnerservice: SpinnerService,
    serverConstants: ServerConstants,
    $stateParams: ng.ui.IStateParamsService,
    activityservice: ActivityService,
    private activityHelperService: ActivityHelperService,
    private authService: AuthService,
    private squareActivitiesDragService: SquareActivitiesDragService,
    constantsfactory: ConstantsFactory,
    $mdDialog: ng.material.IDialogService,
    logger: Logger,
    dateFormatService: DateFormatService,
    $location: ng.ILocationService,
    $rootScope: ng.IRootScopeService,
    $state: ng.ui.IStateService,
    featureservice: FeatureService,
    activityFilterService: ActivityFilterService,
    currentUserService: CurrentUserService,
  ) {
    super(activityservice,
      constantsfactory,
      serverConstants,
      $mdDialog,
      spinnerservice,
      logger,
      dateFormatService,
      $location,
      $rootScope,
      $stateParams,
      $state,
      featureservice,
      activityFilterService,
      currentUserService);
  }

  hierarchicalActivities = [];
  activities = [];
  activityStatusFilter = ['Draft', 'Scheduled', 'Active', 'Closed'];

  squareActivitiesComponent;

  activityType = Object.values(this.serverConstants.squareActivityTypeConstants);
  matches = -1;
  matchesFrom: -1;
  search;
  treeOptions;
  isDragEnabled = true;
  type = this.serverConstants.searchTypeConstants.activity;
  showAnalysisAndReporting : boolean;
  isSavingUpEnabled: boolean;

  async $onInit() {
    this.isSavingUpEnabled = await this.featureservice.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards);
    this.showAnalysisAndReporting = await this.isAnalysisAndReportingEnabled();
    this.parseParameters();
    await this.getLastValidDate();
    this.treeOptions = this.squareActivitiesDragService.getDragOptions(() => {
      this.loadActivities(false);
    }, () => this.getCurrentPageInfo());
  }

  async getLastValidDate() {
    this.activityservice.getFilterDateRangeForActivity(this.activityType).then((response) => {
      this.minStartDate = DateTime.fromISO(response.data.StartDate);
      this.maxEndDate = DateTime.fromISO(response.data.EndDate);
      this.setInitialFilterData();
    });
  }

  getCurrentPageInfo(): CurrentPageInformation {
    const currentPageInformation: CurrentPageInformation = {
      pagination: this.pagination,
    };
    return currentPageInformation;
  }

  async setInitialFilterData() {
    this.filterAdditionalData = this.activityFilterService.createFilterAdditionalData(this.minStartDate, this.maxEndDate) as IActivityFilterAdditionalData;
    this.filterData = this.activityFilterService.getFilterFromQueryString(this.minStartDate, this.maxEndDate, this.activityType) as IActivityFilterData;
    this.squareActivitiesComponent.filterData = this.filterData;
    if (this.filterData.Statuses.length === 0) {
      this.filterData.Statuses = this.activityStatusFilter;
    }

    this.loadActivities();
  }

  loadActivities(dragStatusWhileLoading: boolean = true) {
    this.isDragEnabled = dragStatusWhileLoading;
    const request = JSON.parse(JSON.stringify(this.filterData ?? {}));
    this.spinnerservice.show('loading');
    this.activityservice.getSquareActivities(this.activityType, request, this.pagination.page, this.pagination.limit, this.order)
      .then((activities) => {
        if (activities && activities.List && activities.List.Items) {
          this.pagination.total = activities.List.TotalItems;
          this.activityHelperService.convertEnumsToStringForActivities(activities.List.Items);
          this.hierarchicalActivities = activities.List.Items;
          this.activities = this.activityHelperService.getFlattenedActivities(activities.List.Items);
          this.activities.forEach((activity) => {
            if (activity.EndDate) {
              activity.EndDate = this.dateFormatService.getDateTime(activity.EndDate, activity.Offset);
            }

            this.setDownloadParams(activity, this.filterData);
          });
          this.filterDataCounters = activities.FilterDataCounters;
          this.matches = activities.Matches;
          this.matchesFrom = activities.MatchesFrom;
        }
      })
      .finally(() => {
        this.spinnerservice.hide('loading');
        this.isDragEnabled = true;
      });
  }

  statusFilter = ((activity) => this.activityHelperService.isActivityDisplayed(activity, this.filterData.Statuses, this.activities));

  goToActivity(activity, event: Event) {
    // Fix for accessibility issue with md-menu in clickable tr
    if ((event.target as Element).nodeName === 'BUTTON') {
      return;
    }
    if (this.hasAccess(activity)) {
      this.filterData.ActivityType = activity.Type;
      const filterParams = this.activityFilterService.getStateParamsFromFilter(this.filterData);
      const stateName = this.$state.current.name;
      const referer = this.$state.href(stateName, {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
        ...filterParams,
      });
      this.$rootScope.$emit('sidenavActivityRefererChanged', this.$location.url());
      this.helperservice.goToEntity('activity', activity, {
        stateParams: {
          referer,
          ...filterParams,
        },
      });
    }
  }

  hasAccess(activity) {
    return !this.authService.isObserverUserLogin() || (activity.Status !== this.serverConstants.squareActivityStatusConstants.draft);
  }

  applyFilter() {
    this.filterData.Highlight = true;
    this.loadActivities();
  }

  getMatchLabel(count) {
    return count === 1 ? 'MATCH' : 'MATCHES';
  }

  resetFilter() {
    this.activityFilterService.resetFilterData(this.filterData, this.filterAdditionalData.MinStartDate);
    this.applyFilter();
    this.filterData.Highlight = false;
    this.filterData.Keyword = '';
  }

  anyActivities() {
    return this.activities !== undefined && this.activities.length === 0;
  }

  anyMatchingConversations(activity) {
    return activity.ActivityConversationFilterResult.MatchingConversations !== -1;
  }

  anyMatchesCount() {
    return this.matches !== -1 && this.matchesFrom !== -1;
  }

  showArchivedButton(activity) {
    return this.authService.isHuman8UserLogin() && activity.ShowArchivedButton;
  }

  async statusFilterChange(value) {
    if (value.toString() !== this.filterData.Statuses.toString()) {
      this.filterData.Statuses = value;
      this.loadActivities();
    }
  }
}
