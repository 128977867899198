'use strict';

import { HttpService } from './http.service';

export class NotificationService {
  static $inject = ['httpservice', '$stateParams'];

  constructor(
    private httpservice: HttpService,
    private $stateParams,
  ) { }

  // //////////////

  getEmailTemplate(emailType) {
    return this.httpservice.get({
      url: `/NotificationService/GetEmailTemplate?emailType=${emailType}`,
    }).then((response) => response.data);
  }

}
