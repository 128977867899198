'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.activities',
      config: {
        url: '/activities',
        title: 'Activities',
        abstract: true,
        component: 'squareActivitiesComponent',
        default: '.all',
        settings: {
          nav: 3,
          icon: 'fa fa-flag',
          label: 'Activities',
          showForums: true,
          section: 'SQUARE MANAGEMENT',
        },
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

