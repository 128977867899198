'use strict';

import { CheckmarketIdController } from './checkMarketId.controller';
const checkmarketIdTemplate = require('./checkmarketId.html');

export const checkmarketIdComponent: ng.IComponentOptions = {
  controller: CheckmarketIdController,
  templateUrl: checkmarketIdTemplate,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    isDisabled: '<',
  },
  require: {
    form: '^^form',
  },
};
