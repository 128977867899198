'use strict';

import { SquareService } from '../../../../../core/dataservices/square.service';
export class ThemeController {

  static $inject = ['squareservice', 'serverConstants', '_', '$scope', 'logger'];
  editOn = false;
  pageThemes;
  errorMessage = '';
  hasErrors;
  constructor(
    private squareservice: SquareService,
    private serverConstants,
    private _: _.LoDashStatic,
    private $scope,
    private logger: Logger,
  ) {
  }
  dragControlListeners = {
    orderChanged: () => {
      this.themeOrderChanged();
    },
    placeholder: this.getDragPlaceholder,
  };

  themeOrderChanged() {
    this.setPageDirty(this.$scope.$parent.pageEditor.selectedPageIndex);
  }

  getDragPlaceholder(scope) {
    // Populating the floating placeholder
    return `<tr md-row style="display:table-row">
    <td md-cell>${scope.theme.Name}</td>
    <td md-cell>${scope.theme.ActivitiesCount}</td>
    <td md-cell>${scope.theme.SortOrder}</td>
    <td md-cell class="themes-action-col">
      Actions
    </td>
  </tr>`;
  }

  async $onInit() {
    let responseThemes = await this.squareservice.getThemes();
    this._.forEach<any[]>(responseThemes,
      (resultItem: any) => {
        resultItem.hideTheme = false;
      });
    responseThemes = this._.sortBy(responseThemes, 'SortOrder');
    this.pageThemes = responseThemes;
  }

  editTheme(theme) {
    // Keep this theme locked, as it is the default theme
    if (theme.Name === this.serverConstants.squareConstants.uncategorizedThemeName) {
      theme.IsDefaultTheme = true;
    }
    theme.edit = true;
    this.editOn = true;
    // Sets page invalid in order to disable save button while editing
    this.setPageInvalid(this.$scope.$parent.pageEditor.selectedPageIndex);
  }

  deleteTheme(theme) {
    if (theme.ActivitiesCount > 0) {
      this.errorMessage = "This theme has published activities so it can't be deleted. Assign those activities to another theme before doing this !";
      this.hasErrors = true;
      return;
    }
    // Sets this tab as dirty to trigger save button
    this.setPageDirty(this.$scope.$parent.pageEditor.selectedPageIndex);
    this._.pull(this.pageThemes, theme);
  }

  saveTheme(theme) {
    this.hasErrors = false;
    if (theme.Name.length <= 0 || theme.Name === null) {
      this.errorMessage = this.serverConstants.validationConstants.activityThemeEmptyValidationMessage;
      this.hasErrors = true;
    }
    if (theme.Name.length > this.serverConstants.validationConstants.activityThemeNameMaxLength) {
      this.errorMessage = 'Theme name too large';
      this.hasErrors = true;
    }
    this._.each<any[]>(this.pageThemes,
      (listTheme: any) => {
        if (listTheme === theme) {
          return;
        }
        if (listTheme.Name === theme.Name) {
          this.errorMessage = 'There is another theme with the same Name !';
          this.hasErrors = true;
        }
      });
    if (this.hasErrors) {
      return;
    }
    this.setPageDirty(this.$scope.$parent.pageEditor.selectedPageIndex);
    theme.edit = false;
    this.editOn = false;
    this.setPageValid(this.$scope.$parent.pageEditor.selectedPageIndex);
  }

  addTheme() {
    this.editOn = true;
    const newtheme = {
      Name: '',
      ActivitiesCount: 0,
      SortOrder: this.pageThemes.length,
      edit: true,
      hideTheme: false,
      IsDefaultTheme: false,
    };
    this.pageThemes.push(newtheme);
    // Sets page invalid in order to disable save button while editing
    this.setPageInvalid(this.$scope.$parent.pageEditor.selectedPageIndex);
  }

  isUncategorizedOrDefault(theme) {
    if (theme.Name === this.serverConstants.squareConstants.uncategorizedThemeName && theme.Guid === null
      || theme.IsDefaultTheme === true) {
      return true;
    }
  }

  setPageDirty(index) {
    const tab = `tab-form-${index}`;
    if (!this._.isUndefined(this.$scope.$parent.pageEditor.form[tab])) {
      this.$scope.$parent.pageEditor.form[tab].$setDirty();
    }
  }

  setPageInvalid(index) {
    const tab = `tab-form-${index}`;
    if (!this._.isUndefined(this.$scope.$parent.pageEditor.form[tab])) {
      this.$scope.$parent.pageEditor.form[tab].$setValidity('editingThemes', false);
    }
  }

  setPageValid(index) {
    const tab = `tab-form-${index}`;
    if (!this._.isUndefined(this.$scope.$parent.pageEditor.form[tab])) {
      this.$scope.$parent.pageEditor.form[tab].$setValidity('editingThemes', true);
    }
  }
}
