'use strict';

import './clientConfiguration.module';
import appRunClientConfiguration from './clientConfiguration.route';
import './welcomeMessageConfiguration';
import './videoStorageConfiguration';
import './designConfiguration';

angular
  .module('insitesApp.clientConfiguration')
  .run(appRunClientConfiguration);
