'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from '../../../../../../core/serverconstants';
import * as _ from 'lodash';
import { DateFormatService } from '../../../../../../core/services/dateformat.service';
import { CommunicationService } from '../../../../../../core/dataservices/communication.service';
import { ForumService } from '../../../../../../core/dataservices/forum.service';
import { ConfigurationService } from 'src/app/core/dataservices/configuration.service';
import { IFileConfigurationResponse } from 'src/app/core/contracts/configuration.contracts';

export class NewsCardController {
  static $inject = [
    'dateFormatService', 'serverConstants', 'communicationservice',
    '$stateParams', 'logger', 'forumservice', 'iscConfigurationService'];

  constructor(
    private dateFormatService: DateFormatService,
    private serverConstants: ServerConstants,
    private communicationservice: CommunicationService,
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
    private forumservice: ForumService,
    private iscConfigurationService: ConfigurationService,
  ) { }
  removeLink;
  wizardStep;
  channelType;
  form;
  validationConstants = this.serverConstants.validationConstants;
  communicationChannelConstants = this.serverConstants.communicationChannelConstants;
  imageMimeTypes = ['image/png', 'image/jpeg', 'image/jpeg'];
  videoMimeTypes = [
    'video/x-ms-wmv', 'video/mp4', 'video/x-msvideo', 'video/3gpp', 'video/mpeg',
  ];
  invalidFiles = [];
  newsPageList = [];
  fileValidationErrors = [];
  minDateTime: DateTime;
  showNewsPageSelection = false;
  endDateEnabledConstants = {
    No: 0,
    Yes: 1,
  };
  endDateEnabled = this.endDateEnabledConstants.No;
  model = {
    Guid: '',
    Title: '',
    Overview: '',
    Message: '',
    Files: [],
    StartDateTime: DateTime.now(),
    StartTime: DateTime.now(),
    StartDateOffset: DateTime.local().offset / 60,
    EndDateTime: DateTime.now().plus({ months: 1 }),
    EndTime: DateTime.now().plus({ months: 1 }),
    EndDateOffset: DateTime.local().offset / 60,
    Stimuli: [],
    NewsPageGuid: '',
    Sticky: false,
    ShowOnHomepage: false,
  };
  communication;
  isReadOnly;
  saveNotApplicable = false;
  saveCallback = async () => await this.saveData(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm();
  fileConfiguration: IFileConfigurationResponse[] = [];

  async $onInit() {
    this.removeLink = this.wizardStep.linkComponent(`channel-${this.channelType}`, this);
    this.newsPageList = await this.communicationservice.getListNewsPages();
    this.iscConfigurationService.getFileConfiguration().then((response) => this.fileConfiguration = response);
    this.$loadData(this.communication);
  }

  $onDestroy() {
    this.removeLink();
  }

  resetForm() {
    this.$loadData(this.communication);
    this.form.$setPristine();
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    if (!this.isReadOnly && this.communication.Channels[0].Channel.IsPublished) {
      return '';
    }
    return this.serverConstants.squareConstants.wizardNotPublishedStatus;
  }

  $wizardStepIsCompleted() {
    let isCompleted = true;
    if (this.model.Title === '' || this.model.Message === '' || this.model.Message === null
      || !this.model.Stimuli || this.model.Stimuli.length === 0
      || (this.model.Stimuli.length > 0
        && this.model.Stimuli[this.model.Stimuli.length - 1].action === this.serverConstants.communicationConstants.stimuliActionDelete)) {
      isCompleted = false;
    }
    return isCompleted;
  }

  $wizardIsValid() {
    if (!_.isUndefined(this.form)) {
      return this.form.$valid;
    }
  }

  async $wizardBeforeNext() {
    await this.saveData(this);
  }

  $wizardStepLoaded() {
    const data = this.communication.Channels[0];
    if (data !== undefined && this.model.Guid !== undefined) {
      this.model.Guid = data.Channel.Guid;
    }
  }

  async saveData(root: this) {
    const request = {
      Channel: {
        Guid: root.model.Guid,
        Title: root.model.Title,
        CommunicationGuid: root.$stateParams.communicationGuid,
        ChannelType: root.channelType,
        Message: _.escape(root.model.Message),
        StartDateTime: root.dateFormatService.getDateTimeForSaving(root.model.StartDateTime, root.model.StartDateOffset),
        StartDateOffset: root.model.StartDateOffset,
        EndDateTime: root.endDateEnabled === this.endDateEnabledConstants.Yes ?
          root.dateFormatService.getDateTimeForSaving(root.model.EndDateTime, root.model.EndDateOffset) :
          null,
        EndDateOffset: root.endDateEnabled === this.endDateEnabledConstants.Yes ?
          root.model.EndDateOffset :
          null,
        StimuliArray: root.model.Stimuli,
        Stimuli: [],
        Sticky: root.model.Sticky,
        ShowOnHomepage: root.model.ShowOnHomepage,
        NewsPageGuid: root.model.NewsPageGuid,
      },
    };
    root.model.Stimuli.forEach((stimulus) => stimulus.file = stimulus.imgUrl);
    const stimuliAndFiles = await this.forumservice.prepareStimuliAndNewStimuli(
      root.model.Stimuli, true);
    request.Channel.StimuliArray = stimuliAndFiles.stimuli;
    stimuliAndFiles.files.forEach((f) => request.Channel.Stimuli.push(stimuliAndFiles.files.indexOf(f)));
    const arg = { request: angular.toJson(request), files: stimuliAndFiles.files };
    root.form.$setPristine();

    return root.communicationservice.updateEngagement(arg).then(async () => {
      const response = await root.communicationservice.selectCommunication(root.$stateParams.communicationGuid);
      root.communication = response;
      root.$loadData(response);

      if (!root.isReadOnly && root.communication.Channels[0].Channel.IsPublished) {
        root.logger.success('Channel successfully updated.');
      }
    }, () => {
      root.logger.error('Channel not successfully updated.');
    });
  }

  $loadData(communication) {
    const data = communication.Channels[0];
    if (data !== undefined) {
      this.model.Guid = data.Channel.Guid;
      this.model.Title = (data.Channel.Title !== undefined && data.Channel.Title !== '') ? data.Channel.Title : communication.Communication.Title;
      this.model.StartDateTime = this.dateFormatService.getDateTime(data.Channel.StartDateTime || DateTime.now(),
        data.Channel.StartDateOffset || this.model.StartDateOffset);
      this.model.StartTime = this.model.StartDateTime;
      this.model.StartDateOffset = data.Channel.StartDateOffset || this.model.StartDateOffset;
      this.endDateEnabled = data.Channel.EndDateTime ? this.endDateEnabledConstants.Yes : this.endDateEnabledConstants.No;
      if (this.endDateEnabled) {
        this.model.EndDateTime = this.dateFormatService.getDateTime(data.Channel.EndDateTime || DateTime.now(),
          data.Channel.EndDateOffset || this.model.EndDateOffset);
        this.model.EndDateOffset = data.Channel.EndDateOffset || this.model.EndDateOffset;
      } else {
        this.model.EndDateTime = this.dateFormatService
          .getDateTime(data.Channel.StartDateTime || DateTime.now(), this.model.EndDateOffset)
          .plus({ months: 1 });
        this.model.EndDateOffset = data.Channel.EndDateOffset || this.model.EndDateOffset;
      }
      this.model.EndTime = this.model.EndDateTime;
      this.model.Message = data.Channel.Message;
      this.model.NewsPageGuid = data.Channel.NewsPageGuid;
      this.model.Sticky = data.Channel.StickyDate != null;
      this.model.ShowOnHomepage = data.Channel.ShowOnHomepage;
      this.minDateTime = this.dateFormatService.startOfDay(this.dateFormatService.getMinDate(DateTime.now(), this.model.StartDateTime));
      this.saveNotApplicable = communication.Channels[0] && communication.Channels[0].Channel.IsPublished;
      this.model.Stimuli = [];
      if (data.Stimulus != null) {
        this.model.Stimuli.push({
          source: 'db',
          action: 'ignore',
          imgUrl: data.Stimulus.ThumbnailUrl,
          type: data.Stimulus.FileType,
          Guid: data.Stimulus.Guid,
        });
      }
    } else {
      this.model.Title = communication.Communication.Title;
    }

    if (this.newsPageList) {
      if (!this.model.NewsPageGuid) {
        this.model.NewsPageGuid = this.newsPageList[0].Guid;
      }

      if (this.newsPageList.length > 1) {
        this.showNewsPageSelection = true;
      }
    }
  }

  private setDateOrTimeValidity(field: any, value: boolean) {
    if (!value) {
      field.$error.beforestart = true;
      field.$invalid = true;
    } else {
      field.$error = {};
      field.$invalid = false;
    }
  }

  private startDateTimeChange() {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
    }

    const valid = !this.model.EndDateTime || this.model.EndDateTime >= this.model.StartDateTime;
    this.setDateOrTimeValidity(this.form.dateField, valid);
    this.setDateOrTimeValidity(this.form.timeField, valid);
  }

  private endDateTimeChange() {
    if (this.model.EndTime) {
      this.model.EndDateTime = this.model.EndDateTime.set({ hour: this.model.EndTime.hour, minute: this.model.EndTime.minute });
    }

    const valid = !this.model.EndDateTime || this.model.EndDateTime >= this.model.StartDateTime;
    this.setDateOrTimeValidity(this.form.endDateField, valid);
    this.setDateOrTimeValidity(this.form.endTimeField, valid);
  }

}
