'use strict';

import { AuthService } from './dataservices/auth.service';

const roleBasedAccessControl = ($transitions, authService: AuthService, _) => {
  $transitions.onBefore({}, async (trans) => {
    const disallowRoles = trans.targetState()._definition.disallowRoles;
    const allowAnonymous = trans.targetState()._definition.allowAnonymous || false;
    let hasAccess = true;

    if (!allowAnonymous) {
      await authService.ensureTokenInfoLoaded();
      const loggedInUserRole = authService.getRole();

      if (disallowRoles && angular.isArray(disallowRoles) && disallowRoles.length > 0) {
        hasAccess = !_.includes(disallowRoles, loggedInUserRole);

        if (!hasAccess) {
          await redirectToDefaultRoute();
        }
      }
    }

    return hasAccess;
  });

  const redirectToDefaultRoute = async (): Promise<void> => {
    await authService.redirectToHomeRouteInfoForLoggedInUser();
  };
};
roleBasedAccessControl.$inject = ['$transitions', 'authService', '_'];

angular
  .module('insitesApp.core')
  .run(roleBasedAccessControl);
