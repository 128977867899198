import { UiModule } from '../../../index';
import { IscCardController } from './isc-card.controller';
var iscCardTemplate = "<md-card>\n  <md-card-title ng-click=\"vm.toggleCollapsed()\" ng-class=\"{'not-collapsible': !vm.collapsible}\">\n    <md-card-title-text>\n      <div class=\"md-headline\" ng-transclude=\"iscCardTitle\"></div>\n      <div class=\"right\">\n        <span class=\"md-subhead left\" ng-transclude=\"iscCardSubtitle\" ng-if=\"vm.hasCardSubtitle && (!vm.collapsible || !vm.collapsed)\"></span>\n        <i ng-if=\"vm.collapsible && vm.collapsed\" class=\"collapsible-chevron right fa-solid fa-angle-up\"></i>\n        <i ng-if=\"vm.collapsible &&! vm.collapsed\" class=\"collapsible-chevron right fa-solid fa-angle-down\"></i>\n      </div>\n    </md-card-title-text>\n  </md-card-title>\n  <div class=\"collapsive-container\">\n    <md-card-content\n      ng-class=\"{'collapsible-content': vm.collapsible, 'expanded': vm.collapsible && !vm.collapsed }\"\n      ng-hide=\"(vm.collapsible && vm.collapsed)\" ng-transclude=\"iscCardContent\">\n    </md-card-content>\n  </div>\n  <md-card-actions class=\"collapsible-content\" ng-hide=\"(vm.collapsible && vm.collapsed)\" ng-transclude=\"iscCardActions\"\n    ng-if=\"vm.hasCardActions\"></md-card-actions>\n</md-card>";
UiModule
    .component('iscCard', {
    template: iscCardTemplate,
    controller: IscCardController,
    controllerAs: 'vm',
    bindings: {
        collapsible: '<',
        collapsed: '<',
    },
    transclude: {
        iscCardTitle: 'iscCardTitle',
        iscCardSubtitle: '?iscCardSubtitle',
        iscCardContent: 'iscCardContent',
        iscCardActions: '?iscCardActions',
    },
});
