'use strict';

import { SelectedClientFactory } from './../../core/selectedclient.factory';
import { SelectedOrphanFactory } from './../../core/selectedorphan.factory';
import { SelectedNetworkFactory } from './../../core/selectednetwork.factory';
import { SelectedProgramFactory } from './../../core/selectedprogram.factory';
import { SelectedSquareFactory } from './../../core/selectedsquare.factory';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from '../../core/dataservices/auth.service';
import { SelectedActivityFactory } from '../../core/selectedactivity.factory';
import { StateParams } from '@uirouter/core';
import { SearchService } from '../../core/dataservices/search.service';

export class SearchSelectionController {
  static $inject = [
    'searchservice',
    '$scope',
    '$state',
    '$stateParams',
    'selectedProgramFactory',
    'selectedNetworkFactory',
    'selectedSquareFactory',
    'selectedOrphanFactory',
    'selectedActivityFactory',
    'selectedClientFactory',
    'authService',
    '$transitions',
  ];
  constructor(
    private searchservice: SearchService,
    private $scope: ng.IScope,
    private $state,
    private $stateParams: StateParams,
    private selectedProgramFactory: SelectedProgramFactory,
    private selectedNetworkFactory: SelectedNetworkFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private selectedOrphanFactory: SelectedOrphanFactory,
    private selectedActivityFactory: SelectedActivityFactory,
    private selectedClientFactory: SelectedClientFactory,
    private authService: AuthService,
    private $transitions,
  ) {}
  fullSearchList = [];
  list = [];
  searchPlaceholder = '';
  selectedProgramGuid;
  selectedClientGuid;
  searchTypesEnum = {
    program: 1,
    square: 2,
    activity: 3,
    network: 4,
  };
  subscription: Subscription;
  lastClientGuid;

  $onInit() {
    this.activate();

    // //////////////

    // We should replace this with an event on the rootscope or broadcast/emit
    this.$scope.$watch(
      () => this.selectedProgramFactory.Guid,
      (newvalue) => {
        if (newvalue) {
          const res = _.find(this.fullSearchList, (item) => item.Guid === newvalue);
          if (!res) {
            this.fullSearchList.push({
              Name: this.selectedProgramFactory.Name,
              Guid: newvalue,
              Type: this.searchTypesEnum.program,
            });

            this.fillSearchPlaceholder();
          }
        }
      },
    );

    this.$scope.$watch(
      () => this.selectedClientFactory.Guid,
      (newvalue) => {
        if (newvalue && newvalue !== this.lastClientGuid) {
          this.activate();
        }
      },
    );

    this.$scope.$watch(
      () => this.selectedNetworkFactory.Guid,
      (newvalue) => {
        if (newvalue) {
          const res = _.find(this.fullSearchList, (item) => item.Guid === newvalue);
          if (!res) {
            this.fullSearchList.push({
              Name: this.selectedNetworkFactory.Name,
              Guid: newvalue,
              Type: this.searchTypesEnum.network,
            });

            this.fillSearchPlaceholder();
          }
        }
      },
    );

    this.subscription = this.selectedSquareFactory.squareInfoObservable.subscribe(
      (square) => {
        const res = _.find(this.fullSearchList, (item) => item.Guid === square.Guid);
        if (!res) {
          this.fullSearchList.push({
            Name: square.Name,
            SquareUrl: square.SquareUrl,
            SsoEnabled: square.SsoEnabled,
            Guid: square.Guid,
            ProgramGuid: this.selectedProgramFactory.Guid,
            Type: this.searchTypesEnum.square,
            Language: square.Language,
            StartDate: square.StartDate,
          });

          this.fillSearchPlaceholder();
        }
      },
    );

    this.$scope.$watch(
      () => this.selectedOrphanFactory.Guid,
      (newvalue) => {
        if (newvalue) {
          const res = _.find(this.fullSearchList, (item) => item.Guid === newvalue);
          if (!res) {
            this.fullSearchList.push({
              Name: this.selectedOrphanFactory.Name,
              Guid: newvalue,
              ProgramGuid: this.selectedProgramFactory.Guid,
              SquareGuid: this.selectedSquareFactory.Guid,
              Type: this.searchTypesEnum.activity,
            });
            this.fillSearchPlaceholder();
          }
        }
      },
    );

    this.$scope.$watch(
      () => this.selectedActivityFactory.Guid,
      (newvalue) => {
        if (newvalue) {
          const res = _.find(this.fullSearchList, (item) => item.Guid === newvalue);
          if (!res) {
            this.fullSearchList.push({
              Name: this.selectedActivityFactory.Name,
              Guid: newvalue,
              ProgramGuid: this.selectedActivityFactory.Guid,
              Type: this.searchTypesEnum.activity,
            });
          }
        }
      },
    );

    this.$transitions.onSuccess({}, () => {
      this.fillSearchPlaceholder();
    });

    this.searchservice.Invalidated.subscribe(async (result) => {
      if (result) {
        await this.activate();
      }
    });
  }

  $onDestroy() {
    this.subscription.unsubscribe();
  }

  async activate() {
    if (this.$stateParams.clientGuid) {
      this.lastClientGuid = this.$stateParams.clientGuid;
      this.searchservice.getSearchSelectionForDropdown(undefined).then((response) => {
        this.fullSearchList = _.map(response, (el: any) => {
          // Add empty admin name because we filter on AdminName in the dropdown component
          // If we don't do this 'undefined' will be in the AdminName and typing 'I' will show all elements
          el.AdminName = el.AdminName || '';
          return el;
        });

        this.fillSearchPlaceholder();
      });
    }
  }

  async selectItem(item) {
    let state = '';
    switch (item.Type) {
    // Program
      case this.searchTypesEnum.program:
        this.$state.go('root.programsquares', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: item.Guid,
        });
        this.selectedProgramGuid = item.Guid;
        this.selectedClientGuid = this.$stateParams.clientGuid;
        this.selectedProgramFactory.setProgramInfo({
          Name: item.Name,
          Guid: item.Guid,
        });
        break;
      // Square
      case this.searchTypesEnum.square:
        if (item.IsCompleted) {
          state = 'root.square.activities';
        } else {
          state = 'root.square.info';
        }

        this.$state.go(state, {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: item.ProgramGuid,
          squareGuid: item.Guid,
        });

        break;
      // Activity
      case this.searchTypesEnum.activity:
        if (item.SquareGuid) {

          // Redirects are processed in a hook on activityinfo
          this.$state.go('root.square.activity', {
            clientGuid: this.$stateParams.clientGuid,
            programGuid: item.ProgramGuid,
            squareGuid: item.SquareGuid,
            activityGuid: item.Guid,
          });
          this.selectedActivityFactory.setActivityInfo({
            Guid: item.Guid,
            Name: item.Name,
            Format: this.selectedActivityFactory.Format,
            HasMedia: item.HasMedia,
          });

        } else {
          // Orphan activity
          this.$state.go('root.orphaninfo', {
            clientGuid: this.$stateParams.clientGuid,
            programGuid: item.ProgramGuid,
            orphanGuid: item.Guid,
          });
          this.selectedOrphanFactory.Name = item.Name;
          this.selectedOrphanFactory.Guid = item.Guid;
        }
        break;
      // Network
      case this.searchTypesEnum.network:
        this.$state.go('root.networkinfo', {
          clientGuid: this.$stateParams.clientGuid,
          networkGuid: item.Guid,
        });
        this.selectedNetworkFactory.Name = item.Name;
        this.selectedNetworkFactory.Guid = item.Guid;
        break;
      default:
        break;
    }
  }

  fillSearchPlaceholder() {
    if (
      this.$stateParams.clientGuid &&
      !(
        this.$stateParams.programGuid ||
        this.$stateParams.networkGuid ||
        this.$stateParams.squareGuid ||
        this.$stateParams.orphanGuid
      )
    ) {
      this.searchPlaceholder = !this.authService.isObserverUserLogin() ? 'Programs, Squares or Activities' : 'Squares or Activities';
      this.list = this.fullSearchList;
    } else if (
      this.$stateParams.clientGuid &&
      this.$stateParams.programGuid &&
      !(
        this.$stateParams.networkGuid ||
        this.$stateParams.squareGuid ||
        this.$stateParams.orphanGuid
      )
    ) {
      this.searchPlaceholder = 'Squares or Activities';
      this.list = _.filter(this.fullSearchList, (item) => (

        item.Type !== this.searchTypesEnum.program && item.ProgramGuid === this.$stateParams.programGuid
      ));
    } else {
      this.searchPlaceholder = 'Activities';
      this.list = _.filter(this.fullSearchList, (item) => (
        item.Type === this.searchTypesEnum.activity &&
          item.ProgramGuid === this.$stateParams.programGuid &&
          item.SquareGuid === this.$stateParams.squareGuid
      ));
    }
  }
}
