'use strict';

import './footer.module';
import { SquareConfigFooterComponent } from './footer.component';
import routeConfig from './footer.route';
import { SquareConfigFooterController } from './footer.controller';

angular
  .module('insitesApp.squareconfig.footer')
  .component('tabsFooter', SquareConfigFooterComponent)
  .controller('footerController', SquareConfigFooterController)
  .run(routeConfig);
