'use strict';

import { RouterHelper } from '../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.activityWizardType',
      config: {
        url: '/activity/:activityGuid/config?step?referer?{activityType:int}',
        template: '<activity-wizard-type flex layout="column"></activity-wizard-type>',
        title: 'Configuration',
        settings: {
          nav: 2,
          icon: 'fa fa-wrench',
          label: 'Configuration',
          section: 'ACTIVITY SET-UP',
        },
        disallowRoles: ['Observer'],
        params: {
          toastMessage: '',
        },
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;
