import { UiModule } from '../../../index';
var template = "<md-radio-button\n  ng-disabled=\"vm.isDisabled\"\n  ng-value=\"vm.value\">\n  <span ng-transclude=\"\" />\n</md-radio-button>";
UiModule
    .component('iscRadioButton', {
    template: template,
    controllerAs: 'vm',
    transclude: true,
    require: {
        radioGroup: '^iscRadioGroup',
    },
    bindings: {
        value: '=',
        isDisabled: '<',
    },
});
