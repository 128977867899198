'use strict';

import 'angular';
import './fathom';
import './insights';
import './layout';
import './core';
import './home';
import './client';
import './program';
// Import './network';
import './square';
// Import './orphan';
import './activity';
import './log';
import './exportlog';
import './config';
import './insites-app.module';
import './insites-app.config';
import './workarounds';
import routeConfig from './root.route';
import './isc-components';
import 'angular-xregexp';
import './rewardHandling';
import './404';
import './cookieyes';
import '../../../FontAwesome/css/all.css';

angular
  .module('insitesApp.core')
  .run(routeConfig);
