'use strict';

import { IProbeQuestionModel } from '../activityProbeQualBuild/IProbeQuestionModel';
import { ActivityQualOpeningPostModel } from './../../activityQualOpeningPost/activityQualOpeningPostModel';
export class ActivityBuildModelBase extends ActivityQualOpeningPostModel {
  conversationGuid = '';
  discussionGuid = ''; // will ? be used for the next discussion
  thankYouMessage?: string;
  contributionType?: number;
  probeQuestions?: IProbeQuestionModel[] = [];
  sticky: boolean;
  showOnHomepage: boolean;
  constructor() {
    super();
  }
}
