'use strict';

import { BulkSendActivationDialogController } from './../bulkSendActivationDialog/bulkSendActivationDialog.controller';
import { ChangeSegmentsController } from './change-segments.controller';
import { JobService } from './../../../../core/jobs/job.service';
import { ChangeRoleController } from './change-role.controller';
import { ParticipantService } from './../../../../core/dataservices/participant.service';
import * as _ from 'lodash';
import { SuspendMembersController } from './suspend-members.controller';
import { RequestACallController } from './request-a-call.controller';
import { IChangeSuspendStatusRequest } from '../../../../core/contracts/member.contract';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { SquareService } from '../../../../core/dataservices/square.service';
import { SelectedSquareFactory } from '../../../../core/selectedsquare.factory';

const suspendMemberTemplate = require('./suspend-members.html');
const requestACallTemplate = require('./request-a-call.html');
const changeRoleTemplate = require('./change-role.html');
const changeSegmentsTemplate = require('./change-segments.html');
const bulkSendActivationDialogTemplateUrl = require('./../bulkSendActivationDialog/bulkSendActivationDialog.html');
const maxParticipantsSelected = 100;
const maxParticipantsInConnectProjectCall = 30;

const decreaseSelectedAmountMessage = 'Please go back and decrease the amount of selected participants in order to proceed with your request.';
const increaseSelectedAmountMessage = 'Please go back and increase the amount of selected participants in order to proceed with your request.';

export class MemberActionBarController {
  totalMembers: number;
  connectProjectUrl: string;
  segmentations: any[];
  // dictionary of guid and username
  activeSquareParticipants: any[];
  selectedSquareParticipantGuids: string[];
  isCallBtnDisabled: boolean;
  isCallBtnVisible: boolean;
  onBulkActionFinished: () => void;

  get anySelected() {
    return this.selectedCount > 0;
  }
  get selectedCount() {
    return this.selectedSquareParticipantGuids.length;
  }

  static $inject = [
    '$mdDialog',
    'participantservice',
    'jobservice',
    'serverConstants',
    'authService',
    '__env',
    'squareservice',
    'participantservice',
    'selectedSquareFactory',
    '$stateParams',
    '$sce',
  ];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private participantService: ParticipantService,
    private jobService: JobService,
    private serverConstants,
    private authService: AuthService,
    private __env: Environment,
    private squareservice: SquareService,
    private participantservice: ParticipantService,
    private selectedSquareFactory: SelectedSquareFactory,
    private $stateParams: ng.ui.IStateParamsService,
    private $sce: ng.ISCEService,
  ) {
  }

  async $onInit() {
    this.isCallBtnVisible = this.__env.showConnectButton && (this.authService.isHuman8UserLogin() || this.authService.isModeratorUserLogin());
    const connectProject = this.isCallBtnVisible ? await this.squareservice.getSquareConnectProject(this.selectedSquareFactory.Guid) : null;

    if(connectProject !== null) {
      this.connectProjectUrl = connectProject.ProjectUrl;
      this.isCallBtnDisabled = false;
    } else {
      this.isCallBtnDisabled = true;
    }
  }

  async changeRole() {
    const selectedRole = await this.$mdDialog.show({
      controller: ChangeRoleController,
      controllerAs: 'vm',
      templateUrl: changeRoleTemplate,
      clickOutsideToClose: false,
    });

    const jobId = this.participantService.bulkChangeRole(this.selectedSquareParticipantGuids, selectedRole);
    await this.jobService.showJobProgressDialog(jobId, `Changing role for ${this.selectedCount} member(s)`);
    this.onBulkActionFinished();
  }

  async checkACallRequestCanBeMade() {
    if(this.selectedSquareParticipantGuids.length > maxParticipantsSelected) {
      await this.showRequestACallDialog(false,
        `You have selected more than ${maxParticipantsSelected} participants. ${decreaseSelectedAmountMessage}`);
      return;
    }

    this.activeSquareParticipants =
      await this.participantservice.areSquareParticipantsAvailableForCall(this.selectedSquareParticipantGuids);

    const activeSquareParticipantsLength = Object.keys(this.activeSquareParticipants).length;
    const onlyActiveSelected = activeSquareParticipantsLength === this.selectedCount;
    const overMaxParticipants = activeSquareParticipantsLength > maxParticipantsInConnectProjectCall;

    // if only active were selected, do not show any dialog
    // if less than 30 active were selected, show dialog to confirm
    // if more than 30 active were selected, show dialog to warn
    if (activeSquareParticipantsLength === 0) {
      await this.showRequestACallDialog(false,
        `You have selected 0 active participants. ${increaseSelectedAmountMessage}`);
      return;
    }
    if (overMaxParticipants) {
      await this.showRequestACallDialog(false,
        `You have selected more than ${maxParticipantsInConnectProjectCall} active participants. ${decreaseSelectedAmountMessage}`);
      return;
    }
    if (onlyActiveSelected) {
      // run the url directly
      this.submitRequestACallForm();
    } else {
      await this.showRequestACallDialog(true,
        `You have selected ${activeSquareParticipantsLength} active participant(s) and
        ${this.selectedCount - activeSquareParticipantsLength} inactive participant(s).
        We are only moving forward with the ${activeSquareParticipantsLength}
        active one(s). Are you sure you want to proceed with these ${activeSquareParticipantsLength} participant(s)?`);
    }
  }

  async showRequestACallDialog(canRequestACall, displayText) {
    await this.$mdDialog.show({
      controller: RequestACallController,
      controllerAs: 'vm',
      templateUrl: requestACallTemplate,
      clickOutsideToClose: false,
      focusOnOpen: false,
      locals: {
        canRequestACall,
        displayText,
        activeSquareParticipants: canRequestACall ? this.activeSquareParticipants : [],
        connectProjectUrl: canRequestACall ? this.connectProjectUrl : null,
      },
    });
  }

  private submitRequestACallForm = () => {
    RequestACallController.submitRequestACallForm(
      this.$sce.trustAsResourceUrl(this.connectProjectUrl),
      this.authService.getToken(),
      Object.keys(this.activeSquareParticipants),
      this.$stateParams.squareGuid);
  };

  async sendActivation() {
    // Let title = `(Re)Send activation for ${this.selectedCount} member(s)`;
    let jobId;
    await this.$mdDialog.show({
      controller: BulkSendActivationDialogController,
      controllerAs: 'vm',
      templateUrl: bulkSendActivationDialogTemplateUrl,
      clickOutsideToClose: false,
      locals: {
        selectedSquareParticipantGuids: this.selectedSquareParticipantGuids,
        selectedCount: this.selectedCount,
        // Title: title,
      },
    }).then((id) => {
      jobId = id;
    });
    await this.jobService.showJobProgressDialog(jobId, `Sending activation emails for ${this.selectedCount} member(s)`);
    this.onBulkActionFinished();
  }

  async shouldReLogin() {
    await this.showConfirmation(`Re-login ${this.selectedCount} member(s)`, `Are you sure that ${this.selectedCount} member(s) should re-login?`);
    const jobId = this.participantService.bulkShouldReLogin(this.selectedSquareParticipantGuids);
    await this.jobService.showJobProgressDialog(jobId, `Updating status for ${this.selectedCount} member(s)`);
    this.onBulkActionFinished();
  }

  async changeStatus(suspend: boolean) {
    const suspendRequest: IChangeSuspendStatusRequest = { ShouldSuspend: suspend };
    if (suspend) {
      const reason = await this.$mdDialog.show({
        controller: SuspendMembersController,
        controllerAs: 'vm',
        templateUrl: suspendMemberTemplate,
        clickOutsideToClose: false,
        locals: {
          model: null,
          suspendReasons: null,
          displayText: `Are you sure you want to suspend ${this.selectedCount} member(s)?`,
        },
      });
      suspendRequest.SuspendReason = reason.SuspendReason;
      suspendRequest.Comment = reason.Comment;
    } else {
      await this.showConfirmation(`Unsuspend ${this.selectedCount} member(s)`, `Are you sure you want to unsuspend ${this.selectedCount} member(s)?`);
    }
    const jobId = this.participantService.bulkChangeStatus(this.selectedSquareParticipantGuids, suspendRequest);
    await this.jobService.showJobProgressDialog(jobId, `Updating status for ${this.selectedCount} member(s)`);
    this.onBulkActionFinished();
  }

  async changeSegments() {
    if (!this.segmentations) {
      this.participantService.listSegmentations()
        .then((segmentations) => {
          this.segmentations = _.filter(segmentations, (seg) =>
            seg.Type !== this.serverConstants.segmentationTypeConstants.platformBehavior
            && seg.Type !== this.serverConstants.segmentationTypeConstants.activityBehavior);
          this.openChangeSegmentsModal();
        });
    } else {
      this.openChangeSegmentsModal();
    }
  }

  private async openChangeSegmentsModal() {
    const segmentActions = await this.$mdDialog.show({
      controller: ChangeSegmentsController,
      controllerAs: 'vm',
      templateUrl: changeSegmentsTemplate,
      clickOutsideToClose: false,
      locals: {
        selectedSquareParticipantGuids: this.selectedSquareParticipantGuids,
        segmentations: this.segmentations,
      },
    });
    const jobId = this.participantService.bulkChangeSegments(this.selectedSquareParticipantGuids, segmentActions);
    await this.jobService.showJobProgressDialog(jobId, `Updating segments ${this.selectedCount} member(s)`);
    this.onBulkActionFinished();
  }

  private async showConfirmation(title: string, message: string) {
    const confirmDialog = this.$mdDialog.iscConfirm()
      .title(title)
      .text(message)
      .ok('Yes')
      .cancel('No');
    await this.$mdDialog.show(confirmDialog);
  }

}

