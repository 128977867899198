'use strict';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { ISelectedClientInfo, IRelatedClientInfo } from './dataservices/dataservice.contracts';

export class SelectedClientFactory implements ISelectedClientInfo {
  private name = null;
  private code = null;
  private guid = null;
  private id = null;
  private videoStorage: number = 1;
  private _clientInfo = new ReplaySubject<ISelectedClientInfo>(1);
  private _relatedClients: IRelatedClientInfo[] = [];

  public isInMigrationMode: boolean = false;

  constructor() {
  }

  get Id() {
    return this.id;
  }

  get Name() {
    return this.name;
  }

  get Guid() {
    return this.guid;
  }

  get VideoStorage() {
    return this.videoStorage;
  }

  get Code() {
    return this.code;
  }

  get RelatedClients() {
    return this._relatedClients;
  }

  get clientInfoObservable() {
    return this._clientInfo.distinctUntilChanged((a, b) => a.Guid === b.Guid);
  }

  get clientGuidPromise() {
    return this._clientInfo.map((c) => c.Guid).filter((c) => !!c).first().toPromise();
  }

  setClientInfo(clientInfo: ISelectedClientInfo) {
    this.name = clientInfo.AdminName || clientInfo.Name;
    this.guid = clientInfo.Guid;
    this.id = clientInfo.Id;
    this.isInMigrationMode = false;
    this.videoStorage = clientInfo.VideoStorage;
    this.code = clientInfo.Code;
    this._relatedClients = clientInfo.RelatedClients;
    this.infoChanged();
  }

  resetClientInfo() {
    this.guid = null;
    this.name = null;
    this.code = null;
    this.isInMigrationMode = false;
    this.infoChanged();
  }

  private infoChanged() {
    this._clientInfo.next({
      Guid: this.guid,
      Name: this.name,
      VideoStorage: this.VideoStorage,
      Code: this.code,
      RelatedClients: this._relatedClients,
      Id: this.id,
    });
  }
}

angular
  .module('insitesApp.core')
  .service('selectedClientFactory', SelectedClientFactory);
