'use strict';

import { SearchBarController } from './searchBar.controller';

const template = require('./searchBar.html');

export const SearchBarComponent: ng.IComponentOptions = {
  controller: SearchBarController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    searchTerm: '=',
    executeSearch: '&',
    placeholder: '@',
    tooltip: '@',
    onChange: '&?',
  },
};
