'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates(
    [
      {
        state: 'root.programsquares',
        config: {
          url: 'client/:clientGuid/program/:programGuid/squares',
          component: 'programSquares',
          title: 'Program Squares',
          settings: {
            nav: 2,
            section: 'PROGRAM MANAGEMENT',
            icon: 'fa fa-cube',
            label: 'Squares',
          },
          disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
        },
      },
    ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

