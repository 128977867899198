'use strict';

import { HttpService } from './http.service';
import { ListJobItemResponse } from './square.service.contracts';
import {
  CreateProgramResponse,
  GetAvailableSquareJobsResponse,
  ListProgramItemResponse,
  ProgramCreditJobIdResponse,
  ProgramCredits,
  ProgramCreditsDetailItem,
  ProgramCreditsPerActivityType,
  ProgramDetailItemResponse,
  UpdateJobIdReminderEmailResponse,
  UpdateProgramResponse,
} from './program.service.contracts';

export class ProgramService {
  static $inject = ['httpservice'];
  constructor(
    private httpService: HttpService,
  ) { }

  async getClientPrograms() {
    return (await this.httpService.get<ListProgramItemResponse>({
      url: '/ProgramService/ListProgram',
    })).data;
  }

  async getProgramDetails(programGuid) {
    return (await this.httpService.get<ProgramDetailItemResponse>({
      url: '/ProgramService/SelectProgram',
      params: { guid: programGuid },
    })).data;
  }

  async updateProgram(program) {
    return (await this.httpService.post<UpdateProgramResponse>({
      url: '/ProgramService/UpdateProgram',
      data: program,
    })).data;
  }

  async createProgram(client, programName) : Promise<CreateProgramResponse> {
    return (await this.httpService.post<CreateProgramResponse>({
      url: '/ProgramService/CreateProgram',
      data: { name: programName, clientGuid: client },
    })).data;
  }

  async updateJobIdReminderEmail(programGuid: string, email: string) {
    return (await this.httpService.post<UpdateJobIdReminderEmailResponse>({
      url: '/ProgramService/UpdateJobIdReminderEmail',
      data: { programGuid, email },
    })).data;
  }

  async toggleProgramPin(rootUrl, guid, clientGuid) {
    return (await this.httpService.post<void>({
      url: '/ProgramService/ToggleProgramPin',
      data: { programGuid: guid, clientGuid },
    }, rootUrl)).data;
  }

  async updateLastSelectedDate(programGuid: string) {
    return (await this.httpService.post<void>({
      url: '/ProgramService/UpdateLastSelectedDate',
      params: { programGuid },
    })).data;
  }

  async deleteProgram(programGuid: string) {
    return (await this.httpService.post<void>({
      url: '/ProgramService/DeleteProgram',
      params: { programGuid },
    })).data;
  }

  async getAvailableJobsForSquares(programGuid) {
    return (await this.httpService.get<GetAvailableSquareJobsResponse>({
      url: '/ProgramService/ListAvailableJobIdsForSquaresInProgram',
      params: { programGuid },
    })).data;
  }

  async getProgramCredits(programGuid) {
    return (await this.httpService.get<ProgramCreditsDetailItem[]>({
      url: '/ProgramService/GetProgramCredits',
      params: { programGuid },
    })).data;
  }

  async createUpdateProgramCredits(programCredits, programGuid) {
    return (await this.httpService.post<ProgramCredits[]>({
      url: '/ProgramService/CreateUpdateProgramCredits',
      data: { programCredits, programGuid },
    })).data;
  }

  async createUpdateProgramCreditsPerActivityType(programCreditsPerActivity, programGuid) {
    return (await this.httpService.post<ProgramCreditsPerActivityType>({
      url: '/ProgramService/CreateUpdateProgramCreditsPerActivityType',
      data: {
        ...programCreditsPerActivity,
        ProgramGuid: programGuid,
      },
    })).data;
  }

  async getProgramCreditsPerActivityType(programGuid) {
    return (await this.httpService.get<ProgramCreditsPerActivityType>({
      url: '/ProgramService/GetProgramCreditsPerActivityType',
      params: { programGuid },
    })).data;
  }

  async getAllUsedCreditJobIds() {
    return (await this.httpService.get<ListJobItemResponse>({
      url: '/ProgramService/ListCreditJobIds',
    })).data;
  }

  async getOldestActiveProgramCreditsPerType(programGuid: string) {
    return (await this.httpService.get<ProgramCreditJobIdResponse[]>({
      url: '/ProgramService/GetOldestActiveProgramCreditsPerType',
      params: { programGuid },
    })).data;
  }
}
