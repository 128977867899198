'use strict';

import { TargetService } from '../../../core/dataservices/target.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import * as _ from 'lodash';
import { ServerConstants } from '../../../core/serverconstants';
import { ITargeting, ITargetingItemRequest } from '../../../core/contracts/targeting.contracts';

export class DisplayTargetingController {
  static $inject = ['segmentationservice', 'targetservice', 'spinnerservice', '$q', 'serverConstants'];
  constructor(
    private segmentationservice,
    private targetservice: TargetService,
    private spinnerservice: SpinnerService,
    private $q: ng.IQService,
    private serverConstants: ServerConstants,
  ) { }

  targetingOption;
  segmentations = [];
  targetingOptions: ITargeting = {
    TargetingFilterOption: 3,
    Items: [],
  };

  targetingDisplayItems: Array<{
    question: string,
    contains: string,
    answers: string[],
    members: number,
  }> = [];

  GlobalTargetingOptions = [
    { Id: 1, Name: 'Match none of the following' },
    { Id: 2, Name: 'Match any of the following' },
    { Id: 3, Name: 'Match all of the following', sign: true },
  ];
  ContainsTargetingOptions = [
    { Id: 1, Name: 'none of the following' },
    { Id: 2, Name: 'any of the following' },
    { Id: 3, Name: 'all of the following', sign: true },
  ];

  randomlySelected;
  targeted;
  targetedFrom;
  targetedBy;
  activityGuid;
  membersByTarget;
  targetingFilterType;
  targetingFilterTypes;
  squareParticipantIdsString;

  async $onInit() {
    this.targetingFilterTypes = this.serverConstants.targetingFilterTypeConstants;
    if (this.activityGuid) {
      this.spinnerservice.show('loading');

      const loadSegmentationsPromise = this.segmentationservice.getSquareSegmentations(true);
      const loadTargetingPromise = this.targetservice.getTargetingForActivity(this.activityGuid);

      try {
        const [segmentations, activityTargeting] = await this.$q.all([loadSegmentationsPromise, loadTargetingPromise]);

        this.segmentations = segmentations;
        this.targetingOptions = activityTargeting;
        await this.getTargetedMembers();

        this.randomlySelected = activityTargeting.RandomlySelected;
        this.targeted = activityTargeting.Targeted;
        this.targetedFrom = activityTargeting.TargetedFrom;
        this.targetingOption = activityTargeting.TargetingFilterOption;
        this.targetingFilterType = activityTargeting.TargetingFilterType;
        this.targetedBy = this.getGlobalContains(activityTargeting.TargetingFilterOption);
        this.squareParticipantIdsString = _.join(activityTargeting.SquareParticipantIds, ', ');

        let index = 0;
        this.targetingDisplayItems = this.targetingOptions.Items.map((item: ITargetingItemRequest) => ({
          question: this.getQuestion(item),
          contains: this.getSegmentationContains(item.TargetingFilterOption),
          answers: this.getAnswers(item),
          members: this.getMembers(item, index++),
        }));
      } finally {
        this.spinnerservice.hide('loading');
      }
    }
  }

  getGlobalContains(item: number) {
    const name = _.find(this.GlobalTargetingOptions, (o) => o.Id === item);
    return name.Name;
  }

  getSegmentationContains(item: number) {
    const name = _.find(this.ContainsTargetingOptions, (o) => o.Id === item);
    return name.Name;
  }

  getQuestion(item: ITargetingItemRequest) {
    const question = _.find<any>(this.segmentations, (o) => o.Guid === item.SegmentationGuid);
    return question ? question.Question : 'All members selected';
  }

  getAnswers(item: ITargetingItemRequest): string[] {
    const answers = [];
    const questions = _.find<any>(this.segmentations, (o) => o.Guid === item.SegmentationGuid);
    _.each(item.SegmentGuids, (s) => {
      _.each<any>(questions.SegmentItems, (o: any) => {
        if (o.Guid === s) {
          answers.push(o.SegmentAnswer);
        }
      });
    });
    return answers;
  }

  async getTargetedMembers() {
    const response = await this.targetservice.getMembersInSegments(this.targetingOptions, this.activityGuid);
    this.membersByTarget = {};
    _.each(response.MembersByTargeting, (item: any) => {
      this.membersByTarget[item.Id] = item.MembersCount;
    });
  }

  getMembers(item: ITargetingItemRequest, index: number) {
    const selectedSegmentation = _.find<any>(this.segmentations, (o) => o.Guid === item.SegmentationGuid);

    const selectedSegments = item.SegmentGuids;
    if (item.SegmentationGuid && (!selectedSegments || !selectedSegments.length)) {
      return selectedSegmentation.MembersInSegmentation;
    }

    if (Object.prototype.hasOwnProperty.call(this.membersByTarget,item.Id)) {
      return this.membersByTarget[item.Id];
    } else if (Object.prototype.hasOwnProperty.call(this.membersByTarget,index)) {
      return this.membersByTarget[index];
    }

    return 0;
  }
}
