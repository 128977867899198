'use strict';

import { IUploadConversationStimulus, IUploadConversationAttachment } from 'isc-ui';

export class ActivityQualOpeningPostModel {
  conversationFocusType: number;
  title = '';
  description = '';
  caption = '';
  stimuli: IUploadConversationStimulus[] = [];
  attachments: IUploadConversationAttachment[] = [];
  newStimulusFiles: File[] = [];
  newAttachmentFiles: File[] = [];
  squareJobId = '';
}
