'use strict';

import timeZonesAndOffsets from './timezone-dropdown/timezones-and-offsets';
import * as _ from 'lodash';

export class TimeZoneFilter {
  static $inject = [];
  static filter() {
    return (input) => {
      const defaultLabel: string = input;
      const timezone: any = _.find(timeZonesAndOffsets, {
        offset: input,
      });
      return timezone ? timezone.label : defaultLabel;
    };
  }
}

angular
  .module('insitesApp.layout')
  .filter('timezone', TimeZoneFilter.filter);
