'use strict';

export class ShowDetails implements ng.IDirective {
  link(scope, element) {
    element.on('keydown', (event) => {
      if (event.key === 'Enter' || event.keyCode === 32) {
        event.preventDefault();
        const button = element[0].querySelectorAll('button');
        button.forEach((item) => {
          item.setAttribute('tabindex', '0');
        });
        scope.vm.toggleMemberDetails(scope.vm.item);
      }
    });
  }
}
