'use strict';

import { HttpService } from './http.service';
import { IMemberOverviewFilter } from '../../core/contracts/filter.contract';
import { IChangeSuspendStatusRequest } from '../contracts/member.contract';
import _ = require('lodash');
import { DateTime } from 'luxon';

export class ParticipantService {
  static $inject = ['httpservice', '__env'];

  constructor(
    private httpService: HttpService,
    private __env: Environment,
  ) { }

  private invalidateCache() {
    this._getSquareParticipantDetails.cache = new _.memoize.Cache();
    this._getParticipantProgress.cache = new _.memoize.Cache();
    this._listSquareParticipantActivities.cache = new _.memoize.Cache();
    this._listSquareParticipantSegmentations.cache = new _.memoize.Cache();
  }

  createExternalParticipant(participant, sendActivationEmail, overruledLabels, isActivationEmailEdited: boolean, consentValidityDate: DateTime) {
    return this.httpService.post({
      url: '/ParticipantService/CreateExternalParticipant',
      data: {
        ...participant,
        sendActivationEmail,
        overruledLabels,
        isActivationEmailEdited,
        consentValidityDate,
      },
    });
  }

  registerParticipant(participant) {
    return this.httpService.post({
      url: '/ParticipantService/RegisterSquareParticipant',
      data: participant,
    });
  }

  getSquareParticipantList(pageNumber, limit, order, filter: IMemberOverviewFilter) {
    return this.httpService.postToQuery({
      url: '/ParticipantService/SquareParticipantList',
      data: {
        pageNumber,
        limit,
        order,
        ...filter,
      },
    });
  }

  getSquareParticipantGuidList(filter: IMemberOverviewFilter) {
    return this.httpService.postToQuery({
      url: '/ParticipantService/SquareParticipantGuidList',
      data: {
        ...filter,
      },
    });
  }

  getSquareParticipantDetails(memberGuid) {
    return this._getSquareParticipantDetails(memberGuid);
  }

  private _getSquareParticipantDetails = _.memoize((memberGuid) => this.httpService.get({
    url: '/ParticipantService/SquareParticipantDetails',
    params: {
      squareParticipantGuid: memberGuid,
    },
  }));

  getParticipantProgress(participantGuid) {
    return this._getParticipantProgress(participantGuid);
  }

  private _getParticipantProgress = _.memoize((participantGuid) => this.httpService.get({
    url: '/ActivityService/ListParticipantProgress',
    params: {
      participantGuid,
    },
  }).then((response) => response.data));

  isUserUnique(user) {
    return this.httpService.get({
      url: '/ParticipantService/IsUserUnique',
      params: {
        username: user.name,
        squareParticipantGuid: user.squareParticipantGuid,
      },
    });
  }

  areSquareParticipantsAvailableForCall(SquareParticipantGuids: string[]) {
    return this.httpService.postToQuery({
      url: '/ParticipantService/AreSquareParticipantsAvailableForCall',
      data: { SquareParticipantGuids },
    }).then((response) => response.data);
  }

  private _listSquareParticipantSegmentations = _.memoize((squareParticipantGuid) =>
    this.httpService.get({
      url: '/ParticipantService/SquareParticipantSegmentations',
      params: {
        squareParticipantGuid,
      },
    }).then((response) => response.data.SegmentationSegmentList));

  listSquareParticipantSegmentations(squareParticipantGuid) {
    return this._listSquareParticipantSegmentations(squareParticipantGuid);
  }

  getConsentExpirationBanners() {
    return this.httpService.get({
      url: '/ParticipantService/GetConsentExpirationBanners',
    }).then((response) => response.data.Banners);
  }

  listSquareParticipantActivities(squareParticipantGuid) {
    return this._listSquareParticipantActivities(squareParticipantGuid);
  }

  private _listSquareParticipantActivities = _.memoize((squareParticipantGuid) => this.httpService.get({
    url: '/ParticipantService/ListActivitiesForMember',
    params: {
      squareParticipantGuid,
    },
  }).then((response) => response.data));

  listSquareParticipantConversations(squareParticipantGuid, activityGuid) {
    return this.httpService.post({
      url: '/ForumService/GetParticipantConversations',
      data: {
        squareParticipantGuid,
        activityGuid,
      },
    }).then((response) => response.data);
  }

  updateMemberDetail(member) {
    this.invalidateCache();
    return this.httpService.post({
      url: '/ParticipantService/UpdateMember',
      data: {
        ...member,
      },
    });
  }

  saveParticipantAvatar(image, metadata, squareParticipantGuid) {
    this.invalidateCache();
    return this.httpService.upload({
      url: '/ParticipantService/SaveParticipantAvatar',
      data: {
        file: image,
        metadata: angular.toJson(metadata),
        squareParticipantGuid,
      },
    });
  }

  sendActivationEmail(data) {
    return this.httpService.post({
      url: '/ParticipantService/SendActivationEmail',
      data,
    });
  }

  getParticipantStatusCounts(squareParticipantGuids) {
    return this.httpService.post({
      url: '/ParticipantService/GetParticipantStatusCounts',
      data: squareParticipantGuids,
    }).then((response) => response.data);
  }

  validateExcelFile(file) {
    return this.httpService.upload({
      url: '/ParticipantService/ValidateExcelFile',
      data: {
        file,
      },
    });
  }

  validateCustomExcelFile(file, activityGuid, username, password) {
    return this.httpService.upload({
      url: '/ParticipantService/ValidateCustomExcelFile',
      data: {
        file,
        activityGuid,
        username,
        password,
      },
    });
  }

  processUploadedExcelFile(name: string, sendActivationEmail: boolean, overruledLabels, role: string, country: string,
    isActivationEmailEdited: boolean, isTemplateShown: boolean, consentValidityDate: DateTime) {
    return this.httpService.post<string>({
      url: '/ParticipantService/ProcessUploadedExcelFile',
      data: {
        name,
        sendActivationEmail,
        overruledLabels,
        role,
        country,
        isActivationEmailEdited,
        isTemplateShown,
        consentValidityDate,
      },
    }).then((response) => response.data);
  }

  bulkShouldReLogin(squareParticipantGuids: string[]) {
    this.invalidateCache();
    return this.httpService.post<string>({
      url: '/ParticipantService/BulkShouldReLogin',
      data: {
        squareParticipantGuids,
      },
    }).then((response) => response.data);
  }

  bulkChangeRole(squareParticipantGuids: string[], role: number) {
    this.invalidateCache();
    return this.httpService.post<string>({
      url: '/ParticipantService/BulkChangeRole',
      data: {
        squareParticipantGuids,
        role,
      },
    }).then((response) => response.data);
  }

  bulkSendActivation(squareParticipantGuids: string[], overruledActivationLabels, overruledReminderLabels, isTemplateEdited, isTemplateShown) {
    this.invalidateCache();
    return this.httpService.post<string>({
      url: '/ParticipantService/BulkSendActivation',
      data: {
        squareParticipantGuids,
        overruledActivationLabels,
        overruledReminderLabels,
        isTemplateEdited,
        isTemplateShown,
      },
    }).then((response) => response.data);
  }

  bulkChangeStatus(squareParticipantGuids: string[], suspendRequest: IChangeSuspendStatusRequest) {
    this.invalidateCache();
    return this.httpService.post<string>({
      url: '/ParticipantService/BulkChangeStatus',
      data: {
        squareParticipantGuids,
        suspendRequest,
      },
    }).then((response) => response.data);
  }

  bulkChangeSegments(squareParticipantGuids: string[], segments: Array<{ SegmentGuid: string, Action: 'add' | 'remove' }>) {
    this.invalidateCache();
    return this.httpService.post<string>({
      url: '/ParticipantService/BulkChangeSegments',
      data: {
        squareParticipantGuids,
        segments,
      },
    }).then((response) => response.data);
  }

  downloadMembersImportTemplate() {
    return this.httpService.post({
      url: '/ExportService/DownloadMembersImportTemplate',
      responseType: 'arraybuffer',
    }).then((response) => response);
  }

  listSegmentations() {
    return this.httpService.post({
      url: '/ParticipantService/ListSegmentations',
    }).then((response) => response.data);
  }

  listSegmentsAndUsage(segmentationGuid: string, squareParticipantGuids: string[]) {
    return this.httpService.post<Array<{ Guid: string, Answer: string, UsedBy: number }>>({
      url: '/ParticipantService/ListSegmentsAndUsage',
      data: {
        segmentationGuid,
        squareParticipantGuids,
      },
    }).then((response) => response.data);
  }

  getSquareParticipantCustomEmail(squareParticipantGuids: string[]) {
    return this.httpService.postToQuery({
      url: '/ParticipantService/GetSquareParticipantCustomEmail',
      data: { squareParticipantGuids },
    }).then((response) => response.data);
  }

  getInsitesUsersAvailableForSquare() {
    return this.httpService.get({
      url: '/ParticipantService/GetInsitesUsersAvailableForSquare',
    }).then((response) => response.data);
  }

  getAdObjectIdForCurrentUser() {
    return this.httpService.post({
      url: '/api/Participant/GetAdObjectIdForCurrentUser',
      baseUrl: this.__env.memberApiUrl,
    }).then((response) => response.data);
  }
}
