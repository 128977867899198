'use strict';

import { SpinnerService } from '../../core/services/spinner.service';
import { Logger } from '../../blocks/logger/logger';
import { SegmentationService } from '../../core/dataservices/segmentation.service';
import { ServerConstants } from '../../core/serverconstants';
import { ActivityService } from '../../core/dataservices/activity.service';
import { ProjectService } from '../../core/dataservices/project.service';
import { IRewardModel } from './rewardModel';
import * as _ from 'lodash';

export class RewardContoller {
  static $inject = ['projectservice', 'activityservice', '$q', 'serverConstants', 'segmentationservice', 'logger', 'spinnerservice'];
  constructor(
    private projectservice: ProjectService,
    private activityservice: ActivityService,
    private $q: ng.IQService,
    private serverConstants: ServerConstants,
    private segmentationservice: SegmentationService,
    private logger: Logger,
    private spinnerservice: SpinnerService) {
    this.squareActivityApprovalConstants = serverConstants.squareActivityApprovalListConstants;
    this.ApprovalOptions = [
      {
        Label: 'Automatic',
        ApprovalFormat: serverConstants.squareActivityApprovalListConstants.automatic,
      },
      {
        Label: 'Manual',
        ApprovalFormat: serverConstants.squareActivityApprovalListConstants.manual,
      },
    ];

    this.ApprovalOptionsForEur = [
      {
        Label: 'Manual',
        ApprovalFormat: serverConstants.squareActivityApprovalListConstants.manual,
      },
    ];

    this.allSegmentations = {
      Question: 'All segmentations',
      Guid: 'All',
      SegmentItems: [
        {
          Guid: 'All',
          Answer: 'All segments',
        },
      ],
    };

    this.CurrencyGuidsForEur = [];
    this.notLinkedToRewardPartnerCurrencyIds = [];
  }

  dataLoaded = false;
  squareActivityApprovalConstants;
  Currencies = [];
  optionsForSegmentationDropdown: Array<{ Question, Guid, SegmentItems }> = [];
  ApprovalOptions: Array<{ Label: string, ApprovalFormat: number }>;
  ApprovalOptionsForEur: Array<{ Label: string, ApprovalFormat: number }>;
  rewardModel: IRewardModel[];
  raffleType;
  raffleTypes = this.serverConstants.raffleTypeConstants;
  allSegmentations;
  rewardAddForm: ng.IFormController;
  CurrencyGuidsForEur: string[];
  hideSpecificSegment: boolean;
  showInstantTooltip: boolean;
  minValueReward: number;
  isValueRequired: number;
  notLinkedToRewardPartnerCurrencyIds: number[];
  $onInit() {
    this.spinnerservice.show('loading');
    const loadCurrenciesPromise = this.projectservice.getCurrencies()
      .then((data) => {
        this.Currencies = data.List;
        _.each(this.Currencies, (item) => {
          item.Label = `${item.Name} (${item.Description})`;
          if (item.Name.toUpperCase() === 'EUR') {
            this.CurrencyGuidsForEur.push(item.Guid);
          }
        });
      });
    const loadNoRewardPartnerCurrencyIdsPromise = this.projectservice.getNoRewardPartnerCurrencies()
      .then((data) => {
        _.each(data.List, (item) => {
          this.notLinkedToRewardPartnerCurrencyIds.push(item);
        });
      });
    const loadSegmentationListPromise = this.getSegmentationList().then(() => {
      _.each(this.rewardModel, (item) => {
        if (item.IsAutomaticApproved) {
          item.IsAutomaticApproved = this.squareActivityApprovalConstants.automatic;
        } else {
          item.IsAutomaticApproved = this.squareActivityApprovalConstants.manual;
        }

        item.SelectedSegmentation = this.getSelectedSegmentation(item.KeySegmentGuid);
        item.SegmentGuid = this.getSelectedSegment(item.KeySegmentGuid || item.SegmentGuid).Guid;
      });
      this.logger.info('Segmentation selectionlist loaded');
    });
    this.$q.all([loadCurrenciesPromise, loadNoRewardPartnerCurrencyIdsPromise, loadSegmentationListPromise])
      .finally(() => {
        this.dataLoaded = true;
        this.spinnerservice.hide('loading');
      });
  }

  addReward = () => {
    this.rewardModel.push({
      IsAutomaticApproved: 1,
      SelectedSegmentation: this.allSegmentations,
      SegmentGuid: this.hideSpecificSegment ? this.allSegmentations.SegmentItems[0].Guid : null,
    });
  };

  getSelectedSegmentation = (segmentGuid) => {
    const segmentation = _.find(this.optionsForSegmentationDropdown, (option) => _.some(option.SegmentItems, { Guid: segmentGuid }));

    if (segmentation) {
      return segmentation;
    }
    return this.allSegmentations;

  };

  getSelectedSegment = (segmentGuid) => {
    const segment = this.findSegmentByGuid(segmentGuid);
    if (segment) {
      return segment;
    }
    return this.allSegmentations.SegmentItems[0];

  };

  private findSegmentByGuid(segmentGuid) {
    for (const segmentation of this.optionsForSegmentationDropdown) {
      const segment = _.find(segmentation.SegmentItems, { Guid: segmentGuid });
      if (segment) {
        return segment;
      }
    }
  }

  getApprovalLabel = (bool) => {
    if (this.dataLoaded) {
      const approval = bool ? 1 : 0;
      const approvalItem: { Label, ApprovalFormat } = _.find(this.ApprovalOptions, { ApprovalFormat: approval })[0];
      return approvalItem.Label;
    }
  };

  getCurrencyLabel = (currencyGuid) => {
    let label = '';
    const currency = _.find(this.Currencies, { Guid: currencyGuid });
    if (currency) {
      label = `${currency.Name} (${currency.Description})`;
    } else {
      label = 'Invalid currency';
    }
    return label;
  };

  removeReward = (reward) => {
    _.pull(this.rewardModel, reward);
    this.rewardAddForm.$setDirty();
  };

  isRewardValid = () => {
    const lastRewardOption = this.rewardModel.slice(-1)[0];
    return this.rewardModel.length === 0
      || (lastRewardOption && (angular.isNumber(lastRewardOption.Amount) || this.raffleType === this.raffleTypes.savingUp)
        && angular.isNumber(lastRewardOption.Value) && lastRewardOption.CurrencyGuid);
  };

  onCurrencyChange(reward, newValue) {
    if (this.CurrencyGuidsForEur.indexOf(newValue) !== -1) {
      reward.IsAutomaticApproved = 0;
    }
  }

  private getSegmentationList() {
    return this.segmentationservice.getSegmentationForFiltering(true).then((segmentations) => {
      this.optionsForSegmentationDropdown.push(this.allSegmentations);

      _.each(segmentations, (item) => {
        if (!item.SegmentItems) {
          item.SegmentItems = [];
        }

        this.optionsForSegmentationDropdown.push({
          Question: item.Question,
          Guid: item.Guid,
          SegmentItems: item.SegmentItems,
        });
      });
    });
  }
}
