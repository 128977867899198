'use strict';

import { ConversationPostController } from './conversationpost.controller';

const template = require('./conversationpost.html');
require('./_conversationpost.scss');

export const ForumConversationPostComponent: ng.IComponentOptions = {
  controller: ConversationPostController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    item: '<',
    searchTermReplies: '<?',
    highlight: '<',
    stripOffInteractions: '<',
  },
};
