'use strict';

import _ = require('lodash');
import { AuthService } from './core/dataservices/auth.service';

const userExists = (authService, $q, $rootScope, $transitions) => {
  const deregisterUserExist = $transitions.onBefore({}, async (trans) => {
    const allowAnonymous = trans.targetState().state().allowAnonymous || false;

    if (allowAnonymous) {
      return true;
    }

    // Is not logged in
    if (!authService.getToken()) {
      // Redirect to default login
      $rootScope.$emit('notAuthorized');
      return $q.resolve();
    }

    deregisterUserExist();
    return authService.updateUser();
  });
};
userExists.$inject = ['authService', '$q', '$rootScope', '$transitions'];

const addAuthenticationEventHandlers = (authService, authenticationUrl, $rootScope, $mdDialog, __env) => {
  const showAuthenticationFailureMessage = _.once(() => {
    if ((authService.isObserverUserLogin() || authService.isModeratorUserLogin()) && !__env.useSsoLoginPage) {
      $rootScope.$emit('redirectToSquareLogin');
    } else {
      const alert = $mdDialog.iscAlert()
        .title('Authorization failed.')
        .text('Your session might have expired.')
        .ok('Log in');
      $mdDialog.show(alert).then(() => {
        window.location.href = authenticationUrl;
      });
    }
  });
  const redirectToAuthenticationInterface = _.once((shouldLogout: boolean = false) => {
    if ((authService.isObserverUserLogin() || authService.isModeratorUserLogin()) && !__env.useSsoLoginPage) {
      $rootScope.$emit('redirectToSquareLogin');
    } else {
      // make sure we remove the token from our environment
      authService.removeToken();
      window.location.href = authenticationUrl + (shouldLogout ? '&logout=true' : '');
    }
  });

  $rootScope.$on('acquireTokenFailure', showAuthenticationFailureMessage);
  $rootScope.$on('loginFailure', showAuthenticationFailureMessage);
  $rootScope.$on('notAuthorized', (event_name, shouldLogout: boolean = false) => {
    redirectToAuthenticationInterface(shouldLogout);
  });
};

addAuthenticationEventHandlers.$inject = ['authService', 'authenticationUrl', '$rootScope', '$mdDialog', '__env'];

const storeNewTokenIfPresent = (authService: AuthService, $location, $rootScope, $state) => {
  try {
    const ssoToken = $location.search().token;
    if (ssoToken) {
      if (ssoToken === 'null') {
        window.location.href = $state.href('loginerror');
      } else {
        authService.setJwToken(ssoToken);

        // Clean the token out of the url without refreshing the page
        let queryStringParamsWithoutToken =
         (window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[1]).replace(/(token=).*?(&|$)/, '');
        // when a conversation guid is passed in the url as an anchor link we scroll to the anchor guid.
        const hash = window.location.hash.substring(1).toLowerCase();
        if (queryStringParamsWithoutToken) {
          queryStringParamsWithoutToken = `?${queryStringParamsWithoutToken}`;
        }
        if (hash) {
          queryStringParamsWithoutToken = `${queryStringParamsWithoutToken}#${hash}`;
        }
        window.history.pushState({}, document.title, `${window.location.pathname}${queryStringParamsWithoutToken}`);
        $rootScope.$emit('loginSuccess');
      }
    }
  } catch {
    $rootScope.$emit('loginFailure');
  }
};
storeNewTokenIfPresent.$inject = ['authService', '$location', '$rootScope', '$state'];

angular
  .module('insitesApp')
  .run(userExists)
  .run(storeNewTokenIfPresent)
  .run(addAuthenticationEventHandlers);
