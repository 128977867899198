'use strict';

import { IConversationEditorConfig, IUploadConversationStimulus, IVisualFocusedConversationConfig } from 'isc-ui';
import { ServerConstants } from '../../../core/serverconstants';
import { ActivityQualOpeningPostModel } from './activityQualOpeningPostModel';
import { ForumService } from '../../../core/dataservices/forum.service';
import { NotificationsService } from '../../../core/services/notifications';
import { IAttachmentUploaded, IStimuliUploaded } from '../../../core/services/notifications.contracts';
import * as _ from 'lodash';
import { IConversationStimuli } from '../squareActivityModel';
import { MuxService } from '../../../core/dataservices/mux.service';
import { FileStorageService } from '../../../core/dataservices/fileStorage.service';
import { MappingService } from './../../../core/services/mapping.service';
import { ClientService } from './../../../core/dataservices/client.service';
import { IQService } from 'angular';

export class ActivityQualOpeningPostController {
  static $inject = [
    'serverConstants',
    '$stateParams',
    'forumservice',
    'clientservice',
    'notifications',
    'muxService',
    'fileStorageService',
    'mappingService',
    '$q',
  ];

  conversationEditorConfig: IConversationEditorConfig;
  visualFocusedConversationConfig: IVisualFocusedConversationConfig;
  validationConstants;
  model: ActivityQualOpeningPostModel;
  conversationFocusTypeConstants;
  uploadProgress;

  constructor(
    private serverConstants: ServerConstants,
    private $stateParams: ng.ui.IStateParamsService,
    private forumservice: ForumService,
    private clientservice: ClientService,
    private notifications: NotificationsService,
    private muxService: MuxService,
    private fileStorageService: FileStorageService,
    private mappingService: MappingService,
    private $q: IQService,
  ) {
    this.validationConstants = serverConstants.validationConstants;
    this.conversationFocusTypeConstants = this.serverConstants.conversationFocusTypeConstants;
  }
  unsubscribeShowPhotoThumbnail = _.noop;
  unsubscribeShowVideoThumbnail = _.noop;
  unsubscribeUpdateAttachmentUrl = _.noop;

  async $onInit() {
    const uploadConfig = {
      maxAttachments: this.validationConstants.conversationAttachmentMaxNo,
      maxStimuli: this.validationConstants.conversationStimuliMaxNo,
      showImageUpload: true,
      showVideoUpload: true,
      showVideoLink: true,
      showAttachment: true,
      isColumnLayout: false,
    };
    this.conversationEditorConfig = {
      showTitle: false,
      showTextEditor: true,
      showCaptionEditor: true,
      titleMaxLength: this.validationConstants.qualActivityTitleMaxLength,
      messageMaxLength: this.validationConstants.qualActivityDescriptionTextareaMaxLength,
      uploadConfig,
      ariaLabel: 'Introduction',
    };

    const visualFocusedUploadConfig = angular.copy(uploadConfig);
    visualFocusedUploadConfig.isColumnLayout = true;
    visualFocusedUploadConfig.showAttachment = false;
    this.visualFocusedConversationConfig = {
      showTextEditor: false,
      isColumnLayout: true,
      uploadConfig: visualFocusedUploadConfig,
    };
    if (this.model && !this.model.conversationFocusType) {
      this.model.conversationFocusType = this.conversationFocusTypeConstants.text;
    }
    await this.notifications.addUserToGroup(this.$stateParams.activityGuid);
    this.unsubscribeShowPhotoThumbnail = this.notifications.showPhotoThumbnail.subscribe((stimuli: IStimuliUploaded) => {
      _.forEach(this.model.stimuli, (stimulus) => {
        if (stimulus.guid === stimuli.Guid) {
          stimulus.thumbnailUrl = stimuli.ThumbnailUrl;
          stimulus.value = stimuli.Url;
        }
      });
    });
    this.unsubscribeShowVideoThumbnail = this.notifications.showVideoThumbnail.subscribe((stimuli: IStimuliUploaded) => {
      _.forEach(this.model.stimuli, (stimulus) => {
        if (stimulus.guid === stimuli.Guid) {
          stimulus.thumbnailUrl = stimuli.ThumbnailUrl;
          stimulus.url = stimuli.Url;
        }
      });
    });
    this.unsubscribeUpdateAttachmentUrl = this.notifications.updateAttachmentUrl.subscribe((attachmentUploaded: IAttachmentUploaded) => {
      _.forEach(this.model.attachments, (attachment) => {
        if (attachment.guid === attachmentUploaded.Guid) {
          attachment.url = attachmentUploaded.Url;
        }
      });
    });

    const storage = await this.clientservice
      .getClientConfigurationVideoStorage(this.$stateParams.clientGuid);
    if (storage) {
      this.muxService.MuxServiceEnabled = storage.VideoStorage === this.serverConstants.clientVideoStorageConstants.mux;
    }
  }

  iscHashtagSelectFn(item) {
    if (item) {
      return `<span>${item.text}</span>&nbsp;`;
    }
    return '';
  }

  async iscHashtagSearchFn(term) {
    if (term) {
      return await this.forumservice.getHashtagsForConversation(this.$stateParams.activityGuid, term);
    }
    return this.$q.resolve(undefined);
  }

  $onDestroy() {
    this.unsubscribeShowPhotoThumbnail();
    this.unsubscribeShowVideoThumbnail();
    this.unsubscribeUpdateAttachmentUrl();
  }

  // metod needed to compute the progress
  getUploadProgress(stimulus: IConversationStimuli | IUploadConversationStimulus) {
    let stimulusObject: IUploadConversationStimulus;
    if (stimulus && this.muxService.MuxServiceEnabled) {
      if (this.isInstanceOfIConversationStimuli(stimulus)) {
        stimulusObject = this.mappingService.mapConversationStimuliForConversationEditor([stimulus])[0];
      } else {
        stimulusObject = stimulus;
      }

      if (stimulusObject.value &&
        stimulusObject.type === this.serverConstants.conversationStimuliTypeConstants.video &&
        !stimulusObject.thumbnailUrl) {
        return this.muxService.getStimulusUploadProgress(stimulusObject.value);
      }

      if (stimulusObject.guid &&
        stimulusObject.type === this.serverConstants.conversationStimuliTypeConstants.image &&
        !stimulusObject.thumbnailUrl) {
        return this.fileStorageService.getStimulusUploadProgress(stimulusObject.value);
      }
    }
    return null;
  }

  // Used to determine if the object is IConversationStimuli - has properties with PascalCase (while IUploadConversationStimulus has camelCase properties)
  private isInstanceOfIConversationStimuli(object: IConversationStimuli | IUploadConversationStimulus): object is IConversationStimuli {
    return 'Id' in object && 'Guid' in object && 'ConversationGuid' in object;
  }
}
