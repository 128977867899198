'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';
import { AuthService } from '../../core/dataservices/auth.service';
import { ClientService } from '../../core/dataservices/client.service';
import { SelectedClientFactory } from '../../core/selectedclient.factory';
import { SelectedSquareForumsService } from '../../core/services/selectedSquareForumsService';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import { ServerConstants } from '../../core/serverconstants';
import * as _ from 'lodash';
import { TransitionService } from '@uirouter/core';

export class SidenavController implements ng.IOnInit {

  static $inject = ['$scope', 'routerHelper', '$stateParams', '$transitions',
    '$state', 'selectedSquareForumsService', '$q', 'authService',
    'clientservice', 'selectedClientFactory', 'selectedSquareFactory', 'serverConstants', '__env'];
  constructor(
    private $scope: ng.IScope,
    private routerHelper: RouterHelper,
    private $stateParams: ng.ui.IStateParamsService,
    private $transitions: TransitionService,
    private $state: ng.ui.IStateService,
    private selectedSquareForumsService: SelectedSquareForumsService,
    private $q: ng.IQService,
    private authService: AuthService,
    private clientservice: ClientService,
    private selectedClientFactory: SelectedClientFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private serverConstants: ServerConstants,
    private __env: Environment,
  ) { }

  private static readonly routeGuids = ['clientGuid', 'programGuid', 'squareGuid', 'activityGuid', 'networkGuid', 'orphanGuid'];
  navRoutes: _.Dictionary<any[]>;
  clientLogo;
  clientGuid;
  showNav = false;
  _shouldShowManagementNav: boolean = true;

  $onInit() {
    this.toggleDisplayFillStateParams();

    this.$transitions.onSuccess({}, () => {
      this.toggleDisplayFillStateParams();
    });

    this.$scope.$root.$on('sidenavChanged', () => {
      this.toggleDisplayFillStateParams();
    });
    this.$scope.$root.$on('sidenavActivityRefererChanged', (event, referer) => {
      this.$stateParams.referer = referer;
    });
    this.selectedClientFactory.clientInfoObservable.subscribe(() => {
      this.getClientInfoForObserver();
      this.toggleDisplayFillStateParams();
    });
  }

  private getClientInfoForObserver() {
    if (this.isObserver) {
      this.clientGuid = this.$stateParams.clientGuid;
      this.clientservice.getClientLogo().then((response) => {
        this.clientLogo = response;
      });
    }
  }
  toggleShowNav() {
    this.showNav = !this.showNav;
  }

  isOpen(route) {
    if (route.children.length === 0) {
      return false;
    }
    if (route.toggleOpen || this.$state.includes(route.name)) {
      return true;
    }

    let isOpen = false;
    for (const child of route.children) {
      if (child.state === this.$state.current.name
        || child.activateRoute === this.$state.current.name
        || child.name === this.$state.current.name
        || _.startsWith(this.$state.current.name, child.activateRoute)) {
        isOpen = true;
        break;
      }
    }
    return isOpen;
  }

  toggleOpen(route) {
    const isOpen = route.toggleOpen;
    _.forEach(this.navRoutes, (sectionItems) => {
      sectionItems.forEach((r) => {
        r.toggleOpen = false;
      });
    });
    route.toggleOpen = !isOpen;
  }

  private async toggleDisplayFillStateParams() {
    this.getClientInfoForObserver();
    const routes = this.getNavRoutes();

    await routes.reduce(async (promise, route) => {
      await promise;
      let stateparams = '';
      let shouldCheckStatePararmsToShow = true;
      if (this.$stateParams.referer && route.name && route.name.includes('root.square.activity')) {
        if (!route.settings.stateParams) {
          route.settings.stateParams = ['referer', 'clientGuid', 'activityGuid', 'programGuid', 'squareGuid'];
        } else {
          route.settings.stateParams.push('referer');
        }
        shouldCheckStatePararmsToShow = false;
      }
      if (route.settings.stateParams || route.url) {
        // if route is not properly formatted (eg /splash-page), the regex match fails
        const routeMatch = route.url ? route.url.match(/:[a-z]*Guid/g) : null;
        const routeParams = route.settings.stateParams || (routeMatch && routeMatch.map((r) => r.slice(1)));
        // if no route params, don't show it in sidenav
        if (!routeParams) {
          route.shouldShow = false;
          return;
        }
        // Check if user has acces to routes otherwise hide in sidenav
        const disallowRoles = route.disallowRoles;
        const loggedInUserRole = this.authService.getRole();
        let hasAccess = true;
        route.shouldShow = true;
        if (disallowRoles && angular.isArray(disallowRoles) && disallowRoles.length > 0) {
          hasAccess = !_.includes(disallowRoles, loggedInUserRole);
          if (!hasAccess) {
            route.shouldShow = false;
          }
        }
        routeParams.forEach((param) => {
          if (this.$stateParams[param]) {
            stateparams += `${param}:"${this.$stateParams[param]}",`;
          } else {
            route.shouldShow = false;
          }
        });
        this.filterNotPresentRoutes(route, routeParams, shouldCheckStatePararmsToShow);
      }

      if (stateparams && route.name) {
        route.settings.sref = `${route.name}({${stateparams}})`;
        route.settings.reloadsref = route.name;
      } else {
        route.settings.sref = route.name || '';
        route.settings.reloadsref = route.name || '';
      }
      route.section = route.settings.section;
    }, this.$q.resolve());

    let navRoutes = [];
    const sourceList = await Promise.all(_.map(
      routes.filter((route) => route.shouldShow),
      async (route) => {
        try {
          if (!('show' in route.settings)) {
            return route;
          }
          if (_.isFunction(route.settings.show)) {
            return (await route.settings.show()) ? route : null;
          }
          return route.settings.show ? route : null;
        } catch {
          return null;
        }
      }));
    const shouldShowRoutes = _.filter(sourceList, (route) => route !== null);

    let showForums = false;
    shouldShowRoutes.forEach((route) => {
      if (route.settings.showForums) {
        showForums = showForums ? true : route.settings.showForums;
      }

      if (route.settings.childnav) {
        const found = _.find<any>(shouldShowRoutes,
          (e) => !e.settings.childnav && e.settings.nav === route.settings.nav);
        if (found) {
          found.children = found.children || [];
          found.children.push(route);
        }
      } else {
        route.children = route.children || [];
        navRoutes.push(route);
      }

    });

    navRoutes.forEach((route) => {
      if (route.children && route.children.length > 0) {
        route.children.sort((r1, r2) => r1.settings.childnav - r2.settings.childnav);
      }
    });

    if (showForums && this.$stateParams.squareGuid) {
      await this.selectedSquareForumsService.update(this.$stateParams.squareGuid);
      const forums = this.selectedSquareForumsService.forums;
      if (forums) {
        const navroutesHaveChanged = this.navRoutes && !_.isEqual(this.navRoutes, navRoutes);
        if (!this.navRoutes || navroutesHaveChanged) {
          this.buildForumRoutes(forums);
          navRoutes = navRoutes
            .concat(forums)
            .sort((r1, r2) => r1.settings.nav - r2.settings.nav);
        }
      }
    }

    this.navRoutes = _.groupBy<any>(navRoutes, (item: any) => item.section);
  }

  filterNotPresentRoutes(route: any, routeParams: any, shouldCheckStatePararmsToShow: boolean) {
    const notPresent = _.difference(SidenavController.routeGuids, routeParams);
    if (route.shouldShow && shouldCheckStatePararmsToShow) {
      notPresent.forEach((item) => {
        if (route.shouldShow) {
          route.shouldShow = !this.$stateParams[item];
        }
      });
    }
  }

  private buildForumRoutes(forums) {
    if (forums) {
      forums.forEach((forum) => {
        forum.name = 'root.square.forum';
        forum.section = 'SQUARE MANAGEMENT';
        forum.settings = {
          nav: 4,
          icon: 'fa fa-comments',
          label: forum.Name || 'Lounge',
          section: 'SQUARE MANAGEMENT',
          sref: `${'root.square.forum({' +
            'clientGuid:\''}${this.$stateParams.clientGuid}',` +
            `programGuid:'${this.$stateParams.programGuid}',` +
            `squareGuid:'${this.$stateParams.squareGuid}',` +
            `forumGuid:'${forum.PageGuid}',` +
            '})',
          reloadsref: 'root.square.forum',
        };
        if (forum.Rooms) {
          const rooms = forum.Rooms.map((room) => {
            room.settings = {
              sref: `${'root.square.forum.room({' +
                'clientGuid:\''}${this.$stateParams.clientGuid}',` +
                `programGuid:'${this.$stateParams.programGuid}',` +
                `squareGuid:'${this.$stateParams.squareGuid}',` +
                `forumGuid:'${forum.PageGuid}',` +
                `roomGuid:'${room.RoomGuid}',` +
                '})',
              reloadsref: 'root.square.forum.room',
              label: room.Name,
            };
            room.state = 'root.square.forum.room';
            // Also open the toggle when you're inside a conversation
            room.activateRoute = 'root.square.forum.room.conversation';
            return room;
          });
          forum.children = rooms;
        }
      });
    }
  }

  private getNavRoutes() {
    const states = this.getNavStatesWithFullUrls();
    return angular.copy(states);
  }

  private getNavStatesWithFullUrls = _.memoize((): any[] => {
    const states = this.routerHelper.getStates() as any[];
    const statesWithNavSetting = states
      .filter((route) => route.settings && route.settings.nav)
      .sort((route1, route2) => route1.settings.nav - route2.settings.nav);
    const results = statesWithNavSetting.map((route) => {
      const stateObject = route.$$state();
      const fullUrl = this.getStateFullUrl(stateObject);
      route.url = fullUrl;
      return {
        ...route,
        url: fullUrl,
      };
    });
    return results;
  });

  private getStateFullUrl(stateObject) {
    let parentUrl = '';
    if (stateObject.parent) {
      parentUrl = this.getStateFullUrl(stateObject.parent);
    }
    return parentUrl + (stateObject.url ? stateObject.url.toString() : '');
  }

  get isObserver() {
    return this.authService.isObserverUserLogin();
  }

  isEmpty(collection) {
    return collection.length === 0;
  }

  getFeedbackUrl() {
    return this.__env.feedbackUrl;
  }

  get isHome() {
    return this.$state.current.name === 'root.home';
  }

  shouldShowNavSectionItems(navSectionTitle): boolean {
    if (navSectionTitle === 'SQUARE MANAGEMENT') {
      return this.selectedSquareFactory.Status !== this.serverConstants.squareStatusConstants.draft;
    }

    return true;
  }

  shouldShowNavSectionSubItem(subItem): boolean {
    // if there is a selected square, only show the Configuration section if the square is not a draft
    if (subItem === 'Configuration' && this.selectedSquareFactory.Guid) {
      return this.selectedSquareFactory.Status !== this.serverConstants.squareStatusConstants.draft;
    }

    return true;
  }

  isInMigrationMode(): boolean {
    return this.selectedClientFactory.isInMigrationMode;
  }

  // Only show for observers, if the logo exists and if it's different from the default Square icon
  get showClientLogo() {
    return this.isObserver && this.clientLogo && this.clientLogo !== 'images/SquareIcon.png';
  }
}
