'use strict';

import { IProbeQuestionModel } from '../../IProbeQuestionModel';
import { ServerConstants } from '../../../../../../core/serverconstants';
import { IscUIUtils } from 'isc-ui';

export class ProbeQuestionOverviewController {
  static $inject = ['serverConstants'];
  constructor(
    private serverConstants: ServerConstants,
  ) { }

  maxQuestions: number;
  activityType: number;
  questions: IProbeQuestionModel[];
  isEditable: boolean;
  allowedTypes: number[];
  isScoutDiscussionType: boolean;
  isAdditionalProbeQuestion: boolean;

  onAdd;
  onDelete;

  $onInit() {
    this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.activityType, this.serverConstants);
  }

  addQuestion(): void {
    if (!this.onAdd || !this.isEditable || this.questions.length >= this.maxQuestions) {
      return;
    }
    this.onAdd();
  }

  deleteQuestion(question): void {
    if (!this.isEditable) {
      return;
    }
    if (this.questions != null && this.questions.length > 0) {
      const index = this.questions.indexOf(question);
      this.questions.splice(index, 1);

      // Scenario: if you delete the first question and for the second one you selected the attachement then
      // Select automatically the first allowed type (picture right now) - attachement and text can't be for the first question
      if (this.isScoutDiscussionType && this.questions.length > 0 &&
        !this.isQuestionTypeAllowed(this.questions[0].questionType)) {
        this.questions[0].questionType = this.allowedTypes[0];
      }

      if (this.onDelete) {
        this.onDelete();
      }
    }
  }

  isQuestionTypeAllowed(type: number) {
    let result = true;
    if (this.allowedTypes && this.allowedTypes.length > 0) {
      result = this.allowedTypes.indexOf(type) !== -1;
    }
    return result;
  }
}
