'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from './../../core/serverconstants';
import { IChipsItem, IMembershipStatusItem } from '../../core/contracts/filter.contract';
import { SegmentationService } from '../../core/dataservices/segmentation.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { ChipsItem } from './chipsItem';
import * as _ from 'lodash';
import { ISegmentObj, IFilterDataChips, IHealthFilterData, IRoleItem } from '../../core/contracts/health.contract';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import { DateFormatService } from '../../core/services/dateformat.service';
import { Utils } from '../../core/utils';
import { MemberService } from '../../core/services/member.service';

export class IscHealthFilterController {
  static $inject = ['serverConstants', 'spinnerservice', 'segmentationservice',
    '$scope', 'logger', 'dateFormatService', 'selectedSquareFactory', 'memberService'];
  constructor(
    private serverConstants: ServerConstants,
    private spinnerservice: SpinnerService,
    private segmentationservice: SegmentationService,
    private $scope: ng.IScope,
    private logger: Logger,
    private dateFormatService: DateFormatService,
    private selectedSquareFactory: SelectedSquareFactory,
    private memberService: MemberService,
  ) { }

  filterData: IHealthFilterData;
  filterDataChips: IFilterDataChips;
  roleList: IRoleItem[] = [];
  membershipStatusList: IMembershipStatusItem[] = [];

  minStartDate: DateTime;
  maxEndDate: DateTime;
  segmentations;
  segmentsObj: ISegmentObj[];
  chips: IChipsItem = new ChipsItem();
  showFilter = true;
  showToggle = false;
  toggleClicked = false;
  scrollEvent;
  applyFilter;

  private removeScrollHandler = _.noop;

  $onDestroy() {
    this.removeScrollHandler();
  }

  async $onInit() {
    try {
      this.spinnerservice.show('loading');
      await this.init();
      this.applyFilter();
    } catch (e) {
      this.logger.error('Failed to load health filters', e);
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async init() {
    this.filterDataChips = this.filterDataChips || {
      selectedRoleChips: [],
      selectedMembershipStatusChips: [],
      selectedSegmentsChips: [],
      dateAsChip: [],
    };
    this.getRoleList();

    const mainContent = document.getElementById('maincontent');

    const handler = () => this.filterScroll();
    mainContent.addEventListener('scroll', handler);
    this.removeScrollHandler = () => mainContent.removeEventListener('scroll', handler);

    this.membershipStatusList = this.memberService.getMembershipStatusList();
    this.chips.Filters = [];
    this.filterData.SelectedSegments = [];

    this.filterData.SelectedRoles = [this.serverConstants.roleConstants.participant];
    this.calculateSelectedRoleChips();

    this.filterData.SelectedMembershipStatus = [this.serverConstants.registrationStatusConstants.active];
    this.filterDataChips.selectedMembershipStatusChips = this.membershipStatusList
      .filter((status) => status.Value === this.serverConstants.registrationStatusConstants.active);

    await this.segmentationservice.getSegmentationForFiltering(true).then((result) => {
      this.segmentations = result;

      this.segmentations.forEach((segment) => {
        segment.SegmentItems.forEach((seg) => {
          seg.DisplayLabel = seg.SegmentAnswer;
        });
      });
    });

    this.minStartDate = await this.selectedSquareFactory.squareStartDatePromise;
    if (this.minStartDate > DateTime.now()) {
      this.minStartDate = await this.selectedSquareFactory.squareCreateDatePromise;
    }
    this.minStartDate = this.dateFormatService.startOfDay(this.minStartDate);
    this.maxEndDate = this.dateFormatService.endOfDay();
    this.filterData.StartDate = this.minStartDate;
    this.filterData.EndDate = this.maxEndDate;

    this.$scope.$watchGroup(['vm.filterData.StartDate', 'vm.filterData.EndDate'], () => {
      if (this.filterData) {
        this.chips.StartDate = this.filterData.StartDate.toFormat('MMM d yyyy');
        this.chips.EndDate = this.filterData.EndDate.toFormat('MMM d yyyy');
        this.filterDataChips.dateAsChip = this.convertToChip(`${this.chips.StartDate} - ${this.chips.EndDate}`);
      }
    });
  }

  getRoleList() {
    const roleConstants = this.serverConstants.roleConstants;
    const roles = _.filter(Utils.getEnumAsArray(roleConstants), (role) => role.Value === roleConstants.human8 || role.Value === roleConstants.participant ||
      role.Value === roleConstants.professionalAdmin || role.Value === roleConstants.clientAdmin || role.Value === roleConstants.clientEditor ||
      role.Value === roleConstants.observer);
    _.each(roles, (role) => {
      const item: IRoleItem = {
        Label: role.Label,
        Value: role.Value,
        DisplayLabel: role.Label,
      };
      this.roleList.push(item);
    });
  }

  private convertToChip(value) {
    const chip = [];
    if (value) {
      chip[0] = value;
    }
    return chip;
  }

  filterScroll() {
    // Scroll event to collapse filter and show filter button when the filter has reached the top banner
    const stickyMenu = document.querySelector('.sticky');
    const banner = document.querySelector('.top-banner');
    if (stickyMenu && banner) {
      const stickyMenuBoundings = stickyMenu.getBoundingClientRect();
      const bannerBounding = banner.getBoundingClientRect();
      if (stickyMenuBoundings.top <= bannerBounding.height) {
        this.$scope.$apply(() => {
          this.showToggle = true;
        });
      } else {
        this.$scope.$apply(() => {
          this.showToggle = false;
          this.resetToggle();
        });
      }
    }
  }

  toggleFilter() {
    const stickyMenu = document.querySelectorAll('.sticky');
    const top = document.getElementById('filter-top');
    if (this.toggleClicked) {
      this.resetToggle();
    } else {
      this.toggleClicked = true;
      top.classList.add('sticky');
      angular.forEach(stickyMenu, (el) => el.classList.add('under-filter-fields'));
    }
  }

  resetToggle() {
    const top = document.getElementById('filter-top');
    if (top) {
      top.classList.remove('sticky');
    }
    this.toggleClicked = false;
    const stickyMenu = document.querySelectorAll('.sticky');
    if (stickyMenu) {
      angular.forEach(stickyMenu, (el) => el.classList.remove('under-filter-fields'));
    }
  }

  calculateSelectedRoleChips() {
    this.filterDataChips.selectedRoleChips = _.filter<IRoleItem>(this.roleList || [],
      (role) =>
        _.some(this.filterData.SelectedRoles || [],
          (item) => item === role.Value));
  }

  calculateSelectedMembershipStatusChips() {
    this.filterDataChips.selectedMembershipStatusChips = _.filter<IMembershipStatusItem>(this.membershipStatusList || [],
      (membership) =>
        _.some(this.filterData.SelectedMembershipStatus || [],
          (item) => item === membership.Value));
  }

  removeFromChipModel(model, value) {
    // Removes a chip when pressing the X icon on it depending of the chip type
    switch (model) {
      case 'membershipStatus': this.onRemoveSelectedMembershipStatus(value); break;
      case 'segment': this.onRemoveSelectedSegment(value); break;
      default: break;
    }
  }

  calculateSelectedSegmentsChips() {
    this.filterDataChips.selectedSegmentsChips = [];
    if (!this.filterData.SelectedSegments.length) {
      return;
    }
    const segmentsObj = this.getSegmentsObj();
    const segmentsJoinString = this.getSegmentsJoinString();
    _.forEach(this.filterData.SelectedSegments, (item) => {
      const existingSegment = _.filter(this.filterDataChips.selectedSegmentsChips, (chip) => chip.SegmentationGuid === segmentsObj[item].SegmentationGuid);
      if (!existingSegment.length) {
        this.filterDataChips.selectedSegmentsChips.push({
          SegmentationGuid: segmentsObj[item].SegmentationGuid,
          Label: segmentsObj[item].SegmentAnswer,
          Segments: [item],
        });

        return;
      }

      existingSegment[0].Label = [existingSegment[0].Label, segmentsObj[item].SegmentAnswer].join(` ${segmentsJoinString} `);
      existingSegment[0].Segments.push(item);
    });
  }

  resetFilters() {
    // Clears all chips and filters
    this.chips.Filters = [];

    // For the moment, we only display health information for the Participant role, so we need this role to be always selected
    this.filterData.SelectedRoles = [this.serverConstants.roleConstants.participant];
    this.filterDataChips.selectedRoleChips = [{
      DisplayLabel: 'Participant',
      Label: 'Participant',
      Value: this.serverConstants.roleConstants.participant,
    }];

    this.filterData.SelectedMembershipStatus = [];
    this.filterDataChips.selectedMembershipStatusChips = [];

    this.filterData.SelectedSegments = [];
    this.filterData.SelectedSegmentsOption = this.serverConstants.selectedSegmentsOptionConstants.any;
    this.filterDataChips.selectedSegmentsChips = [];

    this.filterData.StartDate = this.minStartDate;
    this.filterData.EndDate = this.maxEndDate;
    this.applyFilter();
  }

  roleSelectedChange(value: number[]) {
    this.filterData.SelectedRoles = this.filterData.SelectedRoles || [];
    const shouldApplyFilter = !angular.equals(this.filterData.SelectedRoles, value);
    this.filterData.SelectedRoles = value;
    this.calculateSelectedRoleChips();
    if (shouldApplyFilter) {
      this.applyFilter();
    }
  }

  membershipStatusSelectedChange(value) {
    this.filterData.SelectedMembershipStatus = this.filterData.SelectedMembershipStatus || [];
    const shouldApplyFilter = this.filterData.SelectedMembershipStatus.toString() !== value.toString();
    this.filterData.SelectedMembershipStatus = value;
    this.calculateSelectedMembershipStatusChips();
    if (shouldApplyFilter) {
      this.applyFilter();
    }
  }

  segmentsSelectedChange(segments, changedOptions) {
    this.filterData.SelectedSegmentsOption = changedOptions && this.filterData.SelectedSegmentsOption === this.serverConstants.selectedSegmentsOptionConstants.any ?
      this.serverConstants.selectedSegmentsOptionConstants.all :
      this.serverConstants.selectedSegmentsOptionConstants.any;

    const shouldApplyFilter = !angular.equals(this.filterData.SelectedSegments, segments) || changedOptions;
    this.filterData.SelectedSegments = segments;
    this.calculateSelectedSegmentsChips();
    if (shouldApplyFilter) {
      this.applyFilter();
    }
  }

  onRemoveSelectedMembershipStatus(chip: IMembershipStatusItem) {
    _.remove(this.filterData.SelectedMembershipStatus, (item) => item === chip.Value);
    this.calculateSelectedMembershipStatusChips();
    this.applyFilter();
  }

  onRemoveSelectedSegment(chip) {
    _.remove(this.filterData.SelectedSegments, (item) => _.some(chip.Segments, (segment) => segment === item));
    this.calculateSelectedSegmentsChips();
    this.applyFilter();
  }

  roleSelectedText() {
    let displayText = 'Select role';
    if (this.filterDataChips.selectedRoleChips.length > 1) {
      displayText = `${this.filterDataChips.selectedRoleChips[0].DisplayLabel} (+${this.filterDataChips.selectedRoleChips.length - 1})`;
    }
    if (this.filterDataChips.selectedRoleChips.length === 1) {
      displayText = this.filterDataChips.selectedRoleChips[0].DisplayLabel;
    }
    return displayText;
  }

  membershipStatusSelectedText() {
    let displayText = 'Select membership status';
    if (this.filterDataChips.selectedMembershipStatusChips.length > 1) {
      displayText = `${this.filterDataChips.selectedMembershipStatusChips[0].DisplayLabel} (+${this.filterDataChips.selectedMembershipStatusChips.length - 1})`;
    }
    if (this.filterDataChips.selectedMembershipStatusChips.length === 1) {
      displayText = this.filterDataChips.selectedMembershipStatusChips[0].DisplayLabel;
    }
    return displayText;
  }

  private getSegmentsObj() {
    if (!this.segmentsObj) {
      this.segmentsObj = [];
      _.forEach(this.segmentations, (segmentation) => {
        _.forEach<any[]>(segmentation.SegmentItems, (segment: any) => {
          this.segmentsObj[segment.Guid] = { SegmentationGuid: segmentation.Guid, SegmentAnswer: segment.SegmentAnswer };
        });
      });
    }

    return this.segmentsObj;
  }

  private getSegmentsJoinString() {
    if (this.filterData.SelectedSegmentsOption === this.serverConstants.selectedSegmentsOptionConstants.any) {
      return 'OR';
    }

    if (this.filterData.SelectedSegmentsOption === this.serverConstants.selectedSegmentsOptionConstants.all) {
      return 'AND';
    }

    return ' ';
  }

  isDisabled() {
    if (this.filterData && this.filterDataChips) {
      if (this.filterDataChips.selectedRoleChips.length > 0 ||
        this.filterDataChips.selectedMembershipStatusChips.length > 0 ||
        this.filterDataChips.selectedSegmentsChips.length > 0 ||
        this.filterData.StartDate !== this.minStartDate ||
        this.filterData.EndDate !== this.maxEndDate) {
        return false;
      }
    }
    return true;
  }
}
