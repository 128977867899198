'use strict';

import './programsquares.component';
import './programsquares.controller';
import './programsquares.route';
import './programsquares.module';
import './addSquareDialog.controller';
import routeConfig from './programsquares.route';
import { AddSquareDialogController } from './addSquareDialog.controller';

import { ProgramSquaresComponent } from './programsquares.component';

angular
  .module('insitesApp.programsquares')
  .controller('AddSquareDialogController', AddSquareDialogController)
  .component('programSquares', ProgramSquaresComponent)
  .run(routeConfig);
