'use strict';

import { SquareService } from '../../../../../../core/dataservices/square.service';
import { ServerConstants } from '../../../../../../core/serverconstants';
export class ActivityThemeController {
  static $inject = ['squareservice', '_', '$stateParams', 'serverConstants'];

  model;
  squareActivity;
  themes = [];
  validationConstants;
  constructor(
    private squareservice: SquareService,
    private _: _.LoDashStatic,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
  ) {
  }


  async $onInit() {
    this.validationConstants = this.serverConstants.validationConstants;
    const result = await this.squareservice.getThemesForActivityConfig(this.$stateParams.activityGuid);
    this.themes = result;

    if (!this.model) {
      // When creating a new activity set the theme by default if there is only one available
      if (this.themes.length === 1) {
        this.model = this.themes[0].Guid;
      } else {
        this.model = this._.find(this.themes, 'IsDefaultTheme').Guid;
      }
      if (this.squareActivity && this.squareActivity.Detail) {
        this.squareActivity.Detail.ThemeGuid = this.squareActivity.Detail.ThemeGuid || this.model;
      }
    }
  }
}
