'use strict';

// Angular material's md-maxlength directive isn't compatible with the default behaviour of
// The required directive. There is a bug for this on the angular-material project, but it
// Won't be fixed.
// Bug: https://github.com/angular/material/issues/1794

export class MdMaxlengthCompatibleRequired implements ng.IDirective {
  restrict = 'A';
  require = 'ngModel';
  link(scope, element, attributes, ngModel) {
    ngModel.$validators.mdMaxlengthCompatibleRequired = (modelValue) => {
      const valueAsString = modelValue ? `${modelValue}` : '';
      return !!valueAsString.trim();
    };
  }
}
