'use strict';

import { HttpService } from './http.service';

export class ActivitySequenceDataService {

  static $inject = ['httpservice'];

  constructor(private httpservice: HttpService) { }

  addActivityToSequence(parentActivityGuid, childActivityGuid, childOrder) {
    return this.httpservice.post({
      url: '/ActivitySequenceService/AddActivityToSequence',
      data: {
        ParentActivityGuid: parentActivityGuid,
        ChildOrder: childOrder,
        ChildActivityGuid: childActivityGuid,
      },
    });
  }

  removeActivityFromSequence(childActivityGuid) {
    return this.httpservice.post({
      url: '/ActivitySequenceService/RemoveActivityFromSequence',
      data: {
        ChildActivityGuid: childActivityGuid,
      },
    });
  }

  validateSequencedActivityCreate(parentActivityGuid, childActivityGuid) {
    return this.httpservice.get({
      url: '/ActivityService/ValidateSequencedActivityCreate',
      params: {
        parentActivityGuid,
        childActivityGuid,
      },
    }).then((response) => response.data);
  }

  validateSequencedActivityRemove(childActivityGuid) {
    return this.httpservice.get({
      url: '/ActivityService/ValidateSequencedActivityRemove',
      params: {
        childActivityGuid,
      },
    }).then((response) => response.data);
  }
}
