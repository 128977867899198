'use strict';

import { IscMemberFilterController } from './isc-member-filter.controller';

const template = require('./isc-member-filter.html');
require('./_isc-member-filter.scss');

export const iscMemberFilterComponent: ng.IComponentOptions = {
  controller: IscMemberFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    memberFilter: '=',
    minStartDate: '<',
    maxEndDate: '<',
    filterMembers: '&',
    resetFilter: '&',
    availableRoles: '=',
    availableSegmentations: '=',
    availableSegments: '=',
    filterDataCounters: '=',
  },
};
