'use strict';

import { FaqController } from './faq.controller';

const template = require('./faq.html');
require('./faq.scss');

export const FaqComponent: ng.IComponentOptions = {
  controller: FaqController,
  templateUrl: template,
  controllerAs: 'fvm',
  bindings: {
    page: '=',
    validationConstants: '<',
  },
};
