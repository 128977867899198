'use strict';

import { ServerConstants } from '../../../../../../core/serverconstants';

export class ActivityDecipherIdController {
  static $inject = ['serverConstants'];
  constructor(
    private serverConstants: ServerConstants) {
    this.validationConstants = this.serverConstants.validationConstants;
  }
  model;
  validationConstants;

}
