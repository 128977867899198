'use strict';

import './pageEditor.module';
import { pageEditorComponent } from './pageEditor.component';
import { AddPageDialogController } from './addPageDialog.controller';

angular
  .module('insitesApp.squareconfig.tabComponents.pageEditor')
  .controller('AddPageDialogController', AddPageDialogController)
  .component('pageEditor', pageEditorComponent);
