'use strict';

import timeZonesAndOffsets from './timezones-and-offsets';

export class TimezoneDropdownController {
  timezones = timeZonesAndOffsets;
  onChange: () => void;

  static $inject = ['$scope'];
  constructor(
    private $scope: ng.IScope,
  ) {
  }

  $onInit() {
    if (this.onChange) {
      this.$scope.$watch('vm.timezone', () => this.onChange());
    }
  }
}
