'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.platformnotice',
      config: {
        url: 'platformnotice',
        title: 'Platform notice',
        views: {
          'sidenav': {
            component: 'homeSidenavComponent',
          },
          'sidenav-footer': {
            component: 'homeSidenavFooterComponent',
          },
          '': {
            component: 'homePlatformNotice',
          },
        },
        disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

