'use strict';

import * as angular from 'angular';

import routeConfig from './conversation.route';
import { ForumConversationComponent } from './conversation.component';
import { ForumConversationPostComponent } from './conversationpost.component';

angular
  .module('insitesApp.squareForumRoom')
  .component('forumConversationComponent', ForumConversationComponent)
  .component('conversationPost', ForumConversationPostComponent)
  .run(routeConfig);
