'use strict';

import { ImageTileController } from './image.tile.controller';
const template = require('./image.tile.html');

angular
  .module('insitesApp.core')
  .component('imageTile', {
    templateUrl: template,
    controller: ImageTileController,
    controllerAs: 'vm',
    bindings: {
      setting: '<',
      model: '=',
      modelInvalid: '=',
      form: '=',
    },
  });
