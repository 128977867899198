'use strict';

import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { DateFormatService } from './../../../../../../core/services/dateformat.service';
import { CommunicationService } from '../../../../../../core/dataservices/communication.service';
import { isArray } from '@uirouter/core';

export class PlatformNotificationController {
  static $inject = [
    'dateFormatService',
    'serverConstants',
    'communicationservice',
    '$stateParams',
    'logger',
  ];

  constructor(
    private dateFormatService: DateFormatService,
    private serverConstants,
    private communicationservice: CommunicationService,
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
  ) { }

  communication;
  isReadOnly;
  errorMessage: string = null;
  hasErrors: boolean = false;
  removeLink;
  wizardStep;
  channelType;
  form;
  validationConstants = this.serverConstants.validationConstants;
  // eslint-disable-next-line no-useless-escape
  urlPattern = '([-a-zA-Z0-9:%_\+.~#?&//=]*)';
  minDateTime: DateTime;
  saveCallback = () => this.saveData(this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  model = {
    Guid: '',
    Title: '',
    Overview: '',
    Message: '',
    StartDateTime: DateTime.now(),
    StartTime: DateTime.now(),
    StartDateOffset: DateTime.local().offset / 60,
    EndDateTime: DateTime.now().plus({ days: 7 }),
    EndTime: DateTime.now().plus({ days: 7 }),
    EndDateOffset: DateTime.local().offset / 60,
    CallToActionUrl: '',
  };

  availablePagesUrl: any[];

  isEndDateAccessible: boolean = true;
  endDateEnabledConstants = {
    No: 0,
    Yes: 1,
  };
  endDateEnabled = 0;

  async $onInit() {
    this.removeLink = this.wizardStep.linkComponent(`channel-${this.channelType}`, this);
    const pageUrlsResponse = await this.communicationservice.getSquareAvailablePageUrls();
    if (pageUrlsResponse && pageUrlsResponse.PageUrls && isArray(pageUrlsResponse.PageUrls)) {
      this.availablePagesUrl = pageUrlsResponse.PageUrls;
    }
    this.$loadData(this.communication);
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardIsValid() {
    if (!_.isUndefined(this.form)) {
      return this.form.$valid;
    }
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardBeforeNext() {
    this.saveData(this);
  }

  $wizardStepLoaded() {
    const data = this.communication.Channels[0];
    if (data !== undefined && this.model.Guid !== undefined) {
      this.model.Guid = data.Channel.Guid;
    }
  }

  $wizardStepIsCompleted() {
    let result = false;
    const channel = _.find(
      this.communication.Channels,
      (communicationChannel: any) => communicationChannel.Channel.ChannelType === this.serverConstants.channelTypeConstants.customAppNotification);
    if (channel !== undefined && channel.Channel.Title && channel.Channel.Message && channel.Channel.CallToActionUrl) {
      result = true;
    }
    return result;
  }

  saveData(root: this) {
    const request = {
      Channel: {
        Guid: root.model.Guid,
        CommunicationGuid: root.communication.Communication.Guid,
        Title: root.titleFromMessage(),
        ChannelType: root.serverConstants.channelTypeConstants.customAppNotification,
        Message: _.escape(root.model.Message),
        CallToActionText: `${root.channelType}`,
        CallToActionUrl: root.model.CallToActionUrl,
        StartDateTime: root.dateFormatService.getDateTimeForSaving(root.model.StartDateTime, root.model.StartDateOffset),
        StartDateOffset: root.model.StartDateOffset,
        EndDateTime: root.endDateEnabled === 1 ?
          root.dateFormatService.getDateTimeForSaving(root.model.EndDateTime, root.model.EndDateOffset) :
          null,
        EndDateOffset: root.endDateEnabled === 1 ?
          root.model.EndDateOffset :
          null,
      },
    };

    const arg = { request: angular.toJson(request) };
    root.form.$setPristine();
    return root.communicationservice.updateEngagement(arg).then(() => {
      root.communicationservice.selectCommunication(root.$stateParams.communicationGuid).then((response) => {
        root.communication = response;
        root.$loadData(response);
      });
      if (!root.isReadOnly) {
        root.logger.success('Channel successfully updated.');
      }
    }, (error) => {
      if (!root.isReadOnly) {
        root.logger.error('Channel not successfully updated.');
      }
      root.hasErrors = true;
      root.errorMessage = error.data.Message;
    });
  }

  titleFromMessage() {
    let result = '';
    if (this.model.Message) {
      result = this.model.Message;
      if (result.length > this.validationConstants.communicationPlatformNotificationTitleMaxLength) {
        result = `${result.substr(0, this.validationConstants.communicationPlatformNotificationTitleMaxLength - 3)}...`;
      }
    }
    return result;
  }

  $loadData(communication) {
    const data = communication.Channels[0];
    if (data !== undefined) {
      this.model.Guid = data.Channel.Guid;
      this.model.Title = (data.Channel.Title !== undefined && data.Channel.Title !== '') ? data.Channel.Title : communication.Communication.Title;
      this.model.CallToActionUrl = data.Channel.CallToActionUrl;
      this.model.StartDateOffset = data.Channel.StartDateOffset ? data.Channel.StartDateOffset : this.model.StartDateOffset;
      this.model.Message = data.Channel.Message;
      const startDate = this.isReadOnly ?
        DateTime.fromISO(data.Channel.StartDateTime) :
        DateTime.max(...[DateTime.now(), data.Channel.StartDateTime ? DateTime.fromISO(data.Channel.StartDateTime) : DateTime.now()]);
      this.model.StartDateTime = this.dateFormatService.getDateTime(startDate || DateTime.now(),
        data.Channel.StartDateOffset || this.model.StartDateOffset);
      this.model.StartTime = this.model.StartDateTime;
      this.model.StartDateOffset = data.Channel.StartDateOffset || this.model.StartDateOffset;
      this.minDateTime = this.dateFormatService.startOfDay(this.dateFormatService.getMinDate(DateTime.now(), this.model.StartDateTime));
      this.isEndDateAccessible = data.Channel.CommunicationStatus !== this.serverConstants.communicationStatusConstants.closed;
      this.endDateEnabled = data.Channel.EndDateTime !== null ? 1 : 0;
      if (this.endDateEnabled) {
        this.model.EndDateTime = this.dateFormatService.getDateTime(data.Channel.EndDateTime, data.Channel.EndDateOffset);
        this.model.EndDateOffset = data.Channel.EndDateOffset;
      } else {
        this.model.EndDateTime = this.dateFormatService
          .getDateTime(data.Channel.StartDateTime || DateTime.now(), this.model.StartDateOffset)
          .plus({ months: 1 });
        this.model.EndDateOffset = data.Channel.EndDateOffset || this.model.EndDateOffset;
      }
      this.model.EndTime = this.model.EndDateTime;
    }
    this.hasErrors = false;
    this.errorMessage = null;
  }

  // eslint-disable-next-line no-unused-vars
  redirectToUrlChanged({ unused, newValue }) {
    if (newValue) {
      if (newValue.toLowerCase().indexOf('/login') !== -1) {
        this.form.RedirectToUrl.$setValidity('serverErrors', false);
        this.errorMessage = 'URL cannot contain the login page.';
      } else {
        if (isArray(this.availablePagesUrl)) {
          if (!this.availablePagesUrl.find((i) => newValue === i || newValue.startsWith(i))) {
            if (!_.isUndefined(this.form)) {
              this.form.RedirectToUrl.$setValidity('serverErrors', false);
              this.errorMessage = 'Please enter a URL that refers to a page on the Square.';
            }
          } else {
            if (!_.isUndefined(this.form)) {
              this.form.RedirectToUrl.$setValidity('serverErrors', true);
              this.errorMessage = null;
            }
          }
        }
      }
    }
  }

  timeChanged(): void {
    const communicationStartDateTime = this.dateFormatService.getDateTime(this.model.StartDateTime, this.model.StartDateOffset);
    const currentDateTime = DateTime.now();
    this.form.timeField.$setValidity('dateInPast', communicationStartDateTime >= (currentDateTime.set({ second: 0, millisecond: 0 })));
  }

  private setDateOrTimeValidity(field: any, value: boolean) {
    if (!value) {
      field.$error.beforestart = true;
      field.$invalid = true;
    } else {
      field.$error = {};
      field.$invalid = false;
    }
  }

  private startDateTimeChange() {
    if (this.model.StartTime) {
      this.model.StartDateTime = this.model.StartDateTime.set({ hour: this.model.StartTime.hour, minute: this.model.StartTime.minute });
    }
    this.timeChanged();
    const valid = !this.model.EndDateTime || this.model.EndDateTime >= this.model.StartDateTime;
    this.setDateOrTimeValidity(this.form.dateField, valid);
    this.setDateOrTimeValidity(this.form.timeField, valid);
    if (valid && this.form.endDateField) {
      this.setDateOrTimeValidity(this.form.endDateField, valid);
      this.setDateOrTimeValidity(this.form.endTimeField, valid);
    }
  }

  private endDateTimeChange() {
    if (this.model.EndTime) {
      this.model.EndDateTime = this.model.EndDateTime.set({ hour: this.model.EndTime.hour, minute: this.model.EndTime.minute });
    }
    const valid = !this.model.EndDateTime || this.model.EndDateTime >= this.model.StartDateTime;
    this.setDateOrTimeValidity(this.form.endDateField, valid);
    this.setDateOrTimeValidity(this.form.endTimeField, valid);
    if (valid) {
      this.setDateOrTimeValidity(this.form.dateField, valid);
      this.setDateOrTimeValidity(this.form.timeField, valid);
    }
  }
}
