'use strict';

import { ActivityEmailController } from './activityEmailChannel.controller';
const template = require('./activityEmailChannel.html');

angular
  .module('insitesApp.squarecommunicationWizard')
  .component('activityEmailChannel', {
    templateUrl: template,
    controller: ActivityEmailController,
    controllerAs: 'vm',
    bindings: {
      description: '@',
      communication: '<',
      isReadOnly: '<',
      footerReadOnly: '<',
    },
    require: {
      wizardStep: '^^iscWizardStep',
    },
  });

