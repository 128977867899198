'use strict';

import { ServerConstants } from '../../../../core/serverconstants';
import { SelectedSquareForumsService } from '../../../../core/services/selectedSquareForumsService';

const addRoomDialogTemplate = require('./addRoomDialog.html');
const editRoomDialogTemplate = require('./editRoomDialog.html');

export class ForumController implements ng.IController {
  public static $inject = ['$mdDialog', '$scope', '_', 'serverConstants', 'selectedSquareForumsService'];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $scope: ng.IScope,
    private _: _.LoDashStatic,
    private serverConstants: ServerConstants,
    private selectedSquareForumsService: SelectedSquareForumsService,
  ) { }

  operationTypeConstants = this.serverConstants.operationTypeConstants;

  addForumRoom(page, event) {
    event.stopPropagation();
    const pageEditor = (this.$scope.$parent as any).pageEditor;
    this.$mdDialog.show({
      controller: 'AddForumRoomDialogController',
      controllerAs: 'vm',
      templateUrl: addRoomDialogTemplate,
      parent: angular.element(document.body),
      targetEvent: event,
      clickOutsideToClose: true,
      locals: {
        validationConstants: pageEditor.validationConstants,
        selectedPage: pageEditor.selectedPage,
      },
    }).then((data) => {
      if (!page.Rooms) {
        page.Rooms = [];
      }
      const room = this.selectedSquareForumsService.createRoom(data.Name, !!data.IsVisible);
      room.PageGuid = page.Guid;
      page.Rooms.push(room);
      this.setRoomsDirty(pageEditor.selectedPageIndex);
    });
  }

  private setRoomsDirty(index) {
    const tab = `tab-form-${index}`;
    const pageEditor = (this.$scope.$parent as any).pageEditor;
    if (!this._.isUndefined(pageEditor.form[tab])) {
      pageEditor.form[tab].Rooms.$setDirty();
      pageEditor.form[tab].Rooms.$setTouched();
    }
  }

  addRoomButtonHidden(page) {
    const rooms = page.Rooms;
    if (!rooms) {
      return false;
    }

    const roomsNotDeleted = this._.filter(rooms, (room: any) => room.OperationType !== this.operationTypeConstants.delete);
    return roomsNotDeleted.length >= 10;
  }

  editRoom(room) {
    const pageEditor = (this.$scope.$parent as any).pageEditor;
    this.setRoomsDirty(pageEditor.selectedPageIndex);
    const dialogData = {
      room,
      validationConstants: pageEditor.validationConstants,
      selectedPage: pageEditor.selectedPage,
    };

    this.showEditRoomDialog(dialogData).then(() => {
      // Create + Update in the same operation
      if (room.OperationType !== this.operationTypeConstants.create) {
        room.OperationType = this.operationTypeConstants.update;
      }
    });
  }

  showEditRoomDialog(data) {
    return this.$mdDialog.show({
      controller: 'EditRoomDialogController',
      controllerAs: 'vm',
      templateUrl: editRoomDialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: data,
    });
  }

  deleteRoom(room, page) {
    const pageEditor = (this.$scope.$parent as any).pageEditor;
    const remainingRooms = this._.filter(page.Rooms, (r: any) => r !== room && r.OperationType !== this.operationTypeConstants.delete);
    if (remainingRooms.length === 0) {
      this.$mdDialog.show(this.$mdDialog.iscAlert()
        .title('Cannot delete last room')
        .text('Deleting the last room is not possible.')
        .ok('Ok'));
    } else {
      this.$mdDialog.show(this.$mdDialog.iscConfirm({
        title: 'Delete lounge room',
        text: `Do you want to delete the lounge room ${room.Name}? All content in this room will be deleted as well.`,
        ok: 'Yes',
        cancel: 'No',
      })).then(() => {
        room.OperationType = this.operationTypeConstants.delete;
        this.setRoomsDirty(pageEditor.selectedPageIndex);
        if (!room.Guid) {
          page.Rooms = this._.without(page.Rooms, room);
        }
      });
    }
  }

  isNotDeleted = (o) => o.OperationType !== this.operationTypeConstants.delete;
}
