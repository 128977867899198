'use strict';

import { IscSquareJobStatusFilterController } from './isc-square-job-status-filter.controller';

const template = require('./isc-square-job-status-filter.html');
require('./_isc-square-job-status-filter.scss');

export const iscSquareJobStatusFilterComponent: ng.IComponentOptions = {
  controller: IscSquareJobStatusFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    statuses: '=',
    name: '@',
  },
};
