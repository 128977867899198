'use strict';

export class TargetingResultController {
  isRecalculatingTargeting: boolean;
  matches: number;
  randomlySelected: number;
  targeted: number;
  targetedFrom: number;
  right: boolean;
}
