'use strict';

import { ActivityHealthController } from './activityHealth.controller';
const template = require('./activityHealth.html');

require('./activityHealth.scss');

angular.module('insitesApp.activityHealth').component('activityHealthComponent', {
  controller: ActivityHealthController,
  controllerAs: 'vm',
  templateUrl: template,
});
