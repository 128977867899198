'use strict';

import { GoogleTranslateHelperService } from './google-translate-helper.service';

export class GoogleTranslateHelperController {
  static $inject = ['$window', 'googleTranslateHelper', '$timeout'];

  constructor(
    private $window: ng.IWindowService,
    public googleTranslateHelper: GoogleTranslateHelperService,
    private $timeout: ng.ITimeoutService) { }

  $postLink() {
    this.createGoogleTranslateElement();
  }

  private createGoogleTranslateElement() {
    // Defer if google translate is fully loaded
    if (!this.$window.google || !this.$window.google.translate || !this.$window.google.translate.TranslateElement) {
      this.$timeout(() => this.createGoogleTranslateElement(), 100);
    } else {
      new this.$window.google.translate.TranslateElement({
        pageLanguage: 'en',
        layout: this.$window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
        multilanguagePage: true,
      }, 'google_translate_element');
    }
  }
}
