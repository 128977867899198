'use strict';

import { ActivityStartEndDate } from './activityStartEndDate.controller';

const template = require('./activityStartEndDate.html');

export const ActivityStartEndDateComponent: ng.IComponentOptions = {
  controller: ActivityStartEndDate,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    allowStartDateEdit: '<',
    allowEndDateEdit: '<',
    isReadOnly: '=',
    endDate: '<',
    squareActivity: '<',
    isActivityTargetCompletionEnabled: '<',
  },
  require: {
    form: '^^form',
  },
};
