'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from '../../core/serverconstants';
import { Utils } from '../../core/utils';

export class DropdownController {
  static $inject = ['$scope', '$attrs', '_', '$timeout', '$stateParams', 'serverConstants', '$document', '$element'];
  constructor(
    private $scope: ng.IScope,
    private $attrs: ng.IAttributes,
    private _: _.LoDashStatic,
    private $timeout: ng.ITimeoutService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverconstants: ServerConstants,
    private $document: ng.IDocumentService,
    private $element: ng.IRootElementService,
  ) {
    this.init();
  }
  label;
  lastSelectedPinnedCount = 10;
  showSuggestionList;
  onSelect;
  selectedItem;
  enablePinning;
  list;
  query;
  isSearchBox;
  isSearch;
  isAutoOpen = false;
  toggleSearch = true;
  isDisabled;
  disabled;
  pinnedOrLastSelectedList = [];
  clientGuid;

  private init = () => {
    this.$document.on('click', this.clickOutside);
    this.isSearch = this.isSearchBox;
    this.$scope.$watch('vm.list', (v: any) => {
      let lastType;

      for (const element of v) {
        if (element.Type !== lastType) {
          element.class = `listType-${element.Type}`;
          lastType = element.Type;
        }
      }

      if (this.list && this.list.length > 0) {
        this.pinnedOrLastSelected();

        // If we tried to search but the list was empty we are changing the query to trigger again the filter
        if (this.query) {
          const x = this.query;
          this.query = null;
          this.$timeout(() => {
            this.query = x;
          }, 100);
        }
      }
    });

    (this.$scope as any).closeThis = () => {
      if (!this.$attrs.id && this.$attrs.id !== 'mainsearchbox') {
        this.toggleSearch = false;
      }
    };

    this.$scope.$watch(() => this.isSearchBox, (newvalue) => {
      this.isSearch = newvalue;
      this.toggleSearch = this.isSearch;
    });
    this.clientGuid = this.$stateParams.clientGuid;
  };

  $onDestroy() {
    this.$document.off('click', this.clickOutside);
  }

  getActivityFormat(){
    const formatName = Utils.reverseEnum(this.serverconstants.squareActivityFormatConstants, this.serverconstants.squareActivityFormatConstants.none);
    const formatLabel = formatName.toUpperCase();
    if (this.label === `${formatLabel} ACTIVITY`) {
      return 'ACTIVITY';
    }
    return this.label;
  }

  openDropdown = () => {
    this.pinnedOrLastSelected();
    if (!this.isSearch) {
      this.toggleSearch = true;
    }
  };

  closeDropdown = () => {
    if (!this.isSearch && (!this.isAutoOpen || !this.query)) {
      this.toggleSearch = false;
    }
  };

  selectItem = async (clickedObject, $event) => {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    if (this.selectedItem || clickedObject) {
      if (this.selectedItem && this.selectedItem.LastSelectedDate) {
        this.selectedItem.LastSelectedDate = DateTime.utc().toString();
      } else if (clickedObject.LastSelectedDate) {
        clickedObject.LastSelectedDate = DateTime.utc().toString();
      }
      this.isAutoOpen = false;
      this.closeDropdown();
      await this.onSelect({ item: this.selectedItem || clickedObject });
    }
    this.query = undefined;
    this.selectedItem = null;
  };

  private pinnedOrLastSelected = () => {
    if (!(this.$scope.$parent as any).svm) { // Used only to check context where this dropdown lies
      const pinnedItemList = this.pinnedItems(this.list);
      const filteredList = this._.difference(this.list, pinnedItemList);

      const orderedList = this._.orderBy(filteredList, (lastSelected: any) => lastSelected.LastSelectedDate || '', ['desc']);
      const lastSelectedToShow = this.lastSelectedPinnedCount - pinnedItemList.length;

      const lastSelectedList = this._.take(orderedList, lastSelectedToShow);
      this.pinnedOrLastSelectedList = this._.union(pinnedItemList as any[], lastSelectedList as any[]);
    } else {
      this.pinnedOrLastSelectedList = this.list;
    }
  };

  private pinnedItems = (list) => this._.filter(list, (x) => x.IsPinned && x.IsPinned());

  clickOutside = (event: JQueryEventObject) => {
    if ((this.$attrs.id && this.$attrs.id === 'notmain') || (!this.isSearch && this.toggleSearch && !this.$element[0].contains(event.target))) {
      this.query = '';
      this.toggleSearch = false;
      this.isAutoOpen = false;
      // Since we've overruled the onClick, Angular doesn't update correctly. So we apply the changes.
      // In this case the dropdown should be closed.
      this.$scope.$evalAsync();
    }
  };

  isPinned = (item) => {
    if (typeof item.IsPinned !== 'undefined') {
      return !item.IsPinned();
    }
  };

  get shouldShowSearchField() {
    return !this.isDisabled && this.toggleSearch;
  }

  get shouldShowSuggestionsList() {
    return !this.isDisabled && this.toggleSearch && !this.query && this.pinnedOrLastSelectedList && this.showSuggestionList;
  }
}
