'use strict';

import { ActivityConfirmitIdController } from './activityConfirmitId.controller';

const template = require('./activityConfirmitId.html');
require('./activityConfirmitId.scss');

export const ActivityConfirmitIdComponent: ng.IComponentOptions = {
  controller: ActivityConfirmitIdController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    isDisabled: '<',
  },
  require: {
    form: '^^form',
  },
};
