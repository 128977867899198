'use strict';

import { ImageCropController } from './image-crop.controller';

const dialogTemplate = require('./image-crop.html');

export class ImageCropService {
  static $inject = ['$mdDialog', '$q'];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $q: ng.IQService,
  ) {
  }

  async cropAvatar(file: Blob) {
    const urlCreator = window.URL || (window as any).webkitURL;
    const imageUrl = urlCreator.createObjectURL(file);

    const cropArea: ICropArea = await this.$mdDialog.show({
      controller: ImageCropController,
      controllerAs: 'vm',
      templateUrl: dialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true,
      locals: {
        file: imageUrl,
        areaType: 'circle',
        areaRatio: 1,
      },
    });
    return cropArea;
  }

  async cropStimuli(file: File) {
    const urlCreator = window.URL || (window as any).webkitURL;
    const imageUrl = urlCreator.createObjectURL(file);

    const cropArea: ICropArea = await this.$mdDialog.show({
      controller: ImageCropController,
      controllerAs: 'vm',
      multiple: true,
      templateUrl: dialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      fullscreen: true,
      locals: {
        file: imageUrl,
        areaType: 'square',
        areaRatio: 1.86,
      },
    });

    const cropped = await this.cropImage(file.type, imageUrl, cropArea);
    if (file.name) {
      (cropped as any).name = file.name;
    }
    return cropped;
  }

  private async cropImage(type: string, imageUrl: string, cropArea: ICropArea) {
    let image: HTMLImageElement | HTMLCanvasElement = new Image();
    const imageLoadDeferred = this.$q.defer();
    image.onload = () => imageLoadDeferred.resolve();
    image.onerror = (err: any) => imageLoadDeferred.reject(err.error);
    image.src = imageUrl;

    await imageLoadDeferred.promise;

    if (cropArea.rotation !== 0) {
      image = this.rotateImage(image, cropArea.rotation);
    }

    const cropCanvas = document.createElement('canvas') as HTMLCanvasElement;
    const cropContext = cropCanvas.getContext('2d');
    cropCanvas.width = cropArea.width;
    cropCanvas.height = cropArea.height;
    cropContext.drawImage(image, cropArea.x, cropArea.y, cropArea.width, cropArea.height, 0, 0, cropArea.width, cropArea.height);
    return this.canvasToBlob(cropCanvas, type);
  }

  private rotateImage(image: HTMLImageElement, rotation: 90 | 180 | 270) {
    const switchAxis = rotation === 90 || rotation === 270;
    const rotateCanvas = document.createElement('canvas') as HTMLCanvasElement;
    const rotateContext = rotateCanvas.getContext('2d');
    rotateCanvas.width = switchAxis ? image.height : image.width;
    rotateCanvas.height = switchAxis ? image.width : image.height;
    const drawWidth = switchAxis ? image.width : image.height;
    const drawHeight = switchAxis ? image.height : image.width;
    rotateContext.save();
    rotateContext.translate(rotateCanvas.width / 2, rotateCanvas.height / 2);
    rotateContext.rotate(rotation * Math.PI / 180);
    rotateContext.drawImage(image, drawWidth / 2, drawHeight / 2, -drawWidth, -drawHeight);
    rotateContext.restore();
    return rotateCanvas;
  }

  private canvasToBlob(canvas: HTMLCanvasElement, blobType: string) {
    const blobDeffered = this.$q.defer<Blob>();
    canvas.toBlob((blob) => {
      blobDeffered.resolve(blob);
    }, blobType);
    return blobDeffered.promise;
  }
}

export interface ICropArea {
  x: number;
  y: number;
  width: number;
  height: number;
  rotation: 0 | 90 | 180 | 270;
}
