'use strict';

import _ = require('lodash');
import { ServerConstants } from '../../core/serverconstants';
import { SegmentationHelperService } from '../../core/services/segmentationHelper.service';

export class IscSegmentationsFilterController {
  static $inject = ['$timeout',  'serverConstants', '$scope', 'segmentationHelperService'];

  constructor(
    private $timeout,
    private serverConstants: ServerConstants,
    private $scope: ng.IScope,
    private segmentationHelperService: SegmentationHelperService,
  ) {
    $scope.$watch('vm.segmentations', (newValue) => {
      if (newValue) {
        this.initDropdown();
      }
    }, true);
  }

  segmentations;
  isDisabled;
  model;
  dropdown = [];

  initDropdown() {
    this.dropdown = [];
    const enabledSegmentations = _.filter(this.segmentations, (seg) => !seg.IsDisabled || (this.model && this.model.Guid === seg.Guid));
    // Starred segmentations
    const starredSegmentations = enabledSegmentations
      .sort((a, b) => a.Type - b.Type)
      .filter((seg) => seg.IsStarred);
    if (starredSegmentations.length > 0) {
      this.dropdown.push({
        title: 'Key segmentations',
        segmentations: starredSegmentations,
      });
    }

    // Other segmentations
    const segmentationsByType = _.groupBy(enabledSegmentations.filter((seg) => !seg.IsStarred), (seg) => seg.Type);

    _.forEach(segmentationsByType, (segmentations, key) => {
      this.dropdown.push({
        title: this.segmentationHelperService.segmentationTypeToReadableString(parseInt(key, 10)),
        segmentations,
      });
    });
  }
}
