'use strict';

import { ActivityRecruitmentPartnerController } from './activityRecruitmentPartner.controller';
const template = require('./activityRecruitmentPartner.html');

export const ActivityRecruitmentPartnerComponent: ng.IComponentOptions = {
  controller: ActivityRecruitmentPartnerController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    isPublished: '<',
    squareActivity: '<',
    isReadOnly: '<',
    surveyPreparationStatus: '<',
  },
  require: {
    wizardStep: '^^iscWizardStep',
  },
};
