'use strict';

// Add Fathom Analytics script to the page
const fathomSiteId = (window as any).__env.fathomSiteId;
const fathomUrl = (window as any).__env.fathomUrl;

if (fathomSiteId !== '') {
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';
  scriptTag.src = fathomUrl;
  scriptTag.defer = true;
  const dataSite = document.createAttribute('data-site');
  scriptTag.setAttributeNode(dataSite);
  dataSite.value = fathomSiteId;
  const dataSpa = document.createAttribute('data-spa');
  dataSpa.value = 'auto';
  scriptTag.setAttributeNode(dataSpa);

  document.body.appendChild(scriptTag);
}
