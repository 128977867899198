'use strict';

export class ActivityInlineSurveyLinkController {

  model;

  getValidationRegexForConfirmitId = () => {
    let confirmitIdRegex = 'p[0-9]{6,}?';
    if (this.model.SurveyId) {
      confirmitIdRegex = this.model.SurveyId;
    }
    return `^http(s)?://go.insites-consulting.com/wix/inline.aspx\\?p=${confirmitIdRegex}&v=[0-9]{4}&qt=false&xhr=false&__ckey=[A-Z0-9]{32}$`;
  };
}
