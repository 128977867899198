var IscCardController = (function () {
    function IscCardController($transclude) {
        this.$transclude = $transclude;
        this.collapsible = false;
        this.collapsed = false;
    }
    IscCardController.prototype.$onInit = function () {
        this.hasCardSubtitle = this.$transclude.isSlotFilled('iscCardSubtitle');
        this.hasCardActions = this.$transclude.isSlotFilled('iscCardActions');
    };
    IscCardController.prototype.toggleCollapsed = function () {
        this.collapsed = !this.collapsed;
    };
    IscCardController.$inject = ['$transclude'];
    return IscCardController;
}());
export { IscCardController };
