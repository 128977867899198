'use strict';

import { ActivityService } from './../../core/dataservices/activity.service';
import { SegmentationService } from '../../core/dataservices/segmentation.service';

require('./_addActivityDialog.scss');


export class AddActivityDialogController {
  static $inject = ['$mdDialog', 'activityservice', 'segmentationService', '$stateParams', '$q'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private activityservice: ActivityService,
    private segmentationService: SegmentationService,
    private $stateParams: ng.ui.IStateParamsService,
    private $q: ng.IQService,
  ) {
    this.getActivityList();
  }

  activities: any[];
  searchTerm = '';
  model = {
    isDefaultActivity: true,
    selectedActivityGuid: '',
    nonExistingSegmentations: [],
    archivedSegmentations: [],
    unarchiveError: '',
  };
  checking = false;

  cancel() {
    this.$mdDialog.cancel();
  }

  async checkActivitySegmentations(selectedActivityGuid) {
    if (selectedActivityGuid) {
      this.checking = true;
      this.model.nonExistingSegmentations = await this.activityservice.getMissingSegmentations(selectedActivityGuid);
      this.model.archivedSegmentations = await this.activityservice.getArchivedSegmentations(selectedActivityGuid);
      this.checking = false;
    }
  }

  addActivity() {
    this.model.selectedActivityGuid = (this.model.isDefaultActivity === true) ? null : this.model.selectedActivityGuid;
    this.$mdDialog.hide(this.model.selectedActivityGuid);
  }

  clearSearchTerm() {
    this.searchTerm = '';
  }

  private async getActivityList() {
    this.activities = await this.activityservice.getProgramActivities(this.$stateParams.programGuid);
  }

  async unarchiveSegmentations() {
    const promises = this.model.archivedSegmentations.map((e) => this.segmentationService.toggleIsArchived(e.Guid));
    try {
      const results = await this.$q.all(promises);
      if (results.every((e) => e)) {
        this.model.archivedSegmentations = [];
      }
    } catch (error) {
      this.model.unarchiveError = 'Failed to unarchive all segmentations. Please try manually';
    }
  }
}
