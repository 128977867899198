var IscSwitchController = (function () {
    function IscSwitchController($element) {
        this.$element = $element;
    }
    IscSwitchController.prototype.$postLink = function () {
        var switchElem = this.$element.find('md-switch');
        this.ngModelController = switchElem.controller('ngModel');
    };
    IscSwitchController.prototype.onValueChanged = function () {
        if (this.onChange) {
            this.onChange({ value: this.model });
        }
        this.ngModelController.$setValidity('serverErrors', true);
    };
    IscSwitchController.prototype.onBlurObject = function () {
        if (this.onBlur) {
            this.onBlur();
        }
    };
    IscSwitchController.$inject = ['$element'];
    return IscSwitchController;
}());
export { IscSwitchController };
