'use strict';

import { ParentActivityChangeConfirm } from './ParentActivityChangeConfirm';
import { ServerConstants } from '../../core/serverconstants';

export abstract class ActivitySpecifyControllerBase extends ParentActivityChangeConfirm {
  constructor(
    protected $mdDialog,
    protected dateFormatService,
    protected serverConstants: ServerConstants,
  ) {
    super($mdDialog);
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnlyActivity()) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    let description = '';
    if (this.getSquareActivity().Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  async saveWithParentActivityChangesConfirmation() {
    if (this.getSquareActivity().isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }

    return this.saveData(true);
  }

  async $wizardBeforeNext() {
    await this.saveWithParentActivityChangesConfirmation();
  }

  protected hasChanges(): boolean {
    const compareStartDate = this.dateFormatService.getDateTime(this.getSquareActivity().Detail.StartDate, this.getSquareActivity().Detail.Offset);
    const compareEndDate = this.dateFormatService.getDateTime(this.getSquareActivity().Detail.EndDate, this.getSquareActivity().Detail.Offset);
    const hasOffsetChanged = this.getSquareActivity().Detail.Offset !== this.getModel().Offset;
    return (
      hasOffsetChanged ||
      !compareStartDate.equals(this.getModel().StartDateTime) ||
      !compareEndDate.equals(this.getModel().EndDateTime) ||
      (
        this.getSquareActivity().Detail.ThemeGuid !== this.getModel().Theme &&
        this.getModel().Theme !== this.serverConstants.validationConstants.emptyGuid
      )
    );
  }

  protected abstract getSquareActivity();
  protected abstract getModel();
  protected abstract saveData(reload: boolean);
  protected abstract isReadOnlyActivity(): boolean;
}
