'use strict';

import { ServerConstants } from '../../core/serverconstants';

export class IscSquareJobStatusFilterController {
  static $inject = ['serverConstants'];
  constructor(private serverConstants: ServerConstants) {

  }
  availableStatuses = [
    {
      Label: 'Legacy',
      Value: this.serverConstants.jobIdStatusConstants.legacy,
    },
    {
      Label: 'Not Started',
      Value: this.serverConstants.jobIdStatusConstants.notStarted,
    },
    {
      Label: 'Active',
      Value: this.serverConstants.jobIdStatusConstants.active,
    },
    {
      Label: 'Paused',
      Value: this.serverConstants.jobIdStatusConstants.paused,
    },
    {
      Label: 'Expiring soon',
      Value: this.serverConstants.jobIdStatusConstants.elapsed75Perc,
    },
    {
      Label: 'Expired',
      Value: this.serverConstants.jobIdStatusConstants.expired,
    },
  ];
}
