'use strict';

export class BeaconService {
  static $inject = [
    '$window',
    '__env',
  ];

  constructor(
    private $window: ng.IWindowService,
    private __env: Environment,
  ) {

  }

  /**
   *
   * @param {string} relativeUrl this needs to be anonymous for beacons
   * @param {Record<string,string>} data data can take any form
   * @returns {Promise<boolean>} this should probably be not awaited
   */
  public async sendBeacon(relativeUrl: string, data: Record<string,string>) : Promise<boolean> {
    // some explanation might be necessary as to why we use form-data.
    // the short answer is becsause it is the most reliable and I didn't find any info online about browsers not allowing this
    // these are all the options for Content-Type based on what object we send:
    // 1. multipart/form-data if you send FormData
    // 2. application/x-www-form-urlencoded is you send UrlSearchParams (Chrome might have problems with this)
    // 3. text/plain if you send a string (here I got either 404 or 415 from the server)
    // 4. application/json (or anything else) if you send a Blob and set its content type
    // (this last one is the worst option, as it requires CORS headers and even then browsers block it for security reasons)
    // find most infor about beacon here https://www.w3.org/TR/beacon/#sec-sendBeacon-method
    const beaconFormData = new FormData();
    for (const prop in data) {
      beaconFormData.append(prop, data[prop]);
    }
    return this.$window.navigator.sendBeacon(
      `${this.__env.commandApiUrl}${relativeUrl}`,
      beaconFormData,
    );
  }
}
