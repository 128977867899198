'use strict';

import { ServerConstants } from '../../core/serverconstants';
import { ActivityService } from '../../core/dataservices/activity.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { SquareActivityModel } from './squareActivityModel';
import { FeatureService } from '../../core/dataservices/feature.service';
import { DiscussionService } from '../../core/dataservices/discussion.service';
import { MappingService } from '../../core/services/mapping.service';
import { CommunicationService } from '../../core/dataservices/communication.service';

import {
  ICommunicationChannelWithStimulus, IDiscussionActivityRequest,
  IDiscussionActivityResponse,
} from '../../core/contracts/discussion.contract';
import { IscUIUtils } from 'isc-ui';

export class ActivityQualResearchConfigController {
  static $inject = ['$stateParams', 'spinnerservice', 'activityservice', 'serverConstants', '$state', '$location', 'featureservice',
    'discussionService', 'mappingService', 'communicationservice', '$q'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private spinnerservice: SpinnerService,
    private activityservice: ActivityService,
    private serverConstants: ServerConstants,
    private $state: ng.ui.IStateService,
    private $location: ng.ILocationService,
    private featureService: FeatureService,
    private discussionService: DiscussionService,
    private mappingService: MappingService,
    private communicationservice: CommunicationService,
    private $q: ng.IQService,

  ) {
    this.squareActivityFormatConstants = serverConstants.squareActivityFormatConstants;
    this.validationConstants = serverConstants.validationConstants;
    this.activityTypes = serverConstants.squareActivityTypeConstants;
  }

  validationConstants;
  initial = {};
  activity = {};
  loading = true;
  squareActivityFormatConstants;
  activityTypes;
  squareActivity: SquareActivityModel;
  isReadOnly;
  isPublished;
  isSavingUpReward;
  activityType: number;

  async $onInit() {
    this.spinnerservice.show('loading');
    try {
      if (this.activityType == null && this.$stateParams.activityType !== null) {
        this.activityType = this.$stateParams.activityType as number;
      }
      const response = await this.loadSpecifications();

      this.squareActivity = response[0].data;

      const discNewResponses = response[2];
      if (discNewResponses) {
        this.squareActivity.ProbeQuestionsAlreadyAnswered = discNewResponses[0]?.isAnswered;
      }

      this.isReadOnly = false;
      this.isPublished = this.squareActivity.ActivityQualDetail !== null ? this.squareActivity.ActivityQualDetail.IsPublished : false;
      this.squareActivity.isChildActivity =
        this.squareActivity.Detail.SequenceRole === this.serverConstants.activitySequenceRoleConstants.child;
      this.squareActivity.isParentActivity =
        this.squareActivity.Detail.SequenceRole === this.serverConstants.activitySequenceRoleConstants.parent;
      this.isSavingUpReward = response[1];

      // response[2] contains the data result for DiscussionNew - this call is made only if the activity is DiscussionNew and the result has 2 parts:
      // response[2][0] - returns DiscussionDetails for Build step and
      // response[2][1] - return CommunicationChannel with stimuli for Present step
      if (this.activityType !== null
        && (IscUIUtils.isActivityDiscussionNewType(this.activityType, this.serverConstants))
        && response[2] !== undefined && response[2] !== null) {
        this.mapDiscussionActivityDetails(response);
      }
    } finally {
      this.spinnerservice.hide('loading');
      this.loading = false;
    }

  }

  private mapDiscussionActivityDetails = (response) => {
    if (response[2][0]) {
      const discussion: IDiscussionActivityResponse = response[2][0];
      this.mappingService.mapDiscussionActivityDetailsToSquareActivityModel(discussion, this.squareActivity);
    }
    if (response[2][1]) {
      const communication: ICommunicationChannelWithStimulus = response[2][1];
      this.mappingService.mapCommunicationDetailsToSquareActivityModel(communication, this.squareActivity);
    }
  };

  loadSpecifications = async () => {
    const discussionRequest: IDiscussionActivityRequest = { activityGuid: this.$stateParams.activityGuid };

    const discussionCall = this.activityType
      && (IscUIUtils.isActivityDiscussionNewType(this.activityType, this.serverConstants))
      ? this.$q.all([await this.discussionService.getDiscussionActivity(discussionRequest),
        await this.communicationservice.getCommunicationWithStimuliForActivityQual(this.$stateParams.activityGuid)])
      : this.$q.resolve();

    return this.$q.all([await this.activityservice.selectActivityQualResearch(this.$stateParams.activityGuid),
      await this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards),
      discussionCall,
    ] as const);
  };
  goBackAct = () => {
    if (this.$stateParams.referer) {
      this.$location.url(this.$stateParams.referer);
    } else {
      this.$state.go('root.square.activities.qual', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      });
    }
  };
}
