'use strict';

import { IChipsItem, IMemberOverviewFilter, IMembershipStatusItem } from '../../core/contracts/filter.contract';
import { ServerConstants } from '../../core/serverconstants';
import * as _ from 'lodash';
import { ChipsItemModel } from './chipsItemModel';
import { MemberService } from '../../core/services/member.service';
import { DateTime } from 'luxon';
import { Utils } from '../../core/utils';

export class IscMemberFilterController {
  static $inject = ['$scope', 'serverConstants', 'memberService'];

  constructor(
    private $scope: ng.IScope,
    private serverConstants: ServerConstants,
    private memberService: MemberService,
  ) { }

  memberFilter: IMemberOverviewFilter;
  roleLabels: Map<number, string> = new Map();
  availableSegmentations;
  chips: IChipsItem = new ChipsItemModel();
  dateAsChip = [];
  keywordAsChip = [];
  listAsChip = [];
  minStartDate = '';
  maxEndDate = '';
  excludeParticipantsInvitedSinceWeeksAsChip = [];
  segmentsObj;
  availableRoles;
  membershipStatusList: IMembershipStatusItem[] = [];
  selectedStatusChips: IMembershipStatusItem[] = [];
  selectedSegmentsChips = [];
  showToggle = false;
  toggleClicked = false;
  private removeScrollHandler = _.noop;
  selectedSegmentation;
  filterMembers: () => Promise<void>;

  filterDataCounters;
  $OnDestroy() {
    this.removeScrollHandler();
  }

  async $onInit() {
    const mainContent = document.getElementById('maincontent');

    const handler = () => this.filterScroll();
    mainContent.addEventListener('scroll', handler);
    this.removeScrollHandler = () => mainContent.removeEventListener('scroll', handler);
    this.membershipStatusList = this.memberService.getMembershipStatusList();

    const roles = Utils.getRoleEnumAsArray(this.serverConstants.roleConstants);
    roles.forEach((role) => this.roleLabels.set(role.Value, role.Label));

    this.$scope.$watchGroup(
      [
        'vm.memberFilter.StartDate',
        'vm.memberFilter.EndDate',
        'vm.showFilter',
        'vm.memberFilter.Keyword',
        'vm.memberFilter.List',
        'vm.memberFilter.Statuses',
        'vm.memberFilter.SegmentGuids',
        'vm.memberFilter.SegmentOptions',
        'vm.minStartDate',
        'vm.maxEndDate',
        'vm.availableSegmentations',
        'vm.memberFilter.ExcludeParticipantsInvitedSinceWeeks',
      ],
      () => {
        if (this.memberFilter) {
          this.chips.StartDate = (this.memberFilter.StartDate || DateTime.fromISO(this.minStartDate)).toFormat('MMM d yyyy');
          this.chips.EndDate = (this.memberFilter.EndDate || DateTime.fromISO(this.maxEndDate)).toFormat('MMM d yyyy');

          if (this.memberFilter.StartDate || this.memberFilter.EndDate) {
            this.dateAsChip = this.convertToChip(`${this.chips.StartDate} - ${this.chips.EndDate}`);
          }

          if(this.memberFilter.ExcludeParticipantsInvitedSinceWeeks || this.memberFilter.ExcludeParticipantsInvitedSinceWeeks === 0) {
            this.excludeParticipantsInvitedSinceWeeksAsChip = this.convertToChip(`${this.memberFilter.ExcludeParticipantsInvitedSinceWeeks} Weeks`);
          } else {
            this.excludeParticipantsInvitedSinceWeeksAsChip = [];
          }

          this.keywordAsChip = this.ArrayToOrChip(this.convertToArrayByDelimiter(this.memberFilter.Keyword), 3);
          if (this.memberFilter.List && this.memberFilter.List.length > 0) {
            this.listAsChip = this.ArrayToOrChip(this.memberFilter.List, 3);
          } else {
            this.listAsChip = [];
          }

          if (this.memberFilter.Statuses) {
            this.calculateSelectedStatusChips();
          }

          if (!this.memberFilter.SegmentOptions) {
            this.memberFilter.SegmentOptions = this.serverConstants.selectedSegmentsOptionConstants.any;
          }
          if (this.memberFilter.SegmentGuids) {
            this.calculateSelectedSegmentsChips(this.memberFilter.SegmentGuids);
          }
        }
      },
    );

    this.$scope.$watchGroup(['vm.filterDataCounters'], () => {
      if (this.filterDataCounters) {
        if (this.availableRoles && this.filterDataCounters.Roles) {
          _.each(this.availableRoles, (role) => {
            const count = this.filterDataCounters.Roles[role.Id] ? this.filterDataCounters.Roles[role.Id] : 0;
            role.DisplayLabel = `${role.Label} (${count})`;
          });
        }
        if (this.membershipStatusList && this.filterDataCounters.Statuses) {
          _.each(this.membershipStatusList, (status) => {
            const count = this.filterDataCounters.Statuses[status.Value] ? this.filterDataCounters.Statuses[status.Value] : 0;
            status.DisplayLabel = `${status.Label} (${count})`;
          });
        }
        if (this.availableSegmentations && this.filterDataCounters.Segments) {
          _.each(this.availableSegmentations, (segmentation) => {
            _.each(segmentation.SegmentItems, (segment) => {
              const count = this.filterDataCounters.Segments[segment.Guid] ? this.filterDataCounters.Segments[segment.Guid] : 0;
              segment.DisplayLabel = `${segment.SegmentAnswer} (${count})`;
            });
          });
        }
      }
    });
  }

  private convertToChip(value) {
    const chip = [];
    if (value) {
      chip[0] = value;
    }
    return chip;
  }

  private convertToArrayByDelimiter(value) {
    if (!value) {
      return [];
    }

    const delimiter = this.getDelimiter(value);
    if (!delimiter) {
      return [value];
    }

    const keywords = [];
    const re = new RegExp(`(?:'(.*?)'|([^${delimiter}]+))${delimiter}?`, 'g');
    let matches;
    do {
      matches = re.exec(value);
      if (!matches) {
        break;
      }
      keywords.push(`${matches[1] || matches[2]}`);
    } while (matches);

    return _.uniq(keywords);
  }

  private ArrayToOrChip(array, maxItems) {
    if (!array) {
      return [];
    }
    if (array.length <= 1) {
      return array;
    }
    if (array.length <= maxItems) {
      return [`${array.slice(-maxItems, -1).join(', ')} or ${array.slice(-1)}`];
    }
    if (array.length > maxItems) {
      return [`${array.slice(0, maxItems - 1).join(', ')}, ... or ${array.slice(-1)} (${array.length} items)`];
    }
  }

  private getDelimiter(value) {
    const reFindDelimiter = /^(?:'.*?'|[^,\s;]+)([,\s;]){1}/;
    const matches = reFindDelimiter.exec(value);
    if (!matches) {
      return undefined;
    }
    return matches[1];
  }

  private getSegmentsObj() {
    if (!this.segmentsObj) {
      this.segmentsObj = {};
      _.forEach(this.availableSegmentations, (segmentation) => {
        _.forEach<any[]>(segmentation.SegmentItems, (segment: any) => {
          this.segmentsObj[segment.Guid] = { SegmentationGuid: segmentation.Guid, SegmentAnswer: segment.SegmentAnswer, DisplayLabel: segment.SegmentAnswer };
        });
      });
    }

    return this.segmentsObj;
  }

  private getSegmentsJoinString() {
    if (this.memberFilter.SegmentOptions === this.serverConstants.selectedSegmentsOptionConstants.any) {
      return 'OR';
    }

    if (this.memberFilter.SegmentOptions === this.serverConstants.selectedSegmentsOptionConstants.all) {
      return 'AND';
    }

    return ' ';
  }

  calculateSelectedStatusChips() {
    this.selectedStatusChips = _.filter<IMembershipStatusItem>(this.membershipStatusList || [],
      (membership) => _.some(this.memberFilter.Statuses || [], (item) => item === membership.Value));
  }

  calculateSelectedSegmentsChips(value) {
    this.selectedSegmentsChips = [];

    if (!value.length) {
      return;
    }
    const segementsObj = this.getSegmentsObj();
    const segmentsJoinString = this.getSegmentsJoinString();
    _.forEach(value, (item) => {
      if (segementsObj && segementsObj[item]) {
        const existingSegment = _.filter(this.selectedSegmentsChips, (chip) => chip.SegmentationGuid === segementsObj[item].SegmentationGuid);
        if (!existingSegment.length) {
          this.selectedSegmentsChips.push({
            SegmentationGuid: segementsObj[item].SegmentationGuid,
            Label: segementsObj[item].SegmentAnswer,
            Segments: [item],
          });
          return;
        }

        existingSegment[0].Label = [existingSegment[0].Label, segementsObj[item].SegmentAnswer].join(` ${segmentsJoinString} `);
        existingSegment[0].Segments.push(item);
      }
    });
  }

  onRemoveSelectedSegment(chip) {
    _.remove(this.memberFilter.SegmentGuids, (item) => _.some(chip.Segments, (segment) => segment === item));
    this.calculateSelectedSegmentsChips(this.memberFilter.SegmentGuids);
  }

  paste(event) {
    const reIds = /^\d+([^\d]+)(?:\d+\1)*\d*$/m;
    // eslint-disable-next-line max-len
    const reEmails = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)([^a-z0-9!#$%&'*+/=?^_`{|}~-])(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\1)*(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)?$/m;
    const pastedText = event.clipboardData.getData('text/plain');
    const matches = reIds.exec(pastedText) || reEmails.exec(pastedText);

    if (matches) {
      const delimiter = matches[1];
      const keywords: string[] = _.filter(_.map(pastedText.split(delimiter), (k) => k.toString().replace('\n', '')), (n) => n !== '');
      if (keywords.length > 1) {
        this.memberFilter.List = keywords;
        event.preventDefault();
      }
    }
  }

  resetSegmentation() {
    this.memberFilter.SegmentGuids = [];
  }

  resetKeyword() {
    this.memberFilter.Keyword = undefined;
  }

  resetList() {
    this.memberFilter.List = [];
  }

  resetDate() {
    this.memberFilter.StartDate = null;
    this.memberFilter.EndDate = null;
  }

  filterScroll() {
    // Scroll event to collapse filter and show filter button when the filter has reached the top banner
    const stickyMenu = document.querySelector('.sticky');
    const banner = document.querySelector('.top-banner');
    if (stickyMenu && banner) {
      const stickyMenuBoundings = stickyMenu.getBoundingClientRect();
      const bannerBounding = banner.getBoundingClientRect();
      if (stickyMenuBoundings.top <= bannerBounding.height) {
        this.$scope.$apply(() => {
          this.showToggle = true;
        });
      } else {
        this.$scope.$apply(() => {
          this.showToggle = false;
          this.resetToggle();
        });
      }
    }
  }

  removeKeyword() {
    this.memberFilter.Keyword = '';
  }

  toggleFilter() {
    const stickyMenu = document.querySelectorAll('.sticky');
    const top = document.getElementById('filter-top');
    if (this.toggleClicked) {
      this.resetToggle();
    } else {
      this.toggleClicked = true;
      top.classList.add('sticky');
      angular.forEach(stickyMenu, (el) => el.classList.add('under-filter-fields'));
    }
  }

  resetToggle() {
    const top = document.getElementById('filter-top');
    if (top) {
      top.classList.remove('sticky');
    }
    this.toggleClicked = false;
    const stickyMenu = document.querySelectorAll('.sticky');
    if (stickyMenu) {
      angular.forEach(stickyMenu, (el) => el.classList.remove('under-filter-fields'));
    }
  }

  showResetFilterBtn(memberFilter: IMemberOverviewFilter) {
    const isFilterApplied = !(
      memberFilter &&
      (!memberFilter.SegmentGuids || memberFilter.SegmentGuids.length === 0) &&
      (!memberFilter.List || memberFilter.List.length === 0) &&
      (!memberFilter.Roles || memberFilter.Roles.length === 0) &&
      (!memberFilter.Statuses || memberFilter.Statuses.length === 0) &&
      (memberFilter.Keyword === undefined || memberFilter.Keyword === null) &&
      memberFilter.StartDate == null &&
      memberFilter.EndDate == null &&
      memberFilter.Suspended === false &&
      memberFilter.ExcludeParticipantsInvitedSinceWeeks == null
    );

    return isFilterApplied;
  }

  onRemoveSelectedStatus(chip) {
    _.remove(this.memberFilter.Statuses, (item) => item === chip.Value);
    this.calculateSelectedStatusChips();
  }

  onRemoveSelectedRole(chip) {
    this.memberFilter.Roles = this.memberFilter.Roles.filter((item) => item !== chip);
  }

  onRemoveExcludeParticipantsInvitedSinceWeeks() {
    this.memberFilter.ExcludeParticipantsInvitedSinceWeeks = null;
  }
}
