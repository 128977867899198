'use strict';


import { SquareConfigSplashController } from './splash.controller';
const template = require('./splash.html');

angular
  .module('insitesApp.squareconfig.splash')
  .component('splash', {
    templateUrl: template,
    controller: SquareConfigSplashController,
    controllerAs: 'vm',
  });

