'use strict';

import appRunDesignConfiguration from './designConfiguration.route';
import './designConfiguration.component';

angular
  .module('insitesApp.clientConfiguration')
  .run(appRunDesignConfiguration);

require('./images');
require('./colors');
