'use strict';

import { SegmentationService } from '../../../core/dataservices/segmentation.service';
import * as _ from 'lodash';

export class SegmentSelectorController implements ng.IOnInit {
  static $inject = ['segmentationservice', '$filter', '$scope'];
  constructor(
    private segmentationservice: SegmentationService,
    private $filter: ng.IFilterService,
    private $scope: ng.IScope,
  ) { }

  ngModel;
  segmentGuid;
  segmentationGuid;
  segmentations;
  Segmentation;
  segmentList;
  Segment;
  formData: ng.IFormController;
  limitAccessToPage: boolean = false;
  private loading: boolean = true;
  private initLimitAccessToPage: boolean = false;

  $onInit() {
    this.ngModel.$render = () => {
      this.segmentGuid = this.ngModel.$viewValue;
    };
    this.segmentationservice.getSquareSegmentations(true)
      .then((segmentations) => {
        this.segmentations = segmentations;
        if (this.segmentGuid && this.segmentationGuid) {
          const selectedSegmentations = this.$filter('filter')(this.segmentations, { Guid: this.segmentationGuid });
          if (selectedSegmentations && selectedSegmentations.length) {
            this.loadSegment(selectedSegmentations[0]);
            this.Segmentation = selectedSegmentations[0];
            this.limitAccessToPage = true;
            this.initLimitAccessToPage = true;
            this.formData.$setPristine();
          }
        }
        this.loading = false;
      });

    this.$scope.$watch('vm.limitAccessToPage', () => {
      if (this.loading) {
        return;
      }

      if (this.limitAccessToPage) {
        if (this.Segment) {
          this.segmentationGuid = this.Segmentation.Guid;
          this.segmentGuid = this.Segment.Guid;
          this.setSegmentGuid(this.Segment);
        }
      } else {
        this.Segmentation = undefined;
        this.segmentationGuid = undefined;
        this.segmentGuid = undefined;
        this.setSegmentGuid(undefined);
      }
      if (!this.initLimitAccessToPage) {
        this.formData.$setDirty();
      } else {
        this.initLimitAccessToPage = false;
      }
      this.setValidity();
    });

    this.$scope.$on('pageSaved', (event, tab) => {
      if (tab === this.formData.$name && !this.limitAccessToPage) {
        this.Segmentation = undefined;
        this.Segment = undefined;
      }
    });
  }

  loadSegment = (segmentation) => {
    if (segmentation && segmentation.Guid) {
      this.segmentList = [];
      _.each(segmentation.SegmentItems,
        (segment) => {
          this.segmentList.push({
            Answer: segment.SegmentAnswer,
            Guid: segment.Guid,
          });
        });
    } else {
      this.segmentList = [];
      this.segmentList.push({
        Answer: 'All segments',
        Guid: 0,
      });
    }

    this.setSegmentGuid(undefined);
    if (this.segmentGuid) {
      const selectedSegments = this.$filter('filter')(this.segmentList, { Guid: this.segmentGuid });
      if (selectedSegments && selectedSegments.length) {
        this.Segment = selectedSegments[0];
        this.setSegmentGuid(this.Segment);
      } else {
        this.Segment = undefined;
      }
    }
  };

  setSegmentGuid = (segment) => {
    let segmentGuid;
    if (segment) {
      segmentGuid = segment.Guid;
    }
    this.ngModel.$setViewValue(segmentGuid);
    this.Segment = segment;
    this.setValidity();
  };

  setValidity() {
    const isValid = this.limitAccessToPage && this.Segment !== undefined || !this.limitAccessToPage;
    this.formData.Segment.$setValidity('requiredWhenLimited', isValid);
  }
}
