'use strict';

import { DesignConfigurationController } from './designConfiguration.controller';

const template = require('./designConfiguration.html');

angular
  .module('insitesApp.clientConfiguration')
  .component('designConfiguration', {
    controller: DesignConfigurationController,
    controllerAs: 'vm',
    templateUrl: template,
  });
