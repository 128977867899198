'use strict';

import {
  IChipsItem,
  IManuallyHandledRewardDictionaryFilterItem,
  IManuallyHandledRewardFilter,
  IManuallyHandledRewardFilterItem,
} from '../../core/contracts/filter.contract';
import { DateTime } from 'luxon';
import * as _ from 'lodash';
import { ChipsItemModel } from './chipsItemModel';
import { ServerConstants } from '../../core/serverconstants';
import { ConstantsFactory } from '../../core/constants.factory';
import { IncentiveService } from '../../core/dataservices/incentive.service';
import { Utils } from '../../core/utils';

export class IscManuallyHandledRewardFilterController {
  static $inject = ['$scope', 'serverConstants', 'constantsfactory', 'incentiveservice'];

  constructor(
    private $scope: ng.IScope,
    private serverConstants: ServerConstants,
    private constantsFactory: ConstantsFactory,
    private incentiveService: IncentiveService,
  ) { }


  manuallyHandledRewardFilter: IManuallyHandledRewardFilter;
  chips: IChipsItem = new ChipsItemModel();
  dateAsChip = [];
  keywordAsChip = [];
  listAsChip = [];
  minStartDate: DateTime;
  maxEndDate: DateTime;
  initStartDate: DateTime;
  initEndDate: DateTime;
  availableClients : IManuallyHandledRewardDictionaryFilterItem[] = [];
  availableSquares : IManuallyHandledRewardFilterItem[] = [];
  availableJobIds : IManuallyHandledRewardFilterItem[] = [];
  availableSquareParticipants : IManuallyHandledRewardFilterItem[] = [];
  availableCountries : IManuallyHandledRewardFilterItem[] = [];
  availableRewardPartners : IManuallyHandledRewardFilterItem[] = [];
  manuallyHandledRewardStatusList : IManuallyHandledRewardFilterItem[] = [{
    label: this.constantsFactory.getLabelForManuallyHandledRewardStatus(this.serverConstants.manuallyHandledRewardStatusConstants.notHandled),
    value: this.serverConstants.manuallyHandledRewardStatusConstants.notHandled,
  },
  {
    label: this.constantsFactory.getLabelForManuallyHandledRewardStatus(this.serverConstants.manuallyHandledRewardStatusConstants.handled),
    value: this.serverConstants.manuallyHandledRewardStatusConstants.handled,
  }];
  manuallyHandledRewardStatusChips : IManuallyHandledRewardFilterItem[];
  manuallyHandledRewardRewardPartnerChips: IManuallyHandledRewardFilterItem[];
  manuallyHandledRewardClientChips: IManuallyHandledRewardDictionaryFilterItem[];

  showToggle = false;
  toggleClicked = false;
  private removeScrollHandler = _.noop;
  filterManuallyHandledRewards: () => Promise<void>;

  $OnDestroy() {
    this.removeScrollHandler();
  }

  async $onInit() {
    this.initStartDate = this.minStartDate;
    this.initEndDate = this.maxEndDate;
    const mainContent = document.getElementById('maincontent');
    this.setupOptionsForFilters();
    this.calculateChips();
    const handler = () => this.filterScroll();
    mainContent.addEventListener('scroll', handler);
    this.removeScrollHandler = () => mainContent.removeEventListener('scroll', handler);

    this.$scope.$watchGroup(
      [
        'vm.manuallyHandledRewardFilter.keyword',
        'vm.manuallyHandledRewardFilter.endDate',
        'vm.manuallyHandledRewardFilter.startDate',
      ],
      () => {
        this.keywordAsChip = this.arrayToOrChip(this.convertToArrayByDelimiter(this.manuallyHandledRewardFilter.keyword), 3);
        if (this.manuallyHandledRewardFilter.list && this.manuallyHandledRewardFilter.list.length > 0) {
          this.listAsChip = this.arrayToOrChip(this.manuallyHandledRewardFilter.list, 3);
        } else {
          this.listAsChip = [];
        }
        this.calculateChips();
      },
    );

  }

  private convertToChip(value: string) {
    const chip = [];
    if (value) {
      chip[0] = value;
    }
    return chip;
  }

  private convertToArrayByDelimiter(value: string) {
    if (!value) {
      return [];
    }

    const delimiter = this.getDelimiter(value);
    if (!delimiter) {
      return [value];
    }

    const keywords = [];
    const re = new RegExp(`(?:'(.*?)'|([^${delimiter}]+))${delimiter}?`, 'g');
    let matches: any[];
    do {
      matches = re.exec(value);
      if (!matches) {
        break;
      }
      keywords.push(`${matches[1] || matches[2]}`);
    } while (matches);

    return _.uniq(keywords);
  }

  private arrayToOrChip(array: any[], maxItems: number) {
    if (!array) {
      return [];
    }
    if (array.length <= 1) {
      return array;
    }
    if (array.length <= maxItems) {
      return [`${array.slice(-maxItems, -1).join(', ')} or ${array.slice(-1)}`];
    }
    if (array.length > maxItems) {
      return [`${array.slice(0, maxItems - 1).join(', ')}, ... or ${array.slice(-1)} (${array.length} items)`];
    }
  }

  private getDelimiter(value: string) {
    const reFindDelimiter = /^(?:'.*?'|[^,\s;]+)([,\s;]){1}/;
    const matches = reFindDelimiter.exec(value);
    if (!matches) {
      return undefined;
    }
    return matches[1];
  }

  async statusChange(value: number[]) {
    if (!angular.equals(this.manuallyHandledRewardFilter.statuses, value)) {
      this.manuallyHandledRewardFilter.statuses = value;
      this.calculateChips();
    }
  }

  async clientChange(value: string[]) {
    if (!angular.equals(this.manuallyHandledRewardFilter.clients, value)) {
      this.manuallyHandledRewardFilter.clients = value;
      this.calculateChips();
    }
  }

  async squareChange(value: string[]) {
    if (!angular.equals(this.manuallyHandledRewardFilter.squares, value)) {
      this.manuallyHandledRewardFilter.squares = value;
      this.calculateChips();
    }
  }

  async jobIdChange(value: string[]) {
    if (!angular.equals(this.manuallyHandledRewardFilter.jobIds, value)) {
      this.manuallyHandledRewardFilter.jobIds = value;
      this.calculateChips();
    }
  }

  async squareParticipantChange(value: number[]) {
    if (!angular.equals(this.manuallyHandledRewardFilter.squareParticipants, value)) {
      this.manuallyHandledRewardFilter.squareParticipants = value;
      this.calculateChips();
    }
  }

  async countryChange(value: string[]) {
    if (!angular.equals(this.manuallyHandledRewardFilter.countries, value)) {
      this.manuallyHandledRewardFilter.countries = value;
      this.calculateChips();
    }
  }

  async rewardPartnerChange(value: number[]) {
    if (!angular.equals(this.manuallyHandledRewardFilter.rewardPartners, value)) {
      this.manuallyHandledRewardFilter.rewardPartners = value;
      this.calculateChips();
    }
  }

  async onRemoveSelectedStatuses(chip: number) {
    this.manuallyHandledRewardFilter.statuses = this.manuallyHandledRewardFilter.statuses.filter((item) => item !== chip);
    this.calculateChips();
  }

  async onRemoveSelectedClients(chip: { value: string; }) {
    this.manuallyHandledRewardFilter.clients = this.manuallyHandledRewardFilter.clients.filter((item) => item !== chip.value);
    this.calculateChips();
  }

  async onRemoveSelectedSquares(chip: string) {
    this.manuallyHandledRewardFilter.squares = this.manuallyHandledRewardFilter.squares.filter((item) => item !== chip);
    this.calculateChips();
  }

  async onRemoveSelectedJobIds(chip: string) {
    this.manuallyHandledRewardFilter.jobIds = this.manuallyHandledRewardFilter.jobIds.filter((item) => item !== chip);
    this.calculateChips();
  }

  async onRemoveSelectedSquareParticipants(chip: number) {
    this.manuallyHandledRewardFilter.squareParticipants = this.manuallyHandledRewardFilter.squareParticipants.filter((item) => item !== chip);
    this.calculateChips();
  }

  async onRemoveSelectedCountries(chip: string) {
    this.manuallyHandledRewardFilter.countries = this.manuallyHandledRewardFilter.countries.filter((item) => item !== chip);
    this.calculateChips();
  }

  async onRemoveSelectedRewardPartners(chip: { value: number; }) {
    this.manuallyHandledRewardFilter.rewardPartners = this.manuallyHandledRewardFilter.rewardPartners.filter((item) => item !== chip.value);
    this.calculateChips();
  }

  paste(event: { clipboardData: { getData: (arg0: string) => any; }; preventDefault: () => void; }) {
    const reIds = /^\d+([^\d]+)(?:\d+\1)*\d*$/m;
    // eslint-disable-next-line max-len
    const reEmails = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)([^a-z0-9!#$%&'*+/=?^_`{|}~-])(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\1)*(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)?$/m;
    const pastedText = event.clipboardData.getData('text/plain');
    const matches = reIds.exec(pastedText) || reEmails.exec(pastedText);

    if (matches) {
      const delimiter = matches[1];
      const keywords: string[] = _.filter(_.map(pastedText.split(delimiter), (k) => k.toString().replace('\n', '')), (n) => n !== '');
      if (keywords.length > 1) {
        this.manuallyHandledRewardFilter.list = keywords;
        event.preventDefault();
      }
    }
  }

  resetKeyword() {
    this.manuallyHandledRewardFilter.keyword = undefined;
    this.filterManuallyHandledRewards();
  }

  resetList() {
    this.manuallyHandledRewardFilter.list = [];
    this.filterManuallyHandledRewards();
  }

  filterScroll() {
    // Scroll event to collapse filter and show filter button when the filter has reached the top banner
    const stickyMenu = document.querySelector('.sticky');
    const banner = document.querySelector('.top-banner');
    if (stickyMenu && banner) {
      const stickyMenuBoundings = stickyMenu.getBoundingClientRect();
      const bannerBounding = banner.getBoundingClientRect();
      if (stickyMenuBoundings.top <= bannerBounding.height) {
        this.$scope.$apply(() => {
          this.showToggle = true;
        });
      } else {
        this.$scope.$apply(() => {
          this.showToggle = false;
          this.resetToggle();
        });
      }
    }
  }

  toggleFilter() {
    const stickyMenu = document.querySelectorAll('.sticky');
    const top = document.getElementById('filter-top');
    if (this.toggleClicked) {
      this.resetToggle();
    } else {
      this.toggleClicked = true;
      top.classList.add('sticky');
      angular.forEach(stickyMenu, (el) => el.classList.add('under-filter-fields'));
    }
  }

  resetToggle() {
    const top = document.getElementById('filter-top');
    if (top) {
      top.classList.remove('sticky');
    }
    this.toggleClicked = false;
    const stickyMenu = document.querySelectorAll('.sticky');
    if (stickyMenu) {
      angular.forEach(stickyMenu, (el) => el.classList.remove('under-filter-fields'));
    }
  }

  showResetFilterBtn(manuallyHandledRewardFilter: IManuallyHandledRewardFilter) {
    const isFilterApplied = !(
      manuallyHandledRewardFilter &&
      (!manuallyHandledRewardFilter.list || manuallyHandledRewardFilter.list.length === 0) &&
      (!manuallyHandledRewardFilter.statuses || manuallyHandledRewardFilter.statuses.length === 0) &&
      (!manuallyHandledRewardFilter.clients || manuallyHandledRewardFilter.clients.length === 0) &&
      (!manuallyHandledRewardFilter.squares || manuallyHandledRewardFilter.squares.length === 0) &&
      (!manuallyHandledRewardFilter.jobIds || manuallyHandledRewardFilter.jobIds.length === 0) &&
      (!manuallyHandledRewardFilter.squareParticipants || manuallyHandledRewardFilter.squareParticipants.length === 0) &&
      (!manuallyHandledRewardFilter.countries || manuallyHandledRewardFilter.countries.length === 0) &&
      (!manuallyHandledRewardFilter.rewardPartners || manuallyHandledRewardFilter.rewardPartners.length === 0) &&
      manuallyHandledRewardFilter.keyword === undefined &&
      manuallyHandledRewardFilter.startDate == null &&
      manuallyHandledRewardFilter.endDate == null
    );

    return isFilterApplied;
  }

  calculateChips() {
    if (this.manuallyHandledRewardFilter) {
      this.manuallyHandledRewardStatusChips = _.filter(this.manuallyHandledRewardStatusList,
        (status) => _.some(this.manuallyHandledRewardFilter.statuses, (item) => item === status.value));

      this.manuallyHandledRewardRewardPartnerChips = _.filter(this.availableRewardPartners,
        (rewardPartner) => _.some(this.manuallyHandledRewardFilter.rewardPartners, (item) => item === rewardPartner.value));

      this.manuallyHandledRewardClientChips = _.filter(this.availableClients,
        (client) => _.some(this.manuallyHandledRewardFilter.clients, (item) => item === client.value));

      this.chips.StartDate = (this.manuallyHandledRewardFilter.startDate.toFormat('MMM d yyyy') || this.minStartDate.toFormat('MMM d yyyy'));
      this.chips.EndDate = (this.manuallyHandledRewardFilter.endDate.toFormat('MMM d yyyy') || this.maxEndDate.toFormat('MMM d yyyy'));

      if (this.manuallyHandledRewardFilter.startDate || this.manuallyHandledRewardFilter.endDate) {
        this.dateAsChip = this.convertToChip(`${this.chips.StartDate} - ${this.chips.EndDate}`);
      }
    }
  }

  async resetFilter() {
    this.manuallyHandledRewardFilter.keyword = undefined;
    this.manuallyHandledRewardFilter.statuses = [];
    this.manuallyHandledRewardFilter.clients = [];
    this.manuallyHandledRewardFilter.squares = [];
    this.manuallyHandledRewardFilter.jobIds = [];
    this.manuallyHandledRewardFilter.squareParticipants = [];
    this.manuallyHandledRewardFilter.countries = [];
    this.manuallyHandledRewardFilter.rewardPartners = [];
    this.manuallyHandledRewardFilter.startDate = this.initStartDate;
    this.manuallyHandledRewardFilter.endDate = this.initEndDate;
    this.manuallyHandledRewardFilter.list = [];
    this.manuallyHandledRewardFilter.cleared = undefined;
    this.calculateChips();
    await this.filterManuallyHandledRewards();
  }

  async setupOptionsForFilters() {
    const filterOptions = await this.incentiveService.getOptionsManuallyHandledRewardFilters();
    for (const key in filterOptions.Clients) {
      this.availableClients.push({ label: filterOptions.Clients[key], value: key });
    }
    filterOptions.RewardPartners.forEach((e: any) => {
      this.availableRewardPartners.push({ label: Utils.getEnumValueName(this.serverConstants.rewardPartnerTypeConstants, e), value: e });
    });
    filterOptions.Squares.forEach((e: any) => {
      this.availableSquares.push({ label: e, value: e });
    });
    filterOptions.Countries.forEach((e: any) => {
      this.availableCountries.push({ label: e, value: e });
    });
    filterOptions.JobIds.forEach((e: any) => {
      this.availableJobIds.push({ label: e, value: e });
    });
    filterOptions.SquareParticipants.forEach((e: any) => {
      this.availableSquareParticipants.push({ label: e, value: e });
    });
  }
}
