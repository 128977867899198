import { UiModule } from '../../../index';
import { IscInputButtonController } from './isc-input-button.controller';
var template = "<form name=\"vm.createForm\" ng-submit=\"vm.submit()\" md-colors=\"{ color: 'warn-500' }\">\n  <div ng-show=\"vm.editMode\" class=\"edit-mode\">\n    <label ng-class=\"vm.createForm.createInput.$invalid ? 'label-error': ''\">{{vm.inputLabel}} - {{vm.createForm.createInput.$viewValue.length ? vm.createForm.createInput.$viewValue.length : 0}}/{{vm.maxlength}}</label>\n    <div layout=\"row\">\n      <input md-colors=\"{ 'border-color': 'warn-500' }\" flex=\"auto\" name=\"createInput\" type=\"text\" tabindex=\"0\" ng-model=\"vm.model\"\n        ng-maxlength=\"vm.maxlength\" />\n      <isc-button flex=\"none\" class=\"warn big submit-button\" type=\"submit\" aria-label=\"Submit\">\n        <isc-button-icon ng-transclude=\"icon\">\n        </isc-button-icon>\n      </isc-button>\n    </div>\n  </div>\n  <isc-button class=\"big warn\" ng-click=\"vm.handleModeChange()\" ng-if=\"!vm.editMode\" label=\"{{vm.buttonLabel}}\">\n    <isc-button-icon>\n      <span ng-transclude=\"icon\"></span>\n    </isc-button-icon>\n  </isc-button>\n  <md-input-container>\n    <div ng-show=\"vm.editMode\" class=\"description-error\" ng-messages=\"vm.createForm.createInput.$error\">\n      <div ng-message=\"maxlength\">Please use max {{vm.maxlength}} characters.</div>\n      <div ng-repeat=\"validation in vm.validations || []\" ng-message=\"{{validation.name}}\">{{validation.errorMessage}}</div>\n    </div>\n  </md-input-container>\n</form>";
UiModule
    .component('iscInputButton', {
    template: template,
    controller: IscInputButtonController,
    controllerAs: 'vm',
    bindings: {
        inputLabel: '@',
        maxlength: '<',
        onCreate: '&',
        validations: '<?',
        buttonLabel: '@',
        model: '=',
    },
    transclude: {
        icon: '?iscButtonIcon',
    },
});
