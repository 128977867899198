'use strict';

import { HomePlatformNoticeController } from './homePlatformNotice.controller';
const template = require('./homePlatformNotice.html');

export const HomePlatformNoticeComponent: ng.IComponentOptions = {
  templateUrl: template,
  controller: HomePlatformNoticeController,
  controllerAs: 'vm',
};
