'use strict';

import { DateTime } from 'luxon';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { ServerConstants } from 'src/app/core/serverconstants';

export class IscConsentController {
  static $inject = [
    'serverConstants',
    'featureservice',
  ];
  minConsentValidityDate: DateTime;
  consentValidityDate: DateTime;
  consentNeverExpires = false;
  isConsentFeatureEnabled: boolean;

  constructor(
    private serverConstants: ServerConstants,
    private featureService: FeatureService,
  ) {

    this.minConsentValidityDate = DateTime.utc().plus(({ days: 1 }));
    this.minConsentValidityDate = DateTime.local(this.minConsentValidityDate.year, this.minConsentValidityDate.month, this.minConsentValidityDate.day);

    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        this.isConsentFeatureEnabled = isEnabled;
      });
  }

  async $onInit() {

  }

  onConsentValidityDateChanged() {
    this.consentNeverExpires = this.consentValidityDate == null;
  }

  consentValidityNeverExpiresChanged() {
    this.consentValidityDate = null;
  }
}
