'use strict';

import { IUploadConversationStimulus, IUploadConversationAttachment, IUploadedItem, IProbeQuestionAnswerModel } from 'isc-ui';
import { IInDraftReply, IConversationElement } from '../../activity/activityData/conversationElementModel';
import { ForumService } from '../dataservices/forum.service';
import { ActivityService } from '../dataservices/activity.service';
import { ServerConstants } from '../serverconstants';
import * as _ from 'lodash';
import { DiscussionService } from '../dataservices/discussion.service';

export class ActivityDataService {
  static $inject = ['forumservice', 'activityservice', 'emojiservice', 'serverConstants', 'discussionService'];
  constructor(
    private forumservice: ForumService,
    private activityservice: ActivityService,
    private emojiservice: any,
    private serverConstants: ServerConstants,
    private discussionService: DiscussionService,
  ) { }

  public async sendQualReply(
    reply: IConversationElement,
    message: string,
    stimuli: IUploadConversationStimulus[],
    attachments: IUploadConversationAttachment[],
    newAttachmentFiles: File[],
    channel?: number,
    caption?: string) {
    reply.Type = this.serverConstants.conversationElementTypeConstants.reply;
    const request = await this.prepareReply(
      reply, message, stimuli, attachments, newAttachmentFiles, channel, null, caption);
    return await this.forumservice.replyToQualConversation(
      request.request, request.stimuliFiles, request.attachmentFiles);
  }

  public async saveConversation(
    conversation: IForumConversation,
    newAttachmentFiles: File[],
    probeQuestions: IProbeQuestion[] = null,
  ) {
    const dataconversation = this.prepareConversation(conversation, probeQuestions);
    const stimuliAndFiles = await this.forumservice.prepareStimuliAndNewStimuli(
      dataconversation.Stimuli);
    dataconversation.Stimuli = stimuliAndFiles.stimuli;

    const attachmentsAndFiles = await this.forumservice.prepareAttachmentsAndNewAttachments(
      dataconversation.Attachments);
    dataconversation.Attachments = attachmentsAndFiles.attachments;
    newAttachmentFiles = attachmentsAndFiles.files;

    // When trying to serialize stimuli, circular reference happens because of the videoPlayer property
    dataconversation.Stimuli.forEach((stimulus) => {
      if (stimulus.videoPlayer) {
        stimulus.videoPlayer = undefined;
      }
      if (stimulus.fileContentToBeRemoved) {
        stimulus.file = undefined;
      }
    });

    return await this.activityservice.createUpdateQualConversation(
      dataconversation, stimuliAndFiles.files, newAttachmentFiles);
  }

  public async sendQualPostUpdate(
    updatePost: IConversationElement,
    message: string,
    stimuliModel: IUploadConversationStimulus[],
    attachmentsModel: IUploadConversationAttachment[],
    newAttachmentFiles: File[],
    channel?: number,
    caption?: string) {
    updatePost.Type = this.serverConstants.conversationElementTypeConstants.topic;
    const request = await this.prepareReply(updatePost, message, stimuliModel, attachmentsModel,
      newAttachmentFiles, channel, null, caption);
    return await this.forumservice.createQualConversationUpdatePost(
      request.request, request.stimuliFiles, request.attachmentFiles);
  }


  public async saveProbeQuestionAnswerAsDraft(
    probeAnswer: IProbeQuestionAnswerModel,
    activityType: number,
    conversationFocusType: number): Promise<void> {
    if (probeAnswer.newAttachmentFiles) {
      probeAnswer.attachments = this.setFilesIds(probeAnswer.attachments, probeAnswer.newAttachmentFiles);
    }
    const stimuliAndFiles = await this.forumservice.prepareStimuliAndNewStimuli(
      probeAnswer.stimuli);
    stimuliAndFiles.stimuli.forEach((stimulus) => {
      if (stimulus.fileContentToBeRemoved) {
        stimulus.file = undefined;
      }
    });
    probeAnswer.stimuli = stimuliAndFiles.stimuli;
    probeAnswer.newStimulusFiles = stimuliAndFiles.files;

    const attachmentsAndFiles = await this.forumservice.prepareAttachmentsAndNewAttachments(
      probeAnswer.attachments);
    probeAnswer.attachments = attachmentsAndFiles.attachments;
    probeAnswer.newAttachmentFiles = attachmentsAndFiles.files;

    return await this.forumservice.saveProbeQuestionAnswerAsDraft(
      probeAnswer, probeAnswer.newStimulusFiles, probeAnswer.newAttachmentFiles, activityType, conversationFocusType);
  }

  public async saveQualReplyAsDraft(
    reply: IInDraftReply,
    newStimulusFiles: File[],
    newAttachmentFiles: File[],
    channel: any,
    parentMessageGuid: string) {
    const request = await this.prepareDraftReply(reply, reply.Message, reply.Stimuli, reply.Attachments, channel,
      parentMessageGuid, reply.Caption, newStimulusFiles, newAttachmentFiles);
    return await this.forumservice.saveQualReplyAsDraft(
      request.request, request.stimuliFiles, request.attachmentFiles);
  }

  public async saveProbeQuestionsAsDraft(
    questions: IProbeQuestion[],
    inDraftConversationGuid: string) {
    return await this.forumservice.saveProbeQuestionAsDraft(questions, inDraftConversationGuid);
  }

  private prepareConversation(
    conversation: IForumConversation,
    probeQuestions: IProbeQuestion[],
  ) {
    let newConversationStimuli: IUploadConversationStimulus[] = [];
    let newConversationAttachments: IUploadConversationAttachment[] = [];
    let removedConversationStimuliGuids: string[] = [];
    let removedConversationAttachmentGuids: string[] = [];
    const removedConverstationProbeQuestionGuids: string[] = [];

    removedConversationStimuliGuids = this.getRemovedFilesGuid(conversation.Stimuli);
    removedConversationAttachmentGuids = this.getRemovedFilesGuid(conversation.Attachments);
    newConversationStimuli = this.getNewFiles(conversation.Stimuli);
    newConversationAttachments = this.getNewFiles(conversation.Attachments);

    // TODO: maybe refactor this too. For now we focus only on stimuli and attachments.
    if (probeQuestions && probeQuestions.length > 0 && conversation.ProbeQuestions) {
      probeQuestions.forEach((probeQuestion) => {
        const index = conversation.ProbeQuestions.findIndex((convProbeQuestion) => convProbeQuestion.guid === probeQuestion.guid);

        if (probeQuestion && index < 0) {
          removedConverstationProbeQuestionGuids.push(probeQuestion.guid);
        }
      });
    }
    const dataconversation = {
      // Add query
      ActivityGuid: conversation.ActivityGuid,
      ConversationGuid: conversation.Guid,
      Title: conversation.Title,
      Message: conversation.Message,
      Caption: conversation.Caption,
      ThankYouMessage: conversation.ThankYouMessage,
      RemovedStimuli: removedConversationStimuliGuids,
      RemovedAttachment: removedConversationAttachmentGuids,
      RemovedProbeQuestions: removedConverstationProbeQuestionGuids,
      ConversationFocusType: conversation.ConversationFocusType,
      ContributionType: conversation.ContributionType,
      Stimuli: newConversationStimuli,
      Attachments: newConversationAttachments,
      ProbeQuestions: conversation.ProbeQuestions ? conversation.ProbeQuestions : probeQuestions,
      ParentMessage: conversation.ParentMessage,
    };

    return dataconversation;
  }

  private async prepareDraftReply(
    reply,
    message: string,
    stimuli: IUploadConversationStimulus[],
    attachments: IUploadConversationAttachment[],
    channel?: number,
    parentMessageGuid?: string,
    caption?: string,
    stimulusFiles?: File[],
    attachmentFiles?: File[]): Promise<IReplyQualConversationWithFilesRequest> {
    if (stimulusFiles) {
      stimuli = this.setFilesIds(stimuli, stimulusFiles);
    }
    if (attachmentFiles) {
      attachments = this.setFilesIds(attachments, attachmentFiles);
    }
    return await this.prepareReply(reply, message, stimuli, attachments,
      attachmentFiles || [], channel, parentMessageGuid, caption);
  }

  private async prepareReply(
    reply,
    message: string,
    stimuli: IUploadConversationStimulus[],
    attachments: IUploadConversationAttachment[],
    newattachments: File[],
    channel?: number,
    parentMessageGuid?: string,
    caption?: string): Promise<IReplyQualConversationWithFilesRequest> {

    const stimuliAndFiles = await this.forumservice.prepareStimuliAndNewStimuli(stimuli);

    stimuliAndFiles.stimuli.forEach((stimulus) => {
      if (stimulus.fileContentToBeRemoved) {
        stimulus.file = undefined;
      }
    });

    const attachmentsAndFiles = await this.forumservice.prepareAttachmentsAndNewAttachments(attachments);

    const request: IReplyQualConversationWithFilesRequest = {
      request: {
        // For replies with type 'topic', we fill in the replies own Guid instead of null,
        // Otherwise a new record will be added in the database for each save as draft that happens
        Guid: reply.InDraftReply ? reply.InDraftReply.Guid : reply.Type === this.serverConstants.conversationElementTypeConstants.topic ? reply.Guid : null,
        ParentMessageGuid: parentMessageGuid ? parentMessageGuid : reply.Guid,
        Message: this.emojiservice.htmlToShort(message),
        Caption: this.emojiservice.htmlToShort(caption),
        Type: reply.Type,
        ChannelType: channel ? channel : 0,
        Stimuli: stimuliAndFiles.stimuli,
        Attachments: attachmentsAndFiles.attachments,
        AnswerRequired: reply.AnswerRequired,
      },
      stimuliFiles: stimuliAndFiles.files,
      attachmentFiles: attachmentsAndFiles.files,
    };

    return request;
  }

  private getRemovedFilesGuid(files: IUploadedItem[]) {
    const removedFilesGuids: string[] = [];
    _.forEach(files, (stimulus: IUploadedItem) => {
      if (stimulus.guid &&
        stimulus.status === this.serverConstants.conversationStimuliStatusConstants.removed) {
        removedFilesGuids.push(stimulus.guid);
      }
    });
    return removedFilesGuids;
  }

  private getNewFiles(files: IUploadedItem[]): IUploadedItem[] {
    const newFiles: IUploadedItem[] = [];
    _.forEach(files, (stimulus: IUploadedItem) => {
      if (stimulus.status === this.serverConstants.conversationStimuliStatusConstants.new &&
        !stimulus.guid) {
        newFiles.push(stimulus);
      }
    });
    return newFiles;
  }

  private setFilesIds(items: IUploadedItem[], files?: File[]) {
    if (files) {
      items = items.map((item: IUploadedItem) => {
        if (item.file) {
          const stimulusIndex = files.indexOf(item.file);
          if (stimulusIndex !== -1) {
            item.id = stimulusIndex.toString();
          }
        }
        return item;
      });
    }
    return items;
  }
}

export interface IForumConversation {
  Guid: string;
  ActivityGuid?: string,
  ParentMessage?: string;
  Title?: string;
  Message: string,
  Caption?: string;
  ThankYouMessage?: string,
  Stimuli: IUploadConversationStimulus[];
  Attachments: IUploadConversationAttachment[];
  ProbeQuestions?: IProbeQuestion[];
  ConversationFocusType?: number;
  ContributionType?: number;
  IsAccepted?: boolean;
}

export interface IProbeQuestion {
  guid: string;
  isEdit: boolean;
  question: string;
  questionType: number;
}

export interface IStimulus {
  ThumbnailUrl: string;
  Id: string;
  Guid: string;
  Type: number;
  file?: File;
  type?: string;
  videoId?: string;
  Thumbnail?: string;
  Value?: string;
}

export interface IStimuliFile {
  Type: number;
  ThumbnailUrl: string;
  Value?: string;
}


export interface IReplyQualConversationRequest {
  Guid: string,
  ParentMessageGuid: string,
  Message: string,
  Caption: string,
  Type: number,
  ChannelType: number,
  Stimuli: IUploadConversationStimulus[],
  Attachments: IUploadConversationAttachment[],
  AnswerRequired: boolean,
}

interface IReplyQualConversationWithFilesRequest {
  request: IReplyQualConversationRequest;
  stimuliFiles: File[];
  attachmentFiles: File[];
}
