'use strict';

export class DefaultColors {
  defaultMainColor: '#ff8200';
  defaultExtraColors = {
    color1: '#f6a4c7',
    color2: '#85c8ca',
    color3: '#275bc7',
    color4: '#C2273D',
    color5: '#f3d558',
    color6: '#008a90',
    color7: '#e4400b',
    color8: '#82ca9c',
    color9: '#525254',
    color10: '#ebe2be',
    color11: '#c4c5c7',
  };
  defaultBackgroundColor = '#ebebeb';
}
angular
  .module('insitesApp.squareconfig.design')
  .constant('defaultColors', new DefaultColors());
