'use strict';

import { IconSelectorComponent } from './iconSelector.component';
import { SegmentSelectorComponent } from './segmentSelector.component';

import './tabComponents.module';
import { SelectIconDialogController } from './selectIconDialog.controller';
import { UniquePropertyDirective } from './unique-property.directive';

require('./pageEditor');
require('./introOrUrlEditor');

angular
  .module('insitesApp.squareconfig.tabComponents')
  .component('iconSelector', IconSelectorComponent)
  .component('segmentSelector', SegmentSelectorComponent)
  .controller('SelectIconDialogController', SelectIconDialogController)
  .directive('uniqueProperty', UniquePropertyDirective.factory());
