'use strict';

import { IntroOrUrlEditorController } from './introOrUrlEditor.controller';

const template = require('./introOrUrlEditor.html');
require('./introOrUrlEditor.scss');

export const IntroOrUrlEditorComponent: ng.IComponentOptions = {
  templateUrl: template,
  controller: IntroOrUrlEditorController,
  controllerAs: 'vm',
  bindings: {
    canEdit: '<?',
    page: '=',
    pageEditor: '<',
    index: '<',
    validationConstants: '<',
  },
};
