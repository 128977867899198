'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from '../../../core/serverconstants';
import { CommunicationService } from '../../../core/dataservices/communication.service';
import { Utils } from '../../../core/utils';
import { Pagination } from '../../../core/models/pagination';
import { SpinnerService } from '../../../core/services/spinner.service';
import * as _ from 'lodash';
import { LabelFactory } from '../../../core/label.factory';
import { JobService } from '../../../core/jobs/job.service';
import { DateFormatService } from 'src/app/core/services/dateformat.service';

export class CommunicationOverviewController {
  static $inject = [
    '$mdDialog',
    'spinnerservice',
    'logger',
    '$stateParams',
    '$state',
    'labelFactory',
    'serverConstants',
    'communicationservice',
    'jobservice',
    '$q',
    'dateFormatService',
  ];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private spinnerservice: SpinnerService,
    private logger: Logger,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private labelFactory: LabelFactory,
    private serverConstants: ServerConstants,
    private communicationservice: CommunicationService,
    private jobService: JobService,
    private $q: ng.IQService,
    private dateFormatService: DateFormatService,
  ) { }

  messages;
  filter;
  applicationLabels;
  operationTypeConstants = this.serverConstants.operationTypeConstants;
  channelTypeConstants = this.serverConstants.channelTypeConstants;
  generalCommunicationTypeConstants = this.serverConstants.generalCommunicationTypeConstants;
  communicationStatusConstants = this.serverConstants.communicationStatusConstants;
  squareGuid = this.$stateParams.squareGuid;
  clientGuid = this.$stateParams.clientGuid;
  programGuid = this.$stateParams.programGuid;
  isDownloadDisabled = false;
  pagination: IPagination = new Pagination();
  newCommunicationTitle = '';
  _communicationStatusFilter = [];
  order = '';
  tabFilter;
  selectedTab;

  tabs = {
    all: '',
    homePage: 'HomePage',
    email: 'Email',
    customAppNotification: 'customAppNotification',
  };

  async $onInit() {
    this.pagination.hide = true;
    await this.activate();
  }

  search() {
    this.goToPage(this.pagination.page);
  }

  notRemovable(channelType, startDate, status) {
    const sensitiveChannels = [this.channelTypeConstants.emailNewsletter,
      this.channelTypeConstants.researchActivityEmail];
    const isLiveSensitiveChannel = sensitiveChannels.indexOf(channelType) > -1 &&
      DateTime.fromISO(startDate) <= DateTime.now() &&
      status !== this.communicationStatusConstants.draft;
    return isLiveSensitiveChannel ||
      status === this.communicationStatusConstants.closed ||
      channelType === this.channelTypeConstants.researchActivityChallengeHome;
  }

  deleteMessage(message) {
    this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Delete message')
        .text(`Do you want to delete the message ${message.Title}?`)
        .ok('Yes')
        .cancel('No'),
    ).then(() => {
      this.spinnerservice.show('loading');
      let call;
      if (message.Guid === undefined || message.Guid === this.serverConstants.validationConstants.emptyGuid) {
        call = this.communicationservice.removeCommunication(message.CommunicationGuid);
      } else {
        call = this.communicationservice.deleteCommunicationMessage(message.Guid, message.CommunicationGuid);
      }
      call.then(() => {
        this.goToPage(this.pagination.page);
      }, (error) => {
        if (error.status === 400) {
          const data = error.data;
          let errorMessage = '';
          _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
            errorMessage += `${validationError.ErrorMessage} `;
          });
          if (errorMessage.length > 0) {
            this.logger.error(errorMessage);
          }
        }
      },
      )
        .finally(() => {
          this.spinnerservice.hide('loading');
        });
    });
  }

  isNotDeleted = (o) => {
    if (o.OperationType === undefined) {
      return true;
    }
    return o.OperationType !== this.operationTypeConstants.delete;
  };

  getStartDateTime = (channel) => {
    let result = DateTime.now();
    if (channel && channel.StartDateTime) {
      // correct compute the start date based on offset
      // and then display in local
      result = this.dateFormatService
        .getDateTime(channel.StartDateTime, channel.StartDateOffset)
        .toLocal();
    }
    return result;
  };

  getChannelTypeLabel(channel) {
    if (channel.ChannelType === this.channelTypeConstants.researchActivityEmail) {
      let emailChannelsToDisplay = Utils.getChannelTypeLabel(this.serverConstants.channelTypeConstants,
        this.serverConstants.communicationChannelConstants, channel.ChannelType);
      if (channel.HasIncomplete) {
        emailChannelsToDisplay += `, ${this.serverConstants.communicationChannelConstants.labelResearchActivityIncompleteShort}`;
      }

      if (channel.HasReminder) {
        emailChannelsToDisplay += `, ${this.serverConstants.communicationChannelConstants.labelResearchActivityReminderShort}`;
      }
      return emailChannelsToDisplay;
    }
    if (channel.ChannelType && this.applicationLabels) {
      return Utils.getChannelTypeLabel(this.serverConstants.channelTypeConstants,
        this.serverConstants.communicationChannelConstants, channel.ChannelType);
    }


    return '';
  }

  getGeneralCommunicationTypeLabel(generalType): string {
    return Utils.getGeneralCommunicationTypeLabel(this.generalCommunicationTypeConstants,
      this.serverConstants.communicationChannelConstants, generalType);
  }

  getCommunicationStatusLabel(communicationStatus): string {
    const communicationStatusName = Utils.reverseEnum(this.communicationStatusConstants, communicationStatus);
    return communicationStatusName;
  }

  redirectActivity(channel) {
    // Gets the activity for the current channel, if it's an email channel
    if (channel.ChannelType === this.channelTypeConstants.researchActivityEmail || channel.ChannelType === this.channelTypeConstants.researchActivityChallengeHome) {
      // Redirects tot the default page for this activity
      this.$state.go('root.square.activity', {
        clientGuid: this.clientGuid,
        programGuid: this.programGuid,
        squareGuid: this.squareGuid,
        activityGuid: channel.ActivityGuid,
      });
    }
  }

  createCommunication() {
    const createPromise = this.communicationservice.createCommunication(this.newCommunicationTitle)
      .then((response) => {
        this.$state.go('root.square.communicationparent.wizard', {
          clientGuid: this.clientGuid,
          programGuid: this.programGuid,
          squareGuid: this.squareGuid,
          communicationGuid: response.data.Guid,
        });
      }, (error) => {
        if (error.status === 400) {
          const data = error.data;
          let message = '';
          _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
            message += `${validationError.ErrorMessage} `;
          });
          if (message.length > 0) {
            this.logger.error(message);
          }
        }
      });
    this.spinnerservice.showFor('loading', createPromise);
  }

  wizardCommunicationEditRedirect(message) {
    if (message.ChannelType !== null) {
      if (message.ChannelType === this.channelTypeConstants.researchActivityChallengeHome) {
        this.$state.go('root.square.activityWizardType', {
          clientGuid: this.clientGuid,
          programGuid: this.programGuid,
          squareGuid: this.squareGuid,
          activityGuid: message.ActivityGuid,
          step: 'Build',
        });
      } else {
        this.$state.go('root.square.communicationparent.wizard', {
          clientGuid: this.clientGuid,
          programGuid: this.programGuid,
          squareGuid: this.squareGuid,
          communicationGuid: message.CommunicationGuid,
          researchActivityGuid: message.ActivityGuid,
          status: message.Status,
        });
      }
    } else {
      this.$state.go('root.square.communicationparent.wizard', {
        clientGuid: this.clientGuid,
        programGuid: this.programGuid,
        squareGuid: this.squareGuid,
        communicationGuid: message.CommunicationGuid,
        status: message.Status,
      });
    }
  }

  async downloadCommunicationData() {
    this.isDownloadDisabled = true;
    const jobResult = await this.communicationservice.downloadCommunicationData();
    if (jobResult && jobResult.data && jobResult.data !== '') {
      await this.jobService.showJobProgressDialog(
        jobResult.data, 'Export communication data');
    }
    this.isDownloadDisabled = false;
  }

  getFormattedTarget(message) {
    const reminderOrIncompleteArray = [this.channelTypeConstants.researchActivityReminder, this.channelTypeConstants.researchActivityIncomplete];
    const reminderOrIncomplete = reminderOrIncompleteArray.indexOf(message.ChannelType) > -1;
    if (reminderOrIncomplete) {
      return `?/${message.Targeted}`;
    }

    return message.TargetingComputedString;
  }

  hardLaunchCommunicationChannel(message) {
    this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Hard launch message')
        .text(`Are you sure you want to change to hard launch the message ${message.Title}?`)
        .ok('Yes')
        .cancel('No'),
    ).then(() => {
      this.spinnerservice.show('loading');
      this.communicationservice.hardLaunchCommunicationChannel(message.Guid).then(() => {
        message.LaunchStatus = 'Hard Launch';
        message.ShowHardLaunchButton = false;
      }).finally(() => {
        this.spinnerservice.hide('loading');
      });
    });
  }

  getMessageStartDate(message) {
    let startDate = DateTime.fromISO(message.StartDateTime);
    if (message.SendEmailDelay && message.SendEmailDelay > 0) {
      startDate = startDate.plus({ hours: message.SendEmailDelay });
    }
    return startDate;
  }

  get communicationStatusFilter() {
    return this._communicationStatusFilter;
  }

  set communicationStatusFilter(value) {
    if (value.toString() === this._communicationStatusFilter.toString()) {
      return;
    }
    this._communicationStatusFilter = value;
    this.getCommunicationMessages();
  }

  async activate() {
    this.spinnerservice.show('loading');
    this.parseParameters();
    await this.$q.all([this.getLabels(), this.getCommunicationMessages()]);
    this.spinnerservice.hide('loading');
  }

  async getCommunicationMessages() {
    const response = await this.communicationservice.getCommunicationMessages(
      this.pagination.page, this.pagination.limit,
      this.order,
      this.communicationStatusFilter, this.tabFilter, this.filter);
    if (response.data.TotalItems < (this.pagination.page - 1) * this.pagination.limit + 1 && response.data.TotalItems > 0) {
      this.goToPage(1);
    }
    this.messages = response.data.Items;

    this.pagination.total = response.data.TotalItems;
    this.pagination.hide = false;
  }

  parseParameters() {
    this.pagination.page = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
    this.pagination.limit = this.$stateParams.limit ? parseInt(this.$stateParams.limit, 10) : 40;
    this.order = this.$stateParams.order || '';
    if (this.$stateParams.statuses) {
      if (Array.isArray(this.$stateParams.statuses)) {
        this._communicationStatusFilter = this.$stateParams.statuses;
      } else {
        this._communicationStatusFilter = this.$stateParams.statuses.split();
      }
    } else {
      this._communicationStatusFilter = ['Draft', 'Scheduled', 'Active'];
    }
    this.filter = this.$stateParams.keyword ? this.$stateParams.keyword : '';
    this.tabFilter = this.$stateParams.generalType ? this.$stateParams.generalType : '';
    switch (this.tabFilter) {
      case this.tabs.homePage:
        this.selectedTab = 1;
        break;
      case this.tabs.email:
        this.selectedTab = 2;
        break;
      case this.tabs.customAppNotification:
        this.selectedTab = 3;
        break;
      default:
        this.selectedTab = 0;
        break;
    }
  }

  setLaunchStatus(message) {
    message.LaunchStatus = '';
    if (message.ChannelType !== this.channelTypeConstants.researchActivityEmail) {
      return;
    }

    message.LaunchStatus = 'Hard Launch';
    if (message.PublishOption === 2) {
      message.LaunchStatus = 'Soft Launch';
      message.ShowHardLaunchButton = true;
    }
  }

  getLabels() {
    const defaultLanguage = 'en-US';
    return this.labelFactory.getLabelsLanguage(defaultLanguage).then((labels) => {
      this.applicationLabels = labels;
    });
  }

  async filterTabBy(type: string) {
    this.tabFilter = type;
    this.goToPage(1);
  }

  onOrder = () => {
    this.goToPage(this.pagination.page);
  };

  private goToPage = (pageNumber) => {
    this.pagination.page = pageNumber;
    this.pagination.hide = true;
    this.$state.go('.', {
      page: this.pagination.page,
      limit: this.pagination.limit,
      order: this.order,
      statuses: this.communicationStatusFilter,
      generalType: this.tabFilter,
      keyword: this.filter,
      triggerDate: DateTime.utc().valueOf(), // Unique parameter to always trigger refresh
    });
  };

  onPaginate = (pageNumber) => {
    this.goToPage(pageNumber);
  };

  async toggleSticky(communicationChannel) {
    communicationChannel.IsUpdatingSticky = true;
    const result = await this.communicationservice.toggleStickyForCommunicationChannel(communicationChannel.Guid);

    for (const key in result.data) {
      const message = _.find(this.messages, (m) => m.Guid === key);
      message.StickyDate = result.data[key];
    }

    communicationChannel.IsUpdatingSticky = false;
  }

  async toggleHome(communicationChannel) {
    communicationChannel.IsUpdatingHome = true;
    const result = await this.communicationservice.toggleShowOnHomepageForCommunicationChannel(communicationChannel.Guid);

    for (const key in result.data) {
      const message = _.find(this.messages, (m) => m.Guid === key);
      message.ShowOnHomepage = result.data[key];
    }

    communicationChannel.IsUpdatingHome = false;
  }

  showStickyButton(message) {
    return message.GeneralType === this.generalCommunicationTypeConstants.homePage
      && message.ChannelType !== this.channelTypeConstants.customAppNotification;
  }

  showHomeButton(message) {
    return message.GeneralType === this.generalCommunicationTypeConstants.homePage
      && message.ChannelType !== this.channelTypeConstants.customAppNotification;
  }

  exclamationMessage(message) {
    if (message.IsRelatedToChildActivity) {
      return 'Locked by sequence';
    }

    if (message.IsActivityCompletionTargetReached) {
      return 'Disabled: Activity target reached';
    }

    return false;
  }
}
