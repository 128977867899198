'use strict';

import { SpinnerService } from '../../../core/services/spinner.service';
import { SquareService } from '../../../core/dataservices/square.service';
import { ISquareDesignDetailItem } from './designModel';

export class DesignController {
  static $inject = ['spinnerservice', 'squareservice', '$stateParams'];

  constructor(
    private spinnerservice: SpinnerService,
    private squareservice: SquareService,
    private $stateParams: ng.ui.IStateParamsService,

  ) { }
  squareDesignData: ISquareDesignDetailItem;

  async $onInit() {
    this.spinnerservice.show('loading');
    const response = await this.squareservice.selectSquareDesign(this.$stateParams.squareGuid);
    this.squareDesignData = response.data;
    this.spinnerservice.hide('loading');
  }
}
