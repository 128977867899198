'use strict';

const template = require('./menu-toggle.html');

export class MenuToggle implements ng.IDirective {
  constructor(
    private $mdUtil,
    private $animateCss,
    private $$rAF) { }

  restrict = 'E';
  templateUrl = template;
  scope = {
    route: '=',
    toggleOpenFn: '&',
    isOpenFn: '&',
  };

  link($scope, $element) {
    // Used for toggling the visibility of the accordion's content, after
    // All of the animations are completed.
    $scope.renderContent = $scope.isOpenFn();
    $scope.route.open = false;
    $scope.isOpen = () => $scope.isOpenFn();

    $scope.toggle = () => {
      $scope.toggleOpenFn();
    };

    this.$mdUtil.nextTick(() => {
      $scope.$watch(() => $scope.isOpenFn(), (open) => {
        $element[0].querySelector('div.md-button').setAttribute('aria-expanded', open ? 'true' : 'false');

        const $ul = $element.find('ul');

        if (open) {
          $scope.renderContent = true;
        }

        this.$$rAF(() => {
          const targetHeight = open ? $ul[0].scrollHeight : 0;

          this.$animateCss($ul, {
            easing: 'cubic-bezier(0.35, 0, 0.25, 1)',
            to: { height: `${targetHeight}px` },
            duration: 0.75, // Seconds
          }).start().then(() => {
            const $li = $ul[0].querySelector('li.active');

            $scope.renderContent = open;

            if (open && $li && $ul[0].scrollTop === 0) {
              const activeHeight = $li.scrollHeight;
              const activeOffset = $li.offsetTop;
              const offsetParent = $li.offsetParent;
              const parentScrollPosition = offsetParent ? offsetParent.offsetTop : 0;

              // Reduce it a bit (2 list items' height worth) so it doesn't touch the nav
              const negativeOffset = activeHeight * 2;
              const newScrollTop = activeOffset + parentScrollPosition - negativeOffset;

              this.$mdUtil.animateScrollTo(document.querySelector('.parent-nav').parentNode, newScrollTop);

              $ul[0].style.height = 'auto';
            }
          });
        });
      });
    });
  }

  static factory(): ng.IDirectiveFactory {
    const directive = ($mdUtil, $animateCss, $$rAF) => new MenuToggle($mdUtil, $animateCss, $$rAF);
    directive.$inject = ['$mdUtil', '$animateCss', '$$rAF'];
    return directive;
  }
}
