'use strict';


import routeConfig from './activityInfo.route';
import { ActivityInfoComponent } from './activityInfo.component';

angular
  .module('insitesApp.activityInfo', [])
  .component('activityInfoComponent', ActivityInfoComponent)
  .run(routeConfig);

import './activityInfo.component';
import './activityInfo.route';
