'use strict';


const communicationChannelTemplate = require('./communicationChannel.html');

import { CommunicationChannelController } from './communicationChannel.controller';
export const communicationChannel: ng.IComponentOptions = {
  transclude: true,
  templateUrl: communicationChannelTemplate,
  controller: CommunicationChannelController,
  controllerAs: 'vm',
  bindings: {
    communication: '=',
    researchActivity: '<',
    isReadOnly: '<',
  },
  require: {
    wizardStep: '^^iscWizardStep',
    wizard: '^^iscWizard',
    communicationWizard: '^^communicationWizard',
  },
};
