'use strict';

import { ActivitySequenceActionType } from './activitySequenceActionType';
import { DateFormatService } from '../../../core/services/dateformat.service';
import { ServerConstants } from '../../../core/serverconstants';

require('./activitySequenceConfirm.scss');

export class ActivitySequenceConfirmController {
  static $inject = ['actionType', 'validationResult', '$mdDialog', 'serverConstants', 'dateFormatService'];
  constructor(
    public actionType,
    public validationResult,
    private $mdDialog: ng.material.IDialogService,
    private serverConstants: ServerConstants,
    private dateFormatService: DateFormatService,
  ) {
    this.activitySequenceWarning = this.serverConstants.activitySequenceWarningsConstants;
    this.activitySequenceError = this.serverConstants.activitySequenceErrorsConstants;

    this.startDate = this.dateFormatService.getDateTime(this.validationResult.ParentStartDate, this.validationResult.ParentDateOffset);
    this.endDate = this.dateFormatService.getDateTime(this.validationResult.ParentEndDate, this.validationResult.ParentDateOffset);

    switch (actionType) {
      case ActivitySequenceActionType.Create:
        this.title = 'Create sequence';
        break;
      case ActivitySequenceActionType.Reorder:
        this.title = 'Update sequence';
        break;
      case ActivitySequenceActionType.Remove:
        this.title = 'Remove from sequence';
        break;
    }
  }
  title: string;
  activitySequenceWarning;
  activitySequenceError;

  startDate;
  endDate;

  cancel() {
    return this.$mdDialog.hide(false);
  }

  confirm() {
    return this.$mdDialog.hide(true);
  }

  hasWarnings() {
    return this.validationResult.ActivitySequenceWarning !== this.activitySequenceWarning.none;
  }

  hasWarning(warningtype: number) {
    if (!this.hasWarnings()) {
      return false;
    }

    return (this.validationResult.ActivitySequenceWarning & warningtype) === warningtype;
  }

  hasErrors() {
    return this.validationResult.ActivitySequenceError !== this.activitySequenceError.none;
  }

  hasError(errorType: number) {
    if (!this.hasErrors()) {
      return false;
    }

    return (this.validationResult.ActivitySequenceError & errorType) === errorType;
  }

  get isRemoveFromSequence(): boolean {
    return this.actionType === ActivitySequenceActionType.Remove;
  }

  get isCreateOrUpdateSequence(): boolean {
    return this.actionType === ActivitySequenceActionType.Create ||
      this.actionType === ActivitySequenceActionType.Reorder;
  }

  get isCreateSequence(): boolean {
    return this.actionType === ActivitySequenceActionType.Create;
  }

  get isUpdateSequence(): boolean {
    return this.actionType === ActivitySequenceActionType.Reorder;
  }
}
