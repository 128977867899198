'use strict';

export class EditCategoryDialogController {
  static $inject = ['$mdDialog', 'category', 'title', 'warning', 'validationConstants'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private category: string,
    private title: string,
    private warning: string,
    private validationConstants) { }

  visibility = [{
    value: true,
    label: 'Visible',
  }, {
    value: false,
    label: 'Hidden',
  }];

  submit = () => this.$mdDialog.hide(this.category);

  cancel = () => this.$mdDialog.cancel();
}
