'use strict';

import { DisplayTargetingController } from './display-targeting.controller';

const template = require('./display-targeting.html');
export const DisplayTargetingComponent: ng.IComponentOptions = {
  controller: DisplayTargetingController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    activityGuid: '<',
  },
};
