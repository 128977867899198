'use strict';

const themesTemplate = require('./themeEditor.html');
import { ThemeController } from './themeEditor.controller';

export const ThemeEditorComponent: ng.IComponentOptions = {
  controller: ThemeController,
  templateUrl: themesTemplate,
  controllerAs: 'vm',
  bindings: {
    pageThemes: '=',
  },
};
