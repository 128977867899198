'use strict';

import { OverviewPaginationController } from './isc-overview-pagination.controller';

const overviewPaginationTemplate = require('./isc-overview-pagination.html');

export const OverviewPagination: ng.IComponentOptions = {
  templateUrl: overviewPaginationTemplate,
  controller: OverviewPaginationController,
  controllerAs: 'vm',
  bindings: {
    list: '=*',
    pagination: '<',
    onPaginate: '=?',
  },
};
