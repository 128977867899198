'use strict';

import { DateTime } from 'luxon';
import { DateFormatService } from '../../core/services/dateformat.service';
import { PlatformNoticeService, IPlatformNotice } from '../../core/dataservices/platformnotice.service';
import { Logger } from '../../blocks/logger/logger';

export class HomePlatformNoticeController {

  static $inject = [
    '$state',
    '$timeout',
    'platformNoticeService',
    'dateFormatService',
    'spinnerservice',
    'projectservice',
    'logger',
  ];

  constructor(
    private $state: ng.ui.IStateService,
    private $timeout,
    private platformNoticeService: PlatformNoticeService,
    private dateFormatService: DateFormatService,
    private spinnerservice,
    private projectservice,
    private logger: Logger,
  ) {
    this.minDate = this.dateFormatService.startOfDay();
    this.offsetDate = this.minDate.offset / 60;
    this.model.startDateTime = DateTime.now().plus({ days: 8 });
    this.model.startNoticeDateTime = DateTime.now().plus({ days: 1 });
    this.model.endNoticeDateTime = DateTime.now().plus({ days: 7 });
    this.onModelChange();
  }

  form;

  async $onInit() {
    this.spinnerservice.show('loading');
    this.languages = await this.projectservice.getLanguageList();
    this.platformNoticeService.existingNotice().then(
      (response) => {
        this.initialType = response.noticeType;
        this.model.defaultTemplates = response.defaultTemplates;
        switch (this.initialType) {
          case this.platformNoticeService.platformNoticeTypeConstants.defaultMaintenance:
            this.model.endNoticeDateTime = DateTime.fromISO(response.endNoticeDateTime);
            this.model.lengthOfMaintenance = response.lengthOfMaintenance;
            this.model.startDateTime = DateTime.fromISO(response.startDateTime);
            this.model.startNoticeDateTime = DateTime.fromISO(response.startNoticeDateTime);
            break;
          case this.platformNoticeService.platformNoticeTypeConstants.customNotice:
            this.model.customMessage = response.customMessage;
            this.model.endNoticeDateTime = DateTime.fromISO(response.endNoticeDateTime);
            this.model.startNoticeDateTime = DateTime.fromISO(response.startNoticeDateTime);
            this.model.targetInSites = response.targetInSites;
            this.model.targetModerators = response.targetModerators;
            this.model.targetObservers = response.targetObservers;
        }
        this.language = 'en';
        this.model.noticeType = response.noticeType;
        this.noticeTypeChanged(this.model.noticeType);
        this.computeDefaultMessage(this.language);
      },
    ).finally(
      () => this.spinnerservice.hide('loading'),
    );
  }

  private async saveChanges() {
    this.spinnerservice.show('loading');
    this.platformNoticeService.appendNotice(this.model).then(
      (response) => {
        if (response) {
          this.cancelChanges();
          this.logger.success('Publish successful.');
        } else {
          this.logger.error('There was an error publishing platform notice.');
        }
      },
    ).finally(
      () => this.spinnerservice.hide('loading'),
    );
  }

  private noticeTypeChanged(value) {
    this.warning = null;
    switch (value) {
      case this.platformNoticeService.platformNoticeTypeConstants.none:
        if (this.initialType !== value) {
          this.warning = 'Please note that publishing will remove the configured notice';
        }
        if (!this.model.lengthOfMaintenance) {
          this.model.lengthOfMaintenance = 0.5;
        }
        if (!this.model.startDateTime) {
          this.model.startDateTime = DateTime.now().plus({ days: 8 });
        }
        if (!this.model.customMessage) {
          this.model.customMessage = '-';
        }
        break;
      case this.platformNoticeService.platformNoticeTypeConstants.defaultMaintenance:
        if (this.initialType === this.platformNoticeService.platformNoticeTypeConstants.customNotice) {
          this.warning = 'Please note that publishing will replace the configured notice';
        } else if (this.initialType === this.platformNoticeService.platformNoticeTypeConstants.none) {
          this.warning = 'Please note that publishing will enable the configured notice';
        }
        if (!this.model.customMessage) {
          this.model.customMessage = '-';
        }
        break;
      case this.platformNoticeService.platformNoticeTypeConstants.customNotice:
        if (!this.model.lengthOfMaintenance) {
          this.model.lengthOfMaintenance = 0.5;
        }
        if (!this.model.startDateTime) {
          this.model.startDateTime = DateTime.now().plus({ days: 8 });
        }
        if (this.initialType === this.platformNoticeService.platformNoticeTypeConstants.defaultMaintenance) {
          this.warning = 'Please note that publishing will replace the configured notice';
        } else if (this.initialType === this.platformNoticeService.platformNoticeTypeConstants.none) {
          this.warning = 'Please note that publishing will enable the configured notice';
        }
        if (this.model.customMessage === '-') {
          this.model.customMessage = null;
        }
        break;
    }
  }

  private cancelChanges() {
    this.$state.go('.');
  }

  private computeDefaultMessage(language) {
    this.defaultMessage = this.platformNoticeService.defaultNoticeMessage(
      language &&
      this.model.defaultTemplates ?
        this.model.defaultTemplates[language] : null,
      this.model.startDateTime, this.model.lengthOfMaintenance,
    );
  }

  private onModelChange() {
    this.$timeout(() => {
      this.validateModel();
      this.computeDefaultMessage('en');
    }, 300);
  }

  private validateModel() {
    if (this.form.theDate &&
      this.form.theNoticeStart &&
      this.form.theNoticeEnd) {
      // We have all datetimes valid, we need to do the comparing
      switch (this.model.noticeType) {
        case this.platformNoticeService.platformNoticeTypeConstants.defaultMaintenance: {
          this.form.theNoticeStart.$setValidity('minimum',
            this.model.startDateTime > this.model.startNoticeDateTime);
          this.form.theNoticeEnd.$setValidity('minimum',
            this.model.endNoticeDateTime > this.model.startNoticeDateTime);
          break;
        }
        case (this.platformNoticeService.platformNoticeTypeConstants.customNotice): {
          this.form.theNoticeEnd.$setValidity('minimum',
            this.model.endNoticeDateTime > this.model.startNoticeDateTime);
          break;
        }
      }
    }
  }

  private onLanguageChange() {
    this.$timeout(() => {
      this.computeDefaultMessage(this.language);
    }, 300);
  }

  model: IPlatformNotice = {
    noticeType: this.platformNoticeService.platformNoticeTypeConstants.none,
    startDateTime: undefined,
    lengthOfMaintenance: 0.5,
    startNoticeDateTime: undefined,
    endNoticeDateTime: undefined,
    customMessage: undefined,
    targetInSites: true,
    targetModerators: true,
    targetObservers: true,
  };

  minDate: DateTime;
  offsetDate;
  languages;
  language;
  defaultMessage;
  warning;
  initialType;

}
