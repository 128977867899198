'use strict';

export abstract class ParentActivityChangeConfirm {
  constructor(
    protected $mdDialog,
  ) {
  }

  protected async showParentActivityChangeConfirm() {
    if (this.hasChanges()) {
      return this.$mdDialog.show(
        this.$mdDialog.iscConfirm()
          .title('Parent activity change(s)')
          .text('This change will affect child activities')
          .ok('Ok')
          .cancel('Cancel'));
    }
  }

  protected abstract hasChanges(): boolean;
}
