'use strict';

import { App } from 'vue';
import { createApp, PlaceholderPage } from '@insites/vue-eco-temp-library';

export class VueToggle {
  currentlyMounted: App<Element> | undefined;

  public mount(app: App<Element>, target: string) {
    if (app === this.currentlyMounted) {
      return;
    }
    if (this.currentlyMounted) {
      this.currentlyMounted.unmount();
    }
    app.mount(target);
    this.currentlyMounted = app;
  }

  public unMount() {
    if (this.currentlyMounted) {
      this.currentlyMounted.unmount();
    }
    const placeholder = createApp(PlaceholderPage);
    placeholder.mount('#vue-placeholder');
    this.currentlyMounted = placeholder;
  }
}

angular
  .module('insitesApp.core')
  .service('VueToggle', VueToggle);

