'use strict';

import { SelectedClientFactory } from '../../core/selectedclient.factory';
import { ISelectedClientInfo, IRelatedClientInfo } from '../../core/dataservices/dataservice.contracts';
import { SpinnerService } from '../../core/services/spinner.service';
import { ClientService } from '../../core/dataservices/client.service';
import { AuthService } from '../../core/dataservices/auth.service';
import * as _ from 'lodash';

export class ClientInfoController implements ng.IOnInit {
  static $inject = ['$stateParams', '$q', 'logger', 'selectedClientFactory', 'spinnerservice', 'clientservice', '$scope', 'authService'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private $q: ng.IQService,
    private logger: Logger,
    private selectedClientFactory: SelectedClientFactory,
    private spinnerservice: SpinnerService,
    private clientservice: ClientService,
    private $scope: ng.IScope,
    private authService: AuthService,
  ) {
    this.model = {
      detail: {
        Id: 0,
        Name: '',
        Guid: '',
        VideoStorage: 1,
        Code: '',
        RelatedClients: [],
      },
      programs: {},
    };

    this.canEditRelatedClients = this.authService.isTeamLead() || this.authService.hasFullAccess() || this.authService.isDeveloper();
  }

  model: { detail: ISelectedClientInfo, programs: Record<string, unknown> };
  initial: ISelectedClientInfo;
  isSaving = false;
  guid = this.$stateParams.clientGuid;
  unrelatedClients: IRelatedClientInfo[] = [];
  // This var is used to temporarily store the selected value from the clients dropdown. We empty it again as soon as a value is picked
  tmpSelectedUnrelatedClient: IRelatedClientInfo;
  clientInfo;
  canEditRelatedClients: boolean;

  // //////////////
  $onInit(): void {
    this.spinnerservice.show('loading');
    // No need to fetch the unrelated clients when the user can't edit
    const promises = this.canEditRelatedClients ?
      [this.getClientDetails(), this.getUnrelatedClients()] :[this.getClientDetails()];
    this.$q.all(promises)
      .then(() => {
        this.logger.info('Client details loaded');

        this.$scope.$watch('vm.tmpSelectedUnrelatedClient', () => {
          this.handleClientsDropdownValuePicked();
        });
      }).finally(() => {
        this.spinnerservice.hide('loading');
        if (this.canEditRelatedClients) {
          this.setUnRelatedClientsDropdownPlaceholder();
        }
      });
  }

  updateClient = () => {
    this.isSaving = true;
    this.clientservice.updateClient(this.model.detail).then(() => {
      this.logger.success('Client successfully updated');
      this.initial = angular.copy(this.model.detail);
      if (this.clientInfo) {
        this.clientInfo.$setPristine();
      }
      this.selectedClientFactory.setClientInfo(this.model.detail);
      // Move previously related clients back to the dropdown
      const relatedClientsMarkedForDeletion = _.filter(this.model.detail.RelatedClients, (relatedClient) => relatedClient.IsMarkedForDeletion);
      this.unrelatedClients.push(...relatedClientsMarkedForDeletion);
      // Remove previously related clients from the table
      this.model.detail.RelatedClients = _.filter(this.model.detail.RelatedClients, (relatedClient) => !relatedClient.IsMarkedForDeletion);
    }, (error) => {
      if (error.status === 400) {
        const data = error.data;
        let message = '';
        _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
          message += `${validationError.ErrorMessage} `;
        });
        if (message.length > 0) {
          this.logger.error(message);
        }
      }
    }).finally(() => {
      this.isSaving = false;
    });
  };

  getClientDetails = () => this.clientservice.getClientDetails(this.$stateParams.clientGuid, true)
    .then((response) => {
      const data = {
        ...response,
        Detail: {
          ...response.Detail,
          Type: 'client',
        },
      };
      this.initial = {
        ...data.Detail,
        RelatedClients: data.Detail.RelatedClients.map((r) => ({ ...r, IsMarkedForDeletion: false, JobIds: r.JobIds || [] })) || [],
      };
      this.model.detail = angular.copy(this.initial);
      this.selectedClientFactory.setClientInfo(this.model.detail);
      return this.$q.resolve(response);
    });

  getUnrelatedClients = () => this.clientservice.getUnrelatedClients()
    .then((response) => {
      this.unrelatedClients = response.map((c) => ({ ...c, IsMarkedForDeletion: false, JobIds: c.JobIds || [] }));
    });

  rollBackForm = (form) => {
    this.model.detail = angular.copy(this.initial);
    form.$setPristine();
  };

  toggleMarkRelatedClientForDeletion = (relatedClient: IRelatedClientInfo) => {
    relatedClient.IsMarkedForDeletion = !relatedClient.IsMarkedForDeletion;
    this.clientInfo.$dirty = true;
  };

  handleClientsDropdownValuePicked() {
    if (!this.tmpSelectedUnrelatedClient) {
      return;
    }
    // Add new value to the table
    if (!_.find(this.model.detail.RelatedClients, (client) => client === this.tmpSelectedUnrelatedClient)) {
      this.tmpSelectedUnrelatedClient.IsMarkedForDeletion = false;
      this.model.detail.RelatedClients.push(this.tmpSelectedUnrelatedClient);
    }
    // Remove new value from the dropdown
    this.unrelatedClients = _.filter(this.unrelatedClients, (client) => client.Guid !== this.tmpSelectedUnrelatedClient.Guid);
    // Empty the temp value
    this.tmpSelectedUnrelatedClient = null;
  }

  saveChanges = () => this.clientservice.updateClient(this.model.detail).then(() => {
    this.logger.success('Client info saved successfully');
    this.selectedClientFactory.setClientInfo(this.model.detail);
  });

  querySearch(query: string) {
    return query ? this.createFilterFor(query) : this.unrelatedClients;
  }

  createFilterFor(query: string) {
    return _.filter(this.unrelatedClients, (item: IRelatedClientInfo) =>
      (item.Name.toLowerCase().indexOf(query.toLowerCase()) === 0) ||
      (item.AdminName && item.AdminName.toLowerCase().indexOf(query.toLowerCase()) === 0) ||
      (item.Code.toLowerCase().indexOf(query.toLowerCase()) === 0),
    );
  }

  // Because of a known issue in md-autocomplete, placeholders aren't displayed when combining them with a label for the dropdown
  // This hack makes sure we can use both the label and add a placeholder
  // https://github.com/angular/material/issues/5854
  setUnRelatedClientsDropdownPlaceholder() {
    const input = document.getElementById('related-clients-dropdown').children[0].children[0].children[1];
    input.setAttribute('placeholder', 'Search a client');
  }
}
