'use strict';

import { IscSegmentationStatusFilterController } from './isc-segmentation-status-filter.controller';

const template = require('./isc-segmentation-status-filter.html');
require('./_isc-segmentation-status-filter.scss');

export const iscSegmentationStatusFilterComponent: ng.IComponentOptions = {
  controller: IscSegmentationStatusFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    statuses: '=',
    changeSelection: '&',
  },
};
