'use strict';

import { DateTime } from 'luxon';
import _ = require('lodash');
import { IMemberOverviewFilter, IMembershipStatusItem } from '../../core/contracts/filter.contract';
import { IConstants } from '../constants';
import { ServerConstants } from '../serverconstants';
import { DateFormatService } from './dateformat.service';
import { HttpService } from '../dataservices/http.service';
import { SplashMessageDetailItem } from '../dataservices/square.service.contracts';

export class MemberService {
  static $inject = ['$stateParams', 'dateFormatService', 'serverConstants', 'constants','httpservice','__env'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private dateFormatService: DateFormatService,
    private serverConstants: ServerConstants,
    private constants: IConstants,
    private httpservice: HttpService,
    private __env: Environment,
  ) { }

  public async getNewestSplashMessage(): Promise<SplashMessageDetailItem> {
    return this.httpservice.get({
      url: '/api/SplashMessage/GetNewestSplashMessage',
    }, this.__env.memberApiUrl).then((response) => response.data);
  }

  public async saveAgreement(agreed: boolean): Promise<number> {
    return this.httpservice.post({
      url: '/api/SplashMessage/SplashAgreement',
      data: {
        agreed,
      },
    }, this.__env.memberApiUrl).then((response) => response.data);
  }

  getFilterFromQueryString(): IMemberOverviewFilter {
    let startdate = null;
    let enddate = null;

    if (this.$stateParams.startDate) {
      startdate = DateTime.fromJSDate(this.$stateParams.startDate);
      // Ensure that startdate hours and minutes is always 00:00
      startdate = this.dateFormatService.startOfDay(startdate);
    }

    if (this.$stateParams.endDate) {
      enddate = DateTime.fromJSDate(this.$stateParams.endDate);
      // Ensure that enddate hours and minutes is always 23:59
      enddate = this.dateFormatService.endOfDay(enddate);
    }

    const filter: IMemberOverviewFilter = {
      Keyword: this.$stateParams.keyword,
      Roles: this.getArrayFromQueryString(this.$stateParams.roles).map(Number),
      Statuses: this.getArrayFromQueryString(this.$stateParams.statuses).map(Number),
      Suspended: this.isSuspendedStatusSelected(this.getArrayFromQueryString(this.$stateParams.statuses)),
      Locked: this.isLockedStatusSelected(this.getArrayFromQueryString(this.$stateParams.statuses)),
      SegmentGuids: this.getArrayFromQueryString(this.$stateParams.segmentGuids),
      SegmentOptions: parseInt(this.$stateParams.segmentOptions || '0', 10),
      StartDate: startdate,
      EndDate: enddate,
      List: [],
      ExcludeParticipantsInvitedSinceWeeks: this.$stateParams.excludeParticipantsInvitedSinceWeeks
        ? parseInt(this.$stateParams.excludeParticipantsInvitedSinceWeeks, 10)
        : undefined,
    };

    if (this.isFilterEmpty(filter)) {
      return null;
    }

    return filter;
  }

  private isFilterEmpty(filter: IMemberOverviewFilter): boolean {
    return !(filter.EndDate
      || filter.Keyword
      || filter.List.length > 0
      || filter.Roles.length > 0
      || filter.SegmentGuids.length > 0
      || filter.StartDate
      || filter.Statuses.length > 0
      || filter.Suspended
      || filter.ExcludeParticipantsInvitedSinceWeeks);
  }

  private isSuspendedStatusSelected(statuses: number[]) {
    const suspended = _.findIndex(statuses, (n) => n.toString() === '7');
    return suspended !== -1;
  }

  private isLockedStatusSelected(statuses: number[]) {
    const locked = _.findIndex(statuses, (n) => n.toString() === '8');
    return locked !== -1;
  }

  private getArrayFromQueryString(data) {
    if (!data) {
      return [];
    }
    if (Array.isArray(data)) {
      return data;
    }
    return [data];

  }

  getFilterUrlParts() {
    return [
      'keyword',
      'roles',
      'statuses',
      'segmentGuids',
      'segmentOptions',
      'startDate',
      'endDate',
      'triggerDate',
      'excludeParticipantsInvitedSinceWeeks',
    ].join('?');
  }

  getStateParamsFromFilter(filter: IMemberOverviewFilter) {
    return {
      keyword: filter.Keyword,
      roles: filter.Roles,
      statuses: filter.Statuses,
      suspended: this.isSuspendedStatusSelected(filter.Statuses),
      segmentGuids: filter.SegmentGuids,
      segmentOptions: filter.SegmentOptions,
      startDate: filter.StartDate ?  filter.StartDate.toJSDate() : null,
      endDate: filter.EndDate ?  filter.EndDate.toJSDate() : null,
      excludeParticipantsInvitedSinceWeeks: filter.ExcludeParticipantsInvitedSinceWeeks,
    };
  }

  getEmptyMemberOverviewFilter(): IMemberOverviewFilter {
    return {
      Keyword: undefined,
      Roles: [],
      Statuses: [],
      Suspended: false,
      Locked: false,
      SegmentOptions: undefined,
      SegmentGuids: [],
      StartDate: null,
      EndDate: null,
      List: [],
      ExcludeParticipantsInvitedSinceWeeks: null,
    };
  }

  getMembershipStatusList(): IMembershipStatusItem[] {
    return [
      {
        DisplayLabel: 'Activated',
        Label: 'Activated',
        Value: this.serverConstants.registrationStatusConstants.active,
      },
      {
        DisplayLabel: 'Single Opt In',
        Label: 'Single Opt In',
        Value: this.serverConstants.registrationStatusConstants.singleOptIn,
      },
      {
        DisplayLabel: 'Double Opt In',
        Label: 'Double Opt In',
        Value: this.serverConstants.registrationStatusConstants.doubleOptIn,
      },
      {
        DisplayLabel: 'Unsubscribed',
        Label: 'Unsubscribed',
        Value: this.serverConstants.registrationStatusConstants.unsubscribed,
      },
      {
        DisplayLabel: 'Anonymized',
        Label: 'Anonymized',
        Value: this.serverConstants.registrationStatusConstants.anonymized,
      },
      {
        DisplayLabel: 'Suspended',
        Label: 'Suspended',
        Value: this.constants.suspendedStatus,
      },
      {
        DisplayLabel: 'Locked',
        Label: 'Locked',
        Value: this.constants.lockedStatus,
      },
    ];
  }
}
