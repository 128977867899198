'use strict';

import { ServerConstants } from '../../../../core/serverconstants';
import * as _ from 'lodash';

export class SequencedActivityInfoMessageHelper {
  constructor(
    private serverConstants: ServerConstants,
    private channelsStep,
    private messageRequiredForParentActivityToo: boolean = false) {
  }

  private get selectedActivitySequenceRole(): number {
    let role = null;
    if (this.channelsStep.selectedActivityGuid) {
      const selectedActivityOption = _.find<any>(this.channelsStep.activitiesDropdownOptions, (opt) => opt.Guid === this.channelsStep.selectedActivityGuid) || { };
      role = selectedActivityOption.SequenceRole;
    }

    return role;
  }

  get infoText(): string {
    let text = null;
    switch (this.selectedActivitySequenceRole) {
      case this.serverConstants.activitySequenceRoleConstants.child:
        text = 'Please note that this email will NOT be sent as long as the research activity is in a sequence as a child.';
        break;
      case this.serverConstants.activitySequenceRoleConstants.parent:
        if (this.messageRequiredForParentActivityToo) {
          text = 'Please note that the end date, the credits and the rewards will NOT be shown in the email as long as the research activity is in a sequence.';
        }
        break;
    }

    return text;
  }
}
