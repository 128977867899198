'use strict';

import { ParticipantService } from '../dataservices/participant.service';
import { ServerConstants } from '../../core/serverconstants';

export class UsernameValidator implements ng.IDirective {
  constructor(
    private $q: ng.IQService,
    private participantservice: ParticipantService,
    private _: _.LoDashStatic,
    private logger: Logger,
    private serverConstants: ServerConstants,
  ) { }

  restrict = 'A';
  require = 'ngModel';

  link(scope, element, attrs) {
    const inputElem = element.find('input');
    if (!inputElem) {
      this.logger.error('No child input element was found');
      return;
    }
    const ngModel = inputElem.controller('ngModel');
    if (!ngModel) {
      this.logger.error('Input element doesn\'t have a ngModel specified');
      return;
    }

    ngModel.$asyncValidators.uniqueUsername = (modelValue, viewValue) => {
      if (modelValue !== undefined
        && viewValue !== undefined
        && modelValue !== null
        && modelValue === viewValue) {
        return this.$q.resolve();
      }
      let squarePartGuid;
      if (Object.prototype.hasOwnProperty.call(attrs,'squareparticipantguid')) {
        squarePartGuid = attrs.squareparticipantguid;
      }
      const user = {
        name: encodeURIComponent(viewValue),
        squareParticipantGuid: squarePartGuid,
      };
      if (!viewValue) {
        return this.$q.resolve();
      }
      return this.participantservice.isUserUnique(user).then((response) => {
        if (response.data === false) {
          return this.$q.reject();
        }
        return this.$q.resolve();
      });
    };

    ngModel.$validators.anonymousUsername = (modelValue, viewValue) => {
      const participant: any = scope.vm && scope.vm.member ? scope.vm.member : null;
      const initialRoleParticipant: any = scope.vm && scope.vm.initial
        && scope.vm.initial.Role === this.serverConstants.roleConstants.participant;
      if (participant && participant.AnonymousSquare && (participant.FirstName || participant.LastName) && initialRoleParticipant) {
        const firstNameSplit: string = participant.FirstName ? participant.FirstName.toLowerCase().split(' ') : '';
        const lastNameSplit: string = participant.LastName ? participant.LastName.toLowerCase().split(' ') : '';
        const names: string[] = this._.unionWith(firstNameSplit, lastNameSplit, this._.isEqual);
        const found: any = this._.find(names, (name: string) => viewValue && viewValue.toLowerCase().includes(name));
        if (found !== undefined) {
          return false;
        }
      }
      return true;
    };
  }
}
