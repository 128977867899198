'use strict';

import { PinnedItemsService } from '../../core/services/pinned-items.service';
import { ServerConstants } from '../../core/serverconstants';
import { InsitesUserDetailService } from '../../core/dataservices/insitesUserDetail.service';

export class PinButtonController {
  static $inject = ['pinnedItemsService', 'logger', 'serverConstants', 'insitesUserDetailService', '$stateParams'];
  toggleInactive: boolean = false;
  commandUrl: string;
  type: number;
  guid: string;
  clientGuid: string;
  menuItem;
  get _clientGuid() {
    return this.clientGuid || this.$stateParams.clientGuid;
  }

  constructor(
    private pinnedItemsService: PinnedItemsService,
    private logger: Logger,
    private serverConstants: ServerConstants,
    private insitesUserDetailService: InsitesUserDetailService,
    private $stateParams,
  ) { }

  isPinned() {
    return this.pinnedItemsService.isPinned(this.guid);
  }

  async togglePin() {
    switch (this.type) {
      case this.serverConstants.searchTypeConstants.activity:
        this.pinnedItemsService.toggleActivityPin(this.commandUrl, this.guid, this._clientGuid);
        break;
      case this.serverConstants.searchTypeConstants.square:
      {
        if(!this.isPinned()){ // Always allow unpin. So we don't need to check access
          const hasInSitesUserAccessToSquare = await this.insitesUserDetailService.hasInSitesUserAccess(this._clientGuid, this.guid);
          if (!hasInSitesUserAccessToSquare) {
            break;
          }
        }
        this.pinnedItemsService.toggleSquarePin(this.commandUrl, this.guid, this._clientGuid);
        break;
      }
      case this.serverConstants.searchTypeConstants.client:
      {
        if(!this.isPinned()){ // Always allow unpin. So we don't need to check access
          const hasInSitesUserAccessToClient = await this.insitesUserDetailService.hasInSitesUserAccess(this.guid);
          if (!hasInSitesUserAccessToClient) {
            break;
          }
        }
        this.pinnedItemsService.toggleClientPin(this.commandUrl, this.guid);
        break;
      }
      case this.serverConstants.searchTypeConstants.program:
        this.pinnedItemsService.toggleProgramPin(this.commandUrl, this.guid, this._clientGuid);
        break;
      default:
        this.logger.error('Unknown type passed to pin-button.', this.type);
    }
  }
}
