'use strict';

angular
  .module('insitesApp.core')
  .directive('iscOnVerticalChanged', () => ({
    link: (scope, element, attrs) => {
      scope.$watch(() => `${element[0].offsetTop}:${element[0].offsetHeight}`, () => {
        const parentTop = element[0].parentElement.offsetTop;
        scope.$eval(attrs.iscOnVerticalChanged, {
          $top: element[0].offsetTop - parentTop,
          $height: element[0].offsetHeight,
        });
      });
    },
  }));
