'use strict';

require('./activityPublish.component');
require('./publishCredits');
require('./publishDate');
require('./publishBackgroundVars');
require('./publishPushedOnTop');
require('./publishRecruitmentPartner');
require('./publishRewards');
require('./publishUrl');
require('../../../../common/activitySteps/activityPublish/publishSavingUp');
