'use strict';

import * as angular from 'angular';
import './rewardHandling.module';
import routeConfig from './rewardHandling.route';
import './rewardHandling.component';
import { RewardHandlingComponent } from './rewardHandling.component';
angular
  .module('insitesApp.rewardHandling')
  .component('rewardHandling', RewardHandlingComponent)
  .run(routeConfig);
