'use strict';

import { AuthService } from 'src/app/core/dataservices/auth.service';
export class RequestACallController {
  static $inject = ['$mdDialog', 'authService', '$sce', 'canRequestACall', 'displayText',
    'activeSquareParticipants', 'connectProjectUrl', '$stateParams'];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private authService: AuthService,
    private $sce: ng.ISCEService,
    public canRequestACall: boolean,
    public displayText: string,
    public activeSquareParticipants: any[],
    public connectProjectUrl: string,
    private $stateParams: ng.ui.IStateParamsService,
  ) {
    this.activeSquareParticipantGuids = Object.keys(activeSquareParticipants);
  }

  activeSquareParticipantGuids: string[];

  get squareGuid() {
    return this.$stateParams.squareGuid;
  }

  get token() {
    return this.authService.getToken();
  }

  trustUrl(url: string) {
    return this.$sce.trustAsResourceUrl(url);
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  private static createHiddenInput = (name: string, value: string): HTMLInputElement => {
    const result = document.createElement('input');
    result.hidden = true;
    result.name = name;
    result.value = value;
    return result;
  };

  public static submitRequestACallForm = (
    action: string,
    token: string,
    participant_guids: string[],
    squareGuid: string,
  ) => {
    const form = document.createElement('form');
    form.target = '_blank';
    form.method = 'POST';
    form.action = action;
    const hiddenToken = this.createHiddenInput('auth_token', token);
    const hiddenParticipants = this.createHiddenInput('participant_guids', JSON.stringify(participant_guids));
    const hiddenSquare = this.createHiddenInput('square_guid', squareGuid);
    form.appendChild(hiddenToken);
    form.appendChild(hiddenParticipants);
    form.appendChild(hiddenSquare);
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  };

}
