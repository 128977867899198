'use strict';

import { ITimeoutService } from 'angular';
import { SpinnerService } from './services/spinner.service';

export class SpinnerController implements ng.IOnInit {
  static $inject = ['$scope', 'spinnerservice', '$timeout'];
  constructor(
    private $scope: ng.IScope,
    private spinnerservice: SpinnerService,
    private $timeout: ITimeoutService,
  ) { }

  register;
  name;
  group;
  show;
  onShow;
  onHide;
  onLoaded;

  $onInit() {
    // Register should be true by default if not specified.
    if (!this.register) {
      this.register = true;
    } else {
      this.register = this.register.toLowerCase() === 'true';
    }

    // Declare a mini-API to hand off to our service so the service
    // Doesn't have a direct reference to this directive's scope.
    const api = {
      name: this.name,
      group: this.group,
      show: () => {
        this.$timeout(() => {
          this.show = true;
        });
      },
      hide: () => {
        this.$timeout(() => {
          this.show = false;
        });
      },
      toggle: () => {
        this.$timeout(() => {
          this.show = !this.show;
        });
      },
    };

    // Register this spinner with the spinner service.
    if (this.register === true) {
      this.spinnerservice._register(api);
    }

    // If an onShow or onHide expression was provided, register a watcher
    // That will fire the relevant expression when show's value changes.
    if (this.onShow || this.onHide) {
      this.$scope.$watch('show',
        function(show) {
          if (show && this.onShow) {
            this.onShow({ spinnerservice: this.spinnerservice, spinnerApi: api });
          } else if (!show && this.onHide) {
            this.onHide({ spinnerservice: this.spinnerservice, spinnerApi: api });
          }
        });
    }

    // This spinner is good to go. Fire the onLoaded expression.
    if (this.onLoaded) {
      this.onLoaded({ spinnerservice: this.spinnerservice, spinnerApi: api });
    }
  }
}

angular
  .module('insitesApp.core')
  .component('iscSpinnerComponent', {
    template: '<div class="spinnerContent" ng-if="vm.show">' +
    '<img ng-if="vm.imgSrc" ng-src="{{vm.imgSrc}}" />' +
    '<ng-transclude></ng-transclude>' +
    '</div>',
    transclude: true,
    controller: SpinnerController,
    controllerAs: 'vm',
    bindings: {
      name: '@?',
      group: '@?',
      show: '=?',
      imgSrc: '@?',
      register: '@?',
      onLoaded: '&?',
      onShow: '&?',
      onHide: '&?',
    },
  });
