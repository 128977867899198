'use strict';

const template = require('./communicationOverview.html');
import { CommunicationOverviewController } from './communicationOverview.controller';
require('./_communicationOverview.scss');

angular
  .module('insitesApp.squarecommunication')
  .component('messageOverview', {
    templateUrl: template,
    controller: CommunicationOverviewController,
    controllerAs: 'vm',
  });
