'use strict';

angular.module('insitesApp.squarerewards')
  .filter('rewardStatusToClass',
    () => {
      const map = {
        active: 'status-active',
        scheduled: 'status-scheduled',
        closed: 'status-closed',
      };

      return (value) => map[value.toLowerCase()];
    });
