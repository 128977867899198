'use strict';

import { ActivityCredits } from './activityCredits.controller';

const template = require('./activityCredits.html');

require('./_activityCredits.scss');

export const ActivityCreditsComponent: ng.IComponentOptions = {
  controller: ActivityCredits,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    allowCreditsEdit: '=',
    isSavingUpReward: '<',
    validationConstants: '<',
    squareActivity: '<',
  },
  require: {
    form: '^^form',
  },
};
