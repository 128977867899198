'use strict';

import { ServerConstants } from '../../../core/serverconstants';
import { SquareService } from '../../../core/dataservices/square.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import * as _ from 'lodash';

export class SquareConfigSplashController {
  static $inject = ['squareservice', 'logger', 'serverConstants', 'spinnerservice', '$mdDialog', '$stateParams'];
  constructor(
    private squareservice: SquareService,
    private logger: Logger,
    private serverConstants: ServerConstants,
    private spinnerservice: SpinnerService,
    private $mdDialog: ng.material.IDialogService,
    private $stateParams: ng.ui.IStateParamsService,
  ) {
    this.pageTypeConstants = serverConstants.pageTypeConstants;
    this.area = serverConstants.areaConstants.splash;
    this.pageTypeDetails = [
      {
        Label: 'Splash page',
        syncService: squareservice.syncPage('MySplash'),
        required: true,
        onlyOneAllowed: true,
      }];
    this.IsEnabled = false;
    this.isSaving = false;
  }
  form;
  pageTypeConstants;
  area;
  pageTypeDetails;
  IsEnabled;
  splashData;
  isSaving;
  originalMessage: string;
  firstMessage: boolean = false;
  // Message;
  public async $onInit() {
    await this.reloadSplashData();
  }

  private async reloadSplashData() {
    try {
      this.logger.info('Square config - Splash Editor activated');
      this.spinnerservice.show('loading');
      await this.getSplashData();
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  private async getSplashData() {
    let isSquareModule = window.location.href.includes('square');
    if (isSquareModule && this.$stateParams.mainSplash) {
      isSquareModule = false;
      delete this.$stateParams.mainSplash;
    }
    try {
      if (isSquareModule) {
        this.splashData = await this.squareservice.getSquareSplashData();
        if (this.splashData && this.splashData.Message) {
          this.originalMessage = this.splashData.Message;
        }
      } else {
        this.splashData = await this.squareservice.getSplashData();

        if (!this.splashData) {
          this.firstMessage = true;
          this.splashData = null;
        }
      }

    } catch (error) {
      if (error.status === 400) {
        const data = error.data;

        // Group by property name in case there is more than 1 error for that property
        // Ideally we should already group them in the backend
        const grouped = _.groupBy(data.ValidationErrors, 'PropertyName');
        _.forEach(grouped, (item, key) => {
          if (this.form[key]) {
            this.form[key].$setValidity('serverErrors', false);
          } else {
            this.form.pageColors[key].$setValidity('serverErrors', false);
          }
        });
      }
    }
  }

  public async savePage(newVersion) {
    const save = (nv) => {
      this.isSaving = true;
      this.splashData.NewVersion = nv;
      const isSquareModule = window.location.href.includes('square');
      this.squareservice.saveSquareSplashData(this.splashData, !isSquareModule, this.firstMessage)
        .then(async (result) => {
          this.form.$setPristine();
          this.logger.success('Splash page saved successfully');
          this.isSaving = false;
          if (result) {
            this.splashData.id = result;
          }
          await this.reloadSplashData();
        })
        .catch(() => {
          this.logger.error('Splash page wasn\'t saved');
          this.isSaving = false;
        });

      this.firstMessage = false;
    };

    if (this.form.$valid) {
      if (newVersion) {
        this.$mdDialog.show(
          this.$mdDialog.iscConfirm()
            .title('Are you sure you want to save?')
            .text('Changes are visible to all members, including members who have already agreed upon a previous version')
            .ok('Yes')
            .cancel('No'),
        ).then(() => save(newVersion));
      } else {
        this.$mdDialog.show(
          this.$mdDialog.iscConfirm()
            .title('Are you sure you want to save?')
            .text('Changes are only visible to members who haven’t yet agreed upon this version')
            .ok('Yes')
            .cancel('No'),
        ).then(() => save(newVersion));
      }
    } else {
      const errorsByType = this.form.$error;
      this.logger.warning('Not saving because of validation errors.', errorsByType);
    }

  }

  cancelPage() {
    this.getSplashData();
    this.form.$setPristine();
  }
}
