'use strict';

import { MappingService } from '../../../core/services/mapping.service';
import { IProbeQuestionEditorModel, IUploadConversationStimulus, IscUIUtils } from 'isc-ui';
import { AuthService } from '../../../core/dataservices/auth.service';
import { DateFormatService } from '../../../core/services/dateformat.service';
import { SelectedSquareFactory } from '../../../core/selectedsquare.factory';
import { ForumService } from '../../../core/dataservices/forum.service';
import { IActivityFilterAdditionalData, IActivityFilterData } from '../../../core/contracts/activity.contract';
import { ActivityFilterService } from '../../../core/services/activityFilter.service';
import { ConversationService } from '../../../core/services/conversation.service';
import { Utils } from '../../../core/utils';
import { ConversationEventsService } from '../../../core/services/conversationEvents.service';
import { NotificationsService } from '../../../core/services/notifications';
import { CurrentUserService } from '../../../core/dataservices/currentUser.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { ConstantsFactory } from '../../../core/constants.factory';
import { Logger } from '../../../blocks/logger/logger';
import { ServerConstants } from '../../../core/serverconstants';
import { IConversationElement, IProbeQuestionConversationElementItem, ISquareParticipantGuidAnswerSetPair } from './../conversationElementModel';
import * as _ from 'lodash';
import { SnippetHighlighterService } from '../snippet-highlighter/snippet-highlighter.service';
import { IConversationAttachment, IConversationStimuli } from '../../activityQualResearchConfig/squareActivityModel';
import { MuxService } from '../../../core/dataservices/mux.service';
import { IStimuliUploaded, IAttachmentUploaded } from '../../../core/services/notifications.contracts';
import { FileStorageService } from '../../../core/dataservices/fileStorage.service';
import { DateTime } from 'luxon';
import { DiscussionService } from '../../../core/dataservices/discussion.service';
import { IDiscussionActivityRequest } from '../../../core/contracts/discussion.contract';
import { FeatureService } from 'src/app/core/dataservices/feature.service';

export class ActivityDataConversationsController {
  static $inject = ['$scope', '$stateParams', '$timeout',
    'forumservice', '$state', 'spinnerservice',
    'constantsfactory', 'serverConstants', 'activityFilterService',
    'selectedSquareFactory', 'dateFormatService',
    'logger', 'conversationEventsService', 'notifications', 'currentUserService', 'conversationService',
    'snippetHighlighterService', '$q', 'authService', 'mappingService', 'muxService', 'fileStorageService',
    'discussionService', 'featureservice',
  ];

  constructor(
    private $scope: ng.IScope,
    private $stateParams: ng.ui.IStateParamsService,
    private $timeout: ng.ITimeoutService,
    private forumservice: ForumService,
    private $state: ng.ui.IStateService,
    private spinnerservice: SpinnerService,
    private constantsfactory: ConstantsFactory,
    private serverConstants: ServerConstants,
    private activityFilterService: ActivityFilterService,
    private selectedSquareFactory: SelectedSquareFactory,
    private dateFormatService: DateFormatService,
    private logger: Logger,
    private conversationEventsService: ConversationEventsService,
    private notifications: NotificationsService,
    private currentUserService: CurrentUserService,
    private conversationService: ConversationService,
    private snippetHighlighterService: SnippetHighlighterService,
    private $q: ng.IQService,
    private authService: AuthService,
    private mappingService: MappingService,
    private muxService: MuxService,
    private fileStorageService: FileStorageService,
    private discussionService: DiscussionService,
    private featureService: FeatureService,
  ) {
    this.sortOptions = serverConstants.conversationSortOptionConstants;
  }

  readonly ITEMS_PER_PAGE = 25;
  unsubscribeDiscussionChange = _.noop;
  unsubscribeShowVideoThumbnail = _.noop;
  unsubscribeShowPhotoThumbnail = _.noop;
  unsubscribeUpdateAttachmentUrl = _.noop;
  latestUpdatePageIndex = 1;
  currentPage: number;
  conversationElements: IConversationElement[];
  probeQuestions: IProbeQuestionEditorModel[];
  totalPages: number;
  filterDataCounters;
  postUpdateRedirectText: string;
  qualFilter: IActivityFilterData;
  filterAdditionalData: IActivityFilterAdditionalData;
  activityType: number;
  activityStartDate;
  minStartDate: DateTime;
  maxEndDate: DateTime;
  sortBy: number;
  sortOptions;
  onTopNote = '';
  onTopTag = '';
  isDownloadDisabled = false;
  isFilteredOnNotes = false;
  isFilteredOnTags = false;
  isFilteredOnHashtags = false;
  expandLatestUpdatePost = true;
  noUnreadConversationsFeatureEnabled = false;
  expandedPosts: string[] = [];
  activityGuid: string;
  title: string;
  postsThatMatchFilterCount = 0;
  loading = false;
  subscriptions = [];
  allTopicQuestionsAnswered: boolean;
  isDividedDiscussionType: boolean;
  conversationFocusType;
  contributionType?: number;
  isScoutDiscussionType: boolean;
  isModeratorCurationEnabled: boolean;
  visibilityBufferPeriodPassed: boolean;
  topicWithProbeQuestions: IConversationElement[] = [];
  activityEndDatePassed: boolean;
  activityStatus: number;
  readConversations: IConversationElement[] = [];
  conversationsRead = undefined;
  isGroupByMemberEnabled = false;
  isGroupedByMember = false;
  isReloadRequired = false;

  isDiscussionChanged: boolean = false;
  timer:ReturnType<typeof setInterval>;

  get uniqueElements() {
    return _.uniqBy(this.conversationElements, (element) => element.Guid);
  }

  async $onInit() {
    this.loading = true;
    this.conversationEventsService.dataConversationsLoadingChange.next(this.loading);

    // Refreshtimer (only for moderators and observers)
    if(this.currentUserService.role === this.serverConstants.roleConstants.human8 ||
      this.currentUserService.role === this.serverConstants.roleConstants.clientAdmin ||
      this.currentUserService.role === this.serverConstants.roleConstants.clientEditor ||
      this.currentUserService.role === this.serverConstants.roleConstants.professionalAdmin ||
      this.currentUserService.role === this.serverConstants.roleConstants.observer) {
      this.timer = setInterval(() => {
        this.automaticRefresh();
      }, 300000); // 5 minutes (5 * 60 * 1000)
    }

    this.noUnreadConversationsFeatureEnabled = await this.featureService
      .isFeatureEnabledForSquare(this.serverConstants.featureConstants.noUnreadConversations);

    this.subscriptions.push(
      this.conversationEventsService.dataConversationsFilterApply.subscribe((filter) => {
        this.qualFilter = filter;
        this.loadFilteredReplies();
      }));

    // We only want to refresh this data when changing impersonate on an activity page, not any of the other cases
    const currentSquare = this.selectedSquareFactory.Guid;
    this.subscriptions.push(this.currentUserService.impersonatedUserChange.subscribe(async (impersonatedUser) => {
      if (currentSquare === this.selectedSquareFactory.Guid) {
        await this.loadConversations(null, null, impersonatedUser ? impersonatedUser.SquareParticipantGuid : null);
      }
    }));

    // we jump to first unread only if the feature is not enabled
    if (this.noUnreadConversationsFeatureEnabled !== true && this.$stateParams.jumpToFirstNew) {
      await this.setInitialFilterData();
      await this.conversationService.jumpToFirstNewReplyQual(this.activityGuid, this.currentPage, this.ITEMS_PER_PAGE,
        this.qualFilter, this.sortBy, this.expandedPosts, this.expandLatestUpdatePost);
      return;
    }

    try {
      await this.init();
    } catch (e) {
      this.logger.error('Failed to init activity data component.', e);
    }

    const throttledLoadConversations = _.throttle(async () => {
      await this.loadConversations(true);
    }, 10000);
    // Participants will never have a automatic refresh
    // If the user is a moderator or observer the automatic refresh only happens when the timer is finished
    // for all the others the automatic refresh happens instantly
    if(this.currentUserService.role !== this.serverConstants.roleConstants.participant) {
      this.unsubscribeDiscussionChange = await this.conversationEventsService.discussionChange.subscribe(
        this.activityGuid,
        async () => {
          this.isDiscussionChanged = true;

          if(this.currentUserService.role !== this.serverConstants.roleConstants.human8 &&
             this.currentUserService.role !== this.serverConstants.roleConstants.clientAdmin &&
             this.currentUserService.role !== this.serverConstants.roleConstants.clientEditor &&
             this.currentUserService.role !== this.serverConstants.roleConstants.professionalAdmin &&
             this.currentUserService.role !== this.serverConstants.roleConstants.observer) {
            await throttledLoadConversations();
          }
        });
    }

    const _this = this;
    this.unsubscribeShowVideoThumbnail = this.notifications.showVideoThumbnail.subscribe((stimuli: IStimuliUploaded) =>
      _this.updateStimuliUrlAndThumbnailUrl(_this.serverConstants.conversationStimuliTypeConstants.video, stimuli));

    this.unsubscribeShowPhotoThumbnail = this.notifications.showPhotoThumbnail.subscribe((stimuli: IStimuliUploaded) =>
      _this.updateStimuliUrlAndThumbnailUrl(_this.serverConstants.conversationStimuliTypeConstants.image, stimuli));

    this.unsubscribeUpdateAttachmentUrl = this.notifications.updateAttachmentUrl.subscribe((attachment: IAttachmentUploaded) =>
      _this.updateAttachmentUrl(attachment));

    this.loading = false;
    this.conversationEventsService.dataConversationsLoadingChange.next(this.loading);
  }

  private async automaticRefresh() {
    if(!this.isDiscussionChanged) {
      return;
    }
    // Refresh (Don’t await for this because then the request will take to long since SetInterval executes every second.)
    this.loadConversations(true);
    this.isDiscussionChanged = false;
  }

  updateStimuliUrlAndThumbnailUrl(stimuliType: number, stimuliUploaded: IStimuliUploaded) {
    _.forEach(this.conversationElements, (item: IConversationElement) => {
      _.forEach(item.Stimuli, (stimulus: IConversationStimuli) => {
        if (stimulus.Guid === stimuliUploaded.Guid) {
          stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
          if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
            stimulus.Value = stimuliUploaded.Url;
            this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
          } else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
            stimulus.Url = stimuliUploaded.Url;
            this.muxService.removeStimulusFromUploadingList(stimulus.Value);
          }
          return false; // Break if the video was found
        }
      });
      if (item.ProbeQuestions) {
        _.forEach(item.ProbeQuestions, (probeQuestion: IProbeQuestionConversationElementItem) => {
          if (probeQuestion.Stimuli && probeQuestion.Stimuli.length) {
            _.forEach(probeQuestion.Stimuli, (stimulus: IConversationStimuli) => {
              if (stimulus.Guid === stimuliUploaded.Guid) {
                stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                  stimulus.Value = stimuliUploaded.Url;
                  this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
                } else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                  stimulus.Url = stimuliUploaded.Url;
                  this.muxService.removeStimulusFromUploadingList(stimulus.Value);
                }
                return false; // Break if the video was found
              }
            });
          }
          if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
            _.forEach(probeQuestion.Answer.Stimuli, (stimulus: IConversationStimuli) => {
              if (stimulus.Guid === stimuliUploaded.Guid) {
                stimulus.ThumbnailUrl = stimuliUploaded.ThumbnailUrl;
                if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                  stimulus.Value = stimuliUploaded.Url;
                  this.fileStorageService.removeStimulusFromUploadingList(stimulus.Guid);
                } else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                  stimulus.Url = stimuliUploaded.Url;
                  this.muxService.removeStimulusFromUploadingList(stimulus.Value);
                }
                return false; // Break if the video was found
              }
            });
          }
          // This is not happening?!?!?!
          // The damn component which is supposed to have ProbeQuestions bindings because its NOT for editor because its ALREADY ANSWERED
          // Is using the ProbeQuestionsForEditor member, so the damn IF from above could be totally useless (marvelous)
          if (item.ProbeQuestionsForEditor) {
            _.forEach(item.ProbeQuestionsForEditor, (question: IProbeQuestionEditorModel) => {
              if (question.answer && question.answer.stimuli && question.answer.stimuli.length) {
                _.forEach(question.answer.stimuli, (stimulus: IUploadConversationStimulus) => {
                  if (stimulus.guid === stimuliUploaded.Guid) {
                    stimulus.thumbnailUrl = stimuliUploaded.ThumbnailUrl;
                    if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.image) {
                      stimulus.value = stimuliUploaded.Url;
                      this.fileStorageService.removeStimulusFromUploadingList(stimulus.guid);
                    } else if (stimuliType === this.serverConstants.conversationStimuliTypeConstants.video) {
                      stimulus.url = stimuliUploaded.Url;
                      this.muxService.removeStimulusFromUploadingList(stimulus.value);
                    }
                    return false; // Break if the video was found
                  }
                });
              }
            });
          }
        });
      }
    });
    this.$scope.$broadcast(`broken_stimulus_${stimuliUploaded.Guid}`, { url: stimuliUploaded.Url, thumbnail: stimuliUploaded.ThumbnailUrl });
  }

  updateAttachmentUrl(attachmentUploaded: IAttachmentUploaded) {
    _.forEach(this.conversationElements, (item: IConversationElement) => {
      _.forEach(item.Attachments, (attachment: IConversationAttachment) => {
        if (attachment.Guid === attachmentUploaded.Guid) {
          attachment.Url = attachmentUploaded.Url;
        }
        return false; // Break if the attachment was found
      });
      if (item.ProbeQuestions) {
        _.forEach(item.ProbeQuestions, (probeQuestion: IProbeQuestionConversationElementItem) => {
          if (probeQuestion.Attachments && probeQuestion.Attachments.length) {
            _.forEach(probeQuestion.Attachments, (attachment: IConversationAttachment) => {
              if (attachment.Guid === attachmentUploaded.Guid) {
                attachment.Url = attachmentUploaded.Url;
                return false; // Break if the attachment was found
              }
            });
          }
          if (probeQuestion.Answer && probeQuestion.Answer.Stimuli && probeQuestion.Answer.Stimuli.length) {
            _.forEach(probeQuestion.Answer.Attachments, (attachment: IConversationAttachment) => {
              if (attachment.Guid === attachmentUploaded.Guid) {
                attachment.Url = attachmentUploaded.Url;
                return false; // Break if the attachment was found
              }
            });
          }
        });
      }
      if (item.ProbeQuestionsForEditor) {
        _.forEach(item.ProbeQuestionsForEditor, (probeQuestion: IProbeQuestionEditorModel) => {
          if (probeQuestion.answer && probeQuestion.answer.attachments && probeQuestion.answer.attachments.length) {
            _.forEach(probeQuestion.answer.attachments, (attachment) => {
              if (attachment.guid === attachmentUploaded.Guid) {
                attachment.url = attachmentUploaded.Url;
                return false; // Break if the video was found
              }
            });
          }
        });
      }
    });
  }

  $onDestroy() {
    this.unsubscribeDiscussionChange();
    this.unsubscribeShowVideoThumbnail();
    this.unsubscribeShowPhotoThumbnail();
    this.unsubscribeUpdateAttachmentUrl();

    clearInterval(this.timer);

    this.subscriptions.map((sub) => sub.unsubscribe());
    this.snippetHighlighterService.destroy();
  }

  private async init() {
    await this.setInitialFilterData();

    this.$scope.$on('qualPostChanged', async (event, newReplyGuid, parentMessageGuid) => {
      if (parentMessageGuid && this.expandedPosts.indexOf(parentMessageGuid) === -1) {
        this.expandedPosts.push(parentMessageGuid);
      }

      await this.loadConversations(false, newReplyGuid);
      this.scrollReply(newReplyGuid);
    });

    // when a conversation guid is passed in the url as an anchor link we scroll to the anchor guid.
    const scrollToAnchorGuid = window.location.hash.substring(1).toLowerCase();
    if (scrollToAnchorGuid) {
      await this.conversationService.scrollToAnchorGuid(scrollToAnchorGuid);
    } else {
      this.scrollReply(this.$stateParams.replyGuid);
    }
  }

  scrollReply(replyGuid) {
    if (replyGuid) {
      Utils.anchorScrollWithWait(replyGuid);
    }
  }

  async setInitialFilterData() {
    this.activityGuid = this.$stateParams.activityGuid;

    let defaultSortOption = this.serverConstants.conversationSortOptionConstants.chronological;
    if (this.$stateParams.activityType) {
      this.isScoutDiscussionType = IscUIUtils.isActivityScoutDiscussionType(this.$stateParams.activityType, this.serverConstants);
    }
    if (this.isScoutDiscussionType) {
      defaultSortOption = this.serverConstants.conversationSortOptionConstants.mostRecent;
    }
    this.currentPage = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
    if (this.$stateParams.expandedPosts !== undefined) {
      this.expandedPosts = _.isArray(this.$stateParams.expandedPosts) ? this.$stateParams.expandedPosts : [this.$stateParams.expandedPosts];
      this.expandLatestUpdatePost = false;
    }
    this.sortBy = this.$stateParams.sort === undefined ? defaultSortOption : parseInt(this.$stateParams.sort, 10);
    this.isGroupedByMember = this.$stateParams.isGroupedByMember === undefined ? false : JSON.parse(this.$stateParams.isGroupedByMember);
    this.minStartDate = await this.selectedSquareFactory.squareCreateDatePromise;
    this.minStartDate = this.dateFormatService.startOfDay(this.minStartDate);
    this.maxEndDate = DateTime.now();
    this.maxEndDate = this.dateFormatService.endOfDay(this.maxEndDate);

    this.qualFilter = this.activityFilterService.getFilterFromQueryString(this.minStartDate, this.maxEndDate,
      this.serverConstants.squareActivityTypeConstants.qualitativeResearch) as IActivityFilterData;
    this.filterAdditionalData = this.activityFilterService.createQualFilterAdditional(
      this.minStartDate, this.maxEndDate, this.isModeratorCurationEnabled);

    this.conversationEventsService.qualFilterChange.next(this.qualFilter);
    this.conversationEventsService.filterAdditionalDataChange.next(this.filterAdditionalData);

    if (this.$stateParams.isReloadRequired) {
      await this.loadFilteredReplies();
      this.$stateParams.isReloadRequired = false;
    }
  }

  async loadPosts(newReplyGuid?: string, impersonatedUserGuid?: string) {
    if (!this.activityGuid) {
      return;
    }

    let response: any;
    let activityType: any = this.$stateParams.activityType;
    if (activityType) {
      activityType = parseInt(activityType.toString(), 10);
    }

    if (IscUIUtils.isActivityDiscussionNewType(activityType, this.serverConstants)) {
      const request: IDiscussionActivityRequest = { activityGuid: this.activityGuid };
      response = await this.discussionService.getDiscussionActivity(request);
      // Map discussion to Conversation
    } else {
      try {
        response = await this.forumservice.getQualConversationElementsForModeration(this.activityGuid, this.currentPage,
          this.ITEMS_PER_PAGE, this.qualFilter, this.sortBy, this.expandedPosts, this.expandLatestUpdatePost, newReplyGuid,
          impersonatedUserGuid, this.isGroupedByMember);
      } catch(ex) {
        // if the activity is not correct => show 404 page
        this.$state.go('root.404', {},  { location: false });
      }
    }

    if (response.ConversationsThatMatchFilterCount != null) {
      const conversationElements = response.ConversationElements;
      this.filterDataCounters = response.FilterDataCounters;
      this.topicWithProbeQuestions = response.TopicWithProbeQuestions;
      this.conversationEventsService.filterDataCountersChange.next(this.filterDataCounters);
      this.latestUpdatePageIndex = response.LatestUpdatePageIndex;
      this.activityEndDatePassed = response.ActivityEndDatePassed;
      this.activityStatus = response.ActivityStatus;
      if (this.expandLatestUpdatePost) {
        this.expandedPosts = [response.LatestUpdateGuid];
        this.expandLatestUpdatePost = false;
      }

      const userGuid = this.authService.SquareParticipantGuid;
      conversationElements.Items.forEach((item) => {
        if (item.Stimuli.length > 0) {
          item.Stimuli.forEach((stimulus) => {
            if (stimulus.IsBroken === true) {
              stimulus.IsBrokenAndBelongsToCurrentUser = item.SquareParticipantGuid === userGuid;
            }
          });
        }
      });

      this.conversationElements = conversationElements.Items;
      this.isDividedDiscussionType = response.IsDividedDiscussionType;
      this.contributionType = response.ContributionType;
      this.conversationFocusType = response.ConversationFocusType;
      this.activityType = response.ActivityType;
      this.isModeratorCurationEnabled = response.ModeratorCuration;
      this.visibilityBufferPeriodPassed = response.VisibilityBufferPeriodPassed;

      this.isGroupByMemberEnabled = this.contributionType === this.serverConstants.activityContributionTypeConstants.diary;

      if (this.isDividedDiscussionType || this.isScoutDiscussionType) {
        this.initProbeQuestionDiscussions();
      }
      this.addInDraftRepliesToPosts();
      this.bindClickEventToHashtags();

      this.postsThatMatchFilterCount = response.ConversationsThatMatchFilterCount;
      this.title = response.ActivityTitle;
      this.activityStartDate = response.ActivityStartDate;
      this.totalPages = conversationElements.TotalPages;
      this.isFilteredOnNotes = this.qualFilter.SelectedAnnotationsOptions.some((option) => option === this.serverConstants.annotationFilterOptionConstants.notes);
      this.isFilteredOnTags = this.qualFilter.TagsSelected.length > 0;
      this.isFilteredOnHashtags = this.qualFilter.HashtagsSelected.length > 0;
      this.setDisplayLevel();

      // We need to set isModeratorCurationEnabled for the filter additional data object.
      // At the moment, It is used to determine if the Moderated(accepted) option is shown in the Moderation Status filter
      this.filterAdditionalData = this.activityFilterService
        .createQualFilterAdditional(this.filterAdditionalData.MinStartDate, this.filterAdditionalData.MaxEndDate, this.isModeratorCurationEnabled);
      this.conversationEventsService.filterAdditionalDataChange.next(this.filterAdditionalData);
      this.conversationEventsService.discussionActivityTypeChange.next({
        activityType: this.activityType,
        contributionType: this.contributionType,
        visibility: response.Visibility,
      });
    }
  }

  private bindClickEventToHashtags() {
    const regex = new RegExp(this.serverConstants.validationConstants.hashtagRegex, 'g');

    // Find every hashtag in every message, caption or probing question answer and wrap it into a span with the hashtag class
    for (const convElement of this.conversationElements) {
      convElement.Message = this.formatHashtagHtmlClass(convElement.Message, regex);
      convElement.Caption = this.formatHashtagHtmlClass(convElement.Caption, regex);

      if (convElement.ProbeQuestionsForEditor && convElement.ProbeQuestionsForEditor.length) {
        for (const pq of convElement.ProbeQuestionsForEditor) {
          if (pq.answer) {
            pq.answer.message = this.formatHashtagHtmlClass(pq.answer.message, regex);
            pq.answer.caption = this.formatHashtagHtmlClass(pq.answer.caption, regex);
          }
        }
      }
    }

    // Bind the click event to all elements with the hashtag class
    this.$timeout(() => {
      const hashtagElements = document.getElementsByClassName('hashtag');
      _.each(hashtagElements, (element) => {
        const hashtagElement = angular.element(element);
        hashtagElement.on('click', (event) => {
          this.onHashtagClick(event.target.innerHTML);
        });
      });
    });
  }

  // Wraps every hashtag in the html string in a span with a hashtag class
  private formatHashtagHtmlClass(htmlString: string, regex: RegExp): string {
    if (htmlString && regex.test(htmlString)) {
      // Clean all the existing spans inside the string by removing the hashtag class
      const htmlWrapper = document.createElement('span');
      htmlWrapper.innerHTML = htmlString;
      angular.element(htmlWrapper).find('span').removeClass('hashtag');
      htmlString = htmlWrapper.innerHTML;

      htmlString = htmlString.replace(regex, (match) => `<span class='hashtag'>${match}</span>`);
    }
    return htmlString;
  }

  private async onHashtagClick(hashtag: string) {
    if (this.qualFilter.Keyword !== hashtag) {
      this.qualFilter.Keyword = hashtag;
      await this.loadConversations();
    }
  }

  private initProbeQuestionDiscussions() {
    this.allTopicQuestionsAnswered = this.conversationElements
      .filter((element) => this.isQuestion(element) && element.Level === 0)
      .every((element) => element.HasBeenAnswered);
    this.addAnswersToProbeQuestions();
    this.addAnswerSetsOfTopicToConversation();
    this.addProbingQuestionsToParentMessage();
    this.createIndividualUpdatePostConversations();
    this.setUpdateLabels();
    this.setAcceptedVisible();
  }

  private getConversationByGuid(conversationGuid: string) {
    let conversation = _.find(this.conversationElements, (c) => c.Guid === conversationGuid);
    if (!conversation) {
      conversation = _.find(this.topicWithProbeQuestions, (c) => c.Guid === conversationGuid);
    }
    return conversation;
  }

  private getOpeningPost() {
    let openingPost = _.find(this.conversationElements, (c) =>
      this.isTopic(c) && c.Guid === c.ParentMessage);
    if (!openingPost) {
      openingPost = _.find(this.topicWithProbeQuestions, (c) =>
        this.isTopic(c) && c.Guid === c.ParentMessage);
    }
    return openingPost;
  }

  // Add the answers to probequestions of:
  // 1. The topic => only for current user and only if the questionset hasn't been answered
  // 2. All individual update posts
  private addAnswersToProbeQuestions() {
    const probeAnswers = this.conversationElements.filter((element) => this.isAnswer(element));
    const answersToBeRemoved: IConversationElement[] = [];
    const probeQuestionGuids = this.conversationElements
      .filter((element) => this.isQuestion(element))
      .map((element) => element.Guid);

    // The impersonate in auth is a getter which will retrieve and parse JSON from storage with every get -> for each in impersonateUserList
    const impersonation = this.authService.impersonate;
    this.conversationElements
      .filter((element) => probeQuestionGuids.indexOf(element.Guid) !== -1)
      .forEach((element: IProbeQuestionConversationElementItem) => {
        const parentMessage = this.getConversationByGuid(element.ParentMessage);
        let answer: IConversationElement;

        if (this.isTopic(parentMessage)) {
          if (this.allTopicQuestionsAnswered && this.contributionType !== this.serverConstants.activityContributionTypeConstants.diary) {
            return;
          }

          const currentSquareParticipantGuid = impersonation
            ? impersonation.SquareParticipantGuid
            : this.currentUserService.userProfile.Guid;

          answer = _.find(probeAnswers, (a) => a.ParentMessage === element.Guid &&
            a.SquareParticipantGuid === currentSquareParticipantGuid &&
            a.IsInDraft);
          if (answer) {
            answersToBeRemoved.push(answer);
          }
        } else if (this.isIndividualUpdatePost(parentMessage)) {
          answer = _.find(probeAnswers, (a) => a.ParentMessage === element.Guid);
          if (answer && !answer.IsInDraft) {
            parentMessage.IsReadonly = true;
            parentMessage.HasBeenAnswered = true;
          }
        }

        element.Answer = {
          Guid: answer ? answer.Guid : undefined,
          SquareParticipantId: answer ? answer.SquareParticipantId : undefined,
          SquareParticipantGuid: answer ? answer.SquareParticipantGuid : undefined,
          ProbeQuestionGuid: element.Guid,
          Message: answer ? answer.Message : '',
          Caption: answer ? answer.Caption : '',
          Stimuli: answer ? answer.Stimuli : [],
          Attachments: answer ? answer.Attachments : [],
          HasBeenSaved: answer != null,
          IsValidAnswer: answer ? answer.IsValidAnswer : false,
          IsInDraft: answer ? answer.IsInDraft : false,
        };

      });
    this.conversationElements = this.conversationElements.filter((element) => answersToBeRemoved.indexOf(element) === -1);
  }

  private addAnswerSetsOfTopicToConversation() {
    const topic = this.getOpeningPost();
    const topicQuestions = this.topicWithProbeQuestions
      .filter((element) => this.isQuestion(element));

    const answers = this.conversationElements
      .filter((element) => {
        const parentMessageIsQuestion = topicQuestions.map((question) => question.Guid).indexOf(element.ParentMessage) !== -1;
        return this.isAnswer(element) && !element.IsInDraft && parentMessageIsQuestion;
      });

    // Create a list of unique pairs containing the squareParticipantGuid and the set where he/she answered
    const squareParticipantGuidSetPairs: ISquareParticipantGuidAnswerSetPair[] = _.uniqBy(answers.map((a) =>
      ({
        SquareParticipantGuid: a.SquareParticipantGuid,
        Set: a.Set,
      }),
    ), 'Set');
    // Group the pairs by squareParticipantGuid, thus creating a list where each squareParticipantGuid has a list of sets where he/she answered
    const squareParticipantGuidSetsList = squareParticipantGuidSetPairs.reduce((list, answerSetPair: ISquareParticipantGuidAnswerSetPair) => {
      list[answerSetPair.SquareParticipantGuid] = [...list[answerSetPair.SquareParticipantGuid] || [], answerSetPair.Set];
      return list;
    }, {});

    const sets = _.uniq(answers.map((element) => element.Set));

    _.forEach(sets, (set) => {
      const probeAnswers = this.conversationElements
        .filter((element) => this.isAnswer(element) && element.Set === set);
      const firstAnswer = probeAnswers[0];
      const lastAnswer = probeAnswers[probeAnswers.length - 1];

      if (!firstAnswer) {
        return false;
      }

      const questions = angular.copy(topicQuestions);
      questions
        .forEach((question: IProbeQuestionConversationElementItem) => {
          const answer = _.find(probeAnswers, (a) => a.ParentMessage === question.Guid);

          question.Answer = {
            Guid: answer ? answer.Guid : undefined,
            SquareParticipantId: answer ? answer.SquareParticipantId : undefined,
            SquareParticipantGuid: answer ? answer.SquareParticipantGuid : undefined,
            ProbeQuestionGuid: question.Guid,
            Message: answer ? answer.Message : '',
            Caption: answer ? answer.Caption : '',
            Stimuli: answer ? answer.Stimuli : [],
            Attachments: answer ? answer.Attachments : [],
            IsValidAnswer: answer ? answer.IsValidAnswer : false,
            IsInDraft: answer ? answer.IsInDraft : false,
          };
        });

      // Create an 'answer set'.
      // The answers are combined with their questions in one conversation element.
      // This is because the answers should be shown in 1 post.
      const datePosted = lastAnswer ? lastAnswer.DatePosted : firstAnswer.DatePosted;
      const answerSet: IConversationElement = {
        ...firstAnswer,
        Message: '',
        Caption: '',
        Stimuli: [],
        Attachments: [],
        ParentMessage: topic.Guid,
        IsReadonly: true,
        DatePosted: lastAnswer ? lastAnswer.DatePosted : firstAnswer.DatePosted,
        ProbeQuestions: questions,
      };

      if (this.contributionType === this.serverConstants.activityContributionTypeConstants.diary) {
        let indexOfSet = answerSet.SetIndex;
        if (!indexOfSet) {
          const squareParticipantSets = squareParticipantGuidSetsList[firstAnswer.SquareParticipantGuid].sort();
          indexOfSet = squareParticipantSets.indexOf(set);
        }
        answerSet.Title = `#${indexOfSet + 1} - ${DateTime.fromISO(datePosted).toLocaleString(DateTime.DATETIME_MED)}`;
      }

      // Remove the answers from the list and replace with the newly created conversationElement at the index of the first answer
      this.conversationElements.splice(_.indexOf(this.conversationElements, firstAnswer), probeAnswers.length, answerSet);
    });
  }

  private addProbingQuestionsToParentMessage() {
    const probeQuestions = this.conversationElements
      .filter((element) => this.isQuestion(element));
    if (probeQuestions.length === 0) {
      return;
    }
    const openingPost = this.getOpeningPost();
    if (openingPost && openingPost.IsOneByOne) {
      const numberOfOpeningPostProbeQuestions = probeQuestions
        .filter((question) => question.ParentMessage === openingPost.Guid)
        .length;
      const numberOfOpeningPostProbeQuestionsAnswered = numberOfOpeningPostProbeQuestions - openingPost.RemainingQuestionsForCurrentSquareParticipant;
      probeQuestions.splice(numberOfOpeningPostProbeQuestionsAnswered + 1, openingPost.RemainingQuestionsForCurrentSquareParticipant - 1);
      this.conversationElements = this.conversationElements
        .filter((element) => !this.isQuestion(element) || probeQuestions.indexOf(element) !== -1);
    }
    const parentMessageGuids = probeQuestions.map((element) => element.ParentMessage);

    // The impersonate in auth is a getter which will retrieve and parse JSON from storage with every get -> for each in impersonateUserList
    const impersonation = this.authService.impersonate;
    this.conversationElements.filter((element) => parentMessageGuids.indexOf(element.Guid) !== -1).forEach((element) => {
      element.ProbeQuestions = [...probeQuestions.filter(((q) => q.ParentMessage === element.Guid))];
      element.ProbeQuestionsForEditor = this.mappingService.mapProbeQuestionsForProbeQuestionEditor(element.ProbeQuestions);
      if (this.isIndividualUpdatePost(element)) {
        const parentMessage = this.getConversationByGuid(element.ParentMessage);
        if (!parentMessage) {
          return;
        }
        element.IsReadonly = (parentMessage.SquareParticipantGuid !== this.currentUserService.userProfile.Guid ||
          impersonation) || element.HasBeenAnswered;
      }
    });

    this.conversationElements = this.conversationElements.filter((element) => probeQuestions.indexOf(element) === -1);
    this.conversationElements = this.conversationElements.map((element) => {
      if (element.ProbeQuestions && element.ProbeQuestions.length > 0) {
        element.ProbeQuestionsForEditor = this.mappingService.mapProbeQuestionsForProbeQuestionEditor(element.ProbeQuestions);
      }
      return element;
    });
  }

  // Unlike answersets on the topic, we want answersets on indivudualposts to be displayed as a reply on the post containing the questions.
  // Therefor we need to create a new 'answerset' conversation as a reply on the questionset.
  private createIndividualUpdatePostConversations() {
    const answeredIndividualUpdatePosts = this.conversationElements.filter((element) =>
      this.isIndividualUpdatePost(element) && element.HasBeenAnswered);
    _.forEach(answeredIndividualUpdatePosts, (individualUpdatePost) => {
      const firstQuestion = individualUpdatePost.ProbeQuestions[0];
      const firstAnswer = this.conversationElements.find((element) => element.ParentMessage === firstQuestion.Guid);
      const lastQuestion = individualUpdatePost.ProbeQuestions[individualUpdatePost.ProbeQuestions.length - 1];
      const lastAnswer = this.conversationElements.find((element) => element.ParentMessage === lastQuestion.Guid);

      const conversationElement: IConversationElement = {
        ...firstAnswer,
        Message: '',
        Stimuli: [],
        Attachments: [],
        ParentMessage: individualUpdatePost.Guid,
        DatePosted: lastAnswer.DatePosted,
        ProbeQuestions: angular.copy(individualUpdatePost.ProbeQuestions),
        ProbeQuestionsForEditor: angular.copy(individualUpdatePost.ProbeQuestionsForEditor),
        IsReadonly: true,
        HasBeenAnswered: true,
      };

      individualUpdatePost.HasBeenAnswered = false;
      individualUpdatePost.IsProbeQuestionsUnEditable = true;
      _.forEach(individualUpdatePost.ProbeQuestions, (question) => {
        question.Answer = null;
      });

      const indexOfPost = _.indexOf(this.conversationElements, individualUpdatePost);
      if (indexOfPost === this.conversationElements.length - 1) {
        this.conversationElements.push(conversationElement);
      } else {
        this.conversationElements.splice(indexOfPost + 1, 0, conversationElement);
      }
    });

    this.conversationElements = this.conversationElements
      .filter((element) => !this.isAnswer(element) || element.ProbeQuestions);
  }

  private setUpdateLabels() {
    this.conversationElements
      .filter((element: IProbeQuestionConversationElementItem) => this.isIndividualUpdatePost(element) || this.isReplyWithAnswerRequired(element))
      .forEach((element: IProbeQuestionConversationElementItem) => {
        const updateLabelRequired = (element.ProbeQuestionsForEditor &&
          element.ProbeQuestionsForEditor.length &&
          _.some(element.ProbeQuestionsForEditor, (q) => q.answerRequired)) ||
          this.isReplyWithAnswerRequired(element);
        if (updateLabelRequired) {
          // If there's an individual update post, the parent (the participant's answer) should have the
          // 'Update required' label if the individual update post contains question that are not optional
          const parent = this.conversationElements.filter((p) => p.Guid === element.ParentMessage)[0] || null;
          if (parent) {
            parent.UpdateLabel = '(LabelQualConversationUpdateRequired)';
          }

          // If all child questions are answered and the answers are not in draft, remove the post
          if (element.ProbeQuestionsForEditor &&
            element.ProbeQuestionsForEditor.every((q) => q.answer && q.answer.guid && !q.answer.isInDraft)) {
            // If all the questions have been answered and the answers are not in draft, the answer post should have the 'Updated' label
            const answerPost = this.conversationElements.filter((p) => p.ParentMessage === element.Guid)[0] || null;
            if (answerPost) {
              answerPost.UpdateLabel = '(LabelQualConversationAnswerUpdated)';
            }
          }
        }
      });
  }

  private setAcceptedVisible() {
    this.conversationElements
      .forEach((element: IProbeQuestionConversationElementItem) => {
        element.IsAcceptedVisible =
          this.isModeratorCurationEnabled
          && this.contributionType === this.serverConstants.activityContributionTypeConstants.diary
          && this.isAnswer(element);
      });
  }

  private setDisplayLevel() {
    this.conversationElements
      .forEach((element: IProbeQuestionConversationElementItem) => {
        if (this.isIndividualUpdatePost(element) || this.isAnswer(element) || this.isReplyWithAnswerRequired(element)) {
          element.DisplayLevel = 1;
        } else {
          element.DisplayLevel = element.Level;
        }
      });
  }

  private isQuestion(element: IConversationElement) {
    return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.question;
  }

  private isAnswer(element: IConversationElement) {
    return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.answer;
  }

  private isTopic(element: IConversationElement) {
    return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.topic;
  }

  private isIndividualUpdatePost(element: IConversationElement) {
    return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.individualUpdatePost;
  }

  private isReplyWithAnswerRequired(element: IConversationElement) {
    return element != null && element.Type === this.serverConstants.conversationElementTypeConstants.reply && element.AnswerRequired;
  }

  async loadConversations(silentLoad: boolean = false, newReplyGuid?: string, impersonatedUserGuid?: string) {
    const loadPostPromise = this.loadPosts(newReplyGuid, impersonatedUserGuid);
    if (!silentLoad) {
      this.spinnerservice.showFor('loading', loadPostPromise);
    } else {
      await this.$q.all(loadPostPromise);
    }
    this.snippetHighlighterService.init();
  }

  toggleReplies(conversation) {
    conversation.IsExpanded = !conversation.IsExpanded;

    if (conversation.IsExpanded) {
      this.expandedPosts = _.union(this.expandedPosts, [conversation.Guid]);
    } else {
      this.expandedPosts = _.without(this.expandedPosts, conversation.Guid);
    }

    this.loadConversations();
  }

  trackViewed(conversation) {
    // if the feature of not using the unread conversations is enabled, we do not go to server
    if (this.noUnreadConversationsFeatureEnabled === true) {
      return;
    }
    if (conversation.UnRead) {
      this.readConversations.push(conversation);
      this.conversationsRead = this.conversationsRead || _.debounce(() => {
        const readConversationGuids = _.map(this.readConversations, 'Guid');
        this.readConversations = [];
        this.forumservice.deleteConversationUnReadDiscussion(readConversationGuids);
      }, 2000, { maxWait: 10000 });

      this.conversationsRead();

      this.$timeout(() => {
        conversation.Reading = true;
        this.$timeout(() => {
          conversation.UnRead = false;
        }, 3000);
      }, 1000);
    }
  }

  loadFilteredReplies = async () => {
    if (!this.loading || this.$stateParams.isReloadRequired) {
      this.qualFilter.Highlight = true;
      await this.loadConversations();
    }
  };

  navigate(pageNumber: number) {
    const filterParams = this.activityFilterService.getQualStateParamsFromFilter(this.qualFilter, true);
    this.$state.go('root.square.activitydata.conversations', {
      referer: this.$stateParams.referer,
      page: pageNumber,
      sort: this.sortBy,
      expandedPosts: this.expandedPosts,
      isGroupedByMember: this.isGroupedByMember,
      searchKeyword: filterParams.keyword,
      isReloadRequired: true,

      ...filterParams,
    }, {
      reload: 'root.square.activitydata.conversations',
    });
  }

  setPostUpdateRedirectText() {
    const link = this.$state.href('.', {
      page: this.latestUpdatePageIndex,
    });
    this.constantsfactory.getLabelValue('LabelQualConversationPostUpdated').then((value) => {
      value = `<span>${value}`;
      let label = value.replace(/\{(\w+)\|(url)\}/g, (match, text) => `</span><a class="link" href="${link}">${text}</a><span>`);
      label += '</span>';
      this.postUpdateRedirectText = label;
    });
  }

  resetFilter() {
    this.qualFilter = {
      Keyword: '',
      Highlight: false,
      SelectedThemes: [],
      SelectedMembers: [],
      StartDate: this.minStartDate,
      EndDate: this.maxEndDate,
      SelectedRatings: [],
      SelectedModerationStatusOptions: [],
      SelectedAnnotationsOptions: [],
      SelectedStimuliOptions: [],
      TagsSelected: [],
      HashtagsSelected: [],
      SelectedSegments: [],
      SelectedProbeQuestions: [],
      SelectedSegmentsOption: this.serverConstants.selectedSegmentsOptionConstants.any,
    };
    this.loadFilteredReplies();
  }

  onSortChange(value) {
    this.sortBy = value;
    this.navigate(1);
  }

  setNoteOnTop(guid, noToggle) {
    if (!noToggle && this.onTopNote === guid) {
      this.onTopNote = '';
    } else {
      this.onTopNote = guid;
      this.onTopTag = '';
    }
  }

  setTagOnTop(guid, noToggle) {
    if (!noToggle && this.onTopTag === guid) {
      this.onTopTag = '';
    } else {
      this.onTopTag = guid;
      this.onTopNote = '';
    }
  }

  setTagAndNoteOnTop(show, item) {
    if (this.isFilteredOnNotes && item.Notes.length) {
      this.onTopNote = '';
      if (show) {
        this.onTopNote = item.Guid;
      }
    }
    if (this.isFilteredOnTags && item.Tags.length) {
      this.onTopTag = '';
      if (show) {
        this.onTopTag = item.Guid;
      }
    }
  }

  // TODO: check notes-filter class
  getItemClass(item) {
    return {
      'notes-filter': this.isFilteredOnNotes && item.Notes.length,
      'tags-filter': this.isFilteredOnTags && item.Tags.length,
      'on-top-tag': item.Guid === this.onTopTag,
      'on-top-note': item.Guid === this.onTopNote,
    };
  }

  isFilterApplied() {
    if (_.isUndefined(this.qualFilter)) {
      return false;
    }

    return (
      (this.qualFilter.Keyword && this.qualFilter.Highlight) ||
      !_.isEmpty(this.qualFilter.SelectedMembers) ||
      !_.isEmpty(this.qualFilter.SelectedSegments) ||
      !_.isEmpty(this.qualFilter.SelectedThemes) ||
      !_.isEmpty(this.qualFilter.SelectedProbeQuestions) ||
      !_.isEmpty(this.qualFilter.TagsSelected) ||
      !_.isEmpty(this.qualFilter.HashtagsSelected) ||
      !_.isEmpty(this.qualFilter.SelectedModerationStatusOptions) ||
      !_.isEmpty(this.qualFilter.SelectedAnnotationsOptions) ||
      !_.isEmpty(this.qualFilter.SelectedStimuliOptions) ||
      !_.isEmpty(this.qualFilter.SelectedRatings));
  }

  private addInDraftRepliesToPosts() {
    const repliesInDraft = this.conversationElements
      .filter((element) => element.IsInDraft);
    const parentMessageGuids = repliesInDraft.map((reply) => reply.ParentMessage);

    this.conversationElements
      .filter((element) => parentMessageGuids.indexOf(element.Guid) !== -1)
      .forEach((element) => {
        element.InDraftReply = _.find(repliesInDraft, (reply) => reply.ParentMessage === element.Guid);
      });

    this.conversationElements =
      this.conversationElements.filter((element) => repliesInDraft.indexOf(element) === -1);
  }
}
