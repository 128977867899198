'use strict';

import { PublishUrlController } from './publishUrl.controller';

const template = require('./publishUrl.html');

angular.module('insitesApp.activityconfig').component('publishUrl', {
  templateUrl: template,
  controller: PublishUrlController,
  controllerAs: 'vm',
});
