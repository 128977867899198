'use strict';

import { AuthService } from './../dataservices/auth.service';
import _ = require('lodash');

const UserRoleFilter = (authService: AuthService) => (item) => {
  const roles = item.split(' ');
  const role = authService.getRole();
  if (!role) {
    return false;
  }

  return _.includes(roles, role);
};

UserRoleFilter.$inject = ['authService'];

export default UserRoleFilter;
