'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.featureSetting',
      config: {
        url: 'featuresetting',
        title: 'Feature Setting',
        views: {
          'sidenav': {
            component: 'homeSidenavComponent',
          },
          'sidenav-footer': {
            component: 'homeSidenavFooterComponent',
          },
          '': {
            component: 'featureSetting',
          },
        },
        disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
        isVue: true,
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

