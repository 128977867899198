'use strict';

export class KeyboardTableSort implements ng.IDirective {
  restrict = 'E';
  link(scope, $element, attrs) {
    // Ensure the element is focusable
    if (attrs.mdOrderBy) {
      $element.attr('tabindex', '0');
    }

    $element.bind('keydown keypress', (event) => {
      if (event.which === 13 || event.keyCode === 32) {
        // Perform click on the element when enter/space is pressed
        // Using [0] to target the actual dom object
        $element[0].click();

        event.preventDefault();
        scope.$apply();
      }
    });

    // Unbind events
    scope.$on('$destroy', () => {
      $element.off('keydown keypress');
    });
  }
}

