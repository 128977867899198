'use strict';

/* eslint-disable */
var appInsights =
  (window as any).appInsights ||
  (function(config) {
    function i(config) {
      t[config] = function() {
        var i = arguments;
        (t as any).queue.push(function() {
          t[config].apply(t, i);
        });
      };
    }
    var t = { config: config },
      u = document,
      e: any = window,
      o = 'script',
      s = 'AuthenticatedUserContext',
      h = 'start',
      c = 'stop',
      l = 'Track',
      a = l + 'Event',
      v = l + 'Page',
      y = u.createElement(o),
      r,
      f;
    (y as any).src = (config as any).url || 'https://az416426.vo.msecnd.net/scripts/a/ai.0.js';
    u.getElementsByTagName(o)[0].parentNode.appendChild(y);
    try {
      (t as any).cookie = u.cookie;
    } catch (p) {}
    for ((t as any).queue = [], (t as any).version = '1.0', r = ['Event', 'Exception', 'Metric', 'PageView', 'Trace', 'Dependency']; r.length; )
      i('track' + r.pop());
    return (
      i('set' + s),
      i('clear' + s),
      i(h + a),
      i(c + a),
      i(h + v),
      i(c + v),
      i('flush'),
      (config as any).disableExceptionTracking ||
        ((r = 'onerror'),
        i('_' + r),
        (f = e[r]),
        (e[r] = function(config, i, u, e, o) {
          var s = f && f(config, i, u, e, o);
          return s !== !0 && t['_' + r](config, i, u, e, o), s;
        })),
      t
    );
  })({
    instrumentationKey: (window as any).__env.instrumentationKey,
  });

(window as any).appInsights = appInsights;
appInsights.trackPageView();
