'use strict';

export class DeleteButtonController {
  static $inject = ['$attrs', '$element'];
  disabled = false;
  menuItem;

  constructor(
    private $attrs: ng.IAttributes,
    private $element: ng.IRootElementService,
  ) { }

  $onInit() {
    this.$attrs.$observe<boolean>('disabled', (disabled) => {
      this.disabled = disabled;
    });

    this.$element.on('click', (event) => {
      if (this.disabled) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
    });
  }
}
