'use strict';

import { ConfigController } from './config.controller';

const template = require('./config.html');

export const ConfigComponent: ng.IComponentOptions = {
  controller: ConfigController,
  templateUrl: template,
  controllerAs: 'vm',
};
