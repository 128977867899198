'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';
import { MemberService } from '../../../core/services/member.service';

const memberOverviewAppRun = (routerHelper: RouterHelper, memberService: MemberService) => {
  routerHelper.configureStates([
    {
      state: 'root.square.members',
      config: {
        url: '/members',
        title: 'Member overview',
        abstract: true,
        default: 'root.square.members.overview',
        template: '<ui-view />',
        activateRoute: 'root.square.members',
        settings: {
          nav: 6,
          childnav: 1,
          label: 'Member overview',
          section: 'SQUARE MANAGEMENT',
        },
        disallowRoles: ['Observer'],
      },
    },
    {
      state: 'root.square.members.overview',
      config: {
        url: `/overview?page?limit?order?${memberService.getFilterUrlParts()}`,
        title: 'Member overview',
        component: 'memberOverview',
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

memberOverviewAppRun.$inject = ['routerHelper', 'memberService'];

angular
  .module('insitesApp.squaremembers.memberOverview')
  .run(memberOverviewAppRun);
