'use strict';

import { DateTime } from 'luxon';
import { config } from './config';

const disableHttpCaching = ($httpProvider) => {
  const cacheControlHeader = 'Cache-Control';
  const pragmaHeader = 'Pragma';
  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {};
  }

  if (!$httpProvider.defaults.headers.post) {
    $httpProvider.defaults.headers.post = {};
  }

  // Disable IE ajax request caching
  $httpProvider.defaults.headers.get[cacheControlHeader] = 'no-cache';
  $httpProvider.defaults.headers.get[pragmaHeader] = 'no-cache';

  $httpProvider.defaults.headers.post[cacheControlHeader] = 'no-cache';
  $httpProvider.defaults.headers.post[pragmaHeader] = 'no-cache';
};

const configure = (
  $logProvider: ng.ILogProvider,
  routerHelperProvider,
  exceptionHandlerProvider,
  $httpProvider: ng.IHttpProvider,
  $mdDateLocaleProvider: ng.material.IDateLocaleProvider,
  ngWigToolbarProvider,
  __env,
  $mdAriaProvider,
  $compileProvider: ng.ICompileProvider,
  $qProvider: ng.IQProvider,
) => {
  if ($logProvider.debugEnabled) {
    $logProvider.debugEnabled(__env.enableDebug);
  }
  if ($mdAriaProvider.disableWarnings && !__env.enableDebug) {
    $mdAriaProvider.disableWarnings();
  }
  if ($compileProvider.debugInfoEnabled) {
    $compileProvider.debugInfoEnabled(__env.enableDebug);
  }
  $compileProvider.aHrefSanitizationWhitelist(/^\s*(softwarecenter|http|https):/);
  exceptionHandlerProvider.configure(config.appErrorPrefix);
  routerHelperProvider.configure({ docTitle: `${config.appTitle}: ` });
  $httpProvider.interceptors.push('httpRequestInterceptor');
  disableHttpCaching($httpProvider);

  $mdDateLocaleProvider.formatDate = (date: any) => {
    if (date === '' || date === undefined) {
      return '';
    }
    if (!(DateTime.fromISO(date).isValid)) {
      return '';
    }
    return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_MED);
  };

  ngWigToolbarProvider.addStandardButton('underline', 'Underline', 'underline', 'fa-underline');

  $qProvider.errorOnUnhandledRejections(false);
};
configure.$inject = ['$logProvider', 'routerHelperProvider', 'exceptionHandlerProvider', '$httpProvider', '$mdDateLocaleProvider',
  'ngWigToolbarProvider', '__env', '$mdAriaProvider', '$compileProvider', '$qProvider'];

export default configure;
