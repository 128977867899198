'use strict';

import { ServerConstants } from './../../core/serverconstants';
import { AuthService } from '../../core/dataservices/auth.service';
export class IscSegmentationStatusFilterController {
  static $inject = ['serverConstants', 'authService'];

  availableStatuses: any[];

  constructor(
    private serverConstants: ServerConstants,
    private authService: AuthService,
  ) {
    this.availableStatuses = [
      { value: 'active', Label: 'Active', isDisabled: true },
      { value: 'disabled', Label: 'Disabled' },
      { value: 'archived', Label: 'Archived' },
    ];
  }
}
