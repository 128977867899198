'use strict';

import '../activityProbeQualBuild/probe-question/isc-probe-question';
import '../activityProbeQualBuild/probe-question/isc-probe-question-overview';
import '../../activityQualOpeningPost';

import { ActivityObservationBuildComponent } from './activityObservationBuild.component';

angular
  .module('insitesApp.activityQualResearchConfig')
  .component('activityObservationBuild', ActivityObservationBuildComponent);
