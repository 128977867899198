'use strict';

import { HttpService } from './http.service';

export class ExportLogService {
  static $inject = ['httpservice'];

  constructor(private httpservice: HttpService) { }

  getLogList(startDate, endDate, limit) {
    return this.httpservice.get({
      url: '/ExportLogService/List',
      params: {
        startDate,
        endDate,
        pageSize: limit,
      },
    });
  }
}
