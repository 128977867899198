'use strict';

import { CommunicationWizardController } from './communicationWizard.controller';

const communicationWizardTemplate = require('./communicationWizard.html');

export const communicationWizard: ng.IComponentOptions = {
  templateUrl: communicationWizardTemplate,
  controller: CommunicationWizardController,
  controllerAs: 'vm',
};
