'use strict';

const ParamResolver = () => (paramName) => {
  const resolver = ($stateParams) => $stateParams[paramName];
  resolver.$inject = ['$stateParams'];
  return resolver;
};

angular
  .module('insitesApp.core')
  .factory('paramResolver', ParamResolver);
