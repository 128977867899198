'use strict';

import { PublishRecruitmentPartnerController } from './publishRecruitmentPartner.controller';

const template = require('./publishRecruitmentPartner.html');

angular
  .module('insitesApp.activityconfig')
  .component('publishRecruitmentPartner', {
    templateUrl: template,
    controller: PublishRecruitmentPartnerController,
    controllerAs: 'vm',
    bindings: {
      recruitmentPartners: '<',
    },
  });
