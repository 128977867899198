import { IscWizardSubstepController } from './isc-wizard-substep.controller';
import { UiModule } from '../../index';
UiModule
    .component('iscWizardSubstep', {
    template: '<div ng-transclude ' +
        'ng-show="vm.getState()===vm.wizardComponent.constants.state.current_complete || vm.getState()===vm.wizardComponent.constants.state.current_incomplete"> </div>',
    controller: IscWizardSubstepController,
    controllerAs: 'vm',
    transclude: true,
    require: {
        stepComponent: '^^iscWizardStep',
        wizardComponent: '^^iscWizard',
    },
    bindings: {
        caption: '@',
        description: '@',
        order: '=',
    },
});
