'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'root.square.mainPages',
    config: {
      url: '/config/mainpages',
      component: 'mainPages',
      title: 'Main pages',
      settings: {
        nav: 2,
        childnav: 2,
        label: 'Main pages',
        showForums: true,
      },
      disallowRoles: ['Observer'],
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

