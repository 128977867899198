'use strict';

import { SplashPageController } from './splashPage.controller';

const template = require('./splashPage.html');

require('./splashPage.scss');

export const SplashPageComponent: ng.IComponentOptions = {
  templateUrl: template,
  controller: SplashPageController,
  controllerAs: 'vm',
};
