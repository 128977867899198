'use strict';

import { ServerConstants } from './../../core/serverconstants';
import { Utils } from './../../core/utils';
import { AuthService } from '../../core/dataservices/auth.service';

export class IscActivityStatusFilterController {
  static $inject = ['serverConstants', '_', 'authService'];

  squareActivityStatusConstants;
  availableStatuses: IActivityStatus[];

  constructor(
    private serverConstants: ServerConstants,
    private _: _.LoDashStatic,
    private authService: AuthService,
  ) {
    this.squareActivityStatusConstants = this.serverConstants.squareActivityStatusConstants;

    this.availableStatuses = [];

    _.forEach(this.squareActivityStatusConstants, (status) => {
      if (!this.authService.isHuman8UserLogin() && status === this.serverConstants.squareActivityStatusConstants.archived) {
        return;
      }

      this.availableStatuses.push({ Label: Utils.getEnumValueName(this.squareActivityStatusConstants, status), Value: status });
    });
  }
}

interface IActivityStatus {
  Label: string;
  Value: number;
}
