'use strict';

import { PageEditorController } from './pageEditor.controller';

const template = require('./pageEditor.html');

export const pageEditorComponent: ng.IComponentOptions = {
  templateUrl: template,
  controller: PageEditorController,
  controllerAs: 'pageEditor',
  bindings: {
    area: '<',
    pageTypeDetails: '<',
    getTemplateForPage: '=',
  },
};
