'use strict';

import { ProjectService } from './dataservices/project.service';
import { LanguageContainer } from './languageContainer';

export class LabelFactory {
  static $inject = ['_', 'projectservice', '$q'];
  constructor(
    private _: _.LoDashStatic,
    private projectservice: ProjectService,
    private $q: ng.IQService) { }

  labels: { [language: string]: LanguageContainer } = {};
  emailLabels: { [language: string]: LanguageContainer } = {};
  emailDefaultLabels: { [language: string]: LanguageContainer } = {};

  getLabelsLanguage = (language) => {
    if (this.labels[language]) {
      return this.$q.resolve(this.labels[language]);
    }

    return this.projectservice.getListLabel(language).then((response) => {
      this.labels[language] = new LanguageContainer(response.data.List);
      return this.labels[language];
    });
  };

  getEmailLabelsLanguage = (language) => {
    if (this.emailLabels[language]) {
      return this.$q.resolve(this.emailLabels[language]);
    }

    return this.projectservice.getListEmailLabel(language).then((response) => {
      this.emailLabels[language] = new LanguageContainer(response.data.List);
      return this.emailLabels[language];
    });
  };

  getListEmailDefaultLabel = (language) => {
    if (this.emailDefaultLabels[language]) {
      return this.$q.resolve(this.emailDefaultLabels[language]);
    }

    return this.projectservice.getListEmailDefaultLabel(language).then((response) => {
      this.emailDefaultLabels[language] = new LanguageContainer(response.data.List);
      return this.emailDefaultLabels[language];
    });
  };
}

angular
  .module('insitesApp.core')
  .service('labelFactory', LabelFactory);
