'use strict';

import { RouterHelper } from '../router/router-helper';

const config = ($provide: ng.auto.IProvideService) => {
  $provide.decorator('$state', extendStateProvider);
};
config.$inject = ['$provide'];

const  extendStateProvider = ($delegate, $timeout: ng.ITimeoutService) => {
  // Let's locally use 'state' name
  const state = $delegate;

  // Let's extend this object with new function
  // 'baseGo', which in fact, will keep the reference
  // To the original 'go' function
  state.baseGo = state.go;

  // Here comes our new 'go' decoration
  const go = (to, params, options) => {
    $timeout(() => {
      state.baseGo(to, params, options);
    }, 0);
  };

  // Assign new 'go', right now decorating the old 'go'
  state.go = go;
  state.defaultErrorHandler(() => {
    // If something flagged that the Human8 user is invalid, clear the flag and redirect to home
    if (RouterHelper.insitesUserInvalidSquareGuid) {
      delete RouterHelper.insitesUserInvalidSquareGuid;
      state.go('root.home');
    }
  });

  return $delegate;
};
extendStateProvider.$inject = ['$delegate', '$timeout'];

angular
  .module('blocks.state')
  .config(config);
