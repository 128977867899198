'use strict';

import { SquareService } from '../../dataservices/square.service';
import { ClientService } from '../../dataservices/client.service';
import { IImageSettingsModel } from '../../../square/squareConfig/design/images/imageSettingsModel';

export class ImageTileController {

  static $inject = ['$stateParams', '__env', 'squareservice', '$scope', 'clientservice'];
  constructor(
    private $stateParams,
    private __env: Environment,
    private squareService: SquareService,
    private $scope: ng.IScope,
    private clientService: ClientService,
  ) { }
  setting: IImageSettingsModel;
  imageSrc: string;
  model: string;
  form: ng.IFormController;
  baseUrl: string;

  $onInit() {
    let envPrefix = '';
    if (this.__env.isEnvironmentAsPrefix) {
      envPrefix = `${this.__env.environment.toLowerCase()}/`;
    }

    this.baseUrl = `${this.__env.azureBaseUrl + envPrefix + this.$stateParams.clientGuid}/${this.$stateParams.squareGuid}/design-images/`;
    this.checkAndSetDesignImage();
    this.$scope.$on('isc-images-saved', () => {
      this.$scope.$apply();
      this.checkAndSetDesignImage();
    });

    this.$scope.$on('images-cancelled', () => {
      this.checkAndSetDesignImage();
    });
  }

  checkAndSetDesignImage() {
    if (this.$stateParams.squareGuid) {
      if (this.setting.IsStored) {
        this.imageSrc = `${this.baseUrl}${this.setting.StoredImage}?t=${new Date().getTime()}`;
      } else {
        this.imageSrc = this.setting.AlternateImage;
      }
    } else {
      this.clientService.getClientLogo(false)
        .then((response) => {
          this.imageSrc = response;
          this.setting.IsStored = response !== this.setting.AlternateImage ? true : false;
        });
    }
  }

  deleteDesignImage() {
    this.imageSrc = this.setting.AlternateImage;
    this.model = null;
    this.setting.IsStored = false;
    this.form.$dirty = true;
  }

  formatError(file) {
    const error = {};
    if (!file) {
      return error;
    }

    error[file.$error] = true;
    return error;
  }
}
