'use strict';

import { StatusToClass } from './statusToClass.filter';
import UserRoleFilter from './user-role.filter';
import ValidationConstantsFilter from './validationConstants.filter';

require('./validationConstants.filter');
require('./stimuli-ignore.filter');
require('./timeago');
require('./user-role.filter');
require('./date-format.filter');

angular
  .module('insitesApp.core')
  .filter('statusToClass', StatusToClass.filter)
  .filter('userRole', UserRoleFilter)
  .filter('validationConstantsFilter', ValidationConstantsFilter);
