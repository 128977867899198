'use strict';

import * as angular from 'angular';

import './homePlatformNotice.module';
import routeConfig from './homePlatformNotice.route';
import './homePlatformNotice.component';
import './components/noticeDefaultMaintenance/noticeDefaultMaintenance.component';
import './components/noticeCustom/noticeCustom.component';
import './components/noticeDateTime/noticeDateTime.component';
import { HomePlatformNoticeComponent } from './homePlatformNotice.component';

angular
  .module('insitesApp.platformnotice')
  .component('homePlatformNotice', HomePlatformNoticeComponent)
  .run(routeConfig);
