'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.splash',
      config: {
        url: '/config/splash',
        component: 'splash',
        title: 'Splash',
        settings: {
          nav: 2,
          childnav: 1,
          label: 'Splash page',
          showForums: true,
        },
        disallowRoles: ['Observer'],
        params: {
          mainSplash: false,
        },
      },
    },
    {
      state: 'root.splash',
      config: {
        url: 'splash-page',
        component: 'splash',
        title: 'Splash',
        settings: {
          nav: 2,
          childnav: 1,
          label: 'Splash page',
          showForums: true,
        },
        disallowRoles: ['Observer'],
        params: {
          mainSplash: true,
        },
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

