'use strict';

export class IntroOrUrlEditorController  implements ng.IOnInit {

  static $inject = ['$scope'];

  constructor(
    private $scope: ng.IScope,
  ) { }
  flagHasCustomUrl = false;
  CustomUrl = 'https://';
  EditUrl = 'https://';
  Intro = '';
  ideastream = {
    isSsoEnabled: false,
  };
  canEdit = true;
  page;
  pageEditor;
  index;
  validationConstants;
  $onInit() {
    this.flagHasCustomUrl = !!this.page.EditUrl;
    if (this.page.CustomUrl) {
      this.CustomUrl = this.page.CustomUrl;
    }
    this.Intro = this.page.Intro;
    if (this.page.EditUrl) {
      this.EditUrl = this.page.EditUrl;
    }
    this.ideastream.isSsoEnabled = this.flagHasCustomUrl && this.page.CustomUrl !== this.page.EditUrl;
    // Update the page when something changes, this ugly thing is needed because we would like to only save intro OR customUrl, not both
    this.$scope.$watchGroup([
      () => this.flagHasCustomUrl,
      () => this.CustomUrl,
      () => this.Intro,
      () => this.EditUrl,
    ], () => {
      this.updatePage();
    });
  }

  updatePage() {
    if (this.flagHasCustomUrl) {
      this.page.CustomUrl = this.CustomUrl;
      this.page.Intro = '';
      this.page.EditUrl = this.EditUrl;
    } else {
      this.page.Intro = this.Intro;
      this.page.CustomUrl = '';
      this.page.EditUrl = '';
    }
  }
}
