'use strict';

import { SquareDownloadController } from './squaredownload.controller';

const template = require('./squaredownload.html');

export const SquareDownloadComponent: ng.IComponentOptions = {
  controller: SquareDownloadController,
  templateUrl: template,
  controllerAs: 'vm',
};
