'use strict';

import { BaseSharer } from './basesharer';
import * as _ from 'lodash';
import { ForumService } from '../../../core/dataservices/forum.service';
import { IPromise } from 'angular';
import { GoogleTranslateHelperService } from 'src/app/layout/google-translate-helper/google-translate-helper.service';
import { Utils } from '../../../core/utils';

export class Highlight extends BaseSharer {
  constructor(
    private forumservice: ForumService,
    private googleTranslateHelper: GoogleTranslateHelperService,
    private config: {
      isOnHighlightedElement: boolean,
      highlightedElementsCommonIdentifier?: string,
      highlightingElement?: any,
    }) {
    super();
  }

  private name = 'highlight';
  private readonly highlightColor = '#DDDD86';
  // Calling document.execCommand('hiliteColor', false, highlightColor); sets the style on the element as rgb color
  private readonly rgbHighlightColor = 'rgb(221, 221, 134)';

  protected renderMenuButton(text, rawText, refUrl) {
    if (this.googleTranslateHelper.isTranslationEnabled()) {
      return '';
    }
    const highlightClass = this.getMenuHighlightClassName();
    const title = Utils.stringToPascalCase(highlightClass);
    return `<a class="${highlightClass}" title="${title}" data-quote="${rawText}" href="${refUrl}" target="_blank" rel="noopener nofollow noreferrer">
              <i class="fal fa-highlighter"></i></a>`;
  }

  private getMenuHighlightClassName(): string {
    let highlightClass = 'highlight';
    if (this.config.isOnHighlightedElement) {
      highlightClass = 'unhighlight';
    }

    return highlightClass;
  }

  protected async menuButtonClick(event, menuItem) {
    event.preventDefault();
    const li = menuItem.firstChild;
    let selectedSnippet = li.getAttribute('data-quote');
    let save: (conversationGuid, content, snippet, highlightedElementsCommonIdentifier) => IPromise<any>;
    const highlightingElement = this.config.highlightingElement;
    let currentHighlightedElementsCommonIdentifier;
    if (this.config.isOnHighlightedElement) {
      currentHighlightedElementsCommonIdentifier = this.config.highlightedElementsCommonIdentifier;
      this.unhighlight(highlightingElement);
      save = (conversationGuid, content, snippet, highlightedElementsCommonIdentifier) =>
        this.forumservice.conversationHighlight(conversationGuid, content, snippet, highlightedElementsCommonIdentifier, false);
    } else {
      this.highlight(highlightingElement);
      currentHighlightedElementsCommonIdentifier = this.config.highlightedElementsCommonIdentifier;
      selectedSnippet = this.getHighlightedSnippetText(highlightingElement);
      save = (conversationGuid, content, snippet, highlightedElementsCommonIdentifier) =>
        this.forumservice.conversationHighlight(conversationGuid, content, snippet, highlightedElementsCommonIdentifier, true);
    }
    const content = highlightingElement.innerHTML;
    const conversationGuid = highlightingElement.getAttribute('postid').replace(/^post-/, '');
    await save(conversationGuid, content, selectedSnippet, currentHighlightedElementsCommonIdentifier);
    this.updateClickedMenuItemStyle(menuItem);
    this.closePopover(menuItem);
    return true;
  }

  private highlight(highlightingElement) {
    highlightingElement.setAttribute('contenteditable', 'true');
    document.execCommand('hiliteColor', false, this.highlightColor);
    highlightingElement.setAttribute('contenteditable', 'false');
    this.resolvedHighlightedElementStyling(highlightingElement);
  }
  private unhighlight(highlightingElement) {
    const spanElems = highlightingElement.querySelectorAll(`span.snippet-highlighted.${this.config.highlightedElementsCommonIdentifier}`);
    _.each(spanElems, (span) => {
      const element = angular.element(span);
      element.removeClass('snippet-highlighted');
      const highlightedElementsCommonIdentifierPattern = /^s\d{13,}$/;
      const highlightedElementsCommonIdentifierClasses = _.filter(element[0].classList, (className) => className.match(highlightedElementsCommonIdentifierPattern));
      _.each(highlightedElementsCommonIdentifierClasses, (className: string) => {
        element.removeClass(className);
      });
    });

    this.config.isOnHighlightedElement = false;
    this.config.highlightedElementsCommonIdentifier = null;
  }

  private resolvedHighlightedElementStyling(highlightingElement) {
    // Helper for unhighlight to only identify the elements highlighted in this action
    const highlightActionIdentifier = `s${(new Date().getTime()).toString()}`;
    const spanElems = highlightingElement.querySelectorAll('span');
    for (const span of spanElems) {
      // It is a highlighted span
      if (span.style.backgroundColor === this.rgbHighlightColor) {
        span.style.removeProperty('background-color');
        span.classList.add('snippet-highlighted');
        span.classList.add(highlightActionIdentifier);
      }
    }

    this.config.highlightedElementsCommonIdentifier = highlightActionIdentifier;
    this.config.isOnHighlightedElement = true;
  }

  private getHighlightedSnippetText(highlightingElement) {
    let highlightedSnippetText = '';
    const highlightedSpans
      = highlightingElement.querySelectorAll(`span.snippet-highlighted.${this.config.highlightedElementsCommonIdentifier}`);
    _.each(highlightedSpans, (span: any) => highlightedSnippetText += span.innerText);
    return highlightedSnippetText;
  }

  private updateClickedMenuItemStyle(menuItem) {
    const highlightClass = this.getMenuHighlightClassName();
    const item = menuItem.querySelectorAll('a')[0];
    const element = angular.element(item);
    element.removeClass('unhighlight');
    element.removeClass('highlight');
    item.classList.add(highlightClass);
  }

  private closePopover(menuItem) {
    setTimeout(() => {
      const popover = menuItem.parentNode.parentNode;
      if (popover && popover.parentNode) {
        popover.parentNode.removeChild(popover);
      }
    }, 200);
  }

  public toSharer() {
    return {
      name: this.name,
      render: (text, rawText, refUrl) => this.renderMenuButton(text, rawText, refUrl),
      action: async (event, item) => await this.menuButtonClick(event, item),
    };
  }
}
