'use strict';

import { ConversationDataModerationService } from '../../../../core/dataservices/conversationDataModeration.service';
import { ForumService } from '../../../../core/dataservices/forum.service';
import { ConversationEventsService } from '../../../../core/services/conversationEvents.service';
import { SpinnerService } from '../../../../core/services/spinner.service';
import * as _ from 'lodash';

export class ActivityDataPostTagsController {
  static $inject = ['$scope', '$stateParams', 'conversationDataModerationService', '$mdDialog',
    'spinnerservice', 'logger', 'forumservice', 'conversationEventsService', '$q'];

  constructor(
    private $scope: ng.IScope,
    private $stateParams: ng.ui.IStateParamsService,
    private conversationDataModerationService: ConversationDataModerationService,
    private $mdDialog: ng.material.IDialogService,
    private spinnerservice: SpinnerService,
    private logger: Logger,
    private forumservice: ForumService,
    private conversationEventsService: ConversationEventsService,
    private $q: ng.IQService,
  ) {
  }

  items;
  conversationGuid;
  readOnly;
  _searchText = null;
  _editingTag: boolean = false;
  autocompleteDemoRequireMatch = false;
  private static tags: any;

  async $onInit() {
    await this.ensureTagsLoaded();
  }

  async ensureTagsLoaded() {
    ActivityDataPostTagsController.tags = await this.conversationDataModerationService.getTagSuggestionList('', this.$stateParams.activityGuid);
  }

  async addTag(newTag) {
    this.spinnerservice.show('loading');
    try {
      const existingTagWithSameText = _.find(this.items, (tag) => tag.Text === newTag.Text && tag.TagGuid);
      if (existingTagWithSameText) {
        this.items.pop(newTag);
        return;
      }

      const tag = await this.conversationDataModerationService.addTag({ Text: newTag.Text, ConversationGuid: this.conversationGuid });

      if (tag) {
        newTag.Guid = tag.Guid;
        this.searchText = '';
      } else {
        this.items.splice(this.items.pop(newTag));
      }
      await this.ensureTagsLoaded();

    } catch (error) {
      if (error.status === 400) {
        this.validationError(error.data);
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async deleteTag(tag) {
    this.spinnerservice.show('loading');
    try {
      await this.conversationDataModerationService.deleteTag(tag);
      await this.ensureTagsLoaded();
    } catch (error) {
      if (error.status === 400) {
        this.validationError(error.data);
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  private validationError(error) {
    let message = '';
    _.forEach<any[]>(error.ValidationErrors, (validationError: any) => {
      message += `${validationError.ErrorMessage} `;
    });

    if (message.length > 0) {
      this.logger.error(message);
    }
  }

  transformChip(chip) {
    // If it is an object, it's already a known chip
    if (angular.isObject(chip)) {
      return chip;
    }

    for (const item of this.items) {
      if (chip === item.Text) {
        return null;
      }
    }

    // Otherwise, create a new one
    return { Text: chip };
  }

  querySearch(queryText) {
    const result = _.filter(ActivityDataPostTagsController.tags,
      (value: { Text: string }) => value.Text.indexOf(queryText) !== -1 && !_.find(this.items, (tag) => tag.Text === value.Text));

    if (result && result.length) {
      return this.$q.resolve(result);
    }

    return this.$q.reject(result);
  }

  get searchText() {
    return this._searchText;
  }

  set searchText(value) {
    this._searchText = value;
    if (value) {
      if (!this._editingTag) {
        this.conversationEventsService.discussionChangeStarted(this.$stateParams.activityGuid);
      }
      this._editingTag = true;
    } else {
      this.conversationEventsService.discussionChangeEnded(this.$stateParams.activityGuid);
      this._editingTag = false;
    }
  }
}
