'use strict';

import { ForumController } from './forum.controller';
import { AddForumRoomDialogController } from './addRoomDialog.controller';
import { EditRoomDialogController } from './editRoomDialog.controller';

angular
  .module('insitesApp.squareconfig.mainPages')
  .controller('AddForumRoomDialogController', AddForumRoomDialogController)
  .controller('EditRoomDialogController', EditRoomDialogController)
  .controller('ForumController', ForumController);
