'use strict';

import { IProbeQuestionModel } from '../activityProbeQualBuild/IProbeQuestionModel';
import { MappingService } from './../../../../core/services/mapping.service';
import { SelectedActivityFactory } from './../../../../core/selectedactivity.factory';
import { ServerConstants } from './../../../../core/serverconstants';
import { ActivityDataService, IForumConversation } from './../../../../core/services/activityData.service';
import { ActivityService } from './../../../../core/dataservices/activity.service';
import { ActivityBuildBaseController } from './../common/activityBuildBase.controller';
import { ActivityScoutBuildModel } from './activityScoutBuildModel';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
import * as _ from 'lodash';
import { ActivityBuildModelBase } from '../common/activityBuildBase.model';

export class ActivityScoutBuildController extends ActivityBuildBaseController {
  static $inject = [
    'serverConstants',
    'activityservice',
    'activitydataservice',
    '$stateParams',
    'logger',
    'selectedActivityFactory',
    '$scope',
    '$mdDialog',
    'poboService',
    'mappingService'];

  wizardStep;
  removeLink: () => void;
  validationConstants: ServerConstants['validationConstants'];
  form: ng.IFormController;
  squareActivity: SquareActivityModel;

  saveNotApplicable = false;
  saveCallback = () => super.beforeSave(false);
  resetFormCallback = () => super.resetForm();
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  isPublished: boolean;

  model = new ActivityScoutBuildModel();
  isReadOnly: boolean;
  probeQuestions: IProbeQuestionModel[] = [];
  probeQuestionsAlreadyAnswered: boolean;

  // scoutBUild
  allowedQuestionTypes: number[] = [];
  questionType:number = this.serverConstants.conversationQuestionTypeConstants.imageAndVideo;

  constructor(
    protected serverConstants: ServerConstants,
    protected activityservice: ActivityService,
    protected activitydataservice: ActivityDataService,
    protected $stateParams: ng.ui.IStateParamsService,
    protected logger: Logger,
    protected selectedActivityFactory: SelectedActivityFactory,
    protected $scope: ng.IScope,
    protected $mdDialog: ng.material.IDialogService,
    protected poboService: PoboService,
    protected mappingService: MappingService,
  ) {
    super(serverConstants, activityservice, activitydataservice, $stateParams, logger, selectedActivityFactory, $scope,
      $mdDialog, poboService, mappingService);
    // this.validationConstants = serverConstants.validationConstants;

    // scoutBUild
    const questionTypes = this.serverConstants.conversationQuestionTypeConstants;
    this.allowedQuestionTypes.push(questionTypes.image);
    this.allowedQuestionTypes.push(questionTypes.video);
    this.allowedQuestionTypes.push(questionTypes.imageAndVideo);
    this.questionType = this.serverConstants.conversationQuestionTypeConstants.imageAndVideo;
  }

  $onInit() {
    super.$onInit();
  }

  protected mapModel(data: SquareActivityModel) {
    this.model = this.mappingService.mapSquareActivityModelForScoutActivity(data, this.serverConstants.conversationFocusTypeConstants.text);
  }

  $loadData(data: SquareActivityModel) {
    this.model = new ActivityScoutBuildModel();
    super.$loadData(data);
  }

  // eslint-disable-next-line no-unused-vars
  protected additionalConditionForCompletedSteps(model: ActivityBuildModelBase) {
    return true; // no other additional condition for tis type
  }

  $wizardIsValid() {
    const isScoutFirstQuestionRequired = _.head(this.model.probeQuestions).answerRequired;
    return super.$wizardIsValid() && isScoutFirstQuestionRequired;
  }

  async updateActivityBuild(reload: boolean, root: this) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }

    const conversation: IForumConversation = this.mappingService.mapActivityBuildModelToForumConversation(root.model);
    conversation.ActivityGuid = root.$stateParams.activityGuid;

    const dataActivityQual = {
      ActivityGuid: root.$stateParams.activityGuid,
      ConversationGuid: '',
      Title: root.model.title,
      Description: root.model.description,
      IsOneByOne: root.model.isOneByOne,
      ConversationFocusType: root.model.conversationFocusType,
    };

    let conversationGuid = '';
    try {
      ({ ConversationGuid: conversationGuid } = await root.activitydataservice
        .saveConversation(
          conversation,
          root.model.newAttachmentFiles,
          root.probeQuestions));
    } catch (error) {
      if (error.data && error.data.ValidationErrors && error.data.ValidationErrors[0] && error.data.ValidationErrors[0].PropertyName
        && error.data.ValidationErrors[0].PropertyName === 'ProbeQuestions') {
        // Reload the data for this step and show that probe questions can't be changed anymore.
        root.$loadData(root.squareActivity);
        root.probeQuestionsAlreadyAnswered = true;
      }
      // Rethrow error so that the wizard doesn't go to the next step.
      throw error;
    }

    dataActivityQual.ConversationGuid = conversationGuid;
    root.model.conversationGuid = dataActivityQual.ConversationGuid;
    await root.activityservice.updateActivityQualBuild(dataActivityQual);

    const response = await root.activityservice.selectActivityQualResearch(root.$stateParams.activityGuid);
    root.squareActivity = response.data;
    root.squareActivity.isChildActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.child;
    root.squareActivity.isParentActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.parent;
    if (response.data) {
      root.logger.success('Activity build successfully updated');
    }
    root.$loadData(response.data);
    root.form.$setPristine();
  }
}
