'use strict';

import { ServerConstants } from '../serverconstants';
import {
  QualConversationExportParams,
  UserQualConversationsExportParams,
  ConversationsExportParams,
  ConfigurationForExportType,
  IConfigurationForDiscussionExportType,
  HealthExportParams,
  MembersExportParams,
  ManuallyHandledRewardsExportParams,
} from '../contracts/download.contract';
import { DataExportModalController } from '../../layout/data-export/modal/data-export-modal.controller';
import { AuthService } from '../dataservices/auth.service';

const dialogTemplate = require('../../layout/data-export/modal/data-export-modal.html');

export class ExportConfigService {
  static $inject = ['serverConstants', 'authService', '$mdDialog'];
  constructor(
    private serverConstants: ServerConstants,
    private authService: AuthService,
    private $mdDialog) {
    this.prepareExportConfigs();
  }

  config: { [exportType: number]: ConfigurationForExportType } = {};

  private prepareExportConfigs = () => {
    this.config[this.serverConstants.exportTypeConstants.healthReport] = new ConfigurationForExportType({
      title: 'Export Health data',
      downloadUrl: '/ExportService/ExportHealthReport',
      validateDownloadParams: (params) => params instanceof HealthExportParams,
      options: {
        showSegmentValuesInOneColumnOption: true,
        showIncludeActiveActivitiesOnlyOption: true,
        showIncludeKeySegmentationsOnlyOption: true,
        showExportOnlyFilteredDataOption: true,
        showExportPiiDataOption: true,
      },
    });

    this.config[this.serverConstants.exportTypeConstants.onboardingReport] = new ConfigurationForExportType({
      title: 'Export OnBoarding data',
      downloadUrl: '/ExportService/ExportOnboardingReport',
      validateDownloadParams: (params) => !params,
      options: {
        showSegmentValuesInOneColumnOption: true,
      },
    });

    this.config[this.serverConstants.exportTypeConstants.members] = new ConfigurationForExportType({
      title: 'Export members',
      downloadUrl: '/ExportService/ExportMembers',
      validateDownloadParams: (params) => params instanceof MembersExportParams,
      options: {
        showSegmentValuesInOneColumnOption: true,
        showExportOnlyFilteredDataOption: true,
        showExportPiiDataOption: true,
      },
    });

    this.config[this.serverConstants.exportTypeConstants.qualConversation] = new ConfigurationForExportType({
      title: 'Export discussion',
      downloadUrl: '/ExportService/DownloadQualConversation',
      validateDownloadParams: (params) => params instanceof QualConversationExportParams,
      options: {
        showSegmentValuesInOneColumnOption: true,
        showExportOnlyFilteredDataOption: true,
        showExportPiiDataOption: true,
      },
      showDownloadButton:
        (params: IConfigurationForDiscussionExportType) => !!params.stimuliDownloadAvailable || !!params.textDownloadAvailable,
    });

    this.config[this.serverConstants.exportTypeConstants.qualConversationStimuli] = new ConfigurationForExportType({
      title: 'Export discussion',
      downloadUrl: '/ExportService/DownloadConversationStimuli',
      validateDownloadParams: (params) => params instanceof QualConversationExportParams,
      options: {
        showSegmentValuesInOneColumnOption: true,
      },
      showDownloadButton:
        (params: IConfigurationForDiscussionExportType) => !!params.stimuliDownloadAvailable || !!params.textDownloadAvailable,
    });

    this.config[this.serverConstants.exportTypeConstants.powerpointOneColumn] = new ConfigurationForExportType({
      title: 'Export discussion',
      downloadUrl: '/ExportService/DownloadConversationPowerpoint',
      validateDownloadParams: (params) => params instanceof QualConversationExportParams,
      options: {},
      showDownloadButton:
        (params: IConfigurationForDiscussionExportType) => !!params.stimuliDownloadAvailable || !!params.textDownloadAvailable,
    });

    this.config[this.serverConstants.exportTypeConstants.powerpointWorkshopDeck] = new ConfigurationForExportType({
      title: 'Export discussion',
      downloadUrl: '/ExportService/DownloadConversationPowerpoint',
      validateDownloadParams: (params) => params instanceof QualConversationExportParams,
      options: {},
      showDownloadButton:
        (params: IConfigurationForDiscussionExportType) => !!params.stimuliDownloadAvailable || !!params.textDownloadAvailable,
    });

    this.config[this.serverConstants.exportTypeConstants.userQualConversations] = new ConfigurationForExportType({
      title: 'Export Member discussions',
      downloadUrl: '/ExportService/DownloadUserQualConversations',
      validateDownloadParams: (params) => params instanceof UserQualConversationsExportParams,
      options: {
        showSegmentValuesInOneColumnOption: true,
      },
      showDownloadButton:
        (params: IConfigurationForDiscussionExportType) => !!params.stimuliDownloadAvailable || !!params.textDownloadAvailable,
    });

    this.config[this.serverConstants.exportTypeConstants.userQualConversationsStimuli] = new ConfigurationForExportType({
      title: 'Export Member discussions',
      downloadUrl: '/ExportService/DownloadUserQualConversationsStimuli',
      validateDownloadParams: (params) => params instanceof UserQualConversationsExportParams,
      options: {
        showSegmentValuesInOneColumnOption: true,
      },
      showDownloadButton:
        (params: IConfigurationForDiscussionExportType) => !!params.stimuliDownloadAvailable || !!params.textDownloadAvailable,
    });

    this.config[this.serverConstants.exportTypeConstants.conversation] = new ConfigurationForExportType({
      title: 'Export conversation',
      downloadUrl: '/ExportService/DownloadConversation',
      validateDownloadParams: (params) => params instanceof ConversationsExportParams,
      options: {
        showSegmentValuesInOneColumnOption: true,
      },
    });

    this.config[this.serverConstants.exportTypeConstants.savingUpRewards] = new ConfigurationForExportType({
      title: 'Export Saving Up rewards',
      downloadUrl: '/ExportService/ExportSavingUpRewards',
      validateDownloadParams: (params) => !params,
      options: {
        showSegmentValuesInOneColumnOption: true,
        showIncludeKeySegmentationsOnlyOption: true,
        showIncludeActiveActivitiesOnlyOption: true,
        showExportPiiDataOption: true,
      },
    });

    this.config[this.serverConstants.exportTypeConstants.manuallyHandledRewards] = new ConfigurationForExportType({
      title: 'Export Manually Handled Rewards',
      downloadUrl: '/ExportService/ExportManuallyHandledRewards',
      validateDownloadParams: (params) => params instanceof ManuallyHandledRewardsExportParams,
      options: {
        showSegmentValuesInOneColumnOption: false,
        showExportOnlyFilteredDataOption: true,
      },
    });
  };

  getExportConfig(exportType: number): ConfigurationForExportType {
    const exportConfig = this.config[exportType];
    if (!exportConfig) {
      throw new Error(`Export type ${exportType} has no configuration option set up`);
    }

    return exportConfig;
  }

  async showModal(exportType: number, params: any) {
    if (!exportType) {
      throw new Error('exportType should be provided');
    }
    const exportConfig = this.getExportConfig(exportType);
    const isValid = exportConfig.validateDownloadParams(params);
    if (!isValid) {
      throw new Error('params is not valid for this export type');
    }

    // Download doesn't trigger state transition or api calls so the token is not validated
    // So we need to manually validate the token
    // In case the page is open untouched for a longer period, the token may be expired
    await this.authService.validateToken();
    // This.setCookieForDownload();

    return this.$mdDialog.show({
      controllerAs: 'dvm',
      controller: DataExportModalController,
      templateUrl: dialogTemplate,
      clickOutsideToClose: true,
      locals: {
        exportType,
        downloadParams: params,
      },
    });
  }

}
