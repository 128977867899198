'use strict';

import { Pagination } from '../../core/models/pagination';
import { ServerConstants } from '../../core/serverconstants';
import { SpinnerService } from '../../core/services/spinner.service';
import { PinnedItemsService } from '../../core/services/pinned-items.service';
import { ProgramService } from '../../core/dataservices/program.service';
import { SelectedProgramFactory } from '../../core/selectedprogram.factory';
import { HelperService } from '../../core/services/helper.service';
import * as _ from 'lodash';
import { SearchService } from '../../core/dataservices/search.service';
import { AuthService } from '../../core/dataservices/auth.service';
import { JobService } from 'src/app/core/jobs/job.service';

export class ClientProgramsController {
  constructor(
    private $q,
    private logger: Logger,
    private selectedProgramFactory: SelectedProgramFactory,
    private programservice: ProgramService,
    private helperservice: HelperService,
    private pinnedItemsService: PinnedItemsService,
    private $state,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
    private spinnerservice: SpinnerService,
    private $mdDialog: ng.material.IDialogService,
    private searchService: SearchService,
    private authService: AuthService,
    private jobservice: JobService,
    private __env: Environment,
  ) { }
  static $inject = ['$q', 'logger', 'selectedProgramFactory', 'programservice', 'helperservice', 'pinnedItemsService', '$state',
    '$stateParams', 'serverConstants', 'spinnerservice', '$mdDialog', 'searchservice', 'authService', 'jobservice', '__env'];
  programs = [];
  filter = '';
  programType = this.serverConstants.searchTypeConstants.program;
  addProgramValidations = [
    {
      name: 'unique',
      isAsync: false,
      errorMessage: 'A program with this name already exists.',
      validate: (modelValue) => this.isProgramNameUnique(modelValue),
    },
  ];
  newProgramName;
  promise;
  pagination: IPagination = new Pagination();
  isSquareOnly = false;

  $onInit() {
    this.isSquareOnly = this.authService.isSquareOnly();
    this.activate();
  }

  // //////////////

  activate() {
    const promises = [this.getClientPrograms()];
    this.$q.all(promises)
      .then(() => {
        this.logger.info('Client programs loaded');
      });
  }

  getClientPrograms() {
    const promise = this.programservice.getClientPrograms();

    // Bind the promise to the data table so it can display a loading bar
    this.promise = promise;

    promise.then((response) => {
      const data = response;

      this.programs = _.map(data.List, (item) => ({
        ...item,
        JobId: item.JobId ? _.orderBy(item.JobId.split(','), (inner) => parseInt(inner.substring(1), 10)).reverse() : [],
      }));
    });
  }

  search(filter) {
    return this.helperservice.search(filter, this.filter);
  }

  async createProgram(secondTime?: boolean) {
    this.spinnerservice.show('loading');
    try {
      const createResult = await this.programservice.createProgram(
        this.$stateParams.clientGuid, this.newProgramName);
      if (!secondTime && createResult.JobId && createResult.JobId !== '') {
        // there is a job creating client database, we need to display the job dialog and to invoke
        // program creation again if it succeeds
        this.spinnerservice.hide('loading');
        await this.jobservice.showJobProgressDialog(createResult.JobId, 'Creating client database');
        const jobDetails = await this.jobservice.getJobDetails(createResult.JobId);
        if (jobDetails.State === 'Succeeded') {
          await this.createProgram(true);
        } else {
          this.logger.error('Failed to create client database');
        }
      } else if (createResult.Id > 0) {
        this.goToProgram(createResult);
        this.logger.success('Program created successfully');
      } else {
        this.logger.error('Failed to create program');
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  goToProgram(program) {
    this.$state.go('root.programsquares', { clientGuid: this.$stateParams.clientGuid, programGuid: program.Guid });
  }

  isProgramNameUnique(name) {
    let result = true;
    for (const program of this.programs) {
      if (program.Name && program.Name.toLowerCase() === name.toLowerCase()) {
        result = false;
        break;
      }
    }
    return result;
  }

  isPinned(program) {
    return this.pinnedItemsService.isPinned(program.Guid);
  }

  togglePin(program) {
    this.pinnedItemsService.toggleProgramPin(this.__env.commandApiUrl, program.Guid, this.$stateParams.clientGuid);
  }

  async deleteProgram(program) {
    await this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Delete program')
        .text(`Are you sure you want to delete this Program ${program.Name}?`)
        .ok('Yes')
        .cancel('No'),
    );

    if (program.Guid !== undefined && program.Guid !== this.serverConstants.validationConstants.emptyGuid) {
      try {
        this.spinnerservice.show('loading');
        await this.programservice.deleteProgram(program.Guid);
        this.logger.success('Program successfully deleted');
        _.remove(this.programs, program);
      } catch (error) {
        if (error.status === 400) {
          const data = error.data;
          let errorMessage = '';
          _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
            errorMessage += `${validationError.ErrorMessage} `;
          });
          if (errorMessage.length > 0) {
            this.logger.error(errorMessage);
          }
        }
      } finally {
        this.spinnerservice.hide('loading');
        this.searchService.invalidate();
      }
    }
  }
}
