'use strict';

import { NewManualRewardController } from './newManualRewards/newManualReward.controller';
import { ManualRewardDialogController } from './manualRewardDialog/manualRewardDialog.controller';
import { IncentiveService } from './../../../core/dataservices/incentive.service';
import { HelperService } from '../../../core/services/helper.service';
import { Pagination } from '../../../core/models/pagination';
import * as _ from 'lodash';
import { FeatureService } from '../../../core/dataservices/feature.service';
import { ServerConstants } from '../../../core/serverconstants';

const manualRewardDialogTemplateUrl = require('./manualRewardDialog/manualRewardDialog.html');
const newManualRewardTemplateUrl = require('./newManualRewards/newManualReward.html');

export class ManualRewardsController {

  static $inject = ['$mdDialog', 'serverConstants', 'incentiveservice', 'spinnerservice',
    'helperservice', '$rootScope', 'featureservice', '$q'];
  pagination: IPagination = new Pagination();
  filter;
  newManualRewardName;
  manualRaffles;
  isSaving;
  listener;
  isSavingUpEnabled = false;
  nothingFoundLabel = 'Oops, no manual rewards are created yet. Click the button above to create a new reward.';
  searchPlaceholderLabel = 'Search rewards';
  order = '-DateCreated'; // Default way to sort the table

  constructor(
    private $mdDialog,
    private serverConstants: ServerConstants,
    private incentiveservice: IncentiveService,
    private spinnervice,
    private helperservice: HelperService,
    private $rootScope: ng.IRootScopeService,
    private featureService: FeatureService,
    private $q: ng.IQService,
  ) {}

  $onInit() {
    if (!this.listener) {
      this.listener = this.$rootScope.$on('rewardsUploaded', () => {
        this.$onInit();
      });
    }
    this.spinnervice.show('loading');
    const isSavingUpEnabledPromise =
      this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards)
        .then((isEnabled) => {
          this.isSavingUpEnabled = isEnabled;
          if(isEnabled){
            this.nothingFoundLabel = 'Oops, no manual point corrections are created yet. Click the button above to create a new point correction.';
            this.searchPlaceholderLabel = 'Search manual correction';
          }
        });

    const getRafflesPromise = this.incentiveservice.getManualRaffles()
      .then((result) => this.manualRaffles = _.map(result.List, (s) => {
        s.TotalRewardValue = _.sum(s.TotalRewards.map((t) => t.Value));
        return s;
      }));

    this.$q.all([isSavingUpEnabledPromise, getRafflesPromise])
      .finally(() => {
        this.spinnervice.hide('loading');
      });
  }

  $onDestroy() {
    this.listener();
  }

  getRaffleStatus(status: number) {
    switch (status) {
      case this.serverConstants.raffleStatusConstants.winnersPartial:
        return 'Not all approved';
      case this.serverConstants.raffleStatusConstants.winnersNotDrawn:
        return 'Not approved';
      case this.serverConstants.raffleStatusConstants.winnersDrawn:
        return 'Approved';
      default:
        return '';
    }
  }

  createManualReward() {
    this.$mdDialog.show({
      controller: NewManualRewardController,
      controllerAs: 'vm',
      templateUrl: newManualRewardTemplateUrl,
      clickOutsideToClose: true,
      locals: {
        name: this.newManualRewardName,
      },
    });
  }

  openManualReward(manualRaffle) {
    this.$mdDialog.show({
      controller: ManualRewardDialogController,
      controllerAs: 'vm',
      templateUrl: manualRewardDialogTemplateUrl,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        reward: manualRaffle,
        isSaving: this.isSaving,
      },
      onRemoving: () => {
        this.$onInit();
      },
    });
  }

  search(filter) {
    return this.helperservice.search(filter, this.filter, (type, val) => val);
  }

  winnersLabel = '#Participants';

  get rewardValueLabel() {
    return this.isSavingUpEnabled ? 'Total number of points' : 'Total reward value';
  }

  rewardStatusLabel = 'Approval status';

  get newRewardLabel() {
    return this.isSavingUpEnabled ? 'New point correction' : 'New manual reward';
  }

  get newRewardInputLabel() {
    return this.isSavingUpEnabled ? 'New point correction name' : 'New manual reward name';
  }

  get pageTitle() {
    return this.isSavingUpEnabled ? 'Manual point corrections' : 'Manual Rewards';
  }
}
