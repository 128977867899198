'use strict';

import { ExportConfigService } from '../../core/services/exportconfig.service';
import { ConfigurationForExportType } from '../../core/contracts/download.contract';

export class DataExportController implements ng.IOnInit {
  static $inject = [
    'exportconfigservice',
  ];
  constructor(
    private exportconfigservice: ExportConfigService,
  ) {
  }

  params;
  exportType: number;
  isDownloadDisabled: boolean = false;
  exportConfig: ConfigurationForExportType;

  $onInit(): void {
    if (!this.exportType) {
      throw new Error('exportType should be provided');
    }
    this.exportConfig = this.exportconfigservice.getExportConfig(this.exportType);
    const isValid = this.exportConfig.validateDownloadParams(this.params);
    if (!isValid) {
      throw new Error('params is not valid for this export type');
    }
  }

  async onClick() {
    // Open modal to choose download text or visual
    this.isDownloadDisabled = true;
    try {
      await this.exportconfigservice.showModal(this.exportType, this.params);
    } finally {
      this.isDownloadDisabled = false;
    }
  }

  get showButton(): boolean {
    return this.exportConfig.showDownloadButton(this.params);
  }

}
