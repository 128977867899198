import { IscWizardStepController } from './isc-wizard-step.controller';
import { UiModule } from '../../index';
var wizardStepTemplate = "<div ng-show=\"vm.show()\" flex=\"initial\" layout=\"column\">\n  <div ng-class=\"{'layout-row': vm.tooltipText}\" class=\"progress-container-text\">\n    <h2 class=\"h4\">{{vm.position}}. {{vm.formattedDescription()}}</h2>\n    <span ng-if=\"vm.tooltipText\" class=\"tooltip-container tooltip-title\">\n      <md-tooltip md-direction=\"right\">\n        {{vm.tooltipText}}\n      </md-tooltip>\n      <i class=\"fa fa-circle-info\" aria-hidden=\"true\"></i>\n    </span>\n  </div>\n\n  <div class=\"wizard-step-content\" flex=\"initial\">\n    <div ng-transclude ng-show=\"vm.currentStepIndex===-1\"></div>\n    <div ng-transclude=\"substeps\"></div>\n  </div>\n</div>\n";
UiModule
    .component('iscWizardStep', {
    template: wizardStepTemplate,
    controller: IscWizardStepController,
    controllerAs: 'vm',
    transclude: {
        substeps: '?iscWizardSubstep',
    },
    require: {
        wizardComponent: '^^iscWizard',
    },
    bindings: {
        caption: '@',
        description: '@',
        order: '<',
        tooltipText: '@',
    },
});
