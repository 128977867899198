'use strict';

import { SquareActivitiesController } from './squareActivities.controller';

const squareActivitiesTemplate = require('./squareActivities.html');

export const squareActivitiesComponent: ng.IComponentOptions = {
  controller: SquareActivitiesController,
  templateUrl: squareActivitiesTemplate,
  controllerAs: 'vm',
};
