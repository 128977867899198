'use strict';

import { PageService } from '../dataservices/page.service';

export class FaqCategoryNameValidator implements ng.IDirective {
  constructor(
    private $q: ng.IQService,
    private pageService: PageService,
    private logger: Logger) { }

  restrict = 'A';

  link(scope, element, attrs) {
    const inputElem = element.find('input');
    if (!inputElem) {
      this.logger.error('No child input element was found');
      return;
    }
    const ngModel = inputElem.controller('ngModel');
    if (!ngModel) {
      this.logger.error('Input element doesn\'t have a ngModel specified');
      return;
    }

    ngModel.$asyncValidators.uniqueFaqCategoryName = (modelValue, viewValue) => {
      let faqCategoryGuid;
      let faqCategoryType;
      if (Object.prototype.hasOwnProperty.call(attrs,'faqcategoryguid')) {
        faqCategoryGuid = attrs.faqcategoryguid;
      }
      if (Object.prototype.hasOwnProperty.call(attrs,'faqcategorytype')) {
        faqCategoryType = attrs.faqcategorytype;
      }
      const faqCategory = {
        name: encodeURIComponent(viewValue),
        guid: faqCategoryGuid,
        categorytype: faqCategoryType,
      };

      return this.pageService.isFaqCategoryNameUnique(faqCategory).then((response) => {
        if (response.data === false) {
          return this.$q.reject();
        }
        return response.data;
      });
    };
  }
}
