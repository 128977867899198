'use strict';

import { IUserNotification } from './../../core/services/notifications.contracts';
import { UserNotificationService } from './../../core/dataservices/usernotification.service';
import { ConversationService } from '../../core/services/conversation.service';
import { ServerConstants } from '../../core/serverconstants';
export class UserNotificationController {
  static $inject = ['$state', 'serverConstants', 'userNotificationService', '$stateParams', 'conversationService', 'selectedSquareFactory'];

  constructor(
    private $state: ng.ui.IStateService,
    private serverConstants: ServerConstants,
    private userNotificationService: UserNotificationService,
    private $stateParams: ng.ui.IStateParamsService,
    private conversationService: ConversationService,

  ) { }

  isMobile;
  menuOffset = '10 71';
  notificationTypes;
  channelTypes;
  notificationStatuses;
  viewedNotifications = [];
  borderedIcon;

  get show() {
    return this.$stateParams.squareGuid;
  }

  get notifications() {
    return this.userNotificationService.userNotifications;
  }

  $onInit() {
    this.borderedIcon = this.borderedIcon || false;
    this.notificationTypes = this.serverConstants.notificationTypeConstants;
    this.channelTypes = this.serverConstants.channelTypeConstants;
    this.menuOffset = this.isMobile ? '20 70' : this.menuOffset;
  }
  clearAllNotifications() {
    this.userNotificationService.clearAllNotifications();
  }
  // eslint-disable-next-line complexity
  async notificationClicked(notification: IUserNotification) {
    this.userNotificationService.markNotificationRead(notification.Guid, notification.RelatedNotificationGuids);
    const notificationTargetGuid = await this.userNotificationService.resolveNotificationTargetGuid(notification);

    switch (notification.NotificationType) {
      // Client/:clientGuid/program/:programGuid/square/:squareGuid/activity/:activityGuid/health
      case this.notificationTypes.quantResearchAlmostClosed: {
        this.$state.go('root.square.activityhealth', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid: notificationTargetGuid,
        });
        break;
      }
      // Client/:clientGuid/program/:programGuid/square/:squareGuid/activity/:activityGuid/data
      case this.notificationTypes.qualResearchAlmostClosed: {
        this.$state.go('root.square.activitydata', {
          clientGuid: this.$stateParams.clientGuid,
          programGuid: this.$stateParams.programGuid,
          squareGuid: this.$stateParams.squareGuid,
          activityGuid: notificationTargetGuid,
        });
        break;
      }
      case this.notificationTypes.discussionMention:
      case this.notificationTypes.discussionReply:
      case this.notificationTypes.discussionLike:
      case this.notificationTypes.discussionMentions:
      case this.notificationTypes.discussionReplies:
      case this.notificationTypes.discussionLikes:
      case this.notificationTypes.discussionMentionReplyLike:
      case this.notificationTypes.discussionMentionRepliesLike:
      case this.notificationTypes.discussionMentionReplyLikes:
      case this.notificationTypes.discussionMentionRepliesLikes:
      case this.notificationTypes.discussionMentionsReplyLike:
      case this.notificationTypes.discussionMentionsRepliesLike:
      case this.notificationTypes.discussionMentionsReplyLikes:
      case this.notificationTypes.discussionMentionsRepliesLikes:
      case this.notificationTypes.discussionMentionReply:
      case this.notificationTypes.discussionMentionReplies:
      case this.notificationTypes.discussionMentionsReply:
      case this.notificationTypes.discussionMentionsReplies:
      case this.notificationTypes.discussionMentionLike:
      case this.notificationTypes.discussionMentionLikes:
      case this.notificationTypes.discussionMentionsLike:
      case this.notificationTypes.discussionMentionsLikes:
      case this.notificationTypes.discussionReplyLike:
      case this.notificationTypes.discussionRepliesLike:
      case this.notificationTypes.discussionReplyLikes:
      case this.notificationTypes.discussionRepliesLikes: {
        await this.conversationService.jumpToReplyQual(notification.TargetGuid, notification.ActivityGuid, notification.IsDiscussionNew);
        break;
      }
      case this.notificationTypes.forumMention:
      case this.notificationTypes.forumReply:
      case this.notificationTypes.forumLike:
      case this.notificationTypes.forumMentions:
      case this.notificationTypes.forumReplies:
      case this.notificationTypes.forumLikes:
      case this.notificationTypes.forumMentionReplyLike:
      case this.notificationTypes.forumMentionRepliesLike:
      case this.notificationTypes.forumMentionReplyLikes:
      case this.notificationTypes.forumMentionRepliesLikes:
      case this.notificationTypes.forumMentionsReplyLike:
      case this.notificationTypes.forumMentionsRepliesLike:
      case this.notificationTypes.forumMentionsReplyLikes:
      case this.notificationTypes.forumMentionsRepliesLikes:
      case this.notificationTypes.forumMentionReply:
      case this.notificationTypes.forumMentionReplies:
      case this.notificationTypes.forumMentionsReply:
      case this.notificationTypes.forumMentionsReplies:
      case this.notificationTypes.forumMentionLike:
      case this.notificationTypes.forumMentionLikes:
      case this.notificationTypes.forumMentionsLike:
      case this.notificationTypes.forumMentionsLikes:
      case this.notificationTypes.forumReplyLike:
      case this.notificationTypes.forumRepliesLike:
      case this.notificationTypes.forumReplyLikes:
      case this.notificationTypes.forumRepliesLikes: {
        await this.conversationService.jumpToReplyForum(notification.TargetGuid);
        break;
      }
      case this.notificationTypes.newLoungeTopic: {
        await this.conversationService.jumpToConsumerReplyForum(notification.TargetGuid, this.serverConstants.redirectForumTypeConstants.singleTopic);
        break;
      }
      case this.notificationTypes.singleUserSingleRoomMultipleTopicLoungeTopics:
      case this.notificationTypes.multipleUsersMultipleTopicsSingleRoomLoungeTopics: {
        await this.conversationService.jumpToConsumerReplyForum(notification.TargetGuid, this.serverConstants.redirectForumTypeConstants.newTopics) ;
        break;
      }
      case this.notificationTypes.singleUserMultipleTopicMultipleRoomsLoungeTopics:
      case this.notificationTypes.multipleUsersMultipleTopicsMultipleRoomsLoungeTopics:
      case this.notificationTypes.customAppNotification: {
        window.open(notification.RedirectToUrl, '_blank');
        break;
      }
    }
  }
  async notificationMarkAsReadClicked(notification: IUserNotification, event: JQueryEventObject) {
    event.stopPropagation();
    this.userNotificationService.markNotificationRead(notification.Guid, notification.RelatedNotificationGuids);
  }
}
