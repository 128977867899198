'use strict';

import { TransitionService } from '@uirouter/angularjs';
import { ConfigureLuxon } from 'isc-ui';
import { Settings } from 'luxon';
import { RouterHelper } from './blocks/router/router-helper';
import { AuthService } from './core/dataservices/auth.service';
import { CurrentUserService } from './core/dataservices/currentUser.service';

const rootTemplateUrl = require('./root.html');

const routeConfig = (
  routerHelper: RouterHelper,
  notifications,
  authService: AuthService,
  $transitions: TransitionService,
  currentUserService: CurrentUserService,
) => {
  routerHelper.configureStates([{
    state: 'root',
    config: {
      abstract: true,
      url: '/',
      templateUrl: rootTemplateUrl,
      resolve: {
        userExists: () => {
          authService.init();
          notifications.init();

          Settings.defaultLocale = 'en-US';
          ConfigureLuxon('en-US', 'system');
        },
      },
    },
  }]);

  // When landing on a page that is not square related remove the impersonate
  $transitions.onBefore({}, (transition) => {
    if(!transition.to().name.startsWith('root.square.')) {
      authService.impersonate = undefined;
    } else {
      // when landing on a square page set the fallback of the impersonating in the session storage
      const impersonate = localStorage.getItem('Impersonating');
      if(impersonate) {
      // no need to stringify as the one in local is already in the correct format
        sessionStorage.setItem('Impersonating', impersonate);
      }
    }
  });

  const deregisterTermsTransition = $transitions.onBefore({}, async (transition) => {
    if ((transition.targetState().state() as any).allowAnonymous) {
      return;
    }

    const agreement = await currentUserService.getAgreement();

    if (agreement.agreed) {
      deregisterTermsTransition();
    } else if(transition.to().name !== 'root.splash-agreement') {
      // the url is in the form of: https://adminurl/client/guid/program/guid/square/guid/researcherlogin
      await transition.router.stateService.transitionTo('root.splash-agreement', { returnUri: window.location.href });
      return false;
    }
    return true;
  });

};

routeConfig.$inject = ['routerHelper', 'notifications', 'authService', '$transitions', 'currentUserService'];

export default routeConfig;
