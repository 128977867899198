'use strict';

import { AuthService } from '../dataservices/auth.service';
import { CurrentUserService } from '../dataservices/currentUser.service';
import { SquareService } from '../dataservices/square.service';
export class PoboService {
  static $inject = ['$mdDialog', 'currentUserService', 'authService', 'squareservice', '$stateParams'];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private squareservice: SquareService,
    private $stateParams: ng.ui.IStateParamsService,
  ) {
  }

  lastCurrentUser;
  lastOriginalUser;
  isDIYSquare;

  async checkPoboConfirmation(conversationUsername, newConversation: boolean = false): Promise<string> {
    const data = await this.squareservice.getSquareDetails(this.$stateParams.squareGuid);
    this.isDIYSquare = data.data.Detail.JobIds.some((jobId) => jobId.IsSelfServe);

    if (this.isDIYSquare || conversationUsername == null) {
      return this.currentUserService.userName;
    }

    // get the actual current username (pobo or not)
    let currentUserName = this.authService.userName;
    if (this.authService.isImpersonating) {
      if (!this.currentUserService.impersonateUserList
        || !this.currentUserService.impersonateUserList.length) {
        await this.currentUserService.getImpersonateUserList();
      }
      const impersonateUser = this.currentUserService.impersonateUserList
        .find((i) => i.Guid === this.authService.impersonate.SquareParticipantGuid);
      if (impersonateUser) {
        currentUserName = impersonateUser.Username;
      }
    }

    const shouldShowDialog = conversationUsername !== currentUserName;
    if (shouldShowDialog && this.authService.isModeratorUserLogin) {
      // at first login, moderators do not have username,
      // so, if currentUserName is an empty string, we do not have pobo
      if (currentUserName === '') {
        return conversationUsername;
      }
    }

    if (shouldShowDialog) {
      let dialogTitle = 'Repost as different member';
      let dialogText = `You are about to repost as <b>${currentUserName}</b>, while the initial post was submitted by <b>${conversationUsername}</b>.
      This change will be visible to all participants. \n\n Are you sure you want to proceed?`;
      let dialogButton = 'Repost';

      // If it's the first time for a new activity on the specify step we need to show another message,
      // After that the conversation is created so we show the default message
      // At Lounge in the admin we can't write a new post right now but if we could we are safe and we will show this message
      if (newConversation) {
        dialogTitle = 'Post as a different member';
        dialogText = `You are about to post as <b>${currentUserName}</b>. This will be visible to all participants. Are you sure you want to proceed?`;
        dialogButton = 'Post';
      }

      await this.$mdDialog.show(
        this.$mdDialog.iscConfirm()
          .title(dialogTitle)
          .text(dialogText)
          .ok(dialogButton)
          .cancel('Cancel'),
      );
      return currentUserName;
    }
    return conversationUsername;
  }
}
