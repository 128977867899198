'use strict';

import { ITargetingQuotaItem } from '../../../../core/contracts/targeting.contracts';

export class TargetingQuotaTableController {

  constructor() { }

  model: ITargetingQuotaItem[];
  valueChanged: boolean;
  randomlySelected: number;
  isDisabled: boolean;

  get sortedModel() {
    return this.model.sort((a, b) => {
      // Ignore cases
      const nameA = a.segmentName.toLowerCase();
      const nameB = b.segmentName.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  getYesRow(item: ITargetingQuotaItem) {
    return item.targetingQuotaItemRows.find((row) => row.value);
  }

  getNoRow(item: ITargetingQuotaItem) {
    return item.targetingQuotaItemRows.find((row) => !row.value);
  }
}
