'use strict';

import { ServerConstants } from './../../../../../core/serverconstants';
import * as _ from 'lodash';
import { ActivityEmailController } from './../communicationChannel/emailChannel/activityEmailChannel.controller';
import { CommunicationService } from '../../../../../core/dataservices/communication.service';
import { ActivityService } from '../../../../../core/dataservices/activity.service';
import { PlatformNotificationController } from '../communicationChannel/platformNotificationChannel/platformNotificationChannel.controller';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { SequencedActivityInfoMessageHelper } from '../../sequencedActivity/sequencedActivityInfoMessageHelper';
import { IChannelPublishOption } from '../../../../../core/dataservices/dataservice.contracts';
import { DateTime } from 'luxon';

export class CommunicationPublishController {
  static $inject = ['communicationservice', '$stateParams', '$state', 'logger', 'serverConstants',
    '$mdDialog', 'spinnerservice', 'activityservice', '$scope'];

  constructor(
    private communicationservice: CommunicationService,
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private logger: Logger,
    private serverConstants: ServerConstants,
    private $mdDialog: ng.material.IDialogService,
    private spinnerservice: SpinnerService,
    private activityService: ActivityService,
    private $scope: ng.IScope,
  ) {
  }
  hasErrors = false;
  channelType;
  removeLink;
  wizardStep;
  wizard;
  channelTypeConstants = this.serverConstants.channelTypeConstants;
  communicationChannelConstants = this.serverConstants.communicationChannelConstants;
  engagementData;
  isEdit = false;
  isEngagement = false;
  isPlatformNotification = false;
  isEmailChannel = false;
  isTestMailButtonDisabled = false;

  channelsStep;
  researchActivityEmailToDisplay = [];
  researchActivityEmailComponent = [];
  targetingStep;
  engagementComponent = [];
  publishOptions = [
    { Id: this.serverConstants.publishResearchActivityOptionConstants.hardLaunch, Name: 'All in target' },
    { Id: this.serverConstants.publishResearchActivityOptionConstants.softLaunch, Name: 'Soft launch' },
  ];
  publishForm: ng.IFormController;
  rewards = [];
  communication;
  isPublished: boolean = false;
  isReadOnly;
  infoTextProvider: SequencedActivityInfoMessageHelper;

  async $onInit() {
    this.removeLink = this.wizardStep.linkComponent('publish', this);
    this.researchActivityEmailComponent = [];
    if (this.$stateParams.communicationGuid) {
      this.isEdit = true;
    }
    const result = await this.wizardStep.resolveComponent(['channels', 'targeting']);
    this.engagementComponent = result;
    this.channelsStep = result[0];
    this.targetingStep = result[1];
    this.$scope.$watch(() => this.communication, async () => {
      if (!this.targetingStep.targeting.Matches
        && this.communication && this.communication.Communication.ActivityGuid) {
        await this.targetingStep.recalculateTargetingMatches(this.communication.Communication.ActivityGuid);
      }
    });
    await this.targetingStep.$wizardStepLoaded();
    this.researchActivityEmailComponent.push(this.targetingStep);
    this.infoTextProvider = new SequencedActivityInfoMessageHelper(this.serverConstants, this.channelsStep, true);
  }

  async $wizardStepLoaded() {
    this.spinnerservice.show('loading');
    try {
      const reminderOrIncomplete = [this.channelTypeConstants.researchActivityReminder, this.channelTypeConstants.researchActivityIncomplete];
      const channels = this.communication.Channels;
      this.isEngagement = this.communication.isEngagement;
      await this.deleteUnselectedChannels();
      await this.copyTargeting();
      this.researchActivityEmailToDisplay = [];
      _.forEach(channels, async (channelContainer: any) => {
        const channel = channelContainer.Channel;
        const channelDef = _.filter(this.channelsStep.allChannels, (c: any) => c.channelType === channel.ChannelType)[0];
        const result = await this.wizardStep.resolveComponent(`channel-${channel.ChannelType}`);
        this.isEmailChannel = channel.ChannelType === this.channelTypeConstants.emailNewsletter
          || channel.ChannelType === this.channelTypeConstants.researchActivityEmail;
        if (this.isEngagement) {
          this.engagementData = result;
          this.engagementData.ChannelName = channelDef.caption;
          this.isPlatformNotification = result instanceof PlatformNotificationController;
        } else {
          // Pushes the previous channels to the queue and overwrites  old ones
          _.remove(this.researchActivityEmailComponent,
            (researchActivityEmailComponentItem) => researchActivityEmailComponentItem.channelType === channel.channelType);
          this.researchActivityEmailComponent.push(result);
          // Build the display/test-mail part
          const tempChannel = {
            guid: '',
            title: '',
            channel: channelDef.caption,
            message: '',
            targeted: this.targetingStep.targeted,
            targetedFrom: this.targetingStep.targetedFrom,
            publishOption: this.serverConstants.publishResearchActivityOptionConstants.hardLaunch,
            softLaunchNumber: 0,
            startDateTime: DateTime.now(),
            sendEmailDelay: '',
            callToActionText: '',
            callToActionUrl: '',
            channelType: channel.ChannelType,
            channelName: channelDef.caption,
            footerText: '',
            isPublished: channel.IsPublished,
          };
          // Copy delay
          if (_.some(reminderOrIncomplete, (item) => item === channel.ChannelType)) {
            tempChannel.sendEmailDelay = result.model.SendEmailDelay;
          }
          // Get publish options for research activity
          if (result instanceof ActivityEmailController) {
            // Sets variables specific to research activity mail type
            tempChannel.guid = result.model.Guid || '';
            tempChannel.publishOption = result.model.PublishOption || this.serverConstants.publishResearchActivityOptionConstants.hardLaunch;
            tempChannel.softLaunchNumber = result.model.SoftLaunchNumber || 0;
          }
          this.isPlatformNotification = result instanceof PlatformNotificationController;
          // Map resolved values
          tempChannel.title = result.model.Title;
          tempChannel.message = result.model.Message;
          tempChannel.startDateTime = result.model.StartDateTime;
          tempChannel.callToActionText = result.model.CallToActionText;
          tempChannel.callToActionUrl = this.isPlatformNotification ? result.model.RedirectToUrl : result.model.CallToActionUrl;
          tempChannel.footerText = result.saveFooterText;
          tempChannel.targeted = result.communication.Targeting.Targeted;
          tempChannel.targetedFrom = result.communication.Targeting.TargetedFrom;
          this.researchActivityEmailToDisplay.push(tempChannel);
        }
      });
      await this.activityService.getActivityDetailsForMessage(this.channelsStep.selectedActivityGuid).then((result) => {
        this.rewards = result.data.RaffleDetailItems;
      });
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  deleteUnselectedChannels() {
    if (this.isEdit) {
      _.forEach(this.channelsStep.communicationChannelsToDeleteGuids, (channelGuid) => {
        this.communicationservice.deleteCommunicationMessage(channelGuid, this.communication.Communication.Guid).then(() => {
          _.remove(this.channelsStep.communicationChannelsToDeleteGuids, (guid) => guid === channelGuid);
        });
      });
    }
  }

  async copyTargeting() {
    this.targetingStep.targeted = this.targetingStep.targeting.Targeted;
    this.targetingStep.targetedFrom = this.targetingStep.targeting.TargetedFrom;
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardNextLabel() {
    return 'Publish';
  }

  $wizardNextDescription() {
    if (this.isReadOnly) {
      return '';
    }

    if (_.every(this.communication.Channels, (c: any) => c.Channel.IsPublished)) {
      return '';
    }
    if (_.every(this.communication.Channels, (c: any) => !c.Channel.IsPublished)) {
      return this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }

    const lastChannel: any = _.last(this.communication.Channels);
    if (lastChannel.Channel.IsPublished) {
      return ''; // Space to overrule parent message
    }
    return this.serverConstants.squareConstants.wizardNotPublishedStatus;
  }

  $wizardStepIsCompleted() {
    if (this.communication !== undefined && this.communication.Communication !== undefined) {
      this.isPublished = this.communication.Channels[0] && this.communication.Channels[0].Channel.IsPublished;
    }
    return this.isPublished;
  }

  $wizardIsValid() {
    if (this.publishForm) {
      return !this.publishForm.$invalid;
    }
    return true;
  }

  async $wizardBeforeNext() {
    const thisSquareGuid = this.$stateParams.squareGuid;
    const thisClientGuid = this.$stateParams.clientGuid;
    const thisProgramGuid = this.$stateParams.programGuid;

    this.hasErrors = false;
    await this.publishCommunication();
    if (!this.hasErrors) {
      if (this.channelsStep && this.channelsStep.form) {
        this.channelsStep.form.$setPristine();
      }

      this.$state.go('root.square.communicationparent.overview',
        {
          clientGuid: thisClientGuid,
          programGuid: thisProgramGuid,
          squareGuid: thisSquareGuid,
        });
    }
    this.hasErrors = false;
  }

  async publishCommunication() {
    if (this.isPlatformNotification) {
      if (this.engagementData.hasErrors && this.engagementData.hasErrors === true) {
        this.hasErrors = true;
        if (!this.isReadOnly) {
          this.logger.error('Communication not successfully published');
        } else {
          this.logger.error('Communication not successfully updated');
        }
        return;
      }
    }
    let channelPublishOptions: IChannelPublishOption[] = [];
    _.each(this.researchActivityEmailToDisplay, (option) => {
      if (option.channelType === this.channelTypeConstants.researchActivityEmail) {
        channelPublishOptions = _.concat(channelPublishOptions, {
          Guid: option.guid,
          SoftLaunchNumber: option.softLaunchNumber,
          PublishOption: option.publishOption,
        });
      }
    });
    await this.communicationservice.publishCommunication(this.$stateParams.communicationGuid, channelPublishOptions).then(() => {
      if (!this.isReadOnly) {
        this.logger.success('Communication successfully published.');
        if (Array.isArray(this.communication.Channels) && this.communication.Channels.length > 0) {
          this.communication.Channels[0].Channel.IsPublished = true;
        }
      } else {
        this.logger.success('Communication successfully updated.');
      }
    }, () => {
      if (!this.isReadOnly) {
        this.logger.error('Communication not successfully published');
      } else {
        this.logger.error('Communication not successfully updated');
      }
      this.hasErrors = true;
    });
  }

  async sendTestEmailNewsletter() {
    const emailRequest = {
      ChannelType: this.channelTypeConstants.emailNewsletter,
      CommunicationGuid: this.$stateParams.communicationGuid,
    };

    await this.sendTestMail(emailRequest, emailRequest.ChannelType);
  }

  async sendResearchEmailTest(channelType) {

    // Get selected channel to send test email
    const channelToSend = _.find<any>(this.researchActivityEmailToDisplay, (ch) => ch.channelType === channelType);
    const postData = {
      ActivityGuid: this.channelsStep.selectedActivityGuid,
      ChannelType: channelType,
      Title: channelToSend.title,
      Message: channelToSend.message,
      FooterText: channelToSend.footerText,
      CallToActionText: channelToSend.callToActionText,
      Rewards: this.rewards,
      CommunicationGuid: this.$stateParams.communicationGuid,
    };

    await this.sendTestMail(postData, channelType);
  }

  async sendTestMail(request, channelType) {
    this.isTestMailButtonDisabled = true;

    try {
      switch (channelType) {
        case this.channelTypeConstants.emailNewsletter:
          await this.communicationservice.sendNewsletterTestEmail(request);
          break;
        case this.channelTypeConstants.researchActivityEmail:
        case this.channelTypeConstants.researchActivityReminder:
        case this.channelTypeConstants.researchActivityIncomplete:
          await this.communicationservice.researchActivitySendTestMail(request);
          break;
      }

      // according to #65930
      const mailtitle = 'Email scheduled';
      const mailbody = 'The email has been successfully scheduled. It would reach your inbox soon.';
      this.$mdDialog.show(this.$mdDialog.iscAlert({
        title: mailtitle,
        text: mailbody,
        ok: 'OK',
      }));
    } catch (error) {
      this.logger.error('The email could not be sent');
      this.hasErrors = true;
    } finally {
      this.isTestMailButtonDisabled = false;
    }
  }

  getTargetStringForNonEngagement(channel): string {
    const left = this.targetingStep.targeting.Matches != null ?
      (this.targetingStep.randomlySelectedFromActivity != null
        && this.targetingStep.targeting.Matches > this.targetingStep.randomlySelectedFromActivity
        ? this.targetingStep.randomlySelectedFromActivity : this.targetingStep.targeting.Matches)
      : channel.targeted;
    const right = this.targetingStep.randomlySelectedFromActivity != null
      ? this.targetingStep.randomlySelectedFromActivity : channel.targetedFrom;

    return `${left}/${right}`;
  }
}
