'use strict';

import { SquareService } from '../../../core/dataservices/square.service';
import { ServerConstants } from '../../../core/serverconstants';
import * as _ from 'lodash';

const tabTemplates = {
  contact: require('./tabTemplates/contact.tabtemplate.html'),
  faq: require('./tabTemplates/faq.tabtemplate.html'),
  helpcentercustom: require('./tabTemplates/helpCenterCustom.tabtemplate.html'),
  platformtour: require('./tabTemplates/platformtour.tabtemplate.html'),
};

export class SquareConfigHelpCenterController implements ng.IOnInit {
  static $inject = ['squareservice', 'serverConstants', '$scope'];
  constructor(
    private squareservice: SquareService,
    private serverConstants: ServerConstants,
    private $scope: ng.IScope) {
    this.pageTypeConstants = serverConstants.pageTypeConstants;
    this.area = serverConstants.areaConstants.helpCenter;
  }

  pageTypeConstants;
  area;
  pageTypeDetails;

  $onInit(): void {
    this.pageTypeDetails = [
      {
        Label: 'FAQ',
        syncService: this.squareservice.syncPage('FAQ'),
        required: false,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.faq,
      },
      {
        Label: 'Platform tour',
        syncService: this.squareservice.syncPage('PlatformTour'),
        required: false,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.platformTour,
      },
      {
        Label: 'Contact',
        syncService: this.squareservice.syncPage('Contact'),
        required: true,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.contact,
      },
      {
        Label: 'Custom',
        syncService: this.squareservice.syncPage('HelpCenterCustom'),
        required: false,
        onlyOneAllowed: false,
        pageType: this.pageTypeConstants.helpCenterCustom,
      },
    ];
  }

  getTemplateForPage = (page) => {
    const name = _.findKey(this.pageTypeConstants, (c) => c === page.PageType);

    return tabTemplates[name.toLowerCase()];
  };

  addPage() {
    this.$scope.$broadcast('addPage');
  }
}
