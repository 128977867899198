'use strict';

import { ClientMaintenanceController } from './client-maintenance.controller';
import { clientMaintenanceModule } from './client-maintenance.module';

const clientMaintenanceTemplate = require('./client-maintenance.html');
require('./client-maintenance.scss');

clientMaintenanceModule.component('clientMaintenance', {
  controller: ClientMaintenanceController,
  templateUrl: clientMaintenanceTemplate,
  bindings: {
    goto: '@',
  },
});
