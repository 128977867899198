'use strict';

import './programinfo.module';
import { ProgramInfoComponent } from './programinfo.component';
import routeConfig from './programinfo.route';
import './updateProgramCreditsDialog';

angular
  .module('insitesApp.programinfo')
  .component('programInfo', ProgramInfoComponent)
  .run(routeConfig);
