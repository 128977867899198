'use strict';

import { ActivitySpecifyController } from './activitySpecify.controller';

const template = require('./activitySpecify.html');

export const ActivitySpecifyComponent: ng.IComponentOptions = {
  controller: ActivitySpecifyController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    squareActivity: '=',
    squareCommunication: '=',
    isPublished: '=',
    isReadOnly: '<',
    isSavingUpReward: '<',
  },
  require: {
    wizardStep: '^^iscWizardStep',
    wizard: '^^iscWizard',
  },
};
