'use strict';

import { SplashMessageDetailItem } from 'src/app/core/dataservices/square.service.contracts';
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { MemberService } from 'src/app/core/services/member.service';

export class SplashPageController {
  static $inject = ['$stateParams', 'memberService', 'authService'];

  constructor(private $stateParams: ng.ui.IStateParamsService, private memberService: MemberService, private authService: AuthService) { }

  splashMessage: SplashMessageDetailItem = {
    Id: 0,
    Guid: null,
    SquareGuid: null,
    IsEnabled: null,
    Message: null,
    AgreeLabel: null,
    DisagreeLabel: null,
    Type: null,
  };

  async $onInit() {
    this.splashMessage = await this.memberService.getNewestSplashMessage();
  }

  public async agree() {
    await this.saveSplashAgreement(true);
  }

  public async disagree() {
    await this.saveSplashAgreement(false);
  }

  public async saveSplashAgreement(agreementValue: boolean) {
    await this.memberService.saveAgreement(agreementValue);

    if (agreementValue) {
      // Since we redirected the user to this link before going to the splash page
      // we can use it again since this contains the data we need to redirect
      window.location.href = this.$stateParams.returnUri;
    } else {
      this.authService.logout(false);
    }
  }
}
