'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from '../../core/serverconstants';
import * as _ from 'lodash';
import { DateFormatService } from '../../core/services/dateformat.service';

export class RewardsService {
  static $inject = ['serverConstants', 'dateFormatService'];
  constructor(
    private serverConstants: ServerConstants,
    private dataFormatService: DateFormatService) {
    this.rewardPartnerTypeConstants = serverConstants.rewardPartnerTypeConstants;
  }

  rewardPartnerTypeConstants;

  getRewardStatus(reward) {
    const executionDate = this.dataFormatService.getDateTime(DateTime.fromISO(reward.RaffleExecutionDate), reward.Offset);
    if (reward.Status !== 'Closed' || executionDate > DateTime.now()) {
      return `Execution @ ${executionDate.toFormat('EEE, MMM d, yyyy')}`;
    }
    return `Winners drawn (${this.getWinnersApprovalStatus(reward)})`;

  }

  getWinnersApprovalStatus(reward) {
    if (reward.Winners === 0 || reward.NotApprovedWinners === 0) {
      return 'approved';
    } else if (reward.Winners === reward.NotApprovedWinners) {
      return 'not approved';
    }
    return 'partly approved';

  }

  getRewardPartners() {
    return _.map(this.rewardPartnerTypeConstants, (value) => ({
      label: this.getLabelForRewardPartner(value),
      value,
    }));
  }

  getLabelForRewardPartner(rewardPartner) {
    switch (rewardPartner) {
      case this.rewardPartnerTypeConstants.tangoCardNonEur:
        return 'TangoCard (currencies other than EUR)';
      case this.rewardPartnerTypeConstants.tangoCardEurFrance:
        return 'TangoCard France (EUR)';
      case this.rewardPartnerTypeConstants.tangoCardEurGermany:
        return 'TangoCard Germany (EUR)';
      case this.rewardPartnerTypeConstants.tangoCardEurItaly:
        return 'TangoCard Italy (EUR)';
      case this.rewardPartnerTypeConstants.tangoCardEurSpain:
        return 'TangoCard Spain (EUR)';
      default:
      {
        const key = _.findKey(this.rewardPartnerTypeConstants, (constant) => constant === rewardPartner);
        return key[0].toUpperCase() + key.slice(1);
      }
    }
  }
}
