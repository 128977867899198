'use strict';

import { ObserverLandingPageController } from './observer-landing-page.controller';
const template = require('./observer-landing-page.html');
require('./observer-landing-page.scss');

angular.module('insitesApp.home')
  .component('observerLandingPageComponent', {
    controller: ObserverLandingPageController,
    controllerAs: 'vm',
    templateUrl: template,
  });
