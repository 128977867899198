'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.segmentation',
      config: {
        url: '/members/segmentation',
        title: 'Segmentation',
        component: 'segmentationComponent',
        settings: {
          nav: 6,
          childnav: 2,
          label: 'Segmentation',
        },
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

