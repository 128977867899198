var searchCounterTemplate = "<div class=\"search-counter-container\" ng-if=\"vm.counter\">\n  <div class=\"search-counter\">{{vm.counter}} {{vm.counterLabel}}</div>\n</div>\n";
import { IscSearchCounterController } from './isc-search-counter.controller';
import './isc-search-counter.scss';
export var IscSearchCounterComponent = {
    controller: IscSearchCounterController,
    template: searchCounterTemplate,
    controllerAs: 'vm',
    transclude: true,
    bindings: {
        counterLabel: '@',
        counter: '<',
    },
};
