'use strict';

import { ForumService } from './../../core/dataservices/forum.service';
import { DiscussionService } from './../../core/dataservices/discussion.service';
import { ParticipantFactory } from './../../core/participant.factory';
import { Pagination } from '../../core/models/pagination';
import { SpinnerService } from '../../core/services/spinner.service';
import { ExportConfigService } from '../../core/services/exportconfig.service';
import { ServerConstants } from '../../core/serverconstants';
import { ConversationsExportParams } from '../../core/contracts/download.contract';
import { IRemoveTopicRequest } from '../../core/contracts/discussionLounge.contract';
import * as _ from 'lodash';

export class ForumRoomController {
  static $inject = ['$stateParams', 'forumservice', '$state', 'selectedSquareForumsService', 'spinnerservice',
    '$mdDialog', 'participantFactory', 'googleTranslateHelper', '$scope', 'exportconfigservice',
    'serverConstants', 'discussionService'];
  constructor(
    private $stateParams,
    private forumservice: ForumService,
    private $state,
    private selectedSquareForumsService,
    private spinnerservice: SpinnerService,
    private $mdDialog,
    private participantFactory: ParticipantFactory,
    private googleTranslateHelper,
    private $scope,
    private exportconfigservice: ExportConfigService,
    private serverConstants: ServerConstants,
    private discussionService:DiscussionService,
  ) { }

  conversations = [];
  Name = '';
  loading = true;
  searchTerm = '';
  isSearching = false;
  imageSrc;
  roomGuid;

  pagination: IPagination = new Pagination(1);

  $onInit() {
    this.googleTranslateHelper.autoEnableForScope(this.$scope);
    this.spinnerservice.show('loading');
    this.imageSrc = 'images/no-items-waiting.png';
    this.roomGuid = this.$stateParams.roomGuid;
    this.pagination.page = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
    this.pagination.limit = this.$stateParams.limit ? parseInt(this.$stateParams.limit, 10) : 15;
    this.updateRoomName();
    this.populateConversationOverview();
  }

  updateRoomName() {
    const selectPromise = this.selectedSquareForumsService.update(this.$stateParams.squareGuid)
      .then(() => {
        this.Name = this.selectedSquareForumsService.getRoom(this.roomGuid).Name;
      }).finally(() => {
        this.spinnerservice.hide('loading');
      });
    this.spinnerservice.showFor('loading', selectPromise);
  }

  populateConversationOverview(search?) {
    const loadPromise = this.loadConversations(search)
      .then((response) => {
        const data = response.data;
        this.pagination.total = data.TotalItems;
        _.forEach(data.Items, (item) => {
          item.avatar = this.getAvatar(item);
        });
        this.conversations = data.Items;
      }).finally(() => {
        this.loading = false;
      });
    this.spinnerservice.showFor('loading', loadPromise);
  }

  loadConversations(search?) {
    this.isSearching = false;
    if (search && search !== '') {
      this.searchTerm = search;
      return this.forumservice.searchRoom(this.roomGuid, search, this.pagination.page, this.pagination.limit).then((response) => {
        this.isSearching = true;
        return response;
      });
    }
    return this.forumservice.getConversationsInRoom(this.roomGuid, this.pagination.page, this.pagination.limit);

  }

  deleteConversation(conversation) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: 'Delete conversation',
      text: `Do you want to delete the conversation ${conversation.Title}?`,
      ok: 'Yes',
      cancel: 'No',
    })).then(async () => {
      this.spinnerservice.show('loading');
      try {
        if (conversation.IsDiscussionNewConversation) {
          const removeTopicRequest: IRemoveTopicRequest = {
            topicGuid: conversation.Guid,
          };
          await this.discussionService.removeTopic(removeTopicRequest);
        } else {
          await this.forumservice.deleteConversation(conversation.Guid);
        }
        this.populateConversationOverview();
      } finally {
        this.spinnerservice.hide('loading');
      }
    });
  }

  goToConversation = (conversation) => {
    // If the conversation is part of the new Discussions module, redirect to a separate component
    const url = conversation.IsDiscussionNewConversation
      ? 'root.square.forum.room.newDiscussionConversation'
      : 'root.square.forum.room.conversation';

    this.$state.go(url, {
      roomGuid: this.roomGuid,
      conversationGuid: conversation.Guid,
      conversationpage: 1,
      query: this.searchTerm,
    });
  };

  getAvatar(conversation) {
    if (conversation) {
      return conversation.SquareParticipantHasProfileImage ?
        this.participantFactory.getParticipantImageUrl(conversation.SquareParticipantGuid, conversation.SquareParticipantDateModified) :
        ParticipantFactory.defaultAvatar;
    }
    return undefined;
  }

  navigate = (pageNumber, limit) => {
    this.$state.go('.', {
      page: pageNumber,
      limit,
    });
  };

  async downloadConversation(conversation) {
    await this.exportconfigservice.showModal(
      this.serverConstants.exportTypeConstants.conversation,
      new ConversationsExportParams({
        conversationGuid: conversation.Guid,
        keyword: this.searchTerm,
        sortOption: 0,
      }));
  }

  async search(searchTerm) {
    await this.populateConversationOverview(searchTerm);
  }
}
