'use strict';

import { GoogleTranslateHelperController } from './google-translate-helper.controller';
const template = require('./google-translate-helper.html');

angular.module('insitesApp.layout')
  .component('googleTranslateHelper', {
    templateUrl: template,
    controller: GoogleTranslateHelperController,
    controllerAs: 'vm',
  });
