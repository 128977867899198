'use strict';


export class OnScrollShadow implements ng.IDirective {
  constructor(
    private $document,
    private $filter) { }

  restrict = 'A';
  link(scope, element, attrs) {
    const body = angular.element(this.$document[0].body);
    const contentContainer = angular.element(this.$filter('filter')(body.find('md-content'), { id: 'maincontent' })[0]);
    const shadowElement = angular.element(attrs.scrollComponent ? element[0].childNodes[0] : element[0]);
    contentContainer.on('scroll', function() {
      if (this.scrollTop > 0) {
        shadowElement.addClass('sticky-shadow');
      } else {
        shadowElement.removeClass('sticky-shadow');
      }
    });
  }
}
