'use strict';

export class TableArrowsController {
  static $inject = ['$scope'];
  constructor(
    private $scope: ng.IScope) { }

  tableContainer;
  tableScrollLeft = 0;
  scrollLeft = 0;
  mouseDown = false;

  async $onInit() {
    this.tableContainer = document.getElementById('table-container');
    this.tableScrollLeft = this.tableContainer.scrollLeft;
    this.$scope.$watch('vm.tableScrollLeft', () => {
      if (this.tableContainer.scrollLeft !== this.scrollLeft) {
        this.showLeftArrow();
        this.showRightArrow();
        this.scrollLeft = this.tableContainer.scrollLeft;
      }
    });
  }

  showLeftArrow() {
    if (this.tableContainer.scrollLeft > 10) {
      return true;
    }

    return false;
  }

  showRightArrow() {
    if (this.tableContainer.scrollLeft + this.tableContainer.offsetWidth  < this.tableContainer.scrollWidth - 10) {
      return true;
    }

    return false;
  }

  scroll(step) {
    this.tableContainer.scrollLeft = this.tableContainer.scrollLeft + step;
    const that = this;
    if (this.mouseDown) {
      setTimeout(() => that.scroll(step), 20);
    }
  }

  scrollMouseDown(step) {
    this.mouseDown = true;
    this.scroll(step);
  }

  scrollMouseUp() {
    this.mouseDown = false;
  }

}
