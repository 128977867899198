'use strict';

import { ActivityObservationSpecifyController } from './activityObservationSpecify.controller';

const template = require('./activityObservationSpecify.html');

export const ActivityObservationSpecifyComponent: ng.IComponentOptions = {
  controller: ActivityObservationSpecifyController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    squareActivity: '=',
    isReadOnly: '<',
    isPublished: '<',
    isSavingUpReward: '<',
  },
  require: {
    wizardStep: '^^iscWizardStep',
  },
};
