'use strict';

import { SavingUpRewardsController } from './savingUpRewards.controller';

const savingUpRewardsTemplate = require('./savingUpRewards.html');

export const SavingUpRewardsComponent: ng.IComponentOptions = {
  controller: SavingUpRewardsController,
  templateUrl: savingUpRewardsTemplate,
  controllerAs: 'vm',
};
