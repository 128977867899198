'use strict';

import './newPeriodicRewards';
// import routeConfig from './periodicRewards.route';
import { PeriodicRewardsController } from './periodicRewards.controller';
import { PeriodicRewardsComponent } from './periodicRewards.component';

angular
  .module('insitesApp.squarerewards')
  .controller('PeriodicRewardsController', PeriodicRewardsController)
  .component('periodicRewards', PeriodicRewardsComponent);
// routing commented out because feature shouldn't be used at the moment
// .run(routeConfig);
