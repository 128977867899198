'use strict';

import * as _ from 'lodash';
import { SegmentationService } from '../../../core/dataservices/segmentation.service';
import { ITargeting } from '../../../core/contracts/targeting.contracts';
import { TargetService } from '../../../core/dataservices/target.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { DateTime } from 'luxon';

export class TargetingQuotaController {
  static $inject = ['segmentationservice', 'targetservice', '$scope', 'spinnerservice'];

  constructor(
    private segmentationservice: SegmentationService,
    private targetService: TargetService,
    private $scope: ng.IScope,
    private spinnerService: SpinnerService,
  ) { }
  activityGuid: string;
  segmentationGuid1: string;
  segmentationGuid2: string;
  segmentationList1;
  segmentationList2;
  targeting: ITargeting;
  isExecutingFeasibilityCheck = false;

  async $onInit() {
    await this.getSegmentationLists();
    this.$scope.$watchGroup(['vm.segmentationGuid1', 'vm.segmentationGuid2'], (newValue, oldValue) => {
      if (this.segmentationGuid1) {
        if (this.segmentationGuid1 === this.segmentationGuid2) {
          this.segmentationGuid2 = '';
        }
        this.updateSegmentationList2();
      }

      if (newValue !== oldValue) {
        this.targeting.TargetingQuota = null;
      }
    });

    this.setSegmentationGuids();
  }

  async checkFeasibility() {
    this.isExecutingFeasibilityCheck = true;
    try {
      const targetingCopy = _.cloneDeep(this.targeting);
      if (targetingCopy.TargetingQuota == null) {
        targetingCopy.TargetingQuota = {
          segmentationGuids: [this.segmentationGuid1, this.segmentationGuid2],
          targetingQuotaItems: [],
        };
      }

      this.spinnerService.showFor('loading', this.targetService.calculateTargetingQuota(this.activityGuid, targetingCopy)
        .then((response) => {
          this.targeting.TargetingQuota = response;
        }));
    } finally {
      this.isExecutingFeasibilityCheck = false;
    }
  }

  setSegmentationGuids() {
    if (this.targeting.TargetingQuota != null) {
      this.segmentationGuid1 = this.targeting.TargetingQuota.segmentationGuids[0];
      this.segmentationGuid2 = this.targeting.TargetingQuota.segmentationGuids[1];
    }
  }

  get dateLastFeasibilityCheck() {
    return DateTime.fromISO(this.targeting.TargetingQuota.dateLastFeasibilityCheck as any as string).toLocaleString(DateTime.DATETIME_MED);
  }

  get isCheckFeasibilityButtonEnabled() {
    return this.targeting.RandomlySelected <= this.targeting.Targeted &&
      this.targeting.RandomlySelected != null &&
      this.randomNumbersAddUp &&
      this.segmentationGuid1 != null &&
      !this.isExecutingFeasibilityCheck;
  }

  get randomNumbersAddUp(): boolean {
    if (this.targeting.TargetingQuota == null || this.targeting.TargetingQuota.targetingQuotaItems == null) {
      return true;
    }

    return this.targeting.TargetingQuota.targetingQuotaItems.every((item) =>
      _.sum(item.targetingQuotaItemRows.map((row) => row.amountRandom)) === this.targeting.RandomlySelected);
  }

  updateSegmentationList2() {
    this.segmentationList2 = this.segmentationList1.filter((seg) => seg.Guid !== this.segmentationGuid1);
  }

  public async getSegmentationLists() {
    await this.segmentationservice.getSquareSegmentations(true).then((response) => {
      _.forEach(response, (res: any) => res.Name = res.Question);
      this.segmentationList1 = response;
      this.segmentationList1
        .sort((a, b) => (a.type > b.type) ? 1 : ((b.type > a.type) ? -1 : 0))
        .unshift({
          Name: '-- Select --',
          Guid: '',
        });
      this.segmentationList2 = this.segmentationList1;
    });
  }
}
