'use strict';

import { IscTopNavController } from './isc-topnav.controller';

const template = require('./isc-topnav.html');

require('./_isc-topnav.scss');

export const IscTopNavComponent: ng.IComponentOptions = {
  controller: IscTopNavController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    clientGuid: '=',
    programGuid: '=',
    networkGuid: '=',
    squareGuid: '=',
    orphanGuid: '=',
    activityGuid: '=',
    squares: '=',
  },
};
