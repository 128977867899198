'use strict';

import { SidenavController } from './isc-sidenav.controller';

const template = require('./isc-sidenav.html');

require('./isc-sidenav.scss');

export const SidenavComponent: ng.IComponentOptions = {
  controller: SidenavController,
  templateUrl: template,
  controllerAs: 'vm',
};
