'use strict';

export class StatusToClass {
  static filter() {
    const map = {
      active: 'status-active',
      scheduled: 'status-scheduled',
      closed: 'status-closed',
      draft: 'status-scheduled',
      archived: 'status-archived',
      deleted: 'status-archived',
    };

    map['blocked (sequence)'] = 'status-scheduled';

    return (value) => value ? map[value.toLowerCase()] : value;
  }
}
