'use strict';

import { MappingService } from '../../../../core/services/mapping.service';
import { SelectedActivityFactory } from '../../../../core/selectedactivity.factory';
import { ServerConstants } from '../../../../core/serverconstants';
import { ActivityDataService, IForumConversation } from '../../../../core/services/activityData.service';
import { ActivityService } from '../../../../core/dataservices/activity.service';
import { ActivityBuildBaseController } from '../common/activityBuildBase.controller';
import { ActivityObservationBuildModel } from './activityObservationBuildModel';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
import * as _ from 'lodash';
import { ActivityBuildModelBase } from '../common/activityBuildBase.model';
import { IDiscussionActivityRequest } from 'src/app/core/contracts/discussion.contract';
import { DiscussionService } from 'src/app/core/dataservices/discussion.service';
import { DiscussionDataService } from 'src/app/core/services/discussionData.service';
import { CommunicationService } from '../../../../core/dataservices/communication.service';
import { NIL } from 'uuid';

export class ActivityObservationBuildController extends ActivityBuildBaseController {
  static $inject = [
    'serverConstants',
    'activityservice',
    'activitydataservice',
    '$stateParams',
    'logger',
    'selectedActivityFactory',
    '$scope',
    '$mdDialog',
    'poboService',
    'mappingService',
    'discussionService',
    'discussionDataService',
    'communicationservice'];

  squareActivity: SquareActivityModel;
  saveCallback = () => super.beforeSave(false);
  resetFormCallback = () => super.resetForm();
  model = new ActivityObservationBuildModel();
  allowedQuestionTypes: number[] = [];
  questionType: number = this.serverConstants.conversationQuestionTypeConstants.imageAndVideo;

  constructor(
    protected serverConstants: ServerConstants,
    protected activityservice: ActivityService,
    protected activitydataservice: ActivityDataService,
    protected $stateParams: ng.ui.IStateParamsService,
    protected logger: Logger,
    protected selectedActivityFactory: SelectedActivityFactory,
    protected $scope: ng.IScope,
    protected $mdDialog: ng.material.IDialogService,
    protected poboService: PoboService,
    protected mappingService: MappingService,
    protected discussionService: DiscussionService,
    protected discussionDataService: DiscussionDataService,
    protected communicationService: CommunicationService,
  ) {
    super(serverConstants, activityservice, activitydataservice, $stateParams, logger, selectedActivityFactory, $scope,
      $mdDialog, poboService, mappingService);

    this.allowedQuestionTypes.push(this.serverConstants.questionTypesConstants.photo);
    this.allowedQuestionTypes.push(this.serverConstants.questionTypesConstants.video);
    this.allowedQuestionTypes.push(this.serverConstants.questionTypesConstants.photoVideo);
    this.questionType = this.serverConstants.questionTypesConstants.photoVideo;
  }

  $onInit() {
    super.$onInit();

    // if there's no opening post already created, use default values for some of the properties
    const useDefaultValues = !this.squareActivity.discussionModel?.post;
    if (useDefaultValues) {
      // by default, an Observation activity should be visual focused
      if (this.squareActivity.discussionModel) {
        this.squareActivity.discussionModel.appearance = this.serverConstants.appearanceConstants.centered;
      }
      this.model.conversationFocusType = this.mappingService.convertAppearanceToFocusType(this.serverConstants.appearanceConstants.centered);
    }
  }

  protected mapModel(data: SquareActivityModel) {
    this.model = this.mappingService.mapSquareActivityModelForObservationActivity(data);
  }

  $loadData(data: SquareActivityModel) {
    this.model = new ActivityObservationBuildModel();
    super.$loadData(data);
  }

  async $wizardStepLoaded() {
    await super.$wizardStepLoaded();
    const specifyStep = (await this.wizardStep.resolveComponent(['specifyQual']))[0];
    if (specifyStep && this.squareActivity.ActivityQualDetail == null) {
      this.squareActivity.ActivityQualDetail = specifyStep.squareActivity.ActivityQualDetail;
    }
    if (specifyStep && this.squareActivity.discussionModel == null) {
      this.squareActivity.discussionModel = specifyStep.squareActivity.discussionModel;
    }
    if (!this.$wizardStepIsCompleted()) {
      this.setDefaultOneByOne();
    }
  }

  private setDefaultOneByOne() {
    // PBI 78185:
    // One by one is not applicable in private discussions with multiple contributions
    // One by one should be selected by default unless context is group and visibility is influenced
    const isPrivateAndMultipleContributions =
      this.squareActivity.ActivityQualDetail.Visibility === this.serverConstants.activityVisibilityTypeConstants.private &&
      this.squareActivity.ActivityQualDetail.ContributionType === this.serverConstants.activityContributionTypeConstants.diary;

    if (isPrivateAndMultipleContributions) {
      return;
    }

    this.squareActivity.discussionModel.isOneByOne =
      this.squareActivity.ActivityQualDetail.Visibility !== this.serverConstants.activityVisibilityTypeConstants.influenced;
  }

  // eslint-disable-next-line no-unused-vars
  protected additionalConditionForCompletedSteps(model: ActivityBuildModelBase) {
    return true; // no other additional condition for tis type
  }

  $wizardIsValid() {
    const isObservationFirstQuestionRequired = _.head(this.model.probeQuestions).answerRequired;
    return super.$wizardIsValid() && isObservationFirstQuestionRequired;
  }

  async updateActivityBuild(reload: boolean, root: this) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }

    root.squareActivity.ActivityQualDetail.IsOneByOne = this.squareActivity.discussionModel.isOneByOne;
    // if OneByOne is checked and the visibility is not private, the visibility should be set to uninfluenced and back to influenced if unchecked
    if (this.squareActivity.discussionModel.visibilityType !== this.serverConstants.activityVisibilityTypeConstants.private) {
      root.squareActivity.discussionModel.visibilityType = this.squareActivity.discussionModel.isOneByOne ?
        this.serverConstants.activityVisibilityTypeConstants.uninfluenced : this.serverConstants.activityVisibilityTypeConstants.influenced;
    }
    // since the squareActivity object (and its ActivityQualDetail property) is used for saving,
    // map the properties from the model of this step to the properties of the object (the other ones should already be filled in)
    this.mappingService.mapObservationModelToSquareActivityModel(this.model, root.squareActivity);

    const conversation: IForumConversation = this.mappingService.mapActivityBuildModelToForumConversation(root.model);
    conversation.ActivityGuid = root.$stateParams.activityGuid;

    let discussionGuid: string;
    try {
      discussionGuid = await root.discussionDataService
        .saveDiscussionActivity(
          conversation,
          root.model.probeQuestions,
          root.squareActivity,
        );
    } catch (error) {
      if (error.data && error.data.ValidationErrors && error.data.ValidationErrors[0] && error.data.ValidationErrors[0].PropertyName
        && error.data.ValidationErrors[0].PropertyName === 'ProbeQuestions') {
        // Reload the data for this step and show that probe questions can't be changed anymore.
        root.$loadData(root.squareActivity);
        root.probeQuestionsAlreadyAnswered = true;
      }
      // Rethrow error so that the wizard doesn't go to the next step.
      throw error;
    }

    root.model.conversationGuid = discussionGuid;
    const request: IDiscussionActivityRequest = { activityGuid: root.$stateParams.activityGuid };
    const response = await root.discussionService.getDiscussionActivity(request);
    await this.updateCommunicationTitle(response.communicationGuid, conversation.Title);
    root.form.$setPristine();
    if (!reload) {
      root.logger.success('Activity build successfully updated');
    }
    root.squareActivity = this.mappingService.mapDiscussionActivityDetailsToSquareActivityModel(response, root.squareActivity);
    root.squareActivity.isChildActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.child;
    root.squareActivity.isParentActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.parent;

    // Since changing one by one can influence the visibility, make sure to also update the specify step:
    (await this.wizardStep.resolveComponent(['specifyQual']))[0].model.Visibility = root.squareActivity.discussionModel.visibilityType;

    root.$loadData(root.squareActivity);
  }

  get showOneByOneOption(): boolean {
    return this.squareActivity.discussionModel?.contributionType !== this.serverConstants.activityContributionTypeConstants.diary;
  }

  get oneByOneTooltip() {
    return this.squareActivity.discussionModel?.visibilityType === this.serverConstants.activityVisibilityTypeConstants.private ?
      'A question will only appear when the previous one has been answered.' :
      // eslint-disable-next-line max-len
      "A question will only appear when the previous one has been answered. When choosing 'One by one', visibility will automatically be set to 'uninfluenced' in case of a group activity.";
  }

  private async updateCommunicationTitle(communicationGuid?: string, title?: string) {
    // First time going through the wizard, the communicationGuid will not be filled in since no communication has been created yet.
    // The communication only gets created after saving the present step.
    if (communicationGuid && communicationGuid !== NIL) {
      await this.communicationService.updateCommunicationTitle(communicationGuid, title);
    }
  }
}
