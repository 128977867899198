'use strict';

import { RouterHelper } from '../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.404',
      config: {
        url: '404',
        // Just load the shared component from IscUi
        // NEW LOGO: The new square logo should be used here as square-logo binding. Check the history for more details
        template: '<isc-404-page page-not-found-image="../../images/404.png"></isc-404-page>',
        title: 'Not found',
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;
