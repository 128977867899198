'use strict';

import { IscActivityStatusFilterController } from './isc-activity-status-filter.controller';

const template = require('./isc-activity-status-filter.html');
require('./_isc-activity-status-filter.scss');

export const iscActivityStatusFilterComponent: ng.IComponentOptions = {
  controller: IscActivityStatusFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    statuses: '=',
    change: '&',
  },
};
