'use strict';

import { DateFormatService } from './../services/dateformat.service';

const timeAgoFilter = (dateFormatService: DateFormatService) => (value) => dateFormatService.convertDate(value).toRelative();

timeAgoFilter.$inject = ['dateFormatService'];

angular
  .module('insitesApp.core')
  .filter('timeAgo', timeAgoFilter);
