'use strict';

import { ServerConstants } from '../../../../core/serverconstants';
import { RecruitmentPartner } from '../activitySteps/activityRecruitmentPartner/recruitmentPartner.model';
import _ = require('lodash');

export class BackgroundVariableEditor {
  static $inject = ['$anchorScroll', '$timeout', '$scope', 'serverConstants'];
  constructor(
    private $anchorScroll,
    private $timeout: ng.ITimeoutService,
    private $scope: ng.IScope,
    serverConstants: ServerConstants) {
    this.validationConstants = serverConstants.validationConstants;
  }

  backgroundVariableForm: ng.IFormController;
  variables;
  partner: RecruitmentPartner;
  isDisabled;
  validationConstants;

  $onInit() {
    (this.$scope.$parent as any).vm.addChildVM(this);
  }

  isValid = () => {
    let isInEditMode = false;
    _.each(this.variables, (variable) => {
      isInEditMode = variable.isInEditMode || isInEditMode;
      if (isInEditMode) {
        return false;
      }
      _.each(variable.Values, (value) => {
        isInEditMode = value.isInEditMode || isInEditMode;
        if (isInEditMode) {
          return false;
        }
      });
    });
    return !isInEditMode;
  };

  addVariable = () => {
    const variable = this.createVariable();
    this.variables.push(variable);
    this.editVariable(variable);
    this.$timeout(() => {
      this.$anchorScroll(`background-variable-${this.variables.length - 1}`);
    }, 100);
  };

  createVariable = () => ({
    Name: this.findNextVariableName(),
    Label: '',
    Values: [],
    IsSynced: false,
  });

  editVariable = (variable) => {
    if (!variable.IsSynced || !this.isDisabled) {
      variable.isInEditMode = true;
      variable.editingLabel = variable.Label;
    }
  };

  saveEditedVariable = (variable) => {
    const parentIndex = this.variables.indexOf(variable);
    const editingLabelField = this.backgroundVariableForm[`editingLabel-${parentIndex}`];

    editingLabelField.$setTouched();

    if (!_.isUndefined(variable.editingLabel)) {
      variable.editingLabel = variable.editingLabel.trim();

      if (!_.isEmpty(variable.editingLabel)) {
        variable.Label = variable.editingLabel;
        this.closeEditedVariable(variable);
      }
    }
  };

  closeEditedVariable = (variable) => {
    variable.isInEditMode = false;
    delete variable.editingLabel;
  };

  findNextVariableName = () => {
    const partnerId = this.partner.Id;
    let index = 1;
    if (this.partner.CreatedVariableCount) {
      index = this.partner.CreatedVariableCount + 1;
    }
    if (this.variables.length) {
      _.each(this.variables, (variable) => {
        // Assuming a variable is named "BVAR100_1", where 100 is the partner id
        // And 1 is the variables index
        const nameParts = variable.Name.split('_');
        const i = parseInt(nameParts[1], 10);
        index = Math.max(index, i + 1);
      });
    }
    return `BVAR${partnerId}_${index}`;
  };

  deleteVariable = (variable) => {
    const index = this.variables.indexOf(variable);
    if (index !== -1) {
      this.variables.splice(index, 1);
    }
  };

  addVariableValue = (variable) => {
    const value = {
      Value: '',
      Label: '',
      isNew: true,
    };
    variable.Values.push(value);
    this.editValue(variable, value);
  };

  editValue = (variable, value) => {
    if (!variable.IsSynced || !this.isDisabled) {
      value.isInEditMode = true;
      value.editingValue = value.Value;
      value.editingLabel = value.Label;
    }
  };

  saveEditedValue = (variable, value) => {
    /*
    Check if the new value exists before adding it
    */
    const parentIndex = this.variables.indexOf(variable);
    const index = variable.Values.indexOf(value);
    const editingValueField = this.backgroundVariableForm[`editingValue-${parentIndex}-${index}`];
    const editingLabelField = this.backgroundVariableForm[`editingLabel-${parentIndex}-${index}`];

    editingValueField.$setTouched();
    editingLabelField.$setTouched();
    editingValueField.$setValidity('duplicateValue', true);

    if (!_.isUndefined(value.editingValue) && !_.isUndefined(value.editingLabel)) {
      value.editingValue = value.editingValue.trim();
      value.editingLabel = value.editingLabel.trim();

      if (!_.isEmpty(value.editingValue) && !_.isEmpty(value.editingLabel)) {
        if (this.doesValueExists(variable, value)) {
          editingValueField.$setValidity('duplicateValue', false);
        } else {
          value.Label = value.editingLabel;
          value.Value = value.editingValue;
          this.closeEditedValue(value);
        }
      }
    }
  };

  private doesValueExists = (variable, value) => {
    let present = false;
    // Avoid js errors
    if (variable.Values.length > 0) {
      // Search through existing values
      _.forEach(variable.Values, (variableValue) => {
        if (variableValue.Value === value.editingValue && variableValue.$$hashKey !== value.$$hashKey) {
          present = true;
        }
      });
    }
    return present;
  };

  closeEditedValue = (value) => {
    value.isInEditMode = false;
    delete value.editingValue;
    delete value.editingValue;
  };

  deleteValue = (variable, value) => {
    variable.Values = _.without(variable.Values, value);
  };
}
