'use strict';

import { CurrentUserService } from '../../core/dataservices/currentUser.service';
import { AuthService } from '../../core/dataservices/auth.service';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import { ICurrentUser, IImpersonateUser } from '../../core/dataservices/dataservice.contracts';
import { HelperService } from '../../core/services/helper.service';
import * as _ from 'lodash';
import { ParticipantFactory } from '../../core/participant.factory';
import { ServerConstants } from 'src/app/core/serverconstants';

export class IscTopNavController {
  static $inject = [
    'selectedSquareFactory',
    '__env',
    'currentUserService',
    'authService',
    'helperservice',
    'serverConstants',
    '$state',
  ];

  constructor(
    private selectedSquareFactory: SelectedSquareFactory,
    private __env: Environment,
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private helperservice: HelperService,
    private serverConstants: ServerConstants,
    private $state: ng.ui.IStateService,
  ) { }

  selectedSquare = this.selectedSquareFactory;
  selected;
  squares;
  squareUrlBase = this.__env.squareUrlBase;
  defaultAvatar = ParticipantFactory.defaultAvatar;
  decipherUrl = this.__env.decipherUrl;
  clientGuid;
  impersonateGuid;

  get user(): ICurrentUser {
    this.impersonateGuid = null;
    if (!this.authService.isImpersonating || this.impersonateUserList == null) {
      return this.currentUserService.userProfile;
    }
    // The impersonate in auth is a getter which will retrieve and parse JSON from storage with every get -> for each in impersonateUserList
    this.impersonateGuid = this.authService.impersonate.SquareParticipantGuid;
    const impersonateUser = _.find(this.impersonateUserList, (u) => u.Guid === this.impersonateGuid);
    return {
      Guid: this.currentUserService.userProfile.Guid,
      Role: this.currentUserService.userProfile.Role,
      Avatar: this.currentUserService.userProfile.Avatar,
      Username: impersonateUser ? impersonateUser.Username : '',
      Firstname: this.currentUserService.userProfile.Firstname,
      Lastname: this.currentUserService.userProfile.Lastname,
    } as ICurrentUser;
  }

  get originalUser(): ICurrentUser {
    const originalUser = this.currentUserService.originalUser;
    if (originalUser) {
      return originalUser;
    }
    return this.currentUserService.userProfile;
  }

  get impersonateUserList(): IImpersonateUser[] {
    return this.currentUserService.impersonateUserList;
  }

  get firstName(): string {
    return this.user && this.user.Firstname
      ? this.user.Firstname
      : this.authService.firstName;
  }

  getNrOfSquares = () => {
    if ((this.selectedSquare && this.selectedSquare.Guid) || (this.squares && this.squares.length === 1)) {
      return 1;
    }
    if (this.squares && this.squares.length > 1) {
      return this.squares.length;
    }

    return 0;
  };

  async goToSquareParticipantView(squareUrl) {
    if (this.selectedSquare.Guid) {
      // This.squares is undefined when using a pinned square. As a square is selected, use this.selectedSquare
      squareUrl = this.selectedSquare.SquareUrl;
    }
    this.helperservice.goToSquareParticipantView(squareUrl);
  }

  getSquareUrl() {
    if (this.squares && this.squares.length === 1) {
      return this.squares[0].Url;
    }
    return;
  }

  logout() {
    this.authService.logout(false);
  }

  impersonate(squareParticipantGuid: string) {
    this.authService.impersonate = {
      SquareParticipantGuid: squareParticipantGuid,
      SquareGuid: this.selectedSquare.Guid,
    };
    this.impersonateGuid = squareParticipantGuid;
  }

  stopImpersonating() {
    this.authService.impersonate = undefined;
    this.impersonateGuid = null;
  }

  openMenu = ($mdOpenMenu, ev) => {
    $mdOpenMenu(ev);
  };

  get redirectUrl() {
    const role = this.user.Role;
    const { human8, professionalAdmin, clientAdmin, clientEditor, observer } = this.serverConstants.roleConstants;
    switch (role) {
      case human8:
        return 'root.home()';
      case professionalAdmin:
      case clientAdmin:
      case clientEditor:
        return `root.moderatorlandingpage({clientGuid: ${this.clientGuid}})`;
      case observer:
        return `root.observerlandingpage({clientGuid: ${this.clientGuid}})`;
      default:
        return '.';
    }
  }

  public goToPlatformnotice() {
    this.$state.go('root.platformnotice');
  }

  public goToHangfire() {
    this.helperservice.goToHangfire();
  }

  public goToFeatureSetting() {
    this.$state.go('root.featureSetting');
  }

  public goToRewardHandling() {
    this.$state.go('root.rewardHandling');
  }

  get isDeveloper() {
    return this.authService.isDeveloper();
  }
}
