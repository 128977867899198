'use strict';

export class SearchBarController {

  searchTerm;
  executeSearch;

  clearSearch = () => {
    this.searchTerm = '';
    this.executeSearch({
      searchTerm: '',
    });
  };

  onSubmit = () => {
    this.executeSearch({
      searchTerm: this.searchTerm,
    });
  };
}
