import { UiModule } from '../../../index';
import { IscTagInputController } from './isc-tag-input.controller';
var iscTagInputTemplate = "<md-input-container class=\"md-block\" ng-class=\"!vm.ngModelController.$valid ? 'md-input-invalid' : ''\">\n    <label class=\"md-no-float tag-label\" ng-show=\"vm.label\" ng-class=\"{'requiredLabel': vm.isRequired || false}\">{{vm.label}}</label>\n    <md-chips\n      name=\"vm.name\"\n      md-require-match=\"true\"\n      ng-model=\"vm.model\"\n      md-removable=\"vm.removable\"\n      readonly=\"vm.readonly\"\n      md-on-remove=\"vm.onRemove({chip: $chip})\"\n      md-on-add=\"vm.onAdd({chip: $chip})\"\n      class=\"chips\">\n        <input\n          ng-model=\"vm.inputModel\"\n          ng-blur=\"vm.inputBlur()\"\n          name=\"vm.inputName\"\n          ng-paste=\"vm.inputPaste({event: $event})\">\n        </input>\n        <md-chip-template>\n          <i ng-if=\"vm.showStar({chip: $chip})\" class=\"typcn typcn-star-full-outline\"></i><span class=\"chip\">{{vm.getChip($chip)}}</span>\n        </md-chip-template>\n      </md-chips>\n\n    <div ng-if=\"vm.ngModelController.$dirty\" ng-messages=\"vm.ngModelController.$error\" ng-transclude=\"errors\"></div>\n\n</md-input-container>\n";
UiModule
    .component('iscTagInput', {
    template: iscTagInputTemplate,
    controller: IscTagInputController,
    controllerAs: 'vm',
    bindings: {
        label: '@',
        placeholder: '@',
        name: '@',
        model: '=',
        inputModel: '=',
        inputBlur: '&',
        inputName: '@',
        inputPaste: '&',
        isRequired: '<',
        removable: '<',
        readonly: '<',
        chipProperty: '@',
        onRemove: '&',
        onAdd: '&',
        showStar: '&',
    },
    transclude: {
        errors: '?iscTagInputErrors',
    },
});
