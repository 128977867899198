'use strict';

import { ClientConfigColorsController } from './colors.controller';
const template = require('./colors.html');

angular
  .module('insitesApp.clientConfiguration')
  .component('clientConfigColors', {
    templateUrl: template,
    controller: ClientConfigColorsController,
    controllerAs: 'vm',
  });
