'use strict';

import { SelectedClientFactory } from './../core/selectedclient.factory';
import { AuthService } from '../core/dataservices/auth.service';
import { SelectedSquareFactory } from '../core/selectedsquare.factory';
import { ServerConstants } from '../core/serverconstants';
import { SquareService } from '../core/dataservices/square.service';
import { InsitesUserDetailService } from '../core/dataservices/insitesUserDetail.service';
import { RouterHelper } from '../blocks/router/router-helper';
import { clientService } from '@insites/vue-eco-temp-library';
import { DateTime } from 'luxon';
import { TokenForSquareResponse } from '../core/dataservices/dataservice.contracts';
import { HelperService } from '../core/services/helper.service';

const routeConfig = (
  routerHelper: RouterHelper,
  authService: AuthService,
  helperservice: HelperService,
  __env: Environment,
  insitesUserDetailService: InsitesUserDetailService,
  $transitions) => {
  routerHelper.configureStates([{
    state: 'root.square',
    config: {
      abstract: true,
      url: 'client/:clientGuid/program/:programGuid/square/:squareGuid',
      template: '<ui-view></ui-view>',
      resolve: {
        changeSquareToken: ['$stateParams', '$state', 'selectedSquareFactory', 'selectedClientFactory', 'serverConstants', 'squareservice', '$q',
          async (
            $stateParams,
            $state,
            selectedSquareFactory: SelectedSquareFactory,
            selectedClientFactory: SelectedClientFactory,
            serverConstants: ServerConstants,
            squareservice: SquareService,
            $q: ng.IQService) => {
            // Purchase token for the target square
            if (authService.isObserverUserLogin() || authService.isModeratorUserLogin()) {
              const tokenResponse = await authService.setTokenForSquare($stateParams.clientGuid, $stateParams.squareGuid);
              if (tokenResponse && tokenResponse.Active !== true) {
                // we need to get to the square activation using token and identity from response
                await goToMemberPortalActivation(squareservice, helperservice, tokenResponse, $stateParams.squareGuid);
                return $q.reject();
              }
            }

            await validateTargetSquareStatus(selectedSquareFactory, selectedClientFactory, $stateParams, squareservice, authService,
              serverConstants, $state, $q, insitesUserDetailService);
          }],
      },
    },
  },
  ]);

  $transitions.onFinish({ from: 'root.square.**' }, (transition) => {
    const toParams = transition.params();
    const fromParams = transition.params('from');
    const toRoute = transition.to().name;
    if (toRoute && (!toRoute.startsWith('root.square.') || toParams.squareGuid !== fromParams.squareGuid)) {
      authService.impersonate = undefined;
    }
  });
};

const goToMemberPortalActivation = async (
  squareservice: SquareService, helperservice: HelperService, tokenResponse: TokenForSquareResponse, squareGuid: string) => {
  const square = await squareservice.getSquareDetails(squareGuid);
  if (square && square.data && square.data.Detail && square.data.Detail.Url) {
    helperservice.goToSquareParticipantView(square.data.Detail.Url, tokenResponse.Token);
  }
};

const validateTargetSquareStatus = async (
  selectedSquareFactory: SelectedSquareFactory,
  selectedClientFactory: SelectedClientFactory,
  $stateParams,
  squareservice: SquareService,
  authService: AuthService,
  serverConstants,
  $state,
  $q,
  insitesUserDetailService) => {
  const squareInfoStateName = 'root.square.info';
  if (selectedSquareFactory.Guid !== $stateParams.squareGuid) {
    const hasInSitesUserAccess = await insitesUserDetailService.hasInSitesUserAccess($stateParams.clientGuid, $stateParams.squareGuid);
    if (!hasInSitesUserAccess) {
      return $q.reject();
    }

    if (authService.isHuman8UserLogin()) {
      await authService.getSquareTokenForInSitesUser($stateParams.squareGuid, $stateParams.clientGuid);
    }

    // before getting square details, we need proper client identification in store
    selectedSquareFactory.basicInfoUpdate($stateParams.clientGuid);
    const square = await squareservice.getSquareDetails($stateParams.squareGuid);
    const rightToLeft = await squareservice.getSquareLanguageDirection();
    const token = authService.getToken();

    if(authService.isObserverUserLogin()){
      const client = await clientService.getClientDetails($stateParams.clientGuid);
      selectedClientFactory.setClientInfo({
        Name: client.detail.adminName || client.detail.name,
        Guid: client.detail.guid,
        Id: client.detail.id,
        VideoStorage: client.detail.videoStorage,
        Code: client.detail.code,
        RelatedClients: null,
      });
    }

    selectedSquareFactory.setSquareInfo({
      Guid: square.data.Detail.Guid,
      Name: square.data.Detail.Name,
      SquareUrl: square.data.Detail.Url,
      SsoEnabled: square.data.Detail.SsoEnabled,
      Language: square.data.Detail.Language,
      StartDate: DateTime.fromISO(square.data.Detail.StartDate as string),
      CreateDate: DateTime.fromISO(square.data.Detail.DateCreated),
      RightToLeft: rightToLeft,
      Status: square.data.Detail.Status,
      ClientGuid: $stateParams.clientGuid,
      Anonymous: square.data.Detail.Anonymous,
      Token: token,
      Id: square.data.Detail.Id,
      AllowPiiInExports: square.data.Detail.AllowPiiInExports,
    });
  }

  if (selectedSquareFactory.Status === serverConstants.squareStatusConstants.draft && $state.transition.to().name !== squareInfoStateName) {
    $state.go(squareInfoStateName, {
      clientGuid: $stateParams.clientGuid,
      programGuid: $stateParams.programGuid,
      squareGuid: $stateParams.squareGuid,
      clonedSquareGuid: $stateParams.clonedSquareGuid,
    });

    return $q.reject();
  }

  return $q.resolve(true);
};


routeConfig.$inject = ['routerHelper', 'authService', 'helperservice', '__env', 'insitesUserDetailService', '$transitions'];

export default routeConfig;

