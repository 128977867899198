'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'loginerror',
    config: {
      url: '/loginerror',
      component: 'loginerror',
      title: 'Login Error',
      allowAnonymous: true,
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;
