'use strict';

import { ActivityDecipherIdController } from './activityDecipherId.controller';

const template = require('./activityDecipherId.html');
require('./activityDecipherId.scss');

export const ActivityDecipherIdComponent: ng.IComponentOptions = {
  controller: ActivityDecipherIdController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    model: '=',
    isDisabled: '<',
  },
  require: {
    form: '^^form',
  },
};
