'use strict';

import { DateTime } from 'luxon';
import { Cacheable } from '../models/cacheable';
import _ = require('lodash');

export class CacheService {
  static $inject = [];
  constructor() {
    this.clearExpiredItems();
  }

  static removeCacheValue(key: string): void {
    if (localStorage) {
      localStorage.removeItem(key);
    } else {
      document.cookie = `${key}="";max-age=1`;
    }
  }

  static isSet(key: string) {
    return this.getCacheValue(key) != null;
  }

  static setCache<T extends Cacheable>(cacheable: T) {
    // Normally we use localStorage to cache things
    // But when the square gets opened from within a Webview in a native Android app,
    // The possibility exists that access to localStorage is disabled.
    // In that case cookies wil be used.
    if (localStorage) {
      const cache = {
        Expiry: cacheable.duration ? DateTime.now().plus({ days: cacheable.duration }) : undefined,
        Value: cacheable.value,
      };

      localStorage.setItem(cacheable.key, JSON.stringify(cache));
    } else {
      const maxAge = 60 * 60 * 24 * (cacheable.duration ? cacheable.duration : 365);
      document.cookie = `${cacheable.key}=${JSON.stringify(cacheable.value)};max-age=${maxAge};samesite=lax`;
    }
  }

  static getCacheValue(keyName: string): any {
    const cache = this.getCache(keyName);
    if (!cache) {
      return null;
    }
    if (cache.expiry < DateTime.now()) {
      this.removeCacheValue(keyName);
      return null;
    }

    return cache.value;
  }

  static getCache(keyName: string): {key: string, value: any, expiry: DateTime} {
    return localStorage ? this.getFromLocalStorage(keyName) : this.getFromCookie(keyName);
  }

  private static getFromCookie(keyName: string): { key: string, value: string, expiry: DateTime } {
    let result: { key: string, value: string, expiry: DateTime };
    const value = this.findCookie(keyName);
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        if (parsedValue) {
          result = {
            key: keyName,
            expiry: DateTime.now().plus({ days: 99999 }),
            value: parsedValue,
          };
        }
      } catch (error) {
        if (typeof value === 'string') {
          result = {
            key: keyName,
            expiry: DateTime.now().plus({ days: 99999 }),
            value,
          };
        } else {
          throw error;
        }
      }
    }
    return result;
  }

  private static getFromLocalStorage(keyName: string): { key: string, value: string, expiry: DateTime } {
    let result: { key: string, value: string, expiry: DateTime };
    const value = localStorage.getItem(keyName);
    if (value) {
      try {
        const json = JSON.parse(value);
        if (json && json.Value) {
          result = {
            key: keyName,
            expiry: json.Expiry ? json.Expiry : DateTime.now().plus({ days: 99999 }),
            value: json.Value,
          };
        }
      } catch (err) {
        if (typeof value === 'string') {
          result = {
            key: keyName,
            expiry: DateTime.now().plus({ days: 99999 }),
            value,
          };
        } else {
          throw err;
        }
      }
    }
    return result;
  }

  private static findCookie(key: string): string {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${key}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }

  private clearExpiredItems() {
    // Only necessary for localStorage, cookies will clear themselves
    if (localStorage) {
      const keys = Object.keys(localStorage);
      _.each(keys, (key) => {
        const cached = localStorage.getItem(key);
        if (cached) {
          try {
            const cacheValue = JSON.parse(cached);
            if (cacheValue && cacheValue.Expiry && DateTime.now() > cacheValue.Expiry) {
              localStorage.removeItem(key);
            }
          } catch {
            // Nothing
          }
        }
      });
    }
  }
}
