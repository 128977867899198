'use strict';

import * as angular from 'angular';

import routeConfig from './new-discussion-conversation.route';
import { computed } from 'vue';
import { DiscussionActivityPage, createApp, injectionKeys } from '@insites/vue-eco-temp-library';
import { CurrentUserService } from 'src/app/core/dataservices/currentUser.service';
import { ConversationEventsService } from 'src/app/core/services/conversationEvents.service';
import { VueToggle } from 'src/app/core/vueToggle';
import { GoogleTranslateHelperService } from 'src/app/layout/google-translate-helper/google-translate-helper.service';

const getUserProfileSymbol = injectionKeys.user.getUserProfile;
const videoStorageTypeSymbol = injectionKeys.square.videoStorageType;
const discussionNewChangeSymbol = injectionKeys.notification.discussionNewChange;
const isTranslationEnabledSymbol = injectionKeys.translation.isTranslationEnabled;
const stimulusEncodedSymbol = injectionKeys.notification.stimulusTranscriptionStatusChanged;

angular
  .module('insitesApp.squareForumRoom')
  .component('forumNewDiscussionConversationComponent', {
    controller: ['serverConstants',
      '$stateParams',
      '$state',
      'currentUserService',
      'selectedClientFactory',
      'conversationEventsService',
      'VueToggle',
      'googleTranslateHelper',
      function(serverConstants, $stateParams, $state, currentUserService: CurrentUserService, selectedClientFactory,
        conversationEventsService: ConversationEventsService, vueToggle: VueToggle, googleTranslateHelper: GoogleTranslateHelperService) {
        const getMemberLink = (squareParticipantGuid: string, squareParticipantRole: number): string => {
          if (squareParticipantRole === serverConstants.roleConstants.participant) {
            return $state.href('root.square.members.details.activities', {
              clientGuid: $stateParams.clientGuid,
              programGuid: $stateParams.programGuid,
              squareGuid: $stateParams.squareGuid,
              memberGuid: squareParticipantGuid,
            });
          }
          return null;
        };
        const app = createApp(DiscussionActivityPage);
        this.$postLink = () => {
          app.provide(getUserProfileSymbol, getMemberLink);
          app.provide(videoStorageTypeSymbol, computed(() => selectedClientFactory.VideoStorage));
          app.provide(discussionNewChangeSymbol, conversationEventsService.discussionNewChange);
          app.provide(stimulusEncodedSymbol, conversationEventsService.stimulusEncoded);
          app.provide(isTranslationEnabledSymbol, () => googleTranslateHelper.isTranslationEnabled());

          vueToggle.mount(app, 'forum-new-discussion-conversation-component');
        };
        this.$onDestroy = () => {
          vueToggle.unMount();
        };
      }],
  })
  .run(routeConfig);
