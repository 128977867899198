'use strict';

const timeZonesAndOffsets = [
  { label: 'UTC−12:00', offset: -12 },
  { label: 'UTC−11:00', offset: -11 },
  { label: 'UTC−10:00', offset: -10 },
  { label: 'UTC−09:30', offset: -9.5 },
  { label: 'UTC−09:00', offset: -9 },
  { label: 'UTC−08:00', offset: -8 },
  { label: 'UTC−07:00', offset: -7 },
  { label: 'UTC−06:00', offset: -6 },
  { label: 'UTC−05:00', offset: -5 },
  { label: 'UTC−04:00', offset: -4 },
  { label: 'UTC−03:30', offset: -3.5 },
  { label: 'UTC−03:00', offset: -3 },
  { label: 'UTC−02:00', offset: -2 },
  { label: 'UTC−01:00', offset: -1 },
  { label: 'UTC±00:00', offset: 0 },
  { label: 'UTC+01:00', offset: +1 },
  { label: 'UTC+02:00', offset: +2 },
  { label: 'UTC+03:00', offset: +3 },
  { label: 'UTC+03:30', offset: +3.5 },
  { label: 'UTC+04:00', offset: +4 },
  { label: 'UTC+04:30', offset: +4.5 },
  { label: 'UTC+05:00', offset: +5 },
  { label: 'UTC+05:30', offset: +5.5 },
  { label: 'UTC+05:45', offset: +5.75 },
  { label: 'UTC+06:00', offset: +6 },
  { label: 'UTC+06:30', offset: +6.5 },
  { label: 'UTC+07:00', offset: +7 },
  { label: 'UTC+08:00', offset: +8 },
  { label: 'UTC+08:30', offset: +8.5 },
  { label: 'UTC+08:45', offset: +8.75 },
  { label: 'UTC+09:00', offset: +9 },
  { label: 'UTC+09:30', offset: +9.5 },
  { label: 'UTC+10:00', offset: +10 },
  { label: 'UTC+10:30', offset: +10.5 },
  { label: 'UTC+11:00', offset: +11 },
  { label: 'UTC+12:00', offset: +12 },
  { label: 'UTC+12:45', offset: +12.75 },
  { label: 'UTC+13:00', offset: +13 },
  { label: 'UTC+14:00', offset: +14 },
];

export default timeZonesAndOffsets;
