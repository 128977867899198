'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';
import { clientMaintenanceModule } from './client-maintenance.module';

const appRunClientMaintenance = (routerHelper: RouterHelper, paramResolver) => {
  routerHelper.configureStates([{
    state: 'root.clientmaintenance',
    config: {
      url: 'client/:clientGuid/maintenance?:goto',
      component: 'clientMaintenance',
      title: 'Client Maintenance',
      resolve: {
        goto: paramResolver('goto'),
      },
    },
  }]);
};

appRunClientMaintenance.$inject = ['routerHelper', 'paramResolver'];

clientMaintenanceModule.run(appRunClientMaintenance);
