'use strict';

import { DateTime } from 'luxon';
import { useSquareStore } from '@insites/vue-eco-temp-library';
import { Utils } from './utils';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/distinctUntilChanged';

export interface SquareInfo {
  Id: number,
  Name: string;
  Guid: string;
  SquareUrl: string;
  SsoEnabled: boolean;
  Language: string;
  Status: number;
  StartDate: DateTime;
  CreateDate: DateTime;
  ClientGuid?: string;
  RightToLeft?: boolean;
  Anonymous?: boolean;
  Token: string;
  AllowPiiInExports?: boolean;
}

export class SelectedSquareFactory implements SquareInfo {

  static $inject = ['$stateParams'];

  constructor(private $stateParams: ng.ui.IStateParamsService) {
    useSquareStore().$subscribe(() => this.infoChanged());
  }
  private _squareInfo = new ReplaySubject<SquareInfo>(1);

  get Id() {
    return useSquareStore().id;
  }

  get Name() {
    return useSquareStore().name;
  }

  get SquareUrl() {
    return useSquareStore().url;
  }

  get SquareCompleteUrl() {
    return useSquareStore().completeUrl;
  }

  get Status() {
    return useSquareStore().status;
  }

  get squareUrlPromise() {
    return this.squareInfoObservable
      .map((s) => s.SquareUrl)
      .filter((s) => !!s)
      .first()
      .toPromise();
  }

  get SsoEnabled() {
    return useSquareStore().ssoEnabled;
  }

  get Language() {
    return useSquareStore().language;
  }

  get RightToLeft() {
    return useSquareStore().rightToLeft;
  }

  get languageObservable() {
    return this._squareInfo.filter((s) => !!s).map((s) => s.Language);
  }

  get languagePromise() {
    return this.languageObservable
      .filter((i) => !!i)
      .first()
      .toPromise();
  }

  get Guid() {
    return useSquareStore().guid;
  }

  get StartDate() {
    return useSquareStore().startDate as DateTime;
  }

  get CreateDate() {
    return useSquareStore().createDate as DateTime;
  }

  get Token() {
    return useSquareStore().token;
  }

  get AllowPiiInExports() {
    return useSquareStore().allowPiiInExports;
  }

  get squareStartDatePromise() {
    return this.squareInfoObservable
      .map((s) => DateTime.fromISO(s.StartDate as any as string))
      .filter((s) => !!s)
      .first()
      .toPromise();
  }

  get squareCreateDatePromise() {
    return this.squareInfoObservable
      .map((s) => DateTime.fromISO(s.CreateDate as any as string))
      .filter((s) => !!s)
      .first()
      .toPromise();
  }

  get squareInfoObservable() {
    return this._squareInfo.distinctUntilChanged((a, b) => a.Guid === b.Guid);
  }

  get clientGuid() {
    // when using a direct square link (email, browser) or in other cases,
    // square store is not initialized and has no client identification
    return useSquareStore().clientGuid || this.$stateParams.clientGuid;
  }

  get squareStatusPromise() {
    return this.squareInfoObservable
      .map((s) => s.Status)
      .filter((s) => !!s)
      .first()
      .toPromise();
  }

  get isSquareAnonymous() {
    return useSquareStore().isAnonymous;
  }

  basicInfoUpdate(clientGuid: string) {
    useSquareStore().clientGuid = clientGuid;
  }

  setSquareInfo(squareInfo: SquareInfo) {
    const camelCasedSquareInfo = Utils.convertPascalCasedObjectToCamelCasedObject(squareInfo);
    useSquareStore().setSquareInfo({
      ...useSquareStore().$state,
      ...camelCasedSquareInfo,
      url: camelCasedSquareInfo.squareUrl,
      isAnonymous: camelCasedSquareInfo.anonymous,
    });
  }

  resetSquareInfo() {
    // preserve client identification (if any) when resetting square info
    useSquareStore().resetSquareInfo(this.$stateParams.clientGuid);
  }

  private infoChanged() {
    this._squareInfo.next({
      Guid: this.Guid,
      Name: this.Name,
      SquareUrl: this.SquareUrl,
      SsoEnabled: this.SsoEnabled,
      Language: this.Language,
      StartDate: this.StartDate,
      CreateDate: this.CreateDate,
      RightToLeft: this.RightToLeft,
      Status: this.Status,
      Anonymous: this.isSquareAnonymous,
      Token: this.Token,
      ClientGuid: this.clientGuid,
      Id: this.Id,
      AllowPiiInExports: this.AllowPiiInExports,
    });
  }
}

angular
  .module('insitesApp.core')
  .service('selectedSquareFactory', SelectedSquareFactory);
