'use strict';

import { HttpService } from './http.service';
import { IHttpResponse } from 'angular';
import {
  ICreateOrUpdateDiscussionRequest, ICreateOrUpdateDiscussionResponse, ICreateOrUpdateMessageRequest,
  ICreateOrUpdateMessageResponse, ICreateOrUpdatePostRequest, ICreateOrUpdatePostResponse, ICreateOrUpdateQuestionRequest,
  ICreateOrUpdateQuestionResponse, ICreateStimulusRequest, ICreateStimulusResponse, IRemovalResponse,
  IDiscussionActivityRequest, IDiscussionActivityResponse, IRemoveStimulusRequest,
  IRemoveQuestionRequest,
  IUpdateStimulusRequest,
} from '../contracts/discussion.contract';
import {
  ICreateOrUpdateDiscussionActivityRequest, ICreateOrUpdateDiscussionActivityResponse,
} from '../contracts/discussionActivity.contract';
import { IRemoveTopicRequest } from '../contracts/discussionLounge.contract';

export class DiscussionService {
  static $inject = ['httpservice'];

  constructor(
    private httpService: HttpService,
  ) { }

  async getDiscussionActivity(request: IDiscussionActivityRequest): Promise<IDiscussionActivityResponse> {
    const response: IHttpResponse<IDiscussionActivityResponse> = await this.httpService.get({
      url: '/DiscussionActivityService/Get',
      params: request,
    });

    const data = response.data;
    if (data) {
      const getOpeningPostResponse = await this.httpService.get({
        url: '/DiscussionActivityService/GetOpeningPost',
        params: {
          activityGuid: data.activityGuid,
        },
      });
      data.post = getOpeningPostResponse.data.post;
    }
    return data;
  }

  async createOrUpdateDiscussion(request: ICreateOrUpdateDiscussionRequest): Promise<ICreateOrUpdateDiscussionResponse> {
    const response: IHttpResponse<ICreateOrUpdateDiscussionResponse> = await this.httpService.post({
      url: '/DiscussionService/CreateOrUpdateDiscussion',
      data: request,
    });
    return response.data;
  }

  async createOrUpdatePost(request: ICreateOrUpdatePostRequest): Promise<ICreateOrUpdatePostResponse> {
    const response: IHttpResponse<ICreateOrUpdatePostResponse> = await this.httpService.post({
      url: '/DiscussionService/CreateOrUpdatePost',
      data: request,
    });
    return response.data;
  }

  async createOrUpdateMessage(request: ICreateOrUpdateMessageRequest): Promise<ICreateOrUpdateMessageResponse> {
    const response: IHttpResponse<ICreateOrUpdateMessageResponse> = await this.httpService.post({
      url: '/DiscussionService/CreateOrUpdateMessage',
      data: request,
    });
    return response.data;
  }

  async createOrUpdateQuestion(request: ICreateOrUpdateQuestionRequest): Promise<ICreateOrUpdateQuestionResponse> {
    const response: IHttpResponse<ICreateOrUpdateQuestionResponse> = await this.httpService.post({
      url: '/DiscussionService/CreateOrUpdateQuestion',
      data: request,
    });
    return response.data;
  }

  async removeQuestion(request: IRemoveQuestionRequest): Promise<IRemovalResponse> {
    const response: IHttpResponse<IRemovalResponse> = await this.httpService.post({
      url: '/DiscussionService/RemoveQuestion',
      data: request,
    });
    return response.data;
  }

  async createOrUpdateDiscussionActivity(request: ICreateOrUpdateDiscussionActivityRequest): Promise<ICreateOrUpdateDiscussionActivityResponse> {
    const response: IHttpResponse<ICreateOrUpdateDiscussionActivityResponse> = await this.httpService.post({
      url: '/DiscussionActivityService/CreateOrUpdateDiscussionActivity',
      data: request,
    });
    return response.data;
  }

  async createStimulus(stimulus: ICreateStimulusRequest): Promise<ICreateStimulusResponse> {
    const response: IHttpResponse<ICreateStimulusResponse> = await this.httpService.post({
      url: '/DiscussionService/CreateStimulus',
      data: stimulus,
    });
    return response.data;
  }

  async updateStimulus(stimulus: IUpdateStimulusRequest): Promise<IUpdateStimulusRequest> {
    const response = await this.httpService.post({
      url: '/DiscussionService/UpdateStimulus',
      data: stimulus,
    });

    return response.data;
  }

  async removeStimulus(stimulusGuid: IRemoveStimulusRequest): Promise<IRemovalResponse> {
    const response: IHttpResponse<IRemovalResponse> = await this.httpService.post({
      url: '/DiscussionService/RemoveStimulus',
      data: stimulusGuid,
    });
    return response.data;
  }

  async removeTopic(request: IRemoveTopicRequest): Promise<boolean> {
    const response: IHttpResponse<IRemovalResponse> = await this.httpService.post({
      url: '/DiscussionLoungeService/RemoveTopic',
      data: request,
    });
    return response.data.success;
  }

}
