'use strict';

import { ActivityProbeQualPresentController } from './activityProbeQualPresent.controller';

const template = require('./activityProbeQualPresent.html');

export const ActivityProbeQualPresentComponent: ng.IComponentOptions = {
  controller: ActivityProbeQualPresentController,
  templateUrl: template,
  controllerAs: 'vm',
  require: {
    wizardStep: '^^iscWizardStep',
  },
  bindings: {
    squareActivity: '<',
    isReadOnly: '<',
    isPublished: '<',
  },
};
