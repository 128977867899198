'use strict';

import { SpinnerService } from '../core/services/spinner.service';
import { ConfigurationService } from '../core/dataservices/configuration.service';

export class ConfigController implements ng.IOnInit {
  static $inject = ['$q', 'spinnerservice', 'configurationService'];
  constructor(
    private $q: ng.IQService,
    private spinnerservice: SpinnerService,
    private configurationService: ConfigurationService,
  ) {
  }

  isFetching = false;

  entries = [];
  $onInit(): void {
    this.activate();
  }

  activate = () => {
    this.spinnerservice.show('loading');
    const promises = [this.getLogList()];
    return this.$q.all(promises)
      .finally(() => {
        this.spinnerservice.hide('loading');
      });
  };

  getLogList = () => {
    const _this = this;
    this.configurationService.getConfigList()
      .then((response) => {
        const data = response.data;
        for (const entry in data) {
          _this.entries.push({ key: entry, value: data[entry] });
        }
        return _this.$q.resolve(response);
      });
  };

}
