'use strict';

import { ActivityConfigComponent } from './activityConfig.component';

import './activityConfig.module';
import './activityQuantResearchConfig';

angular
  .module('insitesApp.activityconfig')
  .component('activityConfigComponent', ActivityConfigComponent);
