'use strict';

import * as angular from 'angular';

import routeConfig from './activitiesAllTab.route';
import { activitiesAllTab } from './activitiesAllTab.component';

angular
  .module('insitesApp.squareActivities')
  .component('activitiesAllTab', activitiesAllTab)
  .run(routeConfig);
