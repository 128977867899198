'use strict';

import * as _ from 'lodash';
import { DateTime } from 'luxon';
import { HttpService } from './http.service';

export class PlatformNoticeService {

  static $inject = [
    'httpservice',
    'serverConstants',
  ];

  private _platformNoticeTypes;
  private _platformNoticeTypeConstants;
  private _customnoticeMaxLength;

  constructor(
    private httpService: HttpService,
    private serverConstants,
  ) {
    this._customnoticeMaxLength = this.serverConstants.validationConstants.platformNoticeCustomMaxLength;
    this._platformNoticeTypeConstants = this.serverConstants.platformNoticeTypeConstants;
    this._platformNoticeTypes = [
      {
        Label: 'None',
        Type: this.platformNoticeTypeConstants.none,
      },
      {
        Label: 'Default maintenance',
        Type: this.platformNoticeTypeConstants.defaultMaintenance,
      },
      {
        Label: 'Custom notice',
        Type: this.platformNoticeTypeConstants.customNotice,
      },
    ];
  }

  get platformNoticeTypes() {
    return this._platformNoticeTypes;
  }

  get platformNoticeTypeConstants() {
    return this._platformNoticeTypeConstants;
  }

  get customMessageMaxLength() {
    return this._customnoticeMaxLength;
  }

  existingNotice() {
    return this.httpService.get({
      url: '/PlatformNoticeService/GetCurrentPlatformNotice',
    }).then((response) => response.data);
  }

  displayNotice() {
    return this._displayNotice();
  }

  private _displayNotice = _.memoize(() => this.httpService.get({
    url: '/PlatformNoticeService/GetCurrentPlatformNoticeDisplay',
    params: {
      language: 'en',
      square: false,
    },
  }).then((response) => response.data));

  appendNotice(notice: IPlatformNotice) {
    this._displayNotice.cache = new _.memoize.Cache();
    return this.httpService.post({
      url: '/PlatformNoticeService/PutPlatformNotice',
      data: notice,
    }).then((response) => response.data);
  }

  // eslint-disable-next-line max-len
  private readonly DEFAULT_TEMPLATE = 'On [Day] ([Month, DD]) at [HH:MM] (UTC), there will be scheduled down time of the platform for approximately [#] hours. During this maintenance window, we will perform necessary upgrades to our infrastructure to guarantee you a better platform experience in the future. We appreciate your patience and understanding.';

  defaultNoticeMessage(defaultTemplate, startDateTime, lengthInHours) {
    let result = '';
    if (startDateTime && lengthInHours) {
      const utc = DateTime.fromISO(startDateTime);
      result = defaultTemplate || this.DEFAULT_TEMPLATE;
      result = result
        .replace('[Day]', utc.toFormat('EEE'))
        .replace('[Month, DD]', utc.toFormat('MMMM, dd'))
        .replace('[HH:MM]', utc.toFormat('h:mm a'))
        .replace('[#]', lengthInHours);
    }
    return result;
  }

}

export interface IPlatformNoticeDisplay {
  noticeType,
  startNoticeDateTime: DateTime,
  endNoticeDateTime: DateTime,
  customMessage,
  defaultTemplates?,
}

export interface IPlatformNotice extends IPlatformNoticeDisplay {
  startDateTime: DateTime,
  lengthOfMaintenance,
  targetInSites,
  targetModerators,
  targetObservers,
}

