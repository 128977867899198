'use strict';

import { Utils } from '../../../../core/utils';
import { IncentiveService } from '../../../../core/dataservices/incentive.service';
import { RewardService } from '../../../../core/dataservices/reward.service';
import { ClipboardService } from '../../../../core/services/clipboard.service';
import { ServerConstants } from '../../../../core/serverconstants';
import { SpinnerService } from '../../../../core/services/spinner.service';

export class InstantRewardsDetailsController {
  static $inject = ['$mdDialog', 'dateFormatService', 'rewardservice', '_', 'dataForModal',
    '$q', 'spinnerservice', 'rewardsService', 'serverConstants', 'incentiveservice', 'logger', 'ClipboardService'];

  constructor(
    private $mdDialog,
    private dateFormatService,
    private rewardservice: RewardService,
    private _: _.LoDashStatic,
    private dataForModal,
    private $q: ng.IQService,
    private spinnerservice: SpinnerService,
    private rewardsService,
    private serverConstants: ServerConstants,
    private incentiveservice: IncentiveService,
    private logger,
    private clipboardService: ClipboardService,
  ) {
    this.activate();
  }

  modifiedWinners = [];
  rewardPartners = [];
  anyWinners: boolean = false;
  anyWinnersNotApproved = false;
  isSaving = false;
  model = {
    Name: this.dataForModal.surveyName,
    EndDateOffset: this.dataForModal.offset,
    EndDateTime: this.dateFormatService.getDateTime(this.dataForModal.raffleExecutionDate, this.dataForModal.offset),
    Rewards: [],
    Winners: [],
  };
  hasIncompleteWinners = false;
  winnerStatus = this.serverConstants.winnerStatusConstants;
  instantRewardDetail: ng.IFormController;

  activate() {
    this.spinnerservice.show('loading');
    this.initRewardPartners();
    this.$q.all([this.getWinners(), this.getRewards()])
      .then(() => {
        this.checkIncompleteWinners();
      })
      .finally(() => {
        this.spinnerservice.hide('loading');
      });
  }

  initRewardPartners() {
    this.rewardPartners = this.rewardsService.getRewardPartners();
  }

  checkIncompleteWinners() {
    this._.forEach(this.model.Winners, (winner) => {
      this.hasIncompleteWinners = !winner.IsCompleted;
      if (this.hasIncompleteWinners) {
        return;
      }
    });
  }

  approve(winner) {
    this.markWinnerAsModified(winner);
    winner.RewardStatus = 'Pending Approval';
    winner.IsApprovalPending = true;
    this.instantRewardDetail.$setDirty();
  }

  onRewardPartnerChanged(winner, value) {
    winner.RewardPartner = value;
    this.markWinnerAsModified(winner);
  }

  markWinnerAsModified(winner) {
    if (!this._.includes(this.modifiedWinners, winner)) {
      this.modifiedWinners.push(winner);
    }
  }

  save() {
    this.isSaving = true;
    let index = 0;
    this._.forEach(this.modifiedWinners, (winner) => {
      this.rewardservice.updateWinner({
        WinnerGuid: winner.WinnerGuid,
        IsApproved: winner.IsApprovalPending,
        RewardPartner: winner.RewardPartner,
      }).then(() => {
        winner.IsApproved = winner.IsApprovalPending;
        winner.IsCompleted = winner.IsApproved && winner.RewardPartner;
        winner.Status = winner.IsApproved ? 'Approved' : 'Not approved';

        if (index === this.modifiedWinners.length - 1) {
          this.instantRewardDetail.$setPristine();
          this.isSaving = false;
        } else {
          index++;
        }
        this.incentiveservice.invalidateCache();

        this.checkIncompleteWinners();
        this.logger.success('Instant reward saved successfully');
      });
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  getWinners() {
    return this.incentiveservice.getWinnersInstant(this.dataForModal.activityGuid).then((winnersResponse) => {
      this.anyWinners = winnersResponse.WinnersDetailsList.length >= 1;
      if (winnersResponse && winnersResponse.WinnersDetailsList) {
        this.model.Winners = winnersResponse.WinnersDetailsList;

        this._.forEach(this.model.Winners, (winner) => {
          winner.IsApproved = winner.Status !== 'Not approved';
          if (!winner.IsApproved) {
            this.anyWinnersNotApproved = true;
          }
          winner.RewardStatus = winner.IsApproved ? 'Approved' : 'Approve';
          if (winner.Segment === null || winner.Segment === undefined) {
            winner.Segment = 'All';
          }
          winner.IsCompleted = winner.IsApproved && winner.RewardPartner;
          winner.IsRewardPartnerDisabled = winner.RewardPartner === this.serverConstants.rewardPartnerTypeConstants.other;
        });
      }
    });
  }

  copyToClipboard(url) {
    this.clipboardService.copyToClipboard(url);
  }

  showCopyToClipboard(reward) {
    if (reward.RewardPartner === this.serverConstants.rewardPartnerTypeConstants.cadora
      && reward.Status === Utils.getEnumValueName(this.winnerStatus, this.winnerStatus.offeredToRedeem)) {
      return true;
    }
    return false;

  }

  getRewards() {
    return this.incentiveservice.getRewardsInstant(this.dataForModal.activityGuid)
      .then((rewardsResponse) => {
        if (rewardsResponse && rewardsResponse.RewardsInstantDetailsList) {
          this.model.Rewards = rewardsResponse.RewardsInstantDetailsList;

          this._.forEach(this.model.Rewards, (item) => {
            item.Approval = item.IsAutomaticApproved ? 'Automatic' : 'Manual';
            if (item.KeySegmentation === null || item.KeySegmentation === undefined) {
              item.KeySegmentation = 'All segments';
            }
            if (item.KeySegment === null || item.KeySegment === undefined) {
              item.KeySegment = 'All segments';
            }
          });
        }
      });
  }
}
