'use strict';

import { ActivityScoutBuildController } from './activityScoutBuild.controller';
require('./activityScoutBuild.scss');

const template = require('./activityScoutBuild.html');

export const ActivityScoutBuildComponent: ng.IComponentOptions = {
  controller: ActivityScoutBuildController,
  templateUrl: template,
  controllerAs: 'vm',
  require: {
    wizardStep: '^^iscWizardStep',
  },
  bindings: {
    squareActivity: '=',
    isReadOnly: '<',
    isPublished: '<',
  },
};
