'use strict';

import { ActivityDataPostController } from './activityDataPost.controller';
const template = require('./activityDataPost.html');

require('./activityDataPost.scss');

angular.module('insitesApp.activityData').component('iscActivityDataPost', {
  controller: ActivityDataPostController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    item: '<',
    reloadPosts: '&',
    postUpdateRedirectText: '<',
    showRatingAction: '<',
    showNoteAction: '<',
    setNoteOnTop: '&',
    showTagAction: '<',
    setTagOnTop: '&',
    hideReplyAction: '<',
    hideLikeAction: '<',
    hideUpdateAction: '<',
    hideRemoveAction: '<',
    disableRatingAction: '<',
    keyword: '<',
    highlight: '<',
    disableTagAction: '<',
    isDividedDiscussionType: '<',
    conversationFocusType: '<',
    contributionType: '<?',
    activityEndDatePassed: '<',
    activityStatus: '<',
    activityType: '<',
    activityStartDate: '<?',
    isModeratorCurationEnabled: '<?',
    visibilityBufferPeriodPassed: '<',
  },
});
