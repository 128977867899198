'use strict';

const publishSavingUpTemplate = require('./publishSavingUp.html');

angular
  .module('insitesApp.activityconfig')
  .component('publishSavingUp', {
    templateUrl: publishSavingUpTemplate,
    controllerAs: 'vm',
    bindings: {
      completionPoints: '<',
    },
  });
