'use strict';

import * as _ from 'lodash';
import { HttpService } from './http.service';

export class ProjectService {
  static $inject = ['httpservice'];

  constructor(
    private httpService: HttpService,
  ) { }

  getListLabel(language) {
    return this._getListLabel(language);
  }

  private _getListLabel = _.memoize((language) => this.httpService.get({
    url: '/ProjectService/ListLabelInLanguage',
    params: {
      language,
    },
  }));

  getListEmailLabel(language) {
    return this._getListEmailLabel(language);
  }

  private _getListEmailLabel = _.memoize((language) => this.httpService.get({
    url: '/ProjectService/ListEmailLabelInLanguage',
    params: {
      language,
    },
  }));

  getListEmailDefaultLabel(language) {
    return this._getListEmailDefaultLabel(language);
  }

  private _getListEmailDefaultLabel = _.memoize((language) => this.httpService.get({
    url: '/ProjectService/ListEmailDefaultLabelInLanguage',
    params: {
      language,
    },
  }));

  async getLanguageList() {
    return await this._getLanguageList();
  }

  private _getLanguageList = _.memoize(() => this.httpService.get({
    url: '/ProjectService/ListLanguage',
  }).then((response) => response.data.Languages));

  async getCultureList() {
    return await this._getCultureList();
  }

  private _getCultureList = _.memoize(() => this.httpService.get({
    url: '/ProjectService/ListCulture',
  }).then((response) => response.data.Cultures));

  getJobIds() {
    return this.httpService.get({
      url: '/ProjectService/SelectionJob',
    });
  }

  async getCurrencies() {
    return await this._getCurrencies();
  }

  private _getCurrencies = _.memoize(() => this.httpService.get({
    url: '/ProjectService/ListCurrency',
  }).then((response) => response.data));

  async getNoRewardPartnerCurrencies() {
    return await this._getNoRewardPartnerCurrencies();
  }

  private _getNoRewardPartnerCurrencies = _.memoize(() => this.httpService.get({
    url: '/ProjectService/ListNoRwardPartnerCurrency',
  }).then((response) => response.data));
}
