'use strict';

import { AuthService } from 'src/app/core/dataservices/auth.service';
import { ProgramService } from './dataservices/program.service';
import { SelectedProgramFactory } from './selectedprogram.factory';
import { ActivityService } from './dataservices/activity.service';
import { ClientService } from './dataservices/client.service';
import { SquareService } from './dataservices/square.service';
import { FeatureService } from './dataservices/feature.service';
import { SelectedActivityFactory } from './selectedactivity.factory';
import { SelectedSquareFactory } from './selectedsquare.factory';
import { SelectedClientFactory } from './selectedclient.factory';
import 'rxjs/add/operator/filter';
import { CurrentUserService } from './dataservices/currentUser.service';

const EMPTYGUID = '00000000-0000-0000-0000-000000000000';

const SelectedFactoriesReset = (
  $rootScope: ng.IRootScopeService,
  selectedClientFactory: SelectedClientFactory,
  selectedProgramFactory: SelectedProgramFactory,
  selectedSquareFactory: SelectedSquareFactory,
  selectedActivityFactory: SelectedActivityFactory,
  clientService: ClientService,
  programService: ProgramService,
  squareService: SquareService,
  activityService: ActivityService,
  featureService: FeatureService,
  $transitions,
  authService: AuthService,
  currentUserService: CurrentUserService,
) => {
  $transitions.onSuccess({}, (transition) => {
    if (!transition.params('to').clientGuid) {
      selectedClientFactory.resetClientInfo();
    }

    if (!transition.params('to').programGuid) {
      selectedProgramFactory.resetProgramInfo();
    }

    if (!transition.params('to').squareGuid) {
      selectedSquareFactory.resetSquareInfo();
    }

    if (!transition.params('to').activityGuid) {
      selectedActivityFactory.resetActivityInfo();
    }
  });

  selectedClientFactory
    .clientInfoObservable
    .map((info) => info.Guid)
    .filter((guid) => !!guid)
    .subscribe(async (guid) => {
      clientService.updateLastSelectedDate(guid);
      await authService.setTokenForClient(guid);
    });

  selectedProgramFactory
    .programInfoObservable
    .map((info) => info.Guid)
    .filter((guid) => !!guid)
    .subscribe((guid) => programService.updateLastSelectedDate(guid));

  selectedSquareFactory
    .squareInfoObservable
    .map((info) => info.Guid)
    .filter((guid) => !!guid)
    .subscribe(async (guid) => {
      featureService.invalidateCache();
      squareService.updateLastSelectedDate(guid);
      await authService.setTokenForSquare(EMPTYGUID, guid);
      await currentUserService.getImpersonateUserList();
    });

  selectedActivityFactory
    .activityInfoObservable
    .map((info) => info.Guid)
    .filter((guid) => !!guid)
    .subscribe((guid) => activityService.updateLastSelectedDate(guid));
};

SelectedFactoriesReset.$inject = ['$rootScope',
  'selectedClientFactory', 'selectedProgramFactory', 'selectedSquareFactory', 'selectedActivityFactory',
  'clientservice', 'programservice', 'squareservice', 'activityservice', 'featureservice', '$transitions',
  'authService', 'currentUserService'];

angular
  .module('insitesApp.core')
  .run(SelectedFactoriesReset);

export default SelectedFactoriesReset;
