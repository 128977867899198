'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const getStates = () => [{
  state: 'root.clientconfig',
  config: {
    abstract: true,
    settings: {
      nav: 3,
      icon: 'fa fa-wrench',
      label: 'Configuration',
      section: 'CLIENT SETTINGS',
      stateParams: ['clientGuid'],
      showForums: true,
    },
    disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
  },
}];

const appRunClientConfiguration = (routerHelper: RouterHelper) => {
  routerHelper.configureStates(getStates());
};

appRunClientConfiguration.$inject = ['routerHelper'];

export default appRunClientConfiguration;
