'use strict';

import * as angular from 'angular';
import routeConfig from './landing.route';
import { LandingPage, createApp } from '@insites/vue-eco-temp-library';
import { CurrentUserService } from 'src/app/core/dataservices/currentUser.service';
import { VueToggle } from 'src/app/core/vueToggle';

angular
  .module('insitesApp.home')
  .component('landingPage', {
    controller: ['currentUserService','VueToggle',
      function(currentUserService: CurrentUserService, vueToggle: VueToggle) {
        const app = createApp(LandingPage);
        this.$postLink = () => {
          app.provide('currentUser', {
            avatar: currentUserService.userProfile.Avatar,
            firstname: currentUserService.userProfile.Firstname,
            guid: currentUserService.userProfile.Guid,
            lastname: currentUserService.userProfile.Lastname,
            role: currentUserService.userProfile.Role,
            username: currentUserService.userProfile.Username,
          });
          vueToggle.mount(app, 'landing-page');
        };
        this.$onDestroy = () => {
          vueToggle.unMount();
        };
      }],
  })
  .run(routeConfig);
