'use strict';

import { HttpService } from './http.service';
import { ResponseType } from './responseType';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import * as _ from 'lodash';

export class SearchService {
  static $inject = ['httpservice'];
  constructor(
    private httpService: HttpService) {
  }
  // //////////////

  private clearCache = false;
  private invalidated: Subject<boolean> = new Subject<boolean>();

  get Invalidated(): Observable<boolean> {
    return this.invalidated.asObservable();
  }

  async getSearchSelectionForDropdown(params) {
    if (this.clearCache) {
      this._getSearchSelectionForDropdown.cache = new _.memoize.Cache();
      this.clearCache = false;
    }
    return (await this._getSearchSelectionForDropdown(params)).data.List;
  }

  private _getSearchSelectionForDropdown = _.memoize(async (params) => this.httpService.get({
    url: '/SearchService/Selection',
    params,
  }));

  getSearchSelectionForDropdownActivity(params) {
    return this.getSearchSelectionForDropdown({
      SearchType: ResponseType.Activity,
      ...params,
    });
  }

  getSearchSelectionForDropdownProgram() {
    return this.getSearchSelectionForDropdown({
      SearchType: ResponseType.Program,
    });
  }

  getSearchSelectionForDropdownSquare(params) {
    return this.getSearchSelectionForDropdown({
      SearchType: ResponseType.Square,
      ...params,
    });
  }

  invalidate() {
    this.clearCache = true;
    this.invalidated.next(true);
  }

}
