'use strict';

import { ActivityDataConversationsController } from './activityDataConversations.controller';
const template = require('./activityDataConversations.html');

angular.module('insitesApp.activityData').component('activityDataConversationsComponent', {
  controller: ActivityDataConversationsController,
  controllerAs: 'vm',
  templateUrl: template,
});
