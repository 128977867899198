'use strict';

import { IPromise } from 'angular';
import { FaqCategories, FaqQuestions } from '../contracts/page.contract';
import { HttpService } from './http.service';

export class PageService {
  static $inject = ['httpservice'];

  constructor(
    private httpService: HttpService,
  ) { }

  getSquarePages(squareGuid: string, area: number) {
    return this.httpService.get({
      url: '/PageService/ListPageAdmin',
      params: { SquareGuid: squareGuid, area },
    });
  }

  getFAQCategories(pageGuid: string): IPromise<FaqCategories> {
    return this.httpService.get<FaqCategories>({
      url: '/PageService/ListFaqCategory',
      params: {
        pageGuid,
      },
    }).then((response) => response.data);
  }

  getFAQQuestions(pageGuid: string): IPromise<FaqQuestions> {
    return this.httpService.get<FaqQuestions>({
      url: '/PageService/ListFaqQuestion',
      params: {
        pageGuid,
      },
    }).then((response) => response.data);
  }

  isFaqCategoryNameUnique(category) {
    return this.httpService.get({
      url: '/PageService/IsFaqCategoryNameUnique',
      params: category,
    });
  }

  isFaqQuestionTitleUnique(question) {
    return this.httpService.get({
      url: '/PageService/IsFaqQuestionTitleUnique',
      params: question,
    });
  }

  anyNewsPageForSquare() {
    return this.httpService.get({
      url: '/PageService/AnyNewsPageForSquare',
    }).then((response) => response.data);
  }
}
