'use strict';

import { MemberDetailsController } from './memberDetails.controller';
const template = require('./memberDetails.html');
require('./_memberDetails.scss');

angular.module('insitesApp.squaremembers.memberOverview').component('memberDetails', {
  controller: MemberDetailsController,
  controllerAs: 'vm',
  templateUrl: template,
});
