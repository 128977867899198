'use strict';

const template = require('./custom.html');

angular
  .module('insitesApp.squareconfig.design.custom')
  .component('squareConfigCustom', {
    templateUrl: template,
    controller: SquareConfigDesignCustomController,
    controllerAs: 'vm',
  });

SquareConfigDesignCustomController.$inject = [];
function SquareConfigDesignCustomController() {
  const vm = this;

  vm.$onInit = () => {
  };
}
