'use strict';

import { Pagination } from '../../../core/models/pagination';
import { SpinnerService } from '../../../core/services/spinner.service';

const instantRewardsDetailsTemplate = require('./details/instantRewardsDetails.html');
export class InstantRewardsController {
  static $inject = ['helperservice', 'incentiveservice', '_', '$mdDialog', 'spinnerservice', 'rewardsService', 'serverConstants'];
  constructor(
    private helperservice,
    private incentiveservice,
    private _,
    private $mdDialog,
    private spinnerservice: SpinnerService,
    private rewardsService,
    private serverConstants) {

  }

  pagination: IPagination = new Pagination();
  instantRewards;
  filter;

  $onInit() {
    this.spinnerservice.show('loading');
    this.incentiveservice.getSquareInstantRewards()
      .then((data) => {
        if (data && data.List) {
          this.instantRewards = data.List;
          this._.each(this.instantRewards, (instantReward) => {
            instantReward.RewardStatus = this.rewardsService.getRewardStatus(instantReward);
          });
        }
      }).finally(() => {
        this.spinnerservice.hide('loading');
      });
  }

  showDialog(instantReward) {
    this.$mdDialog.show({
      controller: 'instantRewardsDetailsController',
      controllerAs: 'vm',
      templateUrl: instantRewardsDetailsTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        dataForModal: {
          activityGuid: instantReward.ActivityGuid,
          surveyName: instantReward.Name,
          raffleExecutionDate: instantReward.RaffleExecutionDate,
          offset: instantReward.Offset,
        },
        rewardPartnerTypeConstants: this.serverConstants.rewardPartnerTypeConstants,
      },
    });
  }

  search(filter) {
    return this.helperservice.search(filter, this.filter);
  }
}
