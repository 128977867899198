'use strict';

import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { RouterHelper } from '../../../blocks/router/router-helper';
import { SocialLoginController } from './sociallogin.controller';
import { ServerConstants } from 'src/app/core/serverconstants';
import { SquareService } from 'src/app/core/dataservices/square.service';

const template = require('./sociallogin.html');

const routeConfig = (routerHelper: RouterHelper,
  $stateParams,
  serverConstants: ServerConstants,
  featureservice: FeatureService,
  squareService: SquareService) => {
  routerHelper.configureStates([{
    state: 'root.square.sociallogin',
    config: {
      url: '/config/sociallogin',
      templateUrl: template,
      controller: SocialLoginController,
      controllerAs: 'vm',
      title: 'Social login',
      settings: {
        nav: 2,
        childnav: 8,
        label: 'Social login',
        showForums: true,
        show: async () => await hasAccessToSocialLogin(),
      },
      disallowRoles: ['Observer'],
    },
  }]);

  const hasAccessToSocialLogin = async (): Promise<boolean> => {
    // first, check if AD login is enabled for the square
    const b2cEnabled = await featureservice.checkFeatureAccessibilityForSetup(
      serverConstants.featureConstants.azureAdB2CLogin);
    if (!b2cEnabled) {
      return false;
    }

    // check if landing page is enabled on square
    const squareDetails = await squareService.getSquareDetails($stateParams.squareGuid);
    return squareDetails.data.Detail.AdLandingPage === true;
  };

};

routeConfig.$inject = ['routerHelper', '$stateParams', 'serverConstants', 'featureservice', 'squareservice'];

export default routeConfig;
