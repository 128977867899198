'use strict';

import { MemberDetailsActivitiesController } from './memberDetailsActivities.controller';
const template = require('./memberDetailsActivities.html');

angular.module('insitesApp.squaremembers.memberOverview').component('memberDetailsActivities', {
  controller: MemberDetailsActivitiesController,
  controllerAs: 'vm',
  templateUrl: template,
});
