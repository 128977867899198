'use strict';

import { HttpService } from '../../core/dataservices/http.service';

export class SquareDownloadController {

  static $inject = ['$stateParams', 'httpservice'];

  constructor(
    private $stateParams,
    private httpService: HttpService,
  ) {
  }

  $onInit() {
    if (this.$stateParams.downloadGuid) {
      this.httpService.post({
        url: '/ExportService/DownloadExport',
        params: {
          download: this.$stateParams.downloadGuid,
        },
      });
    }
  }

}
