'use strict';

import { ImportLocationsDialogController } from './importLocationsDialog/importLocationsDialog.controller';
import { GeofencingService } from './../../../core/dataservices/geofencing.service';
import { JobService } from './../../../core/jobs/job.service';
import { ServerConstants } from '../../../core/serverconstants';
import { SelectedSquareFactory } from './../../../core/selectedsquare.factory';
import { AuthService } from 'src/app/core/dataservices/auth.service';

const importLocationsDialogTemplateUrl = require('./importLocationsDialog/importLocationsDialog.html');

export class GeofencingController {
  static $inject = ['$rootScope', '$mdDialog', 'geofencingservice', 'jobservice', 'serverConstants', 'selectedSquareFactory', 'authService'];

  constructor(
    private $rootScope: ng.IRootScopeService,
    private $mdDialog: ng.material.IDialogService,
    private geofencingservice: GeofencingService,
    private jobservice: JobService,
    private serverConstants: ServerConstants,
    private selectedSquareFactory: SelectedSquareFactory,
    private authService: AuthService,
  ) {

  }

  form;
  statisticsForm;
  notificationText;
  isDownloadDisabled = false;
  squareLanguage;
  isSaving = false;
  statistics;

  async $onInit() {
    this.squareLanguage = await this.selectedSquareFactory.languagePromise;
    this.notificationText = await this.geofencingservice.getDefaultGeofencingNotificationText(this.squareLanguage);
    this.statistics = await this.geofencingservice.getGeofencingStatistics();
    this.$rootScope.$on('jobprogressclosed', async () => {
      // import job dialog was closed, so we fetch statistics again
      this.statistics = await this.geofencingservice.getGeofencingStatistics();
    });
  }

  savePage() {
    this.isSaving = true;
    this.geofencingservice.setDefaultGeofencingNotificationText(this.notificationText, this.squareLanguage)
      .then(() => this.form.$setPristine())
      .finally(() => this.isSaving = false);
  }

  async exportLocations() {
    this.isDownloadDisabled = true;
    const jobResult = await this.geofencingservice.exportGeofencingLocations();
    if (jobResult?.data) {
      await this.jobservice.showJobProgressDialog(jobResult.data, 'Export location data');
    }
    this.isDownloadDisabled = false;
  }

  importLocations() {
    this.$mdDialog.show({
      controller: ImportLocationsDialogController,
      controllerAs: 'vm',
      templateUrl: importLocationsDialogTemplateUrl,
      clickOutsideToClose: false,
    });
  }

}
