'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'root.clientinfo',
    config: {
      url: 'client/:clientGuid',
      component: 'clientInfo',
      title: 'Client Info',
      requireADLogin: false,
      settings: {
        nav: 1,
        section: 'CLIENT SETTINGS',
        icon: 'fa fa-gauge',
        label: 'Client info',
      },
      disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

