'use strict';

import { ActivityService } from '../../../../../core/dataservices/activity.service';
import { SquareService } from '../../../../../core/dataservices/square.service';
import { ActivityRecruitmentPartnerService } from '../../../../../core/dataservices/activityrecruitmentpartner.service';
import { ServerConstants } from '../../../../../core/serverconstants';
import { RecruitmentPartner } from './recruitmentPartner.model';
import {
  ActivityRecruitmentPartner, ActivityRecruitmentPartnerConsentOption, ActivityRecruitmentPartnerConsentListItem,
} from './activityRecruitmentPartner.contracts';
import * as _ from 'lodash';
import { FeatureService } from '../../../../../core/dataservices/feature.service';
import { DateTime } from 'luxon';

export class EditRecruitmentPartnerDialogController {
  static $inject = ['$mdDialog', 'selectedActivityRecruitmentPartner', 'title',
    'isCreated', 'activityservice', '$stateParams', 'squareservice', 'linkTypes', 'callbackTypes', '__env', 'isDisabled',
    'activityrecruitmentpartnerservice', 'serverConstants', 'featureservice'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private selectedActivityRecruitmentPartner: ActivityRecruitmentPartner,
    public title: string,
    public isCreated: boolean,
    private activityservice: ActivityService,
    private $stateParams: ng.ui.IStateParamsService,
    private squareservice: SquareService,
    private linkTypes: Array<{ Label, LinkType }>,
    private callbackTypes: Array<{ Label, CallbackType }>,
    private __env,
    public isDisabled: boolean,
    private activityrecruitmentpartnerservice: ActivityRecruitmentPartnerService,
    private serverConstants: ServerConstants,
    private featureService: FeatureService) {
    this.init();
  }

  childVM = [];
  activityGuid;
  backgroundVariablesLinks = [];
  BidParameter = '';
  SubsourceParameter = '';
  variables = [];
  openLinksRefreshed = false;
  urlBase;
  recruitmentPartnerLinkTypeConstants;
  urlMaxLength: number;
  Partner: RecruitmentPartner;
  LinkType;
  CallbackType;
  CompleteUrl;
  ScreenedUrl;
  QuotaUrl;
  squareUrl;
  savingInProgress;
  squareCountries;
  isConsentFeatureEnabled = false;
  sendActivationEmail: boolean;
  consentList: ActivityRecruitmentPartnerConsentOption[];
  consents: ActivityRecruitmentPartnerConsentListItem[];
  recruitmentVariableLabel: string = 'partner';
  useSubsource: boolean = false;

  minStartDate: DateTime;

  async init(): Promise<void> {
    this.setMinStartDate();
    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        this.isConsentFeatureEnabled = isEnabled;
        if (this.isConsentFeatureEnabled) {
          this.recruitmentVariableLabel = 'source';
        }
      });
    this.recruitmentPartnerLinkTypeConstants = this.serverConstants.recruitmentPartnerLinkTypeConstants;
    this.urlMaxLength = this.serverConstants.validationConstants.urlTechnicalMaxLength;
    this.urlBase = this.__env.squareUrlBase;
    this.activityGuid = this.$stateParams.activityGuid;
    this.consentList = Object.keys(this.serverConstants.consentConstants).map((key) => ({
      Label: this.getConsentLabel(this.serverConstants.consentConstants[key]),
      Value: this.serverConstants.consentConstants[key],
      Selected: false,
      ValidityExpirationDate: null,
      NeverExpires: false,
    }));

    if (this.selectedActivityRecruitmentPartner) {
      this.Partner = {
        Id: this.selectedActivityRecruitmentPartner.PartnerId,
        Name: this.selectedActivityRecruitmentPartner.PartnerName,
        CreatedVariableCount: this.selectedActivityRecruitmentPartner.CreatedVariableCount,
      };

      this.LinkType = this.selectedActivityRecruitmentPartner.LinkType;
      this.CallbackType = this.selectedActivityRecruitmentPartner.CallbackType;
      this.CompleteUrl = this.selectedActivityRecruitmentPartner.CompleteUrl;
      this.ScreenedUrl = this.selectedActivityRecruitmentPartner.ScreenedUrl;
      this.QuotaUrl = this.selectedActivityRecruitmentPartner.QuotaUrl;
      this.variables = this.selectedActivityRecruitmentPartner.Variables;
      this.consents = this.selectedActivityRecruitmentPartner.Consents;
      this.useSubsource = this.selectedActivityRecruitmentPartner.UseSubsource;
      this.consentList.forEach((c) => {
        const consent = this.consents.find((i) => i.Consent === c.Value);
        if (consent) {
          c.Selected = consent.IsGiven;
          const date = DateTime.fromISO(consent.ValidityExpirationDate, { zone: 'utc' });
          if (date.isValid) {
            c.ValidityExpirationDate = date;
          }
          // If creating a new source, this will default to false
          // If editting an existing one, either a date is given or it never expires
          c.NeverExpires = this.isCreated && !c.ValidityExpirationDate;
        }
      });
    } else {
      this.LinkType = this.linkTypes[0].LinkType;
      this.CallbackType = this.callbackTypes[0].CallbackType;
      this.consentList.forEach((c) => {
        c.Selected = true;
      });

    }

    this.squareservice.getSquareDetails(this.$stateParams.squareGuid).then((response) => {
      this.squareUrl = response.data.Detail.Url;
      this.squareCountries = response.data.Countries;
    });
  }

  setMinStartDate() {
    this.minStartDate = DateTime.now().plus({ days: 1 });
    this.minStartDate = DateTime.local(this.minStartDate.year, this.minStartDate.month, this.minStartDate.day);
  }

  resetLinks = () => {
    this.backgroundVariablesLinks = [];
  };

  refreshLinks = () => {
    this.BidParameter = this.LinkType === this.recruitmentPartnerLinkTypeConstants.closed ? '&bid=~UNIQUEPARTNERRESPONDENTID~' : '';
    this.SubsourceParameter = this.useSubsource ? '&subsource=~SUBSOURCE~' : '';
    const backgroundVars = this.variables;
    const numberOfLinks = _.reduce(backgroundVars,
      (acc, backgroundVar) => {
        const notEmptyCount = _.filter(backgroundVar.Values, (item) => item.Value !== '');

        return acc * notEmptyCount.length;
      },
      1);

    const links = [];
    this.openLinksRefreshed = true;
    for (let currentLink = 0; currentLink < numberOfLinks; currentLink++) {
      let aux = currentLink;
      const varsToJoin = [];
      for (const backgroundVar of backgroundVars) {
        const values = backgroundVar.Values;
        const varAndValue = `&${backgroundVar.Name}=${values[aux % values.length].Value}`;
        varsToJoin.push(varAndValue);
        aux = Math.floor(aux / values.length);
      }
      const linkVars = varsToJoin.join('');
      if (links.indexOf(linkVars) === -1) {
        links.push(linkVars);
      }
    }
    if (links.length === 0) {
      links.push('');
    }
    this.backgroundVariablesLinks = links;
  };

  isOpenRegistrationScreener = () => this.selectedActivityRecruitmentPartner &&
  this.selectedActivityRecruitmentPartner.PartnerName === this.serverConstants.partnerConstantsConstants.partnerOpenRegistrationName;

  submit = () => {
    let childValid = true;
    _.each(this.childVM, (childvm) => {
      childValid = childvm.isValid();
      if (!childValid) {
        return false;
      }
    });
    if (childValid) {
      let hasError = false;
      if (!this.everyVariableHasAtLeastOneValue()) {
        this.showMessage('Please make sure that every background variable has at least one value.');
        hasError = true;
      }
      if (!hasError) {
        this.save();
      }
    } else {
      this.showMessage('Please save or cancel any open changes before continuing.');
    }
  };

  private showMessage(message) {
    const dialog = this.$mdDialog.iscAlert()
      .text(message)
      .ok('Got it!');
    this.$mdDialog.show(dialog);
  }

  private everyVariableHasAtLeastOneValue() {
    let result = true;
    for (const variable of this.variables) {
      if (variable.Values.length === 0) {
        result = false;
        break;
      }
    }
    return result;
  }

  save = () => {
    this.savingInProgress = true;
    this.selectedActivityRecruitmentPartner = {
      ActivityGuid: this.$stateParams.activityGuid,
      PartnerId: this.Partner.Id,
      LinkType: this.LinkType,
      CallbackType: this.CallbackType,
      CompleteUrl: this.CompleteUrl,
      ScreenedUrl: this.ScreenedUrl,
      QuotaUrl: this.QuotaUrl,
      Variables: this.variables,
      Consents: this.consentList.map((c): ActivityRecruitmentPartnerConsentListItem => ({
        Consent: c.Value,
        ValidityExpirationDate: c.ValidityExpirationDate?.toLocaleString(),
        IsGiven: c.Selected,
      })),
      UseSubsource: this.useSubsource,
    };
    this.activityrecruitmentpartnerservice.createOrUpdateActivityRecruitmentPartner(this.selectedActivityRecruitmentPartner)
      .then((partners) => {
        this.$mdDialog.hide(partners);
      })
      .finally(() => {
        this.savingInProgress = false;
      });
  };

  cancel = () => this.$mdDialog.cancel();

  querySearch = (searchText) => this.activityservice.getActivityPartners(this.$stateParams.activityGuid, searchText)
    .then((response) => response.data.List.filter((partner) => !partner.IsMobileApp &&
    partner.Name !== this.serverConstants.partnerConstantsConstants.partnerOpenRegistrationName));

  addChildVM = (childvm) => this.childVM.push(childvm);

  getConsentLabel = (consent: number) => {
    switch (consent) {
      case this.serverConstants.consentConstants.asynchronousResearch:
        return 'Asynchronous research';
      // The other types of consent aren't currently being used, but they will be added in the future
      // case this.serverConstants.consentConstants.liveResearch:
      //   return 'Live research (Connect)';
      // case this.serverConstants.consentConstants.surveys:
      //   return 'Surveys';
    }
  };

  validityDateChanged = (consent: ActivityRecruitmentPartnerConsentOption) => {
    consent.NeverExpires = false;
  };

  consentValidityNeverExpiresChanged(consent) {
    consent.ValidityExpirationDate = null;
  }

  isSaveEnabled() {
    return !this.isConsentFeatureEnabled || this.consentList.every((consent) => (consent.NeverExpires || !!consent.ValidityExpirationDate));
  }
}
