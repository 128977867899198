'use strict';


import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Utils } from './utils';

export class SelectedActivityFactory implements IActivityInfo {
  static $inject = ['serverConstants'];

  private name = null;
  private guid = null;
  private format = null;
  private hasMedia = null;
  private _activityInfo = new ReplaySubject<IActivityInfo>(1);

  constructor(private serverConstants) {
  }

  get Name() {
    return this.name;
  }

  get Guid() {
    return this.guid;
  }
  get Format() {
    return this.format;
  }
  get HasMedia() {
    return this.hasMedia;
  }

  GetFormatName() {
    const formatName = Utils.reverseEnum(this.serverConstants.squareActivityFormatConstants, this.format);
    return formatName.toUpperCase();
  }

  get activityInfoObservable() {
    return this._activityInfo.distinctUntilChanged((a, b) => a.Guid === b.Guid);
  }

  setActivityInfo(activityInfo: IActivityInfo) {
    this.name = activityInfo.Name;
    this.guid = activityInfo.Guid;
    this.hasMedia = activityInfo.HasMedia;
    // don't allow activity format reset. Update it only if it's null or if new value is not NONE
    if (this.activityFormatUpdateable(activityInfo.Format)) {
      this.format = activityInfo.Format;
    }
    this.infoChanged();
  }

  activityFormatUpdateable(newFormat) {
    return this.format === null || (this.name && this.guid && newFormat !== 0);
  }

  resetActivityInfo() {
    this.name = null;
    this.guid = null;
    this.format = null;
    this.hasMedia = null;
    this.infoChanged();
  }

  private infoChanged() {
    this._activityInfo.next({
      Guid: this.guid,
      Name: this.name,
      Format: this.format,
      HasMedia: this.hasMedia,
    });
  }
}

export interface IActivityInfo {
  Name: string,
  Guid: string,
  Format: number,
  HasMedia: boolean,
}

angular
  .module('insitesApp.core')
  .service('selectedActivityFactory', SelectedActivityFactory);
