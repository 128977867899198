'use strict';

import { SidenavItemComponent } from './isc-sidenav-item.component';
import { SidenavComponent } from './isc-sidenav.component';
import { MenuToggle } from './menu-toggle.directive';

angular
  .module('insitesApp.layout')
  .component('iscSidenavItem', SidenavItemComponent)
  .component('iscSidenav', SidenavComponent)
  .directive('menuToggle', MenuToggle.factory());
