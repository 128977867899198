'use strict';

export class SquareOpenRegistrationController {
  static $inject = ['$mdDialog'];

  constructor(private $mdDialog: ng.material.IDialogService) {}

  form: ng.IFormController;

  cancel() {
    return this.$mdDialog.hide(false);
  }

  goToActivity() {
    return this.$mdDialog.hide(true);
  }
}
