'use strict';

import * as _ from 'lodash';
import { EnvironmentDropdownItem } from '../../core/contracts/layout.contract';
export class EnvironmentSelectionController {
  static $inject = [
    '__env',
  ];
  constructor(
    private __env: Environment,
  ) { }

  icon = 'fas fa-globe';
  environments: EnvironmentDropdownItem[];

  async $onInit() {
    this.environments = _.map(this.__env.environments, (env: SingleEnvironment) => ({
      fullName: env.fullName,
      shortName: env.shortName,
      adminUrl: env.adminUrl,
      type: 5,
    } as EnvironmentDropdownItem));
  }

  get environment(): EnvironmentDropdownItem {
    return _.find(this.environments, (env) => env.adminUrl === this.__env.interfaceUrl);
  }

  selectEnvironment(selectedEnvironment: EnvironmentDropdownItem): void {
    if (!Object.prototype.hasOwnProperty.call(selectedEnvironment, 'adminUrl')) {
      // We only get here when the breadcrumb itself is clicked
      // The environment remains the same
      window.location.href = this.__env.interfaceUrl;
    } else {
      window.location.href = selectedEnvironment.adminUrl;
    }
  }
}
