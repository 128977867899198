'use strict';

// Third party stuff
// We don't necessarily need to do this
// But this ensures the modules are loaded
// Before we load our app

// Third party JavaScript
import * as emojione from 'emojione';
(window as any).emojione = emojione;

import 'angular-animate';
import 'angular-aria';
import 'ng-sortable';
import 'angular-base64-upload';
import 'chart.js';
import 'angular-ivh-treeview/dist/ivh-treeview.js';
import 'angular-material';
import 'angular-material-data-table';
import 'angular-messages';
import 'angular-sanitize';
import '@uirouter/angularjs';
import '@uirouter/angularjs/release/stateEvents.js';
import '@uirouter/angularjs/release/angular-ui-router.js';
import 'angular-vimeo-embed';
import 'angular-youtube-embed';
import 'angular-inview';
import 'angular-xregexp';
import 'lodash';
import 'md-color-picker';
import 'md-pickers/dist/mdPickers';
import 'luxon';
import 'ng-file-upload';
import 'ng-img-crop/compile/unminified/ng-img-crop.js';
import 'ng-wig';
import 'ngtouch';
import 'typicons.font';
import 'ace-builds';
import 'angular-ui-ace';
import 'isc-ui/dist/services';
import 'isc-ui/dist/directives/fallback';
import 'isc-ui/dist/directives/mention';
import 'isc-ui/dist/directives/hashtag';
import 'isc-ui/dist/components/conversation-editor';
import 'isc-ui/dist/components/isc-color-picker';
import 'isc-ui/dist/components/isc-table-container';
import 'isc-ui/dist/components/input/isc-input';
import 'isc-ui/dist/components/input/isc-rich-input';
import 'isc-ui/dist/components/input/isc-textarea';
import 'isc-ui/dist/components/input/isc-dropdown';
import 'isc-ui/dist/components/button/isc-button';
import 'isc-ui/dist/components/button/isc-save-button';
import 'isc-ui/dist/components/button/isc-input-button';
import 'isc-ui/dist/components/button/like-button';
import 'isc-ui/dist/components/button/show-replies-button';
import 'isc-ui/dist/components/pagination';
import 'isc-ui/dist/components/wizard';
import 'isc-ui/dist/components/button/isc-radio-button';
import 'isc-ui/dist/components/button/isc-radio-group';
import 'isc-ui/dist/components/404';
import 'isc-ui/dist/config';
import 'isc-ui/dist/components/tag/isc-tag';
import 'isc-ui/dist/components/tag/isc-tag-input';
import 'isc-ui/dist/components/checkbox/isc-checkbox';
import 'isc-ui/dist/components/modal/isc-modal-dialog';
import 'isc-ui/dist/components/card/isc-challenge-card';
import 'isc-ui/dist/components/card/isc-card';
import 'isc-ui/dist/components/banner';
import 'isc-ui/dist/components/progress/isc-progress-circular';
import 'isc-ui/dist/components/progress/isc-progress-linear';
import 'isc-ui/dist/components/forum-post';
import 'isc-ui/dist/components/stimuli';
import 'isc-ui/dist/components/conversation-sort-options';
import 'isc-ui/dist/components/conversation-list';
import 'isc-ui/dist/components/incentive';
import 'isc-ui/dist/components/timeline';
import 'isc-ui/dist/components/notifications';
import 'isc-ui/dist/components/notifications-counter';
import 'isc-ui/lib/signalr';
import 'isc-ui/dist/components/search-counter';
import 'isc-ui/dist/directives/conversationeditor';
import 'isc-ui/dist/components/switch/isc-switch';
import 'isc-ui/dist/components/probequestioneditor';
import 'isc-ui/dist/components/isc-redemption-threshold';
import 'isc-ui/dist/components/caption-editor';
import 'isc-ui/dist/components/carousel';
import 'isc-ui/dist/components/video';
import 'isc-ui/dist/components/visual-focused-conversation';
import 'isc-ui/dist/components/upload-conversation';
import 'isc-ui/dist/components/stimuli-preview';
import 'isc-ui/dist/components/probe-questions-answer-editor';
import 'isc-ui/dist/directives/isc-button';

import './core.module';
import './core.route';

import './../blocks';

import './close-modal';
import './config';
import './serverconstants';
import './constants.factory';
import './constants';
import './directives';
import './httpinterceptor';
import './label.factory';
import './selectedactivity.factory';
import './selectedclient.factory';
import './selectednetwork.factory';
import './selectedorphan.factory';
import './selectedsquare.factory';
import './selectedprogram.factory';
import './participant.factory';
import './selectedfactories.run';
import './spinner.component';
import './utils';
import './selectedfactories.run';
import './role-based-access-control';
import './feature-based-access-control';

import './filters';
import './services';
import './dataservices';
import './validation';
import './contracts';
import './jobs';
import './shared';

import './ng-google-chart';
import './vueToggle';
