'use strict';

import * as angular from 'angular';
import { ReportingPage, createApp } from '@insites/vue-eco-temp-library';
import { RouterHelper } from '../../blocks/router/router-helper';
import { StateService, TransitionService } from '@uirouter/angularjs';
import { FeatureService } from '../../core/dataservices/feature.service';
import { ServerConstants } from '../../core/serverconstants';
import { SelectedClientFactory } from 'src/app/core/selectedclient.factory';
import { SelectedSquareFactory } from 'src/app/core/selectedsquare.factory';
import { ClientService } from 'src/app/core/dataservices/client.service';
import { VueToggle } from 'src/app/core/vueToggle';

const routeConfig = (
  routerHelper: RouterHelper,
  serverConstants: ServerConstants,
  featureService: FeatureService,
  clientService: ClientService,
  $state: StateService,
  $transitions: TransitionService,
  selectedClientFactory: SelectedClientFactory,
  selectedSquareFactory: SelectedSquareFactory,
) => {
  routerHelper.configureStates([
    {
      state: 'root.reporting',
      config: {
        url: 'client/:clientGuid/program/:programGuid/reporting?selectedActivityGuid',
        title: 'Reporting & Analysis',
        component: 'reporting',
        settings: {
          nav: 3,
          section: 'PROGRAM MANAGEMENT',
          icon: 'fas fa-chart-mixed',
          label: 'Analysis & reporting',
          show: async () =>
            await showAnalysisAndReporting(
              featureService,
              clientService,
              serverConstants,
              selectedClientFactory,
              selectedSquareFactory,
            ),
        },
        disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
        // Used for feature-based-access-control
        isFeatureEnabled: async () =>
          await showAnalysisAndReporting(
            featureService,
            clientService,
            serverConstants,
            selectedClientFactory,
            selectedSquareFactory,
          ),
      },
    },
    {
      state: 'root.square.reporting',
      config: {
        url: '/activity/:activityGuid/reporting',
        title: 'Reporting & Analysis',
        component: 'reporting',
        settings: {
          nav: 3,
          section: 'ACTIVITY MANAGEMENT',
          icon: 'fas fa-chart-mixed',
          label: 'Access data',
          show: async () =>
            await showAnalysisAndReporting(
              featureService,
              clientService,
              serverConstants,
              selectedClientFactory,
              selectedSquareFactory,
            ),
        },
        disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
        // Used for feature-based-access-control
        isFeatureEnabled: async () =>
          await showAnalysisAndReporting(
            featureService,
            clientService,
            serverConstants,
            selectedClientFactory,
            selectedSquareFactory,
          ),
      },
    },
  ]);

  $transitions.onBefore({ to: 'root.square.reporting' }, (transition) => {
    const { activityGuid, clientGuid, programGuid } = transition.params();
    const url = $state.href('root.reporting', { clientGuid, programGuid }, { absolute: true });
    window.open(`${url}?selectedActivityGuid=${activityGuid}`);

    return false;
  });
};

const showAnalysisAndReporting = async (
  featureservice: FeatureService,
  clientService: ClientService,
  serverConstants: ServerConstants,
  selectedClientFactory: SelectedClientFactory,
  selectedSquareFactory: SelectedSquareFactory,
) => {
  const masterFeature = await featureservice.getMasterFeature(serverConstants.featureConstants.accessData);

  if (!masterFeature || !masterFeature.isEnabled) {
    return false;
  }

  // at least for moderators,
  // the selected client factory contains null for all members
  let clientCode = selectedClientFactory.Code;
  if (!clientCode) {
    // if this happens, we retrieve current client code
    const clientInfo = await clientService.getClientDetails(
      selectedSquareFactory.clientGuid, false);
    clientCode = clientInfo?.Detail?.Code;
  }
  if (!clientCode) {
    return false;
  }

  const getFeatureSquare = () => masterFeature.specificSquares
    .find((x) => x.squareId === selectedSquareFactory.Id && x.clientCode === clientCode);

  if (masterFeature.isExcludedForSquaresFromAllSquares) {
    return !getFeatureSquare();
  }

  if (masterFeature.isEnabledForSpecificSquares) {
    return !!getFeatureSquare();
  }

  return true;
};

routeConfig.$inject = [
  'routerHelper',
  'serverConstants',
  'featureservice',
  'clientservice',
  '$state',
  '$transitions',
  'selectedClientFactory',
  'selectedSquareFactory'];

angular
  .module('insitesApp.reporting', [])
  .component('reporting', {
    controller: ['VueToggle', function(vueToggle: VueToggle) {
      const app = createApp(ReportingPage);
      this.$postLink = () => {
        vueToggle.mount(app, 'reporting');
      };
      this.$onDestroy = () => {
        vueToggle.unMount();
      };
    }],
  })
  .run(routeConfig);
