'use strict';

import { ClientConfigImagesController } from './images.controller';
const template = require('./images.html');

angular
  .module('insitesApp.clientConfiguration')
  .component('clientConfigImages', {
    templateUrl: template,
    controller: ClientConfigImagesController,
    controllerAs: 'vm',
  });
