'use strict';

import * as angular from 'angular';

import { iscSegmentsFilterComponent } from './isc-segments-filter.component';
import { segmentationsFilter, segmentsFilter } from './segmentationshavingsegments.filter';

angular
  .module('insitesApp.segmentsFilter', [])
  .component('iscSegmentsFilter', iscSegmentsFilterComponent)
  .filter('segmentationsFilter', segmentationsFilter)
  .filter('segmentsFilter', segmentsFilter);
