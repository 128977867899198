'use strict';

import './mainPages.module';
import routeConfig from './mainPages.route';
import { MainPagesComponent } from './mainPages.component';

import './forum';
import './challenges';

angular
  .module('insitesApp.squareconfig.mainPages')
  .component('mainPages', MainPagesComponent)
  .run(routeConfig);
