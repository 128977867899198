'use strict';

import { IscSegmentsFilterController } from './isc-segments-filter.controller';

const template = require('./isc-segments-filter.html');
require('./_isc-segments-filter.scss');

export const iscSegmentsFilterComponent: ng.IComponentOptions = {
  controller: IscSegmentsFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    options: '=',
    selectedSegments: '=',
    selectedSegmentsOption: '=',
    onChange: '&',
  },
};
