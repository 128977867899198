'use strict';

import { MemberDetailsSavingUpController } from './memberDetailsSavingUp.controller';
const template = require('./memberDetailsSavingUp.html');

angular.module('insitesApp.squaremembers.memberOverview').component('memberDetailsSavingUp', {
  controller: MemberDetailsSavingUpController,
  controllerAs: 'vm',
  templateUrl: template,
});
