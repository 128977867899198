'use strict';

import { RouterHelper } from '../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'config',
    config: {
      url: '/config',
      component: 'configList',
      title: 'Configuration',
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;
