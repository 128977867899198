'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.communicationparent.wizard',
      config: {
        url: '/communication?communicationGuid&channelType&researchActivityGuid&status',
        component: 'communicationWizard',
        title: 'New Communication',
        disallowRoles: ['Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

