'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';
import { GeofencingController } from './geofencing.controller';

const template = require('./geofencing.html');

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'root.square.geofencing',
    config: {
      url: '/config/geofencing',
      templateUrl: template,
      controller: GeofencingController,
      controllerAs: 'vm',
      title: 'Geofencing',
      settings: {
        nav: 2,
        childnav: 6,
        label: 'Geofencing',
        showForums: true,
      },
      disallowRoles: ['Observer'],
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

