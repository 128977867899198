'use strict';

import * as angular from 'angular';
import routeConfig from './featureSetting.route';
import { createApp, FeatureSettingsPage } from '@insites/vue-eco-temp-library';
import { VueToggle } from 'src/app/core/vueToggle';

angular
  .module('insitesApp.featureSetting', ['insitesApp.core'])
  .component('featureSetting', {
    controller: ['VueToggle', function(vueToggle: VueToggle) {
      const app = createApp(FeatureSettingsPage);
      this.$postLink = () => {
        vueToggle.mount(app, 'feature-setting');
      };
      this.$onDestroy = () => {
        vueToggle.unMount();
      };
    }],
  })
  .run(routeConfig);
