'use strict';

export class ActivityProbeQualPresentModel {
  Title = '';
  CardTeaser = '';
  CallToActionText = '';
  Sticky = false;
  CommunicationGuid = '';
  CommunicationStimuli: ICommunicationStimuli[] = [];
  CommunicationFiles: File[] = [];
  ShowOnHomepage = true;
  ShowOnPublicPage = false;
}

interface ICommunicationStimuli {
  action: string;
  guid?: string;
  imgUrl: File;
  source: string;
  type: any;
  id?: string;
  url?: string;
  thumbnailUrl?: string;
}
