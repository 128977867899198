'use strict';

import { DateTime } from 'luxon';

export class HealthFilterDataItem {
  public SelectedRoles: number[];
  public SelectedMembershipStatus: number[];
  public StartDate: DateTime;
  public EndDate: DateTime;
  public SelectedSegments: string[];
  public SelectedSegmentsOption: number;
}
