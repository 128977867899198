import { __awaiter, __generator } from "tslib";
import * as angular from 'angular';
var IscWizardController = (function () {
    function IscWizardController($element, _, $q, $timeout, navigationHelperService) {
        this.$element = $element;
        this._ = _;
        this.$q = $q;
        this.$timeout = $timeout;
        this.navigationHelperService = navigationHelperService;
        this.steps = [];
        this.startingStep = '';
        this.currentStepIndex = 0;
        this.count = 0;
        this.currentStep = undefined;
        this.currentSubstep = undefined;
        this.busy = false;
        this.disabledStep = false;
        this.constants = {
            state: {
                incomplete: 'incomplete',
                current_incomplete: 'current incomplete',
                current_complete: 'current complete',
                complete: 'complete',
                next: 'next',
            },
        };
        this.lastCompletedStep = undefined;
        this.lastCompletedSubstep = undefined;
        this.linkedComponents = {};
        this.resolveStepComponentPromises = [];
    }
    IscWizardController.prototype.$onInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.$timeout()];
                    case 1:
                        _a.sent();
                        this.currentStep.onStepLoaded();
                        this.setNextStepNextSubstepIndices();
                        this.initCurrentStepIndex();
                        return [2];
                }
            });
        });
    };
    IscWizardController.prototype.initCurrentStepIndex = function () {
        var _this = this;
        var index = this.startingStep ? this.steps.findIndex(function (s) { return s.caption.includes(_this.startingStep); }) : 0;
        var step = this.steps[index];
        this.goToStep(step);
    };
    IscWizardController.prototype.addStep = function (step) {
        var order = step.order;
        if (this.steps.length > 0) {
            this.steps[this.steps.length - 1].isLast = false;
        }
        var index;
        if (order === void 0 || this.steps.length === 0) {
            index = this.steps.push(step) - 1;
        }
        else {
            for (index = this.steps.length; index > 0; index--) {
                var testStep = this.steps[index - 1];
                if (testStep.order <= order) {
                    break;
                }
            }
            this.steps.splice(index, 0, step);
        }
        if (index === this.currentStepIndex) {
            this.currentStep = step;
        }
        this.steps[this.steps.length - 1].isLast = true;
        this._.forEach(this.steps, function (steapEach, stepIndex) {
            steapEach.position = stepIndex + 1;
        });
    };
    IscWizardController.prototype.removeStep = function (step) {
        this._.pull(this.steps, step);
    };
    IscWizardController.prototype.nextStep = function () {
        var _this = this;
        this.busy = true;
        var result = this.currentStep.onBeforeNextStep();
        if (result && result.finally) {
            result.then(function () {
                _this.gotoNextStep();
            }).finally(function () {
                _this.busy = false;
            });
        }
        else {
            this.gotoNextStep();
            this.busy = false;
        }
    };
    IscWizardController.prototype.previousStep = function () {
        var _this = this;
        this.busy = true;
        var result = this.currentStep.onBeforePreviousStep();
        if (result && result.finally) {
            result.then(function () {
                _this.gotoPreviousStep();
            }).finally(function () {
                _this.busy = false;
            });
        }
        else {
            this.gotoPreviousStep();
            this.busy = false;
        }
    };
    IscWizardController.prototype.previousLabel = function () {
        return (this.currentStep ? this.currentStep.previousLabel() : undefined) || 'Go back';
    };
    IscWizardController.prototype.nextDescription = function () {
        return (this.currentStep ? this.currentStep.nextDescription() : undefined) || '';
    };
    IscWizardController.prototype.nextLabel = function () {
        return (this.currentStep ? this.currentStep.nextLabel() : undefined) || 'Continue';
    };
    IscWizardController.prototype.linkStep = function (step, name, component) {
        var _this = this;
        this.linkedComponents[name] = { step: step, component: component };
        this._.chain(this.resolveStepComponentPromises)
            .remove(function (promise) { return promise.name === name; })
            .forEach(function (promise) { return promise.resolve(component); })
            .value();
        return function () {
            delete _this.linkedComponents[name];
        };
    };
    IscWizardController.prototype.resolveStep = function (name) {
        var _this = this;
        if (angular.isArray(name)) {
            return this.$q.all(name.map(function (n) { return _this.resolveSingleStep(n); }));
        }
        return this.resolveSingleStep(name);
    };
    IscWizardController.prototype.goto = function (stepComponentName, substepComponentName) {
        var _this = this;
        return this.resolveStep(stepComponentName)
            .then(function (component) {
            var found;
            for (var i = 0; i < _this.steps.length; i++) {
                var step = _this.steps[i];
                if (!found) {
                    if (step.component === component) {
                        _this.currentStep = step;
                        _this.currentStepIndex = i;
                        found = step;
                    }
                }
            }
            if (found) {
                found.onStepLoaded();
                return found.goto(substepComponentName);
            }
        });
    };
    IscWizardController.prototype.setNextStepNextSubstepIndices = function () {
        this.lastCompletedStep = this._.findLast(this.steps, function (s) { return s.isCompleted(); });
        if (this.lastCompletedStep !== undefined) {
            if (this.lastCompletedStep.substeps !== undefined && this.lastCompletedStep.substeps.length > 0) {
                var isLastSubstepCompleted = this._.findLast(this.lastCompletedStep.substeps, function (substep) { return substep.isCompleted(); }) === this._.findLast(this.lastCompletedStep.substeps, function (substep) { return substep; });
                if (isLastSubstepCompleted) {
                    this.nextHoverableSubstepIndex = undefined;
                    this.nextHoverableStepIndex = this.steps[this.lastCompletedStep.position].isCompleted() ? null : this.lastCompletedStep.position;
                }
                else {
                    this.nextHoverableStepIndex = undefined;
                    var lastCompletedSubstep = this._.findLast(this.lastCompletedStep.substeps, function (substep) { return substep.isCompleted(); });
                    this.nextHoverableSubstepIndex = lastCompletedSubstep !== undefined ? lastCompletedSubstep.position : 0;
                }
            }
            else {
                this.nextHoverableSubstepIndex = undefined;
                this.nextHoverableStepIndex = this.lastCompletedStep.position;
            }
        }
    };
    IscWizardController.prototype.onGoToStep = function (step) {
        var _this = this;
        var currentStep = this.currentSubstep != null ? this.currentSubstep : this.currentStep;
        if ((step.isCompleted() || (step.position - 1) === this.nextHoverableStepIndex) && currentStep !== step) {
            if (!currentStep.component.form) {
                this.goToStep(step);
            }
            else {
                this.navigationHelperService.onNavigate(currentStep.component.form.$valid, currentStep.component.form.$dirty, currentStep.component.form.$error, currentStep.component.saveNotApplicable, currentStep.component.customMessage, currentStep.component.navigationErrorMessage, function () { return _this.goToStep(step); }, currentStep.component.saveCallback, currentStep.component.customValidation, true, currentStep.component.resetFormCallback, false);
            }
        }
    };
    IscWizardController.prototype.goToStep = function (step) {
        this.setNextStepNextSubstepIndices();
        try {
            this.currentStep.currentStepIndex = -1;
            var indexOfStep = this._.findIndex(this.steps, step);
            if (step && step.isCompleted() || (this.steps[indexOfStep - 1] && this.steps[indexOfStep - 1].isCompleted())) {
                this.currentStepIndex = indexOfStep;
                this.currentStep = this.steps[this.currentStepIndex];
                this.currentSubstep = null;
                return true;
            }
            if (step && (step.position - 1) === this.nextHoverableStepIndex) {
                if (step.isCompleted()) {
                    this.steps[step.position - 1].state = this.constants.state.current_complete;
                }
                else {
                    this.steps[step.position - 1].state = this.constants.state.current_incomplete;
                }
            }
        }
        finally {
            this.currentStep.onStepLoaded();
            this.goToTop();
        }
    };
    IscWizardController.prototype.onGoToSubstep = function (step, substep) {
        var _this = this;
        var currentStep = this.currentSubstep != null ? this.currentSubstep : this.currentStep;
        if ((substep.isCompleted() || (substep.position - 1) === this.nextHoverableSubstepIndex) && step.substeps.length > 0 && currentStep !== substep) {
            if (!currentStep.component.form) {
                this.goToSubstep(step, substep);
            }
            else {
                this.navigationHelperService.onNavigate(currentStep.component.form.$valid, currentStep.component.form.$dirty, currentStep.component.form.$error, currentStep.component.saveNotApplicable, currentStep.component.customMessage, currentStep.component.navigationErrorMessage, function () { return _this.goToSubstep(step, substep); }, currentStep.component.saveCallback, currentStep.component.customValidation, true, currentStep.component.resetFormCallback, false);
            }
        }
    };
    IscWizardController.prototype.goToSubstep = function (step, substep) {
        this.setNextStepNextSubstepIndices();
        try {
            this.currentStepIndex = this._.findIndex(this.steps, step);
            this.currentStep = this.steps[this.currentStepIndex];
            this.currentStep.currentStepIndex = this._.findIndex(this.currentStep.substeps, substep);
            this.currentStep.goToSubstep(substep);
            this.currentSubstep = substep;
            return true;
        }
        finally {
            this.currentStep.onStepLoaded();
            this.goToTop();
        }
    };
    IscWizardController.prototype.gotoNextStep = function () {
        try {
            if (this.currentStep.nextSubstep()) {
                return true;
            }
            if (this.currentStepIndex >= this.steps.length - 1) {
                return false;
            }
            this.currentStepIndex++;
            this.currentStep = this.steps[this.currentStepIndex];
            return true;
        }
        finally {
            this.currentStep.onStepLoaded();
            this.goToTop();
        }
    };
    IscWizardController.prototype.goToTop = function () {
        var scrollableParent = this.getScrollParent(this.$element[0]);
        if (scrollableParent) {
            scrollableParent.scrollTop = 0;
        }
    };
    IscWizardController.prototype.getScrollParent = function (node) {
        if (node == null) {
            return null;
        }
        if (node.scrollHeight > node.clientHeight) {
            return node;
        }
        else {
            return this.getScrollParent(node.parentNode);
        }
    };
    IscWizardController.prototype.gotoPreviousStep = function () {
        if (this.currentStep.previousSubstep()) {
            return;
        }
        else {
            this.currentStepIndex--;
        }
        if (this.currentStepIndex < 0) {
            return;
        }
        this.currentStep = this.steps[this.currentStepIndex];
        this.currentStep.onStepLoaded();
        this.goToTop();
    };
    IscWizardController.prototype.resolveSingleStep = function (name) {
        var _this = this;
        if (this.linkedComponents[name]) {
            return this.$q.resolve(this.linkedComponents[name].component);
        }
        return this.$q(function (resolve) {
            _this.resolveStepComponentPromises.push({ name: name, resolve: resolve });
        });
    };
    IscWizardController.$inject = ['$element', '_', '$q', '$timeout', 'navigationHelperService'];
    return IscWizardController;
}());
export { IscWizardController };
