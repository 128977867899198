'use strict';

import { ServerConstants } from '../serverconstants';

/*
 * Workaround to be able to find the ngModelController for normal inputs, and also for ng-wig
 */
const findNgModel = (element: ng.IRootElementService, callback: (model: ng.INgModelController) => void): void => {
  const inputElem = element.find('input');
  const ngWig = element.find('ng-wig');
  if (!inputElem && !ngWig) {
    throw new Error('No child input element was found');
  }
  const ngModel = inputElem.controller('ngModel') || (ngWig && ngWig.controller('ngModel'));
  if (ngModel) {
    callback(ngModel);
  } else if (ngWig) {
    window.setTimeout(() => {
      const ngMod = element.find('ng-wig').controller('ngModel');
      callback(ngMod);
    }, 0);
  } else {
    throw new Error('Input element doesn\'t have a ngModel specified');
  }
};

const mustNotContainSurveyLinkValidatorDirective = (serverConstants: ServerConstants): ng.IDirective => ({
  link: (scope, element) => {
    findNgModel(element, (ngModel) => {
      ngModel.$validators.mustNotContainSurveyLink = (modelValue, viewValue) => {
        if (!viewValue) {
          return true;
        }
        const matchesConfirmitDirectLink = serverConstants.validationConstants.confirmitDirectLinkRegex.test(viewValue);
        const matchesDecipherDirectLink = serverConstants.validationConstants.decipherDirectLinkRegex.test(viewValue);
        const matchesResearchActivityLink = serverConstants.validationConstants.researchActivityDirectLinkRegex.test(viewValue);
        const matchesResearchActivityTestLink = serverConstants.validationConstants.researchActivityDirectTestLinkRegex.test(viewValue);
        return !matchesConfirmitDirectLink && !matchesResearchActivityLink && !matchesResearchActivityTestLink && !matchesDecipherDirectLink;
      };
    });
  },
});
mustNotContainSurveyLinkValidatorDirective.$inject = ['serverConstants'];

angular
  .module('insitesApp.core')
  .directive('mustNotContainSurveyLinkValidator', mustNotContainSurveyLinkValidatorDirective);
