'use strict';

import * as angular from 'angular';
import './display-targeting';
import './targeting-result';
import './targeting-quota';
import './target-completion';
import { WizardTargetingComponent } from './wizard-targeting.component';

angular
  .module('insitesApp.layout')
  .component('wizardTargeting', WizardTargetingComponent);

