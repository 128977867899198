import * as angular from 'angular';
var IscColorPickerController = (function () {
    function IscColorPickerController($element) {
        this.$element = $element;
        this.color = null;
        this.label = 'Color';
    }
    IscColorPickerController.prototype.getColorPickerModelController = function () {
        if (!this.colorPickerModelController) {
            var colorPickerElem = this.$element[0].querySelector('.selectColorDiv');
            var angularColorPickerElem = angular.element(colorPickerElem);
            this.colorPickerModelController = angularColorPickerElem.controller('ngModel');
        }
        return this.colorPickerModelController;
    };
    IscColorPickerController.prototype.isValid = function () {
        var controller = this.getColorPickerModelController();
        return controller ? controller.$valid : false;
    };
    IscColorPickerController.$inject = ['$element'];
    return IscColorPickerController;
}());
export { IscColorPickerController };
