'use strict';

import { SidenavMyPinsController } from './sidenav-my-pins.controller';
const template = require('./sidenav-my-pins.html');

export const SidenavMyPinsComponent: ng.IComponentOptions = {
  controller: SidenavMyPinsController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    useAllEnv: '<',
    toggleInactive: '<',
  },
};
