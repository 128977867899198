'use strict';

import { SelectedClientFactory } from '../../core/selectedclient.factory';
import { ProgramService } from './../../core/dataservices/program.service';
export class ClientMaintenanceController implements ng.IOnInit, ng.IOnDestroy {

  static $inject = ['$interval', 'programservice', 'selectedClientFactory'];

  private _intervalPromise: ng.IPromise<void>;

  constructor(
    private $interval: ng.IIntervalService,
    private programservice: ProgramService,
    private selectedClientFactory: SelectedClientFactory,
  ) {
  }
  goto;

  $onInit() {
    this._intervalPromise = this.$interval(() => this.checkIfClientLoaded(), 10000);
    this.selectedClientFactory.isInMigrationMode = true;
  }

  $onDestroy() {
    this.$interval.cancel(this._intervalPromise);
    this.selectedClientFactory.resetClientInfo();
  }

  private checkIfClientLoaded() {
    this.programservice.getClientPrograms().then(() => {
      this.$onDestroy();
      window.location.href = this.goto;
    });
  }
}
