'use strict';

import { ServerConstants } from '../../../../../../core/serverconstants';

export class ActivityConfirmitIdController {
  static $inject = ['serverConstants'];
  constructor(
    private serverConstants: ServerConstants) {
    this.validationConstants = this.serverConstants.validationConstants;
  }
  model;
  validationConstants;
  validationPattern = '^p[0-9]{6,}?$';

}
