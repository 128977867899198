'use strict';

import { NewsletterController } from './newsletterChannel.controller';
const template = require('./newsletterChannel.html');

angular
  .module('insitesApp.squarecommunicationWizard')
  .component('newsletterChannel', {
    templateUrl: template,
    controller: NewsletterController,
    controllerAs: 'vm',
    require: {
      wizardStep: '^^iscWizardStep',
    },
    bindings: {
      description: '@',
      channelType: '<',
      timeZone: '<',
      communication: '<',
      isReadOnly: '<',
    },
  });
