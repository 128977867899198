'use strict';

import { ServerConstants } from '../../../../../../core/serverconstants';

export class ActivityCredits {
  static $inject = ['serverConstants'];
  constructor(
    private serverConstants: ServerConstants) {
  }

  model;
  squareActivity;
  form;

  isLowerThanCompletionPointsLimit() {
    return this.model != null && this.model.CompletionPoints != null && this.model.CompletionPoints.Qualified
      && (this.isLowerThanCompletionPointsLimitForumDiscussion
        || this.isLowerThanCompletionPointsLimitPrivateDiscussion
        || this.isLowerThanCompletionPointsLimitDiscussionsNew
        || this.isLowerThanCompletionPointsLimitResearchActivity
        || this.isLowerThanCompletionPointsLimitProfileActivity
        || this.isLowerThanCompletionPointsLimitLifeScenes
        || this.isLowerThanCompletionPointsLimitLifeSnapshots
        || this.isLowerThanCompletionPointsLimitObservation
        || this.isLowerThanCompletionPointsLimitSurveyActivity);
  }

  private get isLowerThanCompletionPointsLimitForumDiscussion() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch
      && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.forumDiscussionMin;
  }

  private get isLowerThanCompletionPointsLimitPrivateDiscussion() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch
      && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.privateDiscussionMin;
  }

  private get isLowerThanCompletionPointsLimitDiscussionsNew() {
    if (this.squareActivity.Detail.ActivityType !== this.serverConstants.squareActivityTypeConstants.discussion20Research) {
      return false;
    }

    if (this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.private) {
      return this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.privateDiscussionMin;
    }

    return this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.forumDiscussionMin;
  }

  private get isLowerThanCompletionPointsLimitResearchActivity() {
    return (this.isSurvey
      && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
      && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.researchActivityMin);
  }

  private get isLowerThanCompletionPointsLimitProfileActivity() {
    return this.isSurvey
        && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.profile
        && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.profileActivityMin;
  }

  private get isLowerThanCompletionPointsLimitLifeScenes() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateScoutResearch
      && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.lifeScenesMin;
  }

  private get isLowerThanCompletionPointsLimitLifeSnapshots() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicScoutResearch
      && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.lifeSnapshotsMin;
  }

  private get isLowerThanCompletionPointsLimitObservation() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.observation
      && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.observationMin;
  }

  private get isLowerThanCompletionPointsLimitSurveyActivity() {
    return this.isSurvey
        && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
        && this.model.CompletionPoints.Qualified < this.serverConstants.completionPointsConstants.surveyActivityMin;
  }

  isHigherThanCompletionPointsLimit() {
    return this.model != null && this.model.CompletionPoints != null && this.model.CompletionPoints.Qualified != null
      && (this.isHigherThanCompletionPointsLimitForumDiscussion
        || this.isHigherThanCompletionPointsLimitPrivateDiscussion
        || this.isHigherThanCompletionPointsLimitDiscussionsNew
        || this.isHigherThanCompletionPointsLimitResearchActivity
        || this.isHigherThanCompletionPointsLimitProfileActivity
        || this.isHigherThanCompletionPointsLimitLifeScenes
        || this.isHigherThanCompletionPointsLimitLifeSnapshots
        || this.isHigherThanCompletionPointsLimitObservation
        || this.isHigherThanCompletionPointsLimitSurveyActivity);
  }

  private get isHigherThanCompletionPointsLimitForumDiscussion() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch
      && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.forumDiscussionMax;
  }

  private get isHigherThanCompletionPointsLimitPrivateDiscussion() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch
      && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.privateDiscussionMax;
  }

  private get isHigherThanCompletionPointsLimitDiscussionsNew() {
    if (this.squareActivity.Detail.ActivityType !== this.serverConstants.squareActivityTypeConstants.discussion20Research) {
      return false;
    }

    if (this.model.Visibility === this.serverConstants.activityVisibilityTypeConstants.private) {
      return this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.privateDiscussionMax;
    }

    return this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.forumDiscussionMax;
  }

  private get isHigherThanCompletionPointsLimitResearchActivity() {
    return (this.isSurvey
      && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
      && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.researchActivityMax);
  }

  private get isHigherThanCompletionPointsLimitProfileActivity() {
    return this.isSurvey
        && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.profile
        && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.profileActivityMax;
  }

  private get isHigherThanCompletionPointsLimitLifeScenes() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.privateScoutResearch
      && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.lifeScenesMax;
  }

  private get isHigherThanCompletionPointsLimitLifeSnapshots() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.publicScoutResearch
      && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.lifeSnapshotsMax;
  }

  private get isHigherThanCompletionPointsLimitObservation() {
    return this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.observation
      && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.observationMax;
  }

  private get isHigherThanCompletionPointsLimitSurveyActivity() {
    return this.isSurvey
        && this.squareActivity.Detail.Format === this.serverConstants.squareActivityFormatConstants.research
        && this.model.CompletionPoints.Qualified > this.serverConstants.completionPointsConstants.surveyActivityMax;
  }

  checkCompletionPointValidity() {
    // Only check if SO and QF fields are shown
    if (!this.shouldShowSOandQFfields) {
      return;
    }

    this.form.$setValidity('CompletionPoints.QuotaFull', true);
    this.form['CompletionPoints.QuotaFull'].errorMessage = null;
    this.form.$setValidity('CompletionPoints.ScreenedOut', true);
    this.form['CompletionPoints.ScreenedOut'].errorMessage = null;

    if (this.model.CompletionPoints.Qualified < this.model.CompletionPoints.QuotaFull) {
      this.form.$setValidity('CompletionPoints.QuotaFull', false);
      this.form['CompletionPoints.QuotaFull'].errorMessage =
        this.serverConstants.validationConstants.completionPointsQuotaFullShouldBeLowerThanQualifiedValidationMessage;
    }

    if (this.model.CompletionPoints.Qualified < this.model.CompletionPoints.ScreenedOut) {
      this.form.$setValidity('CompletionPoints.ScreenedOut', false);
      this.form['CompletionPoints.ScreenedOut'].errorMessage =
        this.serverConstants.validationConstants.completionPointsScreenedOutShouldBeLowerThanQualifiedValidationMessage;
    }
  }

  private get isSurvey() {
    return (this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey
      || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.confirmitSurvey
      || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.decipherSurvey
      || this.squareActivity.Detail.ActivityType === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey);
  }

  get shouldShowSOandQFfields() {
    return this.isSurvey;
  }
}
