'use strict';

import './squareinfo.module';
import routeConfig from './squareinfo.route';
import { SquareInfoComponent } from './squareinfo.component';
import { SquareInfoChangeService } from './squareInfoChangeConfirm/squareinfochange.service';
import { SquareOpenRegistrationService } from './squareOpenRegistration/squareOpenRegistration.service';
import './squareclosure';

angular
  .module('insitesApp.squareinfo')
  .component('squareInfo', SquareInfoComponent)
  .service('squareInfoChangeService', SquareInfoChangeService)
  .service('squareOpenRegistrationService', SquareOpenRegistrationService)
  .run(routeConfig);
