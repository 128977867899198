'use strict';

import _ = require('lodash');
import Chart, { ChartConfiguration, ChartItem } from 'chart.js/auto';

export class DonutChartController implements ng.IOnChanges {
  static $inject = [];
  private chart: Chart;
  private isLoaded: boolean = false;
  constructor() { }

  private data = {
    datasets: [{
      data: [],
      backgroundColor: [],
      borderWidth: 0,
    }],
    labels: [],
  };

  private options = {
    cutout: '80%',
    responsive: true,
    layout: {
      padding: {
        left: 12,
        right: 12,
        top: 32,
        bottom: 0,
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            size: 11,
          },
          usePointStyle: true,
          padding: 8,
        },
      },
      title: {
        display: false,
        color: '#000',
        font: {
          size: 30,
        },
        text: '',
      },
      subtitle: {
        color: 'rgba(0,0,0,0.38)',
        font: {
          size: 14,
        },
        text: '',
      },
    },
    font: {
      family: 'Open Sans',
    },
  };

  private plugins = [{
    id: 'donut_chart',
    beforeDraw: (chart) => {
      // Get ctx from string
      const ctx = chart.ctx;
      ctx.save();

      // Get options from the center object in options
      const optionsConfig = chart.config.options;
      optionsConfig.plugins.legend.position = 'bottom';
      const fontStyle = optionsConfig.font.family || 'Arial';
      const txt = optionsConfig.plugins.title;
      const subTxt = optionsConfig.plugins.subtitle;

      // Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);


      // Draw text in center
      ctx.fillStyle = txt.color as string;
      ctx.font = `${txt.font.size}px ${fontStyle}`;
      ctx.fillText(txt.text as string, centerX, centerY - 6);

      ctx.fillStyle = subTxt.color as string;
      ctx.font = `${subTxt.font.size}px ${fontStyle}`;
      ctx.fillText(subTxt.text as string, centerX, centerY + 22);

      ctx.restore();
    },
  }];

  $onChanges = (changes) => {
    if (!this.isLoaded) {
      this.initChart();
    }
    if (changes.donutData && changes.donutData.currentValue) {
      _.forEach(changes.donutData.currentValue, (datapoint) => {
        this.data.datasets[0].data.push(datapoint.values);
        this.data.datasets[0].backgroundColor.push(datapoint.backgroundColor);
        this.data.labels.push(datapoint.text);
      });
    }
    if (changes.donutCenter !== undefined && changes.donutCenter.currentValue !== undefined) {
      this.options.plugins.title.text = changes.donutCenter.currentValue;
    }
    if (changes.donutSubCenter !== undefined && changes.donutSubCenter.currentValue !== undefined) {
      this.options.plugins.subtitle.text = changes.donutSubCenter.currentValue;
    }
    if (changes.donutPreventStatusHide !== undefined && changes.donutPreventStatusHide.currentValue === true) {
      this.options.plugins.legend = { ...this.options.plugins.legend, onClick: () => { } } as any;
    }
    this.chart.update();
  };

  initChart = () => {
    if (this.isLoaded) {
      return;
    }
    const canvasElement = document.getElementById('donutChartCanvas') as ChartItem;
    const config: ChartConfiguration = {
      type: 'doughnut',
      data: this.data,
      plugins: this.plugins,
      options: this.options,
    };
    this.chart = new Chart(canvasElement, config);

    this.isLoaded = true;
  };
}
