'use strict';


import { SquareService } from '../../core/dataservices/square.service';
export class AddSquareDialogController {
  static $inject = ['$mdDialog', 'squareservice', '$stateParams', 'serverConstants'];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private squareservice: SquareService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants,
  ) {
    this.getSquareList();
  }


  squareCreateTypeConstants = {
    defaultValues: 0,
    clone: 1,
  };

  squares;
  searchTerm = '';
  model = {
    createType: this.squareCreateTypeConstants.defaultValues,
    selectedSquareGuid: undefined,
  };

  cancel() {
    this.clearSearchTerm();
    this.$mdDialog.cancel();
  }

  addSquare() {
    this.$mdDialog.hide(this.model);
  }

  clearSearchTerm() {
    this.searchTerm = '';
  }

  async getSquareList() {
    const response = await this.squareservice.getProgramSquares(this.$stateParams.programGuid);
    this.squares = response.data.List;
  }
}
