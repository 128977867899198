'use strict';

import './incentives.module';
import { SquareConfigIncentivesComponent } from './incentives.component';
import routeConfig from './incentives.route';

angular
  .module('insitesApp.squareconfig.incentives')
  .component('incentives', SquareConfigIncentivesComponent)
  .run(routeConfig);
