'use strict';

import { ServerConstants } from '../../../core/serverconstants';
import { ExportConfigService } from '../../../core/services/exportconfig.service';
import { ConfigurationForExportType } from '../../../core/contracts/download.contract';
import { HttpService } from '../../../core/dataservices/http.service';
import { CurrentUserService } from '../../../core/dataservices/currentUser.service';
import { JobService } from '../../../core/jobs/job.service';
import { AuthService } from '../../../core/dataservices/auth.service';
import { SelectedSquareFactory } from '../../../core/selectedsquare.factory';

require('./data-export-modal.scss');

export class DataExportModalController {
  static $inject = [
    'httpservice',
    'exportType',
    'downloadParams',
    'exportconfigservice',
    '$mdDialog',
    'serverConstants',
    'jobservice',
    'authService',
    'currentUserService',
    'selectedSquareFactory'];
  constructor(
    private httpService: HttpService,
    private exportType: number,
    private downloadParams,
    private exportconfigservice: ExportConfigService,
    private $mdDialog: ng.material.IDialogService,
    private serverConstants: ServerConstants,
    private jobService: JobService,
    private authService: AuthService,
    private currentUserService: CurrentUserService,
    private selectedSquareFactory: SelectedSquareFactory,
  ) {
    this.exportConfig = this.exportconfigservice.getExportConfig(exportType);
    this.includeActiveActivitiesOnly = !!this.exportConfig.options.showIncludeActiveActivitiesOnlyOption;
    this.includeKeySegmentationsOnly = !!this.exportConfig.options.showIncludeKeySegmentationsOnlyOption;
    this.segmentValuesInOneColumn = !!this.exportConfig.options.showSegmentValuesInOneColumnOption;
    this.exportOnlyFilteredData = !!this.exportConfig.options.showExportOnlyFilteredDataOption;
    // redundant but left here for code clearity. Should be ticked off by default
    this.exportPiiData = false;

    if (exportType === serverConstants.exportTypeConstants.userQualConversations) {
      this.textDownloadValue = serverConstants.exportTypeConstants.userQualConversations;
      this.stimuliDownloadValue = serverConstants.exportTypeConstants.userQualConversationsStimuli;
    }

    this.initIsEditExportPiiDataOptionDisabled();
  }

  downloadType: number = this.exportType;
  chosenDownloadType: number = this.downloadType;
  exportConfig: ConfigurationForExportType;
  includeActiveActivitiesOnly: boolean;
  includeKeySegmentationsOnly: boolean;
  segmentValuesInOneColumn: boolean;
  exportOnlyFilteredData: boolean;
  exportPiiData: boolean;
  textDownloadValue: number = this.serverConstants.exportTypeConstants.qualConversation;
  stimuliDownloadValue: number = this.serverConstants.exportTypeConstants.qualConversationStimuli;
  powerpointOneColumnDownloadValue: number = this.serverConstants.exportTypeConstants.powerpointOneColumn;
  powerpointWorkshopDeckDownloadValue: number = this.serverConstants.exportTypeConstants.powerpointWorkshopDeck;
  isEditExportPiiDataOptionDisabled = true;

  cancel() {
    this.$mdDialog.cancel();
  }

  async export() {
    this.$mdDialog.hide();

    const powerpointExports = [
      this.serverConstants.exportTypeConstants.powerpointOneColumn,
      this.serverConstants.exportTypeConstants.powerpointWorkshopDeck,
    ];
    this.exportConfig = this.exportconfigservice.getExportConfig(this.chosenDownloadType);

    if (!powerpointExports.includes(this.chosenDownloadType)) {
      const jobIdResult = await this.httpService.post({
        url: this.exportConfig.downloadUrl,
        data: {
          includeActiveActivitiesOnly: this.includeActiveActivitiesOnly,
          includeKeySegmentationsOnly: this.includeKeySegmentationsOnly,
          segmentValuesInOneColumn: this.segmentValuesInOneColumn,
          exportOnlyFilteredData: this.exportOnlyFilteredData,
          exportPiiData: this.exportPiiData,
          ...this.downloadParams,
        },
      });
      if (jobIdResult && jobIdResult.data && jobIdResult.data !== '') {
        await this.jobService.showJobProgressDialog(
          jobIdResult.data, this.exportConfig.title);
        return true;
      }
    } else {
      // Powerpoint exports
      const requestSent = await this.httpService.post({
        url: this.exportConfig.downloadUrl,
        params: {
          activityGuid: this.downloadParams.activityGuid,
          exportType: this.chosenDownloadType,
        },
      }).then((response) => response.data);

      const dialogText = requestSent
        ? 'When the export is generated, detailed feedback is sent via email. Please close this window in the meantime.'
        : 'The service is temporarily unavailable. Please try again later.';

      await this.$mdDialog.show(
        this.$mdDialog.iscConfirm()
          .title(this.exportConfig.title)
          .text(dialogText)
          .ok('Close'));
    }

    return false;
  }

  isDiscussionTextCheckBoxDisabled(): boolean {
    return this.chosenDownloadType !== this.textDownloadValue;
  }

  private initIsEditExportPiiDataOptionDisabled() {
    if (!this.exportConfig.options.showExportPiiDataOption) {
      return;
    }

    if (this.authService.isFullDev() && this.currentUserService.role === this.serverConstants.roleConstants.human8) {
      this.isEditExportPiiDataOptionDisabled = false;
      return;
    }

    this.isEditExportPiiDataOptionDisabled = !this.selectedSquareFactory.AllowPiiInExports;
  }
}
