'use strict';

import { ProbeQuestionOverviewController } from './isc-probe-question-overview.controller';
require('./isc-probe-question-overview.scss');

const iscProbeQuestionOverviewTemplate = require('./isc-probe-question-overview.html');

export const IscProbeQuestionOverviewComponent: ng.IComponentOptions = {
  controller: ProbeQuestionOverviewController,
  templateUrl: iscProbeQuestionOverviewTemplate,
  controllerAs: 'vm',
  bindings: {
    maxQuestions: '<',
    maxQuestionsMessage: '@',
    questions: '=',
    allowedTypes: '<?',
    isEditable: '<',
    onAdd: '&',
    onDelete: '&',
    activityType: '<?',
    isAdditionalProbeQuestion: '<?',
    autosave: '&',
    onlyOptionalQuestions: '<',
    isDiscussionNew: '<?',
  },
};
