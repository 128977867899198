'use strict';

import { NotificationsService } from './notifications';
import { Subject } from 'rxjs/Subject';
import { IActivityFilterData, IActivityFilterAdditionalData, StimulusTranscriptionStatusChanged } from '../contracts/activity.contract';
import { PostChanged } from '@insites/vue-eco-temp-library/dist/types/SquareUi/src/components/user-notification-base/user-notification-base.types';
import { CamelCase, convertPascalCasedObjectToCamelCasedObject } from '@insites/vue-eco-temp-library';

export class ConversationEventsService {
  static $inject = ['notifications'];

  constructor(
    private notifications: NotificationsService,
  ) { }

  private _discussionChangeDone: {
    [activityGuid: string]: {
      openedEditorsCount: number,
      subject: Subject<string>,
    }
  } = { };

  public qualFilterChange = new Subject<IActivityFilterData>();
  public filterAdditionalDataChange = new Subject<IActivityFilterAdditionalData>();
  public filterDataCountersChange = new Subject<any>();
  public dataConversationsLoadingChange = new Subject<boolean>();
  public dataConversationsFilterApply = new Subject<IActivityFilterData>();
  public discussionActivityTypeChange = new Subject<{
    activityType: number;
    contributionType: number;
    visibility: number;
  }>();

  discussionChangeStarted(activityGuid: string) {
    if (!this._discussionChangeDone[activityGuid]) {
      this._discussionChangeDone[activityGuid] = {
        openedEditorsCount: 1,
        subject: new Subject<string>(),
      };
    } else {
      this._discussionChangeDone[activityGuid].openedEditorsCount++;
    }
  }

  discussionChangeEnded(activityGuid: string) {
    if (this._discussionChangeDone[activityGuid]) {
      if (this._discussionChangeDone[activityGuid].openedEditorsCount === 1) {
        this._discussionChangeDone[activityGuid].subject.next(activityGuid);
        this._discussionChangeDone[activityGuid].subject.complete();
        delete this._discussionChangeDone[activityGuid];
      } else {
        this._discussionChangeDone[activityGuid].openedEditorsCount--;
      }
    }
  }

  get forumConversationChange() {
    return {
      subscribe: async (topicGuid: string, callback: (guid: any) => void) => {
        await this.notifications.addUserToGroup(topicGuid);
        const subscription = this.notifications.forumConversationChanged.subscribe(() => {
          this.notifications.forumConversationChanged.resetSubscriptions();
          callback(topicGuid);
        });

        return async () => {
          await this.notifications.removeUserFromGroup(topicGuid);
          subscription();
        };
      },
    };
  }

  get discussionChange() {
    return {
      subscribe: async (activityGuid: string, callback: (guid: any) => void) => {
        await this.notifications.addUserToGroup(activityGuid);
        const subscriptionCallBack = () => {
          this.notifications.discussionChanged.resetSubscriptions();
          if (this._discussionChangeDone[activityGuid]) {
            this._discussionChangeDone[activityGuid].subject.subscribe((changedActivityGuid) => callback(changedActivityGuid));
          } else {
            callback(activityGuid);
          }
        };
        const discussionChangedSubscription = this.notifications.discussionChanged.subscribe(() => {
          subscriptionCallBack();
        });

        const discussionModerationDataChangedSubscription = this.notifications.discussionModerationDataChanged.subscribe(() => {
          subscriptionCallBack();
        });

        return async () => {
          await this.notifications.removeUserFromGroup(activityGuid);
          delete this._discussionChangeDone[activityGuid];
          discussionChangedSubscription();
          discussionModerationDataChangedSubscription();
        };
      },
    };
  }

  get discussionNewChange() {
    return {
      subscribe: async (discussionGuid: string, callback: (message: PostChanged) => void) => {
        await this.notifications.addUserToGroup(discussionGuid);

        const discussionChangedSubscription = this.notifications.discussionNewChanged.subscribe((message) => {
          message = convertPascalCasedObjectToCamelCasedObject(message);
          callback(message);
        });

        return async () => {
          await this.notifications.removeUserFromGroup(discussionGuid);
          discussionChangedSubscription();
        };
      },
    };
  }

  get stimulusEncoded() {
    return {
      subscribe: async (discussionGuid: string, callback: (message: CamelCase<StimulusTranscriptionStatusChanged>) => void) => {
        await this.notifications.addUserToGroup(discussionGuid);

        const stimulusEncodedSubscription = this.notifications.stimulusTranscriptionStatusChanged.subscribe((message) => {
          const convertedMessage = convertPascalCasedObjectToCamelCasedObject(message);
          callback(convertedMessage);
        });

        return async () => {
          await this.notifications.removeUserFromGroup(discussionGuid);
          stimulusEncodedSubscription();
        };
      },
    };
  }
}
