'use strict';

import { UrlTabOptionsController } from './urlTabOptions.controller';

const template = require('./urlTabOptions.html');

require('./_urlTabOptions.scss');

export const UrlTabOptionsComponent: ng.IComponentOptions = {
  controller: UrlTabOptionsController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    showOptions: '<',
    url: '<',
    openInNewTab: '=',
    isDisabled: '<',
  },
};
