'use strict';

import { ParticipantService } from '../../../../core/dataservices/participant.service';
import { SpinnerService } from '../../../../core/services/spinner.service';

export class BulkSendActivationDialogController {
  static $inject = ['$mdDialog', 'participantservice', 'logger', '_', 'selectedSquareParticipantGuids', 'spinnerservice'];

  isSendingEmail: boolean = false;
  overruledActivationLabels = {};
  overruledReminderLabels = {};
  overrulelabels = ['ActivationSuccess'];
  subjectLabelName;
  registrationStatusses;
  showReminderOverruler: boolean;
  showActivationOverruler: boolean;
  showMessageOptionNotValid: boolean = false;
  showActivationTemplate = false;
  showTemplate = false;
  isEdited = false;
  inProgress: boolean = false;
  result;
  title;
  sendingLabel;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private participantservice: ParticipantService,
    private logger: Logger,
    private _: _.LoDashStatic,
    private selectedSquareParticipantGuids,
    private spinnerservice: SpinnerService,
  ) {
    this.getParticipantStatusCounts();
  }
  async $onInit() {
    this.getCustomEmail();
  }

  getParticipantStatusCounts() {
    // eslint-disable-next-line prefer-const
    let promise;
    this.spinnerservice.showFor('loading', promise);
    promise = this.participantservice.getParticipantStatusCounts(this.selectedSquareParticipantGuids)
      .then((response) => {
        this.registrationStatusses = response;
        this.showActivationOverruler = this._.has(this.registrationStatusses, 'SingleOptInNoEmail');
        this.showReminderOverruler = this._.intersection(this._.keys(this.registrationStatusses),
          ['SingleOptIn', 'SingleOptInReminder', 'DoubleOptIn', 'DoubleOptInReminder']).length > 0;
        this.showMailTemplates();
        this.getSendingLabel();
      });
  }

  getSendingLabel() {
    this.sendingLabel = '(Re)Send';
    if (this.showActivationOverruler && !this.showReminderOverruler) {
      this.sendingLabel = 'Send';
    } else if (!this.showActivationOverruler && this.showReminderOverruler) {
      this.sendingLabel = 'Resend';
    }
  }

  showMailTemplates() {
    this.showMessageOptionNotValid = !this.showActivationOverruler && !this.showReminderOverruler;
  }

  bulkSendActivation() {
    this.isSendingEmail = true;
    this.participantservice.bulkSendActivation(this.selectedSquareParticipantGuids,
      this.overruledActivationLabels, this.overruledReminderLabels, this.isEdited, this.showTemplate)
      .then((response) => {
        this.result = response;
      }).catch((error) => {
        this.logger.error(`<p>${error.data.Reason}</p><p>${error.data.Message}</p>`);
      }).finally(() => {
        this.isSendingEmail = false;
        this.$mdDialog.hide(this.result);
      });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  getCustomEmail() {
    this.participantservice.getSquareParticipantCustomEmail(this.selectedSquareParticipantGuids).then((ce) => {
      const customEmails = JSON.parse(ce);
      if (customEmails.length > 1) {
        this.setActivationAndReminderEmails(customEmails);
      } else {
        this.setActivationOrReminderEmail(customEmails);
      }
    });
  }

  setActivationAndReminderEmails(customEmails){
    const activationEmail = customEmails[0];
    this.setLabels(false, activationEmail);
    const reminderEmail = customEmails[1];
    if (reminderEmail.LabelCreateParticipantSubject != null) {
      delete reminderEmail.LabelCreateParticipantSubject;
    }
    this.setLabels(true, reminderEmail);
  }

  setActivationOrReminderEmail(customEmail) {
    if (this.showReminderOverruler && !this.showActivationOverruler && customEmail.LabelCreateParticipantSubject != null) {
      delete customEmail.LabelCreateParticipantSubject;
      this.setLabels(true, customEmail);
    } else {
      if (customEmail.LabelReminderActivationSubject != null) {
        this.setLabels(true, customEmail);
      }
      this.setLabels(false, customEmail);
    }
  }

  setLabels(isReminder, customEmail) {
    if (isReminder) {
      for (const label in customEmail) {
        this.overruledReminderLabels[label] = customEmail[label];
      }
      this.showTemplate = true;
    } else {
      for (const label in customEmail) {
        this.overruledActivationLabels[label] = customEmail[label];
      }
      this.showActivationTemplate = true;
    }
  }
}
