'use strict';

import { ClientProgramsController } from './clientprograms.controller';
const template = require('./clientprograms.html');

angular.module('insitesApp.clientprograms').component('clientPrograms', {
  controller: ClientProgramsController,
  controllerAs: 'vm',
  templateUrl: template,
});

