'use strict';

import { ServerConstants } from './../../../core/serverconstants';
import { ISurveyActivityResponse } from './../../../core/dataservices/dataservice.contracts.d';
import { ActivityHelperService } from '../../../core/services/activityHelper.service';
import { EditSegmentationDialogController } from './editSegmentationDialog/editSegmentationDialog.controller';
import { Pagination } from '../../../core/models/pagination';
import { SpinnerService } from '../../../core/services/spinner.service';
import { ActivityService } from '../../../core/dataservices/activity.service';
import * as _ from 'lodash';
import { SegmentationHelperService } from '../../../core/services/segmentationHelper.service';
import { HelperService } from '../../../core/services/helper.service';
import { SegmentationService } from '../../../core/dataservices/segmentation.service';

const editSegmentationDialogTemplateUrl = require('./editSegmentationDialog/editSegmentationDialog.html');

export class SegmentationController {
  static $inject = ['helperservice', 'segmentationservice', 'spinnerservice',
    '$mdDialog', 'activityHelperService', 'serverConstants', 'segmentationHelperService',
    'activityservice', 'logger','$timeout'];
  constructor(
    private helperservice: HelperService,
    private segmentationService: SegmentationService,
    private spinnerservice: SpinnerService,
    private $mdDialog: ng.material.IDialogService,
    private activityHelperService: ActivityHelperService,
    private serverConstants: ServerConstants,
    private segmentationHelperService: SegmentationHelperService,
    private activityservice: ActivityService,
    private logger: Logger,
    private $timeout: ng.ITimeoutService,
  ) { }

  filter = '';
  loading = true;
  pagination: IPagination = new Pagination(25);
  segmentations;
  segmentationType = this.serverConstants.segmentationTypeConstants;
  surveyActivities: ISurveyActivityResponse[] = null;
  segmentationStatuses: any[];

  $onInit() {
    this.segmentationStatuses = ['active']; // Default values, do we save these as preferences ?
    this.statusFilterChange(this.segmentationStatuses);
  }

  private loadSegmentations(showDisabled: boolean, showArchived: boolean) {
    this.spinnerservice.show('loading');
    this.segmentationService.getSquareSegmentations(false, showDisabled, showArchived).then((segmentations) => {
      _.forEach(segmentations, (segmentation) => {
        segmentation.ActivityStatus = this.activityHelperService.getSquareActivityStatusAsString(segmentation.ActivityStatus);
      });
      this.segmentations = segmentations;
    }).finally(() => {
      this.spinnerservice.hide('loading');
      this.loading = false;
    });
  }

  async archive(segmentation) {
    await this.segmentationService.toggleIsArchived(segmentation.Guid).then(
      (result) => {
        if (result) {
          segmentation.IsArchived = !segmentation.IsArchived;
          this.segmentationService.invalidateSegmentationForFiltering(true);
        }
      },
      (error) => {  // Is this defined in some base service?
        if (error.status === 400) {
          const data = error.data;
          let message = '';
          _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
            message += `${validationError.ErrorMessage} `;
          });
          if (message.length > 0) {
            this.logger.error(message);
          }
        }
      },
    );
  }

  search(filter) {
    return this.helperservice.search(filter, this.filter);
  }

  statusFilterChange(value: any) {
    const showDisabled = _.some(value, (e) => e === 'disabled');
    const showArchived = _.some(value, (e) => e === 'archived');
    this.loadSegmentations(showDisabled, showArchived);
  }

  isSegmentationArchivingDisabled(segmentation) {
    const excluded = [this.segmentationType.platformBehavior, this.segmentationType.activityBehavior, this.segmentationType.profile];
    return _.includes(excluded, segmentation.Type);
  }

  async editSegmentation(segmentation) {
    // Lazy load surveyActivities
    if (!this.surveyActivities) {
      this.surveyActivities = await this.activityservice.listSurveyActivitiesForCurrentSquare();
      this.surveyActivities = _.filter(this.surveyActivities, (activity) => activity.type !== this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey);
      _.forEach(this.surveyActivities, (activity) => activity.displayLabel = `${activity.activityName} (${activity.surveyId})`);
    }

    this.$mdDialog.show({
      controller: EditSegmentationDialogController,
      controllerAs: 'vm',
      templateUrl: editSegmentationDialogTemplateUrl,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        segmentationGuid: segmentation ? segmentation.Guid : null,
        segmentationName: segmentation ? segmentation.Question : null,
        segmentationType: segmentation ? segmentation.Type : this.segmentationType.manual,
        membersInSegmentation: segmentation ? segmentation.MembersInSegmentation : 0,
        surveyActivities: this.surveyActivities,
      },
      onComplete: () => {
      // Fix focus issue on segmantation modal
        this.$timeout(() => {
          const element: HTMLElement = document.querySelector('md-dialog');
          if (element) {
            element.focus();
          }
        },0);
      },
    }).finally(() => {
      this.$onInit();
    });
  }

  toggleFavorite(segmentation) {
    this.segmentationService.toggleFavoriteSegment(segmentation.Guid).then((response) => {
      segmentation.IsStarred = response;
    });
  }

  togglePublic(segmentation) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: '',
      text: `Changes are visible on Participant Member Profile page.
      ${
  !segmentation.IsPublic
    ? `If Public Member Profile is enabled on the Square info page this information will also be visible to other Participants.
    Make sure your segmentation name & relevant segments are properly named.`
    : 'This information will no longer be shown on the Participant Member Profile page for any Participant Member.'
}
      Do you want to proceed?`,
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      this.segmentationService.togglePublicSegmentation(segmentation.Guid).then((response) => {
        segmentation.IsPublic = response;
      });
    });
  }

  deleteSegmentation(segmentation) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: 'Delete segmentation',
      text: `Are you sure you want to ${segmentation.Type === this.segmentationType.activityBehavior ? 'disable' : 'delete'
      } the segmentation ${segmentation.Question} (${segmentation.MembersInSegmentation}) ?`,
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      const createPromise = segmentation.Type === this.segmentationType.activityBehavior ?
        this.segmentationService.toggleActivitySegmentation(segmentation.ActivityGuid, true) :
        this.segmentationService.deleteSegmentation(segmentation.Guid);
      createPromise
        .then(() => {
          this.segmentations = _.without(this.segmentations, segmentation);
        })
        .catch((exception) => {
          this.$mdDialog.show(this.$mdDialog.iscAlert({
            title: 'Delete segmentation',
            text: exception.data.Message,
            ok: 'Got it!',
          }));
        });
      this.spinnerservice.showFor('loading', createPromise);
    });
  }

  segmentationTypeToReadableString(segmentationType: number) {
    return this.segmentationHelperService.segmentationTypeToReadableString(segmentationType);
  }
}
