'use strict';

import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import * as _ from 'lodash';

export class UrlTabOptionsController {
  static $inject = ['__env', 'selectedSquareFactory'];
  constructor(
    __env,
    private selectedSquareFactory: SelectedSquareFactory,
  ) {
    this.squareUrlBase = __env.squareUrlBase;
  }

  squareUrlBase;
  openInNewTab;
  isDisabled: boolean;

  $onChanges(changes) {
    if (changes.url && changes.url.currentValue && !changes.url.isFirstChange()) {
      this.checkUrl(changes.url.currentValue);
    }
  }

  private checkUrl(url) {
    const protocol = url.substring(0, url.indexOf(':'));
    if (protocol === 'http' || protocol === 'https') {
      const urlToCompare = this.selectedSquareFactory.SquareUrl
        ? (`${protocol}://${this.selectedSquareFactory.SquareUrl}.${this.squareUrlBase}`)
        : this.squareUrlBase;
      this.openInNewTab = !_.includes(url, urlToCompare);
    } else {
      this.openInNewTab = true;
    }
  }
}
