'use strict';

import { DateTime } from 'luxon';
import { ServerConstants } from './../../core/serverconstants';
import { DownloadService } from '../../core/services/download.service';
import { ActivityService } from '../../core/dataservices/activity.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { Utils } from '../../core/utils';
import { ConstantsFactory } from '../../core/constants.factory';
import * as _ from 'lodash';
import { IActivityReportData } from '../../core/contracts/activity.contract';
import { ActivityInfoForSegmentTargetCompletion } from 'src/app/layout/wizard-targeting/target-completion/target-completion.controller';
import { FeatureService } from '../../core/dataservices/feature.service';
import { AuthService } from '../../core/dataservices/auth.service';

export class ActivityHealthController {
  static $inject = ['$stateParams', 'activityservice', '$state', 'spinnerservice', 'serverConstants',
    'downloadservice', 'logger', '$q', 'labelFactory', 'constantsfactory', '$location', 'featureservice',
    'authService'];

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    private $state: ng.ui.IStateService,
    private spinnerservice: SpinnerService,
    private serverConstants: ServerConstants,
    private downloadservice: DownloadService,
    private logger,
    private $q: ng.IQService,
    private labelFactory,
    private constantsfactory: ConstantsFactory,
    private $location: ng.ILocationService,
    private featureService: FeatureService,
    private authService: AuthService,
  ) { }

  activityGuid = this.$stateParams.activityGuid;
  activityStatusConstants = this.serverConstants.squareActivityStatusConstants;
  targetingFilterOptionConstants = this.serverConstants.targetingFilterOptionConstants;
  channelTypeConstants = this.serverConstants.channelTypeConstants;
  applicationLabels;
  activityFormatConstants = this.serverConstants.squareActivityFormatConstants;
  isLoading = false;
  activityHealth: IActivityReportData;
  showDateLastSynced: boolean = false;
  activityTypes = [];
  activityInfoForSegmentTargetCompletion: ActivityInfoForSegmentTargetCompletion;
  lastSegmentTargetModified: DateTime;
  segmentTargetItems: [];

  isSegmentTargetCompletionEnabled = false;

  ActivityFormats = [
    {
      Label: 'Screener Survey',
      ActivityFormat: this.serverConstants.squareActivityFormatConstants.screener,
    },
    {
      Label: 'Profile Survey',
      ActivityFormat: this.serverConstants.squareActivityFormatConstants.profile,
    },
    {
      Label: '',
      ActivityFormat: this.serverConstants.squareActivityFormatConstants.research,
    },
  ];

  Statuses = [
    {
      Label: 'Scheduled',
      ActivityStatus: this.activityStatusConstants.scheduled,
    },
    {
      Label: 'Active',
      ActivityStatus: this.activityStatusConstants.active,
    },
    {
      Label: 'Closed',
      ActivityStatus: this.activityStatusConstants.closed,
    },
    {
      Label: 'Draft',
      ActivityStatus: this.activityStatusConstants.draft,
    },
    {
      Label: 'Archived',
      ActivityStatus: this.activityStatusConstants.archived,
    },
  ];

  GlobalTargetingFilterOptions = [
    {
      Label: 'Match none of the following',
      TargetingFilterOption: this.targetingFilterOptionConstants.none,
    },
    {
      Label: 'Match any of the following',
      TargetingFilterOption: this.targetingFilterOptionConstants.any,
    },
    {
      Label: 'Match all of the following',
      TargetingFilterOption: this.targetingFilterOptionConstants.all,
    },
  ];

  ContainsTargetingFilterOptions = [
    {
      Label: 'none of the following',
      TargetingFilterOption: this.targetingFilterOptionConstants.none,
    },
    {
      Label: 'any of the following',
      TargetingFilterOption: this.targetingFilterOptionConstants.any,
    },
    {
      Label: 'all of the following',
      TargetingFilterOption: this.targetingFilterOptionConstants.all,
    },
  ];

  async $onInit() {
    try {
      await this.init();
    } catch (e) {
      this.logger.error('Failed to init progress data component.', e);
    }
  }

  private async init() {
    this.logger.info('Health details activated');
    this.spinnerservice.show('loading');

    this.$q.all([this.getHealthDetails()]).finally(async () => {
      this.logger.info('Health details loaded');
      this.spinnerservice.hide('loading');

      if (this.isActivityCorrectTypeForSegmentTargetCompletion()) {
        this.isSegmentTargetCompletionEnabled = await this.featureService.checkFeatureAccessibilityForSetup(
          this.serverConstants.featureConstants.activityTargetCompletion);
      }
    });

    for (const activityType in this.serverConstants.squareActivityTypeConstants) {
      const activityName = this.constantsfactory.GetLabelForSquareActivityType(
        this.serverConstants.squareActivityTypeConstants[activityType]);
      this.activityTypes.push({
        Label: activityName,
        ActivityType: this.serverConstants.squareActivityTypeConstants[activityType],
      });
    }
  }

  goBackAct() {
    if (this.$stateParams.referer) {
      this.$location.url(this.$stateParams.referer);
    } else if (this.activityHealth.Type === this.serverConstants.squareActivityTypeConstants.confirmitSurvey ||
      this.activityHealth.Type === this.serverConstants.squareActivityTypeConstants.decipherSurvey ||
      this.activityHealth.Type === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey) {
      this.$state.go('root.square.activities.quant', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      });
    } else {
      this.$state.go('root.square.activities.qual', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      });
    }
  }

  getHealthDetails() {
    this.isLoading = true;
    return this.$q.all([
      this.activityservice.getActivityDataReport(this.activityGuid),
      this.getLabels(),
    ]).then((responses) => {
      const response = responses[0];
      response.Status = this.getStatusLabel(response.Status);
      response.StartDate = DateTime.fromISO(response.StartDate as string);
      response.EndDate = DateTime.fromISO(response.EndDate as string);
      response.DateLastSynced = response.DateLastSynced ?
        DateTime.fromISO(response.DateLastSynced as string).toLocaleString(DateTime.DATETIME_MED) : 'Not synced yet';
      if (response.Type === this.serverConstants.squareActivityTypeConstants.confirmitSurvey ||
        response.Type === this.serverConstants.squareActivityTypeConstants.decipherSurvey ||
        response.Type === this.serverConstants.squareActivityTypeConstants.decipherDiarySurvey ||
        response.Type === this.serverConstants.squareActivityTypeConstants.checkMarketSurvey) {
        this.ActivityFormats[2].Label = 'Research Survey';
        response.OverallProgressGraphData = [
          { values: [response.OverallProgress.Qualified], text: this.serverConstants.surveyOutcomeCodeLabelConstants.qualifiedLabel, backgroundColor: '#347F62' },
          { values: [response.OverallProgress.Screened], text: this.serverConstants.surveyOutcomeCodeLabelConstants.screenedLabel, backgroundColor: '#C2273D' },
          { values: [response.OverallProgress.QuotaFull], text: this.serverConstants.surveyOutcomeCodeLabelConstants.quotaFullLabel, backgroundColor: '#F29303' },
          { values: [response.OverallProgress.Incomplete + response.OverallProgress.NotStarted], text: 'Incomplete', backgroundColor: '#E0E0E0' },
        ];
      } else if (this.activityservice.isQualResearchActivity(response.Type)) {
        this.ActivityFormats[2].Label = 'Research Discussion';
        response.OverallProgressGraphData = [
          { values: [response.OverallProgress.Completed], text: this.serverConstants.sampleStatusLabelConstants.completedLabel, backgroundColor: '#347F62' },
          { values: [response.OverallProgress.Incomplete], text: this.serverConstants.sampleStatusLabelConstants.inProgressLabel, backgroundColor: '#F29303' },
          { values: [response.OverallProgress.NotStarted], text: this.serverConstants.sampleStatusLabelConstants.toDoLabel, backgroundColor: '#E0E0E0' },
        ];
      }
      response.DropOutRate.Percentage = Math.floor(response.DropOutRate.Percentage);
      if (response.OverallProgress.Invited == null) {
        response.OverallProgress.Invited = '?';
        response.OverallProgress.Percentage = 0;
      } else {
        response.OverallProgress.Percentage =
          _.round(response.OverallProgress.Invited === 0 ? 0 : 100 * (response.OverallProgress.Completed || 0) / ((response.OverallProgress.Invited as number)));
      }
      _.each(response.ReportDataCommunicationList, (communication) => {
        communication.TargetingFilterOption = this.getGlobalTargetingFilterOptionLabel(communication.TargetingFilterOption as number);
        communication.ChannelTypeLabel = this.getChannelTypeLabel(communication);
        _.each(communication.Items, (item) => {
          item.TargetingFilterOption = this.getContainsTargetingFilterOptionLabel(item.TargetingFilterOption as number);
          item.Segments = (item.Segments as string[]).join(', ');
        });
      });
      _.each(response.TargetingHistory, (history) => {
        history.TargetingFilterOption = this.getGlobalTargetingFilterOptionLabel(history.TargetingFilterOption as number);
        history.CommunicationDateCreated = DateTime.fromISO(history.CommunicationDateCreated).toLocaleString(DateTime.DATETIME_MED);
        _.each(history.Items, (item) => {
          item.TargetingFilterOption = this.getContainsTargetingFilterOptionLabel(item.TargetingFilterOption as number);
          item.Segments = (item.Segments as string[]).join(', ');
        });
      });
      this.showDateLastSynced = response.Type === this.serverConstants.squareActivityTypeConstants.confirmitSurvey ||
        response.Type === this.serverConstants.squareActivityTypeConstants.decipherSurvey;
      this.activityHealth = response;

      // We need some information bundled together for the segment target completion table
      this.activityInfoForSegmentTargetCompletion = {
        activityType: this.activityHealth.Type,
        activityFormat: this.activityHealth.Format,
        activityStatus: this.activityHealth.Status,
      };

      this.isLoading = false;
    });
  }

  getStatusLabel(activityStatus) {
    return _.find(this.Statuses, (o: any) => o.ActivityStatus === activityStatus).Label;
  }

  getGlobalTargetingFilterOptionLabel(targetingFilterOption: number): string {
    return _.find(this.GlobalTargetingFilterOptions, (o: any) => o.TargetingFilterOption === targetingFilterOption).Label;
  }

  getContainsTargetingFilterOptionLabel(targetingFilterOption: number): string {
    return _.find(this.ContainsTargetingFilterOptions, (o: any) => o.TargetingFilterOption === targetingFilterOption).Label;
  }

  getLabels() {
    const defaultLanguage = 'en';
    return this.labelFactory.getLabelsLanguage(defaultLanguage).then((labels) => {
      this.applicationLabels = labels;
    });
  }

  getChannelTypeLabel(communication) {
    const channelTypes = communication.CommunicationChannelTypes
      .map((channelType) => Utils.getChannelTypeLabel(this.serverConstants.channelTypeConstants, this.serverConstants.communicationChannelConstants, channelType))
      .filter((x) => x);
    if (channelTypes.length === 0) {
      return '';
    }
    return `(${channelTypes.join(', ')})`;
  }

  // Check if there is data to show for segment targeting
  shouldShowSegmentTargetCompletionTable() {
    return this.segmentTargetItems && this.segmentTargetItems.length;
  }

  // If this activity type doesn't have targeting, we don't need to check for the feature setting or do any other API calls for it
  isActivityCorrectTypeForSegmentTargetCompletion() {
    const formats = this.serverConstants.squareActivityFormatConstants;
    return this.activityHealth.Format === formats.research
      || this.activityHealth.Format === formats.profile;
  }
  goToWizardTargetStep() {
    this.$state.go('root.square.activityWizardType', {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      squareGuid: this.$stateParams.squareGuid,
      activityGuid: this.$stateParams.activityGuid,
      step: 'Target',
    });
  }
}
