'use strict';

export class BasicActivityFilterController {
  filterdata;
  applyFilter;

  removeKeyword(searchTerm) {
    this.filterdata.Keyword = searchTerm;
    this.applyFilter();
  }

  filterChanged() {
    this.filterdata.Highlight = false;
  }
}
