'use strict';

import { DropdownController } from './dropdownController';

const template = require('./dropdown.html');

export class Dropdown implements ng.IDirective {
  bindToController = true;
  controller = DropdownController;
  controllerAs = 'vm';
  restrict = 'E';
  templateUrl = template;
  scope = {
    list: '<',
    enablePinning: '<',
    onSelect: '&',
    isSearchBox: '=',
    label: '@',
    placeholder: '@',
    isDisabled: '<',
    showSuggestionList: '<?',
    icon: '@?',
  };
}
