'use strict';

import {
  IThemeItem,
  IMemberItem,
  ITagItem,
  IProbeQuestionItem,
  IActivityFilterData,
  IHashtagItem,
  IRating,
  IStaticFilterOption,
} from '../../core/contracts/activity.contract';
import { IChipsItem } from '../../core/contracts/filter.contract';
import { ActivityService } from '../../core/dataservices/activity.service';
import { ForumService } from '../../core/dataservices/forum.service';
import { SquareService } from '../../core/dataservices/square.service';
import { SegmentationService } from '../../core/dataservices/segmentation.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { ChipsItem } from './chipsItem';
import { ServerConstants } from '../../core/serverconstants';
import { ConversationEventsService } from '../../core/services/conversationEvents.service';
import * as _ from 'lodash';
export class IscActivityFilterController {
  static $inject = ['serverConstants', 'spinnerservice', 'segmentationservice', '$stateParams',
    '$scope', 'squareservice', 'activityservice', 'forumservice', '$rootScope', 'logger',
    'conversationEventsService', '$q', '$element'];
  constructor(
    private serverConstants: ServerConstants,
    private spinnerservice: SpinnerService,
    private segmentationservice: SegmentationService,
    private $stateParams: ng.ui.IStateParamsService,
    private $scope: ng.IScope,
    private squareService: SquareService,
    private activityService: ActivityService,
    private forumservice: ForumService,
    private $rootScope: ng.IRootScopeService,
    private logger: Logger,
    private conversationEventsService: ConversationEventsService,
    private $q: ng.IQService,
    private $element: ng.IRootElementService,
  ) { }

  filterActivities: () => void;
  filterData: IActivityFilterData;
  filterDataCounters;
  themes: IThemeItem[];
  members: IMemberItem[];
  tags: ITagItem[];
  hashtags: IHashtagItem[];
  selectedThemesChips = [];
  selectedMembersChips = [];
  selectedSegmentsChips = [];
  selectedTagsChips = [];
  selectedHashtagsChips = [];
  selectedProbeQuestionsChips = [];
  selectedRatingsChips = [];
  selectedStimuliOptionsChips = [];
  selectedAnnotationsOptionsChips = [];
  selectedModerationStatusOptionsChips = [];
  probeQuestions: IProbeQuestionItem[];
  segmentations;
  segmentsObj;

  stimuliOptions: IStaticFilterOption[] = [
    {
      Id: this.serverConstants.stimuliFilterOptionConstants.anyStimuli,
      Text: this.serverConstants.activityFilterConstants.anyStimuli,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.anyStimuli}`,
    },
    {
      Id: this.serverConstants.stimuliFilterOptionConstants.noStimuli,
      Text: this.serverConstants.activityFilterConstants.noStimuli,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.noStimuli} (0)`,
    },
    {
      Id: this.serverConstants.stimuliFilterOptionConstants.image,
      Text: this.serverConstants.activityFilterConstants.image,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.image} (0)`,
    },
    {
      Id: this.serverConstants.stimuliFilterOptionConstants.video,
      Text: this.serverConstants.activityFilterConstants.video,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.video} (0)`,
    },
  ];

  annotationsOptions: IStaticFilterOption[] = [
    {
      Id: this.serverConstants.annotationFilterOptionConstants.anyAnnotation,
      Text: this.serverConstants.activityFilterConstants.anyAnnotation,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.anyAnnotation}`,
    },
    {
      Id: this.serverConstants.annotationFilterOptionConstants.noAnnotation,
      Text: this.serverConstants.activityFilterConstants.noAnnotation,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.noAnnotation} (0)`,
    },
    {
      Id: this.serverConstants.annotationFilterOptionConstants.notes,
      Text: this.serverConstants.activityFilterConstants.notes,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.notes} (0)`,
    },
    {
      Id: this.serverConstants.annotationFilterOptionConstants.highlights,
      Text: this.serverConstants.activityFilterConstants.highlights,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.highlights} (0)`,
    },
  ];

  anyRatingValue: number = -1; // this is an invalid value used only for UX
  ratings: IRating[] = [
    { Selected: false, Value: this.anyRatingValue, Text: 'Any rating', DisplayLabel: 'Any rating (0)' },
    { Selected: false, Value: 0, Text: 'No Rating', DisplayLabel: 'No Rating (0)' },
    { Selected: false, Value: 1, Text: '★', DisplayLabel: '★ (0)' },
    { Selected: false, Value: 2, Text: '★★', DisplayLabel: '★★ (0)' },
    { Selected: false, Value: 3, Text: '★★★', DisplayLabel: '★★★ (0)' },
    { Selected: false, Value: 4, Text: '★★★★', DisplayLabel: '★★★★ (0)' },
    { Selected: false, Value: 5, Text: '★★★★★', DisplayLabel: '★★★★★ (0)' },
  ];

  allModerationStatusOptions: IStaticFilterOption[] = [
    {
      Id: this.serverConstants.moderationStatusOptionConstants.anyModerationStatus,
      Text: this.serverConstants.activityFilterConstants.anyModerationStatus,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.anyModerationStatus}`,
    },
    {
      Id: this.serverConstants.moderationStatusOptionConstants.unmoderated,
      Text: this.serverConstants.activityFilterConstants.unmoderated,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.unmoderated} (0)`,
    },
    {
      Id: this.serverConstants.moderationStatusOptionConstants.moderatedUpdateRequired,
      Text: this.serverConstants.activityFilterConstants.moderatedUpdateRequired,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.moderatedUpdateRequired} (0)`,
    },
    {
      Id: this.serverConstants.moderationStatusOptionConstants.moderatedAccepted,
      Text: this.serverConstants.activityFilterConstants.moderatedAccepted,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.moderatedAccepted} (0)`,
    },
    {
      Id: this.serverConstants.moderationStatusOptionConstants.moderatedOther,
      Text: this.serverConstants.activityFilterConstants.moderatedOther,
      DisplayLabel: `${this.serverConstants.activityFilterConstants.moderatedOther} (0)`,
    },
  ];
  moderationStatusOptions: IStaticFilterOption[];

  chips: IChipsItem = new ChipsItem();
  isQual: boolean;
  dateAsChip = [];
  keywordAsChip = [];
  showFilter = true;
  showToggle = false;
  toggleClicked = false;
  scrollEvent;
  applyFilter;
  showThemeFilter: boolean = true;
  private removeHandler = _.noop;
  selectAllTagsObj = { Text: 'Any tag', Guid: 'SelectAllTags' };
  selectAllHashtagsObj = { Text: 'Any hashtag', Guid: 'SelectAllHashtags' };

  $onDestroy() {
    this.removeHandler();
  }

  async $onInit() {
    try {
      this.spinnerservice.show('loading');
      await this.init();
    } catch (e) {
      this.logger.error('Failed to load activity filters', e);
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async init() {
    this.chips.Filters = [];
    this.showThemeFilter = !this.$stateParams.activityGuid;
    this.moderationStatusOptions = this.allModerationStatusOptions;
    this.scrollEvent = document.getElementById('maincontent');

    const handler = () => this.filterScroll();
    this.scrollEvent.addEventListener('scroll', handler);
    this.removeHandler = () => this.scrollEvent.removeEventListener('scroll', handler);

    if (this.filterData) {
      this.filterData.Highlight = this.filterData && this.filterData.Keyword ? true : false;
    }

    this.$scope.$watchGroup(['vm.filterData.Keyword'], () => {
      if (this.filterData) {
        this.keywordAsChip = this.convertToChip(this.filterData.Keyword);
      }
    });
    let initialize = true;
    this.$scope.$watchGroup(['vm.filterData.StartDate', 'vm.filterData.EndDate'], (newVal, oldVal) => {
      if (this.filterData && (initialize || !(newVal[0].equals(oldVal[0]) && newVal[1].equals(oldVal[1])))) {
        // make sure stateparams is correctly filled in so the filter is not overwritten by the queryparams in the url
        this.$stateParams.startDate = this.filterData.StartDate.toFormat('yyyy-LL-dd');
        this.$stateParams.endDate = this.filterData.EndDate.toFormat('yyyy-LL-dd');

        this.chips.StartDate = this.filterData.StartDate.toFormat('MMM d yyyy');
        this.chips.EndDate = this.filterData.EndDate.toFormat('MMM d yyyy');
        this.dateAsChip = this.convertToChip(`${this.chips.StartDate} - ${this.chips.EndDate}`);
        this.keywordAsChip = this.convertToChip(this.filterData.Keyword);
        this.fillInChips();
        initialize = false;
      }
      this.$element.find('md-chips-wrap').attr('tabindex', '-1');
    });

    const filterListPromises = [];
    let themesIndex: number = null;
    let probeQuestionsIndex: number = null;
    let segmentationsIndex: number = null;
    let membersIndex: number = null;
    let tagsIndex: number = null;
    let hashtagsIndex: number = null;

    if (this.showThemeFilter) {
      // We don't need the theme filter if we are on the 'ActivityData' page
      filterListPromises.push(this.squareService.getThemesForDropdown());
      themesIndex = filterListPromises.length - 1;
    }

    if (this.isQual) {
      // Only make a request when ActivityGuid is set
      if (this.$stateParams.activityGuid) {
        if (!this.filterData.ActivityType) {
          /* The activity-filter component is not a child of the activity-data-conversation component (it's actually higher in the hierarchy).
           When the user arrives at the activity page from a different endpoint than the Activities overview, we use the activityService to
           get the activityType. */
          const response = await this.activityService.getActivityDetails(this.$stateParams.activityGuid);
          if (response) {
            this.filterData.ActivityType = response.data.Type;
          }
        }
        filterListPromises.push(this.forumservice.getProbequestionsForFiltering(this.$stateParams.activityGuid));
        probeQuestionsIndex = filterListPromises.length - 1;
      }

      this.conversationEventsService.filterAdditionalDataChange.subscribe((result) => {
        this.setModeratorStatusOptions(result.IsModeratorCurationEnabled);
      });

      filterListPromises.push(this.segmentationservice.getSegmentationForFiltering(true));
      segmentationsIndex = filterListPromises.length - 1;
      filterListPromises.push(this.activityService.getMemberList(this.$stateParams.activityGuid));
      membersIndex = filterListPromises.length - 1;
      filterListPromises.push(this.forumservice.getConversationTagList(this.$stateParams.activityGuid));
      tagsIndex = filterListPromises.length - 1;
      filterListPromises.push(this.forumservice.getConversationHashtagList(this.$stateParams.activityGuid));
      hashtagsIndex = filterListPromises.length - 1;

      const unregisterTags = this.$rootScope.$on('tagsChanged', async () => {
        this.tags = await this.forumservice.getConversationTagList(this.$stateParams.activityGuid);
      });
      this.$scope.$on('$destroy', unregisterTags);

      const unregisterHashtags = this.$rootScope.$on('hashtagsChanged', async () => {
        this.hashtags = await this.forumservice.getConversationHashtagList(this.$stateParams.activityGuid);
      });
      this.$scope.$on('$destroy', unregisterHashtags);
    }

    this.$q.all(filterListPromises).then((result) => {
      this.segmentations = segmentationsIndex !== null ? result[segmentationsIndex] : null;
      this.members = membersIndex !== null ? result[membersIndex] : null;
      this.tags = tagsIndex !== null ? result[tagsIndex] : null;
      this.hashtags = hashtagsIndex !== null ? result[hashtagsIndex] : null;
      this.themes = themesIndex !== null ? result[themesIndex] : null;
      this.probeQuestions = probeQuestionsIndex !== null ? result[probeQuestionsIndex] : null;
    });

    this.$scope.$watchGroup(['vm.filterDataCounters', 'vm.members', 'vm.tags', 'vm.segmentations', 'vm.probeQuestions',
      'vm.hashtags', 'vm.moderationStatusOptions', 'vm.annotationsOptions', 'vm.stimuliOptions'], () => {
      if (this.filterDataCounters) {
        this.setMembersDropdownOptionsCount();
        this.setSegmentationsDropdownOptionsCount();
        this.setStimuliDropdownOptionsCount();
        this.setTagsDropdownOptionsCount();
        this.setHashtagsDropdownOptionsCount();
        this.setAnnotationsDropdownOptionsCount();
        this.setRatingsDropdownOptionsCount();
        this.setModerationStatusesDropdownOptionsCount();
        this.setProbeQuestionsDropdownOptionsCount();
      }
    });

    this.$scope.$watchGroup(['vm.filterDataCounters', 'vm.themes'], () => {
      if (this.filterDataCounters && this.themes) {
        this.themes.forEach((theme) => {
          const count = this.filterDataCounters.Themes[theme.Guid] ? this.filterDataCounters.Themes[theme.Guid] : 0;
          theme.DisplayLabel = `${theme.Name} (${count})`;
        });

        this.calculateSelectedThemesChips();
      }
    });
  }

  removeKeyword() {
    this.filterData.Keyword = '';
  }

  setModeratorStatusOptions(includeModeratedAccepted: boolean) {
    if (includeModeratedAccepted) {
      this.moderationStatusOptions = this.allModerationStatusOptions;
    } else {
      // Remove the moderatedAccepted option from the Moderator Status Options list
      this.moderationStatusOptions = this.moderationStatusOptions.filter(
        (option) => option.Id !== this.serverConstants.moderationStatusOptionConstants.moderatedAccepted);
    }
  }

  private convertToChip(value) {
    const chip = [];
    if (value) {
      chip[0] = value;
    }
    return chip;
  }

  fillInChips() {
    this.calculateSelectedStimuliOptionsChips();
    this.calculateSelectedAnnotationsOptionsChips();
    this.calculateSelectedRatingsChips();
    this.calculateSelectedModerationStatusOptionsChips();
  }

  filterScroll() {
    if (!this.toggleClicked) {
      // Scroll event to collapse filter and show filter button when the filter has reached the top banner
      const qualFilterDiv = document.querySelector('isc-activity-filter');
      qualFilterDiv.classList.remove('isc-activity-filter-default');

      const filter = document.getElementById('filter-bottom');
      const banner = document.querySelector('.top-banner');
      if (filter && banner) {
        const filterBounding = filter.getBoundingClientRect();
        const bannerBounding = banner.getBoundingClientRect();
        if (filterBounding.top <= bannerBounding.height) {
          this.showFilter = false;
          this.showToggle = true;
        } else {
          this.showFilter = true;
          this.showToggle = false;
        }
      }
    }
    this.toggleClicked = false;
  }

  toggleFilter() {
    const filterDiv = document.querySelector('isc-activity-filter');
    this.showFilter = !this.showFilter;
    filterDiv.className = this.showFilter ? 'isc-activity-filter-default' : '';
    this.showToggle = true;
    this.toggleClicked = !this.toggleClicked;
  }

  preventSubmit($event) {
    // Prevent form behaviour when pressing enter inside the search field
    if ($event.keyCode === 9 && this.filterData.Keyword) {
      $event.preventDefault();
    }
  }

  setMembersDropdownOptionsCount() {
    if (this.members && this.filterDataCounters.Members) {
      this.members.forEach((member) => {
        const count = (this.filterDataCounters.Members && this.filterDataCounters.Members[member.Guid]) ? this.filterDataCounters.Members[member.Guid] : 0;
        member.DisplayLabel = `${member.Username} - ${member.Id} (${count})`;
      });

      this.calculateSelectedMembersChips();
    }
  }

  setTagsDropdownOptionsCount() {
    if (this.tags && this.filterDataCounters.Tags) {
      this.tags.forEach((tag) => {
        const count = (this.filterDataCounters.Tags && this.filterDataCounters.Tags[tag.Guid]) ? this.filterDataCounters.Tags[tag.Guid] : 0;
        tag.DisplayLabel = `${tag.Text} (${count})`;
      });

      this.calculateSelectedTagsChips();
    }
  }

  setRatingsDropdownOptionsCount() {
    if (this.ratings && this.filterDataCounters.Ratings) {
      let counterSum = 0;
      this.ratings.forEach((rating) => {
        const counter = this.filterDataCounters.Ratings[rating.Value] || 0;
        rating.DisplayLabel = `${rating.Text} (${counter})`;
        counterSum += counter;
      });

      const sumForAny = counterSum - (this.filterDataCounters.Ratings[0] || 0); // "Any" rating is everything except no rating
      const anyRating = _.find(this.ratings, (item) => item.Value === this.anyRatingValue);
      anyRating.DisplayLabel = `${anyRating.Text} (${sumForAny})`;
    }
  }

  setProbeQuestionsDropdownOptionsCount() {
    if (this.probeQuestions && this.filterDataCounters.ProbeQuestions) {
      this.probeQuestions.forEach((probequestion) => {
        const count = (this.filterDataCounters.ProbeQuestions && this.filterDataCounters.ProbeQuestions[probequestion.Guid]) ?
          this.filterDataCounters.ProbeQuestions[probequestion.Guid] : 0;
        probequestion.DisplayLabel = `${probequestion.Message} (${count})`;
      });

      this.calculateSelectedProbeQuestionsChips();
    }
  }

  setSegmentationsDropdownOptionsCount() {
    if (this.segmentations && this.filterDataCounters.Segments) {
      this.segmentations.forEach((segment) => {
        segment.SegmentItems.forEach((seg) => {
          const count =
            (this.filterDataCounters.Segments && this.filterDataCounters.Segments[seg.Guid]) ? this.filterDataCounters.Segments[seg.Guid] : 0;
          seg.DisplayLabel = `${seg.SegmentAnswer} (${count})`;
        });
      });

      this.calculateSelectedSegmentsChips();
    }
  }

  setModerationStatusesDropdownOptionsCount() {
    if (this.moderationStatusOptions && this.filterDataCounters.ModerationStatusOptions) {
      this.moderationStatusOptions.forEach((option) => {
        const count = (this.filterDataCounters.ModerationStatusOptions && this.filterDataCounters.ModerationStatusOptions[option.Id])
          ? this.filterDataCounters.ModerationStatusOptions[option.Id]
          : 0;
        option.DisplayLabel = `${option.Text} (${count})`;
      });
    }
  }

  setAnnotationsDropdownOptionsCount() {
    if (this.annotationsOptions && this.filterDataCounters.AnnotationsOptions) {
      this.annotationsOptions.forEach((option) => {
        const count = (this.filterDataCounters.AnnotationsOptions && this.filterDataCounters.AnnotationsOptions[option.Id])
          ? this.filterDataCounters.AnnotationsOptions[option.Id]
          : 0;
        option.DisplayLabel = `${option.Text} (${count})`;
      });
    }
  }

  setStimuliDropdownOptionsCount() {
    if (this.stimuliOptions && this.filterDataCounters.StimuliOptions) {
      this.stimuliOptions.forEach((option) => {
        let count = 0;

        if (option.Id === this.serverConstants.stimuliFilterOptionConstants.anyStimuli) {
          // "Any Stimuli" is selected; consider both "Image" and "Video"
          count = (this.filterDataCounters.StimuliOptions[this.serverConstants.stimuliFilterOptionConstants.image] || 0) +
                  (this.filterDataCounters.StimuliOptions[this.serverConstants.stimuliFilterOptionConstants.video] || 0);
        } else {
          // Calculate count for individual option
          count = this.filterDataCounters.StimuliOptions[option.Id] || 0;
        }

        option.DisplayLabel = `${option.Text} (${count})`;
      });
    }
  }

  setHashtagsDropdownOptionsCount() {
    if (this.hashtags && this.filterDataCounters.Hashtags) {
      this.hashtags.forEach((hashtag) => {
        const count = (this.filterDataCounters?.Hashtags && this.filterDataCounters.Hashtags[hashtag.Guid])
          ? this.filterDataCounters.Hashtags[hashtag.Guid]
          : 0;
        hashtag.DisplayLabel = `${hashtag.Text} (${count})`;
      });

      this.calculateSelectedHashtagsChips();
    }
  }

  calculateSelectedRatingsChips() {
    this.selectedRatingsChips = _.filter<IRating>(this.ratings || [],
      (rating) =>
        _.some(this.filterData.SelectedRatings || [],
          (item) => item.toString() === rating.Value.toString()));
  }

  calculateSelectedThemesChips() {
    this.selectedThemesChips = _.filter<IThemeItem>(this.themes || [],
      (theme) =>
        _.some(this.filterData.SelectedThemes || [],
          (item) => item === theme.Guid));
  }

  calculateSelectedMembersChips() {
    this.selectedMembersChips = _.filter<IMemberItem>(this.members || [],
      (member) =>
        _.some(this.filterData.SelectedMembers || [],
          (item) => item === member.Guid));
  }

  calculateSelectedSegmentsChips() {
    this.selectedSegmentsChips = [];
    if (!this.filterData.SelectedSegments || !this.filterData.SelectedSegments.length) {
      return;
    }
    const segementsObj = this.getSegmentsObj();
    const segmentsJoinString = this.getSegmentsJoinString();
    _.forEach(this.filterData.SelectedSegments, (item) => {
      const existingSegment = _.filter(this.selectedSegmentsChips, (chip) => chip.SegmentationGuid === segementsObj[item].SegmentationGuid);
      if (!existingSegment.length) {
        this.selectedSegmentsChips.push({
          SegmentationGuid: segementsObj[item].SegmentationGuid,
          Label: segementsObj[item].SegmentAnswer,
          Segments: [item],
        });

        return;
      }

      existingSegment[0].Label = [existingSegment[0].Label, segementsObj[item].SegmentAnswer].join(` ${segmentsJoinString} `);
      existingSegment[0].Segments.push(item);
    });
  }

  calculateSelectedTagsChips() {
    this.selectedTagsChips = _.filter<ITagItem>(this.tags || [],
      (tag) =>
        _.some(this.filterData.TagsSelected || [],
          (item) => item === tag.Guid));

    if (this.tags !== undefined && !this.selectedTagsChips?.length && this.filterData.TagsSelected?.length) {
      this.selectedTagsChips = [this.selectAllTagsObj];
    }
  }

  calculateSelectedHashtagsChips() {
    this.selectedHashtagsChips = _.filter<IHashtagItem>(this.hashtags || [],
      (hashtag) =>
        _.some(this.filterData.HashtagsSelected || [],
          (item) => item === hashtag.Guid));

    if (this.hashtags !== undefined && !this.selectedHashtagsChips?.length && this.filterData.HashtagsSelected?.length) {
      this.selectedHashtagsChips = [this.selectAllHashtagsObj];
    }
  }

  calculateSelectedStimuliOptionsChips() {
    this.selectedStimuliOptionsChips = _.filter<IStaticFilterOption>(this.stimuliOptions || [],
      (option) =>
        _.some(this.filterData.SelectedStimuliOptions || [],
          (item) => item.toString() === option.Id.toString()));
  }

  calculateSelectedAnnotationsOptionsChips() {
    this.selectedAnnotationsOptionsChips = _.filter<IStaticFilterOption>(this.annotationsOptions || [],
      (option) =>
        _.some(this.filterData.SelectedAnnotationsOptions || [],
          (item) => item.toString() === option.Id.toString()));
  }

  calculateSelectedModerationStatusOptionsChips() {
    this.selectedModerationStatusOptionsChips = _.filter<IStaticFilterOption>(this.moderationStatusOptions || [],
      (option) =>
        _.some(this.filterData.SelectedModerationStatusOptions || [],
          (item) => item.toString() === option.Id.toString()));
  }

  calculateSelectedProbeQuestionsChips() {
    this.selectedProbeQuestionsChips = _.filter<IProbeQuestionItem>(this.probeQuestions || [],
      (question) =>
        _.some(this.filterData.SelectedProbeQuestions || [],
          (item) => item === question.Guid));
  }

  removeFromChipModel(model, value) {
    // Removes a chip when pressing the X icon on it depending of the chip type
    switch (model) {
      case 'theme': this.onRemoveSelectedTheme(value); break;
      case 'member': this.onRemoveSelectedMember(value); break;
      case 'segment': this.onRemoveSelectedSegment(value); break;
      case 'tag': this.onRemoveSelectedTag(value); break;
      case 'hashtag': this.onRemoveSelectedHashtag(value); break;
      case 'probequestion': this.onRemoveProbequestionTag(value); break;
      case 'rating': this.onRemoveSelectedRatings(value); break;
      case 'moderationStatusOption': this.onRemoveSelectedModerationStatusOption(value); break;
      case 'annotationsOption': this.onRemoveSelectedAnnotationsOption(value); break;
      case 'stimuliOption': this.onRemoveSelectedStimuliOption(value); break;
      default: break;
    }
  }

  resetFilters() {
    // Clears all chips and filters
    this.filterData.Keyword = null;
    this.filterData.SelectedThemes = [];
    this.filterData.SelectedMembers = [];
    this.filterData.SelectedSegments = [];
    this.filterData.SelectedProbeQuestions = [];
    this.filterData.SelectedRatings = [];
    this.filterData.SelectedStimuliOptions = [];
    this.filterData.SelectedAnnotationsOptions = [];
    this.filterData.SelectedModerationStatusOptions = [];
    this.filterData.SelectedSegmentsOption = this.serverConstants.selectedSegmentsOptionConstants.any;
    this.filterData.TagsSelected = [];
    this.filterData.HashtagsSelected = [];

    this.chips.Filters = [];
    this.selectedThemesChips = [];
    this.selectedProbeQuestionsChips = [];
    this.selectedMembersChips = [];
    this.selectedSegmentsChips = [];
    this.selectedTagsChips = [];
    this.selectedHashtagsChips = [];
    this.selectedRatingsChips = [];
    this.selectedModerationStatusOptionsChips = [];
    this.selectedAnnotationsOptionsChips = [];
    this.selectedStimuliOptionsChips = [];
  }

  themesSelectedChange(value) {
    this.filterData.SelectedThemes = this.filterData.SelectedThemes || [];
    this.filterData.SelectedThemes = value;
    this.calculateSelectedThemesChips();
  }

  membersSelectedChange(value) {
    this.filterData.SelectedMembers = value;
    this.calculateSelectedMembersChips();
  }

  segmentsSelectedChange(segments) {
    /* when printing this.filterData the object looks alright, however, when printing this.filterData.SelectedSegmentsOption
    * right on the next line it returns the old value. It is some weird angular issue that happens because angular
    * did not update the bindings yet
    * */
    setTimeout(() => {
      this.filterData.SelectedSegments = segments;
      this.calculateSelectedSegmentsChips();
    }, 0);

  }

  tagsSelectedChange(selectedOptions) {
    if (_.some(selectedOptions, (option) => option === this.selectAllTagsObj.Text)) {
      selectedOptions = _.without(selectedOptions, this.serverConstants.activityFilterConstants.noTagOptionGuid);
    }
    this.filterData.TagsSelected = selectedOptions;
    this.calculateSelectedTagsChips();
  }

  hashtagsSelectedChange(selectedOptions) {
    if (_.some(selectedOptions, (option) => option === this.selectAllHashtagsObj.Text)) {
      selectedOptions = _.without(selectedOptions, this.serverConstants.activityFilterConstants.noHashtagOptionGuid);
    }
    this.filterData.HashtagsSelected = selectedOptions;
    this.calculateSelectedHashtagsChips();
  }

  ratingsSelectedChange(ratings) {
    ratings = this.adaptAnyRating(ratings);
    this.filterData.SelectedRatings = ratings;
    this.calculateSelectedRatingsChips();
  }

  adaptAnyRating(ratings) {
    if (_.some(ratings, (rating) => rating === this.anyRatingValue)) { // If any is selected simply replace it with the other values
      ratings = _.without(ratings, this.anyRatingValue);
      ratings = _.union(ratings, [1, 2, 3, 4, 5]);
    }
    return ratings;
  }

  stimuliOptionsSelectedChange(selectedOptions: number[]) {
    if (_.some(selectedOptions, (option) => option === this.serverConstants.stimuliFilterOptionConstants.anyStimuli)) {
      selectedOptions = [this.serverConstants.stimuliFilterOptionConstants.image,
        this.serverConstants.stimuliFilterOptionConstants.video,
        this.serverConstants.stimuliFilterOptionConstants.anyStimuli];
    }
    this.filterData.SelectedStimuliOptions = selectedOptions;
    this.calculateSelectedStimuliOptionsChips();
  }

  annotationsOptionsSelectedChange(selectedOptions: number[]) {
    if (_.some(selectedOptions, (option) => option === this.serverConstants.annotationFilterOptionConstants.anyAnnotation)) {
      selectedOptions = [this.serverConstants.annotationFilterOptionConstants.notes, this.serverConstants.annotationFilterOptionConstants.highlights];
    }
    this.filterData.SelectedAnnotationsOptions = selectedOptions;
    this.calculateSelectedAnnotationsOptionsChips();
  }

  moderationStatusOptionsSelectedChange(selectedOptions: number[]) {
    if (_.some(selectedOptions, (option) => option === this.serverConstants.moderationStatusOptionConstants.anyModerationStatus)) {
      selectedOptions = this.moderationStatusOptions.map((option) => option.Id);
      selectedOptions = _.without(selectedOptions,
        this.serverConstants.moderationStatusOptionConstants.anyModerationStatus,
        this.serverConstants.moderationStatusOptionConstants.unmoderated);
    }

    this.filterData.SelectedModerationStatusOptions = selectedOptions;
    this.calculateSelectedModerationStatusOptionsChips();
  }

  probeQuestionsSelectedChange(value) {
    this.filterData.SelectedProbeQuestions = value;
    this.calculateSelectedProbeQuestionsChips();
  }

  onRemoveSelectedTheme(chip: IThemeItem) {
    _.remove(this.filterData.SelectedThemes, (item) => item === chip.Guid);
    this.calculateSelectedThemesChips();
  }

  onRemoveSelectedMember(chip: IMemberItem) {
    _.remove(this.filterData.SelectedMembers, (item) => item === chip.Guid);
    this.calculateSelectedMembersChips();
  }

  onRemoveSelectedSegment(chip) {
    _.remove(this.filterData.SelectedSegments, (item) => _.some(chip.Segments, (segment) => segment === item));
    this.calculateSelectedSegmentsChips();
  }

  onRemoveSelectedTag(chip) {
    if (chip.Text === this.selectAllTagsObj.Text && chip.Guid === this.selectAllTagsObj.Guid) {
      this.filterData.TagsSelected = [];
    } else {
      _.remove(this.filterData.TagsSelected, (item) => item === chip.Guid);
    }
    this.calculateSelectedTagsChips();
  }

  onRemoveSelectedHashtag(chip) {
    if (chip.Text === this.selectAllHashtagsObj.Text && chip.Guid === this.selectAllHashtagsObj.Guid) {
      this.filterData.HashtagsSelected = [];
    } else {
      _.remove(this.filterData.HashtagsSelected, (item) => item === chip.Guid);
    }
    this.calculateSelectedHashtagsChips();
  }

  onRemoveProbequestionTag(chip) {
    _.remove(this.filterData.SelectedProbeQuestions, (item) => item === chip.Guid);
    this.calculateSelectedProbeQuestionsChips();
  }

  onRemoveSelectedRatings(chip) {
    _.remove(this.filterData.SelectedRatings, (item) => item === chip.Value);
    this.calculateSelectedRatingsChips();
  }

  onRemoveSelectedModerationStatusOption(chip) {
    _.remove(this.filterData.SelectedModerationStatusOptions, (item) => item.toString() === chip.Id.toString());
    this.calculateSelectedModerationStatusOptionsChips();
  }

  onRemoveSelectedAnnotationsOption(chip) {
    _.remove(this.filterData.SelectedAnnotationsOptions, (item) => item.toString() === chip.Id.toString());
    this.calculateSelectedAnnotationsOptionsChips();
  }

  onRemoveSelectedStimuliOption(chip) {
    _.remove(this.filterData.SelectedStimuliOptions, (item) => item.toString() === chip.Id.toString());
    this.calculateSelectedStimuliOptionsChips();
  }

  themesSelectedText() {
    let displayText = 'Select themes';
    if (this.selectedThemesChips.length > 1) {
      displayText = `${this.selectedThemesChips[0].DisplayLabel} (+${this.selectedThemesChips.length - 1})`;
    }
    if (this.selectedThemesChips.length === 1) {
      displayText = this.selectedThemesChips[0].DisplayLabel;
    }
    return displayText;
  }

  membersSelectedText() {
    let displayText = 'Select members';
    if (this.selectedMembersChips.length > 1) {
      displayText = `${this.selectedMembersChips[0].DisplayLabel} (+${this.selectedMembersChips.length - 1})`;
    }
    if (this.selectedMembersChips.length === 1) {
      displayText = this.selectedMembersChips[0].DisplayLabel;
    }
    return displayText;
  }

  ratingsText() {
    let displayText = 'Select ratings';
    if (this.selectedRatingsChips.length > 1) {
      displayText = `${this.selectedRatingsChips[0].DisplayLabel} (+${this.selectedRatingsChips.length - 1})`;
    }
    if (this.selectedRatingsChips.length === 1) {
      displayText = this.selectedRatingsChips[0].DisplayLabel;
    }
    return displayText;
  }

  tagsSelectedText() {
    let displayText = 'Select tags';
    if (this.selectedTagsChips.length > 1) {
      displayText = `${this.selectedTagsChips[0].DisplayLabel} (+${this.selectedTagsChips.length - 1})`;
    }
    if (this.selectedTagsChips.length === 1) {
      displayText = this.selectedTagsChips[0].DisplayLabel;
    }
    return displayText;
  }

  hashtagsSelectedText() {
    let displayText = 'Select hashtags';
    if (this.selectedHashtagsChips.length > 1) {
      displayText = `${this.selectedHashtagsChips[0].DisplayLabel} (+${this.selectedHashtagsChips.length - 1})`;
    }
    if (this.selectedHashtagsChips.length === 1) {
      displayText = this.selectedHashtagsChips[0].DisplayLabel;
    }
    return displayText;
  }

  selectedModerationStatusOptionsText() {
    let displayText = 'Select moderation status';
    if (this.selectedModerationStatusOptionsChips.length > 1) {
      displayText = `${this.selectedModerationStatusOptionsChips[0].DisplayLabel} (+${this.selectedModerationStatusOptionsChips.length - 1})`;
    }
    if (this.selectedModerationStatusOptionsChips.length === 1) {
      displayText = this.selectedModerationStatusOptionsChips[0].DisplayLabel;
    }
    return displayText;
  }

  selectedAnnotationsOptionsText() {
    let displayText = 'Select notes or highlights';
    if (this.selectedAnnotationsOptionsChips.length > 1) {
      displayText = `${this.selectedAnnotationsOptionsChips[0].DisplayLabel} (+${this.selectedAnnotationsOptionsChips.length - 1})`;
    }
    if (this.selectedAnnotationsOptionsChips.length === 1) {
      displayText = this.selectedAnnotationsOptionsChips[0].DisplayLabel;
    }
    return displayText;
  }

  selectedStimuliOptionsText() {
    let displayText = 'Select images or videos';
    if (this.selectedStimuliOptionsChips.length > 1) {
      displayText = `${this.selectedStimuliOptionsChips[0].DisplayLabel} (+${this.selectedStimuliOptionsChips.length - 1})`;
    }
    if (this.selectedStimuliOptionsChips.length === 1) {
      displayText = this.selectedStimuliOptionsChips[0].DisplayLabel;
    }
    return displayText;
  }

  probeQuestionsSelectedText() {
    let displayText = 'Select questions';
    if (this.selectedProbeQuestionsChips.length > 1) {
      displayText = `${this.selectedProbeQuestionsChips[0].DisplayLabel} (+${this.selectedProbeQuestionsChips.length - 1})`;
    }
    if (this.selectedProbeQuestionsChips.length === 1) {
      displayText = this.selectedProbeQuestionsChips[0].DisplayLabel;
    }
    return displayText;
  }

  private getSegmentsObj() {
    if (!this.segmentsObj) {
      this.segmentsObj = {};
      _.forEach(this.segmentations, (segmentation) => {
        _.forEach<any[]>(segmentation.SegmentItems, (segment: any) => {
          this.segmentsObj[segment.Guid] = { SegmentationGuid: segmentation.Guid, SegmentAnswer: segment.SegmentAnswer };
        });
      });
    }

    return this.segmentsObj;
  }

  private getSegmentsJoinString() {
    if (this.filterData.SelectedSegmentsOption === this.serverConstants.selectedSegmentsOptionConstants.any) {
      return 'OR';
    }

    if (this.filterData.SelectedSegmentsOption === this.serverConstants.selectedSegmentsOptionConstants.all) {
      return 'AND';
    }

    return ' ';
  }

  isDisabled() {
    if (this.filterData) {
      if (this.chips.Filters.length > 0 ||
        this.selectedThemesChips.length > 0 ||
        this.selectedMembersChips.length > 0 ||
        this.selectedSegmentsChips.length > 0 ||
        this.selectedStimuliOptionsChips.length > 0 ||
        this.selectedTagsChips.length > 0 ||
        this.selectedHashtagsChips.length > 0 ||
        this.selectedAnnotationsOptionsChips.length > 0 ||
        this.selectedProbeQuestionsChips.length > 0 ||
        this.selectedRatingsChips.length > 0 ||
        this.selectedModerationStatusOptionsChips.length > 0 ||
        this.filterData.Keyword) {
        return false;
      }
    }
    return true;
  }

  isActivityTypeWithProbingQuestions(): boolean {
    const validActivityTypes = [
      this.serverConstants.squareActivityTypeConstants.privateQualitativeResearch,
      this.serverConstants.squareActivityTypeConstants.privateScoutResearch,
      this.serverConstants.squareActivityTypeConstants.publicQualitativeResearch,
      this.serverConstants.squareActivityTypeConstants.publicScoutResearch,
      this.serverConstants.squareActivityTypeConstants.discussion20Research,
      this.serverConstants.squareActivityTypeConstants.observation,
    ];

    return validActivityTypes.includes(this.filterData.ActivityType);
  }

  get showProbeQuestionFilter() {
    return this.probeQuestions && this.probeQuestions.length > 0;
  }
}
