'use strict';

import { ParticipantService } from '../../../../core/dataservices/participant.service';
import { ServerConstants } from '../../../../core/serverconstants';

export class MailtemplateOverrulerDialogController {
  static $inject = ['$mdDialog', 'participantservice', 'logger', 'member', 'serverConstants'];

  isSendingEmail: boolean = false;
  placeholders = {};
  overrulelabels = ['ActivationSuccess'];
  isActivationEmailEdited: boolean;
  subjectLabelName;

  sendingLabel = this.member.enableResendActivationEmail ? 'Resend' : 'Send';

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private participantservice: ParticipantService,
    private logger: Logger,
    private member,
    private serverConstants: ServerConstants,
  ) {
  }

  async $onInit() {
    this.subjectLabelName = this.member.Detail.Status === this.serverConstants.registrationStatusConstants.singleOptInNoEmail ?
      'LabelCreateParticipantSubject' : 'LabelReminderActivationSubject';
    this.participantservice.getSquareParticipantCustomEmail([this.member.Detail.Guid]).then((ce) => {
      const customEmail = JSON.parse(ce);
      for (const label in customEmail) {
        this.placeholders[label] = customEmail[label];
      }
    });
  }

  sendActivationEmail() {
    this.isSendingEmail = true;
    this.participantservice.sendActivationEmail({
      SquareParticipantGuid: this.member.Detail.Guid,
      OverruledLabels: this.placeholders,
      isActivationEmailEdited: this.isActivationEmailEdited,
      isTemplateShown: true,
    }).then(() => {
    }).catch((error) => {
      this.logger.error(`<p>${error.data.Reason}</p><p>${error.data.Message}</p>`);
    }).finally(() => {
      this.isSendingEmail = false;
      this.$mdDialog.hide();
    });
  }

  cancel() {
    this.$mdDialog.cancel();
  }
}
