'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper, __env: Environment) => {
  routerHelper.configureStates([
    {
      state: 'root.clientselection',
      config: {
        url: __env.showStartPage ? 'clientselection' : '',
        title: __env.showStartPage ? 'Client Selection' : 'Home',
        views: {
          'sidenav': {
            component: 'homeSidenavComponent',
          },
          'sidenav-footer': {
            component: 'homeSidenavFooterComponent',
          },
          '': {
            component: 'clientSelectionComponent',
          },
        },
        disallowRoles: ['ProfessionalAdmin', 'ClientAdmin', 'ClientEditor', 'Observer'],
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', '__env'];

export default routeConfig;

