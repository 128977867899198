'use strict';

import { ProbeQuestionController } from './isc-probe-question.controller';
require('./isc-probe-question.scss');

const iscProbeQuestionTemplate = require('./isc-probe-question.html');

export const IscProbeQuestionComponent: ng.IComponentOptions = {
  controller: ProbeQuestionController,
  templateUrl: iscProbeQuestionTemplate,
  controllerAs: 'vm',
  bindings: {
    question: '=',
    allowedTypes: '<?',
    activityType: '<?',
    isFirstQuestion: '<',
    onDelete: '&',
    onAdd: '&',
    isEditable: '<',
    isDisabled: '<',
    isAdditionalProbeQuestion: '<?',
    autosave: '&',
    maxQuestionsMessage: '@',
    ariaLabel: '@',
    name: '@',
    onlyOptional: '<',
    isDiscussionNew: '<?',
  },
};
