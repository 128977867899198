'use strict';

import './activityData.module';
import './activityData.route';
import './activityData.component';
import './activityDataConversations/activityDataConversations.component';
import './activityDataPost/activityDataPost.component';
import './activityDataPost/fiveStarRating/fiveStarRating.component';
import './activityDataPost/activityDataPostNotes/activityDataPostNotes.component';
import './activityDataPost/activityDataPostTags/activityDataPostTags.component';

import { SnippetHighlighterService } from './snippet-highlighter/snippet-highlighter.service';

angular.module('insitesApp.activityData')
  .service('snippetHighlighterService', SnippetHighlighterService);
