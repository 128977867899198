'use strict';

import { AuthService } from './../../../core/dataservices/auth.service';
import { FeatureService } from './../../../core/dataservices/feature.service';
import { ServerConstants } from './../../../core/serverconstants';
import { IUpdateFeatureSettingsRequest } from './../../../core/contracts/feature.contracts';
import { RewardService } from './../../../core/dataservices/reward.service';
import { IncentiveService } from '../../../core/dataservices/incentive.service';
require('./_savingUpRewards.scss');

export class SavingUpRewardsController {

  static $inject = ['featureservice', 'incentiveservice', 'serverConstants', 'rewardservice',
    'logger', '$rootScope', 'authService', '$mdDialog', '$scope'];

  constructor(
    private featureService: FeatureService,
    private incentiveservice: IncentiveService,
    private serverConstants: ServerConstants,
    private rewardService: RewardService,
    private logger: Logger,
    private $rootScope: ng.IRootScopeService,
    private authService: AuthService,
    private $mdDialog: ng.material.IDialogService,
    private $scope: ng.IScope,
  ) {
  }

  form;
  isFullDevUser: boolean = false;
  isSaving: boolean = false;
  savingUpRewardsEnabled: boolean;
  isAutomaticHandlingEnabled: boolean;
  isPointsExpirationEnabled: boolean;
  minUnitsRedeem: number;
  raffleType = this.serverConstants.raffleTypeConstants.savingUp;
  isRewardsValid = true;
  savingUpModel = {
    rewards: [],
    calculatedRewards: [],
  };
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';

  saveCallback = async () => await this.createUpdateSavingUpRewardsSettings();

  async $onInit() {
    this.isFullDevUser = this.authService.isFullDev();
    await this.init();
  }

  private async init() {
    await this.getRewardsSavingUp();
    const featureSettings = await this.featureService.getFeatureSettings(this.serverConstants.featureConstants.savingUpRewards);
    this.savingUpRewardsEnabled = featureSettings.isEnabled;
    const minUnitsRedeemSetting = featureSettings.settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsMinUnitsRedeem];
    this.minUnitsRedeem = minUnitsRedeemSetting ? parseInt(minUnitsRedeemSetting, 10) : 50;
    if (this.minUnitsRedeem === 0) {
      this.minUnitsRedeem = null;
    }
    this.$scope.$watch('vm.minUnitsRedeem', () => {
      this.savingUpModel.calculatedRewards.forEach((r, i) => {
        if(this.minUnitsRedeem > 0 && this.minUnitsRedeem != null){
          r.Units = this.minUnitsRedeem;
          r.Value = ((this.savingUpModel.rewards[i].Value * r.Units)).toFixed(2);
        }else{
          r.Units = 0;
          r.Value = 0;
        }
      });
    });

    const automaticHandlingSetting = featureSettings.settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsAutomaticHandlingEnabled];
    this.isAutomaticHandlingEnabled = automaticHandlingSetting !== undefined ? automaticHandlingSetting.toLowerCase() === 'true' : false;
    const pointsExpirationSetting = featureSettings.settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsPointsExpirationEnabled];
    this.isPointsExpirationEnabled = pointsExpirationSetting !== undefined ? pointsExpirationSetting.toLowerCase() === 'true' : false;
  }

  private async getRewardsSavingUp(): Promise<void> {
    const data = await this.incentiveservice.getRewardsSavingUp();
    if (data) {
      this.savingUpModel.rewards = data.Rewards.map((reward) =>
        ({ ...reward, Value: reward.Value === 0 ? null : reward.Value }));
      this.savingUpModel.calculatedRewards = data.Rewards.map((reward) =>
        ({ ...reward, Value: reward.Value === 0 ? null : reward.Value }));
    }
  }

  onSavingUpToggled() {
    this.isAutomaticHandlingEnabled = !this.savingUpRewardsEnabled;
    this.isPointsExpirationEnabled = !this.savingUpRewardsEnabled;
  }

  async createUpdateSavingUpRewardsSettings() {
    this.isSaving = true;
    const zero = 0;
    const settings: {[key:string]: string;}={};
    settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsUnitLabel] = 'Points';
    settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsMinUnitsRedeem] =
      this.minUnitsRedeem ? this.minUnitsRedeem.toString() : zero.toString();
    settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsAutomaticHandlingEnabled] = this.isAutomaticHandlingEnabled.toString();
    settings[this.serverConstants.featureSettingKeysConstants.savingUpRewardsPointsExpirationEnabled] = this.isPointsExpirationEnabled.toString();

    const featureSettings: IUpdateFeatureSettingsRequest = {
      Settings: settings,
      Enabled: this.savingUpRewardsEnabled,
      Feature: this.serverConstants.featureConstants.savingUpRewards,
    };

    try {
      await this.rewardService.createUpdateSavingUpRewardsSettings(featureSettings);
      // Invalidate cache & reload the data because it can be different after updating the settings
      this.featureService.invalidateCache();
      await this.init();
      this.$rootScope.$emit('savingUpEnabledChanged', this.savingUpRewardsEnabled);
      this.form.$setPristine();
      this.$rootScope.$emit('sidenavChanged');
      this.logger.success('Settings successfully updated');
    } catch (error) {
      this.$mdDialog.show(
        this.$mdDialog.iscAlert()
          .title('Settings for Saving up Rewards couldn\'t be saved')
          .text(error.data.Message)
          .ok('Got it!'));
    } finally {
      this.isSaving = false;
    }
  }
}
