'use strict';

import { RouterHelper } from '../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.rewardHandling',
      config: {
        url: 'rewardhandling?page?limit?order?',
        title: 'Reward Handling',
        views: {
          'sidenav': {
            component: 'homeSidenavComponent',
          },
          '': {
            component: 'rewardHandling',
          },
        },
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

