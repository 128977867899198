'use strict';

export class FiveStarRatingController {
  static $inject = [];

  constructor() {
  }

  /** Bindings */
  rating: number;
  id: string;
  disabled: boolean;
  setRating: any;
  /** /Bindings */

  $onInit() {
  }

  onSetRating(rating: number) {
    this.rating = rating; // This will need to be split up into 2 variables if we want for example your rating and average rating
    this.setRating({ rating });
  }
}
