'use strict';

import { ServerConstants } from './serverconstants';
import { InsitesUserDetailService } from './dataservices/insitesUserDetail.service';
const RequestInterceptor = (
  logger: Logger,
  $q: ng.IQService,
  $templateCache: ng.ITemplateCacheService,
  $injector,
  $rootScope: ng.IRootScopeService,
  __env: Environment,
  serverConstants: ServerConstants,
) => {
  const basePath = angular.element(document.getElementsByTagName('base')).attr('href').replace(/\/$/, '');

  return {
    request: (request) => {
      if (request.method === 'GET' && $templateCache.get(request.url) !== undefined) {
        return request;
      }

      if (request.url.match && !request.url.match(/^http|serviceapi/gi)) {
        request.url = basePath + request.url;
      }

      return request;
    },
    responseError: async (response) => {
      // if the url is not from this environment, do not interfere with its response error
      if (notCurrentEnvironmentUrl(serverConstants, response.config?.method, response.config?.url, __env)) {
        return $q.reject(response);
      }
      // Do something on success
      if (response.status === 500) {
        if (response.data.Errors || response.data.Errors) {
          const errors = response.data.Errors;
          for (const error of errors) {
            logger.error('Something went wrong server side, check the console for the Stacktrace ------- ', error);
          }
        } else if (response.data.Message) {
          logger.error(response.data.Message);
        } else {
          logger.error('Something went wrong');
        }
      } else if (response.status === 409) {
        // The client version is different than the frontend version
        const buildversion = response.data.BuildVersion;
        let location = document.location.href;
        location = location.replace(__env.interfaceUrl, '/');
        const regex = new RegExp(/^(\/\d{8}_\d{3}\/)/g);
        if (regex.test(location)) {
          // Remove buildversion from URL -> go to latest version
          location = location.replace(/^(\/\d{8}_\d{3}\/)/g, () => '');
        } else {
          // Add buildversion to URL -> go to that specific version
          location = buildversion + location;
        }
        window.location.href = __env.interfaceUrl + location;
      } else if (response.status === 503) {
        //  The client is in migration, show that to the user
        const $state: ng.ui.IStateService = $injector.get('$state');
        if ($state.current.name !== 'root.clientmaintenance') {
          const $stateParams: ng.ui.IStateParamsService = $injector.get('$stateParams');
          $state.go('root.clientmaintenance', { clientGuid: response.config.params.ClientGuid || $stateParams.clientGuid, goto: document.location.href });
        }
      } else if (response.status === 401) {
        let isHandled = false;
        if (response.data.Message === serverConstants.squareConstants.userNoAccessMessage) {
          const insitesUserDetailService: InsitesUserDetailService = $injector.get('insitesUserDetailService');
          isHandled = await insitesUserDetailService.showLimitedAccessForInsitesUser(true);
        }
        if (!isHandled) {
          $rootScope.$emit('notAuthorized');
        }
      }
      return $q.reject(response);
    },
  } as ng.IHttpInterceptor;
};

RequestInterceptor.$inject = ['logger', '$q', '$templateCache', '$injector', '$rootScope', '__env', 'serverConstants'];

angular
  .module('insitesApp.core')
  .factory('httpRequestInterceptor', RequestInterceptor);


const notCurrentEnvironmentUrl = (serverConstants: ServerConstants, method: string, url: string, env: Environment) => {
  let handled = false;

  // if our backend is configured to explicitly intercept other environments
  // then we are not checking anything here
  if (serverConstants.squareConstants.interceptHttpErrorsFromOtherEnvironments) {
    return handled;
  }

  // it is enough to compare with our member, command and query base urls
  if (!url.startsWith(env.queryApiUrl)
    && !url.startsWith(env.memberApiUrl)
    && !url.startsWith(env.commandApiUrl)) {
    // the url does not belong to our environment
    handled = true;
  }
  return handled;
};
