'use strict';

import { ActivityWizardType } from './activityWizardType.controller';

const template = require('./activityWizardType.html');
require('./activityWizardType.scss');

export const ActivityWizardTypeComponent: ng.IComponentOptions = {
  controller: ActivityWizardType,
  templateUrl: template,
  controllerAs: 'vm',
};
