'use strict';

import { GeofencingService } from './../../../../core/dataservices/geofencing.service';
import { JobService } from './../../../../core/jobs/job.service';
import { CurrentUserService } from '../../../../core/dataservices/currentUser.service';

require('./_importLocations.scss');

export class ImportLocationsDialogController {
  static $inject = [
    '$mdDialog',
    'geofencingservice',
    'downloadservice',
    'jobservice',
    'currentUserService',
  ];

  isSaving = false;
  importLocationsForm;
  invalidType = false;
  errors = {};
  errorkeys = [];
  warnings = {};
  warningkeys = [];
  totalImportableLocations: number = -1;
  fileValidationSucceed: boolean = false;
  file;
  blobName;
  role;
  isDownloadTemplateDisabled = false;

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private geofencingservice: GeofencingService,
    private downloadservice,
    private jobservice: JobService,
    private currentUserService: CurrentUserService,
  ) {

  }

  cancel() {
    this.$mdDialog.cancel();
  }

  async validateExcel(file, invalidFile) {
    this.resetUpload();
    this.file = file;
    if (invalidFile) {
      this.invalidType = true;
    }
    this.blobName = '';
    this.fileValidationSucceed = false;
    if (this.file) {
      const validationInfo = await this.geofencingservice.validateExcelFile(this.file);
      this.errors = validationInfo.data.Errors;
      this.errorkeys = Object.keys(this.errors);
      this.warnings = validationInfo.data.Warnings;
      this.warningkeys = Object.keys(this.warnings);
      this.totalImportableLocations = validationInfo.data.Total;
      this.blobName = validationInfo.data.Name;
      if (Object.keys(this.errors).length === 0) {
        this.fileValidationSucceed = true;
      }
    }
  }

  resetUpload() {
    this.fileValidationSucceed = false;
    this.totalImportableLocations = -1;
    this.invalidType = false;
    this.errors = {};
  }

  async importLocations() {
    this.isSaving = true;
    this.$mdDialog.hide();

    const role = this.currentUserService.userProfile.Role;
    const jobId = this.geofencingservice.importGeofencingLocations(this.blobName, role);
    await this.jobservice.showJobProgressDialog(jobId, 'Importing locations', true, true);
  }

  async downloadImportTemplate() {
    this.isDownloadTemplateDisabled = true;
    const response = await this.geofencingservice.downloadGeofencingLocationsImportTemplate();
    this.downloadservice.downloadResponse(response);
    this.isDownloadTemplateDisabled = false;
  }

  isSaveDisabled() {
    return !this.fileValidationSucceed || !this.importLocationsForm.$valid || this.totalImportableLocations === 0;
  }
}
