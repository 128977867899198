'use strict';

// Add CookieYes script to the page
const cookieYesSiteId = (window as any).__env.cookieYesSiteId;
if (cookieYesSiteId !== '') {
  const scriptTag = document.createElement('script');
  scriptTag.id = 'cookieyes';
  scriptTag.defer = true;
  scriptTag.type = 'text/javascript';
  scriptTag.src = `https://cdn-cookieyes.com/client_data/${cookieYesSiteId}/script.js`;
  document.body.appendChild(scriptTag);
}
