'use strict';

import { AuthService } from './../../core/dataservices/auth.service';
import { SpinnerService } from './../../core/services/spinner.service';
import { IPinnedItem } from './../../core/services/pinned-items.contracts';
import { PinnedItemsService } from '../../core/services/pinned-items.service';

export class SidenavMyPinsController {
  pinTypesUrls: string[] = [
    'root.clientprograms',
    'root.programsquares',
    'root.square.activities.all',
    'root.square.activity',
  ];

  pinnedItems: any[];
  toggleInactive: boolean = false;
  useAllEnv: boolean = false;

  static $inject = ['spinnerservice', 'pinnedItemsService', '$state', '__env', 'authService'];
  constructor(
    private spinnerservice: SpinnerService,
    private pinnedItemsService: PinnedItemsService,
    private $state: ng.ui.IStateService,
    private __env: Environment,
    private authService: AuthService,
  ) { }

  onClick(pinnedItem: IPinnedItem) {
    const stateName = this.pinTypesUrls[pinnedItem.type];

    if(pinnedItem.adminUrl === this.__env.interfaceUrl) {
      this.$state.go(stateName, pinnedItem.srefParams);
    } else {
      window.location.href = `${pinnedItem.adminUrl}${this.$state.href(stateName, pinnedItem.srefParams)}?token=${this.authService.getToken()}`;
    }
  }

  $onInit() {
    if (this.useAllEnv) {
      this.pinnedItems = this.pinnedItemsService.pinnedItemsAllEnv;
      this.spinnerservice.show('loading');
      this.pinnedItemsService.initAllEnv()
        .finally(() => {
          this.spinnerservice.hide('loading');
        });
    } else {
      this.pinnedItems = this.pinnedItemsService.pinnedItems;
      this.spinnerservice.show('loading');
      this.pinnedItemsService.init()
        .finally(() => {
          this.spinnerservice.hide('loading');
        });
    }
  }
}
