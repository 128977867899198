'use strict';

const template = require('./sticky-button.html');
require('./_sticky-button.scss');

export const StickyButtonComponent: ng.IComponentOptions = {
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    stickyDate: '<',
    isUpdatingSticky: '<',
  },
};
