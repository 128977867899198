'use strict';

import { AuthService } from './../../core/dataservices/auth.service';
import { SelectedSquareFactory } from '../../core/selectedsquare.factory';
import * as _ from 'lodash';
import { SearchService } from '../../core/dataservices/search.service';
import { CurrentUserService } from '../../core/dataservices/currentUser.service';
import { ServerConstants } from '../../core/serverconstants';
import { SquareService } from '../../core/dataservices/square.service';

export class SquareOrphanSelectionController {
  static $inject = [
    'searchservice',
    '$stateParams',
    '$state',
    '$scope',
    'selectedSquareFactory',
    'pinnedItemsService',
    '$transitions',
    'currentUserService',
    'serverConstants',
    'squareservice',
    '__env',
    'authService',
  ];
  constructor(
    private searchService: SearchService,
    private $stateParams,
    private $state,
    private $scope,
    private selectedSquareFactory: SelectedSquareFactory,
    private pinnedItemsService,
    private $transitions,
    private currentUserService: CurrentUserService,
    private serverConstants: ServerConstants,
    private squareService: SquareService,
    private __env: Environment,
    private authService: AuthService,
  ) {
  }
  selectedSquare = this.selectedSquareFactory;
  selectedSquareGuid: string;
  selectedOrphanGuid: string;
  dropdownList = [];
  icon = 'fa fa-cube';

  async $onInit() {
    await this.pinnedItemsService.initAllEnv();

    this.dropdownList = await this.list();
    this.updateSelectedSquare();

    // Used for updating the squares dropdown when updating a square name
    this.$scope.$watch(
      () => this.selectedSquareFactory.Name,
      (newValue) => {
        if (newValue) {
          const selectedFactory = this.selectedSquareFactory;
          _.each(this.dropdownList, (element) => {
            if (element.Guid === selectedFactory.Guid) {
              element.Name = newValue;
            }
          });
        }
      },
    );

    // Used for updating the squares dropdown when creating a new square
    this.$scope.$watch(
      () => this.selectedSquareFactory.Guid,
      (newValue) => {
        if (newValue) {
          const selectedFactory: any = this.selectedSquareFactory;
          const newItem = _.find(this.dropdownList,
            (o: any) => o.Guid === selectedFactory.Guid,
          );
          if (newItem === undefined) {
            this.dropdownList.push({
              Name: selectedFactory.Name,
              Guid: selectedFactory.Guid,
              Type: 2,
              SquareUrl: selectedFactory.SquareUrl,
              SsoEnabled: selectedFactory.SsoEnabled,
              Language: selectedFactory.Language,
            });

            this.updateSelectedSquare();
          }
        }
      },
    );
    this.$transitions.onSuccess({}, (transition) => {
      if (transition.params('to').squareGuid !== this.selectedSquareFactory.Guid) {
        this.selectedSquareGuid = transition.params('to').squareGuid;
        this.selectedOrphanGuid = transition.params('to').orphanGuid;
        this.updateSelectedSquare();
      }
    });
  }
  async list() {
    const returnList = [];
    const shouldFetchSquaresCrossEnvironment = this.currentUserService.userProfile?.Role !== this.serverConstants.roleConstants.human8;

    const squaresPerClient = await this.squareService.getSquaresPerClient([
      this.serverConstants.squareStatusConstants.draft,
      this.serverConstants.squareStatusConstants.scheduled,
      this.serverConstants.squareStatusConstants.active,
      this.serverConstants.squareStatusConstants.closed,
      this.serverConstants.squareStatusConstants.archived,
      this.serverConstants.squareStatusConstants.deleted,
    ],
    shouldFetchSquaresCrossEnvironment,
    shouldFetchSquaresCrossEnvironment ? null : this.$stateParams.programGuid) ?? [];

    if (squaresPerClient.length < 1) {
      // get squares using the search service
      const responseList = await this.searchService.getSearchSelectionForDropdownSquare(
        {
          programGuid: this.$stateParams.programGuid,
        },
      );
      if (responseList) {
        returnList.push(..._.map(responseList, (el: any) => {
          el.isSelected = el.Guid === this.$stateParams.squareGuid;
          el.IsPinned = () => this.pinnedItemsService.isPinned(el.Guid);
          return el;
        }));
      }
    } else {
      _.forEach(squaresPerClient, (spc) => {
        returnList.push(..._.map(spc.squares, (square) => ({
          Guid: square.guid,
          Name: square.name,
          ProgramGuid: square.programGuid,
          ClientGuid: spc.guid,
          ClientCode: spc.code,
          IsSelected: square.guid === this.$stateParams.squareGuid,
          IsPinned: () => this.pinnedItemsService.isPinned(square.guid),
          Type: this.serverConstants.searchTypeConstants.square,
          BaseUrl: square.baseUrl,
          CommandUrl: square.commandUrl,
          IsCompleted: true,
        })));
      });
    }

    return returnList;
  }

  updateSelectedSquare() {
    _.each(this.dropdownList, async (square) => {

      if (square.Guid === this.$stateParams.squareGuid) {
        this.selectInList(square.Guid);
      }
    });
  }

  async selectSquareOrOrphan(squareOrOrphan) {
    const _clientGuid = squareOrOrphan.ClientGuid || this.$stateParams.clientGuid;
    const _programGuid = squareOrOrphan.ProgramGuid || this.$stateParams.programGuid;
    if (squareOrOrphan.BaseUrl && squareOrOrphan.BaseUrl !== this.__env.interfaceUrl) {
      const homeRoute = await this.authService.getHomeRouteInfoForLoggedInUser(true, false, _clientGuid, _programGuid, squareOrOrphan.Guid);
      let redirectLink = this.$state.href(homeRoute.routeName, homeRoute.routeData, { absolute: true });
      redirectLink = redirectLink.replace(this.__env.interfaceUrl, squareOrOrphan.BaseUrl);

      const queryApiUrl = (_.find(this.__env.environments, (env) => squareOrOrphan.BaseUrl.startsWith(env.adminUrl)) as SingleEnvironment)?.queryApiUrl;
      const token = await this.authService.getTokenFor(
        queryApiUrl || '',
        squareOrOrphan.ClientCode,
        squareOrOrphan.Guid || '');
      const separator = redirectLink.includes('?') ? '&' : '?'; // Redirect url might already have a param so we just add to it

      window.location.href = `${redirectLink}${separator}token=${token}`;
    }

    this.selectInList(squareOrOrphan.Guid);
    if (squareOrOrphan === 'current') {
      this.$state.go('root.square.activities', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
      }, { reload: 'root.square.activities' });
      return;
    }

    if (squareOrOrphan.IsCompleted) {
      this.$state.go('root.square.activities', {
        clientGuid: _clientGuid,
        programGuid: _programGuid,
        squareGuid: squareOrOrphan.Guid,
      }, { reload: 'root.square.activities' });
    } else if (squareOrOrphan.Type === 2) {
      this.$state.go('root.square.info', {
        clientGuid: _clientGuid,
        programGuid: _programGuid,
        squareGuid: squareOrOrphan.Guid,
      }, { reload: 'root.square.info' });
    } else if (squareOrOrphan.Type === undefined) {
      this.$state.go('root.orphanoverview', {
        clientGuid: _clientGuid,
        programGuid: _programGuid,
      }, { reload: 'root.orphanoverview' });
    }
  }

  selectInList(selectedGuid) {
    _.each(this.dropdownList, (element) => {
      if (element.IsSelected) {
        element.IsSelected = false;
      }
      if (element.Guid === selectedGuid) {
        element.IsSelected = true;
      }
    });
  }
}
