'use strict';

import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { ServerConstants } from 'src/app/core/serverconstants';
import { RouterHelper } from '../../../blocks/router/router-helper';
import { ActivationQuestionsController } from './activationQuestions.controller';

const template = require('./activationQuestions.html');

const routeConfig = (
  routerHelper: RouterHelper,
  serverConstants: ServerConstants,
  featureservice: FeatureService) => {
  routerHelper.configureStates([{
    state: 'root.square.activationquestions',
    config: {
      url: '/config/activationquestions',
      templateUrl: template,
      controller: ActivationQuestionsController,
      controllerAs: 'vm',
      title: 'Activation questions',
      settings: {
        nav: 2,
        childnav: 7,
        label: 'Activation questions',
        showForums: true,
        show: async () => await hasAccessToActivationQuestions(),
      },
      disallowRoles: ['Observer'],
    },
  }]);

  const hasAccessToActivationQuestions = async () => {
    const result = await featureservice.checkFeatureAccessibilityForSetup(
      serverConstants.featureConstants.azureAdB2CLogin);
    return result;
  };

};

routeConfig.$inject = ['routerHelper', 'serverConstants', 'featureservice'];

export default routeConfig;


