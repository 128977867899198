'use strict';

import { ServerConstants } from './../../core/serverconstants';
import { PinnedItemsService } from './../../core/services/pinned-items.service';

export class HomeSidenavController {
  static $inject = ['pinnedItemsService', 'serverConstants', '$mdDialog'];
  useAllEnvValue: boolean = false;

  constructor(
    private pinnedItemsService: PinnedItemsService,
    private serverConstants: ServerConstants,
    private $mdDialog: ng.material.IDialogService,
  ) { }

  releaseNotesLink = this.serverConstants.validationConstants.releaseNotes;
  pinnedItems;


  $onInit() {
    this.pinnedItems = this.pinnedItemsService.pinnedItems;
    this.pinnedItemsService.init();
  }

  unpin($event, pinnedItem) {
    $event.stopPropagation();
    $event.preventDefault();
    pinnedItem.unpin();
  }

  startGame() {
    const src = 'https://zsee.github.io/bouncy-anything/iframe.html?'
      + 'options=%7B%22bouncyImages%22%3A%5B%22bouncy-bunny.png%22%2C%22bouncy-bunny2.png%22'
      + '%5D%2C%22backgroundImage%22%3A%22background-lightblue.png%22%2C%22topPipeImage%22%3'
      + 'A%22topPipe-eggs.png%22%2C%22bottomPipeImage%22%3A%22bottomPipe-eggs.png%22%2C%22in'
      + 'troTextTitle%22%3A%22Square%20Easter%20bunny%22%2C%22introTextMessage%22%3A%22Don\''
      + 't%20break%20any%20eggs!%20Click%20to%20play!%22%2C%22bouncyDeadTextTitle%22%3A%22Ha'
      + 'ppy%20Easter!%20Score%3A%20%7Bscore%7D%22%2C%22bouncyDeadTextMessage%22%3A%22You%20'
      + 'died.%20Click%20to%20play%20again.%22%7D';
    this.$mdDialog.show({
      template: `<iframe src="${src}" style="width: 670px; height: 510px; border: none;" />`,
      clickOutsideToClose: true,
    });
  }
}
