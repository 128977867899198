'use strict';

import { HttpService } from './http.service';

export class PlatformHealthService {
  static $inject = ['httpservice'];

  constructor(
    private httpservice: HttpService,
  ) { }

  getHealthData(data) {
    return this.httpservice.postToQuery({
      url: '/PlatformHealthService/GetHealthData',
      data,
    }).then((response) => response.data);
  }
}
