'use strict';

import { ToastController } from './toast.controller';

export class Logger {
  static $inject = ['$log', '$injector'];
  private $mdToast: ng.material.IToastService;

  constructor(
    private $log: ng.ILogService,
    private $injector,
  ) { }

  error(message: string, data?: any): void {
    this.makeSureMdToastIsInitialized();
    this.$mdToast.show({
      hideDelay: false,
      position: 'bottom right',
      controller: ToastController,
      controllerAs: 'vm',
      template: `${'<md-toast class="toast-error">' +
        '<span class="md-toast-text">'}${message}</span>` +
        '<md-icon ng-click="vm.closeToast()" class="fa fa-close close-icon"></md-icon>' +
        '</md-toast>',
    });
    this.$log.error(`Error: ${message}`, data);
  }

  info(message: string, data?: any): void {
    this.$log.info(`Info: ${message}`, data);
  }

  success(message: string, data?: any): void {
    this.makeSureMdToastIsInitialized();
    this.$mdToast.show({
      hideDelay: false,
      position: 'bottom right',
      controller: ToastController,
      controllerAs: 'vm',
      template: `${'<md-toast class="toast toast-success">' +
        '<md-icon class="fa fa-check"></md-icon>' +
        '<span class="md-toast-text">'}${message}</span>` +
        '<md-icon ng-click="vm.closeToast()" class="fa fa-close close-icon"></md-icon>' +
        '</md-toast>',
    });
    this.$log.info(`Success: ${message}`, data);
  }

  warning(message: string, data?: any): void {
    this.$log.warn(`Warning: ${message}`, data);
  }

  log(...args: any[]): void {
    this.$log.log(...args);
  }

  private makeSureMdToastIsInitialized(): void {
    if (!this.$mdToast) {
      this.$mdToast = this.$injector.get('$mdToast');
    }
  }
}
