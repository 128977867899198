'use strict';

import { ServerConstants } from '../../../core/serverconstants';
import { HttpService } from '../../../core/dataservices/http.service';
import { JobService } from '../../../core/jobs/job.service';
import { ParticipantService } from 'src/app/core/dataservices/participant.service';

require('./data-import-modal.scss');

export class DataImportModalController {
  static $inject = [
    'activityGuid', 'httpservice', '$mdDialog', 'serverConstants', 'jobservice', 'participantservice'];
  constructor(
    private activityGuid: string,
    private httpService: HttpService,
    private $mdDialog: ng.material.IDialogService,
    private serverConstants: ServerConstants,
    private jobService: JobService,
    private participantservice: ParticipantService,
  ) {
  }

  invalidType = false;
  errors = {};
  errorkeys = [];
  warnings = {};
  warningkeys = [];
  totalImportableReplies: number = 0;
  totalImportableSegments: number = 0;
  fileValidationSucceed: boolean = false;
  file: File;
  blob: string;
  settings;
  username: string;
  password: string;

  private readonly url = '/ParticipantService/ProcessCustomUploadedExcelFile';

  cancel() {
    this.$mdDialog.cancel();
  }

  async validateMass(file, invalidFile) {
    this.resetUpload();
    this.file = file;
    if (invalidFile) {
      this.invalidType = true;
    }
    this.blob = '';
    this.fileValidationSucceed = false;
    if (this.file) {
      const validationInfo = await this.participantservice
        .validateCustomExcelFile(this.file, this.activityGuid, this.username, this.password);
      this.errors = validationInfo.data.Errors;
      this.errorkeys = Object.keys(this.errors);
      this.warnings = validationInfo.data.Warnings;
      this.warningkeys = Object.keys(this.warnings);
      this.totalImportableReplies = validationInfo.data.TotalReplies;
      this.totalImportableSegments = validationInfo.data.TotalSegments;
      this.blob = validationInfo.data.Name;
      if (Object.keys(this.errors).length === 0) {
        this.fileValidationSucceed = true;
        this.settings = validationInfo.data.Settings;
      }
    }
  }

  resetUpload() {
    this.fileValidationSucceed = false;
    this.totalImportableReplies = this.totalImportableSegments = 0;
    this.invalidType = false;
    this.errors = {};
  }

  async import() {
    this.$mdDialog.hide();
    const jobIdResult = await this.httpService.post({
      url: this.url,
      data: {
        activityGuid: this.activityGuid,
        name: this.blob,
        settings: this.settings,
      },
    });
    if (jobIdResult && jobIdResult.data && jobIdResult.data !== '') {
      await this.jobService.showJobProgressDialog(
        jobIdResult.data, 'Import discussion');
      return true;
    }
    return false;
  }

}
