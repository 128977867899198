'use strict';

import './loginerror.module';
import { LoginErrorComponent } from './loginerror.component';
import routeConfig from './loginerror.route';

angular
  .module('insitesApp.loginerror')
  .component('loginerror', LoginErrorComponent)
  .run(routeConfig);
