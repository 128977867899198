'use strict';

import { CustomCSSController } from './customCSS.controller';
const customCssTemplate = require('./customCSS.html');
require('./customCss.scss');

export const squareCustomCss: ng.IComponentOptions = {
  controller: CustomCSSController,
  templateUrl: customCssTemplate,
  controllerAs: 'vm',
  bindings: {
    isEmail: '<',
  },
};
