'use strict';

const excludeConsumed = (_) => (filteringList, targetingOptions, targeting) => {
  const response = [];
  if (!filteringList) {
    return response;
  }

  if (filteringList[0]
      && Object.prototype.hasOwnProperty.call(filteringList[0], 'SegmentItems')) {
    if (targeting && targeting.segmentation) {
      return filteringList;
    }
    _.each(filteringList, (item) => {
      let numberOfLines = 0;
      _.each(targetingOptions, (elem) => {
        if (elem.segmentation && elem.segmentation.Guid === item.Guid) {
          numberOfLines++;
        }
      });
      if (item.SegmentItems.length !== numberOfLines) {
        response.push(item);
      }
    });
  }

  if (filteringList[0]
      && Object.prototype.hasOwnProperty.call(filteringList[0], 'Answer')) {
    if (targeting && targeting.segment) {
      return filteringList;
    }
    _.each(filteringList, (item) => {
      let found = 0;
      _.each(targetingOptions, (elem) => {
        if (elem.segment && elem.segment.SegmentGuid === item.SegmentGuid) {
          found++;
        }
      });
      if (!found) {
        response.push(item);
      }
    });
  }
  return response;
};

const excludeConsumedFilter = (_) => excludeConsumed(_);
excludeConsumedFilter.$inject = ['_'];

const excludeConsumedService= (_) => excludeConsumed(_);
excludeConsumedService.$inject = ['_'];

angular
  .module('insitesApp.squarecommunication')
  .service('excludeConsumedService', excludeConsumedService)
  .filter('excludeConsumed', excludeConsumedFilter);
