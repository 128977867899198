'use strict';

import _ = require('lodash');
import { AuthService } from 'src/app/core/dataservices/auth.service';
import { HelperService } from 'src/app/core/services/helper.service';

export class DeveloperMenuController {
  static $inject = ['authService', '$state', 'helperservice'];

  constructor(
    private authService: AuthService,
    private $state: ng.ui.IStateService,
    private helperService: HelperService,
  ) { }

  isFullDev = _.memoize(() => this.authService.isFullDev());

  public goToPlatformnotice() {
    this.$state.go('root.platformnotice');
  }

  public goToHangfire() {
    this.helperService.goToHangfire();
  }

  public goToFeatureSetting() {
    this.$state.go('root.featureSetting');
  }

  public goToRewardHandling() {
    this.$state.go('root.rewardHandling');
  }

  public goToSplashPage() {
    this.$state.go('root.splash');
  }
}
