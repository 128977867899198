'use strict';

import '../activityProbeQualBuild/probe-question/isc-probe-question';
import '../activityProbeQualBuild/probe-question/isc-probe-question-overview';
import '../../activityQualOpeningPost';

import { ActivityDiscussionNewBuildComponent } from './activityDiscussionNewBuild.component';

angular
  .module('insitesApp.activityQualResearchConfig')
  .component('activityDiscussionNewBuild', ActivityDiscussionNewBuildComponent);
