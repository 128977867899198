'use strict';

angular
  .module('insitesApp.home')
  .component('noticeDefaultMaintenance', {
    templateUrl: require('./noticeDefaultMaintenance.html'),
    controller: () => { },
    controllerAs: 'vm',
    bindings: {
      model: '=',
      form: '<',
      offsetDate: '<',
      minDate: '<',
      languages: '<',
      language: '=',
      changeCallback: '&',
      languageCallback: '&',
      defaultMessage: '<',
    },
  });
