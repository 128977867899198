'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';

const researcherLoginAppRun = (routerHelper: RouterHelper, authService) => {
  routerHelper.configureStates([
    {
      state: 'researcherlogin',
      config: {
        // eslint-disable-next-line max-len
        url: '/client/:clientGuid/program/:programGuid/square/:squareGuid/researcherlogin?token?switchinterface?redirectType?activityGuid?step?replyGuid?activatemsg?isEdit?',
        component: 'researcherLoginComponent',
        requireADLogin: false,
        allowAnonymous: true,
        resolve: {
          userExists: [
            '$stateParams',
            '$q',
            ($stateParams, $q) => {
              const userToken = $stateParams.token;
              const oldToken = authService.getToken();
              if (userToken) {
                if (userToken !== oldToken) {
                  authService.setJwToken(userToken, false);
                  window.location.reload();
                  return $q.reject();
                }
              }
              return authService.updateUser();
            },
          ],
        },
      },
    },
  ]);
};

researcherLoginAppRun.$inject = ['routerHelper', 'authService'];

angular.module('insitesApp.researcherLogin').run(researcherLoginAppRun);
