'use strict';

import { IscActivityFilterController } from './isc-activity-filter.controller';

const template = require('./isc-activity-filter.html');
require('./_isc-activity-filter.scss');

export const iscActivityFilterComponent: ng.IComponentOptions = {
  controller: IscActivityFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    filterData: '=',
    filterDataCounters: '=',
    applyFilter: '&',
    resetFilter: '&',
    additionalData: '=',
    isQual: '<?',
  },
};
