'use strict';

import { DateTime } from 'luxon';
import { IActivityFilterAdditionalData, IActivityFilterData } from '../contracts/activity.contract';
import _ = require('lodash');
import { ActivityService } from '../dataservices/activity.service';

export class ActivityFilterService {
  static $inject = ['$stateParams', 'serverConstants', 'activityservice'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants,
    private activityservice: ActivityService,
  ) { }

  getFilterFromQueryString(defaultStartDate: DateTime, defaultEndDate: DateTime, activityType: number[]|number|any): IActivityFilterData {
    let startDate = this.$stateParams.startDate ? DateTime.fromISO(this.$stateParams.startDate) : defaultStartDate;
    let endDate = this.$stateParams.endDate ? DateTime.fromISO(this.$stateParams.endDate) : defaultEndDate;
    if (typeof startDate === 'string' || startDate instanceof String) {
      startDate = DateTime.fromISO(startDate.toString());
    }
    if (typeof endDate === 'string' || endDate instanceof String) {
      endDate = DateTime.fromISO(endDate.toString());
    }
    const filter: IActivityFilterData = {
      Keyword: this.$stateParams.searchKeyword,
      StartDate: startDate.startOf('day'),
      EndDate: endDate.endOf('day'),
      Statuses: this.getArrayFromQueryString(this.$stateParams.statuses),
      SelectedThemes: this.getArrayFromQueryString(this.$stateParams.themes),
    };

    let activityTypes = [];
    if (Array.isArray(activityType)) {
      activityTypes = activityType;
    } else {
      activityTypes = [activityType];
    }

    if (_.find(activityTypes, (at) => this.activityservice.isQualResearchActivity(at))) {
      const qualFilter: IActivityFilterData = {
        SelectedMembers: this.getArrayFromQueryString(this.$stateParams.members),
        Highlight: this.$stateParams.highlight !== undefined ? JSON.parse(this.$stateParams.highlight) : false,
        TagsSelected: this.getArrayFromQueryString(this.$stateParams.tags),
        HashtagsSelected: this.getArrayFromQueryString(this.$stateParams.hashtags),
        SelectedProbeQuestions: this.getArrayFromQueryString(this.$stateParams.probequestions),
        SelectedSegments: this.getArrayFromQueryString(this.$stateParams.segments),
        SelectedSegmentsOption: parseInt(this.$stateParams.segmentsOption || this.serverConstants.selectedSegmentsOptionConstants.any, 10),
        SelectedRatings: this.getArrayFromQueryString(this.$stateParams.ratings),
        SelectedModerationStatusOptions: this.getArrayFromQueryString(this.$stateParams.selectedModerationStatusOptions),
        SelectedAnnotationsOptions: this.getArrayFromQueryString(this.$stateParams.selectedAnnotationsOptions),
        SelectedStimuliOptions: this.getArrayFromQueryString(this.$stateParams.selectedStimuliOptions),
        ActivityType: Number(this.$stateParams.activityType),
        ...filter,
      };
      return qualFilter;
    }
    return filter;
  }

  // If keyword -> keep date / if no keyword -> no date /
  getQualStateParamsFromFilter(filter: IActivityFilterData, shouldFillDates: boolean = false) {
    const qualFilterData = {
      highlight: filter.Highlight,
      members: filter.SelectedMembers,
      themes: filter.SelectedThemes,
      tags: filter.TagsSelected,
      hashtags: filter.HashtagsSelected,
      probequestions: filter.SelectedProbeQuestions,
      segments: filter.SelectedSegments,
      segmentsOption: filter.SelectedSegmentsOption,
      ratings: filter.SelectedRatings,
      selectedModerationStatusOptions: filter.SelectedModerationStatusOptions,
      selectedAnnotationsOptions: filter.SelectedAnnotationsOptions,
      selectedStimuliOptions: filter.SelectedStimuliOptions,
      jumpToFirstNew: filter.JumpToFirstNew,
      keyword: filter.Keyword,
      activityType: filter.ActivityType,
    };
    if (filter.Keyword !== undefined || shouldFillDates) {
      const basicFilterData = this.getStateParamsFromFilter(filter);
      return _.merge(qualFilterData, basicFilterData);
    }
    return qualFilterData;
  }

  getStateParamsFromFilter(filter: IActivityFilterData) {
    const basicFilterData = {
      searchKeyword: filter.Keyword,
      startDate: filter.StartDate.toISO({ includeOffset: false }),
      endDate: filter.EndDate.toISO({ includeOffset: false }),
      statuses: filter.Statuses,
      themes: filter.SelectedThemes,
      activityType: filter.ActivityType,
    };
    return basicFilterData;
  }

  resetFilterData(filter: IActivityFilterData, defaultMinStartDate: DateTime) {
    filter.Keyword = '';
    filter.StartDate = defaultMinStartDate;
    filter.EndDate = DateTime.now();
  }

  resetQualFilterData(filter: IActivityFilterData, defaultMinStartDate: DateTime) {
    this.resetFilterData(filter, defaultMinStartDate);
    filter.Highlight = false;
    filter.SelectedMembers = [];
    filter.SelectedSegments = [];
    filter.SelectedSegmentsOption = null;
    filter.SelectedRatings = [];
    filter.TagsSelected = [];
    filter.HashtagsSelected = [];
    filter.SelectedProbeQuestions = [];
    filter.SelectedModerationStatusOptions = [];
    filter.SelectedAnnotationsOptions = [];
    filter.SelectedStimuliOptions = [];
  }

  createFilterAdditionalData(minStartDate: DateTime, maxEndDate: DateTime): IActivityFilterAdditionalData {
    const additionalData: IActivityFilterAdditionalData = {
      MinStartDate: minStartDate,
      MaxEndDate: maxEndDate,
    };
    return additionalData;
  }

  createQualFilterAdditional(minStartDate: DateTime, maxEndDate: DateTime, isModeratorCurationEnabled?: boolean): IActivityFilterAdditionalData {
    const additionalData: IActivityFilterAdditionalData = {
      MinStartDate: minStartDate,
      MaxEndDate: maxEndDate,
      IsModeratorCurationEnabled: isModeratorCurationEnabled,
    };
    return additionalData;
  }

  private getArrayFromQueryString(data) {
    if (!data) {
      return [];
    }
    if (Array.isArray(data)) {
      return data;
    }
    return [data];

  }

  getFilterUrlParts() {
    return [
      'searchKeyword',
      'startDate',
      'endDate',
      'statuses',
      'themes',
    ].join('?');
  }

  getQualFilterUrlParts() {
    return [
      'searchKeyword',
      'startDate',
      'endDate',
      'statuses',
      'themes',
      'highlight',
      'members',
      'tags',
      'hashtags',
      'segments',
      'segmentsOption',
      'ratings',
      'jumpToFirstNew',
      'probequestions',
      'activityType',
      'isGroupedByMember',
      'selectedModerationStatusOptions',
      'selectedAnnotationsOptions',
      'selectedStimuliOptions',
    ].join('?');
  }

  getQualFilterUrlPartsForDiscussions() {
    return [
      'searchKeyword',
      'startDate',
      'endDate',
      'themes',
      'members',
      'tags',
      'hashtags',
      'segments',
      'segmentsOption',
      'ratings',
      'jumpToFirstNew',
      'probequestions',
      'isGroupedByMember',
      'selectedModerationStatusOptions',
      'selectedAnnotationsOptions',
      'activityType',
      'selectedStimuliOptions',
    ].join('?');
  }
}
