'use strict';

export class DropdownGeneral implements ng.IDirective {
  scope = true;
  link(scope, elem) {
    const unsetVisible = () => {
      scope.menu.visible = false;
      scope.$apply();
    };

    const toggleVisible = () => {
      scope.menu.visible = !scope.menu.visible;
      scope.$apply();
    };

    const update = (visible) => {
      // Flip chevron
      icon.attr('class', visible ? 'fa-caret-up' : 'fa-caret-down');
      // Toggle menu
      menu.toggle(visible);
    };

    const body = angular.element('body');
    const menu = elem.find('ul');
    const unfold = angular.element("<a href='#'><i/></a>");
    const icon = unfold.find('i');

    elem.prepend(unfold);

    scope.menu = { visible: false };
    scope.$watch('menu.visible', update);

    body.on('click', unsetVisible);
    body.on('keyup', (e) => {
      // Escape key
      if (e.which === 27) {
        unsetVisible();
      }
    });
    unfold.on('click', (e) => {
      e.stopPropagation();
      toggleVisible();
    });
  }
}
