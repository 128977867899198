'use strict';

import { WelcomeMessageConfigurationController } from './welcomeMessageConfiguration.controller';

const template = require('./welcomeMessageConfiguration.html');

angular
  .module('insitesApp.clientConfiguration')
  .component('welcomeMessageConfiguration', {
    controller: WelcomeMessageConfigurationController,
    controllerAs: 'vm',
    templateUrl: template,
  });
