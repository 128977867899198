'use strict';

import { IFileConfigurationResponse } from '../contracts/configuration.contracts';
import { HttpService } from './http.service';
import { IPromise } from 'angular';
import _ = require('lodash');
import { ServerConstants } from '../serverconstants';

export class ConfigurationService {
  static $inject = ['httpservice', 'serverConstants'];

  constructor(
    private httpService: HttpService,
    private serverConstants: ServerConstants,
  ) { }

  getFileConfiguration(): IPromise<IFileConfigurationResponse[]> {
    return this._getFileConfiguration();
  }

  private _getFileConfiguration = _.memoize(() => this.httpService.get({
    url: '/ConfigurationService/GetFileConfiguration',
  }).then((response) => response.data as IFileConfigurationResponse[]));

  getFileTypes(): Record<string, unknown> {
    return this.serverConstants.fileTypeConstants;
  }

  getAcceptedMimeTypesAndExtensions(fileConfiguration: IFileConfigurationResponse[], fileTypes: number[]):
  { mimeTypes: string[], extensions: string[], patterns: string[] } {
    let mimeTypes = [];
    let extensions = [];
    let patterns = [];

    if (fileTypes && fileTypes.length) {
      _.forEach(fileTypes, (fileType) => {
        const fileTypeConfig = _.find(fileConfiguration, (config) => config.FileType === fileType);
        if (fileTypeConfig && fileTypeConfig.Extensions) {
          _.forEach(fileTypeConfig.Extensions, (ext) => {
            mimeTypes = mimeTypes.concat(ext.MimeType);
            extensions = extensions.concat(ext.Extension);
            patterns = patterns.concat(`.${ext.Extension}`);
          });
        }
      });
    }
    return { mimeTypes, extensions, patterns };
  }

  getConfigList() {
    return this.httpService.post({
      url: '/FeatureService/ConfigurationList',
    });
  }

}
