'use strict';

import './squareRewards.module';
import routeConfig from './squareRewards.route';
import './rewardStatusToClass.filter';
import { RewardsService } from './rewards.service';
import './instantRewards';
import './periodicRewards';
import './manualRewards';
import './savingUpRewards';
require('./squareRewards.scss');

angular.module('insitesApp.squarerewards')
  .service('rewardsService', RewardsService)
  .run(routeConfig);
