'use strict';

import { SquareService } from '../../../core/dataservices/square.service';
import { ServerConstants } from '../../../core/serverconstants';
import * as _ from 'lodash';

const tabTemplates = {
  incentivescustom: require('./tabTemplates/incentivescustom.tabtemplate.html'),
  myincentive: require('./tabTemplates/myincentive.tabtemplate.html'),
};

export class SquareConfigIncentivesController implements ng.IOnInit {
  static $inject = ['squareservice', '$q', 'serverConstants', '$scope'];
  constructor(
    private squareservice: SquareService,
    private $q: ng.IQService,
    private serverConstants: ServerConstants,
    private $scope: ng.IScope) { }

  pageTypeConstants;
  area;
  pageTypeDetails;

  $onInit(): void {
    this.pageTypeConstants = this.serverConstants.pageTypeConstants;
    this.area = this.serverConstants.areaConstants.incentives;
    this.pageTypeDetails = [
      {
        Label: 'My Rewards',
        syncService: this.squareservice.syncPage('MyIncentive'),
        required: true,
        onlyOneAllowed: true,
        pageType: this.serverConstants.pageTypeConstants.myIncentive,
      },
      {
        Label: 'Custom',
        syncService: this.squareservice.syncPage('IncentivesCustom'),
        required: false,
        onlyOneAllowed: false,
        pageType: this.serverConstants.pageTypeConstants.incentivesCustom,
      },
    ];
  }

  getTemplateForPage = (page) => {
    const name = _.findKey(this.pageTypeConstants, (c) => c === page.PageType);
    return tabTemplates[name.toLowerCase()];
  };

  addPage = () => this.$scope.$broadcast('addPage');
}
