'use strict';

import { DateTime } from 'luxon';

export class ActivityQuantBuilderModel {
  Title = '';
  Description = '';
  CardTeaser = '';
  Files = [];
  CallToActionText = '';
  Sticky = false;
  ShowOnHomepage = true;
  StartDateTime = DateTime.now();
  CommunicationStimuli = [];
  CommunicationFiles = [];
  CommunicationGuid;
  constructor() { }
}
