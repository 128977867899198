'use strict';

import { ActivityDataPostNotesController } from './activityDataPostNotes.controller';
const template = require('./activityDataPostNotes.html');

angular.module('insitesApp.activityData').component('iscActivityDataPostNotes', {
  controller: ActivityDataPostNotesController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    items: '<',
    conversationGuid: '<',
    readOnly: '<',
  },
});
