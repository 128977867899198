'use strict';

import { ServerConstants } from './../serverconstants';
import { JobService, IJobDetails } from './job.service';

require('./job-progress.scss');

export class JobProgressController {
  private pollPromise;
  jobDetails: IJobDetails;
  result;
  loading: boolean;
  private jobId: string;

  get inProgress() {
    return this.loading || this.jobDetails && !this._.includes(['Failed', 'Succeeded', 'Deleted'], this.jobDetails.State);
  }
  get interpolateResult() {
    return this._.isString(this.result.Feedback) || this._.isNumber(this.result) || this._.isNull(this.result) || this._.isUndefined(this.result);
  }

  static $inject = ['$mdDialog', '$timeout', 'jobservice', 'jobId', 'canSendDigest', 'canEmitOnClose',
    'title', '_', '$scope', '$q', 'logger', 'serverConstants'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $timeout: ng.ITimeoutService,
    private jobService: JobService,
    jobId: { jobId: string | Promise<string> | ng.IPromise<string> },
    private canSendDigest: boolean,
    private canEmitOnClose: boolean,
    public title: string,
    private _: _.LoDashStatic,
    private $scope: ng.IScope,
    private $q: ng.IQService,
    private logger: Logger,
    private serverConstants: ServerConstants,
  ) {
    this.$scope.$on('$destroy', () => this.stopPollingResetStatus());
    this.resolveJobId(jobId.jobId);
  }

  private async resolveJobId(jobId: string | Promise<string> | ng.IPromise<string>) {
    this.loading = true;
    this.jobId = await jobId;
    this.pollResetStatus();
    this.loading = false;
  }

  private async pollResetStatus() {
    this.jobDetails = await this.jobService.getJobDetails(this.jobId);
    if (this.jobDetails.Result) {
      const result = JSON.parse(this.jobDetails.Result);
      // Maybe the result is an array of results, so, we just take the first one in this case
      this.result = result && Array.isArray(result) ? result[0] : result;

      // If the result has file data, access the file Uri to download it
      if (this.result.File) {
        window.open(this.result.File.Uri, '_self');
      }
    }
    if (!this.inProgress) {
      this.pollPromise = null;
      return;
    }

    this.pollPromise = this.$timeout(() => {
      this.pollResetStatus();
    }, 1000);
  }

  private async stopPollingResetStatus() {
    if (this.pollPromise) {
      this.$timeout.cancel(this.pollPromise);
      this.pollPromise = null;
    }
  }

  private async sendDigestAndClose() {
    this.loading = true;
    await this.jobService.sendDigest(this.jobId);
    this.logger.success('You will receive an email with the results, when the job is done.');
    this.$mdDialog.hide();
    this.loading = false;
  }

  toggleContent(event) {
    event.currentTarget.classList.toggle('collapsed');
  }

  close() {
    this.loading = true;
    this.$mdDialog.hide();
    this.loading = false;
    if (this.canEmitOnClose) {
      // we emit an event to parent, if we need to
      this.$scope.$root.$emit('jobprogressclosed', this.jobId);
    }
  }

  createResultString(key, value) {
    if (this._.isArray(value)) {
      if (key.indexOf('{count}') > -1) {
        const re = /{count}/gi;
        return `<p>${key.replace(re, value.length)}</p>`;
      }
      return `<p>${key}</p><p>${value.length}</p>`;

    }
    return `<p>${key}</p><p>${value}</p>`;

  }
}
