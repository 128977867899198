'use strict';

import * as angular from 'angular';

import { IscTopNavComponent } from './isc-topnav.component';
import './developer-menu';

angular
  .module('insitesApp.iscComponents')
  .component('iscTopnav', IscTopNavComponent);
