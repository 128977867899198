'use strict';

import { MappingService } from './../../../../core/services/mapping.service';
import { SelectedActivityFactory } from './../../../../core/selectedactivity.factory';
import { ServerConstants } from './../../../../core/serverconstants';
import { ActivityDataService, IForumConversation } from './../../../../core/services/activityData.service';
import { ActivityService } from './../../../../core/dataservices/activity.service';
import { ActivityBuildBaseController } from './../common/activityBuildBase.controller';
import { ActivityDiscussionNewBuildModel } from './activityDiscussionNewBuildModel';
import { SquareActivityModel } from '../../squareActivityModel';
import { PoboService } from '../../../../core/services/pobo.service';
import * as _ from 'lodash';
import { ActivityBuildModelBase } from '../common/activityBuildBase.model';
import { DiscussionService } from './../../../../core/dataservices/discussion.service';
import { DiscussionDataService } from './../../../../core/services/discussionData.service';
import { IDiscussionActivityRequest } from '../../../../core/contracts/discussion.contract';
import { CommunicationService } from '../../../../core/dataservices/communication.service';
import { NIL } from 'uuid';

export class ActivityDiscussionNewBuildController extends ActivityBuildBaseController {
  static $inject = [
    'serverConstants',
    'activityservice',
    'activitydataservice',
    '$stateParams',
    'logger',
    'selectedActivityFactory',
    '$scope',
    '$mdDialog',
    'poboService',
    'mappingService',
    'discussionService',
    'discussionDataService',
    'communicationservice'];

  wizardStep;
  model = new ActivityDiscussionNewBuildModel();
  questionType: number = this.serverConstants.conversationQuestionTypeConstants.imageAndVideo;
  saveCallback = () => super.beforeSave(false);
  resetFormCallback = () => super.resetForm();
  isFirstQuestionRequired: boolean = false;

  constructor(
    protected serverConstants: ServerConstants,
    protected activityservice: ActivityService,
    protected activitydataservice: ActivityDataService,
    protected $stateParams: ng.ui.IStateParamsService,
    protected logger: Logger,
    protected selectedActivityFactory: SelectedActivityFactory,
    protected $scope: ng.IScope,
    protected $mdDialog: ng.material.IDialogService,
    protected poboService: PoboService,
    protected mappingService: MappingService,
    protected discussionService: DiscussionService,
    protected discussionDataService: DiscussionDataService,
    protected communicationService: CommunicationService,
  ) {
    super(serverConstants, activityservice, activitydataservice, $stateParams, logger, selectedActivityFactory, $scope,
      $mdDialog, poboService, mappingService);
    // We use a different enum for question types on the discussions module.
    // Since that one cannot be exported right now (it is not located in the Common project), this is a temporary solution.
    // Will further investigate it
    this.questionType = 15;
  }

  $onInit() {
    super.$onInit();
  }

  protected mapModel(data: SquareActivityModel) {
    this.model = this.mappingService.mapSquareActivityModelForDiscussionNewActivity(data);
  }

  $loadData(data: SquareActivityModel) {
    this.model = new ActivityDiscussionNewBuildModel();
    super.$loadData(data);
  }

  async $wizardStepLoaded() {
    await super.$wizardStepLoaded();
    const specifyStep = (await this.wizardStep.resolveComponent(['specifyQual']))[0];
    if (specifyStep && this.squareActivity.ActivityQualDetail == null) {
      this.squareActivity.ActivityQualDetail = specifyStep.squareActivity.ActivityQualDetail;
    }
    if (specifyStep && this.squareActivity.discussionModel == null) {
      this.squareActivity.discussionModel = specifyStep.squareActivity.discussionModel;
    }
    if (!this.$wizardStepIsCompleted()) {
      this.setDefaultOneByOne();
    }

    this.$scope.$watch('vm.model.probeQuestions', () => {
      this.isFirstQuestionRequired = this.model.probeQuestions && _.some(this.model.probeQuestions, (question) => question.answerRequired);
    }, true);
  }

  private setDefaultOneByOne() {
    // PBI 78185:
    // One by one is not applicable in discussions with multiple contributions
    // One by one should be selected by default unless context is group and visibility is influenced
    if (this.squareActivity.ActivityQualDetail.ContributionType === this.serverConstants.activityContributionTypeConstants.diary) {
      this.model.isOneByOne = false;
    }

    this.model.isOneByOne = this.squareActivity.ActivityQualDetail.Visibility !== this.serverConstants.activityVisibilityTypeConstants.influenced;
  }

  // eslint-disable-next-line no-unused-vars
  protected additionalConditionForCompletedSteps(model: ActivityBuildModelBase) {
    return true; // no other additional condition for tis type
  }

  $wizardIsValid() {
    return super.$wizardIsValid() && this.isFirstQuestionRequired;
  }

  async updateActivityBuild(reload: boolean, root: this) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }

    const conversation: IForumConversation = this.mappingService.mapActivityBuildModelToForumConversation(root.model);
    conversation.ActivityGuid = root.$stateParams.activityGuid;

    let discussionGuid: string;
    root.squareActivity.ActivityQualDetail.IsOneByOne = root.model.isOneByOne;
    try {
      discussionGuid = await root.discussionDataService
        .saveDiscussionActivity(
          conversation,
          root.model.probeQuestions,
          root.squareActivity,
        );
    } catch (error) {
      if (error.data && error.data.ValidationErrors && error.data.ValidationErrors[0] && error.data.ValidationErrors[0].PropertyName
        && error.data.ValidationErrors[0].PropertyName === 'ProbeQuestions') {
        // Reload the data for this step and show that probe questions can't be changed anymore.
        root.$loadData(root.squareActivity);
        root.probeQuestionsAlreadyAnswered = true;
      }
      // Rethrow error so that the wizard doesn't go to the next step.
      throw error;
    }
    root.model.conversationGuid = discussionGuid;

    const request: IDiscussionActivityRequest = { activityGuid: root.$stateParams.activityGuid };
    const response = await root.discussionService.getDiscussionActivity(request);
    await this.updateCommunicationTitle(response.communicationGuid, conversation.Title);
    root.form.$setPristine();
    if (!reload) {
      root.logger.success('Activity build successfully updated');
    }
    root.squareActivity = this.mappingService.mapDiscussionActivityDetailsToSquareActivityModel(response, root.squareActivity);
    root.squareActivity.isChildActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.child;
    root.squareActivity.isParentActivity =
      root.squareActivity.Detail.SequenceRole === root.serverConstants.activitySequenceRoleConstants.parent;

    // Since changing one by one can influence the visibility, make sure to also update the specify step:
    (await this.wizardStep.resolveComponent(['specifyQual']))[0].model.Visibility = root.squareActivity.ActivityQualDetail.Visibility;

    root.$loadData(root.squareActivity);
  }

  get oneByOneTooltip() {
    return this.squareActivity.discussionModel?.visibilityType === this.serverConstants.activityVisibilityTypeConstants.private ?
      'A question will only appear when the previous one has been answered.' :
      // eslint-disable-next-line max-len
      "A question will only appear when the previous one has been answered. When choosing 'One by one', visibility will automatically be set to 'uninfluenced' in case of a group activity.";
  }

  private async updateCommunicationTitle(communicationGuid?: string, title?: string) {
    // First time going through the wizard, the communicationGuid will not be filled in since no communication has been created yet.
    // The communication only gets created after saving the present step.
    if (communicationGuid && communicationGuid !== NIL) {
      await this.communicationService.updateCommunicationTitle(communicationGuid, title);
    }
  }
}
