'use strict';

export class ForumRoomModel {
  IsVisible = true;
  Name: string = '';
  constructor(name: string = '', isVisible = true) {
    this.Name = name;
    this.IsVisible = isVisible;
  }
}
