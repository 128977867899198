'use strict';

import { TargetService } from 'src/app/core/dataservices/target.service';
import { ServerConstants } from 'src/app/core/serverconstants';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import _ = require('lodash');
import { DateTime } from 'luxon';

export class TargetCompletionController {
  static $inject = [
    'segmentationservice', 'spinnerservice', '$mdDialog', 'targetservice', 'logger',
    'serverConstants', '$scope', '$q',
  ];
  constructor(
    private segmentationservice,
    private spinnerservice: SpinnerService,
    private $mdDialog: ng.material.IDialogService,
    private targetService: TargetService,
    private logger: Logger,
    private serverConstants: ServerConstants,
    private $scope,
    private $q: ng.IQService,
  ) {
    // Everytime something updates, we need to check for validity to let the parent component know
    this.$scope.$watch('vm.segmentTargets', () => this.updateValidity(), true);
    // We need to wait for the activity guid to be injected before we can load the data
    this.loadWatcher = this.$scope.$watch('vm.activityGuid', async () => {
      if (this.activityGuid) {
        this.spinnerservice.showFor('loading', this.loadData().then(() => this.loadWatcher())); // Removes the watcher after running once
      }
    });
    this.$scope.$on('onActivityTargetingSave', () => this.onSave());
  }

  loadWatcher;
  activityGuid;
  activityInfo: ActivityInfoForSegmentTargetCompletion;
  isReadonly = false;
  isUpdateSamplesDisabled = true;
  isUpdatingSamples = false;
  updateSamplesFn;
  isValid = false;
  hasChanges = false;
  lastModified: DateTime;
  uniqueNrOfCompletes = 0;

  segmentations: any = [];
  activityTargetCompletionSegmentations = [];
  segmentTargets = [];
  isCompletionTarget = false;
  _minimumNumberOfCompletes = 1;
  completionTarget: number;
  initialSegmentTargets: ActivityTargetingSegment[] = [];
  loading = false;
  isActivityTargetCompletionEnabled: boolean;

  async loadData() {
    await this.initSegmentationData();
    if (this.segmentTargets?.length) {
      this.initialSegmentTargets = _.flatMap(this.segmentTargets, (s) => s.SegmentItems);
    } else {
      await this.loadPreviousSegmentTargets();
    }
    this.calculateLastModifiedDate();
  }

  calculateLastModifiedDate() {
    if (!this.segmentTargets || !this.segmentTargets.length) { // We have no data to give the last day for
      this.lastModified = null;
      return;
    }
    const dates = _.map(_.flatMap(this.segmentTargets, (segmentation) => segmentation.SegmentItems), (segment) => segment.DateModified);
    this.lastModified = _.max(dates); // Gets the last date modified
  }

  private initSegmentationData = _.memoize(() => {
    this.spinnerservice.show('loading');
    const includeArchived = this.activityInfo !== undefined && (
      this.activityInfo.activityStatus === this.serverConstants.squareActivityStatusConstants.closed
      || this.activityInfo.activityStatus === this.serverConstants.squareActivityStatusConstants.archived
    );
    return this.segmentationservice.getSquareSegmentations(true, true, includeArchived, this.activityGuid).then((segmentations) => {
      this.segmentations = segmentations;
      this.activityTargetCompletionSegmentations = _.cloneDeep(segmentations); // We don't want references leaking
      this.segmentations.segment = {};

      _.each(this.segmentations, (item: any) => {
        if (!item.SegmentItems) {
          item.SegmentItems = [];
        }
        this.segmentations.segment[item.Guid] = item.SegmentItems;
      });
    }).finally(() => {
      this.spinnerservice.hide('loading');
      this.loading = false;
    });
  });

  private async loadPreviousSegmentTargets() {
    if (!this.activityGuid) {
      return;
    }
    await this.targetService.getActivitySegmentTargetForActivity(this.activityGuid)
      .then(async (response) => {
        const data = response.data.ActivitySegmentTargetList;
        this.uniqueNrOfCompletes = response.data.UniqueNrOfCompletes;

        // Get previous data and map to segmentation
        this.segmentTargets = await this.$q.all(_.map(_.groupBy(data, (s) => s.SegmentationGuid), async (segmentList, segmentationGuid) => {
          // Find segment, then add segments and add to list, no reference

          const segmentation = _.find(this.activityTargetCompletionSegmentations, (t) => t.Guid === segmentationGuid);

          // Now that we have most of the information, we need to add the MemberCount as well, which is an extra API call

          const segments = await this.segmentationservice.getSegmentsForSegmentation(segmentationGuid).then((() =>
            _.map<ActivityTargetingSegment>(segmentList, (segment) => (
              {
                Guid: segment.SegmentGuid,
                SegmentAnswer: segment.Answer,
                Target: segment.Target,
                Qualified: segment.Qualified,
                InProgress: segment.InProgress,
                MemberCount: segment.Available,
                DateModified: segment.DateModified,
              }
            ))
          ));

          // Save it and return to map it from dictionary to list of segmentation with segments set

          const segmentTotals = segments.pop();
          segmentation.SegmentItems = segments;
          segmentation.IsDisabled = true; // So it doesn't show up in the dropdown
          segmentation.totals = {
            memberCount: segmentTotals.MemberCount,
            qualified: segmentTotals.Qualified,
            inProgress: segmentTotals.InProgress,
          };
          return segmentation;
        }));
        this.initialSegmentTargets = angular.copy(_.flatMap(this.segmentTargets, (s) => s.SegmentItems));
      });
  }

  selectSegmentationComplete(newSegmentation, index) {
    _.find<any>(this.activityTargetCompletionSegmentations, { Question: newSegmentation.Question }).IsDisabled = true;
    this.segmentTargets[index] = newSegmentation;
    // Get segments for selected segmentation as the present data is useless

    this.targetService.getActivitySegmentTargetForActivity(this.activityGuid, newSegmentation.Guid).then((response) => {
      const segmentData = response.data.ActivitySegmentTargetList;
      const totalsTemp = segmentData.pop();
      const totals = {
        memberCount: totalsTemp.Available,
        inProgress: totalsTemp.InProgress,
        qualified: totalsTemp.Qualified,
      };
      const segments = _.map<ActivityTargetingSegment>(segmentData, (segment) => ({
        Guid: segment.SegmentGuid,
        MemberCount: segment.Available,
        SegmentAnswer: segment.Answer,
        Target: segment.Target,
        Qualified: segment.Qualified,
        InProgress: segment.InProgress,
      }));

      newSegmentation.SegmentItems = segments;
      newSegmentation.totals = totals;
    });
  }

  hasSelectedMaxSegmentations() {
    return this.segmentTargets?.length >= 5;
  }

  // Adds a new possible item to selectedSegmentationCompletes if none are present yet
  addTemplateSegmentationComplete() {
    if (!this.canAddNewSegmentation()) {
      return;
    }

    // When no segmentations have been loaded yet
    if (!this.activityTargetCompletionSegmentations.length && !this.segmentTargets.length) {
      this.initSegmentationData();
    }
    this.segmentTargets.push({
      Question: '',
      SegmentItems: [],
    });
  }

  // Check if the Add Segmentation
  canAddNewSegmentation() {
    return (!this.segmentTargets?.length || this.segmentTargets[this.segmentTargets.length - 1].Question !== '')
      && !this.hasSelectedMaxSegmentations();
  }

  deleteSegmentationComplete(index) {
    // Check against out of bounds
    if (index >= this.segmentTargets.length) {
      this.logger.error('That segmentation could not be found');
      return;
    }
    const segmentation = this.segmentTargets[index];
    // Check if data comes from DB to add confirmation modal
    const segmentGuids = _.map(segmentation.SegmentItems, (s) => s.Guid);
    const fromDB = this.initialSegmentTargets.some((s) => segmentGuids.includes(s.Guid));

    const promise = fromDB
      ? this.$mdDialog.show( // Show confirm dialog
        this.$mdDialog.iscConfirm()
          .title('Delete segmentation targets')
          .text('Are you sure you want to delete the targets for this segmentation? You will have to re-add them manually.')
          .ok('Confirm')
          .cancel('Cancel'))
      : this.$q.resolve(); // If not needed, then we just want the code to run regardless

    promise.then(async () => {
      const question = segmentation.Question;
      if (this.segmentTargets.length > 1) {
        this.segmentTargets.splice(index, 1);
      } else {
        this.segmentTargets[index] = {
          Question: '',
          SegmentItems: [],
        };
      }

      if (question !== '') {
        _.find<any>(this.activityTargetCompletionSegmentations, { Question: question }).IsDisabled = false;
      }
    });
  }

  // Check if there are any changes to ActivitySegmentTargets
  updateHasChanges() {
    // Sort by segmentguid so we can check 1 to 1 if they're the same and also removing null values
    const newAst = _.sortBy(_.flatMap(_.filter(this.segmentTargets, (s) => s), (s) => s.SegmentItems), (s) => s.Guid);
    const oldAst = _.sortBy(this.initialSegmentTargets, (s) => s.Guid);

    // If length isnt the same, segmentations were added
    if (oldAst.length !== newAst.length) {
      this.hasChanges = true;
      return;
    }

    // We only need the guids and targets
    const oldValues = _.map(oldAst, (s) => ({ Guid: s.Guid, Target: s.Target }));
    const newValues = _.map(newAst, (s) => ({ Guid: s.Guid, Target: s.Target }));

    let hasChange = false;
    // If the guids aren't equal, there are different segmentations present
    // If targets aren't equal, they were changed or removed
    _.forEach(oldValues, (s, index) => {
      const newVal = newValues[index];
      if (newVal.Guid !== s.Guid || newVal.Target !== s.Target) {
        hasChange = true;
      }
    });
    this.hasChanges = hasChange;
  }

  updateValidity() {
    this.updateHasChanges();
    const mapped = _.map(_.flatMap(_.filter(this.segmentTargets, (s) => s), (s) => s.SegmentItems), (s) => s.Target);
    // Only uses double equals so it also checks against undefined, null but allows zero
    this.isValid = _.every(mapped, (s) => s != null && s >= 0);
  }

  isSegmentTargetValid() {
    const mapped = _.map(_.flatMap(_.filter(this.segmentTargets, (s) => s), (s) => s.SegmentItems), (s) => s.Target);
    // Only uses double equals so it also checks against undefined, null but allows zero
    return _.every(mapped, (s) => s != null && s >= 0);
  }

  showActivityTargetCompletion(): boolean {
    return this.isActivityTargetCompletionEnabled && !!this.activityInfo;
  }

  updateSamplesAvailable() {
    this.updateSamplesFn().then(() => {
      const watcher = this.$scope.$watch(
        () => this.isUpdatingSamples,
        () => {
          // We are only interested in it being disabled again
          // It gets disabled when targeting has finished running, which may take quite a while
          if (this.isUpdatingSamples) {
            return;
          }
          // Fetch data from backend and deregister watcher
          this.loadPreviousSegmentTargets().finally(() => watcher());
        },
      );
    });
  }

  calculateTotaltargetForSegmentation(segments) {
    return _.sumBy(segments, (segment: any) => segment.Target);
  }

  // Update initialSegmentTargets after saving to avoid dirty forms
  onSave(): void {
    this.initialSegmentTargets = angular.copy(_.flatMap(_.filter(this.segmentTargets, (s) => s), (s) => s.SegmentItems));
    this.updateHasChanges();
  }
}

interface ActivityTargetingSegment {
  Guid: string,
  SegmentAnswer: string,
  MemberCount?: number,
  Target?: number,
  Qualified?: number,
  InProgress?: number,
  DateModified?: DateTime,
}

export interface ActivityInfoForSegmentTargetCompletion {
  activityType: number,
  activityFormat: number,
  activityStatus: number
}


