'use strict';

import { ActivityRecruitmentPartnerService } from '../../../../../core/dataservices/activityrecruitmentpartner.service';
import { ServerConstants } from '../../../../../core/serverconstants';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { ActivityService } from '../../../../../core/dataservices/activity.service';
import { Logger } from '../../../../../blocks/logger/logger';
import { ActivityRecruitmentPartner } from './activityRecruitmentPartner.contracts';
import { JobService } from '../../../../../core/jobs/job.service';
import * as _ from 'lodash';
import { FeatureService } from '../../../../../core/dataservices/feature.service';
import { DateTime } from 'luxon';


const editRecruitmentPartnerDialogTemplate = require('./editRecruitmentPartnerDialog.html');

export class ActivityRecruitmentPartnerController implements ng.IOnInit {
  static $inject = ['$mdDialog', 'logger', '$stateParams', 'activityservice', '$q', 'spinnerservice',
    'serverConstants', 'activityrecruitmentpartnerservice', 'jobservice', '$scope', 'featureservice'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private logger: Logger,
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    private $q: ng.IQService,
    private spinnerservice: SpinnerService,
    private serverConstants: ServerConstants,
    private activityrecruitmentpartnerservice: ActivityRecruitmentPartnerService,
    private jobservice: JobService,
    private $scope,
    private featureService: FeatureService) {
  }

  recruitmentPartnerCallbackTypeConstants;
  recruitmentPartnerLinkTypeConstants;
  activityRecruitmentPartners = [];
  removeLink;
  isVisible = false;
  squareActivity = null;
  wizardStep;
  linkTypes: Array<{ Label, LinkType }>;
  callbackTypes: Array<{ Label, CallbackType }>;
  isReadOnly;
  isPublished;
  surveyPreparationStatus: number;
  isDisabled;
  form;
  isDirty = false;
  saveNotApplicable = false;
  saveCallback = () => this.prepareSurvey();
  customMessage = 'Save your changes to synchronize the background variables with the survey';
  customValidation = () => this.validateNavigation(this);
  resetFormCallback = () => this.resetForm();
  isConsentFeatureEnabled = false;
  recruitmentVariableLabel = 'partner';

  async $onInit() {
    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        this.isConsentFeatureEnabled = isEnabled;
        if (this.isConsentFeatureEnabled) {
          this.recruitmentVariableLabel = 'source';
        }
      });

    this.recruitmentPartnerCallbackTypeConstants = this.serverConstants.recruitmentPartnerCallbackTypeConstants;
    this.recruitmentPartnerLinkTypeConstants = this.serverConstants.recruitmentPartnerLinkTypeConstants;
    this.logger.info(`Activity recruitment ${this.recruitmentVariableLabel}s activated`);
    this.linkTypes = [
      {
        Label: 'Closed',
        LinkType: this.recruitmentPartnerLinkTypeConstants.closed,
      },
      {
        Label: 'Open',
        LinkType: this.recruitmentPartnerLinkTypeConstants.open,
      },
    ];
    this.callbackTypes = [
      {
        Label: 'Pixel',
        CallbackType: this.recruitmentPartnerCallbackTypeConstants.pixel,
      },
      {
        Label: 'Redirect',
        CallbackType: this.recruitmentPartnerCallbackTypeConstants.redirect,
      },
    ];

    this.removeLink = this.wizardStep.linkComponent('recruitment sources', this);

    this.wizardStep.resolveComponent(['specify']).then((resolved) => {
      this.squareActivity = resolved[0].squareActivity;
    });

    // Sometimes this.isPublished is already initialized at this point, sometimes not
    this.saveNotApplicable = this.isPublished;
    this.$scope.$watch('vm.isPublished', () => {
      this.saveNotApplicable = this.isPublished;
    });
    this.$scope.$watch('vm.squareActivity', () => {
      this.isActivityBvarsDisabled();
    });
  }

  $onDestroy = () => this.removeLink();

  $wizardStepLoaded() {
    this.spinnerservice.show('loading');
    this.$q.all([this.getActivityRecruitmentPartners()]).finally(() => {
      this.logger.info('Activity segmentations loaded');
      if (this.activityRecruitmentPartners.length > 0) {
        this.isActivityBvarsDisabled();
      }
      this.spinnerservice.hide('loading');
    });
  }

  $wizardNextLabel = () => {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  };

  $wizardNextDescription = () => {
    let description = '';
    if (this.squareActivity !== undefined && this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  };

  $wizardStepIsCompleted = () =>
    this.isPublished || this.surveyPreparationStatus === this.serverConstants.surveyPreparationStatusConstants.finished || this.$wizardIsValid();

  $wizardIsValid = () => {
    if (this.activityRecruitmentPartners.length > 0) {
      return true;
    }
    return false;
  };

  createActivityRecruitmentPartner = () => {
    const title = `ADD RECRUITMENT  ${this.recruitmentVariableLabel.toUpperCase()}`;
    this.showRecruitmentPartnerDialog(title)
      .then((partners) => {
        this.activityRecruitmentPartners = partners;
        this.isDirty = true;
      });
  };

  getLinkTypeLabel = (linkType) => _.find<{ Label, LinkType }>(this.linkTypes, { LinkType: linkType }).Label;

  getCallbackLabel = (callbackType) => _.find<{ Label, CallbackType }>(this.callbackTypes, { CallbackType: callbackType }).Label;

  updateActivityRecruitmentPartner = (recruitmentPartner) => {
    if (!this.isReadOnly) {
      const title = `EDIT RECRUITMENT ${this.recruitmentVariableLabel.toUpperCase()}`;
      return this.activityservice.getActivityRecruitmentPartner(recruitmentPartner.Guid)
        .then((model) => this.showRecruitmentPartnerDialog(title, model))
        .then((partners) => {
          this.activityRecruitmentPartners = partners;
          this.isDirty = true;
        })
        .then(() => {
          if (this.isPublished && !this.isReadOnly) {
            this.logger.success('Activity segmentations successfully updated');
          }
        });
    }
  };

  deleteActivityRecruitmentPartner(recruitmentPartner) {
    const activityStatus = this.squareActivity.activityStatus;
    if (activityStatus !== 0) {
      this.$mdDialog.show(
        this.$mdDialog.iscConfirm()
          .title(`Recruitment ${this.recruitmentVariableLabel} could not be removed`)
          .text(`Are you sure you want to remove the recruitment ${this.recruitmentVariableLabel}?\n`
            + 'Be aware that when you delete this, links can be broken.')
          .ok('Yes')
          .cancel('No'),
      ).then(() => {
        this.activityrecruitmentpartnerservice.deleteActivityRecruitmentPartner(recruitmentPartner.Guid, this.$stateParams.activityGuid)
          .then((partners) => {
            this.activityRecruitmentPartners = partners;
            this.isDirty = true;
          });
      });
    } else {
      this.activityrecruitmentpartnerservice.deleteActivityRecruitmentPartner(recruitmentPartner.Guid, this.$stateParams.activityGuid)
        .then((partners) => {
          this.activityRecruitmentPartners = partners;
          this.isDirty = true;
        }, (error) => {
          if (error.data.Reason === 'activity_partner_not_deletable') {
            this.$mdDialog.show(
              this.$mdDialog.iscAlert()
                .title(`Recruitment ${this.recruitmentVariableLabel} could not be removed`)
                .text(`You can't delete a recruitment ${this.recruitmentVariableLabel} that is already involved.`)
                .ok('Got it!'),
            );
          }
        });
    }
  }

  // Fix callback when everything is in place at backend
  getActivityRecruitmentPartners() {
    return this.activityservice.getActivityRecruitmentPartners(this.$stateParams.activityGuid).then((response) => {
      const partners = response.data.Partners;
      if (partners) {
        this.activityRecruitmentPartners = partners;
      }
    });
  }

  isActivityBvarsDisabled() {
    this.isDisabled = false;
    if (this.squareActivity) {
      this.isDisabled = this.squareActivity.Detail.Status > 1;
    }
  }

  showRecruitmentPartnerDialog(title, recruitmentPartner: ActivityRecruitmentPartner = undefined) {
    const isCreated = recruitmentPartner !== undefined;

    return this.$mdDialog.show({
      controller: 'EditRecruitmentPartnerDialogController',
      controllerAs: 'vm',
      templateUrl: editRecruitmentPartnerDialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        title,
        selectedActivityRecruitmentPartner: isCreated ? recruitmentPartner : undefined,
        isCreated,
        linkTypes: this.linkTypes,
        callbackTypes: this.callbackTypes,
        isDisabled: this.isDisabled,
        useSubsource: isCreated ? recruitmentPartner.UseSubsource : false,
      },
      multiple: true,
    });
  }

  async $wizardBeforeNext() {
    // Always do this because the user could have made changes last time and then clicked on leave without syncing
    // Then this time isDirty could be false but we still want to sync
    await this.prepareSurvey();
  }

  async prepareSurvey() {
    const jobId = this.activityservice.prepareSurvey(this.$stateParams.activityGuid);
    await this.jobservice.showJobProgressDialog(jobId, 'Transferring background variables.');
    this.isDirty = false;
  }

  validateNavigation(root) {
    if (!root) {
      root = this;
    }
    return {
      canNavigate: true,
      isDirty: this.isDirty,
    };
  }

  getConsent(activityRecruitmentPartner, consent) {
    const consentObj = activityRecruitmentPartner.Consents.find((c): boolean => c.Consent === consent);
    if (consentObj) {
      const date = DateTime.fromISO(consentObj.ValidityExpirationDate, { zone: 'utc' });
      return `${consentObj.IsGiven ? 'Yes ' : 'No'} ${date.isValid ? `(${date.toFormat('dd/MM/yyyy')})` : ''}`;
    }
    return 'No';
  }

  private resetForm() {
    this.isDirty = false;
  }

  getRecruitmentPartnerConsentValidityDateExpiration(activityRecruitmentPartner) {
    const date = activityRecruitmentPartner.Consents[0].ValidityExpirationDate;
    return date ? DateTime.fromISO(date).toFormat('MMMM dd, yyyy') : 'Never';
  }
}
