'use strict';


const template = require('./isc-date-range.html');

require('./_isc-date-range.scss');

export const iscDateRangeComponent: ng.IComponentOptions = {
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    startDate: '=',
    endDate: '=',
    minStartDate: '<',
    maxEndDate: '<',
    startDateLabel: '@',
    endDateLabel: '@',
    onStartDateChange: '&',
    onEndDateChange: '&',
  },
};
