'use strict';

/* eslint-disable max-classes-per-file */
import { DateTime } from 'luxon';
import { IActivityFilterData } from './activity.contract';

export interface IConversationDownloadParams extends IActivityFilterData {
  squareParticipantGuid: string,
  activityGuid: string,
}

export interface IConfigurationForDiscussionExportType {
  stimuliDownloadAvailable: boolean,
  textDownloadAvailable: boolean,
}

export class QualConversationExportParams implements IActivityFilterData, IConfigurationForDiscussionExportType {
  applyQualFilter(config?: IActivityFilterData) {
    if (config) {
      this.Keyword = config.Keyword;
      this.StartDate = config.StartDate || this.StartDate;
      this.EndDate = config.EndDate || this.EndDate;
      this.Statuses = config.Statuses || this.Statuses;
      this.SelectedThemes = config.SelectedThemes || this.SelectedThemes;
      this.SelectedMembers = config.SelectedMembers || this.SelectedMembers;
      this.SelectedSegments = config.SelectedSegments || this.SelectedSegments;
      this.SelectedSegmentsOption = config.SelectedSegmentsOption || this.SelectedSegmentsOption;
      this.SelectedRatings = config.SelectedRatings || this.SelectedRatings;
      this.SelectedModerationStatusOptions = config.SelectedModerationStatusOptions || this.SelectedModerationStatusOptions;
      this.SelectedAnnotationsOptions = config.SelectedAnnotationsOptions || this.SelectedAnnotationsOptions;
      this.SelectedStimuliOptions = config.SelectedStimuliOptions || this.SelectedStimuliOptions;
      this.TagsSelected = config.TagsSelected || this.TagsSelected;
      this.HashtagsSelected = config.HashtagsSelected || this.HashtagsSelected;
      this.SelectedProbeQuestions = config.SelectedProbeQuestions || this.SelectedProbeQuestions;
      this.SortOption = config.SortOption || this.SortOption;
    }
  }

  stimuliDownloadAvailable: boolean;
  textDownloadAvailable: boolean;
  Keyword: string;
  StartDate: DateTime;
  EndDate: DateTime;
  Statuses?: string[];
  HasNoAnnotation?: boolean;
  HasNote?: boolean;
  HasHighlight?: boolean;
  SelectedThemes?: string[];
  SelectedMembers?: string[];
  SelectedSegments?: string[];
  SelectedProbeQuestions?: string[];
  SelectedSegmentsOption?: number;
  SelectedRatings?: number[];
  SelectedModerationStatusOptions?: number[];
  SelectedAnnotationsOptions?: number[];
  SelectedStimuliOptions?: number[];
  TagsSelected?: string[];
  HashtagsSelected?: string[];
  JumpToFirstNew?: boolean;
  ActivityType?: number;
  activityGuid: string;
  SortOption: number;
  isModeratorCurationEnabled?: boolean;
}

export class UserQualConversationsExportParams implements IConfigurationForDiscussionExportType {
  stimuliDownloadAvailable: boolean;
  textDownloadAvailable: boolean;
  squareParticipantGuid: string;
  squareParticipantRole: number;
}

export class ConversationsExportParams {
  constructor(config?: ConversationsExportParams) {
    if (config) {
      this.conversationGuid = config.conversationGuid || this.conversationGuid;
      this.keyword = config.keyword || this.keyword;
      this.sortOption = config.sortOption || this.sortOption;
    }
  }

  conversationGuid: string;
  keyword: string;
  sortOption: number;
}

export class HealthExportParams {
  startDate: DateTime;
  endDate: DateTime;
  selectedRoles: number[];
  selectedStatuses: number[];
  selectedSegments?: string[];
  selectedSegmentsOption?: number;
}

export class ManuallyHandledRewardsExportParams {
  startDate: DateTime;
  endDate: DateTime;
  keyword: string;
  clients: string[];
  statuses: number[];
  squares: string[];
  jobIds: string[];
  squareParticipants: number[];
  countries: string[];
  rewardPartners: number[];
}

export class MembersExportParams {
  keyword: string;
  startDate: DateTime;
  endDate: DateTime;
  selectedRoles: number[];
  selectedStatuses: number[];
  selectedSegments?: string[];
  selectedSegmentsOption?: number;
  excludeParticipantsInvitedSinceWeeks?: number;
}

export interface IExportDataOptionParamsBase {
  showSegmentValuesInOneColumnOption?: boolean,
}

export interface IExportDataOptionParams extends IExportDataOptionParamsBase {
  showIncludeKeySegmentationsOnlyOption?: boolean,
  showIncludeActiveActivitiesOnlyOption?: boolean,
  showExportOnlyFilteredDataOption?: boolean,
  showExportPiiDataOption?: boolean,
}

export class ConfigurationForExportType {
  constructor(config?: ConfigurationForExportType) {
    if (config) {
      this.title = config.title || this.title;
      this.downloadUrl = config.downloadUrl || this.downloadUrl;
      this.validateDownloadParams = config.validateDownloadParams || this.validateDownloadParams;
      this.options = config.options || this.options;
      this.showDownloadButton = config.showDownloadButton || this.showDownloadButton;
    }
  }
  title?: string;
  downloadUrl: string;
  validateDownloadParams: (params) => boolean;
  options: IExportDataOptionParams;
  showDownloadButton?: (params) => boolean = () => true;
}
