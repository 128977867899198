'use strict';

export class SelectedOrphanFactory {
  Name: string = '';
  Guid: string = '';
}

angular
  .module('insitesApp.core')
  .service('selectedOrphanFactory', SelectedOrphanFactory);
