'use strict';

import './dropdown.module';

import { ActivitySelectionController } from './activityselection.controller';
import { ProgramSelectionController } from './programselection.controller';
import { SquareOrphanSelectionController } from './squareorphanselection.controller';
import { SearchSelectionController } from './searchselection.controller';
import { EnvironmentSelectionController } from './environmentselection.controller';
import { ClientSelectionController } from './clientselection.controller';
import { Dropdown } from './dropdown.directive';

angular
  .module('insitesApp.layout.breadcrumb')
  .controller('SquareOrphanSelectionController', SquareOrphanSelectionController)
  .controller('SearchSelectionController', SearchSelectionController)
  .controller('EnvironmentSelectionController', EnvironmentSelectionController)
  .controller('ClientSelectionController', ClientSelectionController)
  .controller('ProgramSelectionController', ProgramSelectionController)
  .controller('ActivitySelectionController', ActivitySelectionController)
  .directive('dropdown', () => new Dropdown());
