'use strict';

import * as _ from 'lodash';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
import { ConstantsFactory } from '../../../../core/constants.factory';
import { ServerConstants } from '../../../../core/serverconstants';

export class SuspendMembersController {
  static $inject = ['$mdDialog', 'serverConstants', 'constantsfactory', '$scope', 'featureservice', 'model', 'suspendReasons', 'displayText'];

  needsComment = false;


  get canSave() {// Has a valid suspendReason (zero allowed) && has custom input if required
    return (!!this.model.SuspendReason || this.model.SuspendReason === 0) && (!this.needsComment || this.model.Comment);
  }

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private serverConstants: ServerConstants,
    private constantsFactory: ConstantsFactory,
    private $scope: ng.IScope,
    private featureService: FeatureService,
    public model,
    public suspendReasons,
    public displayText,
  ) {

    if (!suspendReasons) {
      this.loadReasons();
    }
    if (!this.model) {
      this.model = {};
    }

    this.$scope.$watch('vm.model.SuspendReason', () => {
      const reason = this.suspendReasons.find((s) => s.index === this.model?.SuspendReason);
      if (reason) {
        this.needsComment = reason.needsComment;
      }
    });
  }

  private loadReasons() {
    this.suspendReasons =
      _.compact(
        Object
          .values(this.serverConstants.suspendReasonConstants)
          .map((reason) => {
            const labelData = this.constantsFactory.getSuspendReasonsForSelection(reason);
            if (labelData) {
              return { index: reason, label: labelData.label, needsComment: labelData.needsComment };
            }
          }),
      );
    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        if (!isEnabled) {
          this.suspendReasons = this.suspendReasons.filter((s) => s.index !== 11);
        }
      });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  save() {
    if (!this.needsComment) {
      this.model.Comment = null; // Clears if not needed
    }
    this.$mdDialog.hide(this.model);
  }
}
