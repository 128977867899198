'use strict';

require('./editSegmentationDialog.scss');
import * as _ from 'lodash';
import { SegmentationService } from '../../../../core/dataservices/segmentation.service';
import {
  ISurveyActivityResponse, IQuestionTree, ISurveyData,
  ISurveyQuestion, ISegment, ISurveyItem, ISegmentAnswer,
  ISaveSegmentationResponse, ISurveyAnswerDetails, ISurveyAnswer, ISegmentationFormItem,
} from '../../../../core/dataservices/dataservice.contracts';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { Logger } from '../../../../blocks/logger/logger';
import { ActivityService } from '../../../../core/dataservices/activity.service';
import { JobService } from '../../../../core/jobs/job.service';
import { ServerConstants } from '../../../../core/serverconstants';

export class EditSegmentationDialogController {
  static $inject = [
    '$mdDialog',
    '$scope',
    'segmentationGuid',
    'segmentationName',
    'segmentationType',
    'membersInSegmentation',
    'surveyActivities',
    'segmentationservice',
    'spinnerservice',
    'logger',
    'activityservice',
    'jobservice',
    'serverConstants',
    '$q',
  ];

  forms: ng.IFormController[];

  processingData: boolean;
  isSaving: boolean;
  isSyncingSelectedActivity: boolean;
  isSegmentationEditMode: boolean;
  isViewOnly: boolean;
  isNewSegmentation: boolean;
  populateSegmentsViaSurveyData: boolean;
  segmentationWasEdited = false;

  newSegmentsCount = 0;
  emptyGuid: string;
  segmentationCounter = 0;

  alreadyLinkedTree: ISurveyData[] = [];

  selectedActivityGuid: string;
  selectedQuestionGuid: string;

  txtBulkCreateSegments: string;

  questionOptions: IQuestionTree[];

  segmentations: ISegmentationFormItem[] = [];

  questionNodeLayout = `
    <div>
      <span ivh-treeview-toggle>
        <span ng-click="node.onClick()" ivh-treeview-twistie></span>
      </span>
      <span class="ivh-treeview-node-label" ivh-treeview-toggle ng-bind-html="trvw.label(node)"></span>
      <div ivh-treeview-children></div>
    </div>
  `;

  questionTreeOptionsSegmentation = {
    twistieCollapsedTpl: '✓',
    twistieExpandedTpl: '✓',
    twistieLeafTpl: ' ',
    nodeTpl: this.questionNodeLayout,
  };

  questionTreeOptionsSegment = {
    twistieCollapsedTpl: '✓',
    twistieExpandedTpl: '✓',
    twistieLeafTpl: 'x',
    nodeTpl: this.questionNodeLayout,
  };

  constructor(
    private $mdDialog,
    private $scope: ng.IScope,
    protected segmentationGuid: string,
    protected segmentationName: string,
    protected segmentationType: number,
    protected membersInSegmentation: number,
    protected surveyActivities: ISurveyActivityResponse[],
    private segmentationservice: SegmentationService,
    private spinnerservice: SpinnerService,
    private logger: Logger,
    private activityservice: ActivityService,
    private jobService: JobService,
    private serverConstants: ServerConstants,
    private $q: ng.IQService,
  ) {
    this.isViewOnly =
      (this.segmentationType === this.serverConstants.segmentationTypeConstants.platformBehavior
        || this.segmentationType === this.serverConstants.segmentationTypeConstants.activityBehavior
        || this.segmentationType === this.serverConstants.segmentationTypeConstants.profile);

    this.isNewSegmentation = this.segmentationGuid === null;
    this.isSegmentationEditMode = this.isNewSegmentation;

    this.emptyGuid = this.serverConstants.validationConstants.emptyGuid;
    this.selectedActivityGuid = this.serverConstants.validationConstants.emptyGuid;
    this.selectedQuestionGuid = this.serverConstants.validationConstants.emptyGuid;

    this.unshiftEmptyOptionToSurveyActivities();
    this.segmentations.push({
      segmentationGuid: this.segmentationGuid,
      segmentationName: this.segmentationName,
      initialSegmentationName: angular.copy(this.segmentationName),
      segments: [],
      answerOptions: [],
      isSegmentationEditMode: this.isSegmentationEditMode,
      isPlaceHolder: !!this.segmentationGuid,
      formId: this.segmentationCounter,
    });
    this.segmentationCounter++;

    if (this.isNewSegmentation) {
      this.unshiftEmptyOptionToQuestionOptions();
    } else {
      segmentationservice.getQuestionsForSegmentation(segmentationGuid).then((questions: IQuestionTree[]) => {
        this.fillAlreadyLinkedTree(...questions);

        segmentationservice.getSegmentsForSegmentation(segmentationGuid).then((segments: ISegment[]) => {
          segments.forEach((seg) => seg.initialName = seg.name);
          const firstSegmentation = this.getFirstSegmentation();
          firstSegmentation.segments = this.isViewOnly ? segments : _.orderBy(segments, (segment: ISegment) => segment.id);
          this.selectFirstLinkedActivity();
        });
      });
    }

    this.$scope.$watch(() => this.selectedActivityGuid, async (newValue: string) => {
      await this.loadQuestionsForActivity(newValue, false);
    });

    this.$scope.$watch(() => this.selectedQuestionGuid, async (newValue: string) => {
      await this.loadAnswersForQuestion(newValue, false);
    });

    this.$scope.$watch(() => this.populateSegmentsViaSurveyData, async (newValue: boolean) => {
      if (!newValue) {
        this.segmentations = [this.segmentations[0]];
        this.selectedQuestionGuid = this.emptyGuid;
        this.selectedActivityGuid = this.emptyGuid;
      }
    });
  }

  // The first element from the list is shown in ui if populateSegmentsViaSurveyData is not checked
  // This function is used when there is a need to update properties on the first element in this.segmentations
  getFirstSegmentation(): ISegmentationFormItem {
    return this.segmentations[0];
  }

  private async loadQuestionsForActivity(activityGuid: string, renewData: boolean): Promise<void> {
    const selectedActivity = _.find(this.surveyActivities, (activity) => activity.activityGuid === activityGuid);

    if (!selectedActivity) {
      return;
    }

    if (!selectedActivity.surveyQuestions || renewData) {
      selectedActivity.surveyQuestions = await this.segmentationservice.getQuestionsForActivity(selectedActivity.activityGuid, false);
    }

    this.questionOptions = selectedActivity.surveyQuestions;
    this.unshiftEmptyOptionToQuestionOptions();

    this.selectFirstLinkedQuestion();
  }

  private async loadAnswersForQuestion(questionGuid: string, renewData: boolean): Promise<void> {
    const firstSegmentation = this.getFirstSegmentation();

    if (questionGuid === this.emptyGuid) {
      firstSegmentation.answerOptions = [];
    }

    const selectedQuestion = _.filter(this.questionOptions, (question) => question.GuidValue === questionGuid)[0];

    if (!selectedQuestion) {
      return;
    }

    if (!selectedQuestion.Answers || renewData) {
      selectedQuestion.Answers = await this.segmentationservice.getAnswers(selectedQuestion.GuidValue);
    }

    firstSegmentation.answerOptions = selectedQuestion.Answers;
    if (selectedQuestion.Answers.length > 0) {
      _.forEach(firstSegmentation.segments, (segment: ISegment) => {
        const linkedAnswer = _.find(firstSegmentation.answerOptions, (answerOption) =>
          _.some(segment.surveyAnswerGuids, (answerGuid) => answerOption.GuidValue === answerGuid));

        if (linkedAnswer) {
          segment.answerLinkGuid = linkedAnswer.GuidValue;
        } else {
          segment.answerLinkGuid = this.emptyGuid;
        }
        segment.initialAnswerLinkGuid = segment.answerLinkGuid;
      });
    }

    this.unshiftEmptyOptionToAnswerOptions();
  }

  private fillAlreadyLinkedTree(...treeItems: IQuestionTree[]): void {
    _.forEach(treeItems, (question) => {
      const existingActivityNode = _.find(this.alreadyLinkedTree, (node) => node.label === question.ActivityName);

      if (existingActivityNode) {
        if (!_.some(existingActivityNode.children, (child) => child.label === question.Label)) {
          existingActivityNode.children.push({
            label: question.Label,
            questionLabel: question.QuestionLabel,
            __ivhTreeviewExpanded: true,
          } as ISurveyQuestion);
        }
      } else {
        this.alreadyLinkedTree.push({
          label: question.ActivityName,
          __ivhTreeviewExpanded: true,
          children: [{
            label: question.Label,
            questionLabel: question.QuestionLabel,
            __ivhTreeviewExpanded: true,
          } as ISurveyQuestion],
        } as ISurveyData);
      }
    });
  }

  async syncCurrentActivity(): Promise<void> {
    this.isSyncingSelectedActivity = true;

    const selectedActivity = [this.selectedActivityGuid];
    const jobid = await this.activityservice.syncActivitiesQuestionnaire(selectedActivity);
    await this.jobService.showJobProgressDialog(jobid, 'Syncing 1 activity');

    await this.loadQuestionsForActivity(this.selectedActivityGuid, true);
    await this.loadAnswersForQuestion(this.selectedQuestionGuid, true);

    this.isSyncingSelectedActivity = false;
  }

  async processData() {
    if (!this.segmentations.some((segmentation) => segmentation.linkedQuestionGuid === this.selectedQuestionGuid)) {
      this.processingData = true;
      const selectedQuestion = _.filter(this.questionOptions, (question) => question.GuidValue === this.selectedQuestionGuid)[0];
      const answerOptions = await this.segmentationservice.getAnswers(selectedQuestion.GuidValue);
      const segments: ISegment[] = [];
      _.forEach(answerOptions, (a) => {
        segments.push(this.createSegment(segments, a.QuestionLabel, false, a.GuidValue));
      });
      const segmentation = {
        segmentationName: selectedQuestion.Label,
        initialSegmentationName: angular.copy(selectedQuestion.Label),
        segments,
        answerOptions,
        linkedActivityGuid: this.selectedActivityGuid,
        linkedQuestionGuid: this.selectedQuestionGuid,
        isSegmentationEditMode: false,
        isPlaceHolder: false,
        formId: this.segmentationCounter,
      };
      this.segmentationCounter++;
      this.segmentations.push(segmentation);
      this.processingData = false;
    }
    setTimeout(() => this.setFormsDirty(), 1000);
  }

  startEditSegmentation(segmentation: ISegmentationFormItem): void {
    segmentation.isSegmentationEditMode = true;
  }

  async startEditSegment(segment: ISegment) {
    await this.buildQuestionTreeForSegment(segment);
    segment.isEditMode = true;
  }

  saveEditSegmentation(segmentation: ISegmentationFormItem): void {
    this.hideValidation(segmentation);
    if (segmentation.segmentationName !== undefined) {
      segmentation.isSegmentationEditMode = false;
      this.segmentationWasEdited = true;
    }
  }

  saveEditSegment(segmentation: ISegmentationFormItem, segment): void {
    this.hideValidation(segmentation);
    if (segment.name !== undefined) {
      segment.isEditMode = false;
      this.segmentationWasEdited = true;
    }
  }

  addSegment(segmentation: ISegmentationFormItem, segmentName: string = '', isEditMode: boolean = true, answerLinkGuid: string = this.emptyGuid): void {
    const firstSegmentation = this.getFirstSegmentation();
    if (firstSegmentation.segments === undefined) {
      firstSegmentation.segments = [];
    }

    const segment = this.createSegment(segmentation.segments, segmentName, isEditMode, answerLinkGuid);
    segmentation.segments.push(segment);
    this.segmentationWasEdited = true;
  }

  createSegment(segments: ISegment[],
    segmentName: string = '',
    isEditMode: boolean = true,
    answerLinkGuid: string = this.emptyGuid,
    initialAnswerLinkGuid: string = this.emptyGuid): ISegment {
    const currentHighestPrecode = _.max(_.map(segments, (segment: ISegment) => parseInt(segment.precode, 10)));
    this.newSegmentsCount++;
    const segment: ISegment = {
      // Don't give the segment a real guid so it's clear this segment doesn't exist yet
      id: null,
      guid: null,
      name: segmentName,
      initialName: segmentName,
      memberCount: 0,
      isEditMode,
      precode: (!isNaN(currentHighestPrecode) ? currentHighestPrecode + 1 : 1).toString(),
      surveyAnswerGuids: [],
      surveyAnswerDetails: [],
      answerLinkGuid,
      initialAnswerLinkGuid,
      questionTree: [],
    };
    return segment;
  }

  deleteSegment(segmentation: ISegmentationFormItem, segmentToDelete: ISegment): void {
    _.remove(segmentation.segments, (segment) => segment === segmentToDelete);
    this.segmentationWasEdited = true;
  }

  hasDuplicateSegments(segmentation: ISegmentationFormItem): boolean {
    if (!segmentation.segments) {
      return false;
    }
    const hasDuplicateSegments = _.some(segmentation.segments, (segment: ISegment) =>
      _.some(segmentation.segments, (c: ISegment) => c !== segment && c.name === segment.name),
    );
    return hasDuplicateSegments;
  }

  hideValidation(segmentation: ISegmentationFormItem): void {
    segmentation.validationError = undefined;
  }

  isEditMode(segmentation: ISegmentationFormItem) {
    if (!segmentation.segments) {
      return false;
    }
    const isEditMode = segmentation.isSegmentationEditMode || _.some(segmentation.segments, (segment: ISegment) => segment.isEditMode);
    return isEditMode;
  }

  // Saves the currently selected links between segments and answers
  saveLinkSelection(): void {
    this.isSaving = true;
    const firstSegmentation = this.getFirstSegmentation();
    const links = _.map(firstSegmentation.segments, (seg: ISegment) => ({
      segmentGuid: seg.guid,
      answerGuid: seg.answerLinkGuid,
    } as ISegmentAnswer));

    const savePromise = this.segmentationservice
      .linkSegmentAnswers(this.selectedQuestionGuid, links)
      .then(
        () => {
          _.forEach(firstSegmentation.segments, (seg: ISegment) => {
            if (!seg.surveyAnswerGuids) {
              seg.surveyAnswerGuids = [];
            }
            if (seg.answerLinkGuid !== this.emptyGuid && seg.surveyAnswerGuids.indexOf(seg.answerLinkGuid) === -1) {
              seg.surveyAnswerGuids.push(seg.answerLinkGuid);
            }
          });
          this.updateAlreadyLinkedTree();

          _.forEach(firstSegmentation.segments, (segment: ISegment) => {
            segment.isEditMode = false;
            segment.surveyAnswerDetails = [];
            segment.questionTree = [];
          });

          this.logger.success('Links saved successfully');
        }, (error) => {
          this.logger.error(error.data.Reason);
        },
      )
      .finally(() => {
        this.isSaving = false;
      });

    this.spinnerservice.showFor('loading', savePromise);
  }

  deleteAnswerForSegment(segment: ISegment, answerGuid: string): void {
    if (this.isSaving) {
      return;
    }

    this.isSaving = true;

    const savePromise = this.segmentationservice.deleteAnswerForSegment(segment.guid, answerGuid).then(() => {
      // Update segment.surveyAnswerGuids
      const indexAnswerGuid = segment.surveyAnswerGuids.indexOf(answerGuid);
      segment.surveyAnswerGuids.splice(indexAnswerGuid, 1);

      // Empty the dropdown if needed
      if (segment.answerLinkGuid === answerGuid) {
        segment.answerLinkGuid = this.emptyGuid;
      }

      // Update segment.surveyAnswerDetails
      const answerDetails = _.find(segment.surveyAnswerDetails, (answer: ISurveyAnswerDetails) => answer.answerGuid === answerGuid);
      const indexAnswerDetails = segment.surveyAnswerDetails.indexOf(answerDetails);
      segment.surveyAnswerDetails.splice(indexAnswerDetails, 1);

      // Rebuild QuestionTree for segment
      segment.questionTree = [];
      this.buildQuestionTreeForSegment(segment);

      // Update alreadyLinkedTree
      this.segmentationservice.getQuestionsForSegmentation(this.segmentationGuid).then((questions: IQuestionTree[]) => {
        this.alreadyLinkedTree = [];
        this.fillAlreadyLinkedTree(...questions);
      });

      this.logger.success('Answer unlinked successfully');
    }, (error) => {
      this.logger.error(error.data.Reason);
    }).finally(() => {
      this.isSaving = false;
    });

    this.spinnerservice.showFor('loading', savePromise);
  }

  // Saves both the segmentation definition and possibly the currently selected links between segments and answers
  save(closeModal: boolean): void {
    if (!this.areFormsValid()) {
      return;
    }
    this.isSaving = true;
    const createPromises = [];
    _.forEach(this.segmentations, (segmentation) => {
      if (this.isValid(segmentation)) {
        const saveSegmentationRequestData = {
          Guid: segmentation.segmentationGuid,
          Name: segmentation.segmentationName,
          Type: segmentation.segmentationType,
          Segments: _.map(segmentation.segments, (segment) => ({
            Guid: segment.guid,
            Name: segment.name,
            Precode: segment.precode,
          })),
        };

        const createPromise = this.segmentationservice
          .saveSegmentation(saveSegmentationRequestData)
          .then(async (data: ISaveSegmentationResponse) => {
            this.fillGuidsFromSaveSegmentationResponse(segmentation, data);
            if (this.isNewSegmentation) {
              this.logger.success('Segmentation definition saved successfully');
              if (this.populateSegmentsViaSurveyData) {
                await this.linkSegmentAnswers(segmentation, closeModal);
                if (!closeModal) {
                  const questions = await this.segmentationservice.getQuestionsForSegmentation(segmentation.segmentationGuid);
                  this.fillAlreadyLinkedTree(...questions);
                  _.forEach(segmentation.segments, (segment) => segment.initialAnswerLinkGuid = segment.answerLinkGuid);
                }
              } else {
                this.selectedQuestionGuid = this.emptyGuid;
                this.selectedActivityGuid = this.emptyGuid;
              }
              return;
            }
            await this.linkSegmentAnswers(segmentation, closeModal);
          }, (exception) => {
            segmentation.validationError = _.join(
              _.map(exception.data.ValidationErrors, (validationError): any => (validationError.ErrorMessage)),
              '<br/>',
            );
          });
        createPromises.push(createPromise);
      }
    });
    this.spinnerservice.showFor('loading', createPromises);
    this.$q.all(createPromises).then(() => {
      this.isSaving = false;
      this.isNewSegmentation = false;
      // we need to clean out the placeholder segmentation that was added when creating the first segmentation and retain the link selection if we stay on the modal
      if (!closeModal) {
        this.segmentations.splice(0, 1);
        this.saveLinkSelection();
      }
      if (closeModal) {
        this.$mdDialog.hide();
        this.logger.success('Segmentation definition saved successfully');
      }
    }, (err) => this.logger.error(`Segmentation could not be saved successfully: ${err.data.Reason}`));
  }

  async linkSegmentAnswers(segmentation: ISegmentationFormItem, closeModal: boolean) {
    const links = _.map(segmentation.segments, (seg) => ({
      segmentGuid: seg.guid,
      answerGuid: seg.answerLinkGuid,
    } as ISegmentAnswer));

    await this.segmentationservice
      .linkSegmentAnswers(this.selectedQuestionGuid, links)
      .then(
        () => {
          if (closeModal) {
            this.$mdDialog.hide();
          }
          this.logger.success('Segmentation definition and links saved successfully');
        }, () => {
          this.logger.error('Segmentation definition saved successfully, but the saving of links failed');
        },
      );
  }

  // After a segmentation and its segments are saved, they will be assigned a guid from the backend if they were new
  // So we need to fill the empty guids
  private fillGuidsFromSaveSegmentationResponse(segmentation: ISegmentationFormItem, response: ISaveSegmentationResponse): void {
    segmentation.segmentationGuid = segmentation.segmentationGuid || response.segmentationGuid;

    _.forEach(segmentation.segments, (segment) => {
      if (!segment.guid) {
        segment.guid = response.segmentGuidsForName[segment.name];
      }
    });
  }

  hasUnsavedSegment(segmentation: ISegmentationFormItem): boolean {
    return _.some(segmentation.segments, (segment) => !segment.guid);
  }

  isValid(segmentation: ISegmentationFormItem): boolean {
    return segmentation.segmentationName &&
      segmentation.segments &&
      segmentation.segments.length > 0 &&
      !this.hasDuplicateSegments(segmentation);
  }

  // If at least one segment has a linked answer, add it to the alreadyLinkedTree. Else remove it.
  private updateAlreadyLinkedTree(): void {
    const question = _.find(this.questionOptions, (questionOption: IQuestionTree) => questionOption.GuidValue === this.selectedQuestionGuid);
    const firstSegmentation = this.getFirstSegmentation();
    if (_.some(firstSegmentation.segments, (segment: ISegment) => segment.answerLinkGuid !== this.emptyGuid)) {
      if (question) {
        this.fillAlreadyLinkedTree(question);
      }
    } else {
      const alreadyLinkedActivity = _.find(this.alreadyLinkedTree, (activityNode: ISurveyData) => activityNode.label === question.ActivityName);
      _.remove(alreadyLinkedActivity.children, (questionNode: ISurveyQuestion) => questionNode.label === question.Label);

      if (alreadyLinkedActivity.children.length === 0) {
        _.remove(this.alreadyLinkedTree, (activityNode: ISurveyData) => activityNode.label === alreadyLinkedActivity.label);
      }
    }
  }

  private unshiftEmptyOptionToSurveyActivities(): void {
    if (!this.surveyActivities) {
      this.surveyActivities = [];
    }
    if (!_.some(this.surveyActivities, (surveyActivity: ISurveyActivityResponse) => surveyActivity.activityGuid === this.emptyGuid)) {
      const emptySurveyActivity = {
        activityGuid: this.emptyGuid,
        displayLabel: '<< Select >>',
      } as ISurveyActivityResponse;
      this.surveyActivities.unshift(emptySurveyActivity);
    }
  }

  private unshiftEmptyOptionToQuestionOptions(): void {
    if (!this.questionOptions) {
      this.questionOptions = [];
    }

    if (!_.some(this.questionOptions, (questionOption: IQuestionTree) => questionOption.GuidValue === this.emptyGuid)) {
      const emptyQuestionOption = {
        GuidValue: this.emptyGuid,
        Label: '<< Select >>',
      } as IQuestionTree;
      this.questionOptions.unshift(emptyQuestionOption);
    }
  }

  private unshiftEmptyOptionToAnswerOptions(): void {
    const firstSegmentation = this.getFirstSegmentation();
    if (!firstSegmentation.answerOptions) {
      firstSegmentation.answerOptions = [];
    }

    if (!_.some(firstSegmentation.answerOptions, (answerOption: ISurveyItem) => answerOption.GuidValue === this.emptyGuid)) {
      const emptyAnswerOption = {
        GuidValue: this.emptyGuid,
        Label: '<< Not linked >>',
      } as IQuestionTree;
      firstSegmentation.answerOptions.unshift(emptyAnswerOption);
    }
  }

  private selectFirstLinkedActivity(): void {
    const firstLinkedActivity = _.find(this.surveyActivities, (surveyActivity: ISurveyActivityResponse) =>
      _.some(this.alreadyLinkedTree, (item: ISurveyData) => item.label === surveyActivity.activityName && surveyActivity.activityGuid !== this.emptyGuid));

    this.selectedActivityGuid = firstLinkedActivity ? firstLinkedActivity.activityGuid : this.emptyGuid;
  }

  private selectFirstLinkedQuestion(): void {
    const firstLinkedQuestion = _.find(this.questionOptions, (questionOption: IQuestionTree) =>
      _.some(this.alreadyLinkedTree, (item: ISurveyData) =>
        _.some(item.children, (child: ISurveyQuestion) => child.label === questionOption.Label && questionOption.GuidValue !== this.emptyGuid)));

    this.selectedQuestionGuid = firstLinkedQuestion ? firstLinkedQuestion.GuidValue : this.emptyGuid;
  }

  private async buildQuestionTreeForSegment(segment: ISegment) {
    // If guid is empty, segment wasn't properly saved
    if (segment.surveyAnswerGuids.length === 0 || this.alreadyLinkedTree.length === 0 || !segment.guid) {
      return;
    }

    if (!segment.surveyAnswerDetails || segment.surveyAnswerGuids.length !== segment.surveyAnswerDetails.length) {
      segment.surveyAnswerDetails = await this.segmentationservice.getAnswersForSegment(segment.guid);
    }

    // Start from the question tree of the general segmentation since it already contains all activities/questions we possibly need for a segment
    const questionTree = angular.copy(this.alreadyLinkedTree);

    for (let activityNodeIndex = 0; activityNodeIndex < questionTree.length; activityNodeIndex++) {
      const activityNode = questionTree[activityNodeIndex];

      for (let questionNodeIndex = 0; questionNodeIndex < activityNode.children.length; questionNodeIndex++) {
        const questionNode = activityNode.children[questionNodeIndex];

        const linkedAnswerForQuestion = _.find(segment.surveyAnswerDetails, (answer: ISurveyAnswerDetails) => questionNode.questionLabel === answer.questionLabel);
        if (linkedAnswerForQuestion) {
          // An answer was found for the question so we add it to the question node
          questionNode.children = [{
            label: linkedAnswerForQuestion.answerLabel,
            onClick: () => this.deleteAnswerForSegment(segment, linkedAnswerForQuestion.answerGuid),
          } as ISurveyAnswer];
        } else {
          // No answer was found for the question so we remove the question node
          const indexQuestion = activityNode.children.indexOf(questionNode);
          activityNode.children.splice(indexQuestion, 1);
          questionNodeIndex--;

          // If we just removed the last question node of an activity node, we remove the activity node
          if (activityNode.children.length === 0) {
            const indexActivity = questionTree.indexOf(activityNode);
            questionTree.splice(indexActivity, 1);
            activityNodeIndex--;
          }
        }
      }
    }

    segment.questionTree = questionTree;
  }

  unlinkSelectedQuestion(): void {
    const firstSegmentation = this.getFirstSegmentation();
    _.forEach(firstSegmentation.segments, (segment: ISegment) => segment.answerLinkGuid = this.emptyGuid);
  }

  bulkAddSegments(): void {
    const segmentNames = this.txtBulkCreateSegments.split(/\r\n|\r|\n/g);

    _.forEach(segmentNames, (segmentName: string) => {
      if (segmentName && segmentName !== '') {
        this.addSegment(this.segmentations[0], segmentName, false);
      }
    });

    this.txtBulkCreateSegments = '';
  }

  cancel(): void {
    this.$mdDialog.cancel();
  }

  isSaveDisabled() {
    let isSaveDisabled = this.populateSegmentsViaSurveyData && this.segmentations.length === 1;
    _.forEach(this.segmentations, (segmentation) => {
      // if populateSegmentsViaSurveyData is checked we skip the first element
      // because it is displayed in ui only when populateSegmentsViaSurveyData is false
      // However, sometimes this default empty segmentation gets replaced by a valid one so we should only ignore it if its the empty segmentation
      if (this.populateSegmentsViaSurveyData && this.segmentations.indexOf(segmentation) === 0 && segmentation.isPlaceHolder) {
        return;
      }
      isSaveDisabled = this.isEditMode(segmentation) || !this.isValid(segmentation);
      // for existing segmentations we also need to check if the user changes something before enabling the save button
      if (!this.isNewSegmentation && !isSaveDisabled) {
        if (this.selectedActivityGuid === this.emptyGuid) {
          isSaveDisabled = !this.segmentationWasEdited;
        } else {
          const isSegmentationNameChanged = segmentation.segmentationName !== segmentation.initialSegmentationName;
          const areSegmentNamesChanged = _.every(segmentation.segments, (segment) => segment.name !== segment.initialName);
          const areLinkedAnswersChanged = _.every(segmentation.segments, (segment) => segment.initialAnswerLinkGuid === undefined ||
            segment.answerLinkGuid !== segment.initialAnswerLinkGuid);

          isSaveDisabled =  !this.segmentationWasEdited && !isSegmentationNameChanged && !areSegmentNamesChanged && !areLinkedAnswersChanged;
        }
      }
    });
    return isSaveDisabled || this.isSaving || this.isViewOnly || !this.areFormsValid();
  }

  isSaveAndLinkDisabled() {
    return this.isSaveDisabled() || this.segmentations.length > 2;
  }

  isSaveLinkSelectionDisabled(segmentation: ISegmentationFormItem) {
    return this.isSaving ||
      this.hasUnsavedSegment(segmentation) ||
      !this.selectedQuestionGuid ||
      this.selectedQuestionGuid === this.emptyGuid ||
      _.every(segmentation.segments, (segment) => segment.answerLinkGuid === segment.initialAnswerLinkGuid);
  }

  displaySegmentationListItem(segmentation: ISegmentationFormItem) {
    let displaySegmentationListItem = true;
    if (this.populateSegmentsViaSurveyData) {
      displaySegmentationListItem = this.segmentations.indexOf(segmentation) > 0 || this.segmentations.length === 1;
    }
    return displaySegmentationListItem;
  }

  processDataDisabled() {
    return this.processingData || this.segmentations.some((segmentation) => segmentation.linkedQuestionGuid === this.selectedQuestionGuid);
  }

  areFormsValid(): boolean{
    let isInvalid = false;
    for (const prop in this.forms) {
      isInvalid = this.forms[prop] && this.forms[prop].$invalid;
    }
    return !isInvalid;
  }

  setFormsDirty() {
    // Because we alter the value in code, the inputs are never touched and validation never happens so we need to set them manually
    for (const prop in this.forms) {
      const form = this.forms[prop];
      form?.$setDirty();
      form?.$getControls().forEach((con) => {
        con.$setDirty();
        con.$setTouched();
      });
    }
    // Digest cycles don't automatically trigger because of this, so we also do this manually
    this.$scope.$digest();
  }
}
