'use strict';

import { Cacheable } from './cacheable';

export class PlatformNoticeOption extends Cacheable {

  public static readonly KEY = 'platformNoticeOption';

  public key: string = PlatformNoticeOption.KEY;
  public duration: number = 365;
  public value: string = null;

}
