'use strict';

import { NoticeDateTimeController } from './noticeDateTime.controller';
angular
  .module('insitesApp.home')
  .component('noticeDateTime', {
    templateUrl: require('./noticeDateTime.html'),
    controllerAs: 'vm',
    controller: NoticeDateTimeController,
    bindings: {
      model: '=',
      name: '@',
      form: '<',
      minValue: '<',
      offsetValue: '<',
      labelText: '<',
      requiredText: '<',
      minimumText: '<',
      otherText: '<',
      modelChange: '&',
    },
  });
