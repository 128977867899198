'use strict';

export class ClipboardService {
  copyToClipboard(text: string) {
    const anyWindow: any = window;
    if (anyWindow.clipboardData && anyWindow.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return anyWindow.clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      const textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed';  // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy');  // Security exception may be thrown by some browsers.
      } catch (ex) {
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }
}
