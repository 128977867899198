'use strict';

import { DateTime } from 'luxon';
import { Pagination } from '../../../core/models/pagination';
import { ActivityService } from '../../../core/dataservices/activity.service';
import { ConstantsFactory } from '../../../core/constants.factory';
import { ServerConstants } from '../../../core/serverconstants';
import { SpinnerService } from '../../../core/services/spinner.service';
import { QualConversationExportParams } from '../../../core/contracts/download.contract';
import { DateFormatService } from '../../../core/services/dateformat.service';
import * as _ from 'lodash';
import { IActivityFilterData, IActivityFilterAdditionalData } from '../../../core/contracts/activity.contract';
import { FeatureService } from '../../../core/dataservices/feature.service';
import { ActivityFilterService } from '../../../core/services/activityFilter.service';
import { CurrentUserService } from 'src/app/core/dataservices/currentUser.service';

export abstract class ActivitiesOverviewControllerBase  {

  static $inject = ['activityservice', 'constantsfactory', 'serverConstants', '$mdDialog', 'spinnerservice',
    'logger', 'dateFormatService', '$location', '$rootScope', '$stateParams', '$state', 'featureservice', 'activityFilterService',
    'currentUserService'];

  constructor(
    protected activityservice: ActivityService,
    protected constantsfactory: ConstantsFactory,
    protected serverConstants: ServerConstants,
    protected $mdDialog: ng.material.IDialogService,
    protected spinnerservice: SpinnerService,
    protected logger: Logger,
    protected dateFormatService: DateFormatService,
    protected $location: ng.ILocationService,
    protected $rootScope: ng.IRootScopeService,
    protected $stateParams: ng.ui.IStateParamsService,
    protected $state: ng.ui.IStateService,
    protected featureservice: FeatureService,
    protected activityFilterService: ActivityFilterService,
    protected currentUserService: CurrentUserService,
  ) {
    this.subscriptions.push(this.currentUserService.impersonatedUserChange.subscribe(async () => {
      await this.loadActivities(false);
    }));
  }

  private activitiesOverviewPageLimit = 40;
  protected activities = [];
  pagination: IPagination = new Pagination(this.activitiesOverviewPageLimit);
  order: string = null; // Holds the name of the property used for ordering
  minStartDate: DateTime;
  maxEndDate: DateTime;
  filterData: IActivityFilterData;
  filterAdditionalData: IActivityFilterAdditionalData;
  filterDataCounters;
  subscriptions = [];

  $onDestroy() {
    this.subscriptions.map((sub) => sub.unsubscribe());
  }

  abstract loadActivities(dragStatusWhileLoading: boolean);

  parseParameters() {
    this.pagination.page = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
    this.pagination.limit = this.$stateParams.limit ? parseInt(this.$stateParams.limit, 10) : this.activitiesOverviewPageLimit;
    this.order = this.$stateParams.order;
  }

  showProgress(activity: {
    Progress
  }): string {
    const totalText = activity.Progress.Total != null ? activity.Progress.Total : '?';
    return `${activity.Progress.Completed}/${activity.Progress.Qualified}/${activity.Progress.InProgress}/${activity.Progress.Target}/${totalText}`;
  }

  getLabelForActivityType(activityType, activityFormat?) {
    return this.constantsfactory.GetLabelForSquareActivityType(activityType, activityFormat);
  }

  isRemovable(status) {
    return status === this.serverConstants.squareActivityStatusConstants.draft ||
      status === this.serverConstants.squareActivityStatusConstants.scheduled;
  }

  async deleteActivity(activity) {
    await this.$mdDialog.show(
      this.$mdDialog.iscConfirm()
        .title('Delete activity')
        .text(`Do you want to delete the activity ${activity.Name}?`)
        .ok('Yes')
        .cancel('No'));

    if (activity.Guid !== undefined && activity.Guid !== this.serverConstants.validationConstants.emptyGuid) {
      try {
        this.spinnerservice.show('loading');
        await this.activityservice.deleteActivity(activity.Guid);
        this.logger.success('Activity successfully deleted');
        _.remove(this.activities, activity);
      } catch (error) {
        if (error.status === 400) {
          const data = error.data;
          let errorMessage = '';
          _.forEach < any[] > (data.ValidationErrors, (validationError: any) => {
            errorMessage += `${validationError.ErrorMessage} `;
          });
          if (errorMessage.length > 0) {
            this.logger.error(errorMessage);
          }
        }
      } finally {
        this.spinnerservice.hide('loading');
      }
    }
  }

  setDownloadParams(
    activity: {
      Guid: string,
      downloadParams: QualConversationExportParams,
      ActivityConversationFilterResult: any,
      HasConversations: boolean
    },
    filterData,
  ) {
    if (!activity.downloadParams) {
      activity.downloadParams = new QualConversationExportParams();
    }
    activity.downloadParams.activityGuid = activity.Guid;
    activity.downloadParams.stimuliDownloadAvailable = activity.ActivityConversationFilterResult.HasStimuli;
    activity.downloadParams.textDownloadAvailable = activity.HasConversations;
    activity.downloadParams.applyQualFilter(JSON.parse(JSON.stringify(filterData)));
  }

  private goToPage = (pageNumber) => {
    this.pagination.page = pageNumber;
    this.pagination.hide = true;
    const filterParams = this.activityFilterService.getQualStateParamsFromFilter(this.filterData, true);
    this.$state.go('.', {
      page: this.pagination.page,
      limit: this.pagination.limit,
      order: this.order,
      triggerDate: DateTime.utc().valueOf(), // Unique parameter to always trigger refresh
      ...filterParams,
    });
  };

  onOrder = () => {
    this.goToPage(this.pagination.page);
  };

  onPaginate = (pageNumber) => {
    this.goToPage(pageNumber);
  };

  goToAnalysisAndReporting(activityGuid) {
    this.$state.go('root.reporting',  {
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      selectedActivityGuid: activityGuid,
    });
  }

  async isAnalysisAndReportingEnabled() {
    const featureSettings = await this.featureservice.getFeatureSettings(this.serverConstants.featureConstants.analysisAndReporting);
    return featureSettings.isMasterSwitchEnabled;
  }

  isActivityDraftOrScheduled(activity) {
    return activity.Status === this.serverConstants.squareActivityStatusConstants.draft
      || activity.Status === this.serverConstants.squareActivityStatusConstants.scheduled;
  }
}
