'use strict';

import { SquareConfigIncentivesController } from './incentives.controller';

const template = require('./incentives.html');

export const SquareConfigIncentivesComponent: ng.IComponentOptions = {
  controller: SquareConfigIncentivesController,
  templateUrl: template,
  controllerAs: 'vm',
};
