'use strict';

import { ParticipantService } from './../../../../core/dataservices/participant.service';
import * as _ from 'lodash';
import { SegmentModel } from './segmentModel';
import { ServerConstants } from '../../../../core/serverconstants';

export class ChangeSegmentsController {
  static $inject = ['$mdDialog', 'selectedSquareParticipantGuids', 'participantservice', 'segmentations', 'serverConstants'];
  private _selectedSegmentationGuid: string;

  loadingSegments;
  isSingleOption: boolean;

  get selectedSegmentationGuid() {
    return this._selectedSegmentationGuid;
  }
  set selectedSegmentationGuid(value: string) {
    this._selectedSegmentationGuid = value;
    const segmentation = _.find(this.segmentations, (s) => s.Guid === value);
    this.isSingleOption = segmentation.Type === this.serverConstants.segmentationTypeConstants.profile && segmentation.Question === 'Country';

    this.loadSegments();
  }
  get canSave() {
    return !this.loadingSegments &&
      this.segments && this.segments.length && this.segments.some((s) => s.hasChanges);
  }
  segments: SegmentModel[];

  constructor(
    private $mdDialog: ng.material.IDialogService,
    private selectedSquareParticipantGuids: string[],
    private participantService: ParticipantService,
    private segmentations,
    private serverConstants: ServerConstants,
  ) {
  }

  private async loadSegments() {
    try {
      this.loadingSegments = true;
      this.segments = null;
      const segments = await this.participantService.listSegmentsAndUsage(this.selectedSegmentationGuid, this.selectedSquareParticipantGuids);
      this.segments = _.map(segments, (segment) => {
        let selected = false;
        let indeterminate = false;
        if (segment.UsedBy === this.selectedSquareParticipantGuids.length) {
          selected = true;
          indeterminate = false;
        } else if (segment.UsedBy === 0) {
          selected = false;
          indeterminate = false;
        } else {
          selected = false;
          indeterminate = this.isSingleOption ? false : true;
        }
        return new SegmentModel(segment.Guid, segment.Answer, selected, indeterminate);
      });
    } finally {
      this.loadingSegments = false;
    }
  }

  get inProgress() {
    return this.loadingSegments;
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  save() {
    this.$mdDialog.hide(_.filter(this.segments, (s) => s.hasChanges).map((s) => ({
      SegmentGuid: s.guid,
      Action: s.selected ? 'add' : 'remove',
    })));
  }

  unCheckOtherOptions(segment) {
    _.forEach(this.segments, (s) => {
      if (s !== segment) {
        s.selected = false;
      }
    });
  }
}
