'use strict';

import { ActivityConfigController } from './activityConfig.controller';

const template = require('./activityConfig.html');

export const ActivityConfigComponent: ng.IComponentOptions = {
  controller: ActivityConfigController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    startingStep: '<',
  },
};
