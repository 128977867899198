'use strict';

import { TargetingQuotaTableController } from './targeting-quota-table.controller';

const template = require('./targeting-quota-table.html');
require('./targeting-quota-table.scss');
export const TargetingQuotaTableComponent: ng.IComponentOptions = {
  controller: TargetingQuotaTableController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    activityGuid: '<',
    segments: '=',
    model: '=',
    randomlySelected: '<',
    isDisabled: '<',
  },
};
