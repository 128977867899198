'use strict';

const StimuliIgnoreFilter = (): IStimuliIgnoreFilter => (stimuli) => {
  let filtrable: any[] = [];
  if (stimuli) {
    if (angular.isArray(stimuli)) {
      filtrable = stimuli as any[];
    } else if (Object.prototype.hasOwnProperty.call(stimuli, '$viewValue')) {
      filtrable = (stimuli as ng.INgModelController).$viewValue;
    }
  }
  return filtrable.filter((stimulus) => stimulus.action !== 'delete');
};

export type IStimuliIgnoreFilter = (stimuli: any[] | ng.INgModelController) => any[];

angular
  .module('insitesApp.core')
  .filter('stimuliIgnore', StimuliIgnoreFilter);
