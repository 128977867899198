'use strict';

import { ForumRoomModel } from './forumRoomModel';

export class AddForumRoomDialogController {

  static $inject = ['$mdDialog', 'validationConstants', 'selectedPage', '_', 'serverConstants'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private validationConstants,
    private selectedPage,
    private _: _.LoDashStatic,
    private serverConstants,
  ) {
    this.operationTypeConstants = serverConstants.operationTypeConstants;
    this.forumRoom = new ForumRoomModel();
  }

  forumRoom: ForumRoomModel;
  operationTypeConstants;
  addForumRoom;
  room;

  cancel() {
    this.$mdDialog.cancel();
  }

  submit() {
    this.$mdDialog.hide(this.forumRoom);
  }
}
