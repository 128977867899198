'use strict';

import { MemberOverviewController } from './memberOverview.controller';
const template = require('./memberOverview.html');
require('./_memberOverview.scss');

angular.module('insitesApp.squaremembers.memberOverview').component('memberOverview', {
  controller: MemberOverviewController,
  controllerAs: 'vm',
  templateUrl: template,
});
