'use strict';

import './helpCenter.module';
import { SquareConfigHelpCenterComponent } from './helpCenter.component';
import routeConfig from './helpCenter.route';
import './faq';

angular
  .module('insitesApp.squareconfig.helpCenter')
  .component('helpCenter', SquareConfigHelpCenterComponent)
  .run(routeConfig);
