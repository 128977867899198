'use strict';

const inParenthesesFilter = () => (value) => {
  if (!value) {
    return '';
  }
  return `(${value})`;
};

angular
  .module('insitesApp.squarecommunication')
  .filter('inParentheses', inParenthesesFilter);
