'use strict';

import './log.module';
import { LogComponent } from './log.component';
import routeConfig from './log.route';

angular
  .module('insitesApp.squareconfig.footer')
  .component('logList', LogComponent)
  .run(routeConfig);
