'use strict';

export class SidenavItemController {
  $inject = [];
  constructor() { }

  onActionIconClick;

  actionIconClick = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    this.onActionIconClick({ $event });
  };
}
