'use strict';

import { MemberDetailsProfileController } from './memberDetailsProfile.controller';
require('./_memberDetailsProfile.scss');
const template = require('./memberDetailsProfile.html');

angular.module('insitesApp.squaremembers.memberOverview').component('memberDetailsProfile', {
  controller: MemberDetailsProfileController,
  controllerAs: 'vm',
  templateUrl: template,
});
