'use strict';

import { ActivitiesAllTabController } from './activitiesAllTab.controller';

const activitiesAllTabTemplate = require('./activitiesAllTab.html');

export const activitiesAllTab: ng.IComponentOptions = {
  controller: ActivitiesAllTabController,
  templateUrl: activitiesAllTabTemplate,
  controllerAs: 'vm',
  require: {
    squareActivitiesComponent: '^^squareActivitiesComponent',
  },
};
