'use strict';

import { RewardContoller } from './isc-reward.controller';

const template = require('./isc-reward.html');
require('./_isc-reward.scss');

export const RewardComponent: ng.IComponentOptions = {
  controller: RewardContoller,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    rewardModel: '=',
    squareGuid: '=',
    ngDisabled: '=',
    raffleType: '=',
    hideSpecificSegment: '<',
    showInstantTooltip: '<',
    hideEditButtons: '<',
    minValueReward: '<',
    isValueRequired: '<',
    newRewardsOnly: '<',
  },
};
