'use strict';

import { AuthService } from './dataservices/auth.service';
import _ = require('lodash');

const featureBasedAccessControl = ($transitions, authService: AuthService) => {
  $transitions.onSuccess({}, async (trans) => {
    let hasAccess = true;
    const isFeatureEnabled = trans.targetState()._definition.isFeatureEnabled;

    if (isFeatureEnabled) {
      hasAccess = _.isFunction(isFeatureEnabled) ? await isFeatureEnabled() : isFeatureEnabled;

      if (!hasAccess) {
        await redirectToDefaultRoute();
      }
    }

    return hasAccess;
  });

  const redirectToDefaultRoute = async (): Promise<void> => {
    await authService.redirectToHomeRouteInfoForLoggedInUser();
  };
};
featureBasedAccessControl.$inject = ['$transitions', 'authService'];

angular
  .module('insitesApp.core')
  .run(featureBasedAccessControl);
