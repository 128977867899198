'use strict';

import { ToggleableController } from './isc-expander.controller';

const template = require('./isc-expander.html');

export const ToggleableComponent: ng.IComponentOptions = {
  controller: ToggleableController,
  templateUrl: template,
  controllerAs: 'vm',
  transclude: true,
  bindings: {
    sectionTitle: '@',
    sectionSubtitle: '@',
    registerId: '<',
    isCollapsed: '<',
  },
};
