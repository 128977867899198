'use strict';


import { ActivitiesQuantTabController } from './activitiesQuantTab.controller';

const activitiesQuantTabTemplate = require('./activitiesQuantTab.html');

export const activitiesQuantTab: ng.IComponentOptions = {
  controller: ActivitiesQuantTabController,
  templateUrl: activitiesQuantTabTemplate,
  controllerAs: 'vm',
  require: {
    squareActivitiesComponent: '^^squareActivitiesComponent',
  },
};
