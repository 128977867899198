'use strict';

export class EditButtonController {
  static $inject = ['$attrs'];
  disabled = false;

  constructor(
    private $attrs: ng.IAttributes,
  ) { }

  $onInit() {
    this.$attrs.$observe<boolean>('disabled', (disabled) => {
      this.disabled = disabled;
    });
  }
}
