'use strict';

const closeModalOnTransition = ($transitions, $mdDialog: ng.material.IDialogService) => {
  $transitions.onFinish({}, () => $mdDialog.cancel());
};

closeModalOnTransition.$inject = ['$transitions', '$mdDialog'];

angular
  .module('insitesApp.core')
  .run(closeModalOnTransition);
