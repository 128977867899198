'use strict';

import * as angular from 'angular';

import { DisplayTargetingComponent } from './display-targeting.component';

angular
  .module('insitesApp.layout')
  .component('displayTargeting', DisplayTargetingComponent);

