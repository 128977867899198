'use strict';

import { IconSelectorController } from './iconSelector.controller';

const template = require('./iconSelector.html');

export const IconSelectorComponent: ng.IComponentOptions = {
  controller: IconSelectorController,
  templateUrl: template,
  controllerAs: 'vm',
  require: {
    ngModel: 'ngModel',
  },
  bindings: {
    selectedIcon: '<',
    usedIcons: '=',
  },
};
