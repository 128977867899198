'use strict';

import { ServerConstants } from './../../../../../../core/serverconstants';
import * as _ from 'lodash';
import { DateFormatService } from './../../../../../../core/services/dateformat.service';
import { ActivityService } from '../../../../../../core/dataservices/activity.service';
import { CommunicationService } from '../../../../../../core/dataservices/communication.service';
import { SequencedActivityInfoMessageHelper } from '../../../sequencedActivity/sequencedActivityInfoMessageHelper';
import { DateTime } from 'luxon';

export class ActivityEmailReminderController {

  static $inject = ['dateFormatService', 'serverConstants', 'activityservice', 'communicationservice', '$stateParams', 'logger'];
  constructor(
    private dateFormatService: DateFormatService,
    private serverConstants: ServerConstants,
    private activityservice: ActivityService,
    private communicationservice: CommunicationService,
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
  ) { }

  wizardStep;
  form;
  removeLink;
  currentTimeZone = DateTime.local().offset / 60;
  channelType = this.serverConstants.channelTypeConstants.researchActivityReminder;
  validationConstants = this.serverConstants.validationConstants;
  saveFooterText;
  model = {
    Guid: '',
    CommunicationGuid: '',
    Title: '',
    Message: '',
    StartDateTime: null as DateTime,
    EndDateTime: null as DateTime,
    StartDateOffset: this.currentTimeZone,
    CallToActionText: '',
    FooterText: '',
    Rewards: [],
    SendEmailDelay: this.validationConstants.defaultReminderIncompleteEmailHours,
  };
  communication;
  isCompleted;
  isReadOnly;
  saveNotApplicable = false;
  saveCallback;
  prefixLabel;
  suffixLabel;
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm();
  infoTextProvider: SequencedActivityInfoMessageHelper;

  async $onInit() {
    this.removeLink = this.wizardStep.linkComponent(`channel-${this.channelType}`, this);
    const result = await this.wizardStep.resolveComponent(['channels']);
    const channelsStep = result[0];
    this.infoTextProvider = new SequencedActivityInfoMessageHelper(this.serverConstants, channelsStep);
    this.$loadData(this.communication);
    this.saveCallback = () => this.saveData(this);
  }

  async $wizardStepLoaded() {
    this.$loadData(this.communication);
    const channelsStep = await this.wizardStep.resolveComponent('channels');
    const selectedResearchActivityGuid = channelsStep.selectedActivityGuid;
    const response = await this.activityservice.getActivityDetailsForMessage(selectedResearchActivityGuid);
    const activityDetail = response.data.Detail;
    const inviteEmailChannelStep = await this.wizardStep.resolveComponent(`channel-${this.serverConstants.channelTypeConstants.researchActivityEmail}`);
    this.saveFooterText = inviteEmailChannelStep.saveFooterText;
    this.model.StartDateOffset = inviteEmailChannelStep.model.StartDateOffset;
    this.model.StartDateTime = this.model.StartDateTime || inviteEmailChannelStep.model.StartDateTime;
    if (!this.saveNotApplicable) {
      const currentDateTime = DateTime.now();
      this.model.StartDateTime = inviteEmailChannelStep.model.StartDateTime < currentDateTime ? currentDateTime : inviteEmailChannelStep.model.StartDateTime;
    }
    this.model.EndDateTime = this.dateFormatService.getDateTime(activityDetail.EndDate, this.model.StartDateOffset);
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardStepIsCompleted() {
    const channel = _.find(this.communication.Channels, (communicationChannel: any) =>
      communicationChannel.Channel.ChannelType === this.serverConstants.channelTypeConstants.researchActivityReminder);
    this.isCompleted = false;
    if (channel !== undefined && channel.Channel.Title && channel.Channel.Message
      && channel.Channel.CallToActionText && channel.Channel.SendEmailDelay !== undefined) {
      this.isCompleted = true;
    }
    return this.isCompleted;
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    const channelIndex = _.findIndex(this.communication.Channels, (c: any) =>
      c.Channel.ChannelType === this.serverConstants.channelTypeConstants.researchActivityReminder);
    if (!this.isReadOnly && this.communication.Channels[channelIndex - 1] && this.communication.Channels[channelIndex - 1].Channel.IsPublished) {
      return ' '; // Space to overrule parent message
    }
    return this.serverConstants.squareConstants.wizardNotPublishedStatus;
  }

  $wizardIsValid() {
    return this.form ? this.form.$valid : false;
  }

  async $wizardBeforeNext() {
    await this.saveData(this);
  }

  async saveData(root) {
    const channel = {
      Guid: root.model.Guid,
      CommunicationGuid: root.model.CommunicationGuid,
      Title: root.model.Title,
      ChannelType: root.channelType,
      Message: _.escape(root.model.Message),
      CallToActionText: root.model.CallToActionText,
      StartDateTime: root.dateFormatService.getDateTimeForSaving(root.model.StartDateTime, root.model.StartDateOffset),
      StartDateOffset: root.model.StartDateOffset,
      SendEmailDelay: root.model.SendEmailDelay,
      FooterText: root.saveFooterText,
    };
    root.form.$setPristine();
    await root.communicationservice.updateActivityEmail(root.$stateParams.communicationGuid, channel).then(() => {
      this.communicationservice.selectCommunication(root.$stateParams.communicationGuid).then((response) => {
        root.communication = response;
        root.$loadData(response);
      });
      const channelToFind = _.find(this.communication.Channels, (c: any) =>
        c.Channel.ChannelType === this.serverConstants.channelTypeConstants.researchActivityReminder);
      if (!root.isReadOnly && channelToFind.Channel.IsPublished) {
        root.logger.success('Channel successfully updated.');
      }
    }, () => {
      root.logger.error('Channel not successfully updated.');
    });
  }

  resetForm() {
    this.$loadData(this.communication);
    this.form.$setPristine();
  }

  $loadData(communication) {
    this.model.CommunicationGuid = communication.Communication.Guid;
    const channel = _.find(communication.Channels, (c: any) =>
      c.Channel.ChannelType === this.serverConstants.channelTypeConstants.researchActivityReminder);
    if (channel !== undefined) {
      this.prefixLabel = channel.ChannelPrefixTemplate;
      this.suffixLabel = channel.ChannelSuffixTemplate;
      this.model.Guid = channel.Channel.Guid;
      this.model.Title = channel.Channel.Title;
      this.channelType = channel.Channel.ChannelType;
      this.model.Message = channel.Channel.Message;
      this.model.CallToActionText = channel.Channel.CallToActionText;
      this.model.StartDateTime = this.dateFormatService.getDateTime(channel.Channel.StartDateTime, channel.Channel.StartDateOffset);
      // Update it when rewards are saved
      this.model.Rewards = [];
      this.model.SendEmailDelay = channel.Channel.SendEmailDelay > 0 ? channel.Channel.SendEmailDelay : this.validationConstants.defaultReminderIncompleteEmailHours;
      this.saveNotApplicable = channel.Channel.IsPublished;
    }
    // This fixes the bug that when the reminder is completed it doesn't show it as completed
    this.wizardStep.wizardComponent.setNextStepNextSubstepIndices();
  }
}
