'use strict';

import { IscManuallyHandledRewardFilterController } from './isc-manually-handled-reward-filter.controller';

const template = require('./isc-manually-handled-reward-filter.html');
require('./_isc-manually-handled-reward-filter.scss');

export const iscManuallyHandledRewardComponent: ng.IComponentOptions = {
  controller: IscManuallyHandledRewardFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    manuallyHandledRewardFilter: '=',
    minStartDate: '<',
    maxEndDate: '<',
    filterManuallyHandledRewards: '&',
  },
};
