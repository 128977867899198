'use strict';

export class PublishRecruitmentPartnerController {
  static $inject = ['serverConstants', '_'];

  recruitmentPartnerCallbackTypeConstants;
  callbackTypes;

  constructor(
    private serverConstants,
    private _: _.LoDashStatic,
  ) {
    this.recruitmentPartnerCallbackTypeConstants = this.serverConstants.recruitmentPartnerCallbackTypeConstants;
    this.callbackTypes = [
      {
        Label: 'Pixel',
        CallbackType: this.recruitmentPartnerCallbackTypeConstants.pixel,
      },
      {
        Label: 'Redirect',
        CallbackType: this.recruitmentPartnerCallbackTypeConstants.redirect,
      },
    ];
  }

  getCallbackLabel(int) {
    return this._.find<any>(this.callbackTypes, { CallbackType: int }).Label;
  }
}
