'use strict';

import { DateTime } from 'luxon';
import { IncentiveService } from '../core/dataservices/incentive.service';
import * as _ from 'lodash';
import { ServerConstants } from '../core/serverconstants';
import { Pagination } from '../core/models/pagination';
import { ManuallyHandledRewardsExportParams } from '../core/contracts/download.contract';
import { Utils } from '../core/utils';
import { IManuallyHandledRewardFilter } from '../core/contracts/filter.contract';
import { CacheService } from '../core/services/cache.service';
import { SpinnerService } from '../core/services/spinner.service';
export class RewardHandlingController {
  static $inject = ['incentiveservice', '$stateParams', 'serverConstants', '$state', 'spinnerservice'];

  constructor(
    private incentiveService: IncentiveService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
    private $state: ng.ui.IStateService,
    private spinnerservice: SpinnerService) { }

  downloadParams: ManuallyHandledRewardsExportParams = new ManuallyHandledRewardsExportParams();
  order: string = null;
  manuallyHandledRewardFilter: IManuallyHandledRewardFilter;
  manuallyHandledRewards = [];
  allSelected: boolean = false;
  minStartDate = DateTime.now();
  maxEndDate = DateTime.now();
  startDate = DateTime.now().minus({ years: 3 });
  endDate = DateTime.now();
  filteredManuallyHandledRewards: string[] = [];
  selectedManuallyHandledRewards: string[] = [];
  pagination: IPagination = new Pagination(25);

  async $onInit() {
    this.pagination.hide = true;
    this.minStartDate = this.startDate;
    this.maxEndDate = this.endDate;
    this.manuallyHandledRewardFilter = CacheService.getCacheValue('filter') ??
    {
      keyword: undefined,
      statuses: [this.serverConstants.manuallyHandledRewardStatusConstants.notHandled],
      clients: [],
      squares: [],
      jobIds: [],
      squareParticipants: [],
      countries: [],
      rewardPartners: [],
      startDate: this.startDate,
      endDate: this.endDate,
      list: [],
      cleared: undefined,
    };
    this.manuallyHandledRewardFilter.startDate = this.startDate;
    this.manuallyHandledRewardFilter.endDate = this.endDate;
    this.parseParameters();
    await this.loadData();
    this.order = this.$stateParams.order;
  }

  parseParameters() {
    this.pagination.page = this.$stateParams.page ? parseInt(this.$stateParams.page, 10) : 1;
    this.pagination.limit = this.$stateParams.limit ? parseInt(this.$stateParams.limit, 10) : 25;
    this.order = this.$stateParams.order;
  }

  private setDataInLocalStorage(key, value) {
    if (!value) {
      CacheService.removeCacheValue(key);
    } else {
      CacheService.setCache({ key, value });
    }
  }

  async loadData() {
    try {
      this.spinnerservice.show('loading');
      if (!this.downloadParams) {
        this.downloadParams = new ManuallyHandledRewardsExportParams();
      }
      this.downloadParams.startDate = this.startDate;
      this.downloadParams.endDate = this.endDate;
      this.downloadParams.keyword = this.manuallyHandledRewardFilter.keyword;
      this.downloadParams.clients = this.manuallyHandledRewardFilter.clients;
      this.downloadParams.countries = this.manuallyHandledRewardFilter.countries;
      this.downloadParams.jobIds = this.manuallyHandledRewardFilter.jobIds;
      this.downloadParams.rewardPartners = this.manuallyHandledRewardFilter.rewardPartners;
      this.downloadParams.squareParticipants = this.manuallyHandledRewardFilter.squareParticipants;
      this.downloadParams.squares = this.manuallyHandledRewardFilter.squares;
      this.downloadParams.statuses = this.manuallyHandledRewardFilter.statuses;

      this.manuallyHandledRewards = [];
      this.selectedManuallyHandledRewards = CacheService.getCacheValue('selectedManuallyHandledRewards') || [];
      this.allSelected = !!CacheService.getCacheValue('allSelected');
      const manuallyHandledRewardsResponse = await this.incentiveService.getManuallyHandledRewards(
        this.pagination.page,
        this.pagination.limit,
        this.order,
        this.manuallyHandledRewardFilter);
      this.pagination.total = manuallyHandledRewardsResponse.data.Rewards.TotalItems;
      _.each(manuallyHandledRewardsResponse.data.Rewards.Items, (item) => {
        item.RewardPartner = Utils.getEnumValueName(this.serverConstants.rewardPartnerTypeConstants, item.RewardPartner);
        item.selected = _.includes(this.selectedManuallyHandledRewards, item.Guid);
        this.manuallyHandledRewards.push(item);
      });
      this.filteredManuallyHandledRewards = manuallyHandledRewardsResponse.data.AllItemGuids;
      if (this.allSelected && this.selectedManuallyHandledRewards.length < this.filteredManuallyHandledRewards.length) {
        this.allSelected = false;
      }
      const dates = manuallyHandledRewardsResponse.data.Rewards.Items.map((p) => DateTime.fromISO(p.DateCreated));
      const minDate = DateTime.min.apply(null, dates);
      if (minDate < this.startDate) {
        this.startDate = minDate;
        this.minStartDate = minDate;
      }
      this.pagination.hide = false;
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  async filterManuallyHandledRewards() {
    this.setDataInLocalStorage('filter', this.manuallyHandledRewardFilter);
    await this.loadData();
    this.goToPage(1);
  }

  async selectAll() {
    if (this.allSelected) {
      this.selectedManuallyHandledRewards = angular.copy(this.filteredManuallyHandledRewards);
    } else {
      this.selectedManuallyHandledRewards = [];
    }
    for (const participant of this.manuallyHandledRewards) {
      participant.selected = this.allSelected;
    }
    this.setDataInLocalStorage('selectedManuallyHandledRewards', this.selectedManuallyHandledRewards);
  }

  selectManuallyHandledRewards(manuallyHandledReward) {
    if (manuallyHandledReward.selected) {
      this.selectedManuallyHandledRewards = _.concat(this.selectedManuallyHandledRewards, manuallyHandledReward.Guid);
    } else {
      _.pull(this.selectedManuallyHandledRewards, manuallyHandledReward.Guid);
      this.allSelected = false;
    }
    this.setDataInLocalStorage('selectedManuallyHandledRewards', this.selectedManuallyHandledRewards);
  }

  async markAsHandled() {
    if (this.selectedManuallyHandledRewards.length > 0) {
      await this.incentiveService.handleManualRewardWinner(this.selectedManuallyHandledRewards);
      this.selectedManuallyHandledRewards = [];
      await this.loadData();
    }
  }

  onOrder = () => {
    const order: boolean | 'asc' | 'desc' = this.order.charAt(0) === '-' ? 'desc' : 'asc';
    const orderBy = order === 'asc' ? this.order : this.order.substring(1);
    this.manuallyHandledRewards = _.orderBy(this.manuallyHandledRewards, [orderBy], [order]);
    this.goToPage(this.pagination.page);
  };

  private goToPage = (pageNumber) => {
    this.pagination.page = pageNumber;
    this.setDataInLocalStorage('allSelected', this.allSelected);
    this.$state.go('.', {
      page: this.pagination.page,
      limit: this.pagination.limit,
      order: this.order,
      triggerDate: DateTime.utc().valueOf, // Unique parameter to always trigger refresh
    });
  };

  onPaginate = (pageNumber) => {
    this.goToPage(pageNumber);
  };

  markAsHandledDisabled() {
    return this.selectedManuallyHandledRewards.length === 0;
  }
}
