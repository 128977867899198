'use strict';

import { HttpService } from './http.service';
import {
  IMembersInSegmentsResponse,
  IMembersInSegmentsRequest,
  ITargeting,
  ITargetingItemRequest,
  ITargetingQuota,
} from '../contracts/targeting.contracts';
import { IPromise } from 'angular';
import { ServerConstants } from '../serverconstants';
import { Utils } from '../utils';
import { DateTime } from 'luxon';

export class TargetService {
  static $inject = ['httpservice', 'serverConstants'];

  constructor(
    private httpService: HttpService,
    private serverConstants: ServerConstants,
  ) {
  }

  getMembersInSegments(targeting: ITargeting, activityGuid: string, startDate: string = null, combined: boolean = false): IPromise<IMembersInSegmentsResponse> {
    const data = this.targetingOptionsToIMembersInSegmentsRequest(targeting, activityGuid, startDate, combined);
    return this.httpService.postToQuery({
      url: '/TargetService/GetMembersInSegments',
      data,
    }).then((response) => response.data as IMembersInSegmentsResponse);
  }

  getTargetingForActivity(activityGuid: string): IPromise<ITargeting> {
    return this.httpService.get({
      url: '/TargetService/GetTargetingForActivity',
      params: {
        ActivityGuid: activityGuid,
      },
    }).then((response) => {
      if (response.data && response.data.TargetingQuota) {
        response.data.TargetingQuota =
          Utils.convertPascalCasedObjectToCamelCasedObject(response.data.TargetingQuota);
      }

      return response.data;
    });
  }

  getTargetingForCommunication(communicationGuid: string): IPromise<ITargeting> {
    return this.httpService.get({
      url: '/TargetService/GetTargetingForCommunication',
      params: {
        CommunicationGuid: communicationGuid,
      },
    }).then((response) => response.data);
  }

  private targetingOptionsToIMembersInSegmentsRequest(targeting: ITargeting,
    activityGuid: string,
    startDate: string,
    combined: boolean): IMembersInSegmentsRequest {
    return {
      TargetingGuid: targeting.Guid,
      ParentActivityGuid: targeting.TargetingType === this.serverConstants.targetingTypeConstants.communication && combined ?
        activityGuid :
        null,
      RefGuid: targeting.TargetingType === this.serverConstants.targetingTypeConstants.activity ?
        activityGuid :
        targeting.CommunicationGuid,
      TargetingType: targeting.TargetingType,
      TargetingFilterOption: targeting.TargetingFilterOption,
      TargetingFilterType: targeting.TargetingFilterType,
      RandomlySelected: targeting.RandomlySelected,
      ExcludeParticipantsInvitedSince: targeting.ExcludeParticipantsInvitedSinceWeeks && startDate ?
        DateTime.fromISO(startDate).plus({ days: 7 * -targeting.ExcludeParticipantsInvitedSinceWeeks }) :
        null,
      Items: targeting.Items.map((item): ITargetingItemRequest => (
        {
          Id: item.Id,
          SegmentGuids: item.SegmentGuids,
          SegmentationGuid: item.SegmentationGuid,
          TargetingFilterOption: item.TargetingFilterOption,
        }
      )),
      TargetedMemberIds: targeting.SquareParticipantIds,
    } as IMembersInSegmentsRequest;
  }

  createUpdateCommunicationTargeting(targetingData) {
    return this.httpService.post({
      url: '/TargetService/CreateUpdateCommunicationTargeting',
      data: targetingData,
    });
  }

  createUpdateActivityTargeting(targetingData) {
    return this.httpService.post({
      url: '/TargetService/CreateUpdateActivityTargeting',
      data: targetingData,
    });
  }

  calculateTargetingQuota(
    activityGuid: string,
    targeting: ITargeting)
    : IPromise<ITargetingQuota> {
    return this.httpService.post({
      url: '/TargetQuotaService/CalculateTargetingQuota',
      data: { activityGuid, targeting },
    }).then((response) => Utils.convertPascalCasedObjectToCamelCasedObject(response.data) as ITargetingQuota);
  }

  getActivitySegmentTargetForActivity(activityGuid: string, segmentationGuid?: string): IPromise<any> {
    return this.httpService.get({
      url: '/TargetService/GetActivitySegmentTargetForActivity',
      params: { ActivityGuid: activityGuid, SegmentationGuid: segmentationGuid },
    });
  }

  deleteActivitySegmentTargets(activityGuid: string, segmentGuids: string[]): IPromise<any> {
    return this.httpService.post({
      url: '/TargetService/DeleteActivitySegmentTargetsForSegmentation',
      data: { activityGuid, segmentGuids },
    });
  }
}
