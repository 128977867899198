'use strict';

import { DataExportController } from './data-export.controller';
const template = require('./data-export.html');

export const DataExportComponent: ng.IComponentOptions = {
  controller: DataExportController,
  controllerAs: 'cvm',
  templateUrl: template,
  transclude: {
    downloadButton: 'downloadButton',
  },
  bindings: {
    exportType: '<',
    params: '<?',
    pullRight: '<?',
  },
};
