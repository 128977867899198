'use strict';


import { ServerConstants } from '../../../core/serverconstants';
import { ClientService } from '../../../core/dataservices/client.service';
import { IClientConfigurationWelcomeMessage } from '../../../core/dataservices/dataservice.contracts';
import * as _ from 'lodash';

export class WelcomeMessageConfigurationController {

  static $inject = [
    '$timeout',
    '$state',
    '$stateParams',
    'serverConstants',
    'clientservice',
    'logger'];

  isSaving: boolean = false;

  Message: string = '';
  Title: string = '';

  validationConstants: Record<string, unknown>;

  welcomeMessageConfigForm: ng.IFormController;

  constructor(
    private $timeout: ng.ITimeoutService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
    private clientservice: ClientService,
    private logger: Logger,
  ) {
    this.validationConstants = serverConstants.validationConstants;

    this.isSaving = false;
  }

  $onInit() {
    this.clientservice
      .getClientConfigurationWelcomeMessage(this.$stateParams.clientGuid)
      .then((welcomeMessage) => {
        if (welcomeMessage) {
          this.Title = welcomeMessage.Title;
          this.Message = welcomeMessage.Message;
        }
      });
  }

  isFormDirty(): boolean {
    return this.welcomeMessageConfigForm.Title.$dirty
      || this.welcomeMessageConfigForm.Message.$dirty;
  }

  isFormValid(): boolean {
    return this.welcomeMessageConfigForm.Title.$valid
      && this.welcomeMessageConfigForm.Message.$valid;
  }

  couldSave(): boolean {
    return this.isFormDirty()
      && this.isFormValid();
  }

  hasErrorsFor(validationErrors, propertyName: string) {
    return _.some(validationErrors, ['PropertyName', propertyName]);
  }

  errorMessage(validationErrors, propertyName: string) {
    const propertyErrors = _.filter(validationErrors, ['PropertyName', propertyName]);
    const messages = _.map(propertyErrors, 'ErrorMessage');

    return _.join(messages, ' ');
  }

  setServerErrors(validationErrors, control, propertyName: string) {
    if (!this.hasErrorsFor(validationErrors, propertyName)) {
      return;
    }

    control.$setDirty();
    control.$setValidity('serverErrors', false);
    control.errorMessage = this.errorMessage(validationErrors, propertyName);
  }

  save() {
    this.isSaving = true;
    const request: IClientConfigurationWelcomeMessage = {
      ClientGuid: this.$stateParams.clientGuid,
      Title: this.Title,
      Message: this.Message,
    };
    return this.clientservice
      .saveClientConfigurationWelcomeMessage(request)
      .then(() => {
        this.logger.success('Welcome message configuration saved successfully');
        this.welcomeMessageConfigForm.$setPristine();
      }, (error) => {
        if (error.status === 400) {
          const validationErrors = error.data.ValidationErrors;

          this.setServerErrors(validationErrors, this.welcomeMessageConfigForm.Title, 'Title');
          this.setServerErrors(validationErrors, this.welcomeMessageConfigForm.Message, 'Message');
        }
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  cancel() {
  }
}
