'use strict';

import { VideoStorageConfigurationController } from './videoStorageConfiguration.controller';

const template = require('./videoStorageConfiguration.html');

angular
  .module('insitesApp.clientConfiguration')
  .component('videoStorageConfiguration', {
    controller: VideoStorageConfigurationController,
    controllerAs: 'vm',
    templateUrl: template,
  });
