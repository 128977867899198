'use strict';

export interface IRemoveTopicRequest {
  topicGuid: string
}


export class RemoveTopicRequest implements IRemoveTopicRequest {
  topicGuid: string;

  constructor(topicGuid: string) {
    this.topicGuid = topicGuid;
  }
}
