'use strict';

import { InfoChartController } from './infoChart.controller';

const template = require('./infoChart.html');

export const InfoChartComponent: ng.IComponentOptions = {
  controller: InfoChartController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    graphdata: '<',
  },
};
