'use strict';

import { Utils } from './../../../../../core/utils';
import { ParticipantService } from './../../../../../core/dataservices/participant.service';
import { IActivityItem } from '../../../../../core/dataservices/dataservice.contracts';
import * as _ from 'lodash';
import { SpinnerService } from '../../../../../core/services/spinner.service';
import { ServerConstants } from '../../../../../core/serverconstants';
import { ConstantsFactory } from '../../../../../core/constants.factory';
import { UserQualConversationsExportParams } from '../../../../../core/contracts/download.contract';
import { ActivityService } from '../../../../../core/dataservices/activity.service';

export class MemberDetailsActivitiesController {
  static $inject = ['$stateParams', '$state', 'participantservice', 'spinnerservice', 'serverConstants', '$location',
    '$filter', 'constantsfactory', 'activityservice'];

  SquareParticipantActivities: IActivityItem[];
  order = null;
  TotalReplies = null;
  allExpanded = false;
  onTopNote = '';
  onTopTag = '';
  annotationPosition = {};
  downloadParams: UserQualConversationsExportParams = new UserQualConversationsExportParams();

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private $state: ng.ui.IStateService,
    private participantservice: ParticipantService,
    private spinnerservice: SpinnerService,
    private serverConstants: ServerConstants,
    private $location: ng.ILocationService,
    private $filter: ng.IFilterService,
    private constantsfactory: ConstantsFactory,
    private activityservice: ActivityService,
  ) {
    this.downloadParams.squareParticipantGuid = $stateParams.memberGuid;
  }

  async $onInit() {
    await this.loadActivities();
  }

  async loadActivities() {
    try {
      this.spinnerservice.show('loading');

      const activities = await this.participantservice.listSquareParticipantActivities(this.$stateParams.memberGuid);
      _.each(activities.List, (activity) => {
        activity.StatusLabel = Utils.getEnumValueName(this.serverConstants.squareActivityStatusConstants, activity.Status as any);
        activity.SampleStatusLabel = Utils.getEnumValueName(this.serverConstants.sampleStatusConstants, activity.SampleStatus as any);
        activity.TypeLabel = Utils.getEnumValueName(this.serverConstants.squareActivityTypeConstants, activity.Type as any, false);
        activity.Expanded = false;
      });
      this.TotalReplies = activities.TotalReplies;
      this.downloadParams.stimuliDownloadAvailable
        = (activities.DownloadAvailability & this.serverConstants.conversationsDownloadAvailabilitiesConstants.stimuli)
        === this.serverConstants.conversationsDownloadAvailabilitiesConstants.stimuli;
      this.downloadParams.textDownloadAvailable
        = (activities.DownloadAvailability & this.serverConstants.conversationsDownloadAvailabilitiesConstants.text)
        === this.serverConstants.conversationsDownloadAvailabilitiesConstants.text;
      this.SquareParticipantActivities = this.$filter('orderBy')(activities.List, 'Type', true);

    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  getLabelForActivityType(activityType) {
    return this.constantsfactory.GetLabelForSquareActivityType(activityType);
  }

  getSampleStatusLabel(sampleStatus, outcomeCode, removedFromTargeting: boolean) {
    return Utils.getSampleStatusLabel(this.serverConstants.sampleStatusConstants, this.serverConstants.sampleStatusLabelConstants, sampleStatus,
      this.serverConstants.surveyOutcomeCodeConstants, this.serverConstants.surveyOutcomeCodeLabelConstants, outcomeCode, removedFromTargeting);
  }

  navigateToActivity(activityGuid, $event) {
    this.$state.go('root.square.activitydata', {
      referer: this.$location.url(),
      refererlabel: 'GO TO MEMBER DETAILS',
      clientGuid: this.$stateParams.clientGuid,
      programGuid: this.$stateParams.programGuid,
      squareGuid: this.$stateParams.squareGuid,
      activityGuid,
      members: this.$stateParams.memberGuid,
    });
    $event.stopPropagation();
    $event.preventDefault();
  }

  onVerticalChanged(item, top, height) {
    this.annotationPosition[item.Guid] = {
      _top: top,
      _height: height,
    };
  }

  async showHideConversations(activity) {
    try {
      this.spinnerservice.show('loading');
      activity.Expanded = !activity.Expanded;
      if (activity.Expanded && !activity.Conversations) {
        const response = await this.participantservice.listSquareParticipantConversations(this.$stateParams.memberGuid, activity.Guid);
        activity.Conversations = response.ConversationsByActivity[activity.Guid];
        if (!activity.Conversations) {
          activity.Expanded = !activity.Expanded;
        }
      }
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  showHideAllConversations() {
    this.allExpanded = !this.allExpanded;
    const squareParticipantActivities = this.SquareParticipantActivities;

    if (this.allExpanded) {
      try {
        this.spinnerservice.show('loading');
        this.participantservice.listSquareParticipantConversations(this.$stateParams.memberGuid, null,
        ).then((response) => {
          _.each(squareParticipantActivities, (activity) => {
            activity.Conversations = response.ConversationsByActivity[activity.Guid];
            if (activity.Conversations && activity.Conversations.length) {
              activity.Expanded = true;
            }
          });
        });
      } finally {
        this.spinnerservice.hide('loading');
      }
    } else {
      try {
        this.spinnerservice.show('loading');
        _.each(squareParticipantActivities, (activity) => {

          activity.Expanded = this.allExpanded;
        });
      } finally {
        this.spinnerservice.hide('loading');
      }
    }
  }

  setNoteOnTop(guid, noToggle) {
    if (!noToggle && this.onTopNote === guid) {
      this.onTopNote = '';
    } else {
      this.onTopNote = guid;
      this.onTopTag = '';
    }
  }

  setTagOnTop(guid, noToggle) {
    if (!noToggle && this.onTopTag === guid) {
      this.onTopTag = '';
    } else {
      this.onTopTag = guid;
      this.onTopNote = '';
    }
  }

  setTagAndNoteOnTop(show, item) {
    if (item.Notes.length) {
      this.onTopNote = '';
      if (show) {
        this.onTopNote = item.Guid;
      }
    }
    if (item.Tags.length) {
      this.onTopTag = '';
      if (show) {
        this.onTopTag = item.Guid;
      }
    }
  }


  getItemStyle(item) {
    return {
      'margin-top': `${this.annotationPosition[item.Guid]._top + 10}px`,
      'height': item.Guid === this.onTopTag || item.Guid === this.onTopNote ? undefined : `${this.annotationPosition[item.Guid]._height}px`,
    };
  }

  hideShadowDiv(parentItem) {
    let result = '';
    const target = document.getElementById(`tags_${parentItem.Guid}`);
    if (target && (this.annotationPosition[parentItem.Guid]._height / 2) > target.offsetHeight) {
      result = 'no-shadow';
    }
    return result;
  }

  getItemClass(item) {
    return {
      'notes-filter': false,
      'tags-filter': false,
      'on-top-tag': item.Guid === this.onTopTag,
      'on-top-note': item.Guid === this.onTopNote,
    };
  }

  getReplyLabel(count) {
    return count === 1 ? 'Reply' : 'Replies';
  }

  shouldShowGoToActivityLink(item): boolean {
    return this.activityservice.isQualResearchActivity(item.Type)
      && (item.TotalReplies > 0 || item.SampleStatus !== this.serverConstants.sampleStatusConstants.toDo);
  }
}
