'use strict';

export class LanguageContainer {
  constructor(private labels: Array<{ Name: string, Value: string }>) { }

  [name: string]: any;

  getLabelValue = (name: string) => {

    const label = this.labels.find((item) => item.Name === name);
    return label ? label.Value : '';
  };

  getLabels = () => this.labels;
}
