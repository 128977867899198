'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';
import { ActivityFilterService } from '../../../core/services/activityFilter.service';

const routeConfig = (routerHelper: RouterHelper, activityFilterService: ActivityFilterService) => {
  routerHelper.configureStates([
    {
      state: 'root.square.activities.all',
      config: {
        url: `/?${activityFilterService.getFilterUrlParts()}?page?limit?order?activate?`,
        component: 'activitiesAllTab',
        title: 'Activities',
      },
    },
  ]);
};

routeConfig.$inject = ['routerHelper', 'activityFilterService'];

export default routeConfig;

