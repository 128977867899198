'use strict';

import { InstantRewardsController } from './instantRewards.controller';

const instantRewardsTemplate = require('./instantRewards.html');

export const InstantRewardsComponent: ng.IComponentOptions = {
  controller: InstantRewardsController,
  templateUrl: instantRewardsTemplate,
  controllerAs: 'vm',
};
