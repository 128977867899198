'use strict';

import { RouterHelper } from '../../blocks/router/router-helper';


const appRunActivityHealth = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([
    {
      state: 'root.square.activityhealth',
      config: {
        url: '/activity/:activityGuid/health?referer',
        component: 'activityHealthComponent',
        title: 'Progress',
        settings: {
          nav: 3,
          section: 'ACTIVITY MANAGEMENT',
          icon: 'fa fa-chart-bar',
          label: 'Progress',
        },
      },
    },
  ]);
};

appRunActivityHealth.$inject = ['routerHelper'];


angular.module('insitesApp.activityHealth')
  .run(appRunActivityHealth);
