'use strict';

import { AuthService } from '../../core/dataservices/auth.service';

export class LoginErrorController {
  static $inject = ['authService'];

  logoutUrl: string;

  constructor(
    private authService: AuthService,
  ) {
    // goes to login page via logout route
    this.authService.getSquareLogoutUrl().then((url) => {
      this.logoutUrl = url;
    });
  }
}
