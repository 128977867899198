'use strict';

import { TimezoneDropdownController } from './timezone-dropdown.controller';

const template = require('./timezone-dropdown.html');

export const TimezoneDropdownComponent: ng.IComponentOptions = {
  controller: TimezoneDropdownController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    timezone: '=',
    isDisabled: '<',
    onChange: '&',
  },
};
