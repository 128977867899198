'use strict';

import { ArchiveButtonController } from './archive-button.controller';
const template = require('./archive-button.html');

export const ArchiveButtonComponent: ng.IComponentOptions = {
  controller: ArchiveButtonController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    item: '=',
    list: '=',
    menuItem: '<',
    archiveType: '<',
  },
};
