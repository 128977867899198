'use strict';

import { ConversationDataModerationService } from '../../../../core/dataservices/conversationDataModeration.service';
import { ConversationEventsService } from '../../../../core/services/conversationEvents.service';
import { SpinnerService } from '../../../../core/services/spinner.service';

export class ActivityDataPostNotesController {
  static $inject = ['$scope', '_', '$stateParams', 'conversationDataModerationService', '$mdDialog', 'spinnerservice', 'logger', 'conversationEventsService'];

  constructor(
    private $scope: ng.IScope,
    private _: _.LoDashStatic,
    private $stateParams: ng.ui.IStateParamsService,
    private conversationDataModerationService: ConversationDataModerationService,
    private $mdDialog: ng.material.IDialogService,
    private spinnerservice: SpinnerService,
    private logger: Logger,
    private conversationEventsService: ConversationEventsService,
  ) {
  }

  _newNoteText: string;
  _editingNote: boolean = false;
  items;
  conversationGuid;
  readOnly: boolean;
  editClick(note) {
    note.oldText = note.Text;
    note.isEdit = true;
    this._editingNote = true;
    this.conversationEventsService.discussionChangeStarted(this.$stateParams.activityGuid);
  }
  resetNote(note) {
    note.Text = note.oldText;
    note.isEdit = false;
    this.conversationEventsService.discussionChangeEnded(this.$stateParams.activityGuid);
  }

  async addNote() {
    if (this.newNoteText && this.newNoteText !== '') {
      try {
        this.spinnerservice.show('loading');
        const note = await this.conversationDataModerationService.addNote({ Text: this.newNoteText, ConversationGuid: this.conversationGuid });
        if (note && note.data) {
          this.items.push(note.data);
        }
        this.newNoteText = '';
      } catch (error) {
        if (error.status === 400) {
          this.validationError(error.data);
        }
      } finally {
        this.spinnerservice.hide('loading');
      }
    } else {
      this.logger.error("The conversation note text can't be null or empty");
    }
  }
  resetNew() {
    this.newNoteText = '';
    this.conversationEventsService.discussionChangeEnded(this.$stateParams.activityGuid);
  }
  async editNote(note) {
    if (note && note.Text && note.Text !== '') {
      try {
        this.spinnerservice.show('loading');
        await this.conversationDataModerationService.updateNote(note);
        note.isEdit = false;
      } catch (error) {
        if (error.status === 400) {
          this.validationError(error.data);
        }
      } finally {
        this.spinnerservice.hide('loading');
      }
    } else {
      this.logger.error("The conversation note text can't be null or empty");
    }
  }
  async deleteNote(note) {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: 'Delete conversation note',
      text: 'Do you want to delete this note?',
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      this.spinnerservice.show('loading');
      const index = this.items.indexOf(note);
      try {
        this.conversationDataModerationService.deleteNote(note).then(() => {
          if (index !== -1) {
            this.items.splice(index, 1);
          }
        });
      } catch (error) {
        if (error.status === 400) {
          this.validationError(error.data);
        }
      } finally {
        this.spinnerservice.hide('loading');
      }
    });
  }
  private validationError(error) {
    let message = '';
    this._.forEach<any[]>(error.ValidationErrors, (validationError: any) => {
      message += `${validationError.ErrorMessage} `;
    });

    if (message.length > 0) {
      this.logger.error(message);
    }
  }

  get newNoteText() {
    return this._newNoteText;
  }

  set newNoteText(value) {
    this._newNoteText = value;
    if (value) {
      if (!this._editingNote) {
        this.conversationEventsService.discussionChangeStarted(this.$stateParams.activityGuid);
      }
      this._editingNote = true;
    } else {
      this.conversationEventsService.discussionChangeEnded(this.$stateParams.activityGuid);
      this._editingNote = false;
    }
  }
}
