'use strict';

import { WizardTargetingController } from './wizard-targeting.controller';

const template = require('./wizard-targeting.html');
require('./wizard-targeting.scss');
export const WizardTargetingComponent: ng.IComponentOptions = {
  controller: WizardTargetingController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    nextStepLabel: '@',
    saveActivityTargeting: '<',
    communication: '<',
    isReadOnly: '<',
    isPublished: '<',
    squareActivity: '=',
  },
  require: {
    wizardStep: '^^iscWizardStep',
    wizard: '^^iscWizard',
  },
};
