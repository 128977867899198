'use strict';

export class AutoGrowTextArea implements ng.IDirective {
  restrict = 'A';
  require = 'ngModel';
  scope = {
    options: '=',
    model: '=ngModel',
  };

  link(scope, element) {
    element.on('keyup', () => {
      const scrollHeight = element[0].scrollHeight;
      element[0].style.height = `${scrollHeight}px`;
    });
    scope.$watch('model', (newValue) => {
      if (newValue === '') {
        element[0].style.height = '40px';
      }
    });
  }
}
