'use strict';

export class Pagination implements IPagination {
  page: number;
  limit: number;
  total: number;
  limitOptions: number[];
  public static defaultLimitOptions: number[] = [5, 10, 15, 25, 40];
  public static defaultLimit = 10;

  constructor(
    limit = Pagination.defaultLimit) {
    this.page = 1;
    this.limit = limit;
    this.total = 0;
    this.limitOptions = Pagination.defaultLimitOptions;
  }
}
