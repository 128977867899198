'use strict';

import { UserNotificationController } from './user-notification.controller';

const template = require('./user-notification.html');

export const UserNotificationComponent: ng.IComponentOptions = {
  controller: UserNotificationController,
  templateUrl: template,
  controllerAs: 'vm',
};
