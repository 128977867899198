'use strict';

import { DateTime } from 'luxon';

export class NoticeDateTimeController {
  static $inject = ['$scope'];
  modelTime: DateTime;
  model: DateTime;
  modelChange: () => void;
  constructor(
    private $scope: ng.IScope,
  ) {}


  $onInit(): void {
    this.$scope.$watch('vm.model', () => {
      // using watch because the parent updates the model after server response.
      this.modelTime = this.model;
    });
  }

  onDateTimeChange() {
    if (this.modelTime) {
      this.model = this.model.set({ hour: this.modelTime.hour, minute: this.modelTime.minute });
    }
    this.modelChange();
  }
}
