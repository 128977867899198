'use strict';

import { SsoIdeaStreamController } from './ssoIdeaStream.controller';

const template = require('./ssoIdeaStream.html');

export const SsoIdeaStreamComponent: ng.IComponentOptions = {
  controller: SsoIdeaStreamController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    ctaUrl: '<',
    projectidUrl: '=',
    isSsoEnabled: '=',
  },
};
