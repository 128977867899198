'use strict';

import { DateTime } from 'luxon';

export class CardChannelModel {
  private _callToActionUrl = '';
  private _editCtaUrl = 'https://';
  Guid = '';
  CallToActionText = '';
  Title = '';
  Overview = '';
  Message = '';
  Files = [];
  StartDateTime = DateTime.now();
  StartTime = DateTime.now();
  EndDateTime = DateTime.now().plus({ months: 1 });
  EndTime = DateTime.now().plus({ months: 1 });
  StartDateOffset = DateTime.local().offset / 60;
  EndDateOffset = DateTime.local().offset / 60;
  Stimuli = [];
  Sticky = false;
  OpenInNewTab = true;
  ShowOnHomepage = true;
  get CallToActionUrl() {
    return this._callToActionUrl;
  }
  set CallToActionUrl(value) {
    this._callToActionUrl = value; this.onCtaTextUrlChanged();
  }
  get EditCTAUrl() {
    return this._editCtaUrl;
  }
  set EditCTAUrl(value) {
    this._editCtaUrl = value; this.onCtaTextUrlChanged();
  }

  constructor(private onCtaTextUrlChanged: () => void) {
  }
}
