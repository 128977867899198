'use strict';

import { DateTime } from 'luxon';
import { CommunicationService } from '../../../core/dataservices/communication.service';
import { ActivityService } from '../../../core/dataservices/activity.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { DateFormatService } from '../../../core/services/dateformat.service';
import { ServerConstants } from '../../../core/serverconstants';

export class CommunicationWizardController {
  static $inject = [
    '_',
    'serverConstants',
    '$stateParams',
    'dateFormatService',
    'communicationservice',
    'activityservice',
    'spinnerservice',
    '$state',
  ];
  constructor(
    private _: _.LoDashStatic,
    private serverConstants: ServerConstants,
    private $stateParams: ng.ui.IStateParamsService,
    private dateFormatService: DateFormatService,
    private communicationservice: CommunicationService,
    private activityservice: ActivityService,
    private spinnerservice: SpinnerService,
    private $state: ng.ui.IStateService,
    private communication,
    private isReadOnly,
  ) { }

  targetingReadOnly = false;
  footerReadOnly = false;
  reminderSubstepReadOnly = false;
  incompleteSubstepReadOnly = false;
  removeLink;
  messageTitle = '';
  communicationTitle = '';
  channelTypeConstants = this.serverConstants.channelTypeConstants;
  loading = true;

  set Communication(value) {
    this.communication = value;
  }

  async $onInit() {
    this.spinnerservice.show('loading');
    this.isReadOnly = this.footerReadOnly = this.$stateParams.status === this.serverConstants.communicationStatusConstants.closed.toString();
    await this.loadSpecifications();
    this.spinnerservice.hide('loading');
  }

  async loadSpecifications() {
    const response = await this.communicationservice.selectCommunication(this.$stateParams.communicationGuid);
    this.communication = response;
    if (this.communication &&
      this.communication.Channels &&
      this.communication.Channels[0] &&
      this.communication.Channels[0].Channel) {
      await this.handleReadonlyState(this.communication.Channels[0].Channel, this.communication);
    }
    this.communicationTitle = this.communication.Communication.Title;
  }

  private async handleReadonlyState(channel, communication: any) {
    if (channel.EndDateTime) {
      this.isReadOnly = false;
    }
    this.targetingReadOnly = this.isReadOnly;
    switch (channel.ChannelType) {
      case this.channelTypeConstants.customAppNotification:
      case this.channelTypeConstants.emailNewsletter:
        if (this.$stateParams.status === this.serverConstants.communicationStatusConstants.active.toString() ||
          this.$stateParams.status === this.serverConstants.communicationStatusConstants.closed.toString()) {
          this.targetingReadOnly = true;
          this.isReadOnly = true;
          this.footerReadOnly = true;
        }
        break;
      case this.channelTypeConstants.researchActivityEmail:
        if (this.$stateParams.status === this.serverConstants.communicationStatusConstants.active.toString() ||
          this.$stateParams.status === this.serverConstants.communicationStatusConstants.closed.toString()) {
          this.targetingReadOnly = true;
          this.isReadOnly = true;
          this.footerReadOnly = true;
        }
        if (this.$stateParams.status === this.serverConstants.communicationStatusConstants.active.toString()) {
          if (communication && communication.Channels.length > 1) {
            const reminder = communication.Channels.find((cc) => cc.Channel.ChannelType === this.channelTypeConstants.researchActivityReminder);
            if (reminder) {
              const response = await this.activityservice.getActivityDetailsForMessage(communication.Communication.ActivityGuid);
              const activityDetails = response.data.Detail;
              const activityStartDate = this.dateFormatService.getDateTime(activityDetails.StartDate, activityDetails.Offset);
              const delay = activityStartDate.plus({ hours: reminder.Channel.SendEmailDelay });
              this.reminderSubstepReadOnly = DateTime.utc() > delay && reminder.Channel.IsPublished;
            }
          }
        }
        if (this.$stateParams.status === this.serverConstants.communicationStatusConstants.closed.toString()) {
          this.incompleteSubstepReadOnly = this.reminderSubstepReadOnly = true;
        }
        break;
    }
  }

  getPublishStepSubtitle() {
    const defaultTitle = 'Review & publish Communication';
    if (this.communication && this.communication.Channels[0] && this.communication.Channels[0].Channel) {
      switch (this.communication.Channels[0].Channel.ChannelType) {
        case this.channelTypeConstants.emailNewsletter:
        case this.channelTypeConstants.researchActivityEmail:
          return 'Review, test & publish Communication';
        default:
          return defaultTitle;
      }
    }
    return defaultTitle;
  }
}
