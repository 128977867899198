'use strict';

import * as _ from 'lodash';

export class SelectIconDialogController {
  static $inject = ['$mdDialog', 'usedIcons', 'selectedIcon'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private usedIcons,
    private selectedIcon,
  ) { }

  isIconUsed = (icon) => _.includes(this.usedIcons, icon);

  selectIcon = (iconName) => {
    this.usedIcons.push(iconName);
    if (this.selectedIcon) {
      this.usedIcons.splice(this.usedIcons.indexOf(this.selectedIcon), 1);
    }
    this.$mdDialog.hide(iconName);
  };

  cancel = () => this.$mdDialog.cancel();
}
