'use strict';

import { Logger } from '../../blocks/logger/logger';
import { SegmentationService } from '../../core/dataservices/segmentation.service';
import * as _ from 'lodash';

export class ToggleActivitySegmentationButtonController {
  static $inject = ['logger', 'spinnerservice', 'segmentationService', '$mdDialog'];

  activityGuid: string;
  segmentationGuid: string;
  isEnabled: boolean = Boolean(false);
  activityName: string;

  constructor(
    private logger: Logger,
    private spinnerservice,
    private segmentationService: SegmentationService,
    private $mdDialog: ng.material.IDialogService,
  ) { }

  async $onInit() {
    this.isEnabled = Boolean(this.segmentationGuid);
  }

  async toggleSegmentation() {
    let promise;
    if (this.isEnabled) {
      promise = this.disableSegmentation();
    } else {
      promise = this.enableSegmentation();
    }
    this.spinnerservice.showFor('loading', promise);
  }

  async enableSegmentation() {
    return this.segmentationService.toggleActivitySegmentation(this.activityGuid, false)
      .then((response) => {
        this.segmentationGuid = response.data.SegmentationGuid;
        this.isEnabled = Boolean(this.segmentationGuid);
        this.activityName = response.data.SegmentationName;
        this.logger.success(`Segmentation has been enabled (${response.data.SegmentationName})`);
      }, (error) => {
        if (error.status === 400) {
          const data = error.data;
          let message = '';
          _.forEach<any[]>(data.ValidationErrors, (validationError: any) => {
            message += `${validationError.ErrorMessage} `;
          });
          if (message.length > 0) {
            this.logger.error(message);
          }
        }
      });
  }

  async disableSegmentation() {
    this.$mdDialog.show(this.$mdDialog.iscConfirm({
      title: 'Disable segmentation',
      text: `Are you sure you want to disable the segmentation ${this.activityName}?`,
      ok: 'Yes',
      cancel: 'No',
    })).then(() => {
      this.segmentationService.toggleActivitySegmentation(this.activityGuid, true)
        .then(() => {
          this.segmentationGuid = null;
          this.isEnabled = false;
          this.logger.success(`Segmentation has been disabled (${this.activityName})`);
          this.activityName = null;
        }, (error) => {
          this.$mdDialog.show(this.$mdDialog.iscAlert({
            title: 'Disable segmentation',
            text: error.data.Message,
            ok: 'Got it!',
          }));
        });
    });
  }
}
