import { UiModule } from '../../../index';
var template = "<md-radio-group ng-model=\"vm.model\" ng-change=\"vm.onChange()\" ng-disabled=\"vm.isDisabled\" ng-required=\"vm.isRequired\" aria-label=\"{{vm.ariaLabel}}\" >\n  <div ng-transclude=\"\" ></div>\n</md-radio-group>";
UiModule
    .component('iscRadioGroup', {
    template: template,
    controllerAs: 'vm',
    transclude: true,
    bindings: {
        model: '=',
        onChange: '&?',
        isDisabled: '<?',
        isRequired: '<',
        ariaLabel: '@',
    },
});
