'use strict';

import { ActivityQualResearchConfigComponent } from './activityQualResearchConfig.component';

import './activityQualResearchConfig.module';
import './activityQualSteps';

angular
  .module('insitesApp.activityQualResearchConfig')
  .component('activityQualResearchConfigComponent', ActivityQualResearchConfigComponent);
