'use strict';

import { MemberActionBarController } from './member-action-bar.controller';
const template = require('./member-action-bar.html');
require('./member-action-bar.scss');

angular.module('insitesApp.squaremembers.memberOverview')
  .component('memberActionBar', {
    controller: MemberActionBarController,
    controllerAs: 'vm',
    templateUrl: template,
    bindings: {
      selectedSquareParticipantGuids: '<',
      totalMembers: '<',
      onBulkActionFinished: '&',
    },
  });
