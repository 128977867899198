'use strict';

import { DateTime } from 'luxon';
import { SpinnerService } from '../core/services/spinner.service';
import { LogService } from '../core/dataservices/log.service';
import { HelperService } from '../core/services/helper.service';
import * as _ from 'lodash';

const logDetails = require('./logDetailsDialog.html');

export class LogController {
  static $inject = ['$q', 'spinnerservice', 'logservice', 'helperservice', '$mdDialog', '$timeout'];
  constructor(
    private $q: ng.IQService,
    private spinnerservice: SpinnerService,
    private logservice: LogService,
    private helperservice: HelperService,
    private $mdDialog: ng.material.IDialogService,
    private $timeout: ng.ITimeoutService) {
    this.endDate = DateTime.now().toJSDate();
    this.startDate = DateTime.now().minus({ days: 1 }).toJSDate();
    this.maxEndDate = DateTime.now().toJSDate();
    this.isFetching = false;
    this.severity = '';
    this.clientCode = '';
    this.message = '';
    this.url = '';
    this.Severities = [
      {
        Label: 'Debug',
        Level: 0,
      },
      {
        Label: 'Info',
        Level: 1,
      },
      {
        Label: 'Warning',
        Level: 2,
      },
      {
        Label: 'Error',
        Level: 3,
      },
      {
        Label: 'Critical',
        Level: 4,
      },
    ];
    this.limitOptions = [100, 250, 500, 1000, 2000];
    this.limit = '';
  }
  endDate;
  startDate;
  maxEndDate;
  isFetching;
  severity;
  clientCode;
  message;
  url;

  Severities: Array<{ Label: string, Level: number }>;

  limitOptions;
  limit;
  filter;
  entries;

  $onInit(): void {
    this.defaultSeverityValue();
    this.defaultLimitValue();
    this.activate();
  }

  search = (filter) => this.helperservice.search(filter, this.filter);


  filterChanged = () => {
    this.$timeout(() => {
      this.activate();
    }, 100);
  };

  activate = () => {
    this.spinnerservice.show('loading');
    const promises = [this.getLogList()];
    return this.$q.all(promises)
      .finally(() => {
        this.spinnerservice.hide('loading');
      });
  };

  getSeverityLabel = _.memoize((level) => {
    const severity = _.find(this.Severities, (l) => l.Level === level);
    const severityLabel = severity ? severity.Label : 'Unknown';
    return `${level} - ${severityLabel}`;
  });

  limitMessage = (message) => {
    if (message && message.length > 200) {
      return `${message.substring(0, 200)}...`;
    }
    return message;
  };

  openEntry = (entry) => {
    this.$mdDialog.show({
      controllerAs: 'vm',
      controller: _.noop,
      bindToController: true,
      templateUrl: logDetails,
      clickOutsideToClose: true,
      fullscreen: false,
      locals: {
        entry,
        getSeverityLabel: this.getSeverityLabel,
        thingsToShow: Object.keys(entry),
        close: () => {
          this.$mdDialog.hide();
        },
      },
    });
  };

  getLogList = () => this.logservice.getLogList(this.startDate, this.endDate, this.severity, this.limit,
    this.clientCode, this.message, this.url)
    .then((response) => {
      const data = response.data.List;
      this.entries = data;
      return this.$q.resolve(response);
    });

  defaultSeverityValue = () => {
    this.severity = 4;
  };

  defaultLimitValue = () => {
    this.limit = 100;
  };
}
