'use strict';

import { HttpService } from './http.service';
import { IChannelPublishOption } from './dataservice.contracts';

export class CommunicationService {
  static $inject = ['httpservice'];

  constructor(private httpService: HttpService) { }

  async getCommunicationMessages(pageNumber: number, limit: number, order: string, statuses: string[], generalType: string, keyword: string) {
    return this.httpService.get({
      url: '/CommunicationService/ListCommunicationChannelDetails',
      params: {
        pageNumber,
        limit,
        order,
        statuses,
        generalType,
        keyword,
      },
    });
  }

  getListNewsPages() {
    return this.httpService.post({
      url: '/CommunicationService/ListNewsPages',
    }).then((response) =>  response.data);
  }

  removeCommunication(communicationGuid) {
    return this.httpService.post({
      url: '/CommunicationService/RemoveCommunication',
      data: {
        CommunicationGuid: communicationGuid,
      },
    });
  }

  updateEngagement(communicationData) {
    return this.httpService.upload({
      url: '/CommunicationService/UpdateEngagement',
      data: communicationData ,
    });
  }

  createUpdateActivityEmailChannels(communicationGuid, activityGuid, channels) {
    return this.httpService.post({
      url: '/CommunicationService/CreateUpdateActivityEmailChannels',
      data: { CommunicationGuid: communicationGuid, ActivityGuid: activityGuid, CommunicationChannels: channels },
    });
  }

  updateActivityEmail(communicationGuid, channel) {
    return this.httpService.post({
      url: '/CommunicationService/UpdateActivityEmail',
      data: { CommunicationGuid: communicationGuid, CommunicationChannel: channel },
    });
  }

  publishCommunication(communicationGuid, channelPublishOptions: IChannelPublishOption[]) {
    return this.httpService.post({
      url: '/CommunicationService/PublishCommunication',
      data: { CommunicationGuid: communicationGuid, ChannelPublishOptions: channelPublishOptions },
    });
  }

  updateCommunicationTitle(communicationGuid, title) {
    return this.httpService.post({
      url: '/CommunicationService/UpdateCommunicationTitle',
      data: { CommunicationGuid: communicationGuid, Title: title },
    });
  }

  async getOrSetCommunicationProfileActivity(communicationData) {
    const response = await this.httpService.post({
      url: '/CommunicationService/GetOrSetCommunicationProfileActivity',
      data: communicationData,
    });
    return response.data;
  }

  getCommunicationFooters() {
    return this.httpService.get({
      url: '/CommunicationService/GetCommunicationFooterList',
    }).then((response) => response.data);
  }

  getCommunicationDefaultFooter() {
    return this.httpService.get({
      url: '/CommunicationService/GetCommunicationDefaultFooter',
    }).then((response) => response.data);
  }

  hardLaunchCommunicationChannel(guid) {
    return this.httpService.post({
      url: '/CommunicationService/HardLaunchCommunicationChannel',
      data: { channelGuid: guid },
    }).then((response) => response.data);
  }

  researchActivitySendTestMail(postData) {
    return this.httpService.post({
      url: '/CommunicationService/ResearchActivitySendTestMail',
      data: postData,
    }).then((response) => response.data);
  }

  downloadCommunicationData() {
    return this.httpService.post({
      url: '/ExportService/ExportCommunicationData',
    });
  }

  sendNewsletterTestEmail(data) {
    return this.httpService.post({
      url: '/CommunicationService/SendNewsletterTestEmail',
      data,
    });
  }

  deleteCommunicationMessage(channelGuid, fromCommunicationGuid) {
    return this.httpService.post({
      url: '/CommunicationService/DeleteCommunicationChannel',
      data: {
        ChannelGuid: channelGuid,
        CommunicationGuid: fromCommunicationGuid,
      },

    }).then((response) => response.data);
  }

  async selectCommunication(communicationGuid: string) {
    return this.httpService.get({
      url: '/CommunicationService/SelectCommunication',
      params: { Guid: communicationGuid },
    }).then((response) => response.data);
  }

  createCommunication(communicationTitle) {
    return this.httpService.post({
      url: '/CommunicationService/CreateCommunication',
      data: { Title: communicationTitle },
    });
  }

  communicationExistsForActivity(activityGuid) {
    return this.httpService.post({
      url: '/CommunicationService/CommunicationExistsForActivity',
      params: { ActivityGuid: activityGuid },
    });
  }

  updateCommunicationChannel(channel) {
    return this.httpService.post({
      url: '/CommunicationService/CreateCommunicationChannel',
      data: channel,
    });
  }

  updateLastSelectedDate(communicationGuid: string) {
    return this.httpService.post({
      url: '/CommunicationService/UpdateLastSelectedDate',
      params: {
        communicationGuid,
      },
    });
  }

  uploadEditorImage(file) {
    return this.httpService.upload({
      url: '/CommunicationService/UploadEditorImage',
      data: { files: [file] },
    }).then((response) => response.data);
  }

  toggleStickyForCommunicationChannel(communicationChannelGuid: string) {
    return this.httpService.post({
      url: '/CommunicationService/ToggleStickyForCommunicationChannel',
      params: {
        communicationChannelGuid,
      },
    });
  }

  toggleShowOnHomepageForCommunicationChannel(communicationChannelGuid: string) {
    return this.httpService.post({
      url: '/CommunicationService/ToggleShowOnHomepageForCommunicationChannel',
      params: {
        communicationChannelGuid,
      },
    });
  }

  getSquareAvailablePageUrls() {
    return this.httpService.post({
      url: '/CommunicationService/GetSquareAvailablePageUrls',
    }).then((response) => response.data);
  }

  async getCommunicationWithStimuliForActivityQual(activityGuid: string) {
    return this.httpService.get({
      url: '/CommunicationService/GetCommunicationWithStimuliForActivityQual',
      params: { ActivityGuid: activityGuid },
    }).then((response) => response.data);
  }

}
