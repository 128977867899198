'use strict';


import { ClientInfoController } from './clientinfo.controller';
const template = require('./clientinfo.html');
require('./clientinfo.scss');

export const ClientInfoComponent: ng.IComponentOptions = {
  controller: ClientInfoController,
  templateUrl: template,
  controllerAs: 'vm',
};
