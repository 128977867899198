'use strict';

import { IPrepareSurveyFinished, ITargetingNumbersUpdated, StimulusTranscriptionStatusChanged } from './../contracts/activity.contract';
import { IUserNotification, IStimuliUploaded, IAttachmentUploaded } from './notifications.contracts';
import { AuthService } from './../dataservices/auth.service';
import { EventPubSub } from 'isc-ui/dist/event/eventpubsub';
import { NotificationsServiceBase } from './notificationservicebase';
import { SelectedSquareFactory } from '../selectedsquare.factory';
import { CurrentUserService } from '../dataservices/currentUser.service';
import { ForumService } from '../dataservices/forum.service';
import { PostChanged } from '@insites/vue-eco-temp-library/dist/types/SquareUi/src/components/user-notification-base/user-notification-base.types';

export class NotificationsService extends NotificationsServiceBase {
  static $inject = [
    'signalr',
    'logger',
    '$rootScope',
    '$q',
    '__env',
    'authService',
    'selectedSquareFactory',
    '$stateParams',
    'currentUserService',
    'forumservice',
  ];

  readonly forumConversationChanged: EventPubSub<string>;
  readonly discussionChanged: EventPubSub<string>;
  readonly discussionNewChanged: EventPubSub<PostChanged>;
  readonly discussionModerationDataChanged: EventPubSub<string>;
  readonly userNotification: EventPubSub<IUserNotification[]>;
  readonly showVideoThumbnail: EventPubSub<IStimuliUploaded>;
  readonly showPhotoThumbnail: EventPubSub<IStimuliUploaded>;
  readonly updateAttachmentUrl: EventPubSub<IAttachmentUploaded>;
  readonly userLoggedOut: EventPubSub<void>;
  readonly prepareSurveyFinished: EventPubSub<IPrepareSurveyFinished>;
  readonly targetingNumbersUpdated: EventPubSub<ITargetingNumbersUpdated>;
  readonly stimulusTranscriptionStatusChanged: EventPubSub<StimulusTranscriptionStatusChanged>;
  private readonly tokenChangeListeners: Array<() => void> = [];

  constructor(
    signalr,
    logger,
    $rootScope: ng.IRootScopeService,
    $q: ng.IQService,
    __env: Environment,
    authService: AuthService,
    private selectedSquareFactory: SelectedSquareFactory,
    private stateParams: ng.ui.IStateParamsService,
    private currentUserService: CurrentUserService,
    forumservice: ForumService,
  ) {
    super(signalr, logger, $rootScope, $q, __env, authService, forumservice);

    this.$rootScope.$on('acquireTokenSuccess', () => this.callOnTokenChangeListeners());
    this.$rootScope.$on('loginSuccess', () => this.callOnTokenChangeListeners());
    this.forumConversationChanged = this.createEvent<string>('Notifications', 'forumConversationChanged');
    this.discussionChanged = this.createEvent<string>('Notifications', 'discussionChanged');
    this.discussionNewChanged = this.createEvent<PostChanged>('Notifications', 'discussionNewChanged');
    this.discussionModerationDataChanged = this.createEvent<string>('Notifications', 'discussionModerationDataChanged');
    this.userNotification = this.createEvent<IUserNotification[]>('Notifications', 'UserNotificationAdmin');
    this.showVideoThumbnail = this.createEvent<IStimuliUploaded>('Notifications', 'ShowVideoThumbnail');
    this.showPhotoThumbnail = this.createEvent<IStimuliUploaded>('Notifications', 'ShowPhotoThumbnail');
    this.updateAttachmentUrl = this.createEvent<IAttachmentUploaded>('Notifications', 'UpdateAttachmentUrl');
    this.userLoggedOut = this.createEvent<void>('Notifications', 'userLoggedOut');
    this.prepareSurveyFinished = this.createEvent<IPrepareSurveyFinished>('Notifications', 'PrepareSurveyFinished');
    this.targetingNumbersUpdated = this.createEvent<ITargetingNumbersUpdated>('Notifications', 'TargetingNumbersUpdated');
    this.stimulusTranscriptionStatusChanged = this.createEvent<StimulusTranscriptionStatusChanged>('Notifications', 'StimulusTranscriptionStatusChanged');
  }

  public init() {
    this.selectedSquareFactory.squareInfoObservable.subscribe((squareInfo) => {
      if (squareInfo.Guid) {
        this.connect();
      } else {
        this.disconnect();
      }
    });
    this.currentUserService.impersonatedUserChange.subscribe(() => {
      this.reConnect();
    });
    this.userLoggedOut.subscribe(() => {
      // LogOutOnAllSquares = false to prevent endless loop
      this.authService.logout(false);
    });
  }

  protected reConnect() {
    this.disconnect();
    this.connect();
  }

  protected getSignalRToken(): ng.IPromise<string> {
    return this.authService.getOrRenewSignalRToken();
  }

  protected addOnTokenChangeListener(fn: () => void): () => void {
    this.tokenChangeListeners.push(fn);
    return () => {
      const index = this.tokenChangeListeners.indexOf(fn);
      if (index !== -1) {
        this.tokenChangeListeners.splice(index, 1);
      }
    };
  }

  private callOnTokenChangeListeners() {
    for (const listener of this.tokenChangeListeners) {
      try {
        listener();
      } catch (e) {
        this.logger.error('Error in token change listener.', e);
      }
    }
  }
}

angular
  .module('insitesApp.core')
  .service('notifications', NotificationsService);
