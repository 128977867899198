'use strict';

export class ParticipantFactory {

  static $inject = ['__env', '$stateParams'];

  static defaultAvatar: string = 'images/default-profile.svg';

  constructor(
    private __env: Environment,
    private $stateParams: ng.ui.IStateParamsService,
  ) { }

  getParticipantImageUrl(participantGuid: string, datemodified): string {
    if (!participantGuid) {
      return ParticipantFactory.defaultAvatar;
    }

    let envPrefix = '';
    if (this.__env.isEnvironmentAsPrefix) {
      envPrefix = `${this.__env.environment.toLowerCase()}/`;
    }

    const avatarUrl = `${this.__env.azureBaseUrl + envPrefix + this.$stateParams.clientGuid
    }/${this.$stateParams.squareGuid}/participant-avatars/${participantGuid}.jpg${datemodified ? `?ts=${new Date(datemodified).getTime()}` : ''}`;
    return avatarUrl;
  }

  getAvatar(item): string {
    return item.ImpersonatorGuid ?
      item.ImpersonatorHasProfileImage ?
        this.getParticipantImageUrl(item.ImpersonatorGuid, item.ImpersonatorDateModified) :
        ParticipantFactory.defaultAvatar :
      item.SquareParticipantHasProfileImage ?
        this.getParticipantImageUrl(item.SquareParticipantGuid, item.SquareParticipantDateModified) :
        ParticipantFactory.defaultAvatar;
  }
}

angular
  .module('insitesApp.core')
  .service('participantFactory', ParticipantFactory);
