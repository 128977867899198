import { getAccessibleName } from 'accname';
import * as _ from 'lodash';
var NavigationHelperService = (function () {
    function NavigationHelperService($timeout, $mdDialog, $q) {
        var _this = this;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.$q = $q;
        this.camelToFlat = function (c) {
            if (!/^([a-z]+[A-Z]*)+$/.test(c)) {
                return c;
            }
            c = c.replace(/[A-Z]/g, ' $&');
            return c[0].toUpperCase() + c.slice(1).toLowerCase();
        };
        this.getErrors = function (errors) {
            var errorLabels = [];
            for (var error in errors) {
                if (errors.hasOwnProperty(error)) {
                    errors[error].forEach(function (field) {
                        if (field) {
                            var label = getAccessibleName(field.$$element[0]);
                            if (label) {
                                errorLabels.push(_this.camelToFlat(label));
                            }
                            else if (field.$name) {
                                errorLabels.push(_this.camelToFlat(field.$name));
                            }
                        }
                    });
                }
            }
            return _.uniq(errorLabels);
        };
    }
    NavigationHelperService.prototype.onNavigate = function (canNavigate, isDirty, errors, saveNotApplicable, customMessage, navigationErrorMessage, transitionCallback, saveCallback, customValidation, isWizardStep, resetFormCallback, hideSaveDraftBtn) {
        var _this = this;
        if (customValidation !== undefined) {
            var validationResult = customValidation();
            isDirty = validationResult.isDirty;
            canNavigate = validationResult.canNavigate;
            hideSaveDraftBtn = validationResult.hideSaveDraftBtn || hideSaveDraftBtn;
            navigationErrorMessage = validationResult.navigationErrorMessage || navigationErrorMessage;
        }
        if (!canNavigate) {
            var errorFields_1 = "<ul>".concat(this.getErrors(errors).map(function (err) { return "<li>".concat(err, "</li>"); }).join(''), "</ul>");
            this.$timeout(function () {
                _this.$mdDialog.show(_this.$mdDialog.iscAlert()
                    .title('Action Required')
                    .text(navigationErrorMessage.replace('$errors', errorFields_1))
                    .ok('Got it!'));
            });
            return this.$q.reject();
        }
        else if (isDirty) {
            if (!customMessage) {
                customMessage = 'Do you want to leave and lose your changes?';
            }
            if (saveNotApplicable) {
                this.$mdDialog.show(this.$mdDialog
                    .iscConfirm()
                    .text(customMessage)
                    .ok('Leave')
                    .cancel('Stay')
                    .clickOutsideToClose(true)).then(function () {
                    if (isWizardStep) {
                        resetFormCallback();
                    }
                    transitionCallback();
                }, function () {
                });
            }
            else if (!hideSaveDraftBtn) {
                this.$mdDialog.show(this.$mdDialog
                    .iscConfirm()
                    .text(customMessage)
                    .stay('Stay')
                    .cancel('Leave')
                    .ok('Save and leave')).then(function () {
                    saveCallback().then(function () {
                        transitionCallback();
                    });
                }, function (stay) {
                    if (!stay) {
                        if (isWizardStep) {
                            resetFormCallback();
                        }
                        transitionCallback();
                    }
                });
            }
            else {
                this.$mdDialog.show(this.$mdDialog
                    .iscConfirm()
                    .text('Do you want to leave and lose your changes?')
                    .stay('Stay')
                    .cancel('Leave')).then(function () {
                    saveCallback().then(function () {
                        transitionCallback();
                    });
                }, function (stay) {
                    if (!stay) {
                        if (isWizardStep) {
                            resetFormCallback();
                        }
                        transitionCallback();
                    }
                });
            }
            return this.$q.reject();
        }
        else if (isWizardStep) {
            transitionCallback();
        }
    };
    NavigationHelperService.$inject = ['$timeout', '$mdDialog', '$q'];
    return NavigationHelperService;
}());
export { NavigationHelperService };
