'use strict';

const activitiesQualTabTemplate = require('./activitiesQualTab.html');
import { ActivitiesQualTabController } from './activitiesQualTab.controller';

export const activitiesQualTab: ng.IComponentOptions = {
  controller: ActivitiesQualTabController,
  templateUrl: activitiesQualTabTemplate,
  controllerAs: 'vm',
  require: {
    squareActivitiesComponent: '^^squareActivitiesComponent',
  },
};
