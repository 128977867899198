'use strict';

import { LandingSidenavController } from './landingSidenav.controller';

const template = require('./landingSidenav.html');

export const LandingSidenavComponent: ng.IComponentOptions = {
  controller: LandingSidenavController,
  templateUrl: template,
  controllerAs: 'vm',
};
