'use strict';

import { ActivityDataController } from './activityData.controller';
const template = require('./activityData.html');

require('./activityData.scss');

angular.module('insitesApp.activityData').component('activityDataComponent', {
  controller: ActivityDataController,
  controllerAs: 'vm',
  templateUrl: template,
});
