'use strict';

import { ParticipantService } from './../../../../../core/dataservices/participant.service';
import { ISegmentationSegmentItem } from '../../../../../core/dataservices/dataservice.contracts';
import { SpinnerService } from '../../../../../core/services/spinner.service';

export class MemberDetailsSegmentationsController {
  static $inject = ['$stateParams', 'participantservice', 'spinnerservice'];

  SegmentationSegmentList = [];
  SquareParticipantSegmentations: ISegmentationSegmentItem[];
  order = null;

  constructor(
    private $stateParams: ng.ui.IStateParamsService,
    private participantservice: ParticipantService,
    private spinnerservice: SpinnerService,
  ) {}

  async $onInit() {
    await this.loadSegmentations();
  }

  async loadSegmentations() {
    try {
      this.spinnerservice.show('loading');
      this.SquareParticipantSegmentations = await this.participantservice.listSquareParticipantSegmentations(this.$stateParams.memberGuid);
    } finally {
      this.spinnerservice.hide('loading');
    }
  }
}
