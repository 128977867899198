'use strict';

import { SidenavItemController } from './isc-sidenav-item.controller';

const template = require('./isc-sidenav-item.html');

require('./isc-sidenav-item.scss');

export const SidenavItemComponent: ng.IComponentOptions = {
  controller: SidenavItemController,
  templateUrl: template,
  controllerAs: 'vm',
  transclude: {
    actionIcon: '?actionIcon',
  },
  bindings: {
    env: '@',
    icon: '@?',
    svgIcon: '@?',
    label: '@',
    onClick: '&?',
    ngHref: '@?',
  },
};
