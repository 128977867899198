'use strict';

import { SelectedActivityFactory } from './../../../../../core/selectedactivity.factory';
import { ServerConstants } from './../../../../../core/serverconstants';
import { SelectedSquareFactory } from './../../../../../core/selectedsquare.factory';
import { ImageCropService } from '../../../../../layout/image-crop/image-crop.service';
import { ActivityService } from '../../../../../core/dataservices/activity.service';
import * as _ from 'lodash';
import { IStimuliIgnoreFilter } from '../../../../../core/filters/stimuli-ignore.filter';
import { ParentActivityChangeConfirm } from './../../../../common/ParentActivityChangeConfirm';
import { ActivityQuantBuilderModel } from './activityQuantBuilderModel';
import { IFileConfigurationResponse } from '../../../../../core/contracts/configuration.contracts';
import { ConfigurationService } from '../../../../../core/dataservices/configuration.service';
import { LabelFactory } from '../../../../../core/label.factory';
import { ForumService } from 'src/app/core/dataservices/forum.service';

export class ActivityQuantBuilderController extends ParentActivityChangeConfirm {

  static $inject = ['serverConstants', 'labelFactory', 'selectedSquareFactory',
    '$scope', 'activityservice', '$stateParams', 'logger',
    'imageCropService', 'selectedActivityFactory', 'stimuliIgnoreFilter',
    'iscConfigurationService', '$mdDialog', 'forumservice'];

  constructor(
    private serverConstants: ServerConstants,
    private labelFactory: LabelFactory,
    private selectedSquareFactory: SelectedSquareFactory,
    private $scope: ng.IScope,
    private activityservice: ActivityService,
    private $stateParams: ng.ui.IStateParamsService,
    private logger: Logger,
    private imageCropService: ImageCropService,
    private selectedActivityFactory: SelectedActivityFactory,
    private stimuliIgnoreFilter: IStimuliIgnoreFilter,
    private iscConfigurationService: ConfigurationService,
    protected $mdDialog: ng.material.IDialogService,
    private forumservice: ForumService,
  ) {
    super($mdDialog);
  }

  wizardStep;
  removeLink;
  validationConstants = this.serverConstants.validationConstants;
  communicationChannelConstants = this.serverConstants.communicationChannelConstants;
  displayTabOptions = false;
  invalidFiles = [];
  fileValidationErrors = [];
  form;
  stimuliOptions;
  squareActivity;
  invalidCommunicationFiles = [];
  initialFiles = [];
  isPublished;
  isReadOnly;
  saveNotApplicable = false;
  saveCallback = () => this.updateActivityBuild(false, this);
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm();
  originalShowOnHomepage;
  originalSticky;
  model = new ActivityQuantBuilderModel();
  imageStimuliMaxSizeBytes: number;
  videoStimuliMaxSizeBytes: number;
  fileTypeConstants;
  fileConfiguration: IFileConfigurationResponse[] = [];
  acceptedMimeTypes: string[];
  acceptedMimeTypesString: string;
  acceptedPatterns: string;
  acceptedExtensions: string;

  $onInit() {
    this.fileTypeConstants = this.iscConfigurationService.getFileTypes();
    this.iscConfigurationService.getFileConfiguration()
      .then((response) => {
        this.fileConfiguration = response;
        this.imageStimuliMaxSizeBytes = _.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.image).MaxFileSizeMb * Math.pow(1024, 2);
        this.videoStimuliMaxSizeBytes = _.find(this.fileConfiguration,
          (config) => config.FileType === this.fileTypeConstants.video).MaxFileSizeMb * Math.pow(1024, 2);

        const { mimeTypes, patterns, extensions } = this.iscConfigurationService
          .getAcceptedMimeTypesAndExtensions(this.fileConfiguration, [this.fileTypeConstants.image, this.fileTypeConstants.video]);
        this.acceptedMimeTypes = mimeTypes;
        this.acceptedMimeTypesString = mimeTypes.toString();
        this.acceptedPatterns = `'${patterns.toString()}'`;
        this.acceptedExtensions = extensions.toString();
      });

    this.loadData(this.squareActivity);
    this.removeLink = this.wizardStep.linkComponent('builderQuant', this);
    this.wizardStep.resolveComponent(['targeting']).then((resolved) => {
      resolved[0].$loadData();
    });
    this.$scope.$watch('vm.isPublished', () => {
      this.saveNotApplicable = this.isPublished;
    });
  }

  async $wizardStepLoaded() {
    // Load default fields
    if (!this.model.Title) {
      this.model.Title = this.selectedActivityFactory.Name;
    }
    if (!this.model.CallToActionText) {
      const squareLanguage = await this.selectedSquareFactory.languagePromise;
      const labels = await this.labelFactory.getLabelsLanguage(squareLanguage);
      this.model.CallToActionText = labels.getLabelValue('LabelCallToActionTextDefaultResearchActivity');
    }
  }

  loadData(data) {
    this.model = new ActivityQuantBuilderModel();
    if (data && data.ActivityQuantDetail) {
      const QuantData = data.ActivityQuantDetail;
      this.model.CallToActionText = QuantData.CallToActionText;
      this.model.CardTeaser = QuantData.CardTeaser;
      this.model.Description = QuantData.Description;
      this.model.Sticky = QuantData.Sticky;
      this.originalSticky = QuantData.Sticky;
      this.model.ShowOnHomepage = QuantData.ShowOnHomepage;
      this.originalShowOnHomepage = QuantData.ShowOnHomepage;
      this.model.Title = QuantData.Title;
      this.model.CommunicationGuid = QuantData.CommunicationGuid;

      if (data.CommunicationStimuli != null) {
        this.model.CommunicationStimuli.push({
          source: this.serverConstants.communicationConstants.stimuliSourceDb,
          action: this.serverConstants.communicationConstants.stimuliActionIgnore,
          imgUrl: data.CommunicationStimuli.ThumbnailUrl,
          type: data.CommunicationStimuli.FileType,
          Guid: data.CommunicationStimuli.Guid,
        });
      }
      this.model.StartDateTime = data.Detail.StartDate;
    }
  }

  // Returns true when changes for the childActivities must be made
  protected hasChanges(): boolean {
    return this.model.ShowOnHomepage !== this.originalShowOnHomepage || this.model.Sticky !== this.originalSticky;
  }

  resetForm() {
    this.form.$setPristine();
    this.loadData(this.squareActivity);
  }

  getThumbnail(file: any) {
    switch (file.Type) {
      case 0: return file.ThumbnailUrl;
      case 1: return 'video';
      case 2: return `https://img.youtube.com/vi/]${file.Value}/mqdefault.jpg`;
      case 3: return `http://vimeo.com/api/v2/video/${file.Value}.json`;
      default: return '';
    }
  }
  getTypeAsString(enums: number) {
    switch (enums) {
      case 0: return 'image';
      case 1: return 'video';
      case 2: return 'youtube';
      case 3: return 'vimeo';
      default: return '';
    }
  }

  removeCommunicationFile(file) {
    this.invalidCommunicationFiles = [];
    const index = _.indexOf(this.model.CommunicationStimuli, file);
    if (this.model.CommunicationStimuli[index].source === this.serverConstants.communicationConstants.stimuliSourceUpload) {
      this.model.CommunicationStimuli.splice(index, 1);
    } else {
      this.model.CommunicationStimuli[index].action = this.serverConstants.communicationConstants.stimuliActionDelete;
    }
    const fileIndex = this.model.CommunicationFiles.map((f) => f.name).indexOf(file.imgUrl.name);
    if (fileIndex >= 0) {
      this.model.CommunicationFiles.splice(fileIndex, 1);
    }
  }

  isImageFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const imageMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('image') === 0);
      result = imageMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  isVideoFile(file) {
    let result = false;
    if (file && file.type && this.acceptedMimeTypes && this.acceptedMimeTypes.length) {
      const videoMimeTypes = this.acceptedMimeTypes.filter((mt) => mt.indexOf('video') === 0);
      result = videoMimeTypes.indexOf(file.type) > -1;
    }
    return result;
  }

  async validateCommunicationFiles($files, $newFiles, $invalidFiles) {
    if (!$newFiles) {
      return;
    }

    for (let file of $newFiles) {
      _.pull($files, file);
      this.invalidCommunicationFiles = [];
      const isImageFile = this.isImageFile(file);

      if ((isImageFile && file.size > this.imageStimuliMaxSizeBytes) ||
        (this.isVideoFile(file) && file.size > this.videoStimuliMaxSizeBytes)) {
        file.$errorMessages = file.$errorMessages || {};
        file.$errorMessages.maxSize = true;
        this.invalidCommunicationFiles.push(file);
        $invalidFiles.splice(0);
      } else if (file.$errorMessages) {
        this.invalidCommunicationFiles.push(file);
        $invalidFiles.splice(0);
      } else if (this.isCommunicationStimuliLimitReached()) {
        this.invalidCommunicationFiles.push(file);
        file.$errorMessages = { maxFiles: true };
      } else {
        if (isImageFile) {
          try {
            file = await this.imageCropService.cropStimuli(file);
          } catch (err) {
            // User canceled
            continue;
          }
        }
        this.model.CommunicationStimuli.push({
          source: this.serverConstants.communicationConstants.stimuliSourceUpload,
          imgUrl: file,
          action: this.serverConstants.communicationConstants.stimuliActionUpload,
          type: file.type,
          hashKey: file.$$hashkey,
        });
        this.model.CommunicationFiles.push(file);
      }
    }
  }

  isCommunicationStimuliLimitReached() {
    return this.stimuliIgnoreFilter(this.model.CommunicationStimuli).length > 0;
  }

  $onDestroy() {
    this.removeLink();
  }

  getVideoStimulusDisplaySrc(file): string {
    return (typeof file.imgUrl === 'string') ? file.imgUrl : 'images/video-placeholder.png';
  }

  $wizardStepIsCompleted() {
    return this.squareActivity.ActivityQuantDetail !== null;
  }

  $wizardIsValid() {
    if (!_.isUndefined(this.form)) {
      const stimuli = _.filter(this.model.CommunicationStimuli, (s) => s.action !== this.serverConstants.communicationConstants.stimuliActionDelete);
      return this.form.$valid && (this.model.CommunicationFiles.length > 0 || stimuli.length > 0);
    }
  }

  $wizardNextLabel() {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  }

  $wizardNextDescription() {
    let description = '';
    if (this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  }

  async $wizardBeforeNext(reload: boolean) {
    await this.updateActivityBuild(reload, this);
  }

  async updateActivityBuild(reload: boolean, root: this) {
    if (this.squareActivity && this.squareActivity.isParentActivity) {
      await this.showParentActivityChangeConfirm();
    }
    const datacommunication = {
      ActivityGuid: root.$stateParams.activityGuid,
      Title: root.model.Title,
      Message: root.model.CardTeaser,
      Sticky: root.model.Sticky,
      ShowOnHomepage: root.model.ShowOnHomepage,
      StartDateTime: root.squareActivity.Detail.StartDate,
      CallToActionText: root.model.CallToActionText,
      StimuliCollection: root.model.CommunicationStimuli,
      Stimuli: [],
      IsQualActivity: false,
    };

    const stimuliAndFiles = await this.forumservice.prepareStimuliAndNewStimuli(
      root.model.CommunicationStimuli.map((stimulus) => ({
        file: stimulus.imgUrl,
        isPreview: false,
        status: 1,
        type: stimulus.type,
      })), true);
    datacommunication.StimuliCollection.forEach((stimulus, index) => {
      const prepared = stimuliAndFiles.stimuli[index];
      stimulus.id = prepared.id;
      stimulus.url = prepared.url;
      stimulus.thumbnailUrl = prepared.thumbnailUrl;
    });

    stimuliAndFiles.files.forEach((f) => datacommunication.Stimuli.push(stimuliAndFiles.files.indexOf(f)));

    const data = await root.activityservice.createUpdateActivityConfigCommunication(datacommunication, stimuliAndFiles.files);
    await root.activityservice.selectActivityQuantResearch(root.$stateParams.activityGuid).then((response) => {
      root.squareActivity = response.data;
      this.form.$setPristine();
    });
    _.each(root.model.CommunicationStimuli, (s) => {
      if (s.source === this.serverConstants.communicationConstants.stimuliSourceUpload &&
        s.action === this.serverConstants.communicationConstants.stimuliActionUpload) {
        s.action = this.serverConstants.communicationConstants.stimuliActionIgnore;
      }
    });
    root.model.CommunicationFiles = [];
    const wizardTargeting = await root.wizardStep.resolveComponent('targeting');
    wizardTargeting.communicationGuid = data.CommunicationGuid;

    if (!root.isReadOnly) {
      root.logger.success('Activity build successfully updated');
    }
  }
}
