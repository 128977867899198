'use strict';

import { DateTime } from 'luxon';

export class DateFormatService {

  getMinDate(a: DateTime, b: DateTime) {
    return a < b ? a : b;
  }

  convertDate(value?: DateTime | Date | any): DateTime {
    if (value === undefined) {
      return DateTime.now();
    } else if (value instanceof DateTime) {
      return value;
    } else if (value instanceof Date) {
      return DateTime.fromJSDate(value);
    } else if (value) {
      return DateTime.fromISO(value);
    }
  }

  getDateTime(datetime: DateTime, timezoneOffset: number): DateTime {
    return this.convertDate(datetime).toUTC((timezoneOffset || 0) * 60);
  }

  getDateTimeForSaving(datetime: DateTime, timezoneOffset: number): string {
    const result = this.convertDate(datetime);

    // Hack to make sure the time is in the correct/new timezone
    const currentTimeZone = result.offset / 60;
    return this.convertDate(datetime)
      .plus({ hour: currentTimeZone - (timezoneOffset || 0) })
      .toISO({ suppressMilliseconds: true, suppressSeconds: true });
  }

  startOfDay(datetime?: DateTime) {
    return this.convertDate(datetime).startOf('day');
  }
  // 1 second is subtracted because sql rounds up time, 21:59:59 in the database becomes 22:00:00
  endOfDay(datetime?: DateTime) {
    return this.convertDate(datetime).endOf('day').minus({ seconds: 1 });
  }

  getTimeLoadFormat(time, offset: number) {
    let resultDate = DateTime.fromISO(`${time}`);
    const hoursOffset = Math.floor(offset);
    const minutesOffset = (offset % 1) * 60;
    resultDate = resultDate.plus({ hours: hoursOffset });
    resultDate = resultDate.plus({ minutes: minutesOffset });
    return resultDate;
  }
}
