'use strict';

import { JobService } from '../../../../../core/jobs/job.service';
import { ServerConstants } from '../../../../../core/serverconstants';
import { SegmentationService } from '../../../../../core/dataservices/segmentation.service';
import { ActivityService } from '../../../../../core/dataservices/activity.service';
import { Logger } from '../../../../../blocks/logger/logger';
import * as _ from 'lodash';

export class ActivitySegmentationController implements ng.IOnInit {
  static $inject = ['logger', '$stateParams', 'activityservice', '$q', 'spinnerservice', 'segmentationservice',
    'serverConstants', 'jobservice'];
  constructor(
    private logger: Logger,
    private $stateParams: ng.ui.IStateParamsService,
    private activityservice: ActivityService,
    private $q: ng.IQService,
    private spinnerservice,
    private segmentationservice: SegmentationService,
    private serverConstants: ServerConstants,
    private jobservice: JobService,
  ) { }

  removeLink;
  isPublished;
  surveyPreparationStatus: number;
  isReadOnly;
  wizardStep;
  activity = {};
  segmentations = [];
  segmentationList = [];
  squareActivity;

  previousStepComponent;
  previousIsCompleted = false;
  isCompleted = false;
  form;
  saveCallback = () => this.updateActivitySegmentations();
  navigationErrorMessage = '<p>It seems there are still some unresolved errors :</p>$errors<p>Please review and correct these before you leave.</p>';
  resetFormCallback = () => this.resetForm();
  saveNotApplicable = false;

  $onInit() {
    this.logger.info('Activity segmentations activated');
    this.spinnerservice.show('loading');
    this.removeLink = this.wizardStep.linkComponent('segmentations', this);
    this.$q.all([this.getActivitySegmentations(), this.getSegmentationList()]).finally(() => {
      this.logger.info('Activity segmentations loaded');
      this.logger.info('Segmentation selectionlist loaded');
      this.spinnerservice.hide('loading');
    });
    this.wizardStep.resolveComponent(['targeting']).then(async (resolved) => {
      this.previousStepComponent = resolved[0];
    });
  }

  $onDestroy() {
    this.removeLink();
  }

  $wizardNextLabel = () => {
    let label = '';
    if (this.isReadOnly) {
      label = 'Continue';
    } else {
      label = 'Save and continue';
    }
    return label;
  };

  $wizardNextDescription = () => {
    let description = '';
    if (this.squareActivity !== undefined && this.squareActivity.Detail.Status === this.serverConstants.squareActivityStatusConstants.draft) {
      description = this.serverConstants.squareConstants.wizardNotPublishedStatus;
    }
    return description;
  };

  $wizardBeforeNext = () => this.updateActivitySegmentations();

  $wizardStepIsCompleted() {
    // Consider this step completed if there are segmentations and the previous step is also completed.
    // We check the previous step because the segmentations could be loaded even the user is on the first step
    // And maybe we shouldn't make this step available at that moment.
    if (this.segmentations && this.previousStepComponent) {
      this.previousIsCompleted = this.previousStepComponent.$wizardStepIsCompleted();
      this.isCompleted = this.previousIsCompleted;
    }
    return this.isPublished  || this.surveyPreparationStatus === this.serverConstants.surveyPreparationStatusConstants.finished || this.isCompleted;
  }

  isStarred = (chip) => chip.IsStarred;

  private async prepareSurvey() {
    const jobId = this.activityservice.prepareSurvey(this.$stateParams.activityGuid);
    await this.jobservice.showJobProgressDialog(jobId, 'Transferring background variables');
    this.form.$setPristine();
    if (this.isPublished && !this.isReadOnly) {
      this.logger.success('Activity segmentations successfully updated');
    }
  }

  private getSegmentationList() {
    return this.segmentationservice.getSquareSegmentations(true).then((response) => {
      _.forEach(response, (res: any) => res.Name = res.Question);
      this.segmentationList = response;
    });
  }

  private updateActivitySegmentations = () => {
    const segmentationGuids = _.map(this.segmentations, _.property('Guid'));
    this.activityservice.updateActivitySegmentations(this.$stateParams.activityGuid, segmentationGuids)
      .then(() => {
        this.prepareSurvey();
      });
  };

  private getActivitySegmentations() {
    return this.activityservice.getActivitySegmentations(this.$stateParams.activityGuid).then((response) => {
      this.segmentations = response && response.data && response.data.List
        ? response.data.List
        : [];
    });
  }

  private resetForm = () => {
    this.form.$setPristine();
    this.getActivitySegmentations();
  };

  private searchSegmentations(query) {
    let results = [];
    if (query) {
      query = query.toUpperCase();
      const diff = _.differenceBy(this.segmentationList, this.segmentations, 'Id');
      results = _.filter(diff, (segmentation) => (segmentation.Name.toUpperCase().indexOf(query) >= 0));
    }
    return _.take(results, 100);
  }
}
