var IscSaveButtonController = (function () {
    function IscSaveButtonController($attrs, $transclude, $element, _) {
        this.$attrs = $attrs;
        this.$transclude = $transclude;
        this.$element = $element;
        this._ = _;
        this.disabled = false;
        this.saving = false;
        this.hasIcon = false;
    }
    IscSaveButtonController.prototype.$onInit = function () {
        var _this = this;
        this.theme = this.theme || 'primary';
        this.$attrs.$observe('disabled', function (disabled) {
            _this.disabled = disabled;
        });
        this.hasIcon = this.$transclude.isSlotFilled('icon');
        var iscButton = this.$element[0].querySelector('isc-button');
        var classes = [];
        if (this.$element.hasClass('flat')) {
            classes.push('flat');
        }
        if (this.$element.hasClass('outline')) {
            classes.push('outline');
        }
        this._.each(classes, function (item) {
            iscButton.classList.add(item);
        });
    };
    IscSaveButtonController.$inject = ['$attrs', '$transclude', '$element', '_'];
    return IscSaveButtonController;
}());
export { IscSaveButtonController };
