'use strict';

import { BasicActivityFilterController } from './isc-basic-activity-filter.controller';
const template = require('./isc-basic-activity-filter.html');

export const iscBasicActivityFilterComponent: ng.IComponentOptions = {
  controller: BasicActivityFilterController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    filterdata: '=',
    applyFilter: '&',
    minStartDate: '<',
    maxEndDate: '<',
  },
};
