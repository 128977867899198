'use strict';

import { IUpdateFeatureSettingsRequest } from './../../core/contracts/feature.contracts';

export class RewardService {
  static $inject = ['httpservice'];

  constructor(private httpservice) { }

  updateWinner(winner) {
    return this.httpservice.post({
      url: '/RewardService/UpdateWinner',
      data: {
        WinnerGuid: winner.WinnerGuid,
        IsApproved: winner.IsApproved,
        RewardPartner: winner.RewardPartner,
      },
    });
  }

  createPeriodicRaffle(raffleData) {
    return this.httpservice.post({
      url: '/RewardService/CreatePeriodicRaffle',
      data: raffleData,
    });
  }

  updatePeriodicRaffle(raffleData) {
    return this.httpservice.post({
      url: '/RewardService/UpdatePeriodicRaffle',
      data: raffleData,
    });
  }

  createUpdateSavingUpRewardsSettings(savingUpRewardsSettings: IUpdateFeatureSettingsRequest) {
    return this.httpservice.post({
      url: '/RewardService/CreateUpdateSavingUpRewardsSettings',
      data: {
        FeatureSettings: savingUpRewardsSettings,
      },
    }).then((response) => response.data);
  }
}
