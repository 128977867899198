'use strict';

import './exception.module';
import { Exception } from './exception';
import { ExceptionHandlerProvider, config } from './exception-handler.provider';
import './../logger';

angular
  .module('blocks.exception')
  .service('exception', Exception)
  .provider('exceptionHandler', ExceptionHandlerProvider)
  .config(config);
