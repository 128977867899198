'use strict';

export class ActivityRecruitmentPartnerService {
  static $inject = ['httpservice'];

  constructor(private httpservice) { }

  getActivityRecruitmentPartners(activityGuid) {
    return this.httpservice.get({
      url: `/ActivityService/ListActivityRecruitmentPartners?ActivityGuid=${activityGuid}`,
    });
  }

  createOrUpdateActivityRecruitmentPartner(data) {
    return this.httpservice.post({
      url: '/ActivityRecruitmentPartnerService/CreateOrUpdateActivityRecruitmentPartner',
      data,
    }).then(() => this.getActivityRecruitmentPartners(data.ActivityGuid).then((response) => response.data.Partners));
  }

  deleteActivityRecruitmentPartner(activityRecruitmentPartnerGuid, activityGuid) {
    return this.httpservice.post({
      url: '/ActivityRecruitmentPartnerService/DeleteActivityRecruitmentPartner',
      data: {
        Guid: activityRecruitmentPartnerGuid,
      },
    }).then(() => this.getActivityRecruitmentPartners(activityGuid).then((response) => response.data.Partners));
  }
}
