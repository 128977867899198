'use strict';

export class ErrSrc implements ng.IDirective {
  link($scope, element, attrs) {
    element.bind('error',
      () => {
        attrs.$set('src', attrs.errSrc);
      });
  }
}
