'use strict';

import { CopyLinkButtonController } from './copy-link-button.controller';
const template = require('./copy-link-button.html');

export const CopyLinkButtonComponent: ng.IComponentOptions = {
  controller: CopyLinkButtonController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    textToCopy: '<',
  },
};
