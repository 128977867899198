'use strict';

import { TargetingResultController } from './targeting-result.controller';

const template = require('./targeting-result.html');
require('./targeting-result.scss');
export const TargetingResultComponent: ng.IComponentOptions = {
  controller: TargetingResultController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    isRecalculatingTargeting: '<',
    matches: '<',
    randomlySelected: '<',
    targeted: '<',
    targetedFrom: '<',
    right: '<',
  },
};
