'use strict';

import { ParticipantService } from './../../../../core/dataservices/participant.service';
import { Utils } from './../../../../core/utils';
import * as _ from 'lodash';
import { SquareService } from './../../../../core/dataservices/square.service';
import { CurrentUserService } from '../../../../core/dataservices/currentUser.service';
import { ServerConstants } from './../../../../core/serverconstants';
import { AuthService } from '../../../../core/dataservices/auth.service';
import { DateTime } from 'luxon';
import { FeatureService } from 'src/app/core/dataservices/feature.service';
require('./_addMember.scss');

export class AddMemberDialogController {
  static $inject = [
    '$mdDialog',
    'participantservice',
    'serverConstants',
    'currentUserService',
    'squareservice',
    '$stateParams',
    '$scope',
    'authService',
    'featureservice',
  ];
  participant;
  roles = [];
  insitesUsersAvailableForSquare = [];
  addMemberForm;
  isSaving: boolean;
  businessException;
  overrulelabels = ['ActivationSuccess'];
  placeholders;
  isActivationEmailEdited;
  countries: any[];
  roleConstants;
  insitesRoleSelected = false;
  saveButtonLabel = 'Send activation email';
  minConsentValidityDate: DateTime;
  consentValidityDate: DateTime;
  consentNeverExpires = false;
  isConsentFeatureEnabled = false;
  isDiySquare = false;
  currentRole: number;
  isDeveloper: boolean;
  isTeamLead: boolean;


  constructor(
    private $mdDialog: ng.material.IDialogService,
    private participantservice: ParticipantService,
    private serverConstants: ServerConstants,
    private currentUserService: CurrentUserService,
    private squareservice: SquareService,
    private $stateParams: ng.ui.IStateParamsService,
    private $scope: ng.IScope,
    private authService: AuthService,
    private featureService: FeatureService,
  ) {
    this.roleConstants = this.serverConstants.roleConstants;
    this.currentRole = this.currentUserService.userProfile.Role;
    this.isDeveloper = this.authService.isDeveloper();
    this.isTeamLead = this.authService.isTeamLead();

    this.participant = {
      insitesUser: '',
      email: '',
      firstName: '',
      lastName: '',
      role: this.roleConstants.participant,
      country: '',
    };

    this.featureService.checkFeatureAccessibilityForSetup(this.serverConstants.featureConstants.consentMapping)
      .then((isEnabled) => {
        this.isConsentFeatureEnabled = isEnabled;
      });

    this.minConsentValidityDate = DateTime.utc().plus(({ days: 1 }));
    this.minConsentValidityDate = DateTime.local(this.minConsentValidityDate.year, this.minConsentValidityDate.month, this.minConsentValidityDate.day);

    this.squareservice.getSquareCountries(this.$stateParams.squareGuid).then((countries) => {
      this.countries = countries;
    });

    if (this.isDeveloper || this.isTeamLead) {
      this.loadInsitesUsersAvailableForSquare();
    }
  }

  async loadInsitesUsersAvailableForSquare() {
    await this.participantservice.getInsitesUsersAvailableForSquare()
      .then((result) => {
        this.insitesUsersAvailableForSquare = _.forEach(result, (res) => {
          res.FullName = `${res.FirstName} ${res.LastName}`;
        });
      });
  }

  async $onInit() {
    await this.squareservice.getSquareDetails(this.$stateParams.squareGuid)
      .then((data) => this.isDiySquare = data.data.Detail.JobIds.some((job) => job.IsSelfServe));

    this.roles = _.filter(Utils.getRoleEnumAsArray(this.roleConstants), (role) => (
      role.Value === this.roleConstants.participant ||
      (role.Value === this.roleConstants.human8 && (this.isDeveloper || this.isTeamLead))
      || ((role.Value === this.roleConstants.professionalAdmin
        || (role.Value === this.roleConstants.clientAdmin && this.isDiySquare)
        || (role.Value === this.roleConstants.clientEditor && this.isDiySquare))
        && this.currentRole === this.roleConstants.human8
      )
      || role.Value === this.roleConstants.observer
    ));

    const sortingArr = [this.roleConstants.human8, this.roleConstants.professionalAdmin, this.roleConstants.clientAdmin,
      this.roleConstants.clientEditor, this.roleConstants.observer, this.roleConstants.participant];
    this.roles.sort((a, b) => sortingArr.indexOf(a.Value) - sortingArr.indexOf(b.Value));

    this.$scope.$watch('vm.participant.role', (newVal, oldVal) => {
      this.insitesRoleSelected = newVal === this.roleConstants.human8;
      this.saveButtonLabel = newVal === this.roleConstants.human8 ? 'Save' : 'Send activation email';
      if (newVal === this.roleConstants.human8 || oldVal === this.roleConstants.human8) {
        this.participant.insitesUser = null;
        this.participant.firstName = null;
        this.participant.lastName = null;
        this.participant.email = null;
        this.addMemberForm.$setUntouched();
      }
    });
  }

  insitesUserChanged(value) {
    const user = _.find(this.insitesUsersAvailableForSquare, (c) => c.Guid === value);
    this.participant.firstName = user.FirstName;
    this.participant.lastName = user.LastName;
    this.participant.email = user.Email;
    this.addMemberForm.$setValidity('serverErrors', true);
  }

  save(sendActivationEmail: boolean) {
    this.isSaving = true;
    this.participantservice.createExternalParticipant(
      this.participant, sendActivationEmail,
      this.placeholders,
      this.isActivationEmailEdited,
      this.consentValidityDate)
      .then(() => {
        this.$mdDialog.hide();
      }, (error) => {
        if (error.status === 400) {
          this.businessException = error.data.Message;
        }
      }).finally(() => {
        this.isSaving = false;
      });
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  setValidity(key) {
    this.addMemberForm[key].$setValidity('serverErrors', true);
  }

  isSaveDisabled() {
    const isSaveDisabled = !this.addMemberForm.$valid || this.isSaving;
    if (this.isConsentFeatureEnabled) {
      return isSaveDisabled || (this.consentValidityDate == null && !this.consentNeverExpires);
    }
    return isSaveDisabled;
  }
}
