'use strict';

import { SquareConfigFooterController } from './footer.controller';

const template = require('./footer.html');

export const SquareConfigFooterComponent: ng.IComponentOptions = {
  controller: SquareConfigFooterController,
  templateUrl: template,
  controllerAs: 'vm',
};
