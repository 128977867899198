'use strict';

export class ActivityRewardsController implements ng.IOnInit {
  static $inject = ['$stateParams'];
  constructor(
    private $stateParams: ng.ui.IStateParamsService) { }

  squareGuid: string;
  form: ng.IFormController;

  $onInit() {
    this.squareGuid = this.$stateParams.squareGuid;
  }

  onExecDateTimeChanged = () =>
    this.form.RaffleExecutionDate.$setValidity('serverErrors', true);
}
