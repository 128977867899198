var IscTextareaController = (function () {
    function IscTextareaController($element) {
        this.$element = $element;
    }
    IscTextareaController.prototype.$postLink = function () {
        var inputElem = this.$element.find('textarea');
        this.ngModelController = inputElem.controller('ngModel');
    };
    IscTextareaController.prototype.onValueChanged = function () {
        this.ngModelController.$setValidity('serverErrors', true);
        if (this.onChange) {
            this.onChange();
        }
    };
    IscTextareaController.$inject = ['$element'];
    return IscTextareaController;
}());
export { IscTextareaController };
