'use strict';

require('./activities');
require('./squareCommunication');
require('./squareConfig');
require('./squareForum');
require('./squareHealth');
require('./squareRewards');
require('./squareInfo');
require('./squareDownload');
require('./squareMembers');
require('./ssoIdeaStream');
import './reporting';

import routeConfig from './square.route';

angular
  .module('insitesApp.core')
  .run(routeConfig);
