'use strict';

import { ActivityService } from '../dataservices/activity.service';
import { SquareService } from '../dataservices/square.service';
import { ProgramService } from '../dataservices/program.service';
import { IPinnedItem } from './pinned-items.contracts';
import { AuthService } from '../dataservices/auth.service';
import { ServerConstants } from '../serverconstants';
import { ClientService } from '../dataservices/client.service';
import { PinService } from '../dataservices/pin.service';


export class PinnedItemsService {
  static $inject = ['pinservice', 'activityservice', 'squareservice', 'programservice', 'clientservice', '_', 'authService', 'serverConstants', '__env'];
  public pinnedItems: IPinnedItem[] = [];
  public pinnedItemsAllEnv: IPinnedItem[] = [];

  constructor(
    private pinservice: PinService,
    private activityservice: ActivityService,
    private squareservice: SquareService,
    private programservice: ProgramService,
    private clientservice: ClientService,
    private _: _.LoDashStatic,
    private authService: AuthService,
    private serverConstants: ServerConstants,
    private __env: Environment,
  ) { }

  private isAllEnv: boolean = false;
  init = this._.memoize(() => {
    this.isAllEnv = false;
    return this.refresh();
  });
  initAllEnv = this._.memoize(() => {
    this.isAllEnv = true;
    return this.refresh();
  });
  isPinned = this._.memoize((guid) => this.isPinnedInternal(guid));

  refresh() {
    if (this.isAllEnv) {
      this.pinnedItemsAllEnv.splice(0, this.pinnedItemsAllEnv.length);

      return this.__env.forAllEnvironments((env) => {
        const authToken = this.authService.getToken();
        // we are going to ignore failed ListPinnedItems API call for other environments
        this.pinservice.getPinnedItemsOnEnv(env.queryApiUrl, authToken,
          this.__env.commandApiUrl !== env.commandApiUrl)
          .then((pins) => {
            // ignored failed API call will have the http data null
            if (pins === null) {
              return;
            }
            this.isPinned.cache = new this._.memoize.Cache();

            const pinnedClients = pins.PinnedClient.map((p) => this.mapPinnedClient(p, env.shortName, env.commandApiUrl, env.adminUrl));
            const pinnedPrograms = pins.PinnedProgram.map((p) => this.mapPinnedProgram(p, env.shortName, env.commandApiUrl, env.adminUrl));
            const pinnedSquares = pins.PinnedSquare.map((p) => this.mapPinnedSquare(p, env.shortName, env.commandApiUrl, env.adminUrl));
            const pinnedActivities = pins.PinnedActivity.map((p) => this.mapPinnedActivity(p, env.shortName, env.commandApiUrl, env.adminUrl));

            // Replace all the pinned items, but keep the array reference intact
            const pinnedItems = pinnedClients.concat(pinnedPrograms, pinnedSquares, pinnedActivities);
            this.pinnedItemsAllEnv.push(...pinnedItems);

            this.pinnedItemsAllEnv.sort((a, b) => {
              if (a.type === b.type) {
                const textA = a.domain.toUpperCase();
                const textB = b.domain.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              }

              return (a.type > b.type) ? 1 : -1;
            });
          });
      });
    }

    return this.pinservice.listPinnedItems()
      .then((pins) => {
        this.isPinned.cache = new this._.memoize.Cache();
        const pinnedClients = pins.PinnedClient.map((p) => this.mapPinnedClient(p, '', this.__env.commandApiUrl, this.__env.interfaceUrl));
        const pinnedPrograms = pins.PinnedProgram.map((p) => this.mapPinnedProgram(p, '', this.__env.commandApiUrl, this.__env.interfaceUrl));
        const pinnedSquares = pins.PinnedSquare.map((p) => this.mapPinnedSquare(p, '', this.__env.commandApiUrl, this.__env.interfaceUrl));
        const pinnedActivities = pins.PinnedActivity.map((p) => this.mapPinnedActivity(p, '', this.__env.commandApiUrl, this.__env.interfaceUrl));
        // Replace all the pinned items, but keep the array reference intact
        this.pinnedItems.splice(0, this.pinnedItems.length);
        const pinnedItems = pinnedClients.concat(pinnedPrograms, pinnedSquares, pinnedActivities);
        this.pinnedItems.push(...pinnedItems);
      });
  }

  reset() {
    this.init.cache = new this._.memoize.Cache();
  }

  private mapPinnedClient(client, domainName, envCommandRoot, envAdminUrl): IPinnedItem {
    const sref = this.authService.isObserverUserLogin()
      ? 'root.observerlandingpage'
      : 'root.clientprograms';
    return {
      domain: domainName,
      adminUrl: envAdminUrl,
      envCommandRootUrl: envCommandRoot,
      type: this.serverConstants.searchTypeConstants.client,
      name: client.Name,
      guid: client.ClientGuid,
      clientGuid: client.ClientGuid,
      icon: 'fa fa-user',
      sref,
      srefParams: {
        clientGuid: client.ClientGuid,
      },
      unpin: () => this.toggleClientPin(envCommandRoot, client.ClientGuid),
    };
  }

  private mapPinnedProgram(program, domainName, envCommandRoot, envAdminUrl): IPinnedItem {
    return {
      domain: domainName,
      adminUrl: envAdminUrl,
      envCommandRootUrl: envCommandRoot,
      type: this.serverConstants.searchTypeConstants.program,
      name: program.Name,
      guid: program.ProgramGuid,
      clientGuid: program.ClientGuid,
      icon: 'fa fa-cubes',
      sref: 'root.programsquares',
      srefParams: {
        clientGuid: program.ClientGuid,
        programGuid: program.ProgramGuid,
      },
      unpin: () => this.toggleProgramPin(envCommandRoot, program.ProgramGuid, program.ClientGuid),
    };
  }

  private mapPinnedSquare(square, domainName, envCommandRoot, envAdminUrl): IPinnedItem {
    return {
      domain: domainName,
      adminUrl: envAdminUrl,
      envCommandRootUrl: envCommandRoot,
      type: this.serverConstants.searchTypeConstants.square,
      name: square.Name,
      guid: square.SquareGuid,
      clientGuid: square.ClientGuid,
      icon: 'fa fa-cube',
      sref: square.IsCompleted ? 'root.square.activities' : 'root.square.info',
      srefParams: {
        clientGuid: square.ClientGuid,
        programGuid: square.ProgramGuid,
        squareGuid: square.SquareGuid,
      },
      unpin: () => this.toggleSquarePin(envCommandRoot, square.SquareGuid, square.ClientGuid),
    };
  }

  private mapPinnedActivity(activity, domainName, envCommandRoot, envAdminUrl): IPinnedItem {
    return {
      domain: domainName,
      adminUrl: envAdminUrl,
      envCommandRootUrl: envCommandRoot,
      type: this.serverConstants.searchTypeConstants.activity,
      name: activity.Name,
      guid: activity.ActivityGuid,
      clientGuid: activity.ClientGuid,
      icon: 'fa fa-child',
      sref: 'root.square.activity',
      srefParams: {
        clientGuid: activity.ClientGuid,
        programGuid: activity.ProgramGuid,
        squareGuid: activity.SquareGuid,
        activityGuid: activity.ActivityGuid,
      },
      unpin: () => this.toggleActivityPin(envCommandRoot, activity.ActivityGuid, activity.ClientGuid),
    };
  }

  isPinnedInternal(guid) {
    const pin = this._.find(this.isAllEnv ? this.pinnedItemsAllEnv : this.pinnedItems, {
      guid,
    });
    return pin !== undefined;
  }

  async toggleClientPin(rootUrl, clientGuid?) {
    await this.clientservice.toggleClientPin(rootUrl, clientGuid);
    await this.refresh();
  }

  async toggleProgramPin(rootUrl,programGuid, clientGuid?) {
    await this.programservice.toggleProgramPin(rootUrl, programGuid, clientGuid);
    await this.refresh();
  }

  async toggleSquarePin(rootUrl, squareGuid, clientGuid?) {
    await this.squareservice.toggleSquarePin(rootUrl, squareGuid, clientGuid);
    await this.refresh();
  }

  async toggleActivityPin(rootUrl, activityGuid, clientGuid?) {
    await this.activityservice.toggleActivityPin(rootUrl, activityGuid, clientGuid);
    await this.refresh();
  }
}
