'use strict';

import { SelectedSquareFactory } from '../../../../core/selectedsquare.factory';

export class EditQuestionDialogController {
  static $inject = ['$mdDialog', '$scope', 'question', 'categories', 'validationConstants', 'selectedSquareFactory'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
    private $scope: ng.IScope,
    private question: { Answer: string },
    private categories: any[],
    private validationConstants,
    private selectedSquareFactory: SelectedSquareFactory,
  ) {
  }

  visibility = [{
    value: true,
    label: 'Visible',
  }, {
    value: false,
    label: 'Hidden',
  }];

  submit = () => {
    const buildregex = new RegExp(`<a\\s+href="https://(?!${this.selectedSquareFactory.SquareUrl})`, 'gi');
    this.question.Answer = this.question.Answer.replace(buildregex, '<a target="_blank" rel="noopener nofollow noreferrer" href="');
    this.$mdDialog.hide(this.question);
  };

  cancel = () => this.$mdDialog.cancel();
}
