'use strict';

import { TransitionService } from '@uirouter/core';
import { SelectedClientFactory } from '../../core/selectedclient.factory';
import { AuthService } from '../../core/dataservices/auth.service';
import { InsitesUserDetailService } from '../../core/dataservices/insitesUserDetail.service';
import { ClientService } from 'src/app/core/dataservices/client.service';

export class ClientSelectionController {
  static $inject = ['clientservice', '$state', '$stateParams', '$scope', 'selectedClientFactory', '_', 'pinnedItemsService', 'authService', '$transitions',
    'insitesUserDetailService', '__env'];
  constructor(
    private clientservice: ClientService,
    private $state,
    private $stateParams,
    private $scope: ng.IScope,
    private selectedClientFactory: SelectedClientFactory,
    private _: _.LoDashStatic,
    private pinnedItemsService,
    private authService: AuthService,
    private $transitions: TransitionService,
    private insitesUserDetailService: InsitesUserDetailService,
    private __env: Environment,
  ) { }


  clients = [];
  hideSearch = false;
  selectedClientGuid;
  selectedClient = this.selectedClientFactory;
  icon = 'fa fa-user';

  async $onInit() {
    await this.activate();

    this.$transitions.onSuccess({}, async (transition) => {
      if (transition.$from().name === 'root.clientmaintenance' && transition.$to().name === 'root.home') {
        this.pinnedItemsService.reset();
        await this.activate();
      }
      this.selectedClientGuid = transition.params('to').clientGuid;
      this.updateSelectedClientFactory(transition.params('to').clientGuid);
    });

    this.$scope.$watch(() => this.selectedClientFactory.Name, (newvalue) => {
      this.selectedClientGuid = this.selectedClientFactory.Guid;
      this.clients = this._.map(this.clients,
        (element) => {
          if (element.Guid === this.selectedClientFactory.Guid) {
            element.AdminName = newvalue;
          }
          return element;
        });
    });
  }

  async activate() {
    await this.pinnedItemsService.init();
    this.clientservice.getClientListForDropdown()
      .then((response) => {
        this.clients = response.List;
        this._.each(this.clients, (client) => {
          client.IsPinned = () => this.pinnedItemsService.isPinned(client.Guid);
          client.Type = 0;
        });
        this.updateSelectedClientFactory(null);
      });
  }

  updateSelectedClientFactory(selectedClientGuid) {
    if (!this.$stateParams.clientGuid && !selectedClientGuid) {
      return;
    }
    const clientGuidCheck = (client) => {
      if (selectedClientGuid) {
        return client.Guid === selectedClientGuid;
      }
      if (this.$stateParams.clientGuid) {
        return client.Guid === this.$stateParams.clientGuid;
      }
    };

    this._.each(this.clients,
      (client) => {
        if (clientGuidCheck(client)) {
          this.selectedClientFactory.setClientInfo({
            Guid: client.Guid,
            Name: client.AdminName || client.Name,
            VideoStorage: client.VideoStorage,
            Code: client.ClientCode,
            RelatedClients: client.RelatedClients,
            Id: client.Id,
          });
        }
      });
  }

  isSearchBox() {
    return this.selectedClientGuid === undefined;
  }

  async selectClient(client) {
    let clientGuid = '';
    if (!Object.prototype.hasOwnProperty.call(client,'Guid')) {
      clientGuid = this.$stateParams.clientGuid;
    } else {
      clientGuid = client.Guid;
    }

    const hasInSitesUserAccess = await this.insitesUserDetailService.hasInSitesUserAccess(clientGuid);
    if (!hasInSitesUserAccess) {
      return;
    }

    const targetStateName =
      this.authService.isObserverUserLogin()
        ? (this.__env.showStartPage ? 'root.landing' : 'root.observerlandingpage')
        : this.authService.isModeratorUserLogin()
          ? (this.__env.showStartPage ? 'root.landing' : 'root.moderatorlandingpage')
          : 'root.clientprograms';
    this.$state.go(targetStateName, { clientGuid }, { reload: true });
  }
}
