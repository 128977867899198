'use strict';

import { SelectedSquareFactory } from '../selectedsquare.factory';
import { NotificationsServiceBase } from '../services/notificationservicebase';
import * as _ from 'lodash';
import { setJwtToken } from '@insites/vue-eco-temp-library';

export class HttpService {
  static $inject = ['$http', 'Upload', '__env', '$stateParams', 'selectedSquareFactory'];
  constructor(
    private $http: ng.IHttpService,
    private Upload,
    private __env: Environment,
    private $stateParams: ng.ui.IStateParamsService,
    private selectedSquareFactory: SelectedSquareFactory,
  ) {
    delete $http.defaults.headers.common['X-Requested-With'];
  }

  private httpHeaders = {};
  private authToken: string;
  private impersonate;

  get NgHttp() {
    return this.$http;
  }

  put<T = any>(data: { url: string; data?: any; params?: any; headers?: any; responseType?: string; },
    envRootUrl = this.__env.commandApiUrl) {
    return this.send<T>({
      ...data,
      method: 'PUT',
      url: envRootUrl + data.url,
    });
  }

  delete(data: { url: string; }) {
    return this.send({
      ...data,
      method: 'DELETE',
      url: this.__env.commandApiUrl + data.url,
    });
  }

  post<T = any>(data: { url: string; data?: any; params?: any; headers?: any; responseType?: string; baseUrl?: string; },
    envRootUrl = data.baseUrl ?? this.__env.commandApiUrl) {
    return this.send<T>({
      ...data,
      method: 'POST',
      url: envRootUrl + data.url,
    });
  }

  postToQuery<T = any>(data: { url: string; data?: any; }) {
    return this.send<T>({
      ...data,
      method: 'POST',
      url: this.__env.queryApiUrl + data.url,
    });
  }

  get<T = any>(data: { url: string; params?: any; headers?: any; responseType?: string; },
    envRootUrl = this.__env.queryApiUrl, isAnonymousRequest = false) {
    return this.send<T>({
      ...data,
      method: 'GET',
      url: envRootUrl + data.url,
      params: data.params,
    }, isAnonymousRequest);
  }

  getAnonymous(data: { url: string; params?: any; headers?: any; responseType?: string; }) {
    return this.get(data, undefined, true);
  }

  upload(data: { url: string; data?: any; headers?: any; }) {
    return this.Upload.upload({
      ...data,
      headers: this.parseHeaders(data.headers),
      url: this.__env.commandApiUrl + data.url,
    });
  }

  private send<T>(data, isAnonymousRequest = false) {
    data.headers = this.parseHeaders(data.headers, isAnonymousRequest);
    return this.$http<T>(data);
  }

  private parseHeaders(headers: any, isAnonymousRequest = false) {
    let parsedHeaders: any = {
      ClientGuid: this.selectedSquareFactory.clientGuid || this.$stateParams.clientGuid,
      SquareGuid: this.selectedSquareFactory.Guid || this.$stateParams.squareGuid,
      BuildVersion: this.__env.buildVersion,
    };

    if (NotificationsServiceBase.ConnectionId) {
      parsedHeaders.ConnectionId = NotificationsServiceBase.ConnectionId;
    }

    if (this.authToken && !isAnonymousRequest && (!headers || !headers.Authorization)) {
      const authHeader = {
        Authorization: `Bearer ${this.authToken}`,
      };
      parsedHeaders = _.extend(parsedHeaders, this.httpHeaders, authHeader);
    }
    if (this.impersonate && this.impersonate.SquareParticipantGuid) {
      const impersonate = {
        ImpersonateGuid: this.impersonate.SquareParticipantGuid,
      };
      parsedHeaders = _.extend(parsedHeaders, this.httpHeaders, impersonate);
    }

    parsedHeaders = _.extend(parsedHeaders, this.httpHeaders, headers);
    return parsedHeaders;
  }

  setHeaders(headers) {
    _.extend(this.httpHeaders, headers);
  }

  useToken(token: string) {
    setJwtToken(token);
    this.authToken = token;
  }

  useImpersonate(impersonate) {
    this.impersonate = impersonate;
  }

  get squareGuid() {
    return this.selectedSquareFactory.Guid || this.$stateParams.squareGuid;
  }

  get clientGuid() {
    return this.selectedSquareFactory.clientGuid || this.$stateParams.clientGuid;
  }

}
