'use strict';

import { ServerConstants } from './../../../core/serverconstants';
import { Pagination } from '../../../core/models/pagination';
import { SpinnerService } from '../../../core/services/spinner.service';
import { HelperService } from '../../../core/services/helper.service';
import { IncentiveService } from '../../../core/dataservices/incentive.service';
import { DateFormatService } from '../../../core/services/dateformat.service';
import { RewardsService } from '../rewards.service';

const dialogTemplate = require('./newPeriodicRewards/newPeriodicReward.html');
export class PeriodicRewardsController {

  static $inject = ['helperservice', 'incentiveservice',
    'spinnerservice', '$mdDialog', 'dateFormatService', 'rewardsService', 'serverConstants', 'logger'];

  constructor(
    private helperservice: HelperService,
    private incentiveservice: IncentiveService,
    private spinnerservice: SpinnerService,
    private $mdDialog: ng.material.IDialogService,
    private dateFormatService: DateFormatService,
    private rewardsService: RewardsService,
    private serverConstants:ServerConstants,
    private logger:Logger) { }

  periodicRewards;
  pagination: IPagination = new Pagination();
  filter;
  newPeriodicRewardName;
  $onInit() {
    this.reload();
  }

  reload() {
    this.spinnerservice.show('loading');
    this.incentiveservice.getSquarePeriodicRewards()
      .then((data) => {
        if (data && data.List) {
          this.periodicRewards = data.List;
          this.periodicRewards.forEach((periodicReward) => {
            periodicReward.StartDate = this.dateFormatService.getDateTime(periodicReward.StartDate, periodicReward.Offset);
            periodicReward.EndDate = this.dateFormatService.getDateTime(periodicReward.EndDate, periodicReward.Offset);
            periodicReward.RewardStatus = this.rewardsService.getRewardStatus(periodicReward);
          });
        }
      }).finally(() => {
        this.spinnerservice.hide('loading');
      });
  }

  newPeriodicReward() {
    this.$mdDialog.show({
      controller: 'newPeriodicRewardsController',
      controllerAs: 'vm',
      templateUrl: dialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        periodicRewardName: this.newPeriodicRewardName,
        edit: false,
        raffleGuid: null,
        rewardPartnerTypeConstants: this.serverConstants.rewardPartnerTypeConstants,
      },
    }).then(() => {
      this.logger.success('Periodic reward created successfully');
      this.reload();
    });
  }

  editPeriodicReward(reward) {
    this.$mdDialog.show({
      controller: 'newPeriodicRewardsController',
      controllerAs: 'vm',
      templateUrl: dialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        periodicRewardName: reward.name,
        edit: true,
        raffleGuid: reward.Guid,
        rewardPartnerTypeConstants: this.serverConstants.rewardPartnerTypeConstants,
      },
    }).then(() => {
      this.logger.success('Periodic reward saved successfully');
      this.reload();
    });
  }

  search(filter) {
    return this.helperservice.search(filter, this.filter);
  }
}

