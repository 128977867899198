'use strict';

import { SquareService } from '../../../../core/dataservices/square.service';
import { SpinnerService } from '../../../../core/services/spinner.service';
import * as _ from 'lodash';
export class CustomCSSController {
  static $inject = ['logger', 'squareservice', 'spinnerservice'];
  constructor(
    private logger: Logger,
    private squareservice: SquareService,
    private spinnerservice: SpinnerService,
  ) { }
  form;
  isEmail: boolean;

  editorOptions = {
    showPrintMargin: false,
  };
  css: string;
  isSaving = false;
  public async $onInit() {
    try {
      this.logger.info('Square config - CustomCss Editor activated');
      this.spinnerservice.show('loading');
      this.getOriginalCss();
    } finally {
      this.spinnerservice.hide('loading');
    }
  }

  private async getOriginalCss() {
    try {
      this.css = await this.squareservice.getSquareCustomCSS(this.isEmail);
    } catch (error) {
      if (error.status === 400) {
        const data = error.data;

        // Group by property name in case there is more than 1 error for that property
        // Ideally we should already group them in the backend
        const grouped = _.groupBy(data.ValidationErrors, 'PropertyName');
        _.forEach(grouped, (item, key) => {
          if (this.form[key]) {
            this.form[key].$setValidity('serverErrors', false);
          } else {
            this.form.pageColors[key].$setValidity('serverErrors', false);
          }
        });
      }
    }
  }

  saveButtonClick() {
    if (!this.form.$valid) {
      const errorsByType = this.form.$error;
      this.logger.warning('Not saving because of validation errors.', errorsByType);
    }
  }

  public async savePage() {
    this.isSaving = true;
    await this.squareservice.saveSquareCustomCSS(this.css, this.isEmail);
    this.form.$setPristine();
    this.logger.success('Style saved successfully');
    this.isSaving = false;
  }

  cancelPage() {
    this.getOriginalCss();
    this.form.$setPristine();
  }
}
