'use strict';

import { SquareService } from '../../../core/dataservices/square.service';
import { ServerConstants } from '../../../core/serverconstants';
import * as _ from 'lodash';

const tabTemplates = {
  activities: require('./challenges/challenges.tabtemplate.html'),
  forum: require('./forum/forum.tabtemplate.html'),
  ideabox: require('./ideabox/ideabox.tabtemplate.html'),
  mainpagecustom: require('./mainPageCustom/mainPageCustom.tabtemplate.html'),
  news: require('./news/news.tabtemplate.html'),
  home: require('./home/home.tabtemplate.html'),
};

export class SquareConfigMainPagesController implements ng.IOnInit {
  static $inject = ['squareservice', 'serverConstants', '$scope'];
  constructor(
    private squareservice: SquareService,
    private serverConstants: ServerConstants,
    private $scope: ng.IScope) { }

  pageTypeConstants;
  area;
  pageTypeDetails;

  $onInit(): void {
    this.pageTypeConstants = this.serverConstants.pageTypeConstants;
    this.area = this.serverConstants.areaConstants.mainPages;

    this.pageTypeDetails = [
      {
        Label: 'Home',
        syncService: this.squareservice.syncPage('Home'),
        required: true,
        onlyOneAllowed: true,
        pageType: this.pageTypeConstants.home,
      },
      {
        Label: 'Activities',
        syncService: this.squareservice.syncPage('Activities'),
        onlyOneAllowed: true,
        required: true,
        pageType: this.pageTypeConstants.activities,
      },
      {
        Label: 'Lounge',
        syncService: this.squareservice.syncPage('Lounge'),
        onlyOneAllowed: false,
        required: false,
        pageType: this.pageTypeConstants.forum,
      },
      /*  {
          Label: 'Ideabox',
          syncService: squareservice.syncPage('Ideabox'),
          onlyOneAllowed: false,
          required: false,
          pageType: pageTypeConstants.ideabox,
        },*/
      {
        Label: 'News',
        syncService: this.squareservice.syncPage('News'),
        onlyOneAllowed: false,
        required: false,
        pageType: this.pageTypeConstants.news,
      },
      {
        Label: 'Custom',
        syncService: this.squareservice.syncPage('Custom'),
        onlyOneAllowed: false,
        required: false,
        pageType: this.pageTypeConstants.mainPageCustom,
      },
    ];
  }

  getTemplateForPage = (page) => {
    const name = _.findKey(this.pageTypeConstants, (c) => (c === page.PageType));
    return tabTemplates[name.toLowerCase()];
  };

  addPage = () => {
    this.$scope.$broadcast('addPage');
  };
}
