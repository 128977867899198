'use strict';

export class Input implements ng.IDirective {
  restrict = 'E';
  link(scope, $element, attrs) {
    if (attrs.type === 'number') {
      $element.on('focus', function() {
        angular.element(this).on('mousewheel', (e) => e.preventDefault);
      });
      $element.on('blur', function() {
        angular.element(this).off('mousewheel');
      });
    }
  }
}
