'use strict';

import { DateTime } from 'luxon';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { SquareService } from '../../../../core/dataservices/square.service';
import { ServerConstants } from '../../../../core/serverconstants';
import { ForumService } from '../../../../core/dataservices/forum.service';
import { FeatureService } from '../../../../core/dataservices/feature.service';
import { SelectedSquareForumsService } from '../../../../core/services/selectedSquareForumsService';
import * as _ from 'lodash';
import { MediaService } from '../../../../core/services/media.service';
import { PageService } from '../../../../core/dataservices/page.service';
import { SelectedSquareFactory } from '../../../../core/selectedsquare.factory';
import { LabelFactory } from '../../../../core/label.factory';
import { AuthService } from 'src/app/core/dataservices/auth.service';
const addPageDialogTemplate = require('./addPageDialog.html');

require('./pageEditor.scss');

export class PageEditorController implements ng.IOnInit {
  static $inject = ['authService', 'spinnerservice', 'squareservice', '$q',
    'logger', '$scope', '$timeout', '$mdDialog', '$stateParams',
    'serverConstants', 'forumservice', 'selectedSquareForumsService',
    'featureservice', 'mediaService', 'pageService', 'labelFactory', 'selectedSquareFactory'];

  constructor(
    private authService: AuthService,
    private spinnerservice: SpinnerService,
    private squareservice: SquareService,
    private $q: ng.IQService,
    private logger: Logger,
    private $scope: ng.IScope,
    private $timeout: ng.ITimeoutService,
    private $mdDialog: ng.material.IDialogService,
    private $stateParams: ng.ui.IStateParamsService,
    private serverConstants: ServerConstants,
    private forumservice: ForumService,
    private selectedSquareForumsService: SelectedSquareForumsService,
    private featureService: FeatureService,
    private mediaService: MediaService,
    private pageService: PageService,
    private labelFactory: LabelFactory,
    private selectedSquareFactory: SelectedSquareFactory,
  ) {
    this.deletedNewPages = [];
    this.isLoaded = false;
    this.squaredetails = null;
    this.isSaving = false;
    this.selectedPage = null;
    this.pages = [];
    this.originalPages = [];
    this.selectedPageIndex = 0;
    this.hasSquareStarted = false;
    this.validationConstants = serverConstants.validationConstants;
    this.pageTypeConstants = serverConstants.pageTypeConstants;
    this.operationTypeConstants = serverConstants.operationTypeConstants;
    this.usedIconsList = [];

  }
  form;
  area;
  pageTypeDetails: Array<{
    Label: string,
    syncService: SquareService['syncPage'],
    required: boolean,
    onlyOneAllowed: boolean,
    pageType: string,
  }>;

  savingUpRewardsEnabled: boolean;
  footerRestrictionsEnabled: boolean;
  isFullDev: boolean;
  isInsitesUser: boolean;
  getTemplateForPage;
  deletedNewPages;
  isLoaded;
  squaredetails;
  isSaving;
  selectedPage;
  pages;
  originalPages;
  selectedPageIndex;
  hasSquareStarted;
  validationConstants;
  pageTypeConstants;
  operationTypeConstants;
  usedIconsList;

  async $onInit() {
    this.logger.info('Square config - Page Editor activated');
    this.spinnerservice.show('loading');

    this.loadPages().finally(() => {
      let isFinishedLoading = true;

      // Firefox has an issue when rendering md-tabs;
      // The following workaround quickly selects the second tab(if exists) and then reselects the first tab to solve the issue.
      // Because of late loading of Segments that make the page 'dirty' when the watch for 'limitAccessToPage' sets segments for each tab
      if ((this.mediaService.isFirefox() && this.pages && this.pages.length > 1)
        || this.area === this.serverConstants.areaConstants.mainPages) {
        isFinishedLoading = false;
        this.selectedPageIndex = this.pages.length - 1;
        this.$timeout(() => {
          this.selectedPageIndex = 0;
          this.isLoaded = true;
          this.form.$setPristine(); // Make sure that the form is pristine when data is loaded (Firefox issue)
          this.spinnerservice.hide('loading');
        }, 1000);
      }

      if (isFinishedLoading) {
        this.isLoaded = true;
        this.spinnerservice.hide('loading');
      }
    });

    this.$scope.$on('addPage', () => {
      this.addPage();
    });
    await this.init();
  }

  async init() {
    this.savingUpRewardsEnabled = await this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards);
    this.footerRestrictionsEnabled = await this.featureService.isMasterFeatureEnabledForSquare(this.serverConstants.featureConstants.footerRestrictions);
    this.isFullDev = this.authService.isFullDev();
  }

  loadPages = () => {
    this.pages = [];
    return this.squareservice.getSquareDetails(this.$stateParams.squareGuid)
      .then((response) => {
        this.squaredetails = response.data.Detail;
        const startDate = response.data.Detail.StartDate && DateTime.fromISO(response.data.Detail.StartDate as string);
        if (startDate) {
          this.hasSquareStarted = startDate < DateTime.now();
        }
        return this.$q.resolve(response);
      })
      .then(() =>
        this.pageService.getSquarePages(this.$stateParams.squareGuid, this.area))
      .then(async (result) => {
        const pages = result.data.List;

        this.pages = pages;
        this.originalPages = _.cloneDeep(pages);
        if (this.area === this.serverConstants.areaConstants.mainPages) {
          this.usedIconsList = this.pages.map((page) => page.Icon);
        }

        _.each(this.pages,
          (page) => {
            // We set IsEditable true by default even though we will override it in some cases
            // This is because the pages are already passed to the views at this point
            page.IsEditable = true;
            // Mark as update
            page.OperationType = this.operationTypeConstants.update;
            page.new = false;
            // Remove Delete button from defined pages
            const notDeletablePages = [this.serverConstants.pageTypeConstants.home,
              this.serverConstants.pageTypeConstants.activities,
              this.serverConstants.pageTypeConstants.myIncentive,
              this.serverConstants.pageTypeConstants.contact,
              this.serverConstants.pageTypeConstants.platformTour,
              this.serverConstants.pageTypeConstants.myCookieSettings];
            page.deletable = !_.some(notDeletablePages, (notDeletablePage) => notDeletablePage === page.PageType);
          });
        this.selectedPage = pages[0];
        return this.$q.resolve(result);
      });
  };

  reselectCurrentTab = () => {
    let pageIndex = this.selectedPageIndex;
    this.$timeout(() => {
      // Do this only after a while, because md-tabs will select the first tab after the list changes.
      if (pageIndex >= this.pages.length) {
        pageIndex = this.pages.length - 1;
      }
      this.selectedPageIndex = pageIndex;
    });
  };

  getLabelForPageType = (pageType) => this.getDetailsForPageType(pageType).Label;

  getDetailsForPageType = (pageType) => _.find<{ Label: string, syncService: any, required: boolean, onlyOneAllowed: boolean, pageType: string }>
  (this.pageTypeDetails, {
    pageType,
  });

  addPage = () => {
    this.$mdDialog.show({
      controller: 'AddPageDialogController',
      controllerAs: 'vm',
      templateUrl: addPageDialogTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: true,
      locals: {
        pageTypes: this.filterAvailablePages(),
        pages: this.pages,
      },
    }).then(async (page) => {
      page.Area = this.area;
      page.SquareGuid = this.$stateParams.squareGuid;
      page.OperationType = this.operationTypeConstants.create;
      page.new = true;

      switch (page.PageType) {
        case this.serverConstants.pageTypeConstants.platformTour:
        case this.serverConstants.pageTypeConstants.news:
          page.IsEnabled = true;
          break;
        case this.serverConstants.pageTypeConstants.forum:
          page.Rooms = [];
          this.selectedSquareForumsService.createDefaultRoom().then((room) => {
            page.Rooms.push(room);
          });
          break;
        case this.serverConstants.pageTypeConstants.rewardPolicy:
          page.Intro = await this.getLabel('jAryXn6meLCustomFooter4Content');
          break;
        case this.serverConstants.pageTypeConstants.savingUpRewardPolicy:
          await this.setDefaultSavingUpRewardsPolicyValues(page);
          break;
        case this.serverConstants.pageTypeConstants.myCookieSettings:
        case this.serverConstants.pageTypeConstants.cookiePolicy:
          await this.setDefaultCookiePolicyValues(page);
          break;
        default:
          break;
      }

      if (page.Area === this.serverConstants.areaConstants.mainPages) {
        const mainPagesIcons = ['address', 'blackboard', 'bookmark', 'chat', 'edit', 'globe',
          'home', 'light-bulb', 'megaphone', 'message', 'network', 'news', 'open-book', 'pin', 'share'];
        const availableIcons = _.difference(mainPagesIcons, this.usedIconsList);
        page.Icon = _.sample(availableIcons);
        this.usedIconsList.push(page.Icon);
      }

      this.pages.push(page);

      if (this.mediaService.isFirefox()) {
        this.$timeout(() => {
          this.selectedPageIndex = this.pages.length - 1;
        }, 1000);
      } else {
        this.selectedPageIndex = this.pages.length - 1;
      }
    });
  };

  selectTab = (page) => {
    this.selectedPage = page;
    this.$scope.$root.$emit('tabChanged');
  };

  dirtyFormCheck = (index) => {
    if (this.isLoaded) {
      const tab = this.getChildFormNameForIndex(index);
      if (!_.isUndefined(this.form[tab]) && this.form[tab].$dirty) {
        return true;
      }
      return false;
    }
  };

  validFormCheck = (index) => {
    if (this.isLoaded) {
      const tab = this.getChildFormNameForIndex(index);
      if (!_.isUndefined(this.form[tab])) {
        if (this.form[tab].$valid) {
          return true;
        }
      }
      return false;
    }
  };

  newFormCheck = (page) => {
    if(this.isLoaded) {
      return page.new;
    }
  };

  deletePage = (page, index) => {
    if (this.getDetailsForPageType(page.PageType).required === true) {
      this.$mdDialog.show(this.$mdDialog.iscAlert({
        title: 'Delete page',
        text: 'You cannot delete this type of page',
        ok: 'Ok',
      }));
    } else if (!_.isUndefined(this.selectedPage)) {
      this.$mdDialog.show(this.$mdDialog.iscConfirm({
        title: 'Delete page',
        text: 'Are you sure you want to delete this page?',
        ok: 'Confirm',
        cancel: 'Cancel',
      })).then(() => {
        if (!page.new) {
          if (page.OperationType === this.operationTypeConstants.create) {
            this.deletedNewPages.push(page);
          }
          page.OperationType = this.operationTypeConstants.delete;
          this.savePages(page, index);
          this.pages.push(page);
        }
        _.pull(this.pages, page);
      }).then(() => {
        this.reselectCurrentTab();
      });
    }
  };

  isPageDeleted = (page) => page.OperationType === this.operationTypeConstants.delete;

  getChildFormNameForIndex = (index) => `tab-form-${index}`;

  getIndexForChildFormName = (childFormName) => {
    // ChildFormName eg. tab-form-4
    const parts = childFormName.split('-');
    const index = parseInt(parts[2], 10);
    return index;
  };

  getForm = ($index) => this.form[this.getChildFormNameForIndex($index)];

  saveButtonClick = (page, index) => {
    const tab = this.getChildFormNameForIndex(index);
    if (!this.form[tab].$invalid) {
      this.isSaving = true;
      this.savePages(page, index);
    }
  };

  reinitThemes = () => this.squareservice.getThemes().then((result) => {
    _.forEach(result,
      (resultItem: any) => {
        resultItem.hideTheme = false;
        if (resultItem.Name === this.serverConstants.squareConstants.uncategorizedThemeName &&
          resultItem.Guid === null) {
          resultItem.IsDefaultTheme = true;
        }
      });
    result = _.sortBy(result, 'SortOrder');
    return result;
  });

  savePages = (page, index) => {
    if (!_.isUndefined(page)) {
      const tab = this.getChildFormNameForIndex(index);
      if (page.PageType === this.pageTypeConstants.contact) {
        const base64 = _.get(page, 'Image.base64', undefined);
        const filetype = _.get(page, 'Image.filetype', undefined);
        if (base64 && filetype) {
          page.Image = `data:${filetype};base64,${base64}`;
        }
      }
      this.getDetailsForPageType(page.PageType).syncService(page)
        .then((response) => {
          if (this.isPageDeleted(page)) {
            return;
          }
          page.Guid = response.Guid;
          if (page.PageType === this.pageTypeConstants.forum) {
            this.forumservice.getForumRoom(page.Guid).then((result) => {
              this.pages[index].Rooms = _.cloneDeep(result.data.Rooms);
            });
            this.selectedSquareForumsService.update(this.$stateParams.squareGuid, true);
          }
          if (!_.isUndefined(this.form[tab])) {
            this.form[tab].$setPristine();
          }
          page.new = false;
          this.$scope.$broadcast('pageSaved', tab);
          page.OperationType = this.operationTypeConstants.update;
          this.originalPages[index] = _.cloneDeep(this.pages[index]);
          this.logger.success('Page saved successfully');
          // Resets all themes after save in order to prevent issues
          if (page.ActivityThemes) {
            this.reinitThemes().then((themesResponse) => {
              page.ActivityThemes = themesResponse;
            });
          }
        })
        .catch((response) => {
          // Validation error
          if (response.status === 400) {
            const data = response;

            // Group by property name in case there is more than 1 error for that property
            // Ideally we should already group them in the backend
            const grouped = _.groupBy(data.ValidationErrors, 'PropertyName');
            _.forEach(grouped, (item, key) => {
              let message = '';
              _.forEach(item, (errorMessage) => {
                message += `${errorMessage.ErrorMessage} `;
              });
              const pageForm = this.getForm(index);
              const formModel = pageForm[key];
              if (formModel) {
                pageForm[key].errorMessage = message;
                pageForm[key].$setValidity('serverErrors', false);
              }
            });
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    }
  };

  cancelPages = (page, index) => {
    if (page.new) {
      _.pull(this.pages, page);
      this.reselectCurrentTab();
      return;
    }
    if (!(this.originalPages.length === 0)) {
      const tab = this.getChildFormNameForIndex(index);
      this.form[tab].$setPristine();
      this.pages[index] = _.cloneDeep(this.originalPages[index]);
    }
    this.reselectCurrentTab();
  };

  filterAvailablePages = () => {
    // Build list of pages that can still be added and include their label
    const pages = [];
    const pageOptions = [];
    _.each(this.pageTypeDetails,
      (details) => {
        if (details.Label && this.canCreatePage(details.pageType as any as number)) {
          pageOptions.push(details);
        }
      });

    _.each(pageOptions, (option) => {
      const res = _.filter(this.pages, (page) => page.PageType === option.pageType);
      if (!(option.onlyOneAllowed && res !== undefined && res.length > 0)) {
        pages.push(option);
      }
    });

    return pages;
  };

  validateNavigation = () => {
    const result = {
      canNavigate: true,
      isDirty: false,
    };
    let invalidPageIndex = -1;
    for (let index = 0; index < this.pages.length; index++) {
      if (this.validFormCheck(index) === false) {
        invalidPageIndex = index;
        break;
      }
    }
    if (invalidPageIndex >= 0) {
      result.canNavigate = false;
      this.selectedPageIndex = invalidPageIndex;
    } else {
      const unsavedChanges = this.getModifiedPages();
      if (unsavedChanges.length > 0) {
        result.isDirty = true;
      }
    }
    return result;
  };

  saveChangedPages = () => {
    const pages = this.getModifiedPages();
    let result = true;
    if (pages.length > 0) {
      _.each(pages, (changedPage) => {
        const tab = this.getChildFormNameForIndex(changedPage.pageIndex);
        if (!this.form[tab].$invalid) {
          this.isSaving = true;
          if (!_.isUndefined(changedPage.page)) {
            if (changedPage.page.PageType === this.pageTypeConstants.contact) {
              const base64 = _.get(changedPage.page, 'Image.base64', undefined);
              const filetype = _.get(changedPage.page, 'Image.filetype', undefined);
              if (base64 && filetype) {
                changedPage.page.Image = `data:${filetype};base64,${base64}`;
              }
            }
            this.getDetailsForPageType(changedPage.page.PageType).syncService(changedPage.page)
              .then(() => {
                this.logger.success(`Page ${changedPage.page.Name} saved successfully`);
              })
              .catch(() => {
                this.logger.error('The page could not be saved');
                result = false;
              })
              .finally(() => {
                this.isSaving = false;
              });
          }
        } else {
          this.logger.error(`Page ${changedPage.page.Name}contains invalid data.`);
        }
      });
    }
    return this.$q.resolve(result);
  };

  getModifiedPages = () => {
    const unsavedChanges = [];
    _.each(this.pages,
      (page) => {
        // Check if there are changes for every page
        const pageIndex = _.indexOf(this.pages, page);
        if (this.dirtyFormCheck(pageIndex)) {
          const dirtyForm = { pageIndex, page };
          dirtyForm.pageIndex = pageIndex;
          dirtyForm.page = page;
          unsavedChanges.push(dirtyForm);
        }
      });
    return unsavedChanges;
  };

  onPaste = (e, pasteContent) => {
    const div = document.createElement('div');
    div.innerHTML = pasteContent;
    pasteContent = div.textContent;
    return pasteContent;
  };

  async setDefaultSavingUpRewardsPolicyValues(page) {
    const url = await this.getLabel('RewardsPolicyExternalUrl');
    page.CustomUrl = url;
    page.EditUrl = url;
    page.OpenInNewTab = true;
  }

  async setDefaultCookiePolicyValues(page) {
    const url = await this.getLabel('CookiePolicyExternalUrl');
    page.CustomUrl = url;
    page.EditUrl = url;
    page.OpenInNewTab = true;
  }

  async getLabel(label: string) {
    const squareLanguage = await this.selectedSquareFactory.languagePromise;
    const labels = await this.labelFactory.getLabelsLanguage(squareLanguage);
    return labels.getLabelValue(label);
  }

  isFooterPageRestricted = (pagetype: number) => {
    if (pagetype === this.pageTypeConstants.savingUpRewardPolicy && !this.isFullDev) {
      return true; // Saving up is always restricted unless you are a full dev
    }
    let footerPageRestricted = false;
    // When the feature toggle is on, the restrictions are for everyone except full dev
    if(this.footerRestrictionsEnabled){
      footerPageRestricted = !this.isFullDev;
    }
    return footerPageRestricted &&
      (pagetype === this.pageTypeConstants.footerCustom || pagetype === this.pageTypeConstants.cookiePolicy || pagetype === this.pageTypeConstants.myCookieSettings);
  };

  canCreatePage = (pageType: number) => {
    if (pageType === this.pageTypeConstants.footerCustom) {
      return true; // Always possible to add a custom page
    }

    if (pageType === this.pageTypeConstants.rewardPolicy) {
      return !this.savingUpRewardsEnabled;
    }

    if (pageType === this.pageTypeConstants.savingUpRewardPolicy) {
      return this.savingUpRewardsEnabled && this.isFullDev;
    }

    if (this.footerRestrictionsEnabled) {
      return true;
    }

    const restrictedPageTypes = [this.pageTypeConstants.cookiePolicy, this.pageTypeConstants.myCookieSettings];
    return _.find(restrictedPageTypes, (restrictedPageType) => restrictedPageType === pageType) === undefined;
  };
}
