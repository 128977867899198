'use strict';

import { ActivityQuantBuilderController } from './activityQuantBuilder.controller';

const template = require('./activityQuantBuilder.html');

angular
  .module('insitesApp.activityconfig')
  .component('activityQuantBuilder', {
    templateUrl: template,
    controller: ActivityQuantBuilderController,
    controllerAs: 'vm',
    require: {
      wizardStep: '^^iscWizardStep',
    },
    bindings: {
      squareActivity: '<',
      isPublished: '<',
      isReadOnly: '<',
    },
  });
