'use strict';

import { IncentiveService } from './../../../../core/dataservices/incentive.service';
import { RewardService } from './../../../../core/dataservices/reward.service';
import { Utils } from '../../../../core/utils';
import { ClipboardService } from './../../../../core/services/clipboard.service';
import { ServerConstants } from '../../../../core/serverconstants';
import * as _ from 'lodash';
import { FeatureService } from '../../../../core/dataservices/feature.service';
import { SelectedSquareFactory } from '../../../../core/selectedsquare.factory';
import { DateTime } from 'luxon';

export class ManualRewardDialogController {
  static $inject = [
    '$mdDialog',
    'reward',
    'incentiveservice',
    'serverConstants',
    'rewardservice',
    'ClipboardService',
    'featureservice',
    'selectedSquareFactory',
    '__env'];

  rewardList = [];
  rewardsToSave = [];
  isDisabled = true;
  isSaving = false;
  winnerStatus = this.serverConstants.winnerStatusConstants;
  isSavingUpEnabled = false;
  canSeeRewardLinks = false;

  constructor(
    private $mdDialog,
    private reward,
    private incentiveservice: IncentiveService,
    private serverConstants: ServerConstants,
    private rewardservice: RewardService,
    private clipboardService: ClipboardService,
    private featureService: FeatureService,
    private selectedSquareFactory: SelectedSquareFactory,
    private __env: Environment,
  ) {
    this.featureService.isFeatureEnabledForSquare(this.serverConstants.featureConstants.savingUpRewards)
      .then((isEnabled) => {
        this.isSavingUpEnabled = isEnabled;
      });

    this.incentiveservice.getManualRewards(this.reward.RaffleGuid).then((result) => {
      this.rewardList = result.List;
    });

    this.incentiveservice.canSeeRewardLinks().then((result) => {
      this.canSeeRewardLinks = result;
    });

    if (reward.Status === this.serverConstants.raffleStatusConstants.winnersDrawn) {
      this.isDisabled = true;
    }
  }

  cancel() {
    this.$mdDialog.cancel();
  }

  approveWinner(reward) {
    this.isDisabled = false;
    this.rewardsToSave.push(reward);
    reward.Pending = true;
  }

  saveRewards() {
    this.isSaving = true;
    _.map(this.rewardsToSave, async (winner) => {
      await this.rewardservice.updateWinner({
        WinnerGuid: winner.Guid,
        IsApproved: winner.Pending,
        RewardPartner: winner.RewardPartner,
      });
      this.incentiveservice.invalidateCache();
    });
    this.isSaving = false;
    this.$mdDialog.hide();
  }

  rewardStatus(reward) {
    if (this.isExpired(reward)) {
      return 'Confirmation link expired';
    }
    switch (reward.WinnerStatus) {
      case this.winnerStatus.approved: return 'Approved';
      case this.winnerStatus.notApproved: return 'Not approved';
      case this.winnerStatus.notified: return 'Notified';
      case this.winnerStatus.detailsConfirmed: return 'Details confirmed';
      case this.winnerStatus.offeredToRedeem: return 'Offered to redeem';
      default: return 'Unknown';
    }
  }

  isWinnerStatus(status) {
    if (status === this.winnerStatus.notApproved) {
      return false;
    }
    return true;
  }

  showCopyToClipboard(reward) {
    return (reward.WinnerStatus === this.winnerStatus.notified && !this.isExpired(reward)) || reward.RedeemedRewardResponse;
  }

  copyToClipboard(reward) {
    const interfaceUrl = new URL(this.__env.interfaceUrl);

    const url = reward.WinnerStatus === this.winnerStatus.notified ?
      `${interfaceUrl.protocol}//${this.selectedSquareFactory.SquareUrl}.${this.__env.squareUrlBase}/getreward/${reward.Guid}` :
      this.beautifyRedeemedRewardResponse(reward.RedeemedRewardResponse, reward.WinnerStatus);

    this.clipboardService.copyToClipboard(url);
  }

  beautifyRedeemedRewardResponse(redeemedRewardResponse: string, rewardStatus: number) {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    if (urlRegex.test(redeemedRewardResponse)) {
      return redeemedRewardResponse;
    }

    return rewardStatus === this.winnerStatus.offeredToRedeem ? `Reward ID: ${redeemedRewardResponse}` : redeemedRewardResponse;
  }

  reverseEnum(labels, value) {
    return Utils.reverseEnum(labels, value);
  }

  private isExpired(reward) {
    if (reward.WinnerStatus !== this.winnerStatus.notified || !reward.DateNotificationSent) {
      return false;
    }

    const daysDifference = DateTime.now().diff(DateTime.fromISO(reward.DateNotificationSent), 'days').days;

    return daysDifference > this.serverConstants.incentivesConstants.rewardLinkValidityDays;
  }

  get valueLabel() {
    return this.isSavingUpEnabled ? 'Points' : 'Reward value';
  }
}
