'use strict';

import { ICropArea } from './image-crop.service';

export class ImageCropController {
  static $inject = ['file', 'areaType', 'areaRatio', '$mdDialog'];

  cropArea: ICropArea;

  constructor(
    public file: Blob,
    public areaType: 'circle' | 'square',
    public areaRatio: number,
    private $mdDialog: ng.material.IDialogService,
  ) { }

  cancel() {
    this.$mdDialog.cancel();
  }

  save() {
    this.$mdDialog.hide(this.cropArea);
  }
}
