'use strict';

import { ServerConstants } from '../../core/serverconstants';

export class IscSquareStatusFilterController {
  static $inject = ['serverConstants'];
  constructor(private serverConstants: ServerConstants) {

  }
  availableStatuses = [
    {
      Label: 'Draft',
      Value: this.serverConstants.squareStatusConstants.draft,
    },
    {
      Label: 'Scheduled',
      Value: this.serverConstants.squareStatusConstants.scheduled,
    },
    {
      Label: 'Active',
      Value: this.serverConstants.squareStatusConstants.active,
    },
    {
      Label: 'Closed',
      Value: this.serverConstants.squareStatusConstants.closed,
    },
    {
      Label: 'Archived',
      Value: this.serverConstants.squareStatusConstants.archived,
    },
    {
      Label: 'Deleted',
      Value: this.serverConstants.squareStatusConstants.deleted,
    },
  ];
}
