'use strict';

import { SelectedSquareFactory } from './../../core/selectedsquare.factory';
export class GoogleTranslateHelperService {
  static $inject = ['selectedSquareFactory'];
  private enabled: boolean = false;

  constructor(private selectedSquareFactory: SelectedSquareFactory) { }

  enable(): void {
    this.enabled = true;
  }

  disable(): void {
    this.enabled = false;
  }

  isEnabled(): boolean {
    return this.enabled;
  }

  isTranslationEnabled(): boolean {
    // we need to search for the specific div containing the correct Iframe with the translation info
    const divElement = document.getElementsByClassName('skiptranslate')[0] as HTMLElement;
    if (divElement.children[0].id === ':2.container') {
      const styleOfDiv = divElement.style;
      return styleOfDiv.display !== 'none';
    }
    return false;
  }

  autoEnableForScope($scope: ng.IScope) {
    const subscription = this.selectedSquareFactory.languageObservable.subscribe((value) => {
      if (!value || value === 'en') {
        this.disable();
      } else {
        this.enable();
      }
    });
    $scope.$on('$destroy', () => {
      this.disable();
      subscription.unsubscribe();
    });
  }
}
