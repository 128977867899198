'use strict';

import { IConversationEditorConfig, IUploadConversationStimulus, IUploadConversationAttachment } from 'isc-ui';
import { CurrentUserService } from './../../../core/dataservices/currentUser.service';
import { Utils } from './../../../core/utils';
import { ParticipantFactory } from '../../../core/participant.factory';
import { ConversationService } from '../../../core/services/conversation.service';
import { SpinnerService } from '../../../core/services/spinner.service';
import { PoboService } from '../../../core/services/pobo.service';
import { MappingService } from '../../../core/services/mapping.service';
import { ServerConstants } from '../../../core/serverconstants';

export class ConversationPostController {
  item;
  stripOffInteractions;
  message: string;
  stimuli: IUploadConversationStimulus[] = [];
  attachments: IUploadConversationAttachment[] = [];
  newStimulusFiles: File[];
  newAttachmentFiles: File[];
  isEditMode = false;

  conversationEditorConfig: IConversationEditorConfig;

  static $inject = [
    'serverConstants',
    'participantFactory',
    'spinnerservice',
    'conversationService',
    'currentUserService',
    '$state',
    '$stateParams',
    'poboService',
    'mappingService',
  ];

  constructor(
    private serverConstants: ServerConstants,
    private participantFactory: ParticipantFactory,
    private spinnerservice: SpinnerService,
    private conversationService: ConversationService,
    private currentUserService: CurrentUserService,
    private $state: ng.ui.IStateService,
    private $stateParams: ng.ui.IStateParamsService,
    private poboService: PoboService,
    private mappingService: MappingService,
  ) { }

  $onInit() {
    this.newStimulusFiles = [];
    this.newAttachmentFiles = [];
    this.conversationEditorConfig = {
      showTitle: false,
      showTextEditor: true,
      showCaptionEditor: false,
      saveLabel: '(LabelForumPostSave)',
      uploadConfig: {
        maxStimuli: this.serverConstants.validationConstants.conversationStimuliMaxNo,
        maxAttachments: this.serverConstants.validationConstants.conversationAttachmentMaxNo,
        showAttachment: true,
        showImageUpload: true,
        showVideoLink: true,
        showVideoUpload: true,
      },
    };
  }

  getRoleLabel(roleType: any): string {
    return Utils.getRoleLabel(this.serverConstants.roleConstants, roleType);
  }

  getStatusLabel(statusType: any, suspended: boolean): string {
    return Utils.getStatusLabel(this.serverConstants.registrationStatusConstants, statusType, suspended);
  }

  getAvatar() {
    if (!this.item) {
      return undefined;
    }
    return this.participantFactory.getAvatar(this.item);
  }

  isCurrentParticipant(squareParticipantGuid) {
    return this.currentUserService.userProfile.Guid === squareParticipantGuid;
  }

  toggleCensorConversation() {
    this.conversationService.censorConversation(this.item);
  }

  isStimuliDownloadSupported(stimuli: { Type: number }) {
    return stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.image ||
      stimuli.Type === this.serverConstants.conversationStimuliTypeConstants.video;
  }

  getMemberLink(): string {
    if (this.item && this.item.SquareParticipantRole === this.serverConstants.roleConstants.participant) {
      return this.item && this.$state.href('root.square.members.details.activities', {
        clientGuid: this.$stateParams.clientGuid,
        programGuid: this.$stateParams.programGuid,
        squareGuid: this.$stateParams.squareGuid,
        memberGuid: this.item.SquareParticipantGuid,
      });
    }

    return null;
  }

  ownPost() {
    return this.item.SquareParticipantGuid === this.currentUserService.userProfile.Guid;
  }

  isEditable() {
    return this.item && this.item.IsEditable;
  }

  async cancel() {
    this.isEditMode = false;
    this.item.title = '';
    this.message = '';
    this.stimuli.splice(0);
    this.attachments.splice(0);
  }

  editPostClick() {
    this.message = this.item.Message;
    this.stimuli = this.mappingService.mapConversationStimuliForConversationEditor(this.item.Stimuli);
    this.attachments = this.mappingService.mapConversationAttachmentsForConversationEditor(this.item.Attachments);
    this.isEditMode = true;
  }

  async saveClicked() {
    const username = await this.poboService.checkPoboConfirmation(
      this.item.Username, !this.isEditMode);
    this.item.Username = username;
    let guid = this.item.Guid;
    let parentMessage = this.item.ParentMessage;
    if (!this.isEditMode) {
      guid = null;
      parentMessage = this.item.Guid;
    }
    this.spinnerservice.show('loading');

    await this.conversationService.saveLoungeConversation(
      guid,
      parentMessage,
      this.message,
      this.item.Type,
      this.stimuli,
      this.attachments,
      this.newStimulusFiles,
      this.newAttachmentFiles);

    await this.cancel();
    this.spinnerservice.hide('loading');
  }
}
