'use strict';

import { SquareHealthController } from './squareHealth.controller';

const squareHealthTemplate = require('./squareHealth.html');

export const squareHealthComponent: ng.IComponentOptions = {
  controller: SquareHealthController,
  templateUrl: squareHealthTemplate,
  controllerAs: 'vm',
};
