'use strict';


const SegmentationTemplate = require('./segmentation.html');
import { SegmentationController } from './segmentation.controller';

export const segmentationComponent: ng.IComponentOptions = {
  templateUrl: SegmentationTemplate,
  controller: SegmentationController,
  controllerAs: 'vm',
};
