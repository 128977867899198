'use strict';

import { SquareInfoController } from './squareinfo.controller';
const template = require('./squareinfo.html');
require('./_squareinfo.scss');

export const SquareInfoComponent: ng.IComponentOptions = {
  controller: SquareInfoController,
  templateUrl: template,
  controllerAs: 'vm',
};
