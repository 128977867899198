'use strict';

const template = require('./mainPages.html');
import { SquareConfigMainPagesController } from './mainPages.controller';

export const MainPagesComponent: ng.IComponentOptions = {
  controller: SquareConfigMainPagesController,
  templateUrl: template,
  controllerAs: 'vm',
};
