'use strict';

export class PasswordRuleValidator implements ng.IDirective {
  restrict = 'A';
  require = 'ngModel';

  link(scope, element, attrs, ngModel) {
    ngModel.$validators.complexity = (modelValue, viewValue) => {
      const length = viewValue && viewValue.length >= 8 || false;
      const lowercase = viewValue && viewValue.match(/[a-z]/) || false;
      const uppercase = viewValue && viewValue.match(/[A-Z]/) || false;
      const digit = viewValue && viewValue.match(/[0-9]/) || false;
      const special = viewValue && viewValue.match(/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/) || false;

      const errors = {
        length: length !== false,
        lowercase: lowercase !== false,
        uppercase: uppercase !== false,
        number: digit !== false,
        special: special !== false,
      };

      ngModel.$complexityError = errors;

      return length && lowercase && uppercase && digit && special;
    };
  }
}
