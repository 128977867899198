'use strict';

import { TargetingQuotaController } from './targeting-quota.controller';

const template = require('./targeting-quota.html');
export const TargetingQuotaComponent: ng.IComponentOptions = {
  controller: TargetingQuotaController,
  templateUrl: template,
  controllerAs: 'vm',
  bindings: {
    activityGuid: '<',
    targeting: '=',
    isDisabled: '<',
    form: '<',
  },
};
