'use strict';

import { RouterHelper } from '../../../blocks/router/router-helper';

const routeConfig = (routerHelper: RouterHelper) => {
  routerHelper.configureStates([{
    state: 'root.square.helpCenter',
    config: {
      url: '/config/helpCenter',
      component: 'helpCenter',
      title: 'Help Center',
      settings: {
        nav: 2,
        childnav: 4,
        label: 'Help center',
        showForums: true,
      },
      disallowRoles: ['Observer'],
    },
  }]);
};

routeConfig.$inject = ['routerHelper'];

export default routeConfig;

