'use strict';

const confirmTemplate = require('./squareInfoChangeConfirm.html');
import { SquareInfoChangeConfirmController } from './squareInfoChangeConfirm.controller';
import { ISavingUpModel } from './savingUpModel';
import { SquareChangeType } from './squareChangeType';

export class SquareInfoChangeService {
  static $inject = ['$mdDialog'];
  constructor(
    private $mdDialog: ng.material.IDialogService,
  ) {}

  public async showConfirmationMessage(squareChangeTypes, squareName, closureMessage, savingUpModel: ISavingUpModel): Promise<boolean> {
    return await this.confirmDialog(squareChangeTypes, squareName, closureMessage, savingUpModel);
  }

  private async confirmDialog(squareChangeTypes: SquareChangeType[], squareName: any, closureMessage: any, savingUpModel: ISavingUpModel) {
    return await this.$mdDialog.show({
      controller: SquareInfoChangeConfirmController,
      controllerAs: 'vm',
      templateUrl: confirmTemplate,
      parent: angular.element(document.body),
      clickOutsideToClose: false,
      locals: {
        squareChangeTypes,
        squareName,
        closureMessage,
        savingUpModel,
      },
    });
  }
}
