'use strict';

import { ClientService } from '../../../../core/dataservices/client.service';
import { SpinnerService } from '../../../../core/services/spinner.service';

export class ClientConfigColorsController {
  static $inject = ['logger', 'clientservice', 'spinnerservice', '$mdDialog', '$scope', '$stateParams'];

  constructor(
    private logger: Logger,
    private clientservice: ClientService,
    private spinnerservice: SpinnerService,
    private $mdDialog: ng.material.IDialogService,
    private $scope: ng.IScope,
    private $stateParams,
  ) { }

  color: string | null = null;
  form;

  isSaving = false;

  $onInit() {
    this.logger.info('Client config - Design Color Editor activated');

    this.clientservice.getClientColor().then((response) => {
      this.color = response.Color;
    });
  }

  saveColor() {
    const configurationColor = {
      ClientGuid: this.$stateParams.clientGuid,
      Color: this.color,
    };
    this.clientservice.saveClientColor(configurationColor)
      .then(() => {
        this.logger.success('Color saved successfully');
        this.form.$setPristine();
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  savePage() {
    this.isSaving = true;
    this.saveColor();
  }
}
