'use strict';

import { ActivityQualPublishController } from './activityQualPublish.controller';

const template = require('./activityQualPublish.html');

export const ActivityQualPublishComponent: ng.IComponentOptions = {
  controller: ActivityQualPublishController,
  templateUrl: template,
  controllerAs: 'vm',
  require: {
    wizardStep: '^^iscWizardStep',
  },
  bindings: {
    squareActivity: '<',
    isPublished: '<',
  },
};
