'use strict';

const selectIconDialogTemplate = require('./selectIconDialog.html');

export class IconSelectorController {
  static $inject = ['$mdDialog'];
  constructor(
    private $mdDialog: ng.material.IDialogService) { }

  selectedIcon;
  usedIcons;
  ngModel;

  selectIcon = ($event) => {
    $event.stopPropagation();
    this.$mdDialog.show({
      controller: 'SelectIconDialogController',
      controllerAs: 'vm',
      templateUrl: selectIconDialogTemplate,
      parent: angular.element(document.body),
      targetEvent: $event,
      clickOutsideToClose: true,
      locals: {
        selectedIcon: this.selectedIcon,
        usedIcons: this.usedIcons,
      },
    }).then((icon) => {
      this.ngModel.$setViewValue(icon);
    });
  };
}
