'use strict';

import { ActivityDataPostTagsController } from './activityDataPostTags.controller';
const template = require('./activityDataPostTags.html');

angular.module('insitesApp.activityData').component('iscActivityDataPostTags', {
  controller: ActivityDataPostTagsController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    items: '<',
    conversationGuid: '<',
    readOnly: '<',
  },
});
