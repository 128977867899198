'use strict';

import { PinButtonController } from './pin-button.controller';
const template = require('./pin-button.html');
require('./pin-button.scss');

export const PinButtonComponent: ng.IComponentOptions = {
  controller: PinButtonController,
  controllerAs: 'vm',
  templateUrl: template,
  bindings: {
    toggleInactive: '<',
    commandUrl: '<',
    type: '<',
    guid: '<',
    clientGuid: '<?',
    menuItem: '<',
  },
};
