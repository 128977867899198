'use strict';

import * as angular from 'angular';

import routeConfig from './home.route';

import './home.module';
import './homeSidenav';
import './homePlatformNotice';
import './featureSetting';
import './researcherlogin';
import './squarelogout';
import './observer-landing-page';
import './moderator-landing-page';
import './loginerror';
import './clientSelection';
import './landing';
import './landingSidenav';
import './activation';

require('./_home.scss');

angular
  .module('insitesApp.home')
  .run(routeConfig);
