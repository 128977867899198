'use strict';

import { ServerConstants } from '../../../core/serverconstants';
import { SelectedSquareFactory } from '../../../core/selectedsquare.factory';
import { LabelFactory } from '../../../core/label.factory';
import { SpinnerService } from '../../../core/services/spinner.service';
import { LanguageContainer } from '../../../core/languageContainer';

export class SquareClosureController implements ng.IOnInit {
  static $inject = ['serverConstants', 'selectedSquareFactory', 'labelFactory', 'spinnerservice'];
  constructor(
    public serverConstants: ServerConstants,
    private selectedSquareFactory: SelectedSquareFactory,
    private labelFactory: LabelFactory,
    private spinnerservice: SpinnerService,
  ) { }

  closuremessage: string;
  status: number;
  prevStatus: number;
  hasSquareOnlyExpiredJobIds: boolean;
  statusstring: string;
  labels: LanguageContainer;
  defaultClosureMessage: string;
  form: ng.IFormController;

  async $onInit(): Promise<void> {
    this.prevStatus = this.status;
    try {
      this.spinnerservice.show('loading');
      if (this.status !== this.serverConstants.squareStatusConstants.draft) {
        const squareLanguage: string = await this.selectedSquareFactory.languagePromise;
        this.labels = await this.labelFactory.getLabelsLanguage(squareLanguage);
        this.defaultClosureMessage
          = this.labels.getLabelValue('DefaultSquareClosureMessage')
            .replace('{SquareName}', this.selectedSquareFactory.Name);
        this.closuremessage = this.closuremessage || this.defaultClosureMessage;
      }
    }  finally {
      this.spinnerservice.hide('loading');
    }

  }

  isStatusChangeAllowed(): boolean {
    return this.status === this.serverConstants.squareStatusConstants.active ||
      this.status === this.serverConstants.squareStatusConstants.closed ||
      this.status === this.serverConstants.squareStatusConstants.archived;
  }

  isClosedOrArchived(): boolean {
    return this.status === this.serverConstants.squareActivityStatusConstants.closed ||
      this.status === this.serverConstants.squareActivityStatusConstants.archived;
  }

  isJobIDErrorVisible() {
    // Checks if the square is set from closed to active; if there are jobids expired, then show this message.
    if(this.status === this.serverConstants.squareStatusConstants.active && this.prevStatus !== this.status && this.hasSquareOnlyExpiredJobIds) {
      return true;
    }
    return false;
  }

  get closureMessage(): string {
    this.closuremessage = this.closuremessage || this.defaultClosureMessage;
    return this.closuremessage;
  }

  set closureMessage(value: string) {
    this.closuremessage = value;
  }
}
