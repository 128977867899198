'use strict';

const ListFilter = (_) => (data, query, fields) => {
  if (!data) {
    return null;
  } else if (!query) {
    return data;
  } else if (!fields) {
    return data;
  }
  query = query.toLowerCase();
  fields = fields.split('|');
  return data.filter((item) => {
    for (const i in fields) {
      if (Object.prototype.hasOwnProperty.call(fields, i)) {
        const field = fields[i];
        let itemField = item[field];
        if (itemField) {
          itemField = itemField.toString().toLowerCase();
          if (_.includes(itemField, query)) {
            return true;
          }
        }
      }
    }
    return null;
  });

};
ListFilter.$inject = ['_'];

angular
  .module('insitesApp.layout')
  .filter('ListFilter', ListFilter);
