'use strict';

import {
  IClientConfigurationVideoStorage,
  ISelectedClientInfo,
  IClientConfigurationWelcomeMessage,
  IClientConfigurationColor,
} from './dataservice.contracts';
import {
  clientService,
  convertCamelCasedObjectToPascalCasedObject,
  convertPascalCasedObjectToCamelCasedObject,
} from '@insites/vue-eco-temp-library';
import { HttpService } from './http.service';
import {
  ClientConfigColorResponse,
  ClientConfigVideoStorageDetailItemResponse,
  ClientConfigurationDetailItemResponse,
  ListActivityJobIdResponse, RelatedClientDto,
  SelectionUserClientResponse,
  UpdateClientResponse,
} from './client.service.contracts';

export class ClientService {
  static $inject = ['httpservice'];
  constructor(
    private httpService: HttpService,
  ) { }

  async getClientDetails(clientGuid: string, includeRelatedClients: boolean = false) {
    return convertCamelCasedObjectToPascalCasedObject(
      await clientService.getClientDetails(clientGuid, includeRelatedClients),
    );
  }

  async updateClient(client: ISelectedClientInfo) {
    return (await this.httpService.post<UpdateClientResponse>({
      url: '/ClientService/UpdateClient',
      data: {
        AdminName: client.AdminName,
        Guid: client.Guid,
        RelatedClientGuids: client.RelatedClients
          .filter((relatedClient) => !relatedClient.IsMarkedForDeletion)
          .map((relatedClient) => relatedClient.Guid),
      },
    })).data;
  }

  async toggleClientPin(rootUrl, guid) {
    return (await this.httpService.post<void>({
      url: '/ClientService/ToggleClientPin',
      data: {
        ClientGuid: guid,
      },
    }, rootUrl)).data;
  }

  async getClientListForDropdown() {
    return (await this.httpService.get<SelectionUserClientResponse>({
      url: '/ClientService/SelectionUserClient',
    })).data;
  }

  async getUnrelatedClients() {
    return (await this.httpService.get<RelatedClientDto[]>({
      url: '/ClientService/GetUnrelatedClients',
    })).data;
  }

  async getClientConfigurationWelcomeMessage(clientGuid) {
    return convertCamelCasedObjectToPascalCasedObject(
      await clientService.getClientConfigurationWelcomeMessage(clientGuid),
    );
  }

  async getClientConfigurationVideoStorage(clientGuid) {
    return (await this.httpService.get<ClientConfigVideoStorageDetailItemResponse>({
      url: '/ClientService/GetClientConfigurationVideoStorage',
      params: { clientGuid },
    })).data;
  }

  async updateLastSelectedDate(clientGuid: string) {
    return (await this.httpService.post<void>({
      url: '/ClientService/UpdateLastSelectedDate',
      params: { clientGuid },
    })).data;
  }

  saveClientConfigurationWelcomeMessage(request: IClientConfigurationWelcomeMessage) {
    return clientService.saveClientConfigurationWelcomeMessage(
      convertPascalCasedObjectToCamelCasedObject(request),
    );
  }

  saveClientConfigurationVideoStorage(request: IClientConfigurationVideoStorage) {
    return clientService.saveClientConfigurationVideoStorage(
      convertPascalCasedObjectToCamelCasedObject(request),
    );
  }

  async saveClientLogo(image) {
    return (await this.httpService.upload({
      url: '/ClientService/SaveClientLogo',
      data: image,
    })).data;
  }

  getClientLogo(tryGetFromCache: boolean = true) {
    return clientService.getClientLogo(tryGetFromCache);
  }

  async saveClientColor(request: IClientConfigurationColor) {
    return (await this.httpService.post<void>({
      url: '/ClientService/SaveClientConfigurationColor',
      data: request,
    })).data;
  }

  async getClientColor() {
    return (await this.httpService.get<ClientConfigColorResponse>({
      url: '/ClientService/GetClientConfigurationColor',
    })).data;
  }

  async getClientConfiguration(clientGuid) {
    return (await this.httpService.get<ClientConfigurationDetailItemResponse>({
      url: '/ClientService/GetClientConfiguration', params: { clientGuid },
    })).data;
  }

  async getAllClientJobIds() {
    return (await this.httpService.get<ListActivityJobIdResponse>({ url: '/ClientService/GetClientJobIds' })).data;
  }
}
